import { safeJSONParse } from '@jotforminc/utils';
import { PROMT_PROPS } from '../constants';
import { Message } from '../typings/api';

export const promptLengthLimit = (currentLen: number) => {
  return PROMT_PROPS.MAX_LENGTH - currentLen;
};

export function formatMessage(message: string, messageType: Message['message_type'], username: string) {
  const parsedText = safeJSONParse(message);
  // List help message
  if (messageType === 'HELP' && Array.isArray(parsedText)) {
    const helpMessagesText = parsedText.reduce((totalContent, value) => `${totalContent}<li class="list-disc list-inside mt-1">${value}</li>`, '');
    const pretext = 'I couldn\'t do this for you right now, but you can follow these steps:';
    const listHelpMessage = `${pretext}<ul class="pl-2">${helpMessagesText}</ul>`;
    return listHelpMessage;
  } else if (messageType === 'WELCOME') { // eslint-disable-line no-else-return
    return message.replace('{username}', username);
  }
  return message;
}

export const noop = () => { /** empty */ };
