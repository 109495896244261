import { ValueOf } from 'type-fest';

export const ORDER_DIR_OPTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;
export type OrderType = ValueOf<typeof ORDER_DIR_OPTIONS>;

export const TEXT_DIR_OPTIONS = {
  ASC: 'Sort by ascending order',
  DESC: 'Sort by descending order'
};
