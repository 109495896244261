import React, { useEffect } from 'react';
import {
  number, arrayOf, shape, func, oneOfType, bool
} from 'prop-types';
import {
  InfiniteLoader, List, AutoSizer, WindowScroller
} from 'react-virtualized';
import { LIST_FETCH_LIMIT } from '../../constants';

const FlatList = ({
  totalCount, listSource, loadMoreRows, rowRenderer, height: rowHeight = null
}) => {
  const listItemRowHeight = rowHeight || 68; // TODO: get from compound styles

  useEffect(() => {
    const scrollContainer = document.querySelector('.ReactVirtualized__Grid__innerScrollContainer');
    if (scrollContainer && scrollContainer.classList && !scrollContainer.classList.contains('notranslate')) {
      scrollContainer.classList.add('notranslate');
    }
  }, [listSource]);

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => {
        return !!listSource[index];
      }}
      loadMoreRows={loadMoreRows}
      rowCount={totalCount}
      minimumBatchSize={LIST_FETCH_LIMIT}
      threshold={LIST_FETCH_LIMIT / 5}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller scrollElement={totalCount ? undefined : document.body}>
          {({
            height, isScrolling, onChildScroll, scrollTop
          }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  ref={registerChild}
                  autoHeight
                  onRowsRendered={onRowsRendered}
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  rowCount={totalCount}
                  overscanRowCount={20}
                  rowHeight={listItemRowHeight}
                  height={height}
                  width={width}
                  className="virtualizedGrid"
                  role='grid'
                  containerRole='row'
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};

FlatList.propTypes = {
  totalCount: number.isRequired,
  listSource: arrayOf(shape()).isRequired,
  loadMoreRows: func.isRequired,
  rowRenderer: func.isRequired,
  height: oneOfType([number, bool])
};

export default FlatList;
