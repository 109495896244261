/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { IconAngleDown } from '@jotforminc/svg-icons';
import { DateFilter, FilterType } from './ActivityLog/types';
import { TimeRange } from './TimeRange';
import { DateFilter as TimeRangeDateFilter } from './TimeRange/types';

type onChangeForFilters = (newValue: Exclude<DateFilter, 'range'> | { start: string, end: string }, filterType?: FilterType) => void;
type onChangeRegular = (newValue: Exclude<DateFilter, 'range'> | { start: string, end: string }, start?: string, end?: string) => void;
type onChangeType<T> = T extends true ? onChangeForFilters : onChangeRegular;

type RangeSelectorProps<T extends boolean> = {
  value: TimeRangeDateFilter | { start: string, end: string },
  showTimeFilterText?: boolean,
  disableFuture?: boolean,
  onChange?: onChangeType<T>,
  forFilters?: T,
  customDateRangeValue?: { start?: string; end?: string;},
  showOnlyCustomText?: boolean,
  maxRange?: number,
  hiddenCustomFilters?: TimeRangeDateFilter [],
  customWarningEnabled?: boolean,
  customWarning?: string,
  userLanguage?: string
};

function RangeSelector<T extends boolean>({
  value, onChange = () => {}, showTimeFilterText, forFilters, disableFuture, customDateRangeValue, showOnlyCustomText, maxRange, hiddenCustomFilters, customWarningEnabled, customWarning, userLanguage
}: RangeSelectorProps<T>): ReactElement {
  const getRangeValue = (timeRange: TimeRangeDateFilter): Exclude<DateFilter, 'range'> => {
    const range = timeRange.charAt(0).toLowerCase() + timeRange.slice(1);
    return range.replace(/ /g, '') as Exclude<DateFilter, 'range'>;
  };

  const handleChange = (timeRange: TimeRangeDateFilter, startDate: string, endDate: string) => {
    const newValue: Exclude<DateFilter, 'range'> | { start: string, end: string } = timeRange !== 'custom' ? getRangeValue(timeRange) : { start: startDate, end: endDate };
    if (forFilters) {
      (onChange as onChangeForFilters)(newValue, 'date');
    } else {
      (onChange as onChangeRegular)(newValue, startDate, endDate);
    }
  };

  return (
    <TimeRange
      timeFilterText={typeof value === 'string' ? value : 'custom'}
      isContextMenuOpened={false}
      showTimeFilterText={showTimeFilterText}
      disableFuture={disableFuture}
      customDateRangeValue={customDateRangeValue}
      handleTimeRangeOptionsChange={handleChange}
      iconTimeFilter={showOnlyCustomText ? null : <IconAngleDown className="w-6 h-auto fill-gray-100" />}
      onlySaveOnClose={true}
      showOnlyCustomText={showOnlyCustomText}
      maxRange={maxRange}
      hiddenCustomFilters={hiddenCustomFilters}
      customWarningEnabled={customWarningEnabled}
      customWarning={customWarning}
      userLanguage={userLanguage}
    />
  );
}

RangeSelector.defaultProps = {
  showTimeFilterText: false,
  disableFuture: false,
  onChange: () => {},
  forFilters: false,
  customDateRangeValue: {},
  showOnlyCustomText: false,
  maxRange: null,
  hiddenCustomFilters: [],
  customWarningEnabled: false,
  customWarning: '',
  userLanguage: ''
};

export default RangeSelector;
