import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewPortalWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewPortalWizard" */'./LazyWizards/LazyCreateNewPortalWizard'));

export const openCreateNewPortalModal = ({
  showRecommendation,
  showOnlineStoreRecommendation,
  totalListCount,
  logAbTestAction,
  ...props
}) => {
  return new Promise((resolve, reject) => {
    const containerSelector = '#modal-container';
    const rootElement = document.querySelector(containerSelector);
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      unmountComponentAtNode(rootElement);
      if (props.closeButtonCallback) {
        props.closeButtonCallback();
      }
      logAbTestAction({ action: 'close', target: 'createNewAppWizard' });
    };

    const handleShow = () => {
      if (props.openWizardCallback) props.openWizardCallback();
      logAbTestAction({ action: 'show', target: 'createNewAppWizard' });
    };

    const appTemplatesCountCheck = props?.fromOnLineStoreLandingFeature ? window?.APP_TEMPLATES_COUNT_FROM_LANDING?.SHORT : window?.APP_TEMPLATES_COUNT?.SHORT;

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewPortalWizard
          {...props}
          onShow={handleShow}
          onHide={handleClose}
          initialVisibilityState={true}
          showCloneApp={totalListCount > 0}
          logAbTestAction={logAbTestAction}
          showRecommendation={showRecommendation}
          showBuildStoreApp={props?.showBuildStoreAppFeature || false}
          showOnlineStoreRecommendation={showOnlineStoreRecommendation}
          showBuildDonationApp={props?.showBuildDonationAppFeature || false}
          fromOnLineStoreLanding={props?.fromOnLineStoreLandingFeature || false}
          fromOnLineDonationLanding={props?.fromOnLineDonationLandingFeature || false}
          appTemplatesCount={appTemplatesCountCheck || window?.APP_TEMPLATES_COUNT?.SHORT}
          onViewChange={onViewChange}
        />
      )
    });
  });
};
