/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { Loading } from '@jotforminc/loading';
import { handleCustomNavigation } from '@jotforminc/utils';

import { ActivityLogger } from '@jotforminc/activity-logger';
import qs from 'querystring';
import { appToResourceType, defaultNavigationItem } from './navigationConstants';
import ResourcePickerModal from './ResourcePickerModal';
import { getNavigationResources } from '../../utils/utils';
import NavigationItemBadge from './NavigationItemBadge';

const shouldHide = ({
  summary, app, appConfig, currentAppName
}) => {
  if (currentAppName === app || !appConfig.hideIfEmpty) return false;

  const { count = 0, notification = 0 } = summary;

  return !(count || notification);
};

class NavigationItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getConfig = this.getConfig.bind(this);
    this.onClick = this.onClick.bind(this);
    this.redirectToCreate = this.redirectToCreate.bind(this);
    this.redirect = this.redirect.bind(this);
    this.resourcePickerModalRef = React.createRef();
  }

  onClick(e) {
    const {
      logDiscoverabilityActions = f => f,
      disabled = false
    } = this.props;
    e.preventDefault();

    if (disabled) return;
    const appConfig = this.getConfig();
    const appTitle = appConfig?.title?.replace(/ +/g, '');

    // discoverabilityTracking
    logDiscoverabilityActions({
      action: 'click',
      target: `.appItem${appTitle}`
    });

    this.newNavigation();
  }

  getConfig() {
    const {
      app = '',
      currentAppName = '',
      appConfig = null,
      branding21 = false
    } = this.props;
    const config = appConfig || defaultNavigationItem(app, branding21);
    if (['approvals', 'portal'].includes(currentAppName) && app === 'sign') {
      config.target = '_blank';
    }
    return config;
  }

  getHref(id, oldNavigation) {
    const { appLink } = this.getConfig();
    return typeof appLink === 'function' ? appLink(id, oldNavigation) : appLink;
  }

  getCreateLink(formIDs) {
    const { createLink } = this.getConfig();
    if (typeof createLink !== 'function') {
      return false;
    }
    return createLink(formIDs);
  }

  redirectToCreate(formIDs) {
    const {
      currentAppName = '',
      activeFormID = '',
      formIDs: propFormIDs = []
    } = this.props;
    let createLink;
    if (formIDs) {
      createLink = this.getCreateLink(formIDs);
    } else if (currentAppName === 'tables') {
      // If formIDs is undefined, it means that create new button is clicked.
      // Create app with all forms in the sheet
      createLink = this.getCreateLink(propFormIDs);
    } else {
      // Create an app with the current form
      createLink = this.getCreateLink(activeFormID);
    }

    if (createLink) {
      this.redirectClick(createLink);
    }
  }

  async newNavigation() {
    const {
      currentAppName = '',
      appID = '',
      activeFormID = '',
      app = '',
      setLoading = f => f,
      toggleNavigation = f => f,
      forms = []
    } = this.props;
    if (currentAppName === app) {
      return; // Don't do anything if same product is clicked
    }
    const toProduct = app === 'appTables' ? 'tables' : app;
    const currentProductID = appID || activeFormID;

    if (currentAppName === 'portal' && toProduct === 'tables') { // Tables will automatically handle appTable case
      this.redirect(currentProductID);
      return;
    }

    if (currentAppName === 'AIAgentBuilder' && app === 'conversations') {
      this.redirect();
      return;
    }

    if (currentAppName === 'AIAgentBuilder' && forms.length) {
      if (forms.length === 1) {
        this.redirect(forms[0]?.id);
      } else {
        if (typeof toggleNavigation === 'function') {
          toggleNavigation();
        }
        this.resourcePickerModalRef?.current?.showPicker(forms, toProduct);
      }
      return;
    }

    setLoading(true);
    if (!currentProductID) {
      this.redirect(activeFormID, true);
      return;
    }
    try {
      const { type, result } = await getNavigationResources(currentAppName, currentProductID, toProduct);
      switch (type) {
        case 'SheetFormsList':
          // It means that there is no form connected to an app.
          // Create new app with all the forms in the sheet in this case.
          this.redirectToCreate(result);
          break;
        case 'NoPortal':
          ActivityLogger.setLogger(window?.JotFormActions('formBuilderLauncher'))
            .log({
              action: 'click',
              target: `CreateApp - ${activeFormID}`
            });
          this.redirectToCreate(activeFormID);
          break;
        case 'EmptyPortal':
          this.resourcePickerModalRef?.current?.showPicker([], toProduct);
          break;
        default:
          if (result.length === 1) {
            this.redirect(result[0]?.id);
          } else {
            if (typeof toggleNavigation === 'function') {
              toggleNavigation();
            }
            this.resourcePickerModalRef?.current?.showPicker(result, toProduct);
          }
      }
    } catch (error) {
      // For navigations whose endpoints are not ready, stick with old behavior
      this.redirect(activeFormID, true);
    }
    setLoading(false);
  }

  redirect(id = '', oldNavigation) {
    const {
      app = '', user: { accountType, username, name } = {}, agentID = '', currentAppName
    } = this.props;

    const isGuestUser = accountType === 'GUEST';
    const isGuestUserClickedViewForm = (app === 'viewForm' && isGuestUser);
    if (!isGuestUserClickedViewForm) {
      const res = ActivityLogger.log({
        action: 'appChanged',
        target: `${app}|${id}`
      });

      if (!res && window?.JotFormActions) {
        const { tick } = window.JotFormActions('productPickerNavigationActions');
        tick?.({
          actor: username || name,
          action: 'appChanged',
          target: `${app}${id ? `|${id}` : ''}`
        });
      }

      const href = this.getHref(app === 'aiAgentBuilder' && currentAppName !== 'conversations' ? agentID : id, oldNavigation);
      this.redirectClick(href, app);
    }
  }

  redirectClick(href, app) {
    const { target = '_self' } = this.getConfig();
    const {
      redirectionParameters = {},
      currentAppName = '',
      createForm = f => f,
      setLoading = f => f,
      agentID = ''
    } = this.props;
    const parameters = qs.stringify(redirectionParameters);
    setTimeout(() => {
      if (app === 'viewForm') {
        handleCustomNavigation(`${href}${parameters ? `?${parameters}` : ''}`, '_blank', true);
      } else if (currentAppName === 'tables' && app === 'formBuilder') {
        createForm('edit', true);
      } else if ((app === 'formBuilder' || app === 'inbox' || app === 'conversations') && window?.location?.pathname.includes('agent/build')) {
        setLoading(true);
        if (app === 'conversations') {
          handleCustomNavigation(`/conversations/withAgent/${agentID}${parameters ? `?${parameters}` : ''}`, target, true);
        } else {
          handleCustomNavigation(`${href}${app === 'inbox' ? '/conversations' : ''}${parameters ? `?${parameters}` : ''}`, target, true);
        }
      } else {
        handleCustomNavigation(`${href}${parameters ? `?${parameters}` : ''}`, target);
      }
    }, 250);
  }

  render() {
    const {
      title = '',
      explanation = '',
      app = '',
      currentAppName = '',
      summary = { notification: 0 },
      hideIfEmpty = false,
      branding21 = false,
      newBadge = false,
      loading = false,
      disabled = false
    } = this.props;

    const { notification = 0 } = summary;

    const appConfig = this.getConfig();

    if (hideIfEmpty && shouldHide({
      summary, currentAppName, app, appConfig
    })) {
      return null;
    }

    const isSelected = app === currentAppName;
    const AppIcon = isSelected ? appConfig?.icons?.Selected : appConfig?.icons?.Default;
    const selectedColor = (branding21 && appConfig.appColor) ? appConfig.appColor : appConfig.secondaryColor;
    // if current app is related to boards or agents
    const showBetaBadge = ['boards', 'myboards', 'conversations', 'myagents', 'aiagentbuilder'].includes(app.toLowerCase()) || false;
    const showNewBadge = app === 'myTasks' && window?.isWorkflowReleased;
    const footerCreateNewText = app === 'boards' ? t('Create New Board') : t('Create New');
    const footerDoneText = app === 'boards' ? t('Open') : t('Done');

    return (
      <li className="appItem-wrapper">
        <button
          type="button"
          className={classNames('appItem', {
            notification, isSelected: branding21 && isSelected, disabled
          })}
          data-icon={appConfig['data-icon']}
          {...(newBadge && { 'data-badge': t('NEW') })}
          onClick={this.onClick}
          style={isSelected ? {
            background: selectedColor,
            color: '#FFFFFF',
            borderColor: selectedColor
          } : null}
          data-testid={`hc-${appConfig?.title?.toLowerCase().replace(/\s/g, '-')}`}
        >
          <span
            className="appIcon"
            style={!branding21 ? { backgroundColor: appConfig.primaryColor } : null}
            data-count={notification}
          >
            {branding21 && AppIcon ? <AppIcon /> : appConfig.icon}
          </span>

          {explanation ? (
            <span className='appTextContent'>
              <span title={t(title)} className="appName">
                {t(title)}
              </span>
              <span className="appExplanation">
                {t(explanation)}
              </span>
            </span>
          )
            : (
              <span title={t(title)} className="appName">
                {t(title)}
              </span>
            )}
          {/*  beta tag for Boards in app picker */}
          {showNewBadge ? (<NavigationItemBadge text={t('NEW')} selected={isSelected} colorStyle="success" />) : null}
          {showBetaBadge ? (<NavigationItemBadge text={t('BETA')} selected={isSelected} />) : null}
          {loading && (
            <span className='appItemLoading' data-icon={appConfig['data-icon']}>
              <Loading size="50%" strokeWidth="3px" />
            </span>
          )}
        </button>
        <ResourcePickerModal
          ref={this.resourcePickerModalRef}
          onResourceSelectionComplete={this.redirect}
          onCreateNewResource={appConfig?.createLink && this.redirectToCreate}
          resourceType={appToResourceType(app)}
          footerCreateNew={footerCreateNewText}
          footerDone={footerDoneText}
        />
      </li>
    );
  }
}

NavigationItem.propTypes = {
  app: PropTypes.string,
  appConfig: PropTypes.shape({}),
  currentAppName: PropTypes.string,
  title: PropTypes.string,
  explanation: PropTypes.string,
  forms: PropTypes.arrayOf(PropTypes.shape({})),
  formIDs: PropTypes.instanceOf(Array),
  appID: PropTypes.string,
  activeFormID: PropTypes.string,
  agentID: PropTypes.string,
  summary: PropTypes.shape({
    notification: PropTypes.number
  }),
  user: PropTypes.shape({
    accountType: PropTypes.string,
    username: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  hideIfEmpty: PropTypes.bool,
  branding21: PropTypes.bool,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  logDiscoverabilityActions: PropTypes.func,
  toggleNavigation: PropTypes.func,
  newBadge: PropTypes.bool,
  redirectionParameters: PropTypes.shape({}),
  createForm: PropTypes.func
};

export default NavigationItem;
