import { createRoot } from 'react-dom/client';
import React, { Suspense } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import { Loading } from '@jotforminc/loading';
import { getPortalRoot } from '../../utils';

const MyworkspaceFeedbackModal = safeLazy(() => import(/* webpackChunkName: "MyworkspaceFeedbackModal" */'.'));

export const openMyworkspaceFeedbackModal = ({
  user
}) => {
  const portalRoot = getPortalRoot();
  const root = createRoot(portalRoot);

  let myworkspaceFeedbackTracker = f => f;
  if (window.JotFormActions) {
    ({ tick: myworkspaceFeedbackTracker } = window.JotFormActions('myworkspaceFeedbackModal'));
  }

  const renderLazy = RenderedComponent => root.render(
    <Suspense fallback={<Loading />}>
      {RenderedComponent}
    </Suspense>
  );

  renderLazy(
    <MyworkspaceFeedbackModal
      user={user}
      tracker={myworkspaceFeedbackTracker}
      handleClose={() => root.unmount()}
    />
  );
};
