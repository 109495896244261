export const customStr = 'Custom';
export const allTimeStr = 'All Time';
export const defaultTimeFilterType = 'allTime';
export const labelClass = 'jSheetForm-select mr-14 jSheetButton isOutlineDark forTimeFilter';
export const optionList = {
  allTime: allTimeStr,
  today: 'Today',
  yesterday: 'Yesterday',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  previousWeek: 'Previous Week',
  previousMonth: 'Previous Month',
  thisYear: 'This Year',
  previousYear: 'Previous Year',
  custom: customStr
};
