import { handleCustomNavigation } from '@jotforminc/utils';
import { setFlagToLocSt, setUserNotificationStatus } from '../utils';

const onConfirm = ({ actionTargetName, localStActionKey }) => {
  setFlagToLocSt(localStActionKey);
  // eslint-disable-next-line max-len
  handleCustomNavigation(`${window.location.origin}/enterprise/healthcare/mental-health-software/?utm_source=mental-health-clinic-notification&utm_medium=notification&utm_content=button&utm_campaign=mental-health-clinic`);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, localStActionKey, disabledKey }) => {
  setFlagToLocSt(localStActionKey);
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkMentalHealthClinic = notifications => {
  let notification = notifications.find(notif => notif.name === 'mentalHealthClinic');
  const otherNotifications = notifications.filter(notif => notif.name !== 'mentalHealthClinic');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkMentalHealthClinic;
