import { ListItemSharedDefaultProps } from '../../ListItem/propTypes';

export const POPOVER_PROPS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions,
    ...ListItemSharedDefaultProps.PopoverModifierProps
  },
  className: 'listings-popover-modal isMoreMenu isToolbarMoreMenu'
};
