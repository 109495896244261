// TODO: make a queueble action with sagas.
// This is a fast initial version

const actionProjectsForMixListingAbTest = [
  'discoverabilityTracking',
  'myformsFormSidebar',
  'mixAssetCreationModal',
  'teams'
];

export const sendTrackDataBase = project => {
  return (actor, action, target, isEnterpriseAllowed) => {
    const openToEnterprise = window.JOTFORM_ENV === 'ENTERPRISE' && isEnterpriseAllowed;
    if (!window.JotFormActions || (window.JOTFORM_ENV !== 'PRODUCTION' && !openToEnterprise)) {
      return;
    }
    if (!window[`${project}Actions`]) {
      window[`${project}Actions`] = window.JotFormActions(`${project}`);
    }
    window[`${project}Actions`].tick({ actor, action, target });
    if (window.mixedListingAbTestLogger
      && typeof window.mixedListingAbTestLogger === 'function'
      && (project?.includes?.('-listings') || actionProjectsForMixListingAbTest.includes(project))) {
      window.mixedListingAbTestLogger({ action, target: `${target}-from-${window.location.pathname}` });
    }
  };
};

export const sendTrackData = page => {
  let currentPage = page === 'assignedForms' ? 'assignee' : page;
  currentPage = currentPage || 'assignee-listings';
  return sendTrackDataBase(`${currentPage}-listings`);
};
