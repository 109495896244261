/* eslint-disable max-len */
import styled from 'styled-components';

export const centerer = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  .jfIconSVG-wrapper {
    ${centerer}
    padding: 8px;

    > div {
      ${centerer}
    }
  }
`;
