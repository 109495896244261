import React from 'react';
import { createRoot } from 'react-dom/client';
import { translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';

import SalesforceCloud from '../assets/campaigns/2023/salesforce/cloud.svg';
import SalesforceBg from '../assets/campaigns/2023/salesforce/salesforce.svg';
import './styles/salesforce-headerbanner.scss';

export const SalesforceHeaderBanner = ({ utmSource, accountTypeName }) => {
  return (
    <div className='salesforce-campaign-container'>
      <SalesforceCloud className="cloud-icon" />
      <div className='salesforce-content-container'>
        <SalesforceBg className="salesforce-bg" />
        <h5>
          {translationRenderer('Announcing Jotform for [1[Salesforce]]')({
            renderer1: salesforceText => (
              <strong>
                {salesforceText}
              </strong>
            )
          })}
          {' 🎉'}
        </h5>
        <a
          className='locale cta-button'
          href={`https://link.jotform.com/pQ2GbaGcq7?&utm_campaign=saleforce2023&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
          target="_blank"
        >
          {translationRenderer('Get the App - [1[It’s  Free!]]')({
            renderer1: ctaText => (
              <span>
                {ctaText}
              </span>
            )
          })}
        </a>
      </div>
    </div>
  );
};

SalesforceHeaderBanner.propTypes = {
  utmSource: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderSalesforceHeaderBanner = ({ container, utmSource, accountTypeName }) => {
  const root = createRoot(container);
  root.render(<SalesforceHeaderBanner utmSource={utmSource} accountTypeName={accountTypeName} />);
};
