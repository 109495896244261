import { RequestLayer, Interceptors } from '@jotforminc/request-layer';
import Axios from 'axios';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export const updateUserSettings = (userSetting, value) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/API/user/settings?${userSetting}=${value}`)
      .then(response => {
        if (response.data.responseCode !== 200) {
          console.log(response.data.message);
          reject();
          return;
        }

        resolve();
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  });
};

export const changeLanguage = (lang, url) => {
  Axios.post(`/server.php?action=setCookie&name=language&expire=+1 Month&value=${lang}`)
    .then(response => {
      if (!response.data.success) {
        console.log('Language cannot be changed.');
        return;
      }

      global.location.href = url;
    })
    .catch(error => {
      console.log(error);
    });
};

export const setSurveyOffered = surveyFormId => {
  global.localStorage.setItem(`uxrSurvey-${surveyFormId}`, '1');
};

export const dismissUnreadSupportAnswers = () => {
  Axios.post('/API/unreadSupportAnswers/dismiss')
    .then(response => {
      if (response.data.responseCode !== 200) {
        console.log(response.data.message);
      }
    })
    .catch(error => {
      console.log(error);
    });
};

export const isInHubspotAvoidedList = () => {
  return layer.get('/API/ep/user/hubspot-avoid-list');
};

export const getUserNotifications = () => {
  const navLang = window.navigator.userLanguage || window.navigator.language;
  let url = `/API/user/notifications?navLang=${navLang}`;

  let params = window.location.search;
  if (params) {
    params = params.replace('?', '');
    url = `${url}&${params}`;
  }

  return layer.get(url);
};

export const setUserNotificationStatus = (status, value) => {
  const params = new URLSearchParams({ status });
  if (value) {
    params.append('value', value);
  }
  return layer.post('/API/user/notification-status', params);
};

export const setEpUserSettings = (key, value = 1) => {
  return layer.post('ep/user/settings',
    new URLSearchParams({ key, value }));
};
