/* eslint-disable max-lines */
// eslint-disable-next-line import/no-cycle
import { t } from '@jotforminc/translation';
import {
  IconProductTeamsBasicFilled, IconUsersFilled, IconGearFilled, IconFolderPlusFilled,
  IconTrashFilled, IconDropletFilled, IconPencilFilled, IconPlusSquareFilled, IconShareNodesFilled,
  IconProductFormBuilderColorBorder, IconProductTablesColorBorder, IconProductAppsColorBorder,
  IconProductSignColorBorder, IconProductApprovalsColorBorder, IconProductReportBuilderColorBorder, IconProductBoardsColorBorder
} from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import NoForm from '../assets/noAssignedForms.svg';
import NoKiosks from '../assets/noKiosks.svg';
import NoTasks from '../assets/noTasks.svg';
import NoTables from '../assets/noTables.svg';
import NoReports from '../assets/noReports.svg';
import NoDocuments from '../assets/noDocuments.svg';
import NoApps from '../assets/noApps.svg';
import NoItems from '../assets/noItems.svg';
import NoContacts from '../assets/noContacts.svg';
import NoPages from '../assets/noPages.svg';
import NoBoards from '../assets/noBoards.svg';
import NoAgent from '../assets/noAgent.svg';

import IconAgent from '../assets/iconAgent.svg';

export const ICON_MAP = {
  assigned: NoForm,
  assignedForms: NoForm,
  draft: NoForm,
  portals: window.location.pathname.indexOf('mykiosks') > -1 ? NoKiosks : NoApps,
  reports: NoReports,
  sheets: NoTables,
  documents: NoDocuments,
  myApprovals: NoForm,
  approvals: NoTasks,
  workflows: NoTasks,
  tasks: NoTasks,
  allForms: NoForm,
  forms: NoForm,
  apps: window.location.pathname.indexOf('mykiosks') > -1 ? NoKiosks : NoApps,
  tables: NoTables,
  filledForms: NoForm,
  items: NoItems,
  contacts: NoContacts,
  pages: NoPages,
  assets: NoItems,
  boards: NoBoards,
  agents: NoAgent
};

export const isWorkflowReleased = window?.isWorkflowReleased;
export const FLOW_TEXTS = {
  VIEW_FLOW: isWorkflowReleased ? 'View Workflow' : 'View Approval Flow',
  CREATE_FLOW: isWorkflowReleased ? 'Create Workflow' : 'Create Approval Flow',
  MY_FLOWS: isWorkflowReleased ? 'MY WORKFLOWS' : 'MY APPROVALS',
  ALL_FLOWS: isWorkflowReleased ? 'All Workflows' : 'All Approvals',
  CREATE_FLOW_BUTTON_TEXT: isWorkflowReleased ? 'Create Workflow' : 'Create Approval',
  CREATE_FLOW_T: isWorkflowReleased ? 'Create [1[Workflow]]' : 'Create [1[Approval]]'
};

export const LISTING_TYPES = {
  FORM: 'LISTING@FORM',
  ASSIGNED_FORM: 'LISTING@ASSIGNED_FORM',
  FILLED_FORM: 'LISTING@FILLED_FORM',
  DRAFT: 'LISTING@DRAFT',
  PORTAL: 'LISTING@PORTAL',
  DOCUMENT: 'LISTING@DOCUMENT',
  SIGN: 'LISTING@DOCUMENT',
  REPORT: 'LISTING@REPORT',
  UPLOAD: 'LISTING@UPLOAD',
  SHEET: 'LISTING@SHEET',
  TASK: 'LISTING@TASK',
  WORKFLOW: 'LISTING@TASK',
  TEAMS: 'LISTING@TEAMS',
  TEAM_PAGE: 'LISTING@TEAM_PAGE',
  CONTACTS: 'LISTING@CONTACTS',
  PAGES: 'LISTING@PAGES',
  MIX: 'LISTING@MIX',
  BOARD: 'LISTING@BOARD',
  AGENT: 'LISTING@AGENT'
};

export const LISTING_TYPES_REVERT = {
  [LISTING_TYPES.FORM]: 'form',
  [LISTING_TYPES.SHEET]: 'sheet',
  [LISTING_TYPES.DOCUMENT]: 'sign',
  [LISTING_TYPES.TASK]: 'workflow',
  [LISTING_TYPES.PORTAL]: 'portal',
  [LISTING_TYPES.REPORT]: 'report',
  [LISTING_TYPES.BOARD]: 'board',
  [LISTING_TYPES.PAGES]: 'page',
  [LISTING_TYPES.ASSIGNED_FORM]: 'form',
  [LISTING_TYPES.CONTACTS]: 'contact',
  [LISTING_TYPES.AGENT]: 'ai-agent'
};

export const getListingNames = (isTeamActive = false, forSearch = false) => {
  const prefix = isTeamActive ? 'Team\'s' : 'My';
  return {
    [LISTING_TYPES.REPORT]: `${prefix} Reports`,
    [LISTING_TYPES.SHEET]: `${prefix} Tables`,
    [LISTING_TYPES.TASK]: isWorkflowReleased ? `${prefix} Workflows` : `${prefix} Approvals`,
    [LISTING_TYPES.DOCUMENT]: `${prefix} Sign Documents`,
    [LISTING_TYPES.TEAMS]: `${prefix} Teams`,
    [LISTING_TYPES.FORM]: `${prefix} Forms`,
    [LISTING_TYPES.ASSIGNED_FORM]: 'Forms',
    [LISTING_TYPES.PORTAL]: `${prefix} ${window.location.pathname.indexOf('mykiosks') > -1 ? 'Kiosks' : 'Apps'}`,
    [LISTING_TYPES.TEAM_PAGE]: forSearch ? 'Team\'s Assets' : 'Team',
    [LISTING_TYPES.CONTACTS]: `${prefix} Contacts`,
    [LISTING_TYPES.PAGES]: `${prefix} Pages`,
    [LISTING_TYPES.MIX]: `${prefix} Assets`,
    [LISTING_TYPES.BOARD]: `${prefix} Boards`,
    [LISTING_TYPES.AGENT]: `${prefix} Agents`
  };
};

export const ROUTER_MAP = {
  forms: LISTING_TYPES.FORM,
  assignedForms: LISTING_TYPES.ASSIGNED_FORM,
  apps: LISTING_TYPES.PORTAL,
  reports: LISTING_TYPES.REPORT,
  sheets: LISTING_TYPES.SHEET,
  tasks: LISTING_TYPES.TASK,
  documents: LISTING_TYPES.DOCUMENT,
  drafts: LISTING_TYPES.DRAFT,
  teams: LISTING_TYPES.TEAMS,
  teamPage: LISTING_TYPES.TEAM_PAGE,
  contacts: LISTING_TYPES.CONTACTS,
  pages: LISTING_TYPES.PAGES,
  mix: LISTING_TYPES.MIX,
  boards: LISTING_TYPES.BOARD,
  agents: LISTING_TYPES.AGENT
};

export const LISTING_API_END_POINT_MAP = {
  forms: 'form',
  apps: 'portal',
  sheets: 'sheet',
  reports: 'report',
  documents: 'sign',
  tasks: 'workflow',
  teams: 'teams',
  teamPage: 'teamPage',
  contacts: 'contact',
  pages: 'page',
  mix: 'mix',
  boards: 'board',
  agents: 'agent'
};

export const ASSET_TYPE_MAP = {
  form: LISTING_TYPES.FORM,
  sheet: LISTING_TYPES.SHEET,
  report: LISTING_TYPES.REPORT,
  workflow: LISTING_TYPES.TASK,
  portal: LISTING_TYPES.PORTAL,
  sign: LISTING_TYPES.DOCUMENT,
  contact: LISTING_TYPES.CONTACTS,
  page: LISTING_TYPES.PAGES,
  board: LISTING_TYPES.BOARD,
  'ai-agent': LISTING_TYPES.AGENT
};

export const CLASS_MAP = {
  [LISTING_TYPES.SHEET]: 'sheets',
  [LISTING_TYPES.REPORT]: 'reports',
  [LISTING_TYPES.PORTAL]: 'apps',
  [LISTING_TYPES.TASK]: 'tasks',
  [LISTING_TYPES.DOCUMENT]: 'documents',
  [LISTING_TYPES.FORM]: 'forms',
  [LISTING_TYPES.TEAMS]: 'teams',
  [LISTING_TYPES.TEAM_PAGE]: 'teamPage',
  [LISTING_TYPES.CONTACTS]: 'contacts',
  [LISTING_TYPES.PAGES]: 'pages',
  [LISTING_TYPES.MIX]: 'mix',
  [LISTING_TYPES.BOARD]: 'boards',
  [LISTING_TYPES.AGENT]: 'agents'
};

export const TYPE_MAP = {
  [LISTING_TYPES.SHEET]: 'sheet',
  [LISTING_TYPES.REPORT]: 'report',
  [LISTING_TYPES.TASK]: 'task',
  [LISTING_TYPES.DOCUMENT]: 'document',
  [LISTING_TYPES.FORM]: 'form',
  [LISTING_TYPES.TEAMS]: 'teams',
  [LISTING_TYPES.TEAM_PAGE]: 'teamPage',
  [LISTING_TYPES.CONTACTS]: 'contacts',
  [LISTING_TYPES.PORTAL]: 'app',
  [LISTING_TYPES.PAGES]: 'pages',
  [LISTING_TYPES.BOARD]: 'board',
  [LISTING_TYPES.AGENT]: 'ai-agent'
};

export const ASSET_TYPES = {
  FORM: 'form',
  SHEET: 'sheet',
  PORTAL: 'portal',
  REPORT: 'report',
  WORKFLOW: 'workflow',
  SIGN: 'sign',
  BOARD: 'board',
  PAGE: 'page',
  CONTACT: 'contact',
  AGENT: 'ai-agent'
};

export const FOLDER_TYPES = {
  STATIC: 'static',
  USERNAME: 'username',
  ASSET_FOLDER: 'formFolder',
  BUTTON: 'button',
  TEAM: 'team',
  TEAM_FOLDER: 'teamFolder',
  ALL_TEAM_RESOURCES: 'allTeamResources',
  CREATE_TEAM_BUTTON: 'createTeamButton'
};

export const FOLDER_ITEM_ACTION_TYPES = {
  ADD: 'add',
  REMOVE: 'remove'
};

export const DEFAULT_FOLDER_IDS = {
  CREATE: 'createFolder',
  ASSIGNED: 'assigned',
  CREATE_TEAM: 'createTeam',
  INITIAL_TEAM_FOLDER: 'initialTeamFolder',
  TEAM_FOLDER_GROUP: 'forTeams',
  CREATE_TEAM_FOLDER: 'forCreateTeam',
  TEAM_PAGE_ALL_FOLDER: 'forTeamItem',
  USER_FOLDER_GROUP: 'forUserFolders',
  SHARED_WITH_ME: 'sharedWithMe',
  SHARED_SHEETS: 'sharedSheets',
  SHARED_BOARDS: 'sharedBoards',
  SHARED_REPORTS: 'sharedReports',
  DRAFTS: 'drafts',
  TASKS: 'tasks',
  FILLED_FORMS: 'filledForms',
  MY_SUBMISSIONS: 'mySubmissions',
  FAVORITES: 'favorites',
  ARCHIVE: 'archive',
  TRASH: 'trash',
  TEAMS_TITLE: 'teamsTitle',
  TEAMS_SEPERATOR: 'teamsSeperator',
  MYFORMS_TITLE: 'myformsTitle',
  CONTACTS: 'contacts',
  PAGES: 'pages',
  SHARED_REPORT_SEPERATOR: 'sharedReportSeperator',
  MYAPPROVALS_TITLE: 'myApprovalsTitle',
  MYAPPROVALS_SEPARATOR: 'myApprovalsSeparator'
};

export const ITEM_NO_CONTENT_TITLE = {
  [LISTING_TYPES.FORM]: 'forms',
  [LISTING_TYPES.ASSIGNED_FORM]: 'forms',
  [LISTING_TYPES.PORTAL]: 'apps',
  [LISTING_TYPES.REPORT]: 'reports',
  [LISTING_TYPES.SHEET]: 'tables',
  [LISTING_TYPES.TASK]: isWorkflowReleased ? 'workflows' : 'approvals',
  [LISTING_TYPES.DOCUMENT]: 'documents',
  [LISTING_TYPES.FILLED_FORM]: 'filled forms',
  [LISTING_TYPES.TEAMS]: 'teams',
  [LISTING_TYPES.TEAM_PAGE]: 'assets',
  [LISTING_TYPES.CONTACTS]: 'contacts',
  [LISTING_TYPES.PAGES]: 'pages',
  [LISTING_TYPES.MIX]: 'assets',
  [LISTING_TYPES.BOARD]: 'boards',
  [LISTING_TYPES.AGENT]: 'agents'
};

export const CREATE_BUTTON_TITLES = {
  [LISTING_TYPES.ASSIGNED_FORM]: 'Create Form',
  [LISTING_TYPES.FORM]: 'Create Form',
  [LISTING_TYPES.PORTAL]: window.location.pathname.indexOf('mykiosks') > -1 ? 'Create Kiosk' : 'Create App',
  [LISTING_TYPES.REPORT]: 'Create Report',
  [LISTING_TYPES.SHEET]: 'Create Table',
  [LISTING_TYPES.TASK]: FLOW_TEXTS.CREATE_FLOW_BUTTON_TEXT,
  [LISTING_TYPES.DOCUMENT]: 'Create Sign Document',
  [LISTING_TYPES.TEAMS]: 'Create Team',
  [LISTING_TYPES.TEAM_PAGE]: '+ Create',
  [LISTING_TYPES.CONTACTS]: 'Create Contact',
  [LISTING_TYPES.PAGES]: 'Create Page',
  [LISTING_TYPES.MIX]: '+ Create',
  [LISTING_TYPES.BOARD]: 'Create Board',
  [LISTING_TYPES.AGENT]: 'Create Agent'
};

export const COOKIE_KEYS = {
  LAST_FOLDER: currentPage => `${currentPage.replace('LISTING@', '')}_last_folder`
};

export const TEAM_ENABLED_RESOURCES = [
  LISTING_TYPES.FORM,
  LISTING_TYPES.SHEET,
  LISTING_TYPES.REPORT,
  LISTING_TYPES.PORTAL,
  LISTING_TYPES.TASK,
  LISTING_TYPES.DOCUMENT,
  LISTING_TYPES.BOARD
];

export const MIX_ENABLED_RESOURCES = [
  LISTING_TYPES.MIX
];

export const MOVE_TO_TEAM_DISABLED_RESOURCES = [
  LISTING_TYPES.BOARD
];

export const MIX_FILTER_ENABLED_LISTING_TYPES = [LISTING_TYPES.TEAM_PAGE, LISTING_TYPES.MIX];

export const MOVE_TO_TEAM_ENABLED_RESOURCES = [
  ...TEAM_ENABLED_RESOURCES,
  LISTING_TYPES.AGENT
];

export const TEAM_ENABLED_CREATION_RESOURCES = [
  ...TEAM_ENABLED_RESOURCES,
  LISTING_TYPES.AGENT
];

export const TEAM_SECTION_ENABLED_PAGES = [...TEAM_ENABLED_RESOURCES, LISTING_TYPES.MIX, LISTING_TYPES.AGENT];

export const ASSIGNED_FORM_ENABLED_PAGES = [LISTING_TYPES.ASSIGNED_FORM, LISTING_TYPES.FORM];

export const BULK_FAVORITE_ENABLED_ASSET_TYPES = [LISTING_TYPES.SHEET, LISTING_TYPES.TASK, LISTING_TYPES.PORTAL, LISTING_TYPES.AGENT];

export const APP_PICKER_LISTING_MAPPINGS = {
  myForms: LISTING_TYPES.FORM,
  myTables: LISTING_TYPES.SHEET,
  myReports: LISTING_TYPES.REPORT,
  myApps: LISTING_TYPES.PORTAL,
  myTasks: LISTING_TYPES.TASK,
  myWorkspace: LISTING_TYPES.MIX,
  myDocuments: LISTING_TYPES.DOCUMENT,
  myPages: LISTING_TYPES.PAGES,
  myBoards: LISTING_TYPES.BOARD,
  myAgents: LISTING_TYPES.AGENT
};

export const ALL_ASSETS_ID = {
  [LISTING_TYPES.FORM]: 'allForms',
  [LISTING_TYPES.SHEET]: 'sheets',
  [LISTING_TYPES.TASK]: 'myApprovals',
  [LISTING_TYPES.DOCUMENT]: 'documents',
  [LISTING_TYPES.REPORT]: 'reports',
  [LISTING_TYPES.PORTAL]: 'portals',
  [LISTING_TYPES.MIX]: 'mix',
  [LISTING_TYPES.BOARD]: 'boards',
  [LISTING_TYPES.AGENT]: 'myAgents'
};

export const ASSETS_FOLDER_LISTINGS = [
  LISTING_TYPES.SHEET,
  LISTING_TYPES.REPORT,
  LISTING_TYPES.PORTAL,
  LISTING_TYPES.DOCUMENT,
  LISTING_TYPES.TASK,
  LISTING_TYPES.BOARD
];

export const BULK_FAVORITE_ENABLED_FOLDERS = [ALL_ASSETS_ID[LISTING_TYPES.SHEET], ALL_ASSETS_ID[LISTING_TYPES.TASK], ALL_ASSETS_ID[LISTING_TYPES.PORTAL], ALL_ASSETS_ID[LISTING_TYPES.AGENT]];

// eslint-disable-next-line complexity
export const getDefaultFolders = (currentPage, isTeamsActive, isTeamMember, isFormUser, isAssetsFolderActive) => {
  if (!Object.values(LISTING_TYPES).includes(currentPage)) return;

  const favoritesButton = {
    id: DEFAULT_FOLDER_IDS.FAVORITES, text: 'Favorites', className: 'forFavorites', hasContextMenu: false, isDroppable: false, isFocussable: true
  };
  const archiveButton = {
    id: DEFAULT_FOLDER_IDS.ARCHIVE, text: 'Archive', className: 'forArchive', hasContextMenu: false, isDroppable: false, isFocussable: true
  };
  const trashButton = {
    id: DEFAULT_FOLDER_IDS.TRASH, text: 'Trash', className: 'forTrash', hasContextMenu: false, isDroppable: false, isFocussable: true
  };

  const teamsFolders = isTeamsActive && TEAM_SECTION_ENABLED_PAGES.includes(currentPage) && !isFormUser ? [
    {
      id: DEFAULT_FOLDER_IDS.TEAMS_TITLE, text: 'MY TEAMS', className: `${DEFAULT_FOLDER_IDS.TEAM_FOLDER_GROUP} isTitleFolder isDisabled`, isDroppable: false, hasContextMenu: false
    },
    {
      id: DEFAULT_FOLDER_IDS.CREATE_TEAM,
      text: t('Create team'),
      className: 'forCreateFolder',
      hasContextMenu: false,
      folderType: FOLDER_TYPES.CREATE_TEAM_BUTTON,
      isDroppable: false,
      canDrag: false
    },
    {
      id: DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
    }
  ] : [];

  const createFolderList = isAssetsFolderActive ? [
    {
      id: DEFAULT_FOLDER_IDS.CREATE, text: 'Create folder', className: 'forCreateFolder', hasContextMenu: false, folderType: FOLDER_TYPES.BUTTON, isDroppable: false, canDrag: false
    }
  ] : [];

  switch (currentPage) {
    case LISTING_TYPES.FORM:
      return [
        {
          id: DEFAULT_FOLDER_IDS.MYFORMS_TITLE, text: 'MY FORMS', className: 'forForms isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.CREATE, text: 'Create folder', className: 'forCreateFolder', hasContextMenu: false, folderType: FOLDER_TYPES.BUTTON, isDroppable: false, canDrag: false
        },
        {
          id: 'myFormsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        },
        ...teamsFolders,
        {
          id: DEFAULT_FOLDER_IDS.SHARED_WITH_ME, text: 'SHARED WITH ME', className: 'forForms isTitleFolder', hasContextMenu: false, isDroppable: false, isFocussable: true
        },
        {
          id: 'sharedWithMeSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        },
        {
          id: DEFAULT_FOLDER_IDS.ASSIGNED, text: 'ASSIGNED FORMS', className: 'forForms isTitleFolder', hasContextMenu: false, isDroppable: false, isFocussable: true
        },
        {
          id: 'assignedFormsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        },
        {
          id: DEFAULT_FOLDER_IDS.DRAFTS, isDefault: true, text: 'My Drafts', className: 'forMyDrafts isTitleFolder', canDrag: false, hasContextMenu: false, isDroppable: false, isFocussable: true
        },
        {
          id: 'draftsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        },
        // {
        //   id: DEFAULT_FOLDER_IDS.MY_SUBMISSIONS, isDefault: true, text: 'My Submissions', className: 'forMySubmissions isTitleFolder', canDrag: false, hasContextMenu: false
        // },
        // {
        //   id: 'mySubmissionsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        // },
        {
          id: DEFAULT_FOLDER_IDS.FILLED_FORMS, isDefault: false, text: 'Filled Forms', className: 'forFilledForms isTitleFolder', canDrag: false, hasContextMenu: false
        },
        {
          id: 'filledFormsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', hasContextMenu: false, isDroppable: false
        },
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.ASSIGNED_FORM:
      return [
        {
          id: DEFAULT_FOLDER_IDS.ASSIGNED, isDefault: true, text: 'Assigned Forms', className: 'forAssignedForms isTitleFolder', canDrag: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.DRAFTS, isDefault: true, text: 'My Drafts', className: 'forMyDrafts isTitleFolder', canDrag: false, hasContextMenu: false, isFocussable: true
        },
        {
          id: DEFAULT_FOLDER_IDS.SHARED_WITH_ME, isDefault: true, text: 'Shared with me', className: 'forSharedWithMe isTitleFolder', canDrag: false, hasContextMenu: false, isFocussable: true
        },
        // {
        //   id: DEFAULT_FOLDER_IDS.MY_SUBMISSIONS, isDefault: true, text: 'My Submissions', className: 'forMySubmissions isTitleFolder', canDrag: false, hasContextMenu: false
        // },
        {
          id: DEFAULT_FOLDER_IDS.FILLED_FORMS, isDefault: false, text: 'Filled Forms', className: 'forFilledForms isTitleFolder', canDrag: false, hasContextMenu: false
        }
      ];
    case LISTING_TYPES.PORTAL:
      const base = [
        {
          id: 'myPortalsTitle', text: 'MY APPS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.PORTAL],
          isDefault: true,
          text: window.location.pathname.indexOf('mykiosks') > -1 ? 'All Kiosks' : 'All Apps',
          className: 'forMyApps',
          canDragItems: true,
          isFocussable: true,
          isDroppable: false,
          hasContextMenu: false
        },
        ...createFolderList,
        ...teamsFolders,
        {
          id: 'portalsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        favoritesButton,
        archiveButton,
        trashButton
      ];
      return base;
    case LISTING_TYPES.CONTACTS:
      return [
        {
          id: 'myContactsTitle', text: 'MY CONTACTS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.CONTACTS, isDefault: true, text: 'All Contacts', className: 'forMyContacts', isFocussable: true
        },
        favoritesButton,
        trashButton
      ];
    case LISTING_TYPES.PAGES:
      return [
        {
          id: 'myPagesTitle', text: 'MY PAGES', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.PAGES, isDefault: true, text: 'All Pages', className: 'forMyPages', isFocussable: true
        },
        {
          id: 'pagesSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        favoritesButton,
        trashButton
      ];
    case LISTING_TYPES.REPORT:
      return [
        {
          id: 'myReportsTitle', text: 'MY REPORTS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.REPORT],
          isDefault: true,
          text: 'All Reports',
          className: 'forMyReports',
          isFocussable: true,
          isDroppable: false,
          hasContextMenu: false,
          ...(isAssetsFolderActive ? { canDragItems: true } : {})
        },
        ...createFolderList,
        ...teamsFolders,
        {
          id: 'reportsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.SHARED_REPORTS, isDefault: true, text: 'SHARED WITH ME', className: 'isTitleFolder', isFocussable: true, isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.SHARED_REPORT_SEPERATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.SHEET:
      if (isFormUser) {
        return [
          {
            id: DEFAULT_FOLDER_IDS.SHARED_SHEETS, isDefault: true, text: 'SHARED WITH ME', className: 'isTitleFolder', hasContextMenu: false, isDroppable: false, isFocussable: true
          }
        ];
      }
      return [
        {
          id: 'mySheetsTitle', text: 'MY TABLES', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.SHEET], isDefault: true, text: 'All Tables', className: 'forMySheets', canDragItems: true, isFocussable: true, isDroppable: false, hasContextMenu: false
        },
        ...createFolderList,
        ...teamsFolders,
        {
          id: 'sheetSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.SHARED_SHEETS, text: 'SHARED WITH ME', className: 'isTitleFolder', hasContextMenu: false, isDroppable: false, isFocussable: true
        },
        {
          id: 'sharedSheetSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.BOARD:
      return [
        {
          id: 'myBoardsTitle', text: 'MY BOARDS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.BOARD], isDefault: true, text: 'All Boards', className: 'forMyBoards', canDragItems: true, isFocussable: true, isDroppable: false, hasContextMenu: false
        },
        ...createFolderList,
        ...teamsFolders,
        {
          id: 'boardSeparator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.SHARED_BOARDS, text: 'SHARED WITH ME', className: 'isTitleFolder', hasContextMenu: false, isDroppable: false, isFocussable: true
        },
        {
          id: 'sharedBoardSeparator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.TASK:
      if (isFormUser) {
        return [
          {
            id: DEFAULT_FOLDER_IDS.TASKS, isDefault: true, text: 'Assigned to me', className: 'isTitleFolder', isFocussable: true, isDroppable: false, hasContextMenu: false
          }
        ];
      }
      return [
        {
          id: DEFAULT_FOLDER_IDS.MYAPPROVALS_TITLE, text: FLOW_TEXTS.MY_FLOWS, className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.TASK], text: FLOW_TEXTS.ALL_FLOWS, className: 'forMyTasks', canDragItems: true, isFocussable: true, isDroppable: false, hasContextMenu: false
        },
        ...createFolderList,
        {
          id: DEFAULT_FOLDER_IDS.MYAPPROVALS_SEPARATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.TASKS, isDefault: true, text: 'Assigned to me', className: 'isTitleFolder', isFocussable: true, isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.MYAPPROVALS_SEPARATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        ...teamsFolders,
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.DOCUMENT:
      return [
        {
          id: 'myDocumentsTitle', text: 'MY SIGN DOCUMENTS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.DOCUMENT],
          isDefault: true,
          text: 'All Sign Documents',
          className: 'forMyDocuments',
          canDragItems: true,
          isFocussable: true,
          isDroppable: false,
          hasContextMenu: false
        },
        ...createFolderList,
        {
          id: 'documentsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        ...teamsFolders,
        favoritesButton,
        trashButton
      ];
    case LISTING_TYPES.AGENT:
      if (isFormUser) {
        return [
          {
            id: 'myAgentsTitle', text: 'MY AGENTS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
          },
          {
            id: ALL_ASSETS_ID[LISTING_TYPES.AGENT],
            isDefault: true,
            text: 'All Agents',
            className: 'forMyAgents',
            canDragItems: true,
            isFocussable: true,
            isDroppable: false,
            hasContextMenu: false
          }
        ];
      }
      return [
        {
          id: 'myAgentsTitle', text: 'MY AGENTS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.AGENT],
          isDefault: true,
          text: 'All Agents',
          className: 'forMyAgents',
          canDragItems: true,
          isFocussable: true,
          isDroppable: false,
          hasContextMenu: false
        },
        ...createFolderList,
        {
          id: 'agentsSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        ...teamsFolders,
        favoritesButton,
        archiveButton,
        trashButton
      ];
    case LISTING_TYPES.TEAM_PAGE:
      return [
        {
          id: DEFAULT_FOLDER_IDS.INITIAL_TEAM_FOLDER, isDefault: true
        },
        {
          id: 'createFolderSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        {
          id: DEFAULT_FOLDER_IDS.CREATE, text: 'Create folder', className: 'forCreateFolder', hasContextMenu: false, folderType: FOLDER_TYPES.BUTTON, isDroppable: false, canDrag: false
        },
        {
          id: DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        ...(isTeamMember ? [
          favoritesButton,
          archiveButton,
          trashButton
        ] : [])
      ];
    case LISTING_TYPES.TEAMS:
      return [
        {
          id: 'myTeamsTitle', text: 'MY TEAMS', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: 'teams', isDefault: true, text: 'All Teams', className: 'forMyTeams'
        },
        {
          id: DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR, text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        }
      ];
    case LISTING_TYPES.MIX:
      return [
        {
          id: 'mixTitle', text: 'MY WORKSPACE', className: 'isTitleFolder isDisabled', isDroppable: false, hasContextMenu: false
        },
        {
          id: ALL_ASSETS_ID[LISTING_TYPES.MIX],
          isDefault: true,
          text: 'All Assets',
          className: 'forMyAssets',
          canDragItems: true,
          isFocussable: true,
          isDroppable: false,
          hasContextMenu: false,
          customIconProps: {
            FolderIconComponent: IconProductTeamsBasicFilled,
            hasCustomIcon: true
          },
          hasIcon: true
        },
        {
          id: DEFAULT_FOLDER_IDS.CREATE, text: 'Create folder', className: 'forCreateFolder', hasContextMenu: false, folderType: FOLDER_TYPES.BUTTON, isDroppable: false, canDrag: false
        },
        {
          id: 'mixSeperator', text: null, className: 'isSeperator', itemType: 'listingSeperator', isDroppable: false, hasContextMenu: false
        },
        ...teamsFolders,
        favoritesButton,
        archiveButton,
        trashButton
      ];
    default:
      return [];
  }
};

export const getSearchables = assetFilterType => isTeamActive => {
  if (!Object.values(LISTING_TYPES).includes(assetFilterType)) return;
  return `Search ${getListingNames(isTeamActive, true)[assetFilterType] || ''}`;
};

export const getCommonSortingOptions = () => ([
  { text: 'Title [a-z]', value: 'title_az' },
  { text: 'Title [z-a]', value: 'title_za' },
  { text: 'Creation Date', value: 'created_at' },
  { text: 'Last Edit', value: 'updated_at', isDefault: true }
]);

export const ASSET_FILTER_OPTIONS_WITHOUT_ALL = [
  {
    text: 'Forms',
    ItemIcon: IconProductFormBuilderColorBorder,
    value: LISTING_TYPES.FORM
  },
  {
    text: 'Tables',
    ItemIcon: IconProductTablesColorBorder,
    value: LISTING_TYPES.SHEET
  },
  {
    text: 'Apps',
    ItemIcon: IconProductAppsColorBorder,
    value: LISTING_TYPES.PORTAL
  },
  {
    text: 'Sign Documents',
    ItemIcon: IconProductSignColorBorder,
    value: LISTING_TYPES.DOCUMENT,
    checkVisibility: ({ user }) => {
      return !isEnterprise() || user?.allowSign;
    }
  },
  {
    text: isWorkflowReleased ? 'Workflows' : 'Approvals',
    ItemIcon: IconProductApprovalsColorBorder,
    value: LISTING_TYPES.TASK
  },
  {
    text: 'Reports',
    ItemIcon: IconProductReportBuilderColorBorder,
    value: LISTING_TYPES.REPORT
  },
  {
    text: 'AI Agents',
    ItemIcon: IconAgent,
    value: LISTING_TYPES.AGENT,
    checkVisibility: ({ canAccessMyAgentsPage }) => {
      return !!canAccessMyAgentsPage;
    },
    isDefaultlyHidden: true
  },
  {
    text: 'Boards',
    ItemIcon: IconProductBoardsColorBorder,
    value: LISTING_TYPES.BOARD,
    checkVisibility: ({ user }) => {
      return !!user?.allowBoards;
    },
    isDefaultlyHidden: true
  }
];

// eslint-disable-next-line complexity
export const getSortingOptions = (currentPage, assetFilterType, selectedFolder) => {
  if (currentPage === LISTING_TYPES.MIX) {
    return [
      { text: 'Title [a-z]', value: 'title_az' },
      { text: 'Title [z-a]', value: 'title_za' },
      { text: 'Creation Date', value: 'created_at', isDefault: true },
      { text: 'Last Edit', value: 'updated_at' },
      ...(window.location.search === '?sortingByLastSubmission=1' ? [{ text: 'Last Submission', value: 'last_submission' }] : [])
    ];
  }

  // @Berkay we can remove this "exception" after you resolve: // This is just a folder not listing type find smth else.
  const isMyApprovals = [ALL_ASSETS_ID[LISTING_TYPES.TASK], LISTING_TYPES.TASK].indexOf(currentPage) > -1 || assetFilterType === LISTING_TYPES.TASK;
  if (isMyApprovals) {
    return [
      {
        key: 'created_at-desc',
        text: 'Creation Date',
        value: 'created_at,DESC',
        isDefault: true
      },
      { key: 'title-asc', text: 'Title [a-z]', value: 'title,ASC' },
      { key: 'title-desc', text: 'Title [z-a]', value: 'title,DESC' },
      { text: 'Last Edit', value: 'updated_at' }
    ];
  }
  if (!Object.values(LISTING_TYPES).includes(assetFilterType)) return;

  switch (assetFilterType) {
    case LISTING_TYPES.REPORT:
      return [
        {
          text: 'Form', value: 'form_id', isDefault: true, view: 'grouped'
        },
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at' },
        { text: 'Last Edit', value: 'updated_at' },
        { text: 'Submission Count', value: 'form_count', view: 'grouped' },
        { text: 'Last Submission', value: 'last_submission', view: 'grouped' }
      ];
    case LISTING_TYPES.SHEET:
      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_SHEETS) {
        return [
          { text: 'Title [a-z]', value: 'title_az' },
          { text: 'Title [z-a]', value: 'title_za' },
          { text: 'Creation Date', value: 'created_at' },
          { text: 'Last Edit', value: 'updated_at', isDefault: true }
        ];
      }
      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at' },
        { text: 'Last Edit', value: 'updated_at', isDefault: true },
        { text: 'Unread', value: 'unread' },
        { text: 'Total Entry', value: 'total_entry' },
        { text: 'Last Entry', value: 'last_entry' }
      ];
    case LISTING_TYPES.TASK:
      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Assigned Date', value: 'assigned_at', isDefault: true },
        { text: 'Waiting Approval', value: 'waiting_count' },
        { text: 'Last Approval', value: 'last_assigned' }
      ];
    case LISTING_TYPES.DOCUMENT:
      return [
        { text: 'Last Edit', value: 'modificationTime' },
        { text: 'Creation Date', value: 'creationTime', isDefault: true },
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' }
      ];
    case LISTING_TYPES.PORTAL:
      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at', isDefault: true },
        { text: 'Last Edit', value: 'updated_at' },
        { text: 'Unread Submission Count', value: 'unread_portal_submission' },
        { text: 'Submission Count', value: 'portal_submission' },
        { text: 'Last Submission Date', value: 'last_portal_submission' },
        { text: 'Form Count', value: 'form_count' }
      ];
    case LISTING_TYPES.ASSIGNED_FORM:
    case LISTING_TYPES.FORM:
      if ([DEFAULT_FOLDER_IDS.ASSIGNED, DEFAULT_FOLDER_IDS.DRAFTS].indexOf(selectedFolder) > -1) {
        return [
          { text: 'Title [a-z]', value: 'title_az' },
          { text: 'Title [z-a]', value: 'title_za' }
        ];
      }
      if (selectedFolder === DEFAULT_FOLDER_IDS.MY_SUBMISSIONS) {
        return [
          { text: 'Title [a-z]', value: 'title-down' },
          { text: 'Title [z-a]', value: 'title-up' },
          { text: 'Submission Date (old to new)', value: 'created_at-down' },
          { text: 'Submission Date (new to old)', value: 'created_at-up' },
          {
            text: 'Form', value: 'form_id', isDefault: true, view: 'grouped'
          }
        ];
      }
      if (selectedFolder === DEFAULT_FOLDER_IDS.FILLED_FORMS) {
        return [
          { text: 'Title [a-z]', value: 'title_az' },
          { text: 'Title [z-a]', value: 'title_za' },
          { text: 'Submission Date', value: 'submission_date', isDefault: true }
        ];
      }

      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at' },
        { text: 'Last Edit', value: 'updated_at', isDefault: true },
        { text: 'Submission Count', value: 'count' },
        { text: 'Unread', value: 'new' },
        { text: 'Last Submission', value: 'last_submission' }
      ];
    case LISTING_TYPES.MIX:
    case LISTING_TYPES.TEAM_PAGE:
      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at', isDefault: true },
        { text: 'Last Edit', value: 'updated_at' },
        ...(window.location.search === '?sortingByLastSubmission=1' ? [{ text: 'Last Submission', value: 'last_submission' }] : [])
      ];
    case LISTING_TYPES.CONTACTS:
      return [
        { text: 'Creation Date', value: 'created_at', isDefault: true },
        { text: 'Last Submission', value: 'last_submission' },
        { text: 'Submission Count', value: 'count' },
        { text: 'Name [a-z]', value: 'title_az' },
        { text: 'Name [z-a]', value: 'title_za' }
      ];
    case LISTING_TYPES.PAGES:
      return [
        { text: 'Creation Date', value: 'created_at', isDefault: true },
        { text: 'Name [a-z]', value: 'title_az' },
        { text: 'Name [z-a]', value: 'title_za' }
      ];
    case LISTING_TYPES.BOARD:
      return [
        { text: 'Title [A-Z]', value: 'title_az' },
        { text: 'Title [Z-A]', value: 'title_za' },
        { text: 'Date Created', value: 'created_at' },
        { text: 'Last Updated', value: 'updated_at', isDefault: true },
        { text: 'Form Count', value: 'form_count' }
      ];
    case LISTING_TYPES.AGENT:
      return [
        { text: 'Title [a-z]', value: 'title_az' },
        { text: 'Title [z-a]', value: 'title_za' },
        { text: 'Creation Date', value: 'created_at' },
        { text: 'Last Edit', value: 'updated_at' },
        { text: 'Last Conversation', value: 'last_conversation', isDefault: true }
      ];
    default:
      return [];
  }
};

export const getDefaultOrderByValue = (currentPage = '', assetFilterType = '', selectedFolder = '') => {
  const sortingOptions = getSortingOptions(currentPage, assetFilterType, selectedFolder) || [];
  const { value = '' } = sortingOptions?.find(opt => !!opt.isDefault) || {};
  return value;
};

export const LIST_FETCH_LIMIT = 50;

export const ROLENAMES = {
  readOnly: 'Read Only',
  collaborator: 'Collaborator',
  editor: 'Editor'
};

// * Some roles also have multiple roles e.x: team_admin also have rest of roles too but the role 'team_admin' is unique for admin role.
export const TEAM_USER_ROLES = {
  team_admin: 'team_admin',
  data_collaborator: 'data_collaborator',
  data_viewer: 'data_read_only',
  creator: 'form_collaborator'
};

export const PLACEHOLDERS = {
  APP: {
    APP_NAME: 'New App'
  }
};

export const FOLDER_LIMIT_FEATURE_DATE = '2023-12-11 00:00:00';
export const NEW_USER_MIXED_LISTING_TEST_LIMIT_FEATURE_DATE = '2024-11-14 00:00:00';

export const FEATURE_LIST = {
  LIMIT_WARNING: 'LIMIT_WARNING',
  WORKFLOW_USE_TEMPLATE: 'WORKFLOW_USE_TEMPLATE',
  JOTFORM_TEAMS: 'JOTFORM_TEAMS',
  APP_BUILD_STORE: 'APP_BUILD_STORE',
  APP_BUILD_STORE_LANDING: 'APP_BUILD_STORE_LANDING',
  APP_BUILD_DONATION: 'APP_BUILD_DONATION',
  APP_BUILD_DONATION_LANDING: 'APP_BUILD_DONATION_LANDING',
  HIPAA_WARNING: 'HIPAA_WARNING',
  PREFILL: 'PREFILL',
  APPS: 'APPS',
  WORKFLOW: 'WORKFLOW',
  GATEWAY_WARNING: 'GATEWAY_WARNING',
  ASSETS_FOLDER_SUPPORT: 'ASSETS_FOLDER_SUPPORT',
  SHARED_WITH_ME_V2: 'SHARED_WITH_ME_V2',
  COMMON_SORT_MENU: 'COMMON_SORT_MENU',
  WORKFLOW_RELEASED: 'WORKFLOW_RELEASED'
};

export const filterKeys = {
  whiteList: ['text', 'answer'],
  blackList: [{ order: ['8'] }]
};

export const NAMING_MAP = {
  assigned: 'assigned Forms',
  assignedForms: 'assigned Forms',
  draft: 'draft Forms',
  portals: window.location.pathname.indexOf('mykiosks') > -1 ? 'kiosks' : 'apps',
  reports: 'reports',
  sheets: 'tables',
  documents: 'documents',
  myApprovals: isWorkflowReleased ? 'workflows' : 'approvals',
  tasks: isWorkflowReleased ? 'workflows' : 'approvals',
  allForms: 'forms',
  forms: 'forms and any submissions for those forms',
  apps: 'apps',
  portal: 'app',
  sheet: 'table',
  workflow: isWorkflowReleased ? 'workflow' : 'approval',
  shared: 'shared Forms',
  filledForms: 'filled forms',
  teamPage: 'asset',
  contacts: 'contacts',
  pages: 'pages',
  sign: 'sign document',
  mix: 'asset',
  boards: 'boards',
  agent: 'agent',
  agents: 'agents'
};

export const MOVE_TO_USER_PRODUCT_NAMES = {
  workflow: isWorkflowReleased ? 'workflows' : 'approvals'
};

export const DELETE_NAMING_MAP = {
  [LISTING_TYPES.SHEET]: 'table',
  [LISTING_TYPES.REPORT]: 'report',
  [LISTING_TYPES.PORTAL]: 'app',
  [LISTING_TYPES.TEAM_PAGE]: 'item',
  [LISTING_TYPES.CONTACTS]: 'contact',
  [LISTING_TYPES.TASK]: isWorkflowReleased ? 'workflows' : 'approval',
  [LISTING_TYPES.PAGES]: 'page',
  [LISTING_TYPES.MIX]: 'item',
  [LISTING_TYPES.BOARD]: 'board',
  [LISTING_TYPES.AGENT]: 'agent'
};

export const ITEM_NAMING_MAP = {
  [LISTING_TYPES.SHEET]: 'a table',
  [LISTING_TYPES.REPORT]: 'a report',
  [LISTING_TYPES.PORTAL]: 'an app',
  [LISTING_TYPES.FORM]: 'a form',
  [LISTING_TYPES.DOCUMENT]: 'a sign document',
  [LISTING_TYPES.TASK]: isWorkflowReleased ? 'a workflow' : 'an approval',
  [LISTING_TYPES.TEAM_PAGE]: 'an item',
  [LISTING_TYPES.CONTACTS]: 'a contact',
  [LISTING_TYPES.PAGES]: 'a page',
  [LISTING_TYPES.MIX]: 'an item',
  [LISTING_TYPES.BOARD]: 'a board',
  [LISTING_TYPES.AGENT]: 'an agent'
};

export const NO_CONTENT_NAMING = {
  favorites: 'favorited',
  trash: 'deleted',
  archive: 'archived',
  sharedWithMe: 'shared',
  drafts: 'draft',
  assigned: 'assigned',
  sharedSheets: 'shared',
  sharedBoards: 'shared',
  sharedReports: 'shared',
  tasks: 'assigned',
  filledForms: 'filled',
  contacts: 'contact',
  pages: 'page'
};

export const FOLDER_PREDEFINED_COLOR_LIST = ['#F09E39', '#FAE4A4', '#F6C552', '#F19A89', '#EA678C', '#E85339', '#A8E2C1', '#499A5F', '#4C96B0', '#4E7CDE', '#A9C5FA', '#553B8B', '#545360', '#D9D7D8'];

export const getTeamsSubfolderEnabledListings = isAssetsFolderActive => ([
  LISTING_TYPES.FORM,
  LISTING_TYPES.TEAM_PAGE,
  LISTING_TYPES.MIX,
  ...isAssetsFolderActive ? ASSETS_FOLDER_LISTINGS : []
]);

export const ASSET_CREATION_MENU_ITEM = {
  [LISTING_TYPES.FORM]: {
    id: 'NEW_FORM',
    text: t('Add New Form'),
    key: 'newForm',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.SHEET]: {
    id: 'NEW_ASSET',
    text: t('Add New Table'),
    key: 'newSheet',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.REPORT]: {
    id: 'NEW_ASSET',
    text: t('Add New Report'),
    key: 'newReport',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.PORTAL]: {
    id: 'NEW_ASSET',
    text: t('Add New App'),
    key: 'newPortal',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.TASK]: {
    id: 'NEW_ASSET',
    text: t(isWorkflowReleased ? 'Add New Workflow' : 'Add New Approval'),
    key: 'newTask',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.DOCUMENT]: {
    id: 'NEW_ASSET',
    text: t('Add New Sign Document'),
    key: 'newSign',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.TEAM_PAGE]: {
    id: 'NEW_ASSET',
    text: t('Add New Asset'),
    key: 'newTeamAsset',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.MIX]: {
    id: 'NEW_ASSET',
    text: t('Add New Asset'),
    key: 'newAsset',
    itemIcon: IconPlusSquareFilled
  },
  [LISTING_TYPES.BOARD]: {
    id: 'NEW_ASSET',
    text: t('Add New Board'),
    key: 'newBoard',
    itemIcon: IconPlusSquareFilled
  }
};

export const teamFolderContextMenuItems = [
  {
    id: 'CHANGE_FOLDER_NAME',
    text: t('Change Name'),
    key: 'changeTeamFolderName',
    itemIcon: IconPencilFilled
  },
  {
    id: 'ADD_FOLDER',
    text: t('Create Subfolder'),
    key: 'createTeamSubfolder',
    itemIcon: IconFolderPlusFilled
  },
  {
    id: 'CHANGE_COLOR',
    text: t('Change Color'),
    itemIcon: IconDropletFilled,
    type: 'COLOR',
    key: 'changeTeamFolderColor',
    palette: FOLDER_PREDEFINED_COLOR_LIST
  },
  {
    id: 'DELETE_FOLDER',
    text: t('Delete'),
    key: 'changeTeamFolderDelete',
    itemIcon: IconTrashFilled
  }
];

const folderContextMenuItems = [
  {
    id: 'CHANGE_FOLDER_NAME',
    text: t('Change Name'),
    key: 'changeFolderName',
    itemIcon: IconPencilFilled
  },
  {
    id: 'ADD_FOLDER',
    text: t('Create Subfolder'),
    key: 'createSubfolder',
    itemIcon: IconFolderPlusFilled
  },
  {
    id: 'SHARE_FOLDER',
    text: t('Share Folder'),
    key: 'shareFolder',
    itemIcon: IconShareNodesFilled
  },
  {
    id: 'CHANGE_COLOR',
    text: t('Change Color'),
    itemIcon: IconDropletFilled,
    type: 'COLOR',
    key: 'changeFolderColor',
    palette: FOLDER_PREDEFINED_COLOR_LIST
  },
  {
    id: 'DELETE_FOLDER',
    text: t('Delete'),
    key: 'deleteFolder',
    itemIcon: IconTrashFilled
  }
];

export const getFolderContextMenuItems = isAssetsFolderActive => ({
  [LISTING_TYPES.FORM]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.FORM],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.SHEET]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.SHEET],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.REPORT]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.REPORT],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.PORTAL]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.PORTAL],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.TASK]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.TASK],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.DOCUMENT]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.DOCUMENT],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.CONTACTS]: [
    {
      id: 'NEW_CONTACTS',
      text: t('CREATE NEW CONTACT'),
      key: 'createNewContact'
    }
  ],
  [LISTING_TYPES.MIX]: [
    ASSET_CREATION_MENU_ITEM[isAssetsFolderActive ? LISTING_TYPES.MIX : LISTING_TYPES.FORM],
    ...folderContextMenuItems
  ],
  [LISTING_TYPES.TEAM_PAGE]: [
    {
      id: 'GO_TO_TEAM_WORKSPACE',
      text: t('Team Workspace'),
      key: 'goToTeamWorkspace',
      itemIcon: IconProductTeamsBasicFilled
    },
    {
      id: 'MANAGE_MEMBERS',
      text: t('Manage Members'),
      key: 'manageTeamMembers',
      itemIcon: IconUsersFilled
    },
    {
      id: 'GO_TO_TEAM_SETTINGS',
      text: t('Team Settings'),
      key: 'goToTeamSettings',
      itemIcon: IconGearFilled
    },
    {
      id: 'ADD_FOLDER',
      text: t('Add Folder'),
      itemIcon: IconFolderPlusFilled,
      checkListingType: true,
      key: 'addTeamFolder',
      visibleListings: getTeamsSubfolderEnabledListings(isAssetsFolderActive)
    }
  ],
  [LISTING_TYPES.BOARD]: [
    ASSET_CREATION_MENU_ITEM[LISTING_TYPES.BOARD],
    ...folderContextMenuItems
  ]
});

export const FOLDER_DROP_POSITIONS = {
  BEFORE: 0,
  AFTER: 1,
  OVER: 2
};

export const MODAL_TYPES = {
  FORM_COPILOT_CTA_MODAL: 'FORM_COPILOT_CTA_MODAL',
  EXPERIENCE_HEALTHCARE_INFO: 'EXPERIENCE_HEALTHCARE_INFO',
  EXPERIENCE_ANNOUNCING_LIGHTBOX: 'EXPERIENCE_ANNOUNCING_LIGHTBOX',
  EXPERIENCE_BOUNCE: 'EXPERIENCE_BOUNCE',
  EXPERIENCE_INDUSTRY: 'EXPERIENCE_INDUSTRY',
  EXPERIENCE_UXR_SURVEY: 'EXPERIENCE_UXR_SURVEY',
  EXPERIENCE_NOT_VERIFIED: 'EXPERIENCE_NOT_VERIFIED',
  EXPERIENCE_KVKK_CONSENT: 'EXPERIENCE_KVKK_CONSENT',
  EXPERIENCE_LAUNCH_MODAL: 'EXPERIENCE_LAUNCH_MODAL',
  EXPERIENCE_MYFORMS_BANNER: 'EXPERIENCE_MYFORMS_BANNER',
  EXPERIENCE_ABANDONED_USER: 'EXPERIENCE_ABANDONED_USER',
  EXPERIENCE_HIPAA_ENFORCEMENT: 'EXPERIENCE_HIPAA_ENFORCEMENT',
  EXPERIENCE_CAMPAIGN_LIGHTBOX: 'EXPERIENCE_CAMPAIGN_LIGHTBOX',
  EXPERIENCE_BONUS_CAMPAIGN_POPUP: 'EXPERIENCE_BONUS_CAMPAIGN_POPUP',
  EXPERIENCE_ENTERPRISE_AUSTRALIAN_MODAL: 'EXPERIENCE_ENTERPRISE_AUSTRALIAN_MODAL',
  EXPERIENCE_REACTIVATION_ANNOUNCEMENT: 'EXPERIENCE_REACTIVATION_ANNOUNCEMENT',
  EXPERIENCE_JOTFORMERS_TEAM: 'EXPERIENCE_JOTFORMERS_TEAM',
  EXPERIENCE_ENTERPRISE_GOVERNMENT: 'EXPERIENCE_ENTERPRISE_GOVERNMENT',
  EXPERIENCE_HEALTHCARE_REPORT: 'EXPERIENCE_HEALTHCARE_REPORT',
  EXPERIENCE_EARTHQUAKE_DONATION_MODAL: 'EARTHQUAKE_DONATION_MODAL',
  EXPERIENCE_MOBILE_APP_QR_CODE: 'MOBILE_APP_QR_CODE',
  EXPERIENCE_NOTIFICATION_CENTER: 'EXPERIENCE_NOTIFICATION_CENTER',
  EXPERIENCE_ENTERPRISE_DISCOUNT_MODAL: 'EXPERIENCE_ENTERPRISE_DISCOUNT_MODAL',
  EXPERIENCE_TEMPLATE_SUGGESTION_MODAL: 'EXPERIENCE_TEMPLATE_SUGGESTION_MODAL',
  EXPERIENCE_MYWORKSPACE_SUGGESTION_MODAL: 'EXPERIENCE_MYWORKSPACE_SUGGESTION_MODAL',
  EXPERIENCE_REACTIVATION_MODAL: 'EXPERIENCE_REACTIVATION_MODAL',
  EXPERIENCE_ADVERTISEMENT_LEAD_FLOW: 'EXPERIENCE_ADVERTISEMENT_LEAD_FLOW',
  EXPERIENCE_SALESFORCE_EARLY_ACCESS: 'EXPERIENCE_SALESFORCE_EARLY_ACCESS',
  EXPERIENCE_SALESFORCE_RATING_MODAL: 'EXPERIENCE_SALESFORCE_RATING_MODAL',
  EXPERIENCE_WORKFLOWS_RATING_MODAL: 'EXPERIENCE_WORKFLOWS_RATING_MODAL',
  EXPERIENCE_CV_FORCE_SIGNUP_MODAL: 'EXPERIENCE_CV_FORCE_SIGNUP_MODAL',
  EXPERIENCE_NHS_MODAL: 'EXPERIENCE_NHS_MODAL',
  EXPERIENCE_HR_WEBINAR_MODAL: 'EXPERIENCE_HR_WEBINAR_MODAL',
  EXPERIENCE_EDUCATION_BDM_FLOW: 'EXPERIENCE_EDUCATION_BDM_FLOW',
  EXPERIENCE_LISTINGS_INCREASE_LIMITS_MODAL: 'EXPERIENCE_LISTINGS_INCREASE_LIMITS_MODAL',
  EXPERIENCE_NONPROFIT_DONATION_WEBINAR_MODAL: 'EXPERIENCE_NONPROFIT_DONATION_WEBINAR_MODAL',
  EXPERIENCE_HONGKONG_DATA_RESIDENCY_MODAL: 'EXPERIENCE_ENTERPRISE_HONGKONG_MODAL',
  EXPERIENCE_SINGAPORE_DATA_RESIDENCY_MODAL: 'EXPERIENCE_ENTERPRISE_SINGAPORE_MODAL',
  EXPERIENCE_HEALTHCARE_FUTURE_WEBINAR_MODAL: 'EXPERIENCE_HEALTHCARE_FUTURE_WEBINAR_MODAL',
  EXPERIENCE_SQUARE_BANNER: 'EXPERIENCE_SQUARE_BANNER',
  EXPERIENCE_AGENT_BETA_LAUNCH_MODAL: 'EXPERIENCE_AGENT_BETA_LAUNCH_MODAL'
};

export const ANNOUNCING_2FA_MODAL_TEXTS = {
  MODAL_TITLE: 'Secure your account with two-factor authentication (2FA)',
  BETA: 'Beta',
  NEW: 'New',
  NOT_NOW: 'Not Now',
  ENABLE_2FA: 'Enable 2FA',
  GOT_IT: 'Got it!',
  SECURITY: 'Security',
  MY_ACCOUNT: 'My Account',
  WHATS_NEW: 'What\'s new 🔥',
  WHATS_NEW_DESC: 'Get the latest changes and updates',
  DESC_HEADER: 'Announcing Two-Factor Authentication (2FA)',
  // eslint-disable-next-line max-len
  DESC: 'You can now set up 2FA to give your Jotform account an added layer of security. Go to the [1[{security}]] tab on your [2[{myAccount}]] page, enable two-factor authentication (2FA), and follow the instructions to complete the setup.'
};

export const MODAL_PROPERTIES = {
  EXPERIENCE_LAUNCH_MODAL: {
    logger: 'enterpriseMobileLaunchModal',
    name: 'enterprise-mobile-launch-lightbox'
  }
};

export const NON_INPUT_QUESTIONS = [
  'control_pagebreak',
  'control_collapse',
  'control_image',
  'control_button',
  'control_captcha',
  'control_separator',
  'control_clear',
  'control_divider',
  'control_head'
];

export const MOBILE_BREAKDOWN_WIDTH = 580;

export const MORE_MENU_BREAKDOWN_WIDTH = 768;

export const MORE_MENU_EDIT_AGENT_BREAKDOWN_WIDTH = 1050;

export const SHARED_FOLDERS = [DEFAULT_FOLDER_IDS.SHARED_WITH_ME, DEFAULT_FOLDER_IDS.SHARED_SHEETS, DEFAULT_FOLDER_IDS.SHARED_BOARDS, DEFAULT_FOLDER_IDS.SHARED_REPORTS];

export const STATUS_FOLDERS = [DEFAULT_FOLDER_IDS.ARCHIVE, DEFAULT_FOLDER_IDS.FAVORITES, DEFAULT_FOLDER_IDS.TRASH];

export const ASSET_CREATION_DISABLED_FOLDERS = [
  ...STATUS_FOLDERS,
  DEFAULT_FOLDER_IDS.SHARED_WITH_ME,
  DEFAULT_FOLDER_IDS.ASSIGNED,
  DEFAULT_FOLDER_IDS.DRAFTS,
  DEFAULT_FOLDER_IDS.FILLED_FORMS,
  DEFAULT_FOLDER_IDS.TASKS,
  ...Object.values(ALL_ASSETS_ID)
];

export const MOVE_TO_TEAM_DISABLED_FOLDERS = [
  DEFAULT_FOLDER_IDS.TRASH, DEFAULT_FOLDER_IDS.ASSIGNED, DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.FILLED_FORMS, DEFAULT_FOLDER_IDS.TASKS, ...SHARED_FOLDERS
];

export const ADD_TO_FOLDER_DISABLED_FOLDERS = [DEFAULT_FOLDER_IDS.TRASH, DEFAULT_FOLDER_IDS.SHARED_WITH_ME, DEFAULT_FOLDER_IDS.ASSIGNED, DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.FILLED_FORMS];

export const MIX_ENDPOINT_DISABLED_FOLDERS = [DEFAULT_FOLDER_IDS.ASSIGNED, DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.FILLED_FORMS, DEFAULT_FOLDER_IDS.TASKS, ...SHARED_FOLDERS];

export const BULK_ACTION_RESTRICTEDS = [DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.TASKS, DEFAULT_FOLDER_IDS.ASSIGNED, ...SHARED_FOLDERS];

export const MOVE_STATUS_ACTION_RESULTS = {
  success: {
    single: '[1[asset_name]] successfully transferred.',
    multiple: '[1[asset_name]] and connected assets are successfully transferred.'
  },
  error: 'There was a problem migrating the [1[asset_name]] asset.'
};

export const STATIC_FOLDERS = [...STATUS_FOLDERS, DEFAULT_FOLDER_IDS.ASSIGNED, DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.FILLED_FORMS, DEFAULT_FOLDER_IDS.TASKS, ...SHARED_FOLDERS];

export const WITHOUT_CREATE_BUTTON_LIST = [
  ...STATUS_FOLDERS,
  DEFAULT_FOLDER_IDS.ASSIGNED,
  DEFAULT_FOLDER_IDS.DRAFTS,
  DEFAULT_FOLDER_IDS.TASKS,
  DEFAULT_FOLDER_IDS.MY_SUBMISSIONS,
  DEFAULT_FOLDER_IDS.FILLED_FORMS,
  ...SHARED_FOLDERS
];

// PII Warnings texts
export const PII_WARNINGS_TEXTS = {
  FORMS_WILL_BE_DISABLED: 'Your forms will be disabled',
  // eslint-disable-next-line max-len
  VERIFY_BY: 'We’ve detected that you’re collecting [1[personally identifiable information (PII)]] via your forms. If you do not verify your account by [2[{date}]], your forms will be disabled. If you have any questions, please [3[contact us]].',
  FORMS_HAVE_BEEN_DISABLED: 'Your forms have been disabled',
  UPLOAD_SUPPORTING_DOCUMENTS: 'You need to upload missing/incorrect documents',
  // eslint-disable-next-line max-len
  DETECTED_PII: 'We’ve detected that you’re collecting [1[personally identifiable information (PII)]] via your forms. Please verify your identity and re-enable your forms. If you have any questions, please [2[contact us]].',
  // eslint-disable-next-line max-len
  RECEIVED_SUBMISSON: 'We’ve received your submission to identity verification form. However, [1[{documents}]] missing or incorrect. Please upload these documents. If you have any questions, please [2[contact us]].',
  VERIFY_NOW: 'Verify now',
  UPLOAD_DOCUMENTS: 'Upload documents'
};

export const KYC_ACTIONS = {
  STARTED_0_SEEN: 'kyc_started_warning_asset_seen',
  STARTED_1_SEEN: 'kyc_started_disabled_asset_seen',
  LACK_OF_DOCUMENTS_0_SEEN: 'kyc_lack_of_documents_warning_seen',
  LACK_OF_DOCUMENTS_1_SEEN: 'kyc_lack_of_documents_disabled_seen',
  STARTED_0_CLICK: 'kyc_started_warning_form',
  STARTED_1_CLICK: 'kyc_started_disabled_form',
  LACK_OF_DOCUMENTS_0_CLICK: 'kyc_lack_of_documents_warning_form',
  LACK_OF_DOCUMENTS_1_CLICK: 'kyc_lack_of_documents_disabled_form'
};

export const APP_CREATION_VIEWS = {
  APP_RECOMMENDATION: 'AppRecommendation',
  ONLINE_STORE: 'OnlineStoreAppRecommendation'
};

export const TOOLBAR_MORE_ACTIONS = {
  ARCHIVE: 'archiveMore',
  UNARCHIVE: 'unarchiveMore',
  STAR: 'starMore',
  UNSTAR: 'unstarMore',
  MARK_AS_READ: 'markAsReadMore',
  ENABLE: 'enableMore',
  DISABLE: 'disableMore'
};
