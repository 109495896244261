/* eslint-disable max-lines */
import React from 'react';

import { IconProductPagesMono, IconProductPagesColor } from '@jotforminc/svg-icons';

import PropTypes, { oneOfType } from 'prop-types';
import { RESOURCE_TYPES } from '@jotforminc/resource-picker';
import IconAppAnalytics from '../../assets/svg/iconAppAnalytics.svg';
import IconAppBuilder from '../../assets/svg/iconAppBuilder.svg';
import IconAppCards from '../../assets/svg/iconAppCards.svg';
import IconAppForm from '../../assets/svg/iconAppForm.svg';
import IconAppInbox from '../../assets/svg/iconAppInbox.svg';
import IconAppPdfEditor from '../../assets/svg/iconAppPdfEditor.svg';
import IconAppReports from '../../assets/svg/iconAppReports.svg';
import IconAppSheets from '../../assets/svg/iconAppSheets.svg';
import IconAppUploads from '../../assets/svg/iconAppUploads.svg';
import IconAppMyApps from '../../assets/svg/iconAppMyApps.svg';
import IconAppAiAgentBuilder1 from '../../assets/svg/iconAppAiAgentBuilder1.svg';
import IconAppAiAgentBuilder2 from '../../assets/svg/iconAppAiAgentBuilder2.svg';
import IconConversations1 from '../../assets/svg/iconConversationsv1.svg';
import IconAppMyKiosks from '../../assets/svg/iconAppMyKiosks.svg';
import IconAppMyForms from '../../assets/svg/iconAppMyForms.svg';
import IconAppMyTasks from '../../assets/svg/iconAppMyTasks.svg';
import IconAppSign from '../../assets/svg/iconAppSign.svg';
import IconAppApprovals from '../../assets/svg/iconAppApprovals.svg';
import IconAppMyTeams from '../../assets/svg/iconAppMyTeams.svg';
import IconAppMyWorkspace from '../../assets/svg/iconAppMyWorkspace.svg';
import IconAppMyContacts from '../../assets/svg/iconAppMyContacts.svg';
import IconAppBoards from '../../assets/svg/appIcons/boards_selected.svg';

import AppIcons from './appIcons';
import { getDefaultLogoSrc } from '../../utils/utils';

// TEMPORARY. WILL BE DELETED AFTER ACTUAL RELEASE :) SORRY AND THANKS.
const isWorkflowReleased = window?.isWorkflowReleased;
const MY_TASKS_TEXT = {
  appLink: isWorkflowReleased ? '/myworkflows/' : '/myapprovals/',
  title: isWorkflowReleased ? 'My Workflows' : 'My Approvals',
  titleForTeamContext: isWorkflowReleased ? 'Workflow' : 'Approvals'
};

const APPROVALS_TEXT = {
  title: isWorkflowReleased ? 'Workflow Builder' : 'Approvals',
  explanation: `Build ${isWorkflowReleased ? 'workflows' : 'approval flows'} from your forms, create custom outcomes, and send email notifications to submitters.`
};

/** TODO::Branding
 * - Delete primary and secondary colors (we are using appColor prop)
 */

export const appToResourceType = app => {
  switch (app) {
    case 'portal':
      return RESOURCE_TYPES.APP;
    case 'approvals':
      return RESOURCE_TYPES.APPROVAL;
    case 'sign':
      return RESOURCE_TYPES.SIGN;
    case 'signInbox':
      return RESOURCE_TYPES.SIGN_INBOX;
    case 'boards':
      return RESOURCE_TYPES.BOARD;
    default:
      return RESOURCE_TYPES.FORM;
  }
};

export const navigationItems = {
  viewForm: {
    'data-icon': 'forForm',
    icon: <IconAppForm />,
    appLink: formID => {
      const isProduction = 'JOTFORM_ENV' in window && window.JOTFORM_ENV === 'PRODUCTION';
      if (isProduction) {
        return `//form.jotform.com/${formID}`;
      }
      return `/${formID}`;
    },
    title: 'View Form',
    target: '_blank',
    primaryColor: '#5749C6',
    secondaryColor: '#4034B4',
    backgroundColor: '#FFFFFF'
  },
  formBuilder: {
    'data-icon': 'forBuilder',
    icon: <IconAppBuilder />,
    appLink: formID => `/build/${formID}`,
    title: 'Form Builder',
    className: 'isBuilder',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-orange@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#F38632',
    secondaryColor: '#D26612',
    backgroundColor: '#FFFFFF'
  },
  pdfEditor: {
    'data-icon': 'forPdfEditor',
    icon: <IconAppPdfEditor />,
    appLink: formID => `/pdf-editor/${formID}`,
    title: 'PDF Editor',
    logo: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png',
    className: 'isPdfEditor',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#3468E7',
    secondaryColor: '#1C4BBE',
    backgroundColor: '#3161CF'
  },
  tables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    appLink: formID => `/tables/${formID}`,
    title: 'Tables',
    className: 'isSheets',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#38BA71',
    secondaryColor: '#0D9549',
    backgroundColor: '#0D9549'
  },
  appTables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    icons: AppIcons.tables,
    appLink: formID => `/tables/withApp/${formID}`,
    title: 'Tables',
    className: 'isSheets',
    target: '_self',
    components: {
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#FF6100',
    secondaryColor: '#FF6100',
    backgroundColor: '#FFFFFF',
    appColor: '#149F43'
  },
  cards: {
    'data-icon': 'forCards',
    icon: <IconAppCards />,
    appLink: formID => `/tables/${formID}`,
    title: 'Cards',
    className: 'isCards',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#2E5DFF',
    secondaryColor: '#4277FF',
    backgroundColor: '#4277FF'
  },
  uploads: {
    'data-icon': 'forUploads',
    icon: <IconAppUploads />,
    appLink: formID => `/tables/${formID}`,
    title: 'Uploads',
    className: 'isUploads',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#EA6041',
    secondaryColor: '#F07A59',
    backgroundColor: '#F07A59'
  },
  inbox: {
    'data-icon': 'forInbox',
    icon: <IconAppInbox />,
    appLink: formID => `/inbox/${formID}`,
    title: 'Inbox',
    className: 'isInbox',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7'
  },
  signInbox: {
    'data-icon': 'forInbox',
    icon: <IconAppInbox />,
    appLink: formID => `/inbox/${formID}`,
    title: 'Sign Inbox',
    className: 'isInbox',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7'
  },
  portal: {
    'data-icon': 'forPortal',
    icon: <IconAppMyApps />,
    appLink: formID => `/app/buildWith/${formID}`,
    title: 'App Builder',
    className: 'isPortal',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#42277d',
    backgroundColor: '#69519d'
  },
  aiAgentBuilder: {
    'data-icon': 'forAiAgentBuilder',
    icon: <IconAppAiAgentBuilder1 />,
    appLink: agentID => `/agent/build/${agentID}`,
    title: 'AI Agent Builder',
    className: 'isPortal',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#09f',
    appColor: '#09f',
    backgroundColor: '#69519d'
  },
  workflow: {
    'data-icon': 'forWorkflow',
    icon: <IconAppMyApps />,
    appLink: () => '/workflow',
    title: 'Workflow Builder',
    className: 'isWorkflow',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: '',
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#37836F'
  },
  myForms: {
    'data-icon': 'forMyForms',
    icon: <IconAppMyForms />,
    appLink: () => '/myforms',
    title: 'My Forms',
    className: 'isMyForms',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#FFB448',
    secondaryColor: '#e89010',
    backgroundColor: '#2D364C'
  },
  myApps: {
    'data-icon': 'forMyApps',
    icon: <IconAppMyApps />,
    appLink: '/myapps/',
    title: 'My Apps',
    className: 'isMyApps',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#42277d',
    backgroundColor: '#745CA9'
  },
  myKiosks: {
    'data-icon': 'forMyKiosks',
    icon: <IconAppMyKiosks />,
    appLink: '/mykiosks/',
    title: 'My Kiosks',
    className: 'isMyKiosks',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#BDC1D7',
    secondaryColor: '#ADB2CD',
    backgroundColor: '#BDC1D7'
  },
  enterpriseAdmin: {
    'data-icon': 'forEnterpriseAdmin',
    icon: <IconAppInbox />,
    appLink: () => '/enterprise-admin/',
    title: 'Admin',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#30336A',
    secondaryColor: '#3E417C',
    backgroundColor: '#30336A'
  },
  formAnalytics: {
    'data-icon': 'forAnalytics',
    icon: <IconAppAnalytics />,
    appLink: formID => `/analytics/${formID}`,
    title: 'Form Analytics',
    target: '_self',
    primaryColor: '#AF4AE9',
    secondaryColor: '#8F2DD5',
    backgroundColor: '#5C268B'
  },
  reports: {
    'data-icon': 'forReports',
    icon: <IconAppReports />,
    appLink: formID => `/reports/${formID}`,
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    title: 'Report Builder',
    target: '_self',
    primaryColor: '#4D5773',
    secondaryColor: '#2E354A',
    backgroundColor: '#2E354A'
  },
  boards: {
    'data-icon': 'forBoards',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    icon: <IconAppBoards />,
    icons: AppIcons.boards,
    appLink: formID => {
      return `/boards/new/${formID}?from=formBuilder-navigation`;
    },
    title: 'Boards',
    className: 'isBoards',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED'
  },
  conversations: {
    'data-icon': 'forConversations',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    icon: <IconConversations1 />,
    icons: AppIcons.conversations,
    appLink: formID => {
      return `/conversations/withForm/${formID}`;
    },
    title: 'Conversations',
    className: 'isConversations',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED'
  },
  myReports: {
    'data-icon': 'forMyReports',
    icon: <IconAppReports />,
    appLink: '/myreports/',
    title: 'My Reports',
    className: 'isMyReports',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#4E5773',
    secondaryColor: '#323F62',
    backgroundColor: '#3c4253'
  },
  myTables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    appLink: '/mytables/',
    title: 'My Tables',
    className: 'isMySheets',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#38ba71',
    secondaryColor: '#0d9549',
    backgroundColor: '#0d9549'
  },
  myTasks: {
    'data-icon': 'forTasks',
    icon: <IconAppMyTasks />,
    ...MY_TASKS_TEXT,
    className: 'isMyTasks',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#025F46'
  },
  submissions: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    appLink: formID => `/submissions/${formID}`,
    title: 'Submissions',
    className: 'isSheets',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#037B38',
    secondaryColor: '#0D9549',
    backgroundColor: '#0D9549'
  },
  oldPdfEditor: {
    'data-icon': 'forPdfEditor',
    icon: <IconAppPdfEditor />,
    appLink: formID => `/page.php?p=pdfEditor&formID=${formID}`,
    title: 'PDF Editor',
    logo: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png',
    className: 'isPdfEditor',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#254DB3',
    secondaryColor: '#3161CF',
    backgroundColor: '#3161CF'
  },
  sign: {
    'data-icon': 'forSign',
    icon: <IconAppSign />,
    appLink: formID => `/sign/${formID}`,
    title: 'Sign Builder',
    className: 'isSign',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#7BB60F',
    secondaryColor: '#6b9e0d',
    backgroundColor: '#7BB60F'
  },
  approvals: {
    'data-icon': 'forApprovals',
    icon: <IconAppApprovals />,
    appLink: formID => `/workflow/withForm/${formID}`,
    title: 'Approvals',
    className: 'isApprovals',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#37836F'
  },
  myDocuments: {
    'data-icon': 'forMyDocuments',
    icon: <IconAppSign />,
    appLink: '/mysigndocuments/',
    title: 'My Sign Documents',
    className: 'isMyDocuments',
    target: '_self',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#7BB60F',
    secondaryColor: '#6b9e0d',
    backgroundColor: '#7BB60F'
  },
  myTeams: {
    'data-icon': 'forMyTeams',
    icon: <IconAppMyTeams />,
    appLink: '/myteams/',
    title: 'My Teams',
    target: '_self',
    primaryColor: '#18235C',
    secondaryColor: '#18235C',
    backgroundColor: '#18235C'
  },
  myWorkspace: {
    'data-icon': 'forMyWorkspace',
    icon: <IconAppMyWorkspace />,
    appLink: '/myworkspace/',
    title: 'My Workspace',
    target: '_self',
    primaryColor: '#18235C',
    secondaryColor: '#18235C',
    backgroundColor: '#18235C'
  },
  myAgents: {
    'data-icon': 'forMyAgents',
    icon: <IconAppAiAgentBuilder2 />,
    appLink: '/myagents/',
    title: 'My Agents',
    target: '_self',
    primaryColor: '#0099ff',
    secondaryColor: '#0099ff',
    backgroundColor: '#0099ff'
  },
  myContacts: {
    'data-icon': 'forMyContacts',
    icon: <IconAppMyContacts />,
    appLink: '/mycontacts/',
    title: 'My Contacts',
    target: '_self',
    primaryColor: '#895FFF',
    secondaryColor: '#895FFF',
    backgroundColor: '#895FFF'
  },
  myPages: {
    'data-icon': 'forMyPages',
    icon: <IconProductPagesColor />,
    appLink: '/mypages/',
    title: 'My Pages',
    className: 'isMyPages',
    target: '_self',
    primaryColor: '#034f96',
    secondaryColor: '#034f96',
    backgroundColor: '#034f96'
  },
  myBoards: {
    'data-icon': 'forMyBoards',
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    icon: <IconAppBoards />,
    icons: AppIcons.boards,
    appLink: '/myboards/',
    title: 'My Boards',
    className: 'isBoards',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED'
  }
};

const branding21sharedConfigs = {
  components: {
    logo: {
      visible: true,
      src: getDefaultLogoSrc()
    }
  },
  colors: {
    accountBoxBorderColor: '#FF6100'
  }
};

export const navigationItemsForBranding21 = {
  viewForm: {
    'data-icon': 'forForm',
    icon: <IconAppForm />,
    icons: AppIcons.viewForm,
    appLink: formID => {
      const isProduction = 'JOTFORM_ENV' in window && window.JOTFORM_ENV === 'PRODUCTION';
      if (isProduction) {
        return `//form.jotform.com/${formID}`;
      }
      return `/${formID}`;
    },
    title: 'View Form',
    explanation: 'See your form in action.',
    target: '_blank',
    primaryColor: '#5749C6',
    secondaryColor: '#4034B4',
    backgroundColor: '#FFFFFF',
    appColor: '#343C6A',
    ...branding21sharedConfigs.colors
  },
  formBuilder: {
    'data-icon': 'forBuilder',
    icon: <IconAppBuilder />,
    icons: AppIcons.formBuilder,
    appLink: formID => `/build/${formID}`,
    title: 'Form Builder',
    explanation: 'Create forms and customize them with styling options to match your branding.',
    className: 'isBuilder',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#F38632',
    secondaryColor: '#D26612',
    backgroundColor: '#FFFFFF',
    appColor: '#FF6100',
    ...branding21sharedConfigs.colors
  },
  pdfEditor: {
    'data-icon': 'forPdfEditor',
    icon: <IconAppPdfEditor />,
    icons: AppIcons.pdfEditor,
    appLink: formID => `/pdf-editor/${formID}`,
    title: 'PDF Editor',
    explanation: 'Automatically convert your form responses into professional-looking PDF documents.',
    logo: getDefaultLogoSrc(),
    className: 'isPdfEditor',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#3468E7',
    secondaryColor: '#1C4BBE',
    backgroundColor: '#3161CF',
    appColor: '#3E62C8',
    ...branding21sharedConfigs.colors
  },
  tables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    icons: AppIcons.tables,
    appLink: formID => `/tables/${formID}`,
    title: 'Tables',
    explanation: 'View and manage your form responses, collaborate with teammates, and more.',
    className: 'isSheets',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#FF6100',
    secondaryColor: '#FF6100',
    backgroundColor: '#FFFFFF',
    appColor: '#149F43',
    ...branding21sharedConfigs.colors
  },
  appTables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    icons: AppIcons.tables,
    appLink: formID => `/tables/withApp/${formID}`,
    title: 'Tables',
    className: 'isSheets',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#FF6100',
    secondaryColor: '#FF6100',
    backgroundColor: '#FFFFFF',
    appColor: '#149F43',
    ...branding21sharedConfigs.colors
  },
  inbox: {
    'data-icon': 'forInbox',
    icon: <IconAppInbox />,
    icons: AppIcons.inbox,
    appLink: formID => `/inbox/${formID}`,
    title: 'Inbox',
    explanation: 'Manage your communications — respond to messages, forward form responses, leave comments, and tag teammates.',
    className: 'isInbox',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7',
    appColor: '#249BB4',
    ...branding21sharedConfigs.colors
  },
  signInbox: {
    'data-icon': 'forInbox',
    icon: <IconAppInbox />,
    icons: AppIcons.inbox,
    appLink: formID => `/inbox/${formID}`,
    title: 'Sign Inbox',
    explanation: 'Manage your communications — respond to messages, forward form responses, leave comments, and tag teammates.',
    className: 'isInbox',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7',
    appColor: '#249BB4',
    ...branding21sharedConfigs.colors
  },
  portal: {
    'data-icon': 'forPortal',
    icon: <IconAppMyApps />,
    icons: AppIcons.portal,
    appLink: (formID, oldNavigation) => `/app/${oldNavigation ? 'buildWith' : 'build'}/${formID}`,
    createLink: formIDs => {
      if (typeof formIDs === 'string') {
        return `/app/create/${formIDs}`;
      }
      if (formIDs.length === 0) {
        return '/app';
      }
      return `/app/create/${formIDs.join(',')}`;
    },
    title: 'App Builder',
    explanation: 'Make downloadable no-code apps for your business. Add forms, links, widgets, and your branding.',
    className: 'isPortal',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#42277d',
    backgroundColor: '#69519d',
    appColor: '#8D4ECC',
    ...branding21sharedConfigs.colors
  },
  workflow: {
    'data-icon': 'forWorkflow',
    icon: <IconAppMyApps />,
    appLink: () => '/workflow',
    title: 'Workflow Builder',
    className: 'isWorkflow',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: '',
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#37836F',
    ...branding21sharedConfigs.colors
  },
  myForms: {
    'data-icon': 'forMyForms',
    icon: <IconAppBuilder />,
    icons: AppIcons.formBuilder,
    appLink: () => '/myforms/',
    title: 'My Forms',
    titleForTeamContext: 'Forms',
    className: 'isMyForms',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#FFB448',
    secondaryColor: '#e89010',
    backgroundColor: '#2D364C',
    appColor: '#FF6100',
    ...branding21sharedConfigs.colors
  },
  myApps: {
    'data-icon': 'forMyApps',
    icon: <IconAppMyApps />,
    icons: AppIcons.portal,
    appLink: '/myapps/',
    title: 'My Apps',
    titleForTeamContext: 'Apps',
    className: 'isMyApps',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#42277d',
    backgroundColor: '#745CA9',
    appColor: '#7B5FD4',
    ...branding21sharedConfigs.colors
  },
  enterpriseAdmin: {
    'data-icon': 'forEnterpriseAdmin',
    icon: <IconAppInbox />,
    appLink: () => '/enterprise-admin/',
    title: 'Admin',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#30336A',
    secondaryColor: '#3E417C',
    backgroundColor: '#30336A',
    ...branding21sharedConfigs.color
  },
  enterpriseManagementPanel: {
    'data-icon': 'forEnterpriseManagementPanel',
    icon: <IconAppInbox />,
    appLink: () => '/enterprise-admin/',
    title: 'Admin',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#30336A',
    secondaryColor: '#3E417C',
    backgroundColor: '#30336A',
    ...branding21sharedConfigs.color
  },
  formAnalytics: {
    'data-icon': 'forAnalytics',
    icon: <IconAppAnalytics />,
    icons: AppIcons.formAnalytics,
    appLink: formID => `/analytics/${formID}`,
    title: 'Form Analytics',
    explanation: 'Get the inside scoop on your form’s performance, including its conversion rate and where traffic is coming from.',
    target: '_self',
    primaryColor: '#AF4AE9',
    secondaryColor: '#8F2DD5',
    backgroundColor: '#5C268B',
    appColor: '#8F96CC',
    ...branding21sharedConfigs.colors
  },
  reports: {
    'data-icon': 'forReports',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    icon: <IconAppReports />,
    icons: AppIcons.reports,
    appLink: formID => `/reports/${formID}`,
    title: 'Report Builder',
    explanation: 'Automatically turn form responses into eye-catching reports with bar graphs, pie charts, and data grids.',
    target: '_self',
    primaryColor: '#4D5773',
    secondaryColor: '#2E354A',
    backgroundColor: '#2E354A',
    appColor: '#344067',
    ...branding21sharedConfigs.colors
  },
  boards: {
    'data-icon': 'forBoards',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    icon: <IconAppBoards />,
    icons: AppIcons.boards,
    title: 'Boards',
    explanation: 'Whatever the task, Jotform Boards is here to help you manage.',
    appLink: (formID, oldNavigation) => {
      if (oldNavigation) {
        return `/boards/new/${formID}?from=formBuilder-navigation`;
      }

      return `/boards/${formID}`;
    },
    createLink: formID => {
      return `/boards/new/${formID}?from=formBuilder-navigation`;
    },
    className: 'isBoards',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED',
    appColor: '#504ECC',
    ...branding21sharedConfigs.colors
  },
  conversations: {
    'data-icon': 'forConversations',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    icon: <IconConversations1 />,
    icons: AppIcons.conversations,
    title: 'Conversations',
    explanation: 'Whatever the task, Jotform Boards is here to help you manage.',
    appLink: formID => {
      return `/conversations/withForm/${formID}`;
    },
    className: 'isConversations',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED',
    appColor: '#504ECC',
    ...branding21sharedConfigs.colors
  },
  aiAgentBuilder: {
    'data-icon': 'forAiAgentBuilder',
    icon: <IconAppAiAgentBuilder2 />,
    appLink: agentID => `/agent/build/${agentID}`,
    title: 'AI Agent Builder',
    className: 'isPortal',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    primaryColor: '#69519d',
    secondaryColor: '#09f',
    appColor: '#09f',
    backgroundColor: '#69519d',
    ...branding21sharedConfigs.colors
  },
  myBoards: {
    'data-icon': 'forMyBoards',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    icon: <IconAppBoards />,
    icons: AppIcons.boards,
    appLink: '/myboards/',
    titleForTeamContext: 'Boards',
    title: 'My Boards',
    className: 'isBoards',
    target: '_self',
    primaryColor: '#FF6100',
    secondaryColor: '#5C59ED',
    backgroundColor: '#5C59ED',
    appColor: '#504ECC',
    ...branding21sharedConfigs.colors
  },
  myReports: {
    'data-icon': 'forMyReports',
    icon: <IconAppReports />,
    icons: AppIcons.reports,
    appLink: '/myreports/',
    title: 'My Reports',
    titleForTeamContext: 'Reports',
    className: 'isMyReports',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#4E5773',
    secondaryColor: '#323F62',
    backgroundColor: '#3c4253',
    appColor: '#344168',
    ...branding21sharedConfigs.colors
  },
  myTables: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    icons: AppIcons.tables,
    appLink: '/mytables/',
    title: 'My Tables',
    titleForTeamContext: 'Tables',
    className: 'isMySheets',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#38ba71',
    secondaryColor: '#0d9549',
    backgroundColor: '#0d9549',
    appColor: '#0D953B',
    ...branding21sharedConfigs.colors
  },
  myTasks: {
    'data-icon': 'forTasks',
    icon: <IconAppMyTasks />,
    icons: AppIcons.approvals,
    ...MY_TASKS_TEXT,
    className: 'isMyTasks',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#025F46',
    appColor: '#308A69',
    ...branding21sharedConfigs.colors
  },
  submissions: {
    'data-icon': 'forSheets',
    icon: <IconAppSheets />,
    appLink: formID => `/submissions/${formID}`,
    title: 'Submissions',
    className: 'isSheets',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#037B38',
    secondaryColor: '#0D9549',
    backgroundColor: '#0D9549',
    ...branding21sharedConfigs.colors
  },
  oldPdfEditor: {
    'data-icon': 'forPdfEditor',
    icon: <IconAppPdfEditor />,
    appLink: formID => `/page.php?p=pdfEditor&formID=${formID}`,
    title: 'PDF Editor',
    logo: getDefaultLogoSrc(),
    className: 'isPdfEditor',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#254DB3',
    secondaryColor: '#3161CF',
    backgroundColor: '#3161CF',
    ...branding21sharedConfigs.colors
  },
  sign: {
    'data-icon': 'forSign',
    icon: <IconAppSign />,
    icons: AppIcons.sign,
    appLink: formID => `/sign/${formID}`,
    title: 'Sign Builder',
    explanation: 'Collect signatures on important documents in a few easy steps.',
    className: 'isSign',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: true,
        isIconVisible: false
      }
    },
    primaryColor: '#7BB60F',
    secondaryColor: '#6b9e0d',
    backgroundColor: '#7BB60F',
    appColor: '#94CD2A',
    ...branding21sharedConfigs.colors
  },
  approvals: {
    'data-icon': 'forApprovals',
    icon: <IconAppApprovals />,
    icons: AppIcons.approvals,
    appLink: (formID, oldNavigation) => `/workflow/${oldNavigation ? 'withForm/' : ''}${formID}`,
    ...APPROVALS_TEXT,
    className: 'isApprovals',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: true,
        editable: true,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#37836F',
    secondaryColor: '#025F46',
    backgroundColor: '#37836F',
    appColor: '#007862',
    ...branding21sharedConfigs.colors
  },
  myDocuments: {
    'data-icon': 'forMyDocuments',
    icon: <IconAppSign />,
    icons: AppIcons.sign,
    appLink: '/mysigndocuments/',
    title: 'My Sign Documents',
    titleForTeamContext: 'Sign Documents',
    className: 'isMyDocuments',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#7BB60F',
    secondaryColor: '#6b9e0d',
    backgroundColor: '#7BB60F',
    appColor: '#78BB07',
    ...branding21sharedConfigs.colors
  },
  pageBuilder: {
    'data-icon': 'forPageBuilder',
    title: 'Page Builder',
    className: 'isPageBuilder',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      title: {
        visible: false,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: false,
        isIconVisible: false
      }
    },
    primaryColor: '#0A1551',
    secondaryColor: '#18265b',
    backgroundColor: '#FFFFFF',
    appColor: '#0A1551',
    ...branding21sharedConfigs.colors
  },
  myTeams: {
    'data-icon': 'forMyTeams',
    icon: <IconAppMyTeams />,
    icons: AppIcons.myTeams,
    appLink: '/myteams/',
    title: 'My Teams',
    target: '_self',
    primaryColor: '#18235C',
    secondaryColor: '#18235C',
    backgroundColor: '#18235C',
    appColor: '#18235C',
    ...branding21sharedConfigs.colors
  },
  myWorkspace: {
    'data-icon': 'forMyWorkspace',
    icon: <IconAppMyWorkspace />,
    icons: AppIcons.myWorkspace,
    appLink: '/myworkspace/',
    title: 'My Workspace',
    target: '_self',
    primaryColor: '#18235C',
    secondaryColor: '#18235C',
    backgroundColor: '#18235C',
    appColor: '#18235C',
    ...branding21sharedConfigs.colors
  },
  myAgents: {
    'data-icon': 'forMyAgents',
    icon: <IconAppAiAgentBuilder2 />,
    icons: AppIcons.myAgents,
    appLink: '/myagents/',
    title: 'My Agents',
    target: '_self',
    primaryColor: '#0099ff',
    secondaryColor: '#0099ff',
    backgroundColor: '#0099ff',
    appColor: '#0099ff',
    ...branding21sharedConfigs.colors
  },
  myContacts: {
    'data-icon': 'forMyContacts',
    icon: <IconAppMyContacts />,
    icons: AppIcons.myContacts,
    appLink: '/mycontacts/',
    title: 'My Contacts',
    target: '_self',
    primaryColor: '#895FFF',
    secondaryColor: '#895FFF',
    backgroundColor: '#895FFF',
    appColor: '#895FFF',
    ...branding21sharedConfigs.colors
  },
  myPages: {
    'data-icon': 'forMyPages',
    icon: <IconProductPagesMono />,
    icons: AppIcons.pages,
    appLink: '/mypages/',
    title: 'My Pages',
    className: 'isMyPages',
    target: '_self',
    primaryColor: '#034f96',
    secondaryColor: '#034f96',
    backgroundColor: '#034f96',
    appColor: '#034f96',
    ...branding21sharedConfigs.colors
  },
  singleSubmissionViewer: {
    title: 'Submission',
    className: 'isSingleSubmissionViewer',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo2021/jotform-logo-white.svg'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        title: 'View Form',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7',
    appColor: '#249BB4',
    ...branding21sharedConfigs.colors
  },
  AIAgentSubmissionViewer: {
    title: 'AI Agent',
    className: 'isAIAgentSubmissionViewer',
    target: '_self',
    components: {
      ...branding21sharedConfigs.components,
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo2021/jotform-logo-white.svg'
      },
      title: {
        visible: true,
        editable: false,
        isIconVisible: false
      },
      subTitle: {
        visible: true,
        title: 'View This Agent',
        isIconVisible: false
      }
    },
    primaryColor: '#39B9C5',
    secondaryColor: '#228DB5',
    backgroundColor: '#0097a7',
    appColor: '#249BB4',
    ...branding21sharedConfigs.colors
  }
};

export const appConfigPropsTypes = PropTypes.shape({
  'data-icon': PropTypes.string,
  icon: PropTypes.element,
  appLink: PropTypes.func,
  title: oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  target: PropTypes.string,
  components: PropTypes.shape({
    logo: PropTypes.shape({
      visible: PropTypes.bool,
      src: PropTypes.string
    }),
    title: PropTypes.shape({
      visible: PropTypes.bool,
      editable: PropTypes.bool,
      isIconVisible: PropTypes.bool
    }),
    subTitle: PropTypes.shape({
      visible: PropTypes.bool,
      title: PropTypes.string,
      isIconVisible: PropTypes.bool
    })
  })
});

const defaultNavigationItemObject = appTitle => {
  return {
    'data-icon': `for${appTitle}`,
    icon: <IconAppSheets />,
    appLink: formID => `/${formID}`,
    title: appTitle,
    className: `is${appTitle}`,
    components: {
      logo: {
        visible: true,
        src: 'https://cdn.jotfor.ms/assets/img/logo/jotform-logo-white@4x.png'
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#f38632',
    secondaryColor: '#d27e2d',
    backgroundColor: '#d27e2d'
  };
};

const defaultBranding21NavigationItemObject = appTitle => {
  return {
    'data-icon': `for${appTitle}`,
    icon: <IconAppSheets />,
    appLink: formID => `/${formID}`,
    title: appTitle,
    className: `is${appTitle}`,
    components: {
      logo: {
        visible: true,
        src: getDefaultLogoSrc()
      },
      title: {
        visible: false,
        editable: false,
        isIconVisible: true
      },
      subTitle: {
        visible: false,
        title: 'View This Form',
        isIconVisible: false
      }
    },
    primaryColor: '#f38632',
    secondaryColor: '#d27e2d',
    backgroundColor: '#d27e2d'
  };
};

export const defaultNavigationItem = (appName, branding21) => {
  const appTitle = `${appName.charAt(0).toUpperCase()}${appName.substr(1)}`;

  return branding21 ? defaultBranding21NavigationItemObject(appTitle) : defaultNavigationItemObject(appTitle);
};

export const getDefaultNavItems = branding21 => {
  return branding21 ? navigationItemsForBranding21 : navigationItems;
};
