/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable camelcase */
import React, { ReactNode } from 'react';
import { ValueOf, Merge } from 'type-fest';

export const ACCOUNTS = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  DATA_ONLY_USER: 'DATA_ONLY_USER'
} as const;

export type AccountType = ValueOf<typeof ACCOUNTS>;

export type TeamRoleSlugs = 'team_admin' | 'data_collaborator' | 'form_collaborator' | 'data_read_only' | '';

export interface RolesType { ['role_name']: string, ['role_id']: string, ['role_slug']: TeamRoleSlugs, ['role_description']?: string | ReactNode }

export interface User {
  account_type: AccountType,
  created_at: string,
  updated_at: string,
  last_seen: string,
  username: string,
  name: string,
  email: string,
  jobTitle: string,
  avatarUrl: string,
  form_count: number,
  isDataOnlyUser: string,
  accountLockout: string,
  avatar: string,
  role: RolesType,
  isOwner: boolean,
  userJoined: boolean
}

export type PrettyUser = Merge<User, {
  created_at: Date | string | null,
  updated_at: Date | string | null,
  last_seen: Date | string | null,
  fullText: string,
  avatar: string,
  visibleUserType: string,
  visibleUserName: string,
  formCount: number,
  formCountForUI: string,
}>;

export type AssetsDestination = {
  action?: 'delete' | 'transfer',
  target?: {
    icon: React.JSX.Element,
    text: string,
    value?: string,
    isTeam?: boolean
  }
};

export type NewTeamAdmin = {
  /** Target team's ID */
  teamId: string,
  /** Username of the new team admin, which will replace the deleted user */
  username: string
}

export type SortWay = 'ASC' | 'DESC' | undefined;

declare global {
  interface Window {
    jotformQuill: {
      validateFile: any
    }
  }
}

export interface HTMLUListElement extends HTMLDivElement {
  compact: boolean,
  type: string
}

export type TeamProps = {
  id?: string,
  name?: string,
  showCoverImage?: string,
  teamAvatarIconBgColor?: string,
  teamAvatarIconColor?: string,
  teamAvatarURL?: string,
  teamAvatarIcon?: string,
  teamAvatarEmojiId?: string,
  coverTitleColor?: string,
  teamAvatarIconSvgRef?: string,
  coverBgImageURL?: string,
  coverBgColor?: string,
  coverBgImageCropInfo?: string,
  members?: { [key: string]: any}[],
  slug?: string,
  memberCount?: string | number,
  defaultMemberRole?: TeamRoleSlugs,
  privacy?: string,
  invitationSent?: boolean,
  workspaceVisibility?: string
};

export type ServerTeamSettingsType = {
  teamCreation?: string,
  teamBrowsing?: string,
  teamNewUserInvitation?: string
}

export type AvatarType = {
  teamAvatarIconBgColor?: string,
  teamAvatarIconColor?: string,
  teamAvatarURL?: string,
  teamAvatarIcon?: string,
  teamAvatarEmojiId?: string,
  teamAvatarIconSvgRef?: string
}

export type PasswordRefType = {
  validate: () => any,
  inputRef: any
};

export type LoaderProps = {
  withAnimation?: boolean,
  text?: string,
  teamAnimation?: boolean,
  mobileAnimation?: boolean
};

export type Asset = {
  username: string,
  owner: string,
  id: string,
  title: string,
  type: 'APP' | 'APPROVAL' | 'CARD' | 'LEGACY' | 'SIGN' | 'SHEET' | 'REPORT',
  url: string
}
