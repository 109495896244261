import { setFlagToLocSt } from '../utils';

const urlParams = new URLSearchParams(window.location.search);

const onConfirm = ({ actionTargetName, localStActionKey, confirm }) => {
  // A/B Test: industryDiscountForFreeWithNC
  if (!urlParams.get('idffwnc')) {
    setFlagToLocSt(localStActionKey);
  }

  window.location.href = confirm.target;
  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onConfirmSecondary = ({ actionTargetName, localStActionKey, confirmSecondary }) => {
  // A/B Test: industryDiscountForFreeWithNC
  if (!urlParams.get('idffwnc')) {
    setFlagToLocSt(localStActionKey);
  }
  window.location.href = confirmSecondary.target;
  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirmSecondary',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, localStActionKey }, isAutoVisibleNotificationMode) => {
  // A/B Test: industryDiscountForFreeWithNC
  if (!isAutoVisibleNotificationMode || !urlParams.get('idffwnc')) {
    setFlagToLocSt(localStActionKey);
  }
  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkUpToFiftyPercentDiscount = notifications => {
  let notification = notifications.find(notif => notif.name === 'upToFiftyPercentDiscount');
  const otherNotifications = notifications.filter(notif => notif.name !== 'upToFiftyPercentDiscount');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    confirmSecondary: {
      ...notification.confirmSecondary,
      callback: onConfirmSecondary
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkUpToFiftyPercentDiscount;
