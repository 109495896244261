import { useEffect } from 'react';

export const useKeyTrap = traps => {
  useEffect(() => {
    const handleKeyDown = e => {
      traps.forEach(({ keyCode, callback }) => {
        if (e.key === keyCode) {
          callback(e);
        }
      });
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};
