import React from 'react';
import { createRoot } from 'react-dom/client';
import { Loading } from '@jotforminc/loading';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const LazyPublish = safeLazy(() => import(/* webpackChunkName: "LazyPublish" */'./LazyBuilderComponents/LazyPublish'));
const LazyHIPAAComplianceWizard = safeLazy(() => import(/* webpackChunkName: "LazyHipaaComplienceWizard" */'./LazyBuilderComponents/LazyHipaaComplienceWizard'));

class BuilderUtils {
  static async openPublish(formID, formURL, type, currentTeam, currentTeamID, updateFormUrl, updateFormStatusRequest) {
    const root = createRoot(document.getElementById('app_wizards'));
    root.render(
      <React.Suspense fallback={<Loading />}>
        <LazyPublish
          formID={formID}
          formURL={formURL}
          currentTeam={currentTeam}
          currentTeamID={currentTeamID}
          updateFormUrl={updateFormUrl}
          formType={type}
          onFormPropertyChange={updateFormStatusRequest}
          onClose={() => root.unmount()}
        />
      </React.Suspense>
    );
  }

  static async openHIPAAWizard(showEnableView) {
    const root = createRoot(document.getElementById('hipaa-enforcement-section'));
    root.render(
      <React.Suspense fallback={<Loading />}>
        <LazyHIPAAComplianceWizard
          fromHipaaEnforcement={true}
          onWizardLoad={() => {
            window.HIPAAComplianceWizardReady = true;
          }}
          showHIPAAEnableModalOnLoad={showEnableView}
        />
      </React.Suspense>
    );
  }
}

window.BuilderUtils = BuilderUtils;
export default BuilderUtils;
