import React from 'react';
import { node, func, string } from 'prop-types';
import classNames from 'classnames';

const OptionTextRenderer = ({
  text, onClick = f => f, className = '', icon = null, ...rest
}) => (
  <div className={classNames('isMenuButton', className)} onClick={onClick} {...rest}>
    {icon && <div>{icon}</div>}
    {text}
  </div>
);
OptionTextRenderer.propTypes = {
  icon: node,
  text: string.isRequired,
  onClick: func,
  className: string
};

export default OptionTextRenderer;
