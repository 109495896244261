import React, { useEffect, useMemo, useRef } from 'react';
import { func, object } from 'prop-types';
import { Modal } from '@jotforminc/uikit';
import {
  ASSET_IDENTIFIER, ALL_TEXTS, userPropType,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, getUserPrefillValues
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/educationBdmFlowModal.scss';

const EducationBdmFlowModal = ({
  user,
  modalProps,
  onClose,
  logAbTestAction
}) => {
  const { AB_TEST: { EDUCATION_BDM_FLOW_MODAL } } = ASSET_IDENTIFIER;
  const target = EDUCATION_BDM_FLOW_MODAL;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  const uikitModalRef = useRef(null);
  const userDataRef = useRef({});
  const {
    firstName: filledFirstName,
    lastName: filledLastName,
    email: filledEmail,
    country: filledCountry,
    state: filledState
  } = userDataRef.current;

  const {
    nameFirst, nameLast, email, country
  } = useMemo(() => getUserPrefillValues(user), [user]);

  // eslint-disable-next-line max-len
  const hubspotFormParams = `?embed=true&firstname=${filledFirstName || nameFirst}&lastname=${filledLastName || nameLast || '-'}&email=${filledEmail || email}&country=${filledCountry || country || '-'}&state=${filledState || '-'}`;
  const hubspotUTMParams = '&utm_campaign=milestone_notification&utm_medium=notification&utm_source=hubspot_modal';

  useEffect(() => {
    const actionData = { action: 'seen', target };
    logAbTestAction(actionData);
  }, []);

  const handleCloseClick = () => {
    uikitModalRef.current.hide?.();
    const actionData = { action: 'click', target: `closeButton-${target}` };
    logAbTestAction(actionData);
  };

  const handleClose = () => {
    const actionData = { action: 'close', target };
    gaDataLayerPushClose(gaAttributes);
    logAbTestAction(actionData);
    onClose();
  };

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <iframe
        title={ALL_TEXTS.CHOOSE_TIME}
        className='hubspot-iframe meetings-iframe-container'
        src={`https://meetings.hubspot.com/austin-foley/jotform/${hubspotFormParams}${hubspotUTMParams}`}
      />
    </Modal>
  );
};

EducationBdmFlowModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  onClose: func,
  logAbTestAction: func,
  registerJotformAction: func,
  onPrimaryClick: func,
  onFormSubmit: func
};

EducationBdmFlowModal.defaultProps = {
  modalProps: {},
  onClose: f => f,
  logAbTestAction: f => f,
  registerJotformAction: f => f,
  onPrimaryClick: f => f,
  onFormSubmit: f => f
};

export default EducationBdmFlowModal;
