/* eslint-disable complexity */
import { ABTestManager } from '@jotforminc/abtest-manager';
import {
  getFirstAvailableEnterprisePromotion,
  enterprisePromotionSingleton
} from '@jotforminc/ep-utils';
import { notificationSingleton } from '@jotforminc/notification-center';
import { getUrlParameter, setCookie, updateUserSettings } from '@jotforminc/utils';
import { isEnterprise as isEnterpriseFunc } from '@jotforminc/enterprise-utils';
import { checkUXRSurvey } from '@jotforminc/uxr-survey-modal';
import { StorageHelper } from '@jotforminc/storage-helper';
import { getLocalStorageWithExpiry, getLastSelectedFolder, guestChecker } from '.';
import * as API from '../api';
import {
  LISTING_TYPES, LISTING_TYPES_REVERT, MODAL_PROPERTIES, ALL_ASSETS_ID
} from '../constants';
import WatchmanRecorder from './WatchmanRecorder';
import workflowFeedbackExcludedUsers from '../modals/RatingModal/workflowFeedbackExcludedUsers';

export const isGuest = user => user?.accountType === 'GUEST' || user?.accountType?.name === 'GUEST' || user?.account_type === 'GUEST' || user?.account_type?.name === 'GUEST';
const { PLATFORM_ENV = '' } = window;
const isEnterprise = isEnterpriseFunc();
const isPlatformEnvironment = PLATFORM_ENV !== '';

const isTestingEnv = window.JOTFORM_ENV === 'TESTING';
const isProductLaunch = !!window.jfHeader_productLaunch;

const getJSCreatedAt = ({ created_at: createdAt } = {}) => {
  if (!createdAt) {
    return new Date();
  }

  const dateParts = createdAt.split('-');
  const jsUserCreatedAt = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
  return jsUserCreatedAt;
};

// Saleforce Increase limits check date for double limit
const showSalesforceEarlyAccessModal = dateStr => {
  const date = new Date(dateStr);
  const currentDate = new Date();

  // Get the date 3 months ago from today
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  return date > threeMonthsAgo;
};

const checkFormCopilotModalSeen = () => StorageHelper.getLocalStorageItem({ key: 'formCopilotModalSeen' });
const setFormCopilotModalSeen = () => StorageHelper.setLocalStorageItem({ key: 'formCopilotModalSeen', value: '1' });

const checkFirstVisitDoneLS = () => StorageHelper.getLocalStorageItem({ key: 'firstVisitDone' });
const setFirstVisitDoneLS = () => StorageHelper.setLocalStorageItem({
  key: 'firstVisitDone', value: '1'
});

const isUserCreatedToday = creds => {
  if (!creds) return false;
  const todayDate = new Date();
  const jsUserCreatedAt = getJSCreatedAt(creds);
  return jsUserCreatedAt.toDateString() === todayDate.toDateString();
};

export function setFirstVisitDone(user) {
  if (isGuest(user)) return;
  const isTodaySignupDay = isUserCreatedToday(user);
  const isUserDoneFirstVisit = checkFirstVisitDoneLS();
  if (isTodaySignupDay && !isUserDoneFirstVisit) {
    setFirstVisitDoneLS();
  }
}

// the industry modal should open
// - after the user's first visit to the /myforms,
// - user shouldn't have industry data,
// - user shouldn't have checked "the never show this again" option before.
export const shouldIndustryModalOpen = async ({
  credentials,
  isMobileApp,
  userSettings = {}
}) => {
  if (window.location.search.indexOf('ind-shuffle') > -1) {
    return true;
  }

  if (!checkFirstVisitDoneLS()) {
    setFirstVisitDone(credentials);
    return false;
  }

  if (
    isMobileApp
    || isEnterprise
    || isPlatformEnvironment
    || isGuest(credentials)
    || !isUserCreatedToday(credentials)
    || !credentials.created_at
    || window.jfHeader_productLaunch
  ) {
    return false;
  }

  const {
    neverShowIndustryModal, industry
  } = userSettings;
  const isSatisfiedIndustry = !industry;
  const isSatisfiedShowInfoModal = neverShowIndustryModal !== '1';
  if (isSatisfiedIndustry && isSatisfiedShowInfoModal) {
    return true;
  }
  return false;
};

export function shouldShowNotVerifiedModal({ credentials }) {
  const isVerified = credentials.is_verified === '1';
  const didSkipToday = document.cookie.match('verifyEmailModal');
  const getRussiaParam = new URLSearchParams(window.location.search).get('vfru');
  const getEnglandParam = new URLSearchParams(window.location.search).get('vfgb');

  if (window.showRussiaVerificationModal || getRussiaParam) {
    WatchmanRecorder.trackEvent('seen', 'email-verification-modal', 'validationForRussiaUsers');
    return true;
  }
  if (window.showEnglandVerificationModal || getEnglandParam) {
    WatchmanRecorder.trackEvent('seen', 'email-verification-modal', 'validationForEnglandUsers');
    return true;
  }
  if (isVerified || isEnterprise || isPlatformEnvironment || isProductLaunch || didSkipToday) {
    return false;
  }
  if (typeof window.validationforAfricaUsersModal !== 'undefined') {
    if (window.validationforAfricaUsersModal === 'active') {
      WatchmanRecorder.trackEvent('seen', {}, 'validationforAfricaUsers');
      return true;
    }
  } else if (window.GDPR || !window.preventAccountDialogues) {
    const estTime = new Date(); // get local time to be calculated into EST
    estTime.setHours(estTime.getHours() + estTime.getTimezoneOffset() / 60 - 5);
    const todayDate = new Date(estTime);
    const jsUserCreatedAt = getJSCreatedAt(credentials);
    const relaseDate = new Date('2017-10-01');
    if (!credentials.email || jsUserCreatedAt.toDateString() === todayDate.toDateString() || jsUserCreatedAt < relaseDate) {
      if (!window.GDPR) {
        return false; // do not show modal if user signed up today
      }
    }
  }
  return true;
}

export function onNotVerifiedModalClosed() {
  setCookie('verifyEmailModal', '1', 1);
}

export async function shouldShowEmailBouncedModal() {
  try {
    const response = await API.fetchUserEmailBounce();
    const result = response && typeof response.content === 'boolean' ? response.content : response;
    return result;
  } catch (err) {
    return false;
  }
}

export async function shouldShowHIPAAEnforcementModal({ credentials }) {
  const params = new URLSearchParams(window.location.search);
  const isForced = params.get('forceHipaaEnforcement');
  const { isHIPAA: isHIPAACheck, display_hipaa_enforcement_warning: displayStep } = credentials;
  const isHIPAA = isHIPAACheck === '1';
  if (isHIPAA) {
    return false;
  }
  const hipaaEnforcementEnableLaterCookie = document.cookie.match('hipaa-enforcement-enable-later');

  let showModalToTerminatedUser = false;
  try {
    const isTerminatedUser = await API.fetchHIPAAEnforcementTerminatedUser();

    if (isTerminatedUser === '1') {
      showModalToTerminatedUser = true;
    }
  } catch (error) {
    console.log('[Enforcement Check]', error);
  }

  const hipaaEnforcementWizardSteps = ['1', '2'];
  if (isForced || showModalToTerminatedUser || (
    credentials && credentials.display_hipaa_enforcement_warning && hipaaEnforcementWizardSteps.indexOf(displayStep) !== -1 && !hipaaEnforcementEnableLaterCookie
  )) {
    return true;
  }
  return false;
}

export async function shouldShowJotformersTeamModal() {
  const { location } = window;
  const isFlagAvailable = location.href.indexOf('JotformersTeamModal') > -1;
  const isJotformers = location.host.indexOf('www.jotformers.com') > -1;
  const isForceFlagAvailable = location.href.indexOf('force=1') > -1;
  const isEnabled = isForceFlagAvailable || (isEnterprise && isFlagAvailable && isJotformers);
  return isEnabled;
}

export async function shouldShowAbandonedUserModal({ credentials }) {
  const params = new URLSearchParams(window.location.search);
  const isForced = params.get('forceAbandonedUser');
  const isAbandonedUser = credentials?.abandonedUser === '1';
  return isForced || (isAbandonedUser && !isEnterprise && !isPlatformEnvironment && !isTestingEnv);
}

export async function shouldShowLaunchModal({ launchAvailable = false }) {
  if (!window.location.href.includes('showLaunchModal=1')) return false;

  const { EXPERIENCE_LAUNCH_MODAL: { name } } = MODAL_PROPERTIES;
  const seenLaunchModal = getLocalStorageWithExpiry(`seen-${name}`) !== null;
  const isRedirectedFromLanding = window.location.href.includes('createTeam=1');
  return launchAvailable && !isEnterprise && !isPlatformEnvironment && !isTestingEnv && !seenLaunchModal && !isRedirectedFromLanding;
}

export async function shouldCvShowForceSignupModal({ currentPage, credentials }) {
  if (isEnterprise || isPlatformEnvironment || isTestingEnv) {
    return false;
  }

  const modalSeen = StorageHelper.getLocalStorageItem({ key: 'cv-seenForceSignupModalBefore' });
  if (
    modalSeen
    || [LISTING_TYPES.FORM, LISTING_TYPES.MIX].indexOf(currentPage) === -1
    || !isGuest(credentials)
    || parseInt((credentials?.usage?.form_count || 0), 10) === 0
  ) {
    return false;
  }

  const abTestManager = new ABTestManager({
    isTestEnabled: true,
    testName: 'cvForceSignupModal',
    urlParam: 'cvfsm',
    user: credentials,
    controlVariantCode: '13761',
    testVariantCode: [{
      code: '13771',
      urlParam: 'cvfsm-b'
    }, {
      code: '13781',
      urlParam: 'cvfsm-c'
    }]
  });

  try {
    const [isTestVar, cvForceSignupModalVariationCode] = await abTestManager.isTestVariant();
    return isTestVar ? cvForceSignupModalVariationCode : false;
  } catch {
    return false;
  }
}

export function shouldShowAIBuilder(user) {
  const AITestVariantID = '16861';
  const { aiFormBuilderBetaUser } = user?.credentials;
  const featureFlagEnabled = window.location.href.indexOf('createAIForm=true') > -1;

  return aiFormBuilderBetaUser === AITestVariantID || featureFlagEnabled;
}

export async function shouldShowFormCopilotModal({ credentials, currentPage, list: formList }) {
  if (isEnterprise || isPlatformEnvironment || isTestingEnv || isGuest(credentials) || guestChecker(credentials?.username || '')) return false;
  if (currentPage !== LISTING_TYPES.FORM) return false;
  if (window.location.href.includes('formCopilot=1')) return true;
  if (window.location.href.includes('formAssistant=1')) return true;

  const {
    formCopilotNewSignUp,
    formCopilotExistingUsers,
    aiAgentBetaUser, aiAgentExistingUserAB,
    aiAgentNewUserAB, isHIPAA, euOnly, aiFormBuilderIncrementalUpdateTest, aiFormBuilderBetaUser
  } = credentials;
  if (!formCopilotExistingUsers && !formCopilotNewSignUp) {
    const abTestManager = new ABTestManager({
      user: credentials,
      isTestEnabled: true,
      testName: 'formCopilotExistingUsers',
      testVariantCode: [{
        code: '28192',
        urlParam: 'formCopilotModalEnabled'
      }, {
        code: '28571',
        urlParam: 'formAssistant'
      }],
      controlVariantCode: '28182',
      urlParam: 'formCopilotModalEnabled',
      customUserChecks: {
        isNotExistingAIAgent: !['26421'].includes(aiAgentExistingUserAB),
        isNotNewAIAgent: !['26441'].includes(aiAgentNewUserAB),
        isNotHIPAA: isHIPAA !== '1',
        isNotEU: euOnly !== '1',
        isNotAIAgentBetaUser: aiAgentBetaUser !== '1',
        isNotBuilderCopilotUser: !(aiFormBuilderIncrementalUpdateTest === '22601' || aiFormBuilderBetaUser === '16861')
      }
    });

    return abTestManager
      .isTestVariant()
      .then(([isTestVariant]) => {
        if (isTestVariant && formList.length > 0 && !checkFormCopilotModalSeen()) {
          setFormCopilotModalSeen();
          return true;
        }
        return false;
      })
      .catch(() => {});
  }
  if ((formCopilotNewSignUp === '28181' || formCopilotExistingUsers === '28192' || formCopilotExistingUsers === '28571') && !checkFormCopilotModalSeen() && formList.length > 0) {
    setFormCopilotModalSeen();
    return true;
  }
  return false;
}

export async function shouldShowTemplateSuggestionModal({
  currentPage,
  credentials,
  totalCount
}) {
  if (isEnterprise || isPlatformEnvironment || isTestingEnv) return false;
  if (window.location.href.includes('AIFormCreator') || window.location.href.includes('AIFormBuilder')) return false;
  if (window.location.href.includes('showTemplateSuggestionModal=1')) return true;
  const listingType = currentPage === LISTING_TYPES.MIX ? LISTING_TYPES.FORM : currentPage;
  const isDisabled = StorageHelper.getLocalStorageItem({ key: `showTemplateSuggestionModal-${LISTING_TYPES_REVERT[listingType]}` });

  if (isDisabled && !isGuest(credentials) && credentials?.[`showTemplateSuggestionModal-${LISTING_TYPES_REVERT[listingType]}`] !== '1') {
    try {
      await updateUserSettings({ [`showTemplateSuggestionModal-${LISTING_TYPES_REVERT[listingType]}`]: '1' });
    } catch {
      // eslint-disable-next-line no-console
      console.error('Error while updating user settings');
    }

    return false;
  }

  if (isDisabled || credentials?.[`showTemplateSuggestionModal-${LISTING_TYPES_REVERT[listingType]}`] === '1' || ![
    LISTING_TYPES.FORM, LISTING_TYPES.SHEET, LISTING_TYPES.PORTAL, LISTING_TYPES.DOCUMENT, LISTING_TYPES.WORKFLOW
  ].includes(listingType)) {
    return false;
  }

  if ([LISTING_TYPES.FORM].includes(listingType) && getLastSelectedFolder(listingType) !== ALL_ASSETS_ID[LISTING_TYPES.FORM]) {
    return false;
  }

  if (listingType === LISTING_TYPES.PORTAL && getLastSelectedFolder(listingType) !== ALL_ASSETS_ID[LISTING_TYPES.PORTAL]) {
    return false;
  }

  if (totalCount === undefined || parseInt(totalCount, 10) > 0) {
    return false;
  }

  const isMixedListingAbTestUser = credentials?.mixedListingAbTestNewUser === '1' || credentials?.mixedListingAbTestExistingUser === '1';

  if (isMixedListingAbTestUser) return false;

  if (LISTING_TYPES.SHEET === listingType) {
    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'templateSuggestionModalOnMyTables',
      controlVariantCode: '15191',
      testVariantCode: '15201',
      urlParam: 'tsmomt',
      user: credentials
    });

    try {
      const getTestVariant = await abTestManager.isTestVariant();
      return getTestVariant;
    } catch (e) {
      return false;
    }
  } else if (LISTING_TYPES.PORTAL === listingType) {
    return true;
  } else if (LISTING_TYPES.DOCUMENT === listingType) {
    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'templateSuggestionModalOnMySignDocuments',
      controlVariantCode: '15341',
      testVariantCode: '15351',
      urlParam: 'tsmoms',
      user: credentials
    });

    try {
      const getTestVariant = await abTestManager.isTestVariant();
      return getTestVariant;
    } catch (e) {
      return false;
    }
  } else if (LISTING_TYPES.WORKFLOW === listingType) {
    const isEnabled = true;
    const hasUrlParam = getUrlParameter('wftsm') === '1';
    return isEnabled || hasUrlParam;
  } else {
    return true;
  }
}

export function shouldShowMyworkspaceSuggestionModal({ credentials, currentPage, totalCount = 0 }) {
  const SUGGESTION_MODAL_ENABLED_SERVERS = [
    'userresearch.jotform.com'
  ];

  const forceMyworkspaceSuggestionModal = window.location.search.includes('forceMyworkspaceSuggestionModal');
  const {
    mixedListingBetaUser, mixedListingFeedbackSeen, mixedListingBetaUserCandidate, mixedListingBetaEnrollmentRejected, mixedListingAbTestNewUser, mixedListingAbTestExistingUser
  } = credentials || {};

  const isMixedListingAbTestUser = mixedListingAbTestNewUser === '1' || mixedListingAbTestExistingUser === '1';

  const date1 = new Date();
  const date2 = new Date(mixedListingFeedbackSeen);

  const is24HoursPassedSinceLastSkip = mixedListingFeedbackSeen === undefined ? true : Math.floor(Math.abs(date1?.getTime?.() - date2?.getTime?.()) / 36e5) >= 24;

  if (SUGGESTION_MODAL_ENABLED_SERVERS.includes(window.location.host) || isMixedListingAbTestUser) {
    return forceMyworkspaceSuggestionModal || (
      [LISTING_TYPES.MIX].includes(currentPage)
      && mixedListingBetaEnrollmentRejected !== '1'
    );
  }

  return forceMyworkspaceSuggestionModal || (
    !isEnterprise
    && !isPlatformEnvironment
    && !isTestingEnv
    && mixedListingBetaUser !== '1'
    && is24HoursPassedSinceLastSkip
    && !isGuest(credentials)
    && totalCount
    && parseInt(totalCount, 10) > 0
    && [LISTING_TYPES.TEAM_PAGE, LISTING_TYPES.FORM].includes(currentPage)
    && mixedListingBetaEnrollmentRejected !== '1'
    && mixedListingBetaUserCandidate === '1');
}

export async function shouldShowAnnouncingLightbox() {
  const seenAnnouncingModal = StorageHelper.getLocalStorageItem({ key: 'announcing-salesforce-lightbox' }) !== null;
  return false && (!isEnterprise && !isPlatformEnvironment && !isTestingEnv && !seenAnnouncingModal);
}

export async function shouldShowListingsIncreaseModal({ credentials }) {
  if (getUrlParameter('ilnm') === '1') {
    return true;
  }

  const isDialogSeenOnce = StorageHelper.getLocalStorageItem({ key: 'listingsIncreaseLimitsModal' });

  if (isDialogSeenOnce || isTestingEnv || isEnterprise || isPlatformEnvironment || isGuest(credentials)) return false;

  const accountType = credentials?.accountType ?? credentials?.account_type?.name;
  const formCount = credentials?.usage?.form_count;

  if (accountType === 'FREE' && formCount > 3) {
    return true;
  }

  return false;
}

// todo: remove obsolete utility
export async function shouldShowEnterpriseAusModal({ credentials }) {
  const { username } = credentials;
  const enterpriseAusText = 'jotform-enterprise-aus';

  const enterpriseAusUrlParam = window.location.href.includes(`${enterpriseAusText}=1`);
  if (enterpriseAusUrlParam) return true;

  const seenEnterpriseAusModal = getLocalStorageWithExpiry(enterpriseAusText) !== null;
  if (seenEnterpriseAusModal || isTestingEnv || isEnterprise || isPlatformEnvironment || isGuest(credentials)) return false;
  try {
    const result = await API.isAustralianEnterpriseUser(username);
    return result === true;
  } catch (err) {
    return false;
  }
}

export const getActiveCampaign = ({
  nonprofitAvailable,
  endOfYearAvailable,
  summerSaleAvailable,
  blackFridayAvailable,
  blackFridayCheckoutAvailable,
  bonusCampaignAvailable = false,
  educationAvailable,
  isBackToPaidCampaignAvailable,
  oneDollarSilverCampaignAvailable,
  springSaleAvailable,
  animalShelterAvailable,
  churchAvailable
}) => {
  switch (true) {
    case blackFridayAvailable:
      return {
        campaignName: 'BLACKFRIDAY',
        cookieKey: 'Bf',
        campaignKey: 'bf'
      };
    case endOfYearAvailable:
      return {
        campaignName: 'EOY',
        cookieKey: 'Eoy',
        campaignKey: 'eoy'
      };
    case bonusCampaignAvailable:
      return {
        campaignName: 'BONUS',
        cookieKey: 'Bonus',
        campaignKey: 'bonus'
      };
    case summerSaleAvailable:
      return {
        campaignName: 'SUMMER',
        cookieKey: 'Summer',
        campaignKey: 'summer'
      };
    case nonprofitAvailable:
      return {
        campaignName: 'NONPROFIT',
        cookieKey: 'Nonprofit',
        campaignKey: 'nonprofit'
      };
    case educationAvailable:
      return {
        campaignName: 'EDUCATION',
        cookieKey: 'Education',
        campaignKey: 'education'
      };
    case isBackToPaidCampaignAvailable:
      return {
        campaignName: 'BACKTOPAID',
        cookieKey: 'BackToPaid',
        campaignKey: 'return-to-paid'
      };
    case animalShelterAvailable:
      return {
        campaignName: 'ANIMAL-SHELTER',
        cookieKey: 'AnimalShelter',
        campaignKey: 'animal-shelter'
      };
    case springSaleAvailable:
      return {
        campaignName: 'SPRINGSALE',
        cookieKey: 'SpringSale',
        campaignKey: 'spring-sale'
      };
    case churchAvailable:
      return {
        cookieKey: 'Church',
        campaignKey: 'church',
        campaignName: 'CHURCH'
      };
    case oneDollarSilverCampaignAvailable:
      return {
        cookieKey: 'OneDollarSilver',
        campaignKey: 'onedollarsilver',
        campaignName: 'ONEDOLLARSILVER'
      };
    case blackFridayCheckoutAvailable:
      return {
        cookieKey: 'BlackFridayCheckout',
        campaignKey: 'blackfridaycheckout',
        campaignName: 'BLACKFRIDAYCHECKOUT'
      };
    default:
      return false;
  }
};

export async function shouldShowCampaignLightbox({
  blackFridayAvailable, blackFridayCheckoutAvailable, isNewUserCampaignAvailable, nonprofitAvailable, isBackToPaidCampaignAvailable,
  userAccountType, userSettings = {}, endOfYearAvailable, summerSaleAvailable, springSaleAvailable,
  oneDollarSilverCampaignAvailable
}) {
  const { campaign = {} } = userSettings;
  const { industryCampaign, industryCampaignAssetVersion } = campaign;

  const activeCampaign = getActiveCampaign({
    blackFridayAvailable,
    blackFridayCheckoutAvailable,
    endOfYearAvailable,
    summerSaleAvailable,
    nonprofitAvailable,
    isBackToPaidCampaignAvailable,
    oneDollarSilverCampaignAvailable,
    springSaleAvailable,
    animalShelterAvailable: campaign?.industryCampaign === 'ANIMAL-SHELTER',
    churchAvailable: campaign?.industryCampaign === 'CHURCH',
    educationAvailable: campaign?.industryCampaign === 'EDUCATION'
  });

  const currentYear = new Date().getFullYear();
  const dismissCampaignLightbox = getLocalStorageWithExpiry(`dismiss${activeCampaign.cookieKey}CampaignLightbox-${currentYear}`) !== null;
  if ((industryCampaign === 'EDUCATION' && ['v1', 'v2'].includes(industryCampaignAssetVersion)) || (industryCampaign === 'CHURCH' && ['v1', 'v2'].includes(industryCampaignAssetVersion)) || (
    industryCampaign === 'ANIMAL-SHELTER' && ['v1', 'v2'].includes(industryCampaignAssetVersion)
  )) {
    return !dismissCampaignLightbox;
  }

  const noCampaignAccountTypes = ['GOLD', 'GUEST'];
  if (noCampaignAccountTypes.includes(userAccountType)) return false;
  if (isEnterprise || isTestingEnv || isPlatformEnvironment) return false;

  const nonprofitLightboxQueryParam = window.location.href.includes('enableNonprofitCampaign=1');
  if (nonprofitLightboxQueryParam) return true;

  if (!activeCampaign) return false;
  return !isNewUserCampaignAvailable && !dismissCampaignLightbox;
}

export async function shouldShowUxrSurveyModal({ credentials }) {
  try {
    const { location: { pathname = 'myforms' } } = window;
    const uxrSurveyFormID = await checkUXRSurvey(pathname.split('/').join(''), isGuest(credentials), credentials?.username);
    window.uxrSurveyFormID = uxrSurveyFormID;
    return uxrSurveyFormID;
  } catch (err) {
    // error
  }
}

export function shouldShowMyformsBanner({ credentials = {} }) {
  const {
    excludeCampaignAssets = false
  } = credentials;

  if (
    isTestingEnv
    || isEnterprise
    || isPlatformEnvironment
    || excludeCampaignAssets === '1'
  ) return false;

  return (
    window?.myformsBanner !== false && !document.cookie.match(`myFormsBanner_${window?.myformsBanner?.banner_id}`)
  );
}

export function shouldShowBonusCampaignPopup(/* { isNewUserCampaignAvailable, credentials } */) {
  return false;
  /* if (window.innerWidth <= 768) return false;

  const { location: { href: URL } } = global;
  if (URL.includes('?bonusCampaignAvailable=1')) return true;

  const closedBonusCampaignPopup = getLocalStorageWithExpiry('close-bonus-campaign-popup') !== null;
  if (closedBonusCampaignPopup) return false;

  if (!isNewUserCampaignAvailable) return false;
  const { account_type: { name: accountType } } = credentials;
  if (accountType === 'GUEST') return true;
  return false; */
}

export function shouldShowSalesforceEarlyAccessModal(user) {
  const showNewerThanThreeMonthsUsers = showSalesforceEarlyAccessModal(user?.credentials?.created_at);

  if (isTestingEnv || isEnterprise || isPlatformEnvironment || !showNewerThanThreeMonthsUsers) return false;

  if (window.location.href.includes('showSalesforceEarlyAccessModal=1')) return true;

  const discarded = StorageHelper.getLocalStorageItem({ key: 'discardSalesforceEarlyAccessModal' }) !== null;
  if (discarded) return false;

  return user?.credentials?.salesforce_integration_user === '1';
}

export function shouldShowAgentBetaLaunchModal(user = {}, currentPage) {
  if (isTestingEnv || isEnterprise || isPlatformEnvironment || currentPage === LISTING_TYPES.AGENT) return false;

  const { credentials = {} } = user;
  const {
    aiAgentNewUserAB, aiAgentExistingUserAB, aiAgentBetaUser, dismissAiAgentBetaLightbox = false
  } = credentials;
  if (dismissAiAgentBetaLightbox) return false;
  return aiAgentNewUserAB === '26441' || aiAgentExistingUserAB === '26421' || aiAgentBetaUser === '1' || window.location.href.includes('agentBetaLB=1');
}

export function shouldShowWorkflowLaunchModal(user = {}, currentPage) {
  if (isTestingEnv || isEnterprise || isPlatformEnvironment || currentPage === LISTING_TYPES.AGENT) return false;

  const dismissCampaignLightbox = getLocalStorageWithExpiry('dismissWorkflowsCampaignLightbox-2024') !== null;
  if (dismissCampaignLightbox) return false;

  const {
    credentials: {
      campaign: {
        workflowLaunchAssetsAvailable,
        workflowLaunchAssetsVersion
      } = {}
    } = {}
  } = user;
  return (workflowLaunchAssetsAvailable && workflowLaunchAssetsVersion === 'v2') || window.location.href.includes('workflows=1');
}

export function shouldShowWorkflowsRatingModal(user, currentPage, totalCount = 0) {
  if (isTestingEnv || isEnterprise) return false;

  if (window.location.href.includes('workflowsRatingModal=1')) return true;

  const hasAnyWorkflow = totalCount > 0;
  const isWorkflowListingPage = LISTING_TYPES.WORKFLOW === currentPage;
  if (!hasAnyWorkflow || !isWorkflowListingPage) return false;

  const { credentials = {} } = user || {};
  const { username, discardWorkflowsRatingModal = false } = credentials;
  if (discardWorkflowsRatingModal || workflowFeedbackExcludedUsers.includes(username)) return false;

  return true;
}

export function shouldShowSalesforceRatingModal(user) {
  // also must visible on salesforce platform
  if (isTestingEnv || isEnterprise) return false;

  if (window.location.href.includes('showSalesforceRatingModal=1')) return true;

  if (window.location.href.includes('debugLocalStorage')) console.log('getLocalStorageWithExpiry', StorageHelper.getLocalStorageItem({ key: 'discardSalesforceRatingModal' }));
  return user?.credentials?.salesforceRatingModal === '1';
}

export function shouldShowReactivationAnnouncement({ credentials }) {
  const isSeenBefore = StorageHelper.getLocalStorageItem({ key: 'reactivationAnnouncement' }) === '1';
  const hasReactivationAnnouncement = credentials?.reactivationAnnouncement;
  if (!isSeenBefore && hasReactivationAnnouncement) {
    return true;
  }
  return false;
}

export async function shouldShowEnterprisePromotionAsset({ credentials }) {
  if (isGuest(credentials)) return false;

  let showAsset = false;

  try {
    const {
      show, assetType, seenAtKey, details
    } = await getFirstAvailableEnterprisePromotion();

    if (show) {
      showAsset = true;

      enterprisePromotionSingleton.assetType = assetType;
      enterprisePromotionSingleton.seenAtKey = seenAtKey;
      enterprisePromotionSingleton.details = details;
    }
  } catch (error) {
    showAsset = false;
  }

  return showAsset;
}

export async function shouldShowMobileAppQRCode({ credentials: user = {} }) {
  if (isGuest(user) || global?.screen?.width <= 769) return false;

  let isTestVariant = false;
  const mobileAppQRCodeNeverShow = StorageHelper.getLocalStorageItem({ key: 'neverShowMobileAppQRCodeAgain' });
  const getQRParam = new URLSearchParams(window.location.search).get('mobileAppQr');
  const showQR = getQRParam === '1';

  if (showQR) return true;

  try {
    const isDownloadedMobileApp = await API.checkIfMobileAppDownloaded(user?.username);
    const isDE = user?.location?.country_code === 'DE';

    if (!isDE || isDownloadedMobileApp?.data?.content !== false) return false;

    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'myformsMobileQRModal',
      testVariantCode: '7831',
      controlVariantCode: '7821',
      urlParam: 'mobileAppQr',
      cacheVariantCodeAtLocalStorage: true
    });

    const getTestVariant = await abTestManager.isTestVariant();
    isTestVariant = getTestVariant;

    return (isTestVariant && !mobileAppQRCodeNeverShow);
  } catch (error) {
    return false;
  }
}

export async function shouldShowEarthquakeDonateModal({ credentials }) {
  const { billing_payment_method: billingPaymentMethod } = credentials;
  const seenEarthquakeDonateModal = StorageHelper.getLocalStorageItem({ key: 'dontShowEarthquakeDonationModalAgain' });
  const urlParams = new URLSearchParams(window.location.search);
  const donateParam = urlParams.get('donate');

  // TODO: last payment gateway check
  if (!donateParam || !billingPaymentMethod || seenEarthquakeDonateModal) {
    return false;
  }

  let billingPaymentMethodObj;
  if (typeof billingPaymentMethod !== 'object') {
    try {
      billingPaymentMethodObj = JSON.parse(billingPaymentMethod);
    } catch (e) {
      console.error(e);
    }
  } else {
    billingPaymentMethodObj = billingPaymentMethod;
  }

  return billingPaymentMethodObj?.metadata?.gateway?.includes('ADYEN');
}

export async function shouldShowAutoVisibleNotification({
  credentials, userType, currentPage, accountTypeName, enterpriseMode = false
}) {
  const isNcAvailable = !isGuest(credentials) && userType !== 'FORM_USER' && [LISTING_TYPES.FORM, LISTING_TYPES.MIX].indexOf(currentPage) > -1;

  if (!isNcAvailable) return false;
  if (enterpriseMode && accountTypeName !== 'ADMIN') return false;

  const autoVisibleNotification = notificationSingleton.notifications?.find(notif => notif.isAutoVisible);

  if (autoVisibleNotification) {
    notificationSingleton.autoVisibleNotification = autoVisibleNotification;
    return true;
  }

  return false;
}

export async function shouldShowReactivationModal({
  userAccountType, username, callback
}) {
  const reactivationDataKeys = ['currency', 'diffPrices', 'firstName', 'period', 'plan', 'planPrice', 'planPriceNew', 'remainDate'];

  const params = new URLSearchParams(window.location.search);
  const isForced = params.get('forceReactivationModal') === '1';
  const localStorageKey = 'remainReactivationModal--seen';
  const hasShownBefore = getLocalStorageWithExpiry(localStorageKey) === '1';

  // if forced show modal with mock data and dont trigger any action
  try {
    if (isForced) {
      callback({
        modalData: {
          username,
          currency: 'USD',
          diffPrices: '120$',
          firstName: username,
          period: 'month',
          plan: 'Silver Plan',
          planPrice: 39,
          planPriceNew: 49,
          remainDate: 'July 21, 2023',
          localStorageKey,
          isForced
        }
      });
      return isForced;
    }
    // not gonna send a request if user has seen it before
    if (!hasShownBefore && userAccountType && userAccountType === 'FREE') {
      const res = await API.getReactivationCampaignDetails(username);

      const reactivationData = res?.data?.content;
      const isReactivationAvailable = !!reactivationData;
      const isValid = reactivationDataKeys.every(key => reactivationData[key]); // check if all keys are provided

      /*
        Modals are not wrapped with state provider so we cant use a saga here
        Not a good solution but there should be a singleton object to store dynamic modal data -> assetSelectorSingleton
      */
      callback({
        modalData: {
          ...reactivationData, username, isForced: false, localStorageKey
        }
      });

      return (isReactivationAvailable && isValid);
    }
    return false;
  } catch (e) {
    return false;
  }
}

export const isMobileDevice = () => {
  // eslint-disable-next-line no-undef
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};
