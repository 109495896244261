import Content from './AssetRenderers/Content';
import Icon from './AssetRenderers/Icon';

const RENDERERS = {
  'LISTING@PORTAL': {
    IconRenderer: Icon,
    ContentRenderer: Content
  },
  'LISTING@REPORT': {
    IconRenderer: Icon,
    ContentRenderer: Content
  },
  'LISTING@TASK': {
    IconRenderer: Icon,
    ContentRenderer: Content
  },
  'LISTING@DOCUMENT': {
    IconRenderer: Icon,
    ContentRenderer: Content
  },
  'LISTING@AGENT': {
    IconRenderer: Icon,
    ContentRenderer: Content
  }
};

export const getRenderers = listingType => {
  return RENDERERS[listingType];
};
