/* eslint-disable camelcase */

import { requestLayer } from './requestLayer';
import type {
  ApiCallWithLayer,
  ChangeUserSettings,
  CreateAIFormResponse,
  CreateAIFormType,
  HTTPMethods,
  LogActions,
  SaveFullStorySessionURL,
  SendAIFormBuilderBetaAgreementSubmission
} from '../typings/api';

export const apiCallWithLayer: ApiCallWithLayer = ({
  path, method = 'get', data = {}, params = {}, options = {}, callback = ({ data: responseData }) => { console.log({ responseData }); return responseData; }
}) => {
  return requestLayer[method.toLocaleLowerCase() as HTTPMethods](path, data, {
    params: { ...params },
    ...options,
    headers: {
      ...options?.headers
    }
  }).then(response => callback(response as any)) as any;
};

export const createAIForm: CreateAIFormType = ({ prompt = '' }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const phpBaseParam = urlParams.get('usePhpBase');

  return apiCallWithLayer<CreateAIFormResponse>({
    path: `create${phpBaseParam ? `?usePhpBase=${phpBaseParam}` : ''}`,
    method: 'post',
    data: {
      message: prompt
    }
  });
};

export const sendAIFormBuilderBetaAgreementSubmission: SendAIFormBuilderBetaAgreementSubmission = user => {
  const formData = new FormData();
  formData.append('formID', '240313656520953');
  formData.append('q6_fullName', user.name || '');
  formData.append('q5_email', user.email || '');
  formData.append('q4_username', user.username || '');
  formData.append('q7_betaAgreement', '1.0');

  return apiCallWithLayer({
    path: 'https://www.jotform.com/submit.php',
    method: 'post',
    data: formData,
    options: { withCredentials: false }
  });
};

export const changeUserSettings: ChangeUserSettings = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]: [string, any]) => formData.append(key, value));

  return apiCallWithLayer({
    path: '/API/user/settings',
    method: 'post',
    data: formData
  });
};

export const logActions: LogActions = ({ action, target }) => {
  return apiCallWithLayer({
    path: `/ai-builder/actions/${action}/${target}`
  });
};

export const saveFullStorySessionURL: SaveFullStorySessionURL = ({ formID, fullstoryUrl }) => apiCallWithLayer({
  path: `session/${formID}`,
  method: 'post',
  data: {
    url: fullstoryUrl
  }
});
