/* eslint-disable react/prop-types */
import React from 'react';
import Styled from 'styled-components';
import Container from '../Container';

export const LoaderSvg = Styled.svg`
width: 100%;
height: 100%;

.mainRectangle {
  transform-origin: center;
  animation: scale 1.5s 0s infinite normal cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.chart1 {
  transform: translateY(60%);
  animation: chartAnim1 1.5s 0s infinite normal cubic-bezier(0, 0.88, 0.18, 1.08);
}
.chart2 {
  transform: translateY(40%);
  animation: chartAnim2 1.5s 0s infinite normal cubic-bezier(0, 0.88, 0.18, 1.08);
}
.chart3 {
  transform: translateY(55%);
  animation: chartAnim3 1.5s 0s infinite normal cubic-bezier(0, 0.88, 0.18, 1.08);
}
@keyframes chartAnim1 {
  0% { transform: translateY(60%); }
  20% { transform: translateY(0%); }
  60% { transform: translateY(0%); }
  100% {
    transform: translateY(60%);
  }
}
@keyframes chartAnim2 {
  0% { transform: translateY(40%); }
  40% { transform: translateY(40%); }
  60% { transform: translateY(0%); }
  100% {
    transform: translateY(40%);
  }
}
@keyframes chartAnim3 {
  0% { transform: translateY(55%); }
  20% { transform: translateY(55%); }
  40% { transform: translateY(3%); }
  60% { transform: translateY(3%); }
  100% {
    transform: translateY(55%);
  }
}
@keyframes scale {
  0% { transform: scale(1); }
  15% { transform: scale(1.1); }
  20% { transform: scale(1); }
  100% {
    transform: scale(1);
  }
}
`;

const ReportsLoader = () => {
  return (
    <Container>
      <LoaderSvg
        style={{ overflow: 'visible' }}
        viewBox="0 0 81.37 101.71"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              className="cls-1"
              x="9.52"
              y="3.9"
              width="62.12"
              height="74.65"
            />
          </clipPath>
        </defs>
        <path
          fill="#e4ebfc"
          className="mainRectangle"
          d="M0,12.71C0,5.69,5.4,0,12.06,0H69.32C76,0,81.37,5.69,81.37,12.71V89c0,7-5.4,12.71-12.05,12.71H12.06C5.4,101.71,0,96,0,89Z"
        />
        <g clipPath="url(#clip-path)">
          <path
            fill="#09f"
            className="chart chart1"
            d="M16.83,21.7H22.5A4.45,4.45,0,0,1,27,26.15v52.4a0,0,0,0,1,0,0H12.38a0,0,0,0,1,0,0V26.15A4.45,4.45,0,0,1,16.83,21.7Z"
          />
          <path
            fill="#ff6102"
            className="chart chart2"
            d="M38.39,38.7h5.67a4.45,4.45,0,0,1,4.45,4.45v35.4a0,0,0,0,1,0,0H33.94a0,0,0,0,1,0,0V43.15A4.45,4.45,0,0,1,38.39,38.7Z"
          />
          <path
            fill="#ffb628"
            className="chart chart3"
            d="M59.82,24.7h5.67a4.45,4.45,0,0,1,4.45,4.45v49.4a0,0,0,0,1,0,0H55.37a0,0,0,0,1,0,0V29.15A4.45,4.45,0,0,1,59.82,24.7Z"
          />
        </g>
      </LoaderSvg>
    </Container>
  );
};

export default ReportsLoader;
