import React, { ReactElement, ReactNode } from 'react';
import { t } from '@jotforminc/translation';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import { Tooltip } from '@jotforminc/tooltip';
import SearchInput from './UserTable/SearchInput';

type InputInfoWrapperProps = {
  inputValue?: string,
  placeholder?: string,
  infoTooltip?: string | ReactNode,
  onChange: (change: string) => void
}

export const InputInfoWrapper = ({
  inputValue, placeholder = 'Search', infoTooltip, onChange
}: InputInfoWrapperProps): ReactElement => {
  return (
    <div className="input-info-wrapper">
      <SearchInput
        value={inputValue}
        onChange={e => onChange(e.target.value)}
        placeholder={t(placeholder)}
      />
      {infoTooltip && (
        <span className="jfTooltipNew-hoverTarget">
          <IconInfoCircleFilled className='icon-info' color="#0099ff" />
          <Tooltip
            v2
            align={window.innerWidth < 520 ? 'End' : 'Center'}
            attach="Bottom"
          >
            {infoTooltip}
          </Tooltip>
        </span>
      )}
    </div>
  );
};

InputInfoWrapper.defaultProps = {
  inputValue: '',
  placeholder: 'Search',
  infoTooltip: null
};
