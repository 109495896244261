import { createRoot } from 'react-dom/client';
import React, { useState } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { number, string } from 'prop-types';

import useInterval from '../hooks/useInterval';

import './styles/return-to-paid-campaign.scss';

export const ReturnToPaidCampaign = ({ utmSource, accountTypeName, expirationDate }) => {
  const getDistance = () => {
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = expirationDate - now;
    return distance;
  };
  const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
  const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
  useInterval(() => {
    const distance = getDistance();
    if (distance < 0) {
      setCountdown('00 : 00 : 00');
      setDelay(null);
    }
    // Time calculations for days, hours, minutes and seconds
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) hours = `0${hours}`;

    if (minutes < 10) minutes = `0${minutes}`;

    if (seconds < 10) seconds = `0${seconds}`;

    // Output the result in an element with id="demo"
    setCountdown(`${hours}h : ${minutes}m : ${seconds}s `);
  }, delay);
  return (
    <div className="return-to-paid-campaign-container">
      <div className="return-to-paid-campaign-content-container">
        <div className="heading-counter-wrapper">
          <div className="confetti-left">
            <svg
              width="36" height="51" viewBox="0 0 36 51"
              fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="29.8887" width="3.15227" height="7.88067"
                transform="rotate(-36.8089 0 29.8887)" fill="#FF6100"
              />
              <rect
                x="34.2637" y="44" width="3.48571"
                height="8.71428" transform="rotate(71.5068 34.2637 44)" fill="#FFA800"
              />
              <g filter="url(#filter0_f_167_1098)">
                <rect
                  x="20.498" y="10.8008" width="2.44666"
                  height="6.11666" transform="rotate(-162.088 20.498 10.8008)" fill="#0099FF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_167_1098" x="14.1699" y="0.228119"
                  width="12.2094" height="14.5727" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal" in="SourceGraphic" in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_167_1098" />
                </filter>
              </defs>
            </svg>
          </div>
          <div className="heading">
            <strong className="title">{t('TODAY ONLY!')}</strong>
            <div className="discount">
              {translationRenderer('[1[SAVE]] [2[50]][3[%]]')({
                renderer1: save => <strong className="second-title">{save}</strong>,
                renderer2: rate => <strong className="third-title">{rate}</strong>,
                renderer3: percent => <strong className="four-title">{percent}</strong>
              })}
            </div>
          </div>

          <div className="counter">
            <span className="counter-title locale white">{t('Offer expires in')}</span>
            <span id="countdown-for-newuser">{countdown}</span>
          </div>
        </div>

        <div className="item button">
          <a className="locale" href={`/pricing?utm_campaign=banner&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}>
            {t('Save Now')}
          </a>
        </div>
      </div>
    </div>
  );
};

ReturnToPaidCampaign.propTypes = {
  utmSource: string.isRequired,
  expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderReturnToPaidCampaign = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<ReturnToPaidCampaign utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
