import React, {
  useState, useEffect, useMemo, forwardRef
} from 'react';
import { string } from 'prop-types';

import { SelectionGroup } from '@jotforminc/uikit';
import { IconProductBoardsFilled, IconAngleDown } from '@jotforminc/svg-icons';
import { handleCustomNavigation } from '@jotforminc/utils';
import classNames from 'classnames';

import { Button } from '@jotforminc/magnet';
import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';
import PopoverText from '../../PopoverText';

import { getFormBoards } from '../../../api';
import translate from '../../../utils/translate';
import { ListItemSharedDefaultProps } from '../../ListItem/propTypes';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';

const ButtonWithRref = () => forwardRef((props, ref) => (
  <Button
    ref={ref}
    data-testid="board-action"
    {...props}
    colorStyle="secondary"
    variant="outline"
    startIcon={IconProductBoardsFilled}
    indicatorText={translate('BETA')}
      // eslint-disable-next-line react/prop-types
    className={classNames('forBoardsButton', props.className)}
  >
    {translate('Boards')}
  </Button>
));

const OptionTextRenderer = ({ text, className = '', ...rest }) => {
  return (
    <div
      className={classNames('isMenuButton', className)}
      {...rest}
    >
      {translate(text)}
    </div>
  );
};
OptionTextRenderer.propTypes = { text: string.isRequired, className: string };

const POPOVER_PROPS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions,
    ...ListItemSharedDefaultProps.PopoverModifierProps
  },
  className: 'listings-popover-modal isMoreMenu isToolbarMoreMenu'
};

const BoardAction = ({ selectedID }) => {
  const [boardList, setBoardList] = useState([]);

  const options = useMemo(() => {
    if (boardList.length === 0) {
      return [{
        disabled: true,
        hideOnDisable: false,
        Renderer: PopoverText,
        text: <OptionTextRenderer className="noItems" text="You have no boards yet." />
      }];
    }

    return [
      ...boardList.map(({ id, name }) => ({
        Renderer: PopoverOption,
        value: `/boards/${id}`,
        text: (
          <OptionTextRenderer
            id={id}
            text={name}
          />),
        iconURL: 'https://cdn.jotfor.ms/assets/img/portal/pickerItemDefault.png',
        iconType: 'boards'
      }))
    ];
  }, [boardList]);

  const handleBoardList = async () => {
    if (selectedID) {
      const response = await getFormBoards(selectedID);
      setBoardList(response.data.content);
    }
  };

  useEffect(() => {
    handleBoardList();
  }, [selectedID]);

  const handleSelectionChange = value => {
    if (value !== 'createNewBoard') {
      handleCustomNavigation(value, '_blank');
      return;
    }

    handleCustomNavigation(`/boards/new/${selectedID}?from=myForms-toolbar`, '_blank');
    // discoverabilityTracking
    WatchmanRecorder.trackEventOnceForCustomProject('click', 'toolbar-addNewBoard', 'discoverabilityTracking');
  };

  const handleBoardsButtonClick = () => {
    WatchmanRecorder.trackEvent('click', 'boardsButton', 'myformsFormToolbar');
  };
    // eslint-disable-next-line react/prop-types
  const ContainerRenderer = forwardRef(({ children, ...rest }, containerRef) => (
    <>
      <ul
        aria-label={translate('boards list')} role="listbox" className="listings-popover-modal-item-list"
        ref={containerRef} {...rest}
      >
        {children}
      </ul>
      <ul className="listings-popover-modal-item-actions">
        <li className="listings-popover-more-action">
          <Button
            className="isCreateButton w-full"
            onClick={() => handleSelectionChange('createNewBoard')}
          >
            {translate('Add New Board')}
          </Button>
        </li>
      </ul>
    </>
  ));
  const MenuRenderer = useMemo(() => menuProps => (
    <SelectionGroup
      {...menuProps}
      ContainerRenderer={ContainerRenderer}
      OptionContainerRendererProps={{ tabIndex: 0 }}
    />
  ), []);

  return (
    <PopoverMenu
      withoutChildren
      options={options}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonWithRref()}
      onSelectionChange={handleSelectionChange}
      MenuRenderer={MenuRenderer}
      onButtonClick={handleBoardsButtonClick}
      autoFocusToFirstChild={true}
    >
      {isVisible => (
        <Button
          className={classNames('forBoardsButton', { isMenuVisible: isVisible })}
          colorStyle="secondary"
          variant="outline"
          startIcon={IconProductBoardsFilled}
          endIcon={IconAngleDown}
        >
          {translate('Boards')}
        </Button>
      )}
    </PopoverMenu>
  );
};

BoardAction.propTypes = {
  selectedID: string.isRequired
};

export default BoardAction;
