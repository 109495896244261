import React, {
  useState, useEffect, useMemo, forwardRef
} from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { SelectionGroup } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import { IconProductAppsFilled, IconAngleDown, IconPlus } from '@jotforminc/svg-icons';
import { handleCustomNavigation } from '@jotforminc/utils';

import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';
import PopoverText from '../../PopoverText';

import { getFormPortals } from '../../../api';
import translate from '../../../utils/translate';

import { ListItemSharedDefaultProps } from '../../ListItem/propTypes';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';

const ButtonWithRref = forwardRef((props, ref) => (
  <Button
    ref={ref}
    data-testid="app-action"
    {...props}
    colorStyle="secondary"
    variant="outline"
    startIcon={IconProductAppsFilled}
    // eslint-disable-next-line react/prop-types
    className={classNames('forAppsButton', props.className)}
  >
    {translate('Apps')}
  </Button>
));

const OptionTextRenderer = ({ text, className = '', ...rest }) => (
  <div
    className={classNames('isMenuButton', className)}
    {...rest}
  >
    {translate(text)}
  </div>
);
OptionTextRenderer.propTypes = {
  text: string.isRequired,
  className: string
};

const POPOVER_PROPS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions,
    ...ListItemSharedDefaultProps.PopoverModifierProps
  },
  className: 'listings-popover-modal isMoreMenu isToolbarMoreMenu'
};

const AppAction = ({ selectedID, teamID = '' }) => {
  const [appList, setAppList] = useState([]);

  const options = useMemo(() => {
    if (appList.length === 0) {
      return [{
        disabled: true,
        hideOnDisable: false,
        Renderer: PopoverText,
        text: <OptionTextRenderer className="noItems" text="You don’t have any apps yet." />
      }];
    }

    return [
      ...appList.map(({ id, title, icon: { url } }) => ({
        Renderer: PopoverOption,
        value: `/app/build/${id}`,
        text: <OptionTextRenderer text={title} />,
        iconType: 'apps',
        iconURL: url || 'https://cdn.jotfor.ms/assets/img/portal/pickerItemDefault.png'
      }))
    ];
  }, [appList, selectedID]);

  const handleAppList = async () => {
    if (selectedID) {
      const tmpAppList = await getFormPortals(selectedID);
      setAppList(tmpAppList);
    }
  };

  useEffect(() => {
    handleAppList();
  }, [selectedID]);

  const handleSelectionChange = url => {
    WatchmanRecorder.trackEvent('click', 'createAppClicked', 'myformsFormToolbar');
    // discoverabilityTracking
    WatchmanRecorder.trackEventOnceForCustomProject('click', 'toolbar-createNewApp', 'discoverabilityTracking');
    return handleCustomNavigation(url, '_blank');
  };
  const handleAppButtonClick = () => {
    WatchmanRecorder.trackEvent('click', 'appsButton', 'myformsFormToolbar');
  };
  const handleCreateAppClick = () => handleSelectionChange(`/app/create/${selectedID}${teamID ? `?teamID=${teamID}` : ''}`);
  // eslint-disable-next-line react/prop-types
  const ContainerRenderer = forwardRef(({ children, ...rest }, containerRef) => (
    <>
      <ul
        aria-label={translate('apps list')} role="listbox" className="listings-popover-modal-item-list"
        ref={containerRef} {...rest}
      >
        {children}
      </ul>
      <ul className="listings-popover-modal-item-actions">
        <li className="listings-popover-more-action">
          <Button
            className="isCreateButton w-full"
            startIcon={IconPlus}
            onClick={handleCreateAppClick}
          >
            {translate('Create App')}
          </Button>
        </li>
      </ul>
    </>
  ));
  const MenuRenderer = useMemo(() => menuProps => (<SelectionGroup {...menuProps} ContainerRenderer={ContainerRenderer} OptionContainerRendererProps={{ tabIndex: 0 }} />), []);
  return (
    <PopoverMenu
      withoutChildren
      options={options}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonWithRref}
      onSelectionChange={handleSelectionChange}
      MenuRenderer={MenuRenderer}
      onButtonClick={handleAppButtonClick}
      autoFocusToFirstChild={true}
    >
      {isVisible => (
        <Button
          colorStyle="secondary"
          variant="outline"
          startIcon={IconProductAppsFilled}
          endIcon={IconAngleDown}
          className={`forAppsButton ${isVisible ? 'isMenuVisible' : ''}`}
        >
          {translate('Apps')}
        </Button>
      )}
    </PopoverMenu>
  );
};

AppAction.propTypes = {
  selectedID: string.isRequired,
  teamID: string
};

export default AppAction;
