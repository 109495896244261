import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import World from '../assets/campaigns/2024/jotform-reseller-program/world.svg';
import Rocket from '../assets/campaigns/2024/jotform-reseller-program/rocket.png';
import './styles/jotform-reseller-program.scss';

export const JotformResellerProgram = ({ utmSource, accountTypeName }) => {
  return (
    <section className="jotform-reseller-program-wrapper">
      <div className="jotform-reseller-program-container">
        <div className="left-image">
          <World />
        </div>
        <div className="titlebar">
          <h6 className="top-title">
            {t('Announcing ')}
            <img src={Rocket} alt="Rocket" />
          </h6>
          <h4 className="main-title">
            {t('Jotform Reseller Program')}
          </h4>
        </div>
        <a href={`/partnership/reseller/?utm_campaign=enterprise2024&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`} target="_blank" className="upgrade-now-button">
          {t('Discover Now')}
        </a>
      </div>
    </section>
  );
};

JotformResellerProgram.propTypes = {
  utmSource: string.isRequired,
  //   expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderJotformResellerProgram = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<JotformResellerProgram utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
