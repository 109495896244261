import { useRef, useEffect } from 'react';

const useInview = onInView => {
  const ref = useRef();

  useEffect(() => {
    let observer;

    global.requestAnimationFrame(() => {
      if (!ref.current) return;

      if (global.IntersectionObserver) {
        observer = new global.IntersectionObserver(([entry]) => {
          if (entry.intersectionRatio === 0) {
            return;
          }

          onInView(true);

          observer.unobserve(ref.current);
        }, { threshold: 1 });

        observer.observe(ref.current);
      } else {
        onInView(true);
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref.current]);

  return [ref];
};

export default useInview;
