import {
  all, call, put, select, spawn, takeLatest
} from 'redux-saga/effects';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import { DEFAULT_FOLDER_IDS, LISTING_TYPES } from '../../constants';
import { handleFormCreateWizard } from './form';
import { handleCreateWizard as handlePortalCreateWizard } from './portal';
import { handleCreateWizard as handleDocumentCreateWizard } from './document';
import { handleCreateWizard as handleSheetCreateWizard } from './sheet';
import { handleCreateWizard as handleReportCreateWizard } from './report';
import { handleCreateWizard as handleTaskCreateWizard } from './approval';
import { handleCreateWizard as handleBoardCreateWizard } from './board';
import { handleCreateWizard as handleAgentCreateWizard } from './agent';
import { openTemplateSuggestionModal } from '../../utils/openTemplateSuggestionModal';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

function* handleProductCreateWizard({ productType, type, ...rest }) {
  switch (productType) {
    case LISTING_TYPES.FORM: {
      yield call(handleFormCreateWizard, {
        ...rest, disableSideBySideLightBox: true
      });
      break;
    }
    case LISTING_TYPES.PORTAL: {
      yield call(handlePortalCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.DOCUMENT: {
      yield call(handleDocumentCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.SHEET: {
      yield call(handleSheetCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.REPORT: {
      yield call(handleReportCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.TASK: {
      yield call(handleTaskCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.BOARD: {
      yield call(handleBoardCreateWizard, { ...rest });
      break;
    }
    case LISTING_TYPES.AGENT: {
      yield call(handleAgentCreateWizard, { ...rest });
      break;
    }
    default:
      break;
  }
}

function* handleTemplateSuggestionModal() {
  const [
    user, currentPage
  ] = yield all([
    select(SELECTORS.getUserCredentials),
    select(SELECTORS.getCurrentPage)
  ]);
  let watchmanProjectName = '';
  const page = currentPage === LISTING_TYPES.MIX ? LISTING_TYPES.FORM : currentPage;

  switch (page) {
    case LISTING_TYPES.FORM: {
      watchmanProjectName = 'myformsTemplateSuggestionModal';
      break;
    }
    // A/B Test: urlActionCreateWizVsTempSugModalOnMyApps
    case LISTING_TYPES.PORTAL: {
      // watchmanProjectName = 'myappsTemplateSuggestionModal';
      watchmanProjectName = 'urlActionCreateWizVsTempSugModalOnMyApps';
      break;
    }
    default:
      break;
  }

  yield call(openTemplateSuggestionModal, {
    user,
    watchmanProjectName,
    currentPage: page
  });
}

function* handleUnfavoriteRefetch() {
  const selectedFolder = yield select(SELECTORS.getSelectedFolder);
  if (selectedFolder === DEFAULT_FOLDER_IDS.FAVORITES) {
    yield put(ACTION_CREATORS.fetchListRequest());
  }
}

export function* rootCommonListFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.OPEN_PRODUCT_CREATE_WIZARD, handleProductCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.TEMPLATE_SUGGESTION_MODAL, handleTemplateSuggestionModal);
  yield takeLatest(ACTION_TYPES.SET_FAVORITE, handleUnfavoriteRefetch);
}
