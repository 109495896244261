import {
  put, select, takeLatest
} from 'redux-saga/effects';
import { ACTION_TYPES } from '../../store/actionTypes';
import { SELECTORS } from '../../store/selectors';
import { LISTING_TYPES, ASSET_TYPE_MAP } from '../../constants';
import { redirectToProductBuilder } from '../utils/navigationUtils';
import { ACTION_CREATORS } from '../../store/actionCreators';

function* handleEditResource({ resourceID, resourceType }) {
  const isTeam = yield select(SELECTORS.getIsTeamFolder);
  const asset = yield select(SELECTORS.getItemByIDAndType(resourceID, resourceType));
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const assetListing = ASSET_TYPE_MAP[asset.assetType];
  const assetType = assetListing || assetFilterType;
  const resourceRedirectionID = assetType === LISTING_TYPES.DOCUMENT
    ? asset.formID // sign element's id on redux is document id, but formid is used for opening the builder
    : resourceID;

  if (!isTeam) {
    return redirectToProductBuilder(assetType, resourceRedirectionID);
  }

  yield put(ACTION_CREATORS.requestEditAction(resourceID, null, resourceType));
}

export function* rootNavigationFlow() {
  yield takeLatest(ACTION_TYPES.EDIT_RESOURCE, handleEditResource);
}
