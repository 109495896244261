import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jotforminc/magnet';
import { t } from '@jotforminc/translation';
import { IconAnnotationEllipsisFilled } from '@jotforminc/svg-icons';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import './style.scss';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

const FeedbackButton = () => {
  const dispatch = useDispatch();
  const isMobileDevice = useSelector(SELECTORS.getIsMobileDevice);

  const handleOpenModal = () => {
    WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}myworkspaceFeedbackButton`, 'myworkspaceFeedbackButton', true);
    dispatch(ACTION_CREATORS.openMyworkspaceFeedbackModal());
  };

  return (
    <Button
      colorStyle="secondary"
      variant='filled'
      className="myworkspaceFeedbackButton"
      startIcon={IconAnnotationEllipsisFilled}
      aria-label={t('My Workspace Feedback Button')}
      onClick={handleOpenModal}
    >
      {t('Give Feedback')}
    </Button>
  );
};

export default FeedbackButton;
