/* eslint-disable react/require-default-props */
import React, {
  ReactNode,
  forwardRef,
  FunctionComponent,
  useState,
  useRef
} from 'react';
import debounce from 'lodash/debounce';
import { ImageUpload } from '@jotforminc/image-upload';
import { IconEmojiSelector } from '@jotforminc/icon-selector';
import { TabListWithStyles, Tab } from '@jotforminc/tabs';
import { t } from '@jotforminc/translation';
import { ColorPicker as UIKitColorPicker, Popover } from '@jotforminc/uikit';
import { handleUpdateTeamProps } from './utils';

import ColorRenderer from './ColorRenderer';
import { AVATAR_SET, DEFAULT_SELECTED_OPTIONS } from './constants';
import { TeamProps } from '../../types';
import { CustomizationWrapper } from './StyledElements';
import { ImageUploadRefType } from './types';

type Props = {
  teamId?: string,
  teamAvatarURL?: string,
  teamAvatarIcon?: string,
  teamAvatarIconColor?: string,
  teamAvatarIconBgColor?: string,
  buttonRef: ReactNode,
  setTeam?: (f: (TeamProps | ((currTeam: TeamProps) => TeamProps))) => void,
  onUpdate?: (newData: TeamProps) => void,
  notUpdateOnSelection?: boolean,
  popoverProps?: { [key: string]: any },
  useOnUpdateProp?: boolean
};

const CustomizeTeamAvatar = forwardRef<FunctionComponent, Props>(({
  teamId,
  teamAvatarURL,
  teamAvatarIcon,
  teamAvatarIconColor,
  teamAvatarIconBgColor,
  buttonRef,
  onUpdate = f => f,
  setTeam = f => f,
  notUpdateOnSelection,
  popoverProps = {},
  useOnUpdateProp
}, ref) => {
  const imageUploadRef = useRef<ImageUploadRefType>(null);
  const [activeTab, setActiveTab] = useState<string | undefined>();

  const update = (newData: TeamProps) => {
    if (notUpdateOnSelection || useOnUpdateProp) {
      onUpdate(newData);
    } else {
      handleUpdateTeamProps(teamId as string, newData, onUpdate, setTeam);
    }
  };

  const selectImage = (url: string) => update({
    teamAvatarURL: url,
    teamAvatarIcon: '',
    teamAvatarIconSvgRef: '',
    teamAvatarEmojiId: '',
    teamAvatarIconColor: '',
    teamAvatarIconBgColor: ''
  });

  const handleResetSelectedImage = () => {
    if (imageUploadRef?.current?.setSelectedImage) {
      imageUploadRef.current.setSelectedImage('');
    }
  };

  const removeImage = () => {
    update(DEFAULT_SELECTED_OPTIONS);
    handleResetSelectedImage();
  };

  const selectIcon = (val: { url: string, iconSvgRef: string }) => {
    update({
      teamAvatarIcon: val?.url,
      teamAvatarIconSvgRef: val?.iconSvgRef,
      teamAvatarURL: '',
      teamAvatarEmojiId: '',
      ...(!teamAvatarIconBgColor && { teamAvatarIconBgColor: '#0099FF' })
    });
    handleResetSelectedImage();
  };

  const selectEmoji = (emoji: { id: string }) => {
    update({
      teamAvatarEmojiId: emoji?.id,
      teamAvatarURL: '',
      teamAvatarIcon: '',
      teamAvatarIconSvgRef: '',
      ...(!teamAvatarIconBgColor && { teamAvatarIconBgColor: '#0099FF' })
    });
    handleResetSelectedImage();
  };

  const onLinkTextInputSubmit = (url?: string) => {
    if (!url) return;
    selectImage(url);
    handleResetSelectedImage();
  };

  const TABS = {
    image: { label: t('IMAGE') },
    icon: { label: t('ICON') }
  };

  return (
    <Popover
      ref={ref}
      targetRef={buttonRef}
      popoverOptions={{
        placement: window.innerWidth > 768 ? 'right-start' : 'bottom-end',
        modifiers: [
          { name: 'offset', options: { offset: [0, 5] } }
        ]
      }}
      {...popoverProps}
    >
      <CustomizationWrapper className="avatar">
        <div className="heading withBorder" data-testid="team-avatar-customization-header">
          {t('TEAM AVATAR')}
        </div>
        <div className="content withBorder">
          <TabListWithStyles
            list={TABS}
            onTabChange={setActiveTab}
          >
            <Tab id="image">
              <div className="content-wrapper col">
                <div className="content-fileupload">
                  <ImageUpload
                    ref={imageUploadRef}
                    renderTabs={['groupedGallery', 'upload', 'link']}
                    initialSelectedImage={teamAvatarURL}
                    theme="isLight"
                    groupedGalleryClass="galleryWrapper"
                    groupedGalleryImageList={AVATAR_SET}
                    onImageUpload={(file: { url: string }) => {
                      selectImage(file?.url);
                      handleResetSelectedImage();
                    }}
                    onLinkTextInputSubmit={onLinkTextInputSubmit}
                    onFileRemove={removeImage}
                    onImageSelect={selectImage}
                    onImageRemove={removeImage}
                  />
                </div>
              </div>
            </Tab>
            <Tab id="icon">
              <div className="double-wrapper">
                <div className="content-wrapper col">
                  <div className="content-heading">
                    {t('Icon Color')}
                  </div>
                  <div className="content-option content-option-colorpicker">
                    <UIKitColorPicker
                      ColorRenderer={ColorRenderer}
                      pickerProps={{
                        disableAlpha: true
                      }}
                      value={teamAvatarIconColor}
                      onChange={debounce(val => update({ teamAvatarIconColor: val }), 400)}
                      onInputRemove={() => update({ teamAvatarIconColor: '' })}
                      ariaLabel={t('Icon Color')}
                    />
                  </div>
                </div>
                <div className="content-wrapper col">
                  <div className="content-heading">
                    {t('Background Color')}
                  </div>
                  <div className="content-option content-option-colorpicker">
                    <UIKitColorPicker
                      ColorRenderer={ColorRenderer}
                      pickerProps={{
                        disableAlpha: true
                      }}
                      value={teamAvatarIconBgColor}
                      onChange={debounce(val => update({ teamAvatarIconBgColor: val }), 400)}
                      onInputRemove={() => update({ teamAvatarIconBgColor: '' })}
                      ariaLabel={t('Background Color')}
                    />
                  </div>
                </div>
              </div>
              <div className="content-wrapper col">
                <div className="content-option icon-tab-wrapper">
                  <IconEmojiSelector
                    emojiPickerProperties={{
                      handleEmojiClick: selectEmoji,
                      exclude: ['symbols']
                    }}
                    onChange={selectIcon}
                    value={teamAvatarIcon}
                    allIconsContainerID='team-avatar-icons'
                    allowShuffle={false}
                    selectedIconBgColor={teamAvatarIconBgColor}
                    selectedIconColor={teamAvatarIconColor}
                    overriddenTabNames={['All', 'Colored']}
                    allowRemove={false}
                    isEmojiFirst={true}
                    isActive={activeTab === 'icon'} // To fix clicking tabs issue for the first render of the Emoji Picker.
                  />
                </div>
              </div>
            </Tab>
          </TabListWithStyles>
        </div>
      </CustomizationWrapper>
    </Popover>
  );
});

CustomizeTeamAvatar.defaultProps = {
  teamId: '',
  teamAvatarURL: '',
  teamAvatarIcon: '',
  teamAvatarIconColor: '',
  teamAvatarIconBgColor: '',
  notUpdateOnSelection: false,
  popoverProps: {},
  useOnUpdateProp: false
};

export default CustomizeTeamAvatar;
