import React from 'react';
import { shape, bool } from 'prop-types';
import classNames from 'classnames';
import {
  IconBoxArchiveArrowDownFilled, IconBoxArchiveArrowUpFilled,
  IconTriangleCircleFilled, IconPauseFilled,
  IconStarFilled, IconStar, IconEnvelopeOpenedFilled, IconSquareUserFilled
} from '@jotforminc/svg-icons';
import {
  ScOptionLinkIcon, ScReportIcon, ScAppsIcon, ScActionLinkIcon
} from '../ListItem/bitsListItem';
import translate from '../../utils/translate';
import IconProductBoardMono from '../../assets/icon-boards-mono.svg';
import { LISTING_TYPES, TOOLBAR_MORE_ACTIONS } from '../../constants';

const PopoverOption = ({
  option: {
    text, title, value, component, disabled = false, desc, icon, iconType, iconURL, hideOnDisable = true, optClass = '', useSvgIcons, svgValue
  }, isSelected = false, hasSubMenu = false
}) => {
  if (hideOnDisable && disabled) {
    return null;
  }

  const {
    typeClass, IconComponent
  } = (() => {
    switch (iconType) {
      case 'reports':
        return { typeClass: 'forReports', IconComponent: ScReportIcon };
      case 'boards':
        return { typeClass: 'forBoards', IconComponent: IconProductBoardMono };
      case 'apps':
        return { typeClass: 'forApps', IconComponent: ScAppsIcon };
      case 'move':
        return { typeClass: 'forMove', IconComponent: ScOptionLinkIcon };
      default:
        return { IconComponent: ScOptionLinkIcon };
    }
  })();

  const renderSvgIcon = () => {
    const iconVal = svgValue || value;
    switch (iconVal) {
      case TOOLBAR_MORE_ACTIONS.ARCHIVE:
        return IconBoxArchiveArrowDownFilled;
      case TOOLBAR_MORE_ACTIONS.UNARCHIVE:
        return IconBoxArchiveArrowUpFilled;
      case TOOLBAR_MORE_ACTIONS.ENABLE:
        return IconTriangleCircleFilled;
      case TOOLBAR_MORE_ACTIONS.DISABLE:
        return IconPauseFilled;
      case TOOLBAR_MORE_ACTIONS.STAR:
        return IconStarFilled;
      case TOOLBAR_MORE_ACTIONS.UNSTAR:
        return IconStar;
      case TOOLBAR_MORE_ACTIONS.MARK_AS_READ:
        return IconEnvelopeOpenedFilled;
      case LISTING_TYPES.AGENT:
        return IconSquareUserFilled;
      default:
        return '';
    }
  };

  const SVGIcon = useSvgIcons && renderSvgIcon();

  return (
    <div title={translate(title)}>
      <div
        data-value={value}
        data-disabled={disabled}
        data-selected={isSelected}
        className={classNames('listings-popover-more-option', typeClass, optClass)}
      >
        {SVGIcon ? (
          <SVGIcon
            className="option-icon"
            width={16}
            height={16}
          />
        ) : (
          <IconComponent
            className="option-icon"
            type={icon || value}
            icon={iconURL}
          />
        )}
        <div className="option">
          <div className="option-cmp">
            {component}
          </div>
          <div className="option-text">
            {translate(text)}
          </div>
          {hasSubMenu && <ScActionLinkIcon type="ArrowSubMenu" className="icon isMoreMenu" />}
          {desc && (
          <div className="option-desc">{desc}</div>
          )}
        </div>
      </div>
    </div>
  );
};

PopoverOption.propTypes = {
  option: shape({}).isRequired,
  isSelected: bool,
  hasSubMenu: bool
};

export default PopoverOption;
