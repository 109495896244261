import { renderFhqcModal } from '@jotforminc/enterprise-promotions';
import { setFlagToLocSt, setEpUserSettings, setUserNotificationStatus } from '../utils';

const onConfirm = (notification, user) => {
  setFlagToLocSt(notification.localStActionKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: notification.actionTargetName
    });
  }

  renderFhqcModal({ user });
};

const onConfirmSecondary = ({ actionTargetName }) => {
  setEpUserSettings('lastSession');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'remindMeLater',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, localStActionKey, disabledKey }) => {
  setFlagToLocSt(localStActionKey);
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkFqhc = notifications => {
  let notification = notifications.find(notif => notif.name === 'fqhc');
  const otherNotifications = notifications.filter(notif => notif.name !== 'fqhc');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    confirmSecondary: {
      ...notification.confirmSecondary,
      callback: onConfirmSecondary
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkFqhc;
