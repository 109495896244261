import { t, translationRenderer } from '@jotforminc/translation';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useIsMobile } from '@jotforminc/hooks';
import { setCookie } from '@jotforminc/utils';
import { IconCaretDownBars, IconCheck } from '@jotforminc/svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from '../Dropdown';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { MOBILE_BREAKDOWN_WIDTH, getCommonSortingOptions } from '../../constants';
import { getCookie, getNameFromListingType } from '../../utils';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

const NewSortMenu = () => {
  const dispatch = useDispatch();
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const isMobile = useIsMobile(MOBILE_BREAKDOWN_WIDTH);

  const handleOrderByChange = tmpOrderBy => dispatch(ACTION_CREATORS.setOrderBy(tmpOrderBy));
  const orderBy = useSelector(SELECTORS.getOrderBy);
  const selectedFolder = useSelector(SELECTORS.getSelectedFolder);

  const sortingOptionList = getCommonSortingOptions();
  const defaultOption = (sortingOptionList || []).find(({ value }) => getCookie(`${selectedFolder}Sorting`) === value);
  const defaultOptionValue = defaultOption?.value || orderBy;
  const selectedSortingOptionText = sortingOptionList?.find(opt => opt.value === orderBy)?.text || orderBy;

  const handleOptionsVisible = tmpIsOptionsVisible => {
    if (isOptionsVisible === tmpIsOptionsVisible) {
      return;
    }

    setOptionsVisible(tmpIsOptionsVisible);
    WatchmanRecorder.trackEvent('click', `sortButton-${tmpIsOptionsVisible ? 'Opened' : 'Closed'}`, 'myformsFormToolbar');
  };

  const renderSortOption = ({ option: { text, value }, isSelected: isSortOptionSelected }) => (
    <div data-value={value} className={classNames('sortOption', { isSelected: isSortOptionSelected })}>
      {text}
      <IconCheck className="w-6 h-6 right-2.5 top-1.5 absolute" />
    </div>
  );

  const handleSortingSelect = (value, key) => {
    WatchmanRecorder.trackEvent('click', `${isMobile ? 'mobile-' : ''}sort-${key || value}-${getNameFromListingType(currentPage)}`, '', true);
    handleOrderByChange(value);
    setCookie(`${selectedFolder}Sorting`, value, 7200);
  };

  return (
    <div className={classNames('sb-sortW', { isOpened: isOptionsVisible })}>
      <IconCaretDownBars aria-hidden="true" className={classNames('w-6 top-2 absolute left-2 pointer-events-none ', { 'color-white': isOptionsVisible, 'color-navy-700': !isOptionsVisible })} />
      <Dropdown
        defaultValue={defaultOptionValue}
        options={sortingOptionList?.map(opt => ({ ...opt, text: t(opt.text) }))}
        onOptionSelect={({ value, key }) => handleSortingSelect(value, key)}
        OptionRenderer={renderSortOption}
        popoverProps={{
          className: 'listings-popover-modal',
          popoverOptions: {
            placement: 'bottom-end'
          }
        }}
        onOptionsVisibilityChanged={handleOptionsVisible}
        dropdownButtonAriaLabelPrefix={selectedSortingOptionText ? (
          translationRenderer('Sorted by [1[{selectedSorting}]]')({
            renderer1: () => t(selectedSortingOptionText)
          })
        ) : t('Sorting')}
        placeholder={t('Please select')}
        OptionContainerRenderer={props => <li tabIndex={0} {...props} />}
        optionsAutoFocus={true}
      />
    </div>
  );
};

export default NewSortMenu;
