/* eslint-disable @jotforminc/no-native-button */
import { t } from '@jotforminc/translation';
import { WizardItem, WizardHeader } from '@jotforminc/wizard-shared';
import {
  arrayOf,
  bool, func, shape, string
} from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { PRODUCT_CONFIGS, TEXTS } from './constants';
import ScWizardBody from './ScWizardBody';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { LISTING_TYPES, TEAM_ENABLED_RESOURCES } from '../../constants';

const CreateAssetModal = ({
  onProductSelect = f => f,
  setVisibilityState = f => f,
  teamPermissions = {},
  userPermissions = [],
  isTeamAssetCreation = false,
  selectedFolder = '',
  isAssetsFolderActive = false,
  user = {}
}) => {
  const handleProductSelect = (type, name) => () => {
    WatchmanRecorder.trackEventForCustomProject('click', `teamPageSelectProduct - ${name}`, 'teams', true);
    onProductSelect(type);
    setVisibilityState(false);
  };
  const filteredConfig = PRODUCT_CONFIGS.filter(({
    name, createPermissionName, checkVisibility, userCreatePermissionType
  }) => {
    const hasPermission = isTeamAssetCreation ? teamPermissions[`create${createPermissionName || name}`] : userPermissions?.includes(userCreatePermissionType);

    if (!checkVisibility) return hasPermission;
    return hasPermission && checkVisibility({ user });
  });

  return (
    <WizardItem>
      <WizardHeader
        noDecodeURI
        title={t(TEXTS.HEADER_TITLE)}
      />
      <ScWizardBody>
        <ul className="productItem-list">
          {filteredConfig
            .map(({
              name,
              Icon,
              type
            }) => (
              <li>
                <button
                  key={name}
                  className={classNames('productItem', name.toLowerCase(), {
                    disabled: !TEAM_ENABLED_RESOURCES.includes(type) || (
                      !!selectedFolder && type !== LISTING_TYPES.FORM && !isAssetsFolderActive
                    )
                  })}
                  onClick={handleProductSelect(type, name)}
                  data-productCount={filteredConfig.length}
                  type="button"
                  tabIndex="0"
                  aria-label={t(name)}
                >
                  {Icon && <div className="productIconWrapper"><Icon className='productIcon' /></div>}
                  <div className='productName'>{t(name)}</div>
                </button>
              </li>
            ))}
        </ul>
      </ScWizardBody>
    </WizardItem>
  );
};

CreateAssetModal.propTypes = {
  onProductSelect: func,
  setVisibilityState: func,
  teamPermissions: shape({}),
  userPermissions: arrayOf(string),
  isTeamAssetCreation: bool,
  selectedFolder: string,
  isAssetsFolderActive: bool,
  user: shape({})
};

export default CreateAssetModal;
