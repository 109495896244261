import {
  cancel, takeLatest, takeEvery, call
} from 'redux-saga/effects';

import {
  normalizeForm, normalizeAsset, normalizeSharedForm, findItemByIDAndType, normalizeAgent
} from '../../utils';
import {
  ASSET_TYPE_MAP, FOLDER_TYPES, LISTING_TYPES, ASSETS_FOLDER_LISTINGS, TYPE_MAP, DEFAULT_FOLDER_IDS
} from '../../constants';

export function* initiateOverridenActions(actionMap) {
  const allKeys = Object.keys(actionMap);
  for (let i = 0; i < allKeys.length; i++) {
    const keyName = allKeys[i];
    const handler = actionMap[keyName];
    yield takeLatest(keyName, handler);
  }
}

const REGISTRATION_MAP = {};
export function* registerUniqueAction(actionType, handler, effect = takeLatest, additionalID = '') {
  const uniqueID = actionType + additionalID;
  const existingTask = REGISTRATION_MAP[uniqueID];
  if (existingTask) {
    yield cancel(existingTask);
  }
  REGISTRATION_MAP[uniqueID] = yield effect(actionType, handler);
}

export function* syncRequestSaga(actionType, handler) {
  const requestQueue = [];
  let isRequesting = false;

  function* handleRequest(action) {
    if (!isRequesting) {
      isRequesting = true;
      yield call(handler, action);
      isRequesting = false;

      if (requestQueue.length) {
        const nextAction = requestQueue.shift();
        yield handleRequest(nextAction);
      }
    } else {
      requestQueue.push(action);
    }
  }

  yield takeEvery(actionType, handleRequest);
}

export function normalizeFetchedList({
  list, currentPage, selectedFolder, folderType, isAssetsFolderActive, isSharedWithMeV2, isMixEnabledPage, assetFilterType
}) {
  switch (true) {
    case currentPage === LISTING_TYPES.FORM: {
      if ([DEFAULT_FOLDER_IDS.DRAFTS, DEFAULT_FOLDER_IDS.ASSIGNED].indexOf(selectedFolder) > -1) {
        return list;
      }
      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_WITH_ME || folderType === FOLDER_TYPES.USERNAME) {
        if (isSharedWithMeV2) {
          return typeof list === 'object' ? Object.keys(list).map(listItem => normalizeSharedForm(list[listItem] || {})) : [];
        }
        const { forms } = list;
        return forms;
      }
      return list.map(normalizeForm);
    }
    case isMixEnabledPage: {
      return list.map(item => {
        if (assetFilterType === LISTING_TYPES.FORM || ASSET_TYPE_MAP[item?.assetType] === LISTING_TYPES.FORM) {
          // todo :: overriding assetType will be removed when assetType is coming from endpoint. It is a temporary solution.
          return { ...normalizeForm(item), assetType: TYPE_MAP[LISTING_TYPES.FORM] };
        }
        if (assetFilterType === LISTING_TYPES.AGENT || ASSET_TYPE_MAP[item?.assetType] === LISTING_TYPES.AGENT) {
          return normalizeAgent(item);
        }
        return isAssetsFolderActive ? normalizeAsset(item) : item;
      });
    }
    case (isAssetsFolderActive && ASSETS_FOLDER_LISTINGS.indexOf(currentPage) > -1): {
      return list.map(normalizeAsset);
    }
    case currentPage === LISTING_TYPES.AGENT: {
      return list.map(normalizeAgent);
    }
    default:
      return list;
  }
}

export function filterFrontEnd(filter, list, filterKeys) {
  return list.filter(item => {
    return Object.values(item.answers).some(filteredObj => {
      return filterKeys.whiteList.some(key => {
        if (!(typeof filteredObj[key] === 'string')) {
          if (filteredObj[key] && typeof filteredObj[key] === 'object' && filteredObj[key] != null) {
            return Object.keys(filteredObj[key]).some(keyItem => filteredObj[key][keyItem].includes(filter));
          }
          return false;
        }
        if (filteredObj[key] && filteredObj[key].includes(filter)) return true;
        return false;
      });
    });
  });
}
export function sortList(list, orderBy) {
  const order = orderBy.split('-')[0];
  const sortBy = orderBy.split('-')[1];
  const isUp = sortBy === 'up';
  const isDate = order === 'created_at';
  const sortedList = list.sort((a, b) => {
    let valA = a[order] || a.form[order] || false;
    let valB = b[order] || b.form[order] || false;
    if (!valA || !valB) return 0;
    if (isDate) {
      valA = new Date(valA);
      valB = new Date(valB);
    }
    if (valA > valB) {
      return isUp ? -1 : 1;
    }
    if (valA < valB) {
      return isUp ? 1 : -1;
    }
    return 0;
  });
  return sortedList;
}

export const shouldShowLimitWarnings = () => {
  const allowedOnes = ['forms', 'mysigndocuments', 'myapps'];

  return allowedOnes.some(listing => window.location.href.includes(listing));
};

export const removeListDuplications = (list = []) => {
  return list.reduce((acc, curr) => {
    if (!acc.find(accItem => accItem === curr)) {
      acc.push(curr);
    }
    return acc;
  }, []);
};

export const removeListDuplicationsWithType = (list = []) => {
  return list.reduce((acc, curr) => {
    if (!findItemByIDAndType({ list: acc, assetID: curr.id, assetType: curr.assetType })) {
      acc.push(curr);
    }
    return acc;
  }, []);
};
