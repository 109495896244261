/* eslint-disable max-len */
import Styled from 'styled-components';

export const ScNotificationBell = Styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  transform-origin: 8px 2px;

  ${({
    hasUnseenNotification,
    isNotificationListVisible
  }) => {
    if (!isNotificationListVisible && hasUnseenNotification) {
      return `
      animation: rotationBellNotificationCenter 2s infinite ease-out;
      `;
    }
  }}

  &::before{
    display: block;
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    width: 12px;
    height: 13px;
    top: 0;
    left: 2px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 2c0 .1525-.017.301-.0493.4436 1.5072.7262 2.5478 2.2677 2.5493 4.0524.0093 1.2098.0996 2.0374.2981 2.7546.1966.7107.5144 1.3675 1.0485 2.2172A1 1 0 0111 13H1a1 1 0 01-.8466-1.5322c.5341-.8497.852-1.5065 1.0485-2.2172.1985-.7172.2888-1.5448.2981-2.7546.0015-1.7847 1.042-3.3263 2.5494-4.0524A2.0072 2.0072 0 014 2c0-1.1046.8954-2 2-2s2 .8954 2 2zm-2 .5a.5.5 0 100-1 .5.5 0 000 1z' fill='${({ notificationCount }) => (notificationCount >= 1 ? '%23FFFFFF' : '%239BA0AB')}'/%3E%3C/svg%3E");
    opacity: 1;

    ${({ enterpriseTheme }) => {
    if (!enterpriseTheme) {
      return `
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.95066 2.44362C7.98297 2.30093 8.00003 2.15246 8.00003 2C8.00003 0.89543 7.10464 0 6.00003 0C4.89542 0 4.00003 0.89543 4.00003 2C4.00003 2.15245 4.01709 2.30091 4.04939 2.44359C2.54207 3.16974 1.50153 4.7113 1.5 6.49605C1.49073 7.70581 1.40035 8.5334 1.20193 9.25058C1.00532 9.96126 0.687513 10.6181 0.153376 11.4678C-0.0403351 11.776 -0.0513066 12.165 0.124732 12.4836C0.30077 12.8022 0.636012 13 1 13H11C11.364 13 11.6992 12.8022 11.8753 12.4836C12.0513 12.165 12.0403 11.776 11.8466 11.4678C11.3125 10.6181 10.9947 9.96126 10.7981 9.25058C10.5996 8.53339 10.5093 7.7058 10.5 6.49602C10.4985 4.7113 9.45795 3.16978 7.95066 2.44362ZM7.00003 2C7.00003 2.55228 6.55228 3 6.00003 3C5.44778 3 5.00003 2.55228 5.00003 2C5.00003 1.44772 5.44778 1 6.00003 1C6.55228 1 7.00003 1.44772 7.00003 2Z' fill='white'/%3E%3C/svg%3E%0A");
        .listVisible & {
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.95066 2.44362C7.98297 2.30093 8.00003 2.15246 8.00003 2C8.00003 0.89543 7.10464 0 6.00003 0C4.89542 0 4.00003 0.89543 4.00003 2C4.00003 2.15245 4.01709 2.30091 4.04939 2.44359C2.54207 3.16974 1.50153 4.7113 1.5 6.49605C1.49073 7.70581 1.40035 8.5334 1.20193 9.25058C1.00532 9.96126 0.687513 10.6181 0.153376 11.4678C-0.0403351 11.776 -0.0513066 12.165 0.124732 12.4836C0.30077 12.8022 0.636012 13 1 13H11C11.364 13 11.6992 12.8022 11.8753 12.4836C12.0513 12.165 12.0403 11.776 11.8466 11.4678C11.3125 10.6181 10.9947 9.96126 10.7981 9.25058C10.5996 8.53339 10.5093 7.7058 10.5 6.49602C10.4985 4.7113 9.45795 3.16978 7.95066 2.44362ZM7.00003 2C7.00003 2.55228 6.55228 3 6.00003 3C5.44778 3 5.00003 2.55228 5.00003 2C5.00003 1.44772 5.44778 1 6.00003 1C6.55228 1 7.00003 1.44772 7.00003 2Z' fill='%230A1551'/%3E%3C/svg%3E%0A");
        }
        `;
    }
    if (enterpriseTheme) {
      return `
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.95066 2.44362C7.98297 2.30093 8.00003 2.15246 8.00003 2C8.00003 0.89543 7.10464 0 6.00003 0C4.89542 0 4.00003 0.89543 4.00003 2C4.00003 2.15245 4.01709 2.30091 4.04939 2.44359C2.54207 3.16974 1.50153 4.7113 1.5 6.49605C1.49073 7.70581 1.40035 8.5334 1.20193 9.25058C1.00532 9.96126 0.687513 10.6181 0.153376 11.4678C-0.0403351 11.776 -0.0513066 12.165 0.124732 12.4836C0.30077 12.8022 0.636012 13 1 13H11C11.364 13 11.6992 12.8022 11.8753 12.4836C12.0513 12.165 12.0403 11.776 11.8466 11.4678C11.3125 10.6181 10.9947 9.96126 10.7981 9.25058C10.5996 8.53339 10.5093 7.7058 10.5 6.49602C10.4985 4.7113 9.45795 3.16978 7.95066 2.44362ZM7.00003 2C7.00003 2.55228 6.55228 3 6.00003 3C5.44778 3 5.00003 2.55228 5.00003 2C5.00003 1.44772 5.44778 1 6.00003 1C6.55228 1 7.00003 1.44772 7.00003 2Z' fill='%230A1551'/%3E%3C/svg%3E%0A");
        .listVisible & {
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.95066 2.44362C7.98297 2.30093 8.00003 2.15246 8.00003 2C8.00003 0.89543 7.10464 0 6.00003 0C4.89542 0 4.00003 0.89543 4.00003 2C4.00003 2.15245 4.01709 2.30091 4.04939 2.44359C2.54207 3.16974 1.50153 4.7113 1.5 6.49605C1.49073 7.70581 1.40035 8.5334 1.20193 9.25058C1.00532 9.96126 0.687513 10.6181 0.153376 11.4678C-0.0403351 11.776 -0.0513066 12.165 0.124732 12.4836C0.30077 12.8022 0.636012 13 1 13H11C11.364 13 11.6992 12.8022 11.8753 12.4836C12.0513 12.165 12.0403 11.776 11.8466 11.4678C11.3125 10.6181 10.9947 9.96126 10.7981 9.25058C10.5996 8.53339 10.5093 7.7058 10.5 6.49602C10.4985 4.7113 9.45795 3.16978 7.95066 2.44362ZM7.00003 2C7.00003 2.55228 6.55228 3 6.00003 3C5.44778 3 5.00003 2.55228 5.00003 2C5.00003 1.44772 5.44778 1 6.00003 1C6.55228 1 7.00003 1.44772 7.00003 2Z' fill='white'/%3E%3C/svg%3E%0A");
        }
        `;
    }
  }}
  }

  &::after{
    display: block;
    position: absolute;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    left: 6px;
    width: 4px;
    height: 2px;
    background-image: url("data:image/svg+xml,%3Csvg width='4' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2c1.1046 0 2-.8954 2-2H0c0 1.1046.8954 2 2 2z' fill='${({ notificationCount }) => (notificationCount >= 1 ? '%23FFFFFF' : '%239BA0AB')}'/%3E%3C/svg%3E");
  
    ${({ enterpriseTheme }) => {
    if (!enterpriseTheme) {
      return `
      background-image: url("data:image/svg+xml,%3Csvg width='4' height='2' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2C3.10461 2 4 1.10457 4 0H0C0 1.10457 0.895386 2 2 2Z' fill='white'/%3E%3C/svg%3E%0A");
      .listVisible & {
        background-image: url("data:image/svg+xml,%3Csvg width='4' height='2' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2C3.10461 2 4 1.10457 4 0H0C0 1.10457 0.895386 2 2 2Z' fill='%230A1551'/%3E%3C/svg%3E%0A");
      }
      `;
    }
    if (enterpriseTheme) {
      return `
      background-image: url("data:image/svg+xml,%3Csvg width='4' height='2' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2C3.10461 2 4 1.10457 4 0H0C0 1.10457 0.895386 2 2 2Z' fill='%230A1551'/%3E%3C/svg%3E%0A");
      .listVisible & {
        background-image: url("data:image/svg+xml,%3Csvg width='4' height='2' viewBox='0 0 4 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2C3.10461 2 4 1.10457 4 0H0C0 1.10457 0.895386 2 2 2Z' fill='white'/%3E%3C/svg%3E%0A");
      }
      `;
    }
  }}

  ${({
    hasUnseenNotification,
    isNotificationListVisible
  }) => {
    if (!isNotificationListVisible && hasUnseenNotification) {
      return `
        animation: horzNotificationCenter 2s infinite ease-out;
      `;
    }
  }}

  }



  @keyframes rotationBellNotificationCenter {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(15deg);
    }
    10% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(-15deg);
    }
    30% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(5deg);
    }
    60% {
      transform: rotate(-5deg);
    }
    65% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes horzNotificationCenter {
    0% {
      transform: translateX(0);
    }
    10% {
      transform: translateX(-4px);
    }
    20% {
      transform: translateX(4px);
    }
    30% {
      transform: translateX(-2px);
    }
    40% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-1px);
    }
    60% {
      transform: translateX(1px);
    }
    70% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
