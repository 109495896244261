/* eslint-disable max-len */
import Styled from 'styled-components';

export const ScNotificationImageNv = Styled.img`
  width: 100%;
  height: auto;
  background: #DADEF3;
  border-radius: 2px;
  border: none;
  outline: none;
  margin-bottom: 16px;
`;
