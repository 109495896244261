/* eslint-disable react/prop-types */
import React, {
  forwardRef, useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectionGroup, Hooks } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import {
  IconFolderPlusFilled, IconPlusCircleFilled
} from '@jotforminc/svg-icons';

import classNames from 'classnames';

import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';
import PopoverText from '../../PopoverText';

import translate from '../../../utils/translate';

import { SELECTORS } from '../../../store/selectors';
import { FOLDER_ITEM_ACTION_TYPES, FOLDER_TYPES } from '../../../constants';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';
import OptionTextRenderer from './OptionTextRenderer';
import { POPOVER_PROPS } from './constants';

const ButtonWithRref = forwardRef((props, ref) => (
  <Button
    ref={ref}
    data-testid="add-to-folder-toolbar"
    {...props}
    colorStyle="secondary"
    variant="outline"
    startIcon={IconFolderPlusFilled}
    // eslint-disable-next-line react/prop-types
    className={classNames('forAddToFolder', props.className)}
  >
    {translate('Add to Folder')}
  </Button>
));

const AddToFolder = forwardRef((props, ref) => {
  const [selectedFolders, setSelectedFolders] = useState([]);
  const flattenedFolders = useSelector(SELECTORS.getFlattenedFolders) || [];
  const visibleFlattenedFolders = useSelector(SELECTORS.getVisibleFlattenedFolders) || [];
  const selectedListItems = useSelector(SELECTORS.getSelectedItemsWithInfo);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const currentTeamMembers = useSelector(SELECTORS.getCurrentTeamMembers);
  const isMenuOpen = useSelector(SELECTORS.getIsAddToFolderMenuVisible);
  const dispatch = useDispatch();
  const menuRef = useRef();
  const combinedRef = Hooks.useCombinedRefs(ref, menuRef);
  const createFolder = () => dispatch(ACTION_CREATORS.folderButtonAction('createFolderToolbar'));
  const addItemsToFolder = ({ folderIDs, items }) => dispatch(ACTION_CREATORS.folderItemActionBulk({ actionType: FOLDER_ITEM_ACTION_TYPES.ADD, items, folderIDs }));
  const removeItemsFromFolder = ({ folderIDs, items }) => dispatch(ACTION_CREATORS.folderItemActionBulk({ actionType: FOLDER_ITEM_ACTION_TYPES.REMOVE, items, folderIDs }));
  const toggleMenuVisibility = isVisible => dispatch(ACTION_CREATORS.toggleAddToFolderMenuVisibility(isVisible));

  const { credentials: { username } } = useSelector(SELECTORS.getUser) || { credentials: { username: '' } };
  const teamOwnedFolders = flattenedFolders.filter(
    folder => ((currentTeamID ? currentTeamMembers.find(member => member.username === username) : folder.owner === username)
    && folder.folderType !== FOLDER_TYPES.TEAM
    && folder.folderType !== FOLDER_TYPES.ASSET_FOLDER
    && folder.team_id === currentTeamID)
  );
  const ownedFolders = currentTeamID ? teamOwnedFolders : visibleFlattenedFolders;
  const folderOptions = ownedFolders.length ? ownedFolders.map(folder => ({
    value: folder.id,
    Renderer: PopoverOption,
    text: <OptionTextRenderer text={folder.text} />,
    iconType: 'move'
  })) : [{
    disabled: true,
    hideOnDisable: false,
    Renderer: PopoverText,
    text: <OptionTextRenderer className="noItems" text={translate("You don't have any folders")} />
  }];

  const onApply = () => {
    const addItemsObject = {
      items: [],
      folderIDs: []
    };
    const removeItemsObject = {
      items: [],
      folderIDs: []
    };
    selectedListItems.forEach(item => {
      // if selectedfolders doesn't include item's folders, then it means
      // we'll remove it
      const itemFolders = item.folders?.split?.(',')?.filter?.(f => f) || [];
      const foldersToRemove = itemFolders.filter(itemFolder => !selectedFolders.includes(itemFolder));
      const foldersToAdd = selectedFolders.filter(folder => !itemFolders.includes(folder));
      foldersToAdd.forEach(folderID => {
        addItemsObject.items.push(item);
        addItemsObject.folderIDs.push(folderID);
      });
      foldersToRemove.forEach(folderID => {
        removeItemsObject.items.push(item);
        removeItemsObject.folderIDs.push(folderID);
      });
    });
    addItemsToFolder(addItemsObject);
    removeItemsFromFolder(removeItemsObject);
    combinedRef?.current?.setMenuVisibility(false);
  };
  const hasFolders = ownedFolders.length > 0;
  const options = [...folderOptions];

  const onSelectionChange = items => {
    const filteredFolders = items.filter(f => f).filter(f => f !== 'apply');
    setSelectedFolders(filteredFolders);
  };

  useEffect(() => {
    const selectedItemsFolders = selectedListItems.flatMap(item => item?.folders?.split(',').filter(folderID => folderID !== '' && folderID));
    setSelectedFolders(selectedItemsFolders);
  }, [selectedListItems, setSelectedFolders]);

  const OptionButtonRenderer = useMemo(() => (
    <ul className="listings-popover-modal-item-actions">
      <li className="listings-popover-more-action">
        {hasFolders ? (
          <Button
            colorStyle="success"
            className="w-full"
            onClick={onApply}
          >
            {translate('Apply')}
          </Button>
        ) : (
          <Button
            colorStyle="success"
            startIcon={IconPlusCircleFilled}
            className="w-full"
            onClick={createFolder}
          >
            {translate('Create folder')}
          </Button>
        )}
      </li>
    </ul>
  ), [hasFolders, selectedFolders, selectedListItems]);

  const ContainerRenderer = forwardRef(({ children, ...rest }, containerRef) => (
    <ul
      aria-label={translate('folders list')} role="listbox" className="listings-popover-modal-item-list"
      ref={containerRef} {...rest}
    >
      {children}
    </ul>
  ));

  const MenuRenderer = useMemo(() => menuProps => (
    <SelectionGroup
      {...menuProps}
      ContainerRenderer={ContainerRenderer}
      isMultiSelect
      OptionContainerRendererProps={{ tabIndex: 0 }}
    />
  ), [selectedListItems]);

  const handleAddButtonClick = () => {
    WatchmanRecorder.trackEvent('click', 'manageAll', 'myformsFormToolbar');
  };

  return (
    <PopoverMenu
      withoutChildren
      ref={combinedRef}
      options={options}
      MenuRenderer={MenuRenderer}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonWithRref}
      onSelectionChange={onSelectionChange}
      onButtonClick={handleAddButtonClick}
      isMenuOpen={isMenuOpen}
      onVisibilityChange={toggleMenuVisibility}
      additionalContent={OptionButtonRenderer}
      defaultValue={selectedFolders}
      autoFocusToFirstChild={true}
    >
      <Button
        colorStyle="secondary"
        variant="outline"
        startIcon={IconFolderPlusFilled}
        className="forAddToFolder"
      >
        {translate('Add to Folder')}
      </Button>
    </PopoverMenu>
  );
});

export default AddToFolder;
