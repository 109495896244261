import React, {
  useState, useEffect, useRef, ReactElement
} from 'react';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { t } from '@jotforminc/translation';
import { IconAngleDown } from '@jotforminc/svg-icons';
import Dropdown, { DropdownItem } from './Dropdown';
import { FilterType, LogGroup } from './ActivityLog/types';
import { groupVisibleName, visibleName } from './ActivityLog/utils';
import { LOG_GROUPS } from './ActivityLog/constants';

type ActivityFilterProps = {
  name?: string | null,
  filterName?: string,
  tabIndex?: number
}
const ActivityFilter = ({ name, filterName, ...props }: ActivityFilterProps): ReactElement => (
  <label className={`filterButton-select filterButton for${filterName}`} {...props}>
    <IconAngleDown className="filterButton-icon" />
    <span className="filterButton-text">{t(name || '')}</span>
  </label>
);

ActivityFilter.defaultProps = {
  name: '',
  filterName: '',
  tabIndex: 0
};

type FilterDropdownsProps = {
  value?: {
    filterType: FilterType,
    options: string[],
    optionsMetadata?: { [key: string]: any }[]
  },
  onChange?: (dataValue: string | null, filterType: FilterType, isFirst?: boolean) => void,
  selectedOption?: string | null,
  searchable?: boolean,
  groupSupport?: boolean,
  logGroups?: LogGroup[],
  isUserHistory?: boolean,
  dropdownClassName?: string,
  ariaLabel?: string,
  visibleNames?: { [key: string]: any },
  hideAllOption?: boolean,
  firstItemName?: string,
  optionIcons?: { [key: string]: ReactElement}
};

function FilterDropdowns({
  value = {
    filterType: '',
    options: [],
    optionsMetadata: []
  },
  onChange = () => {},
  selectedOption,
  searchable,
  groupSupport,
  logGroups = LOG_GROUPS,
  isUserHistory = false,
  dropdownClassName = '',
  ariaLabel = '',
  visibleNames,
  hideAllOption,
  firstItemName,
  optionIcons
}: FilterDropdownsProps): ReactElement {
  const { filterType, options } = value;
  const filterName = filterType.charAt(0).toUpperCase() + filterType.slice(1);
  const getFixedFirstItemName = (name: string) => firstItemName || `All ${name}`;
  const firstItem = hideAllOption && visibleNames && options?.length ? visibleNames[options[0]] : getFixedFirstItemName(filterName);
  const [selectedOpt, setSelectedOpt] = useState<string | null>(firstItem);
  const [selectedOptValue, setSelectedOptValue] = useState<string | null>(hideAllOption && options?.length ? options[0] : null);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const selector = `for${filterName}Filter ${dropdownClassName} ${selectedOptValue ? `${selectedOptValue}-selected` : ''}`;
  const emptyFilterDropdownText = `No ${filterType} found`;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const Separator = () => <DropdownItem separator={true} />;
  const EmptyDropdownItem = () => (
    <DropdownItem>
      {t(emptyFilterDropdownText)}
    </DropdownItem>
  );

  const optionVisibleName = (item: string) => {
    if (visibleNames) {
      return visibleNames[item];
    }
    return groupSupport ? groupVisibleName(item, logGroups) : visibleName(item, isUserHistory);
  };

  const getOptionIcon = (item: string): ReactElement | null => (optionIcons ? optionIcons[item] : null);

  const clearSearch = () => {
    setFilteredOptions([]);
    setSearchTerm('');
  };

  const scrollToSelectedOption = (selectedEl: HTMLButtonElement | null, isFirst?: boolean) => {
    const dropdownWrapper = wrapperRef && wrapperRef.current;
    if (!dropdownWrapper) return;

    if (isFirst) {
      dropdownWrapper.scrollTop = 0;
    } else if (selectedEl) {
      dropdownWrapper.scrollTop = selectedEl.offsetTop - dropdownWrapper.offsetTop;
    }
  };

  const handleSelectionChange = (e: any, isFirst: boolean) => {
    const newSelection = e.target.textContent;
    const dataValue = e.target.getAttribute('data-value');
    setSelectedOpt(newSelection);
    setSelectedOptValue(dataValue);
    onChange(dataValue, filterType, isFirst);
    clearSearch();
    if (!isFirst) {
      scrollToSelectedOption(e.target);
    }
  };

  const getNameByUsername = (username: string) => {
    const { optionsMetadata } = value;
    if (optionsMetadata) {
      const userObj = optionsMetadata.find(option => option.username === username);
      if (userObj) {
        const { name } = userObj;
        return name || username;
      }
    }
    return '';
  };

  const getOptions = (item: string, forUsers: boolean) => {
    const isFirstOpt = !hideAllOption && item === firstItem;
    const isSelected = (item === selectedOptValue) || (isFirstOpt && firstItem === selectedOpt);
    const text = forUsers ? getNameByUsername(item) : optionVisibleName(item);
    const ItemIcon = getOptionIcon(isFirstOpt ? 'allIcon' : item);
    const DropdownOpt = () => (
      <DropdownItem
        key={item}
        data-value={isFirstOpt ? null : item}
        className={`${isSelected ? 'activeItem' : ''} ${ItemIcon ? 'hasIcon' : ''}`}
        onClick={e => handleSelectionChange(e, isFirstOpt)}
        isSelected={isSelected}
      >
        {ItemIcon}
        {t(isFirstOpt ? firstItem : text)}
      </DropdownItem>
    );

    return isFirstOpt ? (
      <div className="stickyFirstItem">
        <DropdownOpt />
        <Separator />
      </div>
    ) : <DropdownOpt />;
  };

  const handleUserSearch = (e: any) => {
    const { optionsMetadata: opts = [] } = value;
    const { value: val } = e.target;
    const newOpts = opts.map(opt => (opt.name ? opt : { ...opt, name: opt.username }));
    const filteredOpts = filter(newOpts, opt => (opt.name && opt.name.toLowerCase().indexOf(val.toLowerCase()) > -1));
    const sortedNames = sortBy(filteredOpts, user => user.name && user.name.toLowerCase());
    const filteredUsernames = sortedNames.map(user => user.username);
    setFilteredOptions(filteredUsernames);
    setSearchTerm(val);
  };

  const getDropdownItems = (type: FilterType) => {
    const optionList = hideAllOption ? options : [firstItem, ...options];
    const opts = searchTerm ? filteredOptions : optionList;

    if (opts.length === 0) {
      return <EmptyDropdownItem />;
    }

    return opts.map(item => (
      <React.Fragment key={item}>
        {getOptions(item, type === 'users')}
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (selectedOption !== undefined) {
      let option = selectedOption;
      if (!selectedOption) {
        option = firstItem;
        scrollToSelectedOption(null, true);
      } else if (optionVisibleName(selectedOption)) {
        option = optionVisibleName(selectedOption);
      } else if (getNameByUsername(selectedOption)) {
        option = getNameByUsername(selectedOption);
      }

      setSelectedOpt(option);
      setSelectedOptValue(selectedOption);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, searchTerm]);

  return (
    <Dropdown
      content={<ActivityFilter name={selectedOpt} filterName={filterName} tabIndex={-1} />}
      className={selector}
      ref={wrapperRef}
      {...(searchable
        ? {
          searchable: searchable,
          onSearch: handleUserSearch,
          clearSearch: clearSearch,
          searchTerm: searchTerm
        }
        : {})}
      ariaLabel={ariaLabel}
      dropdownId={filterName}
    >
      {getDropdownItems(filterType)}
    </Dropdown>
  );
}

FilterDropdowns.defaultProps = {
  value: {
    filterType: '',
    options: [],
    optionsMetadata: []
  },
  onChange: () => {},
  selectedOption: null,
  searchable: false,
  groupSupport: true,
  logGroups: LOG_GROUPS,
  isUserHistory: false,
  dropdownClassName: '',
  ariaLabel: '',
  visibleNames: null,
  hideAllOption: false,
  firstItemName: null,
  optionIcons: null
};

export default FilterDropdowns;
