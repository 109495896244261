/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react';
import { CustomizationButton } from '../Cover/CustomizationButton';
import { TeamProps } from '../../types';
import Image from '.';
import { hasQueryParams } from '../../utils';
import '../../styles/Image.scss';

type Props = {
  iconBgColor?: string,
  hasCustomization?:boolean,
  isVisible?: boolean,
  onVisibilityChange?: (isCustomizationVisible: boolean) => void,
  url?: string,
  icon?: string,
  iconColor?: string,
  teamId?: string,
  onUpdate?: (newData: TeamProps) => void,
  customClass?: string,
  emojiId?: string,
  iconSvgRef?: string,
  emojiSize?: number | string
};

export const ImageWithWrapper: FunctionComponent<Props> = ({
  iconBgColor = '',
  hasCustomization = false,
  isVisible = false,
  onVisibilityChange = f => f,
  teamId = '',
  customClass = '',
  onUpdate = f => f,
  url = '',
  icon = '',
  iconColor = '',
  ...props
}) => {
  const imageProps = {
    urlParams: url ? `${hasQueryParams(url) ? '&' : '?'}h=128` : '',
    url,
    icon,
    iconColor,
    ...props
  };
  return hasCustomization ? (
    <>
      <CustomizationButton
        type="teamAvatar"
        buttonClass={`sb-customIcon forTeamAvatar ${customClass}`}
        buttonStyles={{
          backgroundColor: iconBgColor
        }}
        teamAvatarURL={url}
        teamAvatarIcon={icon}
        teamAvatarIconColor={iconColor}
        teamAvatarIconBgColor={iconBgColor}
        isVisible={isVisible}
        onVisibilityChange={onVisibilityChange}
        teamId={teamId}
        onUpdate={onUpdate}
      >
        <Image {...imageProps} />
      </CustomizationButton>
    </>
  ) : (
    <div className={`sb-customIcon ${customClass}`} style={{ backgroundColor: iconBgColor }}>
      <Image {...imageProps} />
    </div>
  );
};
