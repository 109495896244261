import { ACTION_TYPES } from '../actionTypes';

const initialState = {
  teams: [],
  teamRoles: [],
  currentTeamID: '',
  showNoAccessView: window.showNoAccessView,
  showTeamNotFoundView: window.showTeamNotFoundView,
  isTeamMember: window.isTeamMember,
  teamNotificationCount: 0,
  serverTeamSettings: {},
  preventFetchingMoveJobs: false,
  isReorderTeamsAllowed: true,
  isTeamsFetched: false
};

const updateTeam = (teams, teamID, newProperties) => {
  const newTeams = teams.map(team => {
    if (team.id !== teamID) return team;

    return {
      ...team,
      ...newProperties
    };
  });

  return newTeams;
};

// eslint-disable-next-line no-unused-vars
const addFolderToTeam = (folders, parentID, folderToAdd) => {
  const newFolders = [];
  if (!parentID) {
    // TODO Find a better way to handle main create a folder button
    return folders.reduce((prev, curr) => {
      if (curr.id === 'createFolder') {
        return [...prev, folderToAdd, curr];
      }
      return [...prev, curr];
    }, []);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    const folder = folders[i];
    if (folder.id === parentID) {
      if (!folder.subfolders?.length) folder.subfolders = [];
      folder.subfolders.push(folderToAdd);
    }
    if (folder.subfolders) {
      folder.subfolders = addFolderToTeam(folder.subfolders, parentID, folderToAdd);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

const modifyTeamFolder = (folders, folderID, newAttributes) => {
  const newFolders = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    let folder = folders[i];
    if (folder.id === folderID) {
      folder = { ...folder, subfolders: [...folder.subfolders, newAttributes] };
    }
    if (folder.subfolders) {
      folder.subfolders = modifyTeamFolder(folder.subfolders, folderID, newAttributes);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

const removeTeam = (teams, teamIDToRemove) => teams.filter(folder => folder.id !== teamIDToRemove);

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.ADD_FOLDER.SUCCESS: {
      const { folder: folderResult, teamID } = action;
      if (teamID) {
        return { ...state, teams: modifyTeamFolder(state.teams, teamID, folderResult) };
      }
      return state;
    }
    case ACTION_TYPES.UPDATE_TEAM_ROLES: {
      const { teamRoles } = action;
      return { ...state, teamRoles };
    }
    case ACTION_TYPES.UPDATE_TEAMS: {
      const { teams } = action;
      return { ...state, teams };
    }
    case ACTION_TYPES.UPDATE_TEAM_PROPERTIES: {
      const { teamID, newProperties } = action;
      const updatedTeams = updateTeam(state.teams, teamID, newProperties);
      return { ...state, teams: updatedTeams };
    }
    case ACTION_TYPES.DELETE_TEAM.SUCCESS: {
      const { teamID } = action;
      const removedTeams = removeTeam(state.teams, teamID);
      return { ...state, teams: removedTeams };
    }
    case ACTION_TYPES.SET_CURRENT_TEAM_ID: {
      const { id: currentTeamID } = action;
      return { ...state, currentTeamID };
    }
    case ACTION_TYPES.SHOW_NO_ACCESS_TO_TEAM_VIEW: {
      return { ...state, showNoAccessView: true };
    }
    case ACTION_TYPES.RESET_ALL_TEAM_PERMISSIONS: {
      const { teams } = state;
      return {
        ...state,
        teams: teams.map(team => {
          return { ...team, permissions: null };
        })
      };
    }
    case ACTION_TYPES.SET_TEAM_NOTIFICATION_COUNT: {
      const { teamNotificationCount } = action;
      return {
        ...state,
        teamNotificationCount
      };
    }
    case ACTION_TYPES.SET_SERVER_TEAM_SETTINGS: {
      const { serverTeamSettings } = action;
      return {
        ...state,
        serverTeamSettings
      };
    }
    case ACTION_TYPES.PREVENT_FETCHING_MOVE_JOBS: {
      const { prevent } = action;

      return { ...state, preventFetchingMoveJobs: prevent };
    }
    case ACTION_TYPES.ALLOW_REORDER_TEAMS: {
      const { isAllowed } = action;

      return { ...state, isReorderTeamsAllowed: isAllowed };
    }
    case ACTION_TYPES.IS_TEAMS_FETCHED: {
      const { isFetched } = action;
      return { ...state, isTeamsFetched: isFetched };
    }
    default:
      return state;
  }
}
