import get from 'lodash/get';
import set from 'lodash/set';
import uniqby from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

/* eslint-disable complexity */
import {
  FOLDER_ITEM_ACTION_TYPES, LISTING_TYPES, LIST_FETCH_LIMIT, ROUTER_MAP, LISTING_TYPES_REVERT
} from '../../constants';
import { ACTION_TYPES } from '../actionTypes';
import {
  getPageFromUrl, getAssetType, areTheSameItems, findItemByIDAndType
} from '../../utils';

const initialState = {
  list: [],
  lastResultSetCount: LIST_FETCH_LIMIT,
  newCreatedItems: {},
  isInitialState: true,
  baseList: null,
  deletedItems: [],
  movingJobs: {}
};

export default function listReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.RESET_LIST: {
      const { movingJobs } = state;
      return {
        ...initialState, movingJobs
      };
    }
    case ACTION_TYPES.SET_SELECTED_FOLDER:
    case ACTION_TYPES.SET_TEAM_FILTER: {
      return { ...state, lastResultSetCount: LIST_FETCH_LIMIT, list: [] };
    }
    case ACTION_TYPES.SET_LIST: {
      const { list } = action;
      const uniqueList = uniqby(list, ({ id, assetType, type }) => {
        // A digest report can be connected with multiple forms
        if (type === 'digest') {
          return uuid();
        }
        if (assetType) {
          return `${id}_${assetType}`;
        }
        return id;
      });
      return { ...state, list: uniqueList };
    }
    case ACTION_TYPES.SET_DELETED_ITEMS: {
      const { items } = action;
      return { ...state, deletedItems: items };
    }
    case ACTION_TYPES.FETCH_LIST.REQUEST: {
      return {
        ...state,
        isInitialState: false
      };
    }
    case ACTION_TYPES.FETCH_LIST.SUCCESS: {
      const { list: currentList } = state;
      const { offset, assetFilterType, isMixStructureEnabled } = action;
      let { list: incomingList } = action;
      // TODO: This is temporary fix to an edge case where empty "ghost" reports are returned
      // from the backend, therefore these need to be filtered out.
      incomingList = incomingList.filter(({ id }) => id !== undefined);
      const newList = offset === 0 ? incomingList : [
        ...currentList.slice(0, offset),
        ...incomingList,
        ...currentList.slice(offset + incomingList.length)
      ];
      const currentPage = ROUTER_MAP[getPageFromUrl()];
      const useAssetType = isMixStructureEnabled ? assetFilterType === LISTING_TYPES.MIX : currentPage === LISTING_TYPES.TEAM_PAGE;
      const uniqueList = uniqby(newList, ({ id, type, assetType }) => {
        // A digest report can be connected with multiple forms
        if (type === 'digest') {
          return uuid();
        }

        if (useAssetType) {
          return `${id}_${assetType}`;
        }

        return id;
      });

      const diffCausedByDuplicate = newList.length - uniqueList.length;

      // PII forms moved to the top of the list for the new warning banner
      // const piiFilteredList = uniqueList.filter(item => item?.disable_form_pii_enforcement === true);
      // const piiSortedList = [...piiFilteredList].concat(uniqueList.filter(item => item?.disable_form_pii_enforcement === false));

      const { isInitialState, ...rest } = state;
      return {
        ...rest,
        // list: (getUrlParameter('newPiiWarning') === '1' && piiFilteredList.length > 0) ? piiSortedList : uniqueList,
        list: uniqueList,
        lastResultSetCount: incomingList.length - diffCausedByDuplicate
      };
    }
    case ACTION_TYPES.FETCH_LIST.ERROR: {
      return {
        ...state,
        isInitialState: false,
        lastResultSetCount: 0
      };
    }
    case ACTION_TYPES.SET_FAVORITE: {
      const { list } = state;
      const {
        id, favoriteKey, isNumeric, assetType
      } = action;
      const reverseBooleanFavorite = tmpFavorite => !tmpFavorite;
      const reverseNumericFavorite = tmpFavorite => (tmpFavorite === '1' ? '0' : '1');
      const reverseFavorite = isNumeric ? reverseNumericFavorite : reverseBooleanFavorite;
      const newList = list.map(item => {
        if (!areTheSameItems({ item, assetID: id, assetType })) {
          return item;
        }

        const favorite = get(item, favoriteKey);
        const newFavorite = reverseFavorite(favorite);
        return set(cloneDeep(item), favoriteKey, newFavorite);
      });

      return { ...state, list: newList };
    }
    case ACTION_TYPES.INIT_SHARE: {
      const { sharedID, shareData, assetType } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: sharedID, assetType })) {
          return { ...item, share: { ...item.share, ...shareData } };
        }
        return item;
      });
      return { ...state, list: newList };
    }
    // share link
    case ACTION_TYPES.GENERATE_SHARE_LINK.SUCCESS: {
      const { id, shareLink, assetType } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: id, assetType })) {
          return { ...item, share: { ...item.share, shareLink: shareLink } };
        }
        return item;
      });
      return { ...state, list: newList };
    }
    // shared with
    case ACTION_TYPES.ON_SEND.SUCCESS: {
      const { id, assetType, result } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: id, assetType })) {
          const newItem = { ...item, share: { ...item.share, sharedWith: result } };
          return newItem;
        }
        return item;
      });
      return { ...state, list: newList };
    }
    // share update property
    case ACTION_TYPES.ON_PROPERTY_CHANGE.SUCCESS: {
      const { sharedID, shareData, assetType } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: sharedID, assetType })) {
          return {
            ...item,
            share: {
              ...item.share,
              ...shareData.role ? { role: shareData.role } : {},
              permissions: { ...item.share.permissions, ...shareData }
            }
          };
        }
        return item;
      });
      return { ...state, list: newList };
    }
    case ACTION_TYPES.ON_ASSIGNEE_PERMISSION.SUCCESS: {
      const {
        id, shareID, submissionPermission, assetType
      } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: id, assetType })) {
          const updatedUser = item.share?.sharedWith?.find(user => user.id === shareID);
          if (updatedUser) {
            return {
              ...item,
              share: {
                ...item.share,
                sharedWith: item.share?.sharedWith?.map(user => (user.id === shareID ? { ...user, role: submissionPermission } : user))
              }
            };
          }
          return item;
        }
        return item;
      });
      return { ...state, list: newList };
    }
    case ACTION_TYPES.DELETE_ITEM.SUCCESS:
    case ACTION_TYPES.DELETE_SIGN_DOCUMENT.SUCCESS: {
      const { id, assetType } = action;
      const { list } = state;
      return { ...state, list: list.filter(item => !areTheSameItems({ item, assetID: id, assetType })) };
    }
    case ACTION_TYPES.ON_REVOKE_MULTIPLE_USER.SUCCESS:
    case ACTION_TYPES.ON_REVOKE_USER.SUCCESS: {
      const { id, shareID, assetType } = action;
      const { list } = state;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: id, assetType })) {
          if (Array.isArray(shareID)) {
            return { ...item, share: { ...item.share, sharedWith: item.share?.sharedWith?.filter(user => shareID.indexOf(user?.id) < 0) } };
          }
          const newItem = { ...item, share: { ...item.share, sharedWith: item.share?.sharedWith?.filter(user => user?.id !== shareID) } };
          return newItem;
        }
        return item;
      });
      return { ...state, list: newList };
    }
    case ACTION_TYPES.UPDATE_ITEM.SUCCESS:
    case ACTION_TYPES.RENAME_ITEM.SUCCESS:
    case ACTION_TYPES.RENAME_SIGN_DOCUMENT.SUCCESS:
    case ACTION_TYPES.RENAME_AGENT.SUCCESS:
    case ACTION_TYPES.ENABLE_AGENT.SUCCESS:
    case ACTION_TYPES.DISABLE_AGENT.SUCCESS: {
      const { list } = state;
      const { id, payload, assetType } = action;
      const newList = list.map(item => {
        if (areTheSameItems({ item, assetID: id, assetType })) {
          return ({ ...item, ...payload, ...(payload?.sheets ? { name: payload.sheets[id].name } : {}) });
        }
        return item;
      });
      return { ...state, list: newList };
    }
    case ACTION_TYPES.SET_FILTERED_LIST: {
      const { list } = action;
      return { ...state, baseList: list };
    }
    case ACTION_TYPES.FOLDER_ITEM_ACTION_PROCESS: {
      const { list } = state;
      const { itemID, folderID, actionType } = action;
      let newList = list;
      switch (actionType) {
        case FOLDER_ITEM_ACTION_TYPES.ADD:
          newList = newList.map(item => {
            const { folders = '', id } = item;
            if (id === itemID) {
              const newFolders = folders?.length !== 0 ? [...(new Set([...folders?.split?.(',').filter(f => f), folderID]))].join(',') : folderID;
              return { ...item, folders: newFolders };
            }
            return item;
          });
          break;
        case FOLDER_ITEM_ACTION_TYPES.REMOVE: {
          newList = newList.map(item => {
            const { folders = '', id } = item;
            if (id === itemID) {
              const newFolders = folders?.length !== 0 ? [...folders?.split?.(',').filter(f => f).filter(f => f !== folderID)].join(',') : '';
              return { ...item, folders: newFolders };
            }
            return item;
          });
          break;
        }
        default:
          return state;
      }
      return { ...state, list: newList };
    }
    case ACTION_TYPES.BULK_DELETE.SUCCESS:
    case ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.SUCCESS:
    case ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.SUCCESS: {
      const { list } = state;
      const { deletedItemList = [] } = action;
      const newList = list.filter(({ id, assetType }) => !findItemByIDAndType({ list: deletedItemList, assetID: id, assetType }));
      return { ...state, list: newList };
    }
    case ACTION_TYPES.MOVE_TO_TEAM.SUCCESS:
    case ACTION_TYPES.MOVE_FROM_TEAM.SUCCESS: {
      return { ...state };
    }
    case ACTION_TYPES.CANCEL_MOVE_JOBS.SUCCESS: {
      const { movingJobs } = state;
      const { id, assetType } = action;

      const newMovingJobs = Object.keys(movingJobs).reduce((acc, curr) => {
        if (curr !== `${id}_${assetType}`) {
          return { ...acc, [curr]: movingJobs[curr] };
        }
        return acc;
      }, {});

      return { ...state, movingJobs: newMovingJobs };
    }
    case ACTION_TYPES.FETCH_MOVE_JOBS.SUCCESS: {
      const { list, movingJobs } = state;
      const { jobs, assetFilterType } = action;
      const currentJobIDs = Object.keys(movingJobs);
      const nextJobIDs = Object.keys(jobs);

      const diff = currentJobIDs.filter(jobID => {
        return !nextJobIDs.includes(jobID);
      });

      const newList = list.filter(curr => !diff.includes(`${curr.id}_${getAssetType(assetFilterType, curr.assetType)}`));
      return { ...state, list: newList, movingJobs: action.jobs };
    }
    case ACTION_TYPES.UPDATE_FORM_STATUS.SUCCESS: {
      const { list } = state;
      const {
        formID, newStatus, isMixAssetFilter
      } = action;

      return {
        ...state,
        list: list.map(listItem => {
          if (listItem.id === formID && (!isMixAssetFilter || listItem.assetType === LISTING_TYPES_REVERT[LISTING_TYPES.FORM])) {
            return { ...listItem, status: newStatus, isDisabled: newStatus === 'DISABLED' };
          }
          return listItem;
        })
      };
    }
    default:
      return state;
  }
}
