import React, { Suspense } from 'react';

import 'emoji-mart/css/emoji-mart.css';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const PickerComponent = safeLazy(() => import(/* webpackChunkName: "emoji_mart_picker" */'emoji-mart/dist/components/picker/picker'));

const Picker = props => (
  <Suspense fallback={<div />}>
    <PickerComponent {...props} />
  </Suspense>
);

export default Picker;
