import React, { forwardRef, useMemo } from 'react';
import { string, bool, func } from 'prop-types';
import { Button } from '@jotforminc/magnet';
import { IconAngleDown } from '@jotforminc/svg-icons';
import classNames from 'classnames';

import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';

import translate from '../../../utils/translate';

import {
  LISTING_TYPES, ALL_ASSETS_ID, DEFAULT_FOLDER_IDS, TOOLBAR_MORE_ACTIONS, BULK_FAVORITE_ENABLED_FOLDERS, BULK_FAVORITE_ENABLED_ASSET_TYPES
} from '../../../constants';
import { ListItemSharedDefaultProps } from '../../ListItem/propTypes';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';

const ButtonWithRref = forwardRef((props, ref) => (
  <Button
    ref={ref}
    data-testid="more-toolbar"
    {...props}
    colorStyle="secondary"
    variant="outline"
    endIcon={IconAngleDown}
    // eslint-disable-next-line react/prop-types
    className={classNames('forMoreButton', props.className)}
  >
    {translate('More')}
  </Button>
));

const OptionTextRenderer = ({ text, className = '' }) => <div className={classNames('isMenuButton', className)}>{translate(text)}</div>;
OptionTextRenderer.propTypes = { text: string.isRequired, className: string };

const POPOVER_PROPS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions,
    ...ListItemSharedDefaultProps.PopoverModifierProps
  },
  className: 'listings-popover-modal isMoreMenu isToolbarMoreMenu'
};

const MoreAction = forwardRef(({
  anyUnread,
  anyEnabled,
  anyDisabled,
  anyFavorite,
  selectedFolder,
  selectedItemsListingType,
  onSelectionChange
}, ref) => {
  const options = useMemo(() => {
    const archiveOption = selectedFolder === DEFAULT_FOLDER_IDS.ARCHIVE ? ['Unarchive', TOOLBAR_MORE_ACTIONS.UNARCHIVE] : ['Archive', TOOLBAR_MORE_ACTIONS.ARCHIVE];

    const favoriteOption = BULK_FAVORITE_ENABLED_FOLDERS.indexOf(selectedFolder) > -1 || (
      selectedFolder === ALL_ASSETS_ID[LISTING_TYPES.MIX] && BULK_FAVORITE_ENABLED_ASSET_TYPES.indexOf(selectedItemsListingType) > -1
    )
      ? [(selectedFolder === DEFAULT_FOLDER_IDS.FAVORITES) || anyFavorite ? ['Remove From Favorites', TOOLBAR_MORE_ACTIONS.UNSTAR, true] : ['Add to Favorites', TOOLBAR_MORE_ACTIONS.STAR, true]]
      : [];

    const myFormsOptions = selectedItemsListingType === LISTING_TYPES.FORM
      ? [['Mark All as Read', TOOLBAR_MORE_ACTIONS.MARK_AS_READ, anyUnread], ['Enable', TOOLBAR_MORE_ACTIONS.ENABLE, anyDisabled], ['Disable', TOOLBAR_MORE_ACTIONS.DISABLE, anyEnabled]]
      : [];

    return [
      [...archiveOption, true],
      ...favoriteOption,
      ...myFormsOptions
    ].map(([text, value, isEnabled]) => ({
      value,
      hideOnDisable: false,
      disabled: !isEnabled,
      Renderer: PopoverOption,
      text: <OptionTextRenderer text={text} />,
      title: text,
      useSvgIcons: true
    }));
  }, [anyFavorite, anyEnabled, anyDisabled, selectedFolder, selectedItemsListingType, anyUnread]);

  const handleMoreButtonClick = () => {
    WatchmanRecorder.trackEvent('click', 'showMore', 'myformsFormToolbar');
  };

  return (
    <PopoverMenu
      withoutChildren
      ref={ref}
      options={options}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonWithRref}
      onSelectionChange={onSelectionChange}
      onButtonClick={handleMoreButtonClick}
      autoFocusToFirstChild={true}
    >
      {isVisible => (
        <Button
          colorStyle="secondary"
          variant="outline"
          endIcon={IconAngleDown}
          className={`forMoreButton ${isVisible ? 'isMenuVisible' : ''}`}
        >
          {translate('More')}
        </Button>
      )}
    </PopoverMenu>
  );
});

MoreAction.propTypes = {
  anyUnread: bool.isRequired,
  anyEnabled: bool.isRequired,
  anyDisabled: bool.isRequired,
  anyFavorite: bool.isRequired,
  selectedFolder: string.isRequired,
  selectedItemsListingType: string.isRequired,
  onSelectionChange: func.isRequired
};

export default MoreAction;
