import React from 'react';
import {
  shape,
  func,
  string,
  bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { ScDismissButton } from './sc/scDismissButton';

const DismissButton = ({
  notification,
  showText,
  onDismiss
}) => {
  const { dismiss: { text } } = notification;

  const handleClick = notifcn => {
    onDismiss(notifcn);
  };

  return (
    <ScDismissButton
      onClick={() => handleClick(notification)}
    >
      {showText ? t(text) : ''}
    </ScDismissButton>
  );
};

DismissButton.propTypes = {
  notification: shape({
    text: string,
    dismiss: shape({ text: string }),
    name: string
  }),
  showText: bool,
  onDismiss: func
};

DismissButton.defaultProps = {
  notification: {
    text: '',
    dismiss: { text: '' },
    name: ''
  },
  showText: true,
  onDismiss: f => f
};

export default DismissButton;
