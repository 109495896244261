import {
  select, takeLatest, put
} from 'redux-saga/effects';
import { setCookie } from '@jotforminc/utils';

import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

import {
  COOKIE_KEYS,
  FOLDER_TYPES,
  LISTING_TYPES,
  MOBILE_BREAKDOWN_WIDTH,
  TEAM_ENABLED_RESOURCES,
  getDefaultOrderByValue,
  ALL_ASSETS_ID,
  DEFAULT_FOLDER_IDS,
  MIX_ENABLED_RESOURCES,
  MIX_FILTER_ENABLED_LISTING_TYPES
} from '../../constants';
import { ACTION_TYPES } from '../../store/actionTypes';
import {
  getCookie, getAssetTypeFromListingType, isValidSorting, isV2EndpointEnabled, isV2EnabledForAllPages
} from '../../utils';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

function* formInboxPanelFlow({ formID }) {
  const lastOpenedInboxPanelId = yield select(SELECTORS.getLastOpenedInboxPanelId);
  if (formID !== lastOpenedInboxPanelId) {
    yield put(ACTION_CREATORS.changeInboxPanel(formID));
    WatchmanRecorder.trackEvent('click', 'changeFormWithInboxOpen');
  }
  yield put(ACTION_CREATORS.toggleInboxPanel(true));
}

function getBaseFilterByFolder({
  folder, assetFilterType
}) {
  const LISTING_FILTERS = {
    [LISTING_TYPES.FORM]: {
      allForms: { archived: '0', 'status:ne': ['DELETED', 'PURGED'] },
      favorites: { archived: '0', favorite: '1', 'status:ne': ['DELETED', 'PURGED'] },
      archive: { archived: '1', 'status:ne': ['DELETED', 'PURGED'] },
      trash: { status: 'DELETED' },
      default: { archived: '0', 'status:ne': ['DELETED', 'PURGED'] }
    },
    [LISTING_TYPES.TASK]: {
      myApprovals: { 'status:ne': ['DELETED', 'PURGED', 'ARCHIVED'] },
      favorites: { archived: '0', favorite: '1', 'status:ne': ['DELETED', 'PURGED'] },
      archive: { status: 'ARCHIVED' },
      trash: { status: 'DELETED' },
      default: { status: 'ENABLED' }
    },
    [LISTING_TYPES.PORTAL]: {
      portals: { 'status:ne': ['TRASHED', 'ARCHIVED'] },
      favorites: { star: '1', favorite: '1', 'status:ne': ['TRASHED', 'ARCHIVED'] },
      archive: { status: 'ARCHIVED' },
      trash: { status: 'TRASHED' },
      default: { status: 'ENABLED' }
    },
    [LISTING_TYPES.ASSIGNED_FORM]: {
      mySubmissions: { status: 'ACTIVE' },
      default: { status: 'ENABLED' }
    },
    [LISTING_TYPES.DOCUMENT]: {
      favorites: { status: 'DRAFT', star: '1', favorite: '1' },
      archive: { status: 'ARCHIVED' },
      trash: { status: 'DELETED' },
      documents: { status: 'DRAFT' },
      default: { status: 'ENABLED' }
    },
    [LISTING_TYPES.PAGES]: {
      pages: { 'status:ne': ['TRASHED'] },
      favorites: { 'status:ne': ['TRASHED'], star: '1', favorite: '1' },
      trash: { status: 'TRASHED' },
      default: { 'status:eq': ['ENABLED', 'DISABLED'] }
    },
    default: {
      favorites: {
        status: 'ENABLED', star: '1', favorite: '1', statusV2: 'favorited'
      },
      archive: { status: 'ARCHIVED', statusV2: 'archived' },
      trash: { status: 'TRASHED', statusV2: 'trashed' },
      documents: { status: 'DRAFT', statusV2: 'active' },
      mix: { status: 'ENABLED', statusV2: 'active' },
      default: { status: 'ENABLED', statusV2: 'active' }
    }
  };

  const folderFilters = LISTING_FILTERS[assetFilterType] || LISTING_FILTERS.default;
  return folderFilters[folder] || folderFilters[ALL_ASSETS_ID[assetFilterType]] || folderFilters.default;
}

// eslint-disable-next-line complexity
function getFilterByFolder({
  folder, folderType, assetFilterType, currentPage, teamFilter
}) {
  const isV2EnabledForAll = isV2EnabledForAllPages({
    currentPage, folderType, selectedFolder: folder, teamFilter
  });
  if (MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(assetFilterType) > -1 || currentPage === LISTING_TYPES.TEAM_PAGE || isV2EnabledForAll) {
    const enableMultipleAssetFilterV2 = isV2EndpointEnabled({
      currentPage, folderType, selectedFolder: folder, teamFilter
    });
    const enabledResources = enableMultipleAssetFilterV2 ? MIX_ENABLED_RESOURCES : TEAM_ENABLED_RESOURCES;
    const resourceFilters = enabledResources.reduce((acc, listingType) => {
      const filter = getBaseFilterByFolder({
        folder, assetFilterType: listingType
      });
      const currentKey = enableMultipleAssetFilterV2 ? 'mix' : getAssetTypeFromListingType(listingType);
      if (filter) {
        const { favorite, star, ...filterExceptFavorite } = filter; // Extract favorite related props since team assets have seperate configuration
        acc[currentKey] = folder === DEFAULT_FOLDER_IDS.FAVORITES ? filterExceptFavorite : filter;
      }

      return acc;
    }, {});

    return { resourceFilters, ...(folder === DEFAULT_FOLDER_IDS.FAVORITES && { favorite: '1' }) };
  }
  return getBaseFilterByFolder({ folder, assetFilterType });
}

function* setFilterByFolder({ folder, folderType }) {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const selectedFolder = yield select(SELECTORS.getSelectedFolder);
  const selectedFolderType = yield select(SELECTORS.getFolderType);
  const currentSortingCookie = getCookie(`${folder}Sorting`);
  const teamFilter = yield select(SELECTORS.getTeamFilter);
  if (currentSortingCookie && isValidSorting(currentPage, assetFilterType, folder, currentSortingCookie)) {
    yield put(ACTION_CREATORS.setOrderBy(currentSortingCookie));
  } else {
    const defaultVal = getDefaultOrderByValue(currentPage, assetFilterType, folder);

    if (defaultVal) {
      yield put(ACTION_CREATORS.setOrderBy(defaultVal));
    }
  }
  const filter = getFilterByFolder({
    folder: folder || selectedFolder,
    folderType: folderType || selectedFolderType,
    assetFilterType: [FOLDER_TYPES.STATIC, FOLDER_TYPES.TEAM].includes(folderType) ? currentPage : assetFilterType,
    currentPage,
    teamFilter
  });
  setCookie(COOKIE_KEYS.LAST_FOLDER(currentPage), folder, 365);
  if (folderType === FOLDER_TYPES.TEAM_FOLDER || folderType === FOLDER_TYPES.TEAM) {
    setCookie(COOKIE_KEYS.LAST_FOLDER(LISTING_TYPES.TEAM_PAGE), folder, 365);
  }
  yield put(ACTION_CREATORS.setFilter(filter));
}

function* handleMobileDevice() {
  const isMobile = window.innerWidth <= MOBILE_BREAKDOWN_WIDTH;
  yield put(ACTION_CREATORS.setMobileDevice(isMobile));
}

export function* rootUIFlow() {
  yield handleMobileDevice();
  yield takeLatest(ACTION_TYPES.OPEN_INBOX_PANEL, formInboxPanelFlow);
  yield takeLatest([ACTION_TYPES.SET_SELECTED_FOLDER, ACTION_TYPES.SET_TEAM_FILTER], setFilterByFolder);
}
