/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { IconExclamationCircleFilled, IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import { getUrlParameter, handleCustomNavigation } from '@jotforminc/utils';
import cx from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { KYC_ACTIONS, PII_WARNINGS_TEXTS } from '../../constants';
import { actionTrack } from '../../api';

const PIIWarnings = ({
  piiDisabled, piiStatus, piiDisabledAt = '', piiDocuments = '', piiUrl
}) => {
  const getMissingDocuments = {
    'Identity Card': 'your Identity Card',
    'Organization Registration Document': 'your Organization Registration Document',
    'Both of Identity Card and Organization Registration Document': 'both of your Identity Card and Organization Registration Document'
  };

  const handlePiiStatus = async () => {
    if (['STARTED', 'LACK_OF_DOCUMENTS'].includes(piiStatus)) {
      try {
        const data = {
          project: 'kyc_listings_warnings',
          oldActions: {
            action: 'seen',
            target: KYC_ACTIONS[`${piiStatus}_${piiDisabled}_SEEN`]
          }
        };

        await actionTrack(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handlePiiStatus();
  }, [piiStatus, piiDisabled]);

  const handleVerify = async () => {
    try {
      const data = {
        project: 'kyc_listings_warnings',
        oldActions: {
          action: 'click',
          target: KYC_ACTIONS[`${piiStatus}_${piiDisabled}_CLICK`]
        }
      };

      await actionTrack(data);
    } catch (error) {
      console.log(error);
    }

    handleCustomNavigation(piiUrl, '_blank', true);
  };

  return (
    <div className='pii-warning-wrapper'>
      <div className={cx('pii-warning-form-container', { warning: piiDisabled === '0' || getUrlParameter('kycStatus') === 'warning' })}>
        <div className='pii-warning'>
          <div className='pii-warning-content'>
            <div className={cx('icon-container', { warning: piiDisabled === '0' || getUrlParameter('kycStatus') === 'warning' })}>
              {(piiDisabled === '0' || getUrlParameter('kycStatus') === 'warning') && <IconExclamationCircleFilled />}
              {(piiDisabled === '1' || getUrlParameter('kycStatus') === 'disabled') && <IconExclamationTriangleFilled />}
            </div>
            <div className='pii-texts-container'>
              <p className='pii-title'>
                {((piiDisabled === '0' && piiStatus !== 'LACK_OF_DOCUMENTS') || getUrlParameter('kycForms') === 'started') && t(PII_WARNINGS_TEXTS.FORMS_WILL_BE_DISABLED)}
                {((piiDisabled === '1' && piiStatus !== 'LACK_OF_DOCUMENTS') || getUrlParameter('kycForms') === 'disabled') && t(PII_WARNINGS_TEXTS.FORMS_HAVE_BEEN_DISABLED)}
                {(piiStatus === 'LACK_OF_DOCUMENTS' || getUrlParameter('kycForms') === 'lackOfDocuments') && t(PII_WARNINGS_TEXTS.UPLOAD_SUPPORTING_DOCUMENTS)}
              </p>
              <p className='pii-description'>
                {((piiDisabled === '0' && piiStatus !== 'LACK_OF_DOCUMENTS') || getUrlParameter('kycForms') === 'started') && translationRenderer(PII_WARNINGS_TEXTS.VERIFY_BY)({
                  renderer1: renderText1 => <strong>{t(renderText1)}</strong>,
                  renderer2: () => (
                    <strong>
                      {t(dayjs(piiDisabledAt).format('MMMM'))}
                      {' '}
                      {dayjs(piiDisabledAt).format('DD, YYYY')}
                    </strong>
                  ),
                  renderer3: renderText3 => (
                    <a href='https://www.jotform.com/contact/?utm_source=myforms&&utm_content=link_contactus&utm_campaign=pii-warning-verify-identity&username=%7Busername%7D' target='_blank'>
                      {t(renderText3)}
                    </a>
                  )
                })}
                {((piiDisabled === '1' && piiStatus !== 'LACK_OF_DOCUMENTS') || getUrlParameter('kycForms') === 'disabled') && translationRenderer(PII_WARNINGS_TEXTS.DETECTED_PII)({
                  renderer1: renderText1 => <strong>{t(renderText1)}</strong>,
                  renderer2: renderText2 => (
                    <a href='https://www.jotform.com/contact/?utm_source=myforms&&utm_content=link_contactus&utm_campaign=pii-warning-verify-identity&username=%7Busername%7D' target='_blank'>
                      {t(renderText2)}
                    </a>
                  )
                })}
                {(piiStatus === 'LACK_OF_DOCUMENTS' || getUrlParameter('kycForms') === 'lackOfDocuments') && translationRenderer(PII_WARNINGS_TEXTS.RECEIVED_SUBMISSON)({
                  renderer1: () => (
                    <span>
                      <strong>
                        {t(getMissingDocuments[piiDocuments])}
                      </strong>
                      {' '}
                      {['Identity Card', 'Organization Registration Document'].includes(piiDocuments) ? t('is') : t('are')}
                    </span>
                  ),
                  renderer2: renderText2 => (
                    <a href='https://www.jotform.com/contact/?utm_source=myforms&&utm_content=link_contactus&utm_campaign=pii-warning-upload-documents&username=%7Busername%7D' target='_blank'>
                      {t(renderText2)}
                    </a>
                  )
                })}
              </p>
            </div>
          </div>
          <button
            type='button'
            className='pii-primary-button'
            onClick={handleVerify}
          >
            {(piiStatus !== 'LACK_OF_DOCUMENTS' && getUrlParameter('kycForms') !== 'lackOfDocuments') && t(PII_WARNINGS_TEXTS.VERIFY_NOW)}
            {(piiStatus === 'LACK_OF_DOCUMENTS' || getUrlParameter('kycForms') === 'lackOfDocuments') && t(PII_WARNINGS_TEXTS.UPLOAD_DOCUMENTS)}
          </button>
        </div>
      </div>
    </div>
  );
};

PIIWarnings.propTypes = {
  piiDisabled: PropTypes.string.isRequired,
  piiStatus: PropTypes.string.isRequired,
  piiDisabledAt: PropTypes.string,
  piiDocuments: PropTypes.string,
  piiUrl: PropTypes.string.isRequired
};

export default PIIWarnings;
