import isUndefined from 'lodash/isUndefined';

class ActionRecorder {
  actor?: string | null;

  constructor() {
    this.actor = null;
  }

  setActor(actor: string) {
    this.actor = actor;
  }

  tick({
    actor, action, target, customProject = ''
  }: { actor?: string, action: string, target: string, customProject?: string }) {
    const project = customProject || window.activeProject;
    const events = (project && window.JotFormActions) ? window.JotFormActions(project) : null;
    if (!events || isUndefined(events.tick)) return;
    if (!this.actor) {
      this.actor = actor || window.user?.username;
    }
    events.tick({ actor: this.actor, action, target });
  }
}

export default new ActionRecorder();
