import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';

// import useInterval from '../hooks/useInterval';
import './styles/black-friday-2024.scss';
// import { CampaignCountDown } from './utils/CampaignCountDown';

export const BlackFriday2024 = ({ utmSource, accountTypeName }) => {
//   const getDistance = () => {
//     // Get today's date and time
//     const now = new Date().getTime();
//     // Find the distance between now and the count down date
//     const distance = expirationDate - now;
//     return distance;
//   };
//   const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
//   const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
//   useInterval(() => {
//     const distance = getDistance();
//     if (distance < 0) {
//       setCountdown('00 : 00 : 00');
//       setDelay(null);
//     }
//     // Time calculations for days, hours, minutes and seconds
//     let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//     let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //     if (hours < 10) hours = `0${hours}`;

  //     if (minutes < 10) minutes = `0${minutes}`;

  //     if (seconds < 10) seconds = `0${seconds}`;

  //     // Output the result in an element with id="demo"
  //     setCountdown(`${hours}h : ${minutes}m : ${seconds}s `);
  //   }, delay);
  return (
    <section className='blackfriday-2024-wrapper'>
      <div className='blackfriday-2024-container'>
        <div className='title-wrapper'>
          <div className="subtitle">
            {t('LIMITED TIME OFFER')}
          </div>
          <div className="center-area">
            <div className="image-1">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/myforms-header-banner/bf.svg" alt="black friday" />
            </div>
            <div className="image-2">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/blackfriday/myforms-header-banner/lightning.svg" alt="black friday lightning" />
            </div>
          </div>
        </div>
        {/* <CampaignCountDown /> */}
        <a className="locale upgrade-now-button" href={`/pricing?utm_campaign=bf2024&utm_medium=banner&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}>
          {t('Upgrade Now')}
        </a>
      </div>
    </section>
  );
};

BlackFriday2024.propTypes = {
  utmSource: string.isRequired,
  //   expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderBlackFriday2024 = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<BlackFriday2024 utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
