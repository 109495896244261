import { isSalesforceEnv } from '@jotforminc/utils';

import defaultFeatureFlags from './featureFlags.default.json';
import salesforceEnvFeatureFlags from './salesforceEnvFeatureFlags.json';
import { enterpriseFeatureFlags } from './EnterpriseFeatureFlags';

export const getFeatureFlagList = () => {
  return {
    ...(window.JOTFORM_ENV === 'ENTERPRISE' ? enterpriseFeatureFlags : defaultFeatureFlags),
    ...(isSalesforceEnv() ? salesforceEnvFeatureFlags : {}),
    ...(window.isSalesforceLivePrefillAllowed ? {
      SALESFORCE_LIVE_PREFILL: {
        name: 'SALESFORCE_LIVE_PREFILL',
        value: true
      },
      ...(window.isSalesforceLivePrefillOnlyUser ? {
        HIDE_LEGACY_SALESFORCE_PREFILL_: {
          name: 'HIDE_LEGACY_SALESFORCE_PREFILL_',
          value: true
        }
      } : {})
    } : {})
  };
};
