import React from 'react';
import classNames from 'classnames';
import { IconCheck } from '@jotforminc/svg-icons';

// eslint-disable-next-line react/prop-types
export const renderDropdownOption = classes => ({ option: { text, value, ItemIcon }, isSelected: isOptionSelected }) => (
  <div data-value={value} className={classNames(classes, { isSelected: isOptionSelected })} data-testid={value}>
    {ItemIcon && <ItemIcon className="w-5 h-5 color-white" />}
    {text}
    <span className='checkIcon'><IconCheck className="w-4 h-4 color-white" /></span>
  </div>
);
