/* eslint-disable complexity */
import React, { useMemo, Fragment } from 'react';
import { bool, func, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Feature } from '@jotforminc/feature-flag';
import { Button } from '@jotforminc/magnet';
import { getVisibleActionButtons, getActionHandlers, getButtonRenderers } from './utils/actionButtons';
import translate from '../../../utils/translate';
import { LISTING_TYPES, FEATURE_LIST, ASSET_TYPES } from '../../../constants';
import { getPermissionsByAccessType } from '../../../utils/permissions';
import { SELECTORS } from '../../../store/selectors';
import { ACTION_CREATORS } from '../../../store/actionCreators';

const ActionButtonList = ({
  moreMenuRef,
  handleSubmissions,
  handleMultipleTrash,
  handleMultipleDelete,
  handleMultipleRestore,
  handleMoreOptionSelect,
  isAddToFolderEnabled,
  isMoveToTeamEnabled,
  isMoveFromTeamEnabled
}) => {
  const dispatch = useDispatch();
  const selectedFolderObject = useSelector(SELECTORS.getSelectedFolderObject);
  const selectedFolder = selectedFolderObject?.id;
  const selectedIds = useSelector(SELECTORS.getSelectedItemIDs);
  const isSharedWithMeFolder = useSelector(SELECTORS.isSharedWithMeFolder);
  const selectedFormAccessType = useSelector(SELECTORS.selectedFormAccessType) || [];
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const selectedItemAccessType = isSharedWithMeFolder && selectedFormAccessType && selectedFormAccessType?.length === 1 ? selectedFormAccessType[0] : 'none';
  const extraPermissions = useMemo(() => ({
    ...getPermissionsByAccessType(selectedItemAccessType)
  }), [
    selectedItemAccessType
  ]);
  const selectedItemsListingType = useSelector(SELECTORS.getSelectedItemsListingType);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const isAppsActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.APPS));
  const isPortalListing = selectedItemsListingType === LISTING_TYPES.PORTAL;
  const selectedPortalItem = useSelector(state => {
    if (isPortalListing) {
      return SELECTORS.getItemByIDAndType(selectedIds[0], ASSET_TYPES.PORTAL)(state);
    }
    return null;
  });

  const isDigestEnabled = useSelector(SELECTORS.getUserAllowDigest);
  const anyEncryptedFormSelected = useSelector(SELECTORS.isAnyEncryptedFormSelected);
  const isMoveToDisabled = useSelector(SELECTORS.hasMoveDisabledSelectedItems);
  const isBoardEnabled = useSelector(SELECTORS.getUserAllowBoards);
  const teamPermissionFetcher = useSelector(SELECTORS.getTeamPermissionFetcher);
  const anyUnread = useSelector(SELECTORS.isAnyUnreadItemSelected);
  const anyEnabled = useSelector(SELECTORS.isAnyEnabledItemSelected);
  const anyDisabled = useSelector(SELECTORS.isAnyDisabledItemSelected);
  const anyFavorite = useSelector(SELECTORS.isAnyFavoriteItemSelected);
  const isPurgeDisabled = useSelector(SELECTORS.hasPurgeDisabledSelectedItems);
  const getTeamPermission = permission => teamPermissionFetcher(permission, true);
  const handleCreateApp = () => dispatch(ACTION_CREATORS.createApp());
  const handleCreateDigest = selectedItemType => dispatch(ACTION_CREATORS.createDigest({ assetType: selectedItemType }));
  const openMoveFromTeamModal = selectedListItemIDs => dispatch(ACTION_CREATORS.moveFromTeamRequest(selectedListItemIDs));

  const visibleButtons = getVisibleActionButtons({
    selectedItemsListingType,
    currentTeamID,
    selectedPortalItem,
    isDigestEnabled,
    anyEncryptedFormSelected,
    isMoveToDisabled,
    getTeamPermission,
    isAddToFolderEnabled,
    isMoveToTeamEnabled,
    isMoveFromTeamEnabled,
    isSharedWithMeFolder,
    selectedFolder,
    isSelectedItemPlural: selectedIds.length > 1,
    extraPermissions,
    isAppsActive,
    isPurgeDisabled,
    isBoardEnabled,
    currentPage
  });

  const actionHandlers = getActionHandlers({
    handleCreateApp,
    handleCreateDigest,
    selectedItemsListingType,
    handleMultipleDelete,
    handleSubmissions,
    selectedIds,
    openMoveFromTeamModal,
    handleMultipleTrash,
    handleMultipleRestore
  });

  const buttonRenderers = getButtonRenderers({
    visibleButtons,
    actionHandlers,
    selectedItemsListingType,
    selectedIds,
    currentTeamID,
    moreMenuRef,
    anyUnread,
    anyEnabled,
    anyFavorite,
    anyDisabled,
    selectedFolder,
    handleMoreOptionSelect,
    currentPage
  });

  return buttonRenderers.map(button => {
    const {
      isPlainButton, isVisible, className, onClick, text, hasNewBadge, IconRenderer, buttonColor, ContentRenderer, featureFlag, key
    } = button;
    if (!isVisible) return null;

    const ButtonRenderer = isPlainButton ? (
      <Button
        className={className}
        colorStyle={buttonColor || 'secondary'}
        variant="outline"
        startIcon={IconRenderer || null}
        indicatorText={hasNewBadge ? translate('NEW') : null}
        onClick={onClick}
        data-testid={key}
      >
        {text ? translate(text) : null}
      </Button>
    ) : ContentRenderer;

    if (!featureFlag) {
      return (
        <Fragment key={key}>{ButtonRenderer}</Fragment>
      );
    }

    return (
      <Feature feature={featureFlag} key={key}>
        {ButtonRenderer}
      </Feature>
    );
  });
};

ActionButtonList.propTypes = {
  moreMenuRef: shape({}).isRequired,
  handleSubmissions: func.isRequired,
  handleMultipleTrash: func.isRequired,
  handleMultipleDelete: func.isRequired,
  handleMultipleRestore: func.isRequired,
  handleMoreOptionSelect: func.isRequired,
  isAddToFolderEnabled: bool.isRequired,
  isMoveToTeamEnabled: bool.isRequired,
  isMoveFromTeamEnabled: bool.isRequired
};

export default ActionButtonList;
