/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  arrayOf, shape, string, func, bool
} from 'prop-types';
import classNames from 'classnames';
import withScrolling from 'react-dnd-scrolling';
import { useDispatch, useSelector } from 'react-redux';
import Nestable from 'react-nestable';
import { Loader } from '@jotforminc/enterprise-components';
import {
  ALL_ASSETS_ID, FOLDER_DROP_POSITIONS, FOLDER_TYPES, getDefaultFolders, LISTING_TYPES, DEFAULT_FOLDER_IDS
} from '../../constants';
import Folder from './Folder';
import ShowMoreLessButton from '../Folder/ShowMoreLessButton';
import { childrenToSubfolders, subfoldersToChildren } from '../../utils';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { SELECTORS } from '../../store/selectors';

const FolderWrapper = ({ children, ...props }) => <ul {...props}>{children}</ul>;

const INITIAL_VISIBLE_FOLDER_COUNT = 5;

const checkIsSelectedFolderVisible = (folderList, folderId) => {
  return !!folderList.find(folder => {
    if (folder.id === folderId) return true;

    if (folder.subfolders) return checkIsSelectedFolderVisible(folder.subfolders, folderId);

    return false;
  });
};

const getVisibleFolderList = allList => allList.slice(0, INITIAL_VISIBLE_FOLDER_COUNT);

const FolderList = ({
  folders = getDefaultFolders(LISTING_TYPES.ASSIGNED_FORM), className = '', selectedFolderID = '', handleClick = f => f, onDrop = f => f, isDroppable = false,
  hasContextMenu: hasContextMenuFolderList = false, user = {}, isRootFolder = false, isUserFoldersGroup = false, selectedFolder = {}, showAllFolders = false,
  resetAllFoldersVisibility = f => f, isReorderFoldersAllowed = false, folderId = ''
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const filteredFolderList = folders.filter(f => f);
  const filteredFoldersWithoutSeperators = folders.filter(item => item && item?.itemType !== 'listingSeperator');
  const disableDraggingAction = !isReorderFoldersAllowed;
  const classes = classNames(
    'lsApp-sidebar-folder',
    { hasNoFolders: !filteredFoldersWithoutSeperators.length },
    { disableDraggingAction: disableDraggingAction && [DEFAULT_FOLDER_IDS.USER_FOLDER_GROUP].indexOf(folderId) > -1 },
    className
  );
  const hasShowMoreLessButtons = isUserFoldersGroup;
  const [isAllFoldersVisible, setIsAllFoldersVisible] = useState(!hasShowMoreLessButtons);
  const [isSelectedFolderVisibilityChecked, setIsSelectedFolderVisibilityChecked] = useState(false);

  useEffect(() => {
    if (hasShowMoreLessButtons && selectedFolderID && !isSelectedFolderVisibilityChecked) {
      setIsSelectedFolderVisibilityChecked(true);
      const { folderType, isShared } = selectedFolder || {};
      const checkSelectedVisibility = folderType === FOLDER_TYPES.ASSET_FOLDER && !isShared;
      if (checkSelectedVisibility && !checkIsSelectedFolderVisible(getVisibleFolderList(filteredFolderList), selectedFolderID)) {
        setIsAllFoldersVisible(true);
      }
    }
  }, [selectedFolderID]);

  useEffect(() => {
    if (showAllFolders && isUserFoldersGroup && hasShowMoreLessButtons) {
      if (!isAllFoldersVisible) setIsAllFoldersVisible(true);
      resetAllFoldersVisibility();
    }
  }, [showAllFolders]);

  const folderList = !isAllFoldersVisible ? getVisibleFolderList(filteredFolderList) : filteredFolderList;

  const handleReorder = ({ dragItem, items, targetPath }) => {
    const newSubfolders = childrenToSubfolders(items);
    const order = targetPath.pop();
    const firstParent = newSubfolders[targetPath.shift()];
    const remainingPath = [...targetPath];
    const parentFolder = remainingPath.reduce((acc, index) => acc.subfolders[index], firstParent);
    const parentId = parentFolder?.id || ALL_ASSETS_ID[currentPage];
    const searchFolders = parentId === ALL_ASSETS_ID[currentPage] ? newSubfolders : parentFolder.subfolders;
    let position = FOLDER_DROP_POSITIONS.OVER;
    let referenceFolderID = parentId;

    if (order === 0 && searchFolders.length <= 1) {
      position = FOLDER_DROP_POSITIONS.OVER;
      referenceFolderID = parentId;
    } else if (order === 0 && searchFolders.length > 1) {
      position = FOLDER_DROP_POSITIONS.BEFORE;
      referenceFolderID = searchFolders[order + 1].id;
    } else {
      position = FOLDER_DROP_POSITIONS.AFTER;
      referenceFolderID = searchFolders[order - 1].id;
    }
    dispatch(ACTION_CREATORS.reorderFolders({
      position,
      folderID: dragItem.id,
      referenceFolderID
    }));
  };

  const WrapperRenderer = useMemo(() => {
    return (isRootFolder && isUserFoldersGroup ? withScrolling('ul') : FolderWrapper);
  }, [isRootFolder, isUserFoldersGroup]);

  const NestableFolderRenderer = folder => {
    const {
      id, subfolders = [], hasContextMenu = hasContextMenuFolderList, isDroppable: isDroppableFolder = isDroppable, isTeamDroppable
    } = folder;

    const { canDrag = isDroppableFolder } = folder;

    return (
      <Folder
        {...folder}
        key={id}
        handleClick={handleClick}
        selectedFolderID={selectedFolderID}
        canDrag={canDrag}
        onDrop={onDrop}
        isDroppable={isDroppableFolder}
        isTeamDroppable={isTeamDroppable}
        hasContextMenu={hasContextMenu}
        user={user}
      >
        <FolderList
          folders={subfolders}
          selectedFolderID={selectedFolderID}
          handleClick={handleClick}
          isDroppable={isDroppable}
          onDrop={onDrop}
          hasContextMenu={hasContextMenu}
          isReorderFoldersAllowed={isReorderFoldersAllowed}
          folderId={folderId}
          user={user}
        />
      </Folder>
    );
  };

  const isAssetSubFolder = !!folderList.find(folder => folder.folderType === FOLDER_TYPES.ASSET_FOLDER && !folder.isShared);
  return (
    <>
      {(disableDraggingAction && [DEFAULT_FOLDER_IDS.USER_FOLDER_GROUP].indexOf(folderId) > -1) ? (
        <Loader withAnimation={true} teamAnimation={false} />
      ) : null}
      <WrapperRenderer className={classes}>
        {isAssetSubFolder ? (
          <Nestable
            items={subfoldersToChildren(folderList)}
            renderItem={({ item }) => NestableFolderRenderer(item)}
            onChange={handleReorder}
            confirmChange={({ destinationParent }) => {
              if (destinationParent?.id && destinationParent?.folderType) {
                dispatch(ACTION_CREATORS.addExpandedFolders({ id: destinationParent.id, folderType: destinationParent.folderType }));
              }
              return true;
            }}
          />
        ) : folderList.map(NestableFolderRenderer)}
      </WrapperRenderer>
      {hasShowMoreLessButtons && filteredFolderList.length > INITIAL_VISIBLE_FOLDER_COUNT ? (
        <ShowMoreLessButton
          isAllFoldersVisible={isAllFoldersVisible}
          setIsAllFoldersVisible={setIsAllFoldersVisible}
          isUserFoldersGroup={isUserFoldersGroup}
        />
      ) : null}
    </>
  );
};

FolderList.propTypes = {
  folders: arrayOf(shape()),
  selectedFolderID: string,
  handleClick: func,
  isDroppable: bool,
  hasContextMenu: bool,
  onDrop: func,
  className: string,
  user: shape({}),
  isRootFolder: bool,
  folderId: string,
  isReorderFoldersAllowed: bool,
  isUserFoldersGroup: bool,
  selectedFolder: shape({}),
  showAllFolders: bool,
  resetAllFoldersVisibility: func
};

export default FolderList;
