import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  func, number, object, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { useMobileView } from '../hooks';
import { breakpoints } from '../constants';
import { ActionContext } from '../context';
import { ScNotificationBell } from './sc/scNotificationBell';
import { ScNotificationBellButton } from './sc/scNotificationBellButton';
import { ScNotificationBellOutline } from './sc/scNotificationBellOutline';

const NotificationBell = React.forwardRef(({
  notificationCount,
  hasUnseenNotification,
  bellContainerElForMobile,
  isNotificationListVisible,
  enterpriseTheme,
  onBellClick
}, ref) => {
  const isMobileView = useMobileView(breakpoints.tablet);
  const logNotificationCenterActions = useContext(ActionContext);

  useEffect(() => {
    logNotificationCenterActions({
      action: 'seen',
      target: 'notification-bell'
    });
  }, []);

  const bellFragment = (
    <>
      <ScNotificationBellButton
        ref={ref}
        type="button"
        aria-label={t('Notifications')}
        showRedDot={hasUnseenNotification}
        enterpriseTheme={enterpriseTheme}
        notificationCount={notificationCount}
        isNotificationListVisible={isNotificationListVisible}
        className={isNotificationListVisible && 'listVisible'}
        onClick={onBellClick}
      >
        <ScNotificationBellOutline
          notificationCount={notificationCount}
        >
          <div className='circle' />
          <ScNotificationBell
            enterpriseTheme={enterpriseTheme}
            notificationCount={notificationCount}
            hasUnseenNotification={hasUnseenNotification}
            isNotificationListVisible={isNotificationListVisible}
          />
        </ScNotificationBellOutline>
      </ScNotificationBellButton>
    </>
  );

  if (isMobileView) {
    return ReactDOM.createPortal(bellFragment, bellContainerElForMobile);
  }

  return bellFragment;
});

NotificationBell.propTypes = {
  notificationCount: number,
  bellContainerElForMobile: object,
  isNotificationListVisible: bool,
  hasUnseenNotification: bool,
  enterpriseTheme: bool,
  onBellClick: func
};

NotificationBell.defaultProps = {
  notificationCount: 0,
  bellContainerElForMobile: null,
  isNotificationListVisible: false,
  hasUnseenNotification: false,
  enterpriseTheme: false,
  onBellClick: f => f
};

export default NotificationBell;
