import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/summersale.scss';
import SummerSaleBg from '../assets/campaigns/2023/ss/bg.svg';
import { CampaignCountDown } from './utils/CampaignCountDown';

export const SummerSaleCampaignBanner = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="ss-container">
        <div className="campaign-content-item">
          <div className="summer-text">
            <h5>{t('SUMMER SALE')}</h5>
            <h4>
              {translationRenderer('SAVE [1[50%]]')({
                renderer1: str => (<span>{str}</span>)
              })}
            </h4>
          </div>
          {/* counter */}
          <CampaignCountDown />
          <div className="item button">
            <a
              className="locale"
              target="_blank"
              href={`/pricing/?utm_campaign=ss2023&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_term=${username}`}
            >
              {t('Save Now')}
            </a>
          </div>
          <div className="starfish-img" />
        </div>
      </div>
      <div className="ss-container--bg">
        <SummerSaleBg />
      </div>
    </>
  );
};

SummerSaleCampaignBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderSummerSaleCampaign = ({
  container, accountTypeName, utmSource, username
}) => {
  const root = createRoot(container);
  root.render(
    <SummerSaleCampaignBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
