export const ALL_PERMISSIONS = {
  VIEW_FOLDERS: 'VIEW_FOLDERS',
  VIEW_PRODUCT_HEADER: 'VIEW_PRODUCT_HEADER',
  VIEW_PRODUCT_FOOTER: 'VIEW_PRODUCT_FOOTER',
  CREATE_FORM: 'CREATE_FORM',
  CREATE_APP: 'CREATE_APP',
  CREATE_SHEET: 'CREATE_SHEET',
  CREATE_REPORT: 'CREATE_REPORT',
  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  CREATE_CONTACT: 'CREATE_CONTACT',
  CREATE_PAGE: 'CREATE_PAGE',
  FAVORITE_FORM: 'FAVORITE_FORM',
  VIEW_SUBMISSIONS: 'VIEW_SUBMISSIONS',
  VIEW_REPORTS: 'VIEW_REPORTS',
  DELETE_FORM: 'DELETE_FORM',
  ARCHIVE_FORM: 'ARCHIVE_FORM',
  DISABLE_FORM: 'DISABLE_FORM',
  VIEW_INBOX: 'VIEW_INBOX',
  CREATE_FLOW: 'CREATE_FLOW',
  VIEW_ASSIGNED_ITEMS: 'VIEW_ASSIGNED_ITEMS',
  FILL_FORM: 'FILL_FORM',
  EDIT_FORM: 'EDIT_FORM',
  CREATE_BOARD: 'CREATE_BOARD',
  CREATE_AGENT: 'CREATE_AGENT'
};

export const FORM_PERMISSION_LIST = {
  viewForm: true,
  editForm: true,
  formSettings: true,
  renameForm: true,
  cloneForm: true,
  disableForm: true,
  enableForm: false,
  archiveForm: true,
  unarchiveForm: false,
  deleteForm: true,
  submissions: true,
  inbox: true,
  formAnalytics: true,
  formEncryption: true,
  createReport: true,
  viewReports: true,
  createPDF: true,
  publish: true,
  shareTemplate: true,
  revision: true,
  assignForm: true,
  createApp: true,
  pdfSubmissions: false,
  viewApprovals: true,
  createPrefill: true,
  createApproval: true,
  createAgent: true,
  editAgent: true,
  conversations: true
};

export const TEAM_PERMISSIONS = {
  // Form Related Permissions
  CREATE_FORM: 'createForm',
  CREATE_FOLDER: 'createFolder',
  VIEW_FORM: 'viewForm',
  EDIT_FORM: 'editForm',
  FORM_SETTINGS: 'formSettings',
  RENAME_FORM: 'renameForm',
  CLONE_FORM: 'cloneForm',
  DISABLE_FORM: 'disableForm',
  ENABLE_FORM: 'enableForm',
  ARCHIVE_FORM: 'archiveForm',
  UNARCHIVE_FORM: 'unarchiveForm',
  DELETE_FORM: 'deleteForm',
  SUBMISSIONS: 'submissions',
  INBOX: 'inbox',
  FORM_ANALYTICS: 'formAnalytics',
  CREATE_REPORT: 'createReport',
  VIEW_REPORTS: 'viewReports',
  CREATE_PDF: 'createPDF',
  PUBLISH: 'publish',
  SHARE_TEMPLATE: 'shareTemplate',
  REVISION: 'revision',
  ASSIGN_FORM: 'assignForm',
  CREATE_APP: 'createApp',
  PDF_SUBMISSIONS: 'pdfSubmissions',
  VIEW_APPROVALS: 'viewApprovals',
  CREATE_PREFILL: 'createPrefill',

  // Team Related Permissions
  MORE_BUTTON: 'moreButton',
  MANAGE_TEAM: 'manageTeam',
  MOVE_TO: 'moveTo',

  // Sheet Related Permissions
  SHARE_TABLE: 'shareTable',
  RENAME_TABLE: 'renameTable',
  ARCHIVE_TABLE: 'archiveTable',
  DELETE_TABLE: 'deleteTable',

  // Report Related Permissions
  DELETE_REPORT: 'deleteReport',
  VIEW_REPORT: 'viewReport',
  EDIT_REPORT: 'editReport',
  PUBLISH_REPORT: 'publishReport',
  RENAME_REPORT: 'editReport',
  ARCHIVE_REPORT: 'editReport',
  CLONE_REPORT: 'editReport',
  FAVORITE_REPORT: 'viewReport',

  // Approval Related Permissions
  VIEW_APPROVAL: 'viewApproval',
  EDIT_APPROVAL: 'editApproval',
  CLONE_APPROVAL: 'cloneApproval',
  PUBLISH_APPROVAL: 'publishApproval',
  DELETE_APPROVAL: 'deleteApproval',
  ARCHIVE_APPROVAL: 'archiveApproval',
  RENAME_APPROVAL: 'renameApproval',
  CREATE_APPROVAL: 'createApproval',

  // App Related Permissions
  DELETE_APP: 'deleteApp',
  EDIT_APP: 'editApp',
  VIEW_APP: 'viewApp',
  RENAME_APP: 'renameApp',
  ARCHIVE_APP: 'archiveApp',
  CLONE_APP: 'cloneApp',
  SHARE_APP: 'shareApp',

  // Sign Document Related Permissions
  DELETE_DOCUMENT: 'deleteDocument',
  VIEW_DOCUMENT: 'viewDocument',
  EDIT_DOCUMENT: 'editDocument',
  CLONE_DOCUMENT: 'cloneDocument',
  RENAME_DOCUMENT: 'renameDocument',
  INVITE_TO_DOCUMENT: 'inviteToDocument',
  // CREATE_DOCUMENT: 'createDocument', // not used

  // Agent Related Permissions
  CREATE_AGENT: 'createAgent',
  DELETE_AGENT: 'deleteAgent',
  EDIT_AGENT: 'editAgent',
  VIEW_AGENT: 'viewAgent',
  RENAME_AGENT: 'renameAgent',
  CLONE_AGENT: 'cloneAgent',
  SHARE_AGENT: 'shareAgent',
  DISABLE_AGENT: 'disableAgent',
  ENABLE_AGENT: 'enableAgent',
  ARCHIVE_AGENT: 'archiveAgent',
  UNARCHIVE_AGENT: 'unarchiveAgent',
  CONVERSATIONS: 'conversations'
};

const FALSE_FORM_PERMISSION_LIST = Object.keys(FORM_PERMISSION_LIST).reduce((prev, curr) => ({ ...prev, [curr]: false }), {});

const getDataOnlyUserPermissions = () => {
  return [
    // ALL_PERMISSIONS.CREATE_FORM, /* do not open */
    // ALL_PERMISSIONS.ARCHIVE_FORM, /* not used, rendered in more menu */
    // ALL_PERMISSIONS.DELETE_FORM, /* not used, rendered in more menu */
    // ALL_PERMISSIONS.DISABLE_FORM, /* not used, rendered in more menu */
    // ALL_PERMISSIONS.FAVORITE_FORM, /* not used, rendered in more menu */
    ALL_PERMISSIONS.VIEW_FOLDERS,
    ALL_PERMISSIONS.VIEW_PRODUCT_HEADER,
    ALL_PERMISSIONS.VIEW_PRODUCT_FOOTER,
    // ALL_PERMISSIONS.CREATE_APP, /* do not open */
    // ALL_PERMISSIONS.CREATE_SHEET, /* do not open */
    // ALL_PERMISSIONS.CREATE_REPORT, /* do not open */
    // ALL_PERMISSIONS.CREATE_DOCUMENT, /* do not open */
    ALL_PERMISSIONS.VIEW_SUBMISSIONS,
    ALL_PERMISSIONS.VIEW_REPORTS,
    ALL_PERMISSIONS.VIEW_INBOX
  ];
};

const apiPermissionsMAP = {
  createForm: ALL_PERMISSIONS.CREATE_FORM,
  editForm: ALL_PERMISSIONS.EDIT_FORM,
  deleteForm: ALL_PERMISSIONS.DELETE_FORM,
  fillForm: ALL_PERMISSIONS.FILL_FORM,
  beAssignee: ALL_PERMISSIONS.VIEW_ASSIGNED_ITEMS
};

const appendAPIPermissions = (projectPermissions = [], userAPIPermissions = {}, isTeamRelated = false) => {
  let newPermissions = [...projectPermissions];
  Object.keys(userAPIPermissions).forEach(permissionName => {
    const isAllowed = userAPIPermissions[permissionName] === true;
    const projectPermissionName = apiPermissionsMAP[permissionName];
    if (!projectPermissionName) {
      return; // obviously we dont user that permission in listings
    }
    if (isAllowed && newPermissions.indexOf(projectPermissionName) === -1) {
      newPermissions.push(projectPermissionName);
    } else if (!isAllowed && newPermissions.indexOf(projectPermissionName) > -1) {
      newPermissions = newPermissions.filter(np => np !== projectPermissionName);
    }
  });
  return {
    permissions: newPermissions,
    teamPermissions: isTeamRelated ? userAPIPermissions : {},
    permissionForTeam: isTeamRelated
  };
};

export const getUserPermissions = (user, environment, apiPermissions = {}, isTeamRelated) => {
  // This user permission set will be populated from database according to users role and rights.
  // Current rrights are just static types.

  if (user.credentials?.isDataOnlyUser === '1') return appendAPIPermissions(getDataOnlyUserPermissions(), apiPermissions, isTeamRelated);

  const type = user.type || 'FORM_USER';
  const USER_RIGHTS = [
    'VIEW_FOLDERS',
    'VIEW_SUBMISSIONS',
    'VIEW_INBOX',
    'CREATE_FLOW',
    'CREATE_APP',
    'CREATE_SHEET',
    'CREATE_REPORT',
    'CREATE_DOCUMENT',
    'VIEW_ASSIGNED_ITEMS',
    'FILL_FORM',
    'CREATE_BOARD',
    'CREATE_AGENT'
  ];

  if (environment !== 'ENTERPRISE') {
    USER_RIGHTS.push(
      ...['VIEW_PRODUCT_HEADER',
        'CREATE_FORM',
        'FAVORITE_FORM',
        'VIEW_REPORTS',
        'DELETE_FORM',
        'ARCHIVE_FORM',
        'DISABLE_FORM',
        'VIEW_PRODUCT_FOOTER',
        'CREATE_CONTACT',
        'CREATE_PAGE'
      ]
    );
  }

  const FORM_USER_RIGHTS = [
    'VIEW_INBOX',
    'VIEW_FORM',
    'VIEW_ASSIGNED_ITEMS',
    'FILL_FORM'
  ];

  if (environment !== 'ENTERPRISE') {
    FORM_USER_RIGHTS.push('CREATE_FORM', 'VIEW_PRODUCT_FOOTER', 'VIEW_PRODUCT_FOOTER', 'CREATE_FLOW', 'CREATE_APP', 'CREATE_SHEET', 'CREATE_BOARD', 'CREATE_AGENT');
  }

  const roleMap = {
    FORM_USER: FORM_USER_RIGHTS,
    USER: USER_RIGHTS
  };
  return appendAPIPermissions(roleMap[type], apiPermissions, isTeamRelated);
};

export const getPermissionsByAccessType = accessType => {
  const readOnly = {
    inbox: true,
    viewForm: true,
    submissions: true,
    createReport: true,
    viewReports: false,
    formAnalytics: true,
    viewApprovals: true
  };

  const writeOnly = {
    viewForm: true,
    formSettings: true,
    renameForm: true,
    cloneForm: true,
    publish: true,
    assignForm: true,
    formAnalytics: true,
    createPrefill: true,
    editForm: true,
    viewApprovals: true
  };

  switch (accessType) {
    case 'none':
      return {};
    case 'full':
      return { ...FALSE_FORM_PERMISSION_LIST, ...writeOnly, ...readOnly };
    case 'writeOnly':
      return { ...FALSE_FORM_PERMISSION_LIST, ...writeOnly };
    case 'readOnly':
    default:
      return { ...FALSE_FORM_PERMISSION_LIST, ...readOnly };
  }
};
