import React from 'react';
import {
  shape,
  func,
  string,
  number
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { hipaaSteps, hipaaFirstStep } from '../constants';
import { ScButtonNv } from './sc/newNotification/scButtonNv';

const ConfirmButton = ({
  notification,
  onConfirm,
  hipaaStep,
  onHipaaStepChange
}) => {
  const { confirm: { text } } = notification;

  const handleClick = notifcn => {
    if (hipaaStep && hipaaStep === hipaaSteps.firstStep) {
      onHipaaStepChange({ firstStepAnswer: hipaaFirstStep.yes });
      onConfirm(notifcn, hipaaStep);
      return;
    }

    onConfirm(notifcn, hipaaStep);
  };

  return (
    <ScButtonNv
      marginRight="4px"
      lightView={notification.confirm.lightView}
      onClick={() => handleClick(notification)}
    >
      {t(text)}
    </ScButtonNv>
  );
};

ConfirmButton.propTypes = {
  notification: shape({
    text: string,
    confirm: shape({ text: string })
  }),
  hipaaStep: number,
  onConfirm: func,
  onHipaaStepChange: func
};

ConfirmButton.defaultProps = {
  notification: {
    text: '',
    confirm: { text: '' }
  },
  hipaaStep: false,
  onConfirm: f => f,
  onHipaaStepChange: f => f
};

export default ConfirmButton;
