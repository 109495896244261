import '@jotforminc/router-bridge/init';
import '@jotforminc/tracking/init';
import Tracking from '@jotforminc/tracking';
import './polyfills/arrayFrom';

import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { featureFlag } from '@jotforminc/feature-flag';

import store from './store/store';

import App from './App';
import { ConfirmationProvider } from './contexts/confirmation';
import appendAppPicker from './components/Header/AppPickerHelper';
import './utils/Polyfill';
import { getFeatureFlagList } from './feature-flags/utils';

featureFlag.init(getFeatureFlagList());

// Builder modules needs following exports, unfortunately if we use them as external IE11 fails
window.React = React;
window.ReactDOM = ReactDOM;
// Render AppPicker flow
try {
  if (window.PREVENT_APP_PICKER !== '1') {
    appendAppPicker();
  }
} catch (e) {
  console.log('AppPicker Flow failed', e);
  console.error(e);
}

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    if (!window.errorTracking) return;
    if (error instanceof Error) {
      Tracking.captureException(error, info);
    } else {
      Tracking.captureException(new Error(error), info);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return children;
  }
}
// Render app itself
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ReduxProvider store={store}>
    <ConfirmationProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
      <ToastContainer
        hideProgressBar={true}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        icon={false}
        enableMultiContainer
      />
    </ConfirmationProvider>
  </ReduxProvider>);
