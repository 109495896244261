/* eslint-disable max-len */
import styled from 'styled-components';

const svgDown = "data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.7 0h7.6a.6.6 0 01.48.96l-3.8 5.067a.6.6 0 01-.96 0L.22.96A.6.6 0 01.7 0z' fill='%23282F42' fill-rule='evenodd'/%3E%3C/svg%3E";

export const ScGroupTitle = styled.button`
  background: none;
  border: none;
  max-width: 50vw;
  font-weight: bold;
  padding: 9px 0 10px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  :focus {
    border: 0;
    outline: 0;
  }
`;

export const ScGroupTitleIcon = styled.div`
  transform: translateY(10px);
  display: inline-flex;
`;

export const ScGroupTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  place-items: center;
`;

export const ScGroupTitleContainer = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const ScCaretDown = styled.div`
  width: 15px;
  height: 10px;
  margin-left: 4px;
  display: inline-block;
  background: url("${svgDown}") no-repeat;
  background-position: center;
  transition: .3s;
  ${p => (p.isExpanded ? `
    transform: rotate(0deg);
  ` : `
    transform: rotate(180deg);
  `)};

  html[dir="rtl"] & {
    margin-left: 0;
    margin-right: 4px;
  }

`;

export const ScGroupWrapper = styled.div`
  &:first-of-type {
    margin-top: -10px;
  }

  @media (max-width: 768px) {
    margin: 20px !important;

    &:first-of-type {
      margin-top: 10px !important;
    }
  }
`;

export const ScDescriptionItem = styled.div`
  :before {
    content: '●';
    margin-right: 5px;
    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 5px;
    }
  }

  width: 100%;
  max-width: 348px;
  margin: 0 auto 8px;
  font-size: 14px;
  text-align: left;
  display: flex;
  html[dir="rtl"] & {
    text-align: right;
  }

  span {
    flex: 1;
  }
`;

export const ScModalDescriptionPart = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: #8D8FA8;
  margin-bottom: 15px;
`;
