/* eslint-disable max-statements */
import React from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import classNames from 'classnames';
import { safeJSONParse, handleCustomNavigation, getPlatformEnv } from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { IconShapesFilled } from '@jotforminc/svg-icons';
import { StorageHelper } from '@jotforminc/storage-helper';
import translate from './translate';

import {
  FOLDER_TYPES,
  LISTING_TYPES,
  getDefaultFolders,
  COOKIE_KEYS,
  ASSET_TYPE_MAP,
  getFolderContextMenuItems,
  getSortingOptions,
  ASSETS_FOLDER_LISTINGS,
  ALL_ASSETS_ID,
  LISTING_TYPES_REVERT,
  MIX_FILTER_ENABLED_LISTING_TYPES,
  MOVE_TO_TEAM_ENABLED_RESOURCES,
  ASSET_FILTER_OPTIONS_WITHOUT_ALL,
  MOVE_TO_TEAM_DISABLED_RESOURCES,
  STATUS_FOLDERS
} from '../constants';
import TeamMembersCountButton from '../components/TeamMembersCountButton';
import { TEAM_PERMISSIONS } from './permissions';

export const setDate = (language, date) => {
  const options = {
    year: 'numeric',
    day: 'numeric',
    month: 'short'
  };

  return new Intl.DateTimeFormat(language, options).format(date);
};

export const parseDate = date => {
  if (!date || date === null || date[0] === '0') {
    return null;
  }

  if (typeof date !== 'string') {
    return new Date(date);
  }

  return new Date(date.replace(/-/g, '/'));
};

export const prettyDate = (passedDate = new Date(), language) => {
  const date = typeof passedDate === 'string' ? parseDate(passedDate) : passedDate;
  // eslint-disable-next-line
  if (!date || isNaN(date)) return '';

  return setDate(language || 'en-US', date);
};

export const saveURLAsFile = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const saveBlobAsFile = (blob, fileName) => {
  // For ie and edge, use native msSaveOrOpenBlob method instead of dummy anchor and click
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else if (window.URL.createObjectURL) {
    const url = window.URL.createObjectURL(blob);
    saveURLAsFile(url, fileName);
  }
};

export const isPlural = c => c > 1;

export const getTranslationText = (count = '', text) => {
  return `${translate(`{count} ${text}${isPlural(count) || parseInt(count, 10) === 0 ? 's' : ''}`)}`.replace('{count}', count);
};

export const getCookie = name => {
  return decodeURIComponent(document.cookie.replace(
    // eslint-disable-next-line no-useless-escape
    new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1'
  )) || null;
};

export const setLocalStorageWithExpiry = (key, value, ttl = 0) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: ttl === 0 ? undefined : now.getTime() + ttl
  };
  window?.localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = key => {
  try {
    const itemStr = window?.localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      window?.localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch {
    return null;
  }
};

export const treatAsUTC = date => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const daysBetween = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.ceil((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
};

export const getNameFromListingType = type => {
  const string = type.replace('LISTING@', '').toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const redirectToLoginPage = rp => {
  const nextHref = `/login?rp=${encodeURIComponent(rp || window.location)}`;
  handleCustomNavigation(nextHref, '_self', true);
};

export const redirectToLogoutPage = () => {
  const nextHref = '/logout.php';
  handleCustomNavigation(nextHref, '_self', true);
};

export const canAccessMyAgentsPage = credentials => {
  if (isEnterprise()) return !!window.AI_AGENT_ENABLED_ENTERPRISE;

  const userAccountType = credentials?.accountType || credentials?.account_type;
  const accountType = userAccountType && isObject(userAccountType) ? userAccountType.name : userAccountType;

  return accountType === 'ADMIN' || !!credentials?.allowAIAgentFormFiller || credentials?.aiAgentBetaUser === '1' || credentials?.aiAgentBetaUser === true;
};

export const isMixTestEnabled = () => {
  const { host, search } = window.location;
  const MIX_LISTING_TEST_SERVERS = [
    'demo.jotform.com',
    'eeproduct2.jotform.com',
    'enterpriseqa.jotform.com',
    'enterpriseqahipaa.jotform.com',
    'enterpriseqamanual.jotform.com'
  ];

  const isDevServer = host.indexOf('jotform.biz') > -1 || host.indexOf('jotform.pro') > -1;
  return search.includes('enableMix') && (isDevServer || !isEnterprise() || MIX_LISTING_TEST_SERVERS.indexOf(host) > -1);
};

export const isV2EnabledForAllPages = ({
  currentPage, folderType, selectedFolder, teamFilter
}) => {
  const v2EnabledPages = [
    LISTING_TYPES.FORM,
    LISTING_TYPES.SHEET,
    LISTING_TYPES.REPORT,
    LISTING_TYPES.PORTAL,
    LISTING_TYPES.TASK,
    LISTING_TYPES.DOCUMENT
  ];
  return currentPage === LISTING_TYPES.AGENT || (
    window.location.search.includes('forceV2') && v2EnabledPages.indexOf(currentPage) > -1 && (folderType === FOLDER_TYPES.TEAM || (teamFilter && STATUS_FOLDERS.indexOf(selectedFolder) > -1))
  );
};

export const isV2EndpointEnabled = ({
  currentPage, folderType, selectedFolder, teamFilter
}) => {
  return [FOLDER_TYPES.STATIC, FOLDER_TYPES.TEAM, FOLDER_TYPES.ALL_TEAM_RESOURCES].includes(folderType) && (
    currentPage === LISTING_TYPES.MIX || currentPage === LISTING_TYPES.TEAM_PAGE || isV2EnabledForAllPages({
      currentPage, folderType, selectedFolder, teamFilter
    })
  );
};

// eslint-disable-next-line no-unused-vars
export const isAssetFoldersSupported = userCredentials => {
  return window.location.search.includes('withAssetFolders'); // todo :: enable to assetFolderSupportBetaUser || userCredentials?.assetFolderSupportBetaUser === '1';
};

export const getDefaultAssetFilter = ({ currentPage, userCredentials }) => {
  const updatedAssets = StorageHelper.getLocalStorageItem({
    key: `jfLastSelectedAssetFilter${currentPage}${window.teamID || ''}`
  });
  const defaultAssetFilter = currentPage === LISTING_TYPES.TEAM_PAGE ? LISTING_TYPES.MIX : currentPage;

  let options = ASSET_FILTER_OPTIONS_WITHOUT_ALL;
  if (userCredentials) {
    options = options.filter(opt => !opt.checkVisibility || opt.checkVisibility({ user: userCredentials, canAccessMyAgentsPage: canAccessMyAgentsPage(userCredentials) }));
  } else {
    options = options.filter(opt => !opt.isDefaultlyHidden);
  }
  const filteredOptions = options.filter(option => updatedAssets?.includes(option.value));
  return defaultAssetFilter === LISTING_TYPES.MIX ? (filteredOptions.length > 0 ? filteredOptions : options).map(option => option.value) : [defaultAssetFilter];
};

// eslint-disable-next-line complexity
export const getPageFromUrl = () => {
  let paths = window.location.pathname.split('/').filter(part => !!part);
  // shitty solution for handling prs for now
  if (paths[0] === 'PR') {
    paths = paths.slice(2);
  }

  if (paths[0] === 'f') {
    paths = paths[1] === 'r' ? paths.slice(3) : paths.slice(1);
  }

  const isSignMobileApp = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;
  const languagePrefixes = ['en', 'es', 'fr', 'it', 'pt', 'de', 'tr', 'ca', 'nl', 'sv', 'hu', 'no', 'da', 'ro', 'fi', 'pl', 'ru', 'zh', 'sr', 'ua', 'ja', 'bg', 'ka', 'id', 'ar'];
  if (languagePrefixes.indexOf(paths[0]) > -1) { // Ignore language prefixes while determining the listing type
    paths.shift();
  }

  // for disallowed environment
  if (paths[0] === 'd') {
    paths = paths.slice(1);
  }

  if (paths[0] === 'platform' && paths[1] === getPlatformEnv()) {
    paths = paths.slice(2);
  }

  if (paths[0] === 'listings' && paths[1]) {
    return paths[1];
  }

  if (paths[0] === 'myreports') {
    return 'reports';
  }

  if (paths[0] === 'myapps' || paths[0] === 'mykiosks') {
    return 'apps';
  }

  if (paths[0] === 'mysheets' || paths[0] === 'mytables') {
    return 'sheets';
  }

  if (paths[0] === 'myboards') {
    return 'boards';
  }

  if (['mytasks', 'myapprovals', 'myworkflows'].indexOf(paths[0]) !== -1) {
    return 'tasks';
  }

  if (paths[0] === 'myteams') {
    if (paths[1]) {
      return 'teamPage';
    }
    return 'teams';
  }

  if (paths[0] === 'mycontacts') {
    return 'contacts';
  }

  if (paths[0] === 'mypages') {
    return 'pages';
  }

  // Just as a reminder
  if (paths[0] === 'forms' || (paths[0] === 'myforms' && window.canUseListings === true)) {
    return 'forms';
  }

  if (isSignMobileApp || paths[0] === 'mydocuments' || paths[0] === 'mysigndocuments') {
    return 'documents';
  }

  if (paths[0] === 'myagents') {
    return 'agents';
  }

  if (paths[0] === 'myworkspace') {
    return 'mix';
  }

  return 'assignedForms';
};

export const constructEditReportData = ({
  title = '',
  type = '',
  password,
  fields = [],
  isProtected = false,
  settings = {}
}) => {
  let reportSettings = safeJSONParse(settings);
  while (typeof reportSettings === 'string') {
    reportSettings = safeJSONParse(reportSettings);
  }
  return {
    reportName: title,
    reportType: type,
    reportPassword: password,
    fields: (fields && typeof fields === 'string') ? fields.split(',') : [],
    isProtected: password === undefined ? isProtected : !!password,
    reportSettings
  };
};

export const handleEditReport = ({
  type, digest, userCredentials, title, password, fields, isProtected, settings, updateReportWizard, id, formID
}) => {
  const _digest = digest;
  if (type !== 'reports' && type !== 'VISUAL') {
    let reportData;
    if (type === 'digest') {
      if (_digest.subject !== title) {
        _digest.subject = title;
      }
      reportData = { digest: _digest, user: userCredentials };
    } else {
      reportData = constructEditReportData({
        title,
        type,
        password,
        fields: fields || '',
        isProtected,
        settings
      });
    }
    updateReportWizard(type, formID, id, reportData);
  } else {
    updateReportWizard(type, formID, id);
  }
};

export const handleOpenSignBuilder = ({ moveStatus = {}, formID }) => (postfix = '') => {
  if (moveStatus.status) return;
  handleCustomNavigation(`/sign/${formID}${postfix}`, '_self');
};

export const getDefaultSelectedFolderId = (currentPage, folders) => {
  if (currentPage === LISTING_TYPES.FORM) {
    return ALL_ASSETS_ID[LISTING_TYPES.FORM];
  }
  return folders.find(f => f.isDefault).id;
};

export const normalizeFolders = (folders = []) => {
  return folders.map(folder => ({
    id: folder.id || folder.folder_id,
    text: folder.name || folder.folder_name,
    className: '',
    items: folder.forms || [],
    hasIcon: true,
    folderType: FOLDER_TYPES.ASSET_FOLDER,
    canDragItems: true,
    isFocussable: true,
    ...folder,
    subfolders: normalizeFolders(folder.subfolders)
  }));
};

export const normalizeTeamSubfolders = (folders = [], teamPermissions = {}) => {
  const { moveTo = false, manageTeam = false } = teamPermissions || {};
  return folders.map(folder => ({
    id: folder.id || folder.folder_id,
    text: folder.name || folder.folder_name,
    className: '',
    items: folder.forms || [],
    hasIcon: true,
    folderType: FOLDER_TYPES.TEAM_FOLDER,
    isDroppable: !!moveTo,
    canReorderSubfolders: !!manageTeam,
    canDragItems: teamPermissions && teamPermissions[TEAM_PERMISSIONS.CREATE_FOLDER],
    canDrag: false,
    ...folder,
    subfolders: normalizeTeamSubfolders(folder.subfolders, teamPermissions)
  }));
};

export const getTeamFolderContextMenuItems = (currentPage, isAssetsFolderActive) => getFolderContextMenuItems(isAssetsFolderActive)[LISTING_TYPES.TEAM_PAGE]
  .filter(item => !item.checkListingType || item.visibleListings?.indexOf(currentPage) > -1);

export const getNormalizedTeamProps = (newTeamProperties, currentPage, isAssetsFolderActive) => {
  const inTeamPage = currentPage === LISTING_TYPES.TEAM_PAGE;
  return {
    text: inTeamPage ? 'ALL' : newTeamProperties.name,
    menuItems: getTeamFolderContextMenuItems(currentPage, isAssetsFolderActive),
    customIconProps: {
      iconBgColor: newTeamProperties.teamAvatarIconBgColor,
      url: newTeamProperties.teamAvatarURL,
      icon: newTeamProperties.teamAvatarIcon,
      emojiId: newTeamProperties.teamAvatarEmojiId,
      emojiSize: 24,
      iconColor: newTeamProperties.teamAvatarIconColor,
      iconSvgRef: newTeamProperties.teamAvatarIconSvgRef,
      FolderIconComponent: inTeamPage ? IconShapesFilled : null
    }
  };
};

export const normalizeTeam = ({
  team,
  hasContextMenu = false,
  currentPage,
  isAssetsFolderActive
}) => {
  const inTeamPage = currentPage === LISTING_TYPES.TEAM_PAGE;
  const normalizedTeamProps = getNormalizedTeamProps(team, currentPage, isAssetsFolderActive);
  const { manageTeam = false, moveTo = false } = team?.permissions || {};
  return {
    ...team,
    ...(!inTeamPage && {
      subText: <TeamMembersCountButton
        teamID={team.id}
        memberCount={team.memberCount}
        isAdmin={manageTeam}
        disabled={!team?.permissions}
      />
    }),
    className: classNames({ isTitleFolder: inTeamPage }),
    hasIcon: true,
    folderType: inTeamPage ? FOLDER_TYPES.ALL_TEAM_RESOURCES : FOLDER_TYPES.TEAM,
    hasContextMenu: hasContextMenu && manageTeam,
    subfolders: normalizeTeamSubfolders(team.subfolders || [], team?.permissions),
    canDrag: !inTeamPage,
    isTeamDroppable: !inTeamPage,
    canDragItems: team?.permissions && team.permissions[TEAM_PERMISSIONS.CREATE_FOLDER],
    isDroppable: !!moveTo,
    isFocussable: true,
    ...normalizedTeamProps
  };
};

export const normalizeTeams = ({
  teams = [], hasContextMenu = false, currentPage, isAssetsFolderActive
}) => {
  return teams.map(team => normalizeTeam({
    team, hasContextMenu, currentPage, isAssetsFolderActive
  }));
};

export const makeFolderGroup = (id, subfolders = []) => ({
  id,
  subfolders
});

/*
  FIXES: #12841173
  Some children folders were missing because filtering was based on parent folders only. Now we ensure all folders, including their children, are correctly included in the nested structure.
*/
const getFolderPaths = (foldersParam = []) => {
  const folderMap = {};
  foldersParam.forEach(folder => {
    if (folder?.id) {
      folderMap[folder.id] = folder;
    }
  });

  return foldersParam.reduce((result, folder) => {
    const pathIds = folder.path.split(',');
    let currentLevel = result;
    pathIds.forEach(id => {
      const currentFolder = folderMap[id];
      if (currentFolder) {
        const path = id;
        currentLevel = currentLevel?.[path] ?? (set(currentLevel, path, {}), get(currentLevel, path));
      }
    });
    return result;
  }, {});
};

const createFolderStructure = (folders = {}, foldersParam = [], normalizedFormsParam = []) => {
  const newStructure = {};
  const keys = Object.keys(folders);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (Object.keys(folders[key]) !== 0) {
      const subfolders = createFolderStructure(folders[key], foldersParam, normalizedFormsParam);
      newStructure[key] = {
        subfolders: Object.keys(subfolders).map(subkey => {
          // return { [subkey]: subfolders[subkey] };
          return { id: subkey, ...subfolders[subkey], ...(foldersParam.find(({ id }) => id === subkey)) };
        }),
        ...(foldersParam.find(({ id }) => id === key)),
        forms: normalizedFormsParam.filter(({ folders: formFolders }) => formFolders?.includes?.(key)),
        hasContextMenu: false,
        canDragItems: false,
        canDrag: false,
        isShared: true
      };
    } else {
      newStructure[key] = { subfolders: [] };
    }
  }
  return newStructure;
};

const createSharedFolderStructure = (sharer = '', normalizedFormsParam = [], basicStructure = {}, sharerFullName = '') => {
  return {
    id: sharer,
    name: sharerFullName || sharer,
    folderType: FOLDER_TYPES.USERNAME,
    forms: normalizedFormsParam.filter(({ owner, username }) => {
      if (!owner) {
        return username === sharer;
      }
      return owner === sharer;
    }),
    hasContextMenu: false,
    subfolders: Object.keys(basicStructure).reduce((acc, key) => {
      if (basicStructure[key].owner === sharer) {
        return [...acc, basicStructure[key]];
      }
      return acc;
    }, []),
    owner: sharer,
    canDragItems: false,
    canDrag: false,
    isShared: true,
    hasIcon: false,
    hasSharerIcon: true
  };
};

export const normalizeSharedFormFolders = ({ forms: formsParam = [], folders: foldersParam = [] }, parentAccountNames = []) => {
  const normalizedFormsParam = Array.isArray(formsParam) ? formsParam : [];
  const normalizedFoldersParam = Array.isArray(foldersParam) ? foldersParam : [];
  const usernames = normalizedFormsParam.length > 0 ? normalizedFormsParam.map(({ username }) => username) : normalizedFoldersParam.map(({ owner }) => owner);
  const sharers = usernames.filter((value, index, self) => self.indexOf(value) === index).filter(username => parentAccountNames.indexOf(username) > -1);
  const folderPaths = getFolderPaths(foldersParam);
  const basicStructure = createFolderStructure(folderPaths, foldersParam, normalizedFormsParam);
  // Will do a trick to show the users who shared folders
  const structure = sharers.map(sharer => createSharedFolderStructure(sharer, normalizedFormsParam, basicStructure));
  return normalizeFolders(structure);
};

export const normalizeSharedFoldersV2 = (sharedResources = {}) => {
  const structure = Object.keys(sharedResources).map(sharer => {
    const sharerFolders = sharedResources[sharer].folders || [];
    const folderPaths = getFolderPaths(sharerFolders);
    const basicStructure = createFolderStructure(folderPaths, sharerFolders);
    return createSharedFolderStructure(sharer, [], basicStructure, sharedResources[sharer]?.name);
  });
  return normalizeFolders(structure);
};

export const flattenFolders = (folders = [], asMap = false, folderMap = new Map()) => {
  folders.forEach(folder => {
    folderMap.set(folder.id, folder);
    if (folder.subfolders) {
      flattenFolders(folder.subfolders, asMap, folderMap);
    }
  });

  return asMap ? folderMap : [...folderMap.values()];
};

export const getLastSelectedFolder = currentPage => {
  const lastSelectedFolder = getCookie(COOKIE_KEYS.LAST_FOLDER(currentPage));
  return lastSelectedFolder || null;
};

export const constructFolderListByLayout = (folderLayout = new Map(), folderMap = new Map()) => {
  const idList = [...folderLayout.keys()];
  return idList.reduce((prev, folderID) => {
    const folder = folderMap.get(folderID);
    if (!folder) {
      return prev;
    }

    return [
      ...prev,
      { ...folder, subfolders: constructFolderListByLayout(folderLayout.get(folderID), folderMap) }
    ];
  }, []);
};

export const constructFolderLayoutIDs = (folderLayout = new Map()) => {
  const idList = [...folderLayout.keys()];
  return idList.reduce((prev, id) => {
    const subfolder = folderLayout.get(id);
    if (!subfolder) return prev;
    return [
      ...prev,
      id,
      ...constructFolderLayoutIDs(subfolder)
    ];
  }, []);
};

export const constructFolderLayoutMap = (folderLayout = {}) => {
  const tmpMap = new Map();
  const folderIDList = Object.keys(folderLayout);
  folderIDList.forEach(folderID => {
    const { nodes = {} } = folderLayout[folderID] || {};
    tmpMap.set(folderID, constructFolderLayoutMap(nodes));
  });
  return tmpMap;
};

export const constructFolderLayoutObject = (folderLayout = new Map()) => [...folderLayout.keys()].reduce((prev, curr) => ({
  ...prev,
  [curr]: {
    nodes: constructFolderLayoutObject(folderLayout.get(curr))
  }
}), {});

export const isFolderShared = (folders, folderID, username) => {
  const sharedFolders = folders.filter(f => f.folderType === FOLDER_TYPES.USERNAME);
  const flattenedFolders = flattenFolders(sharedFolders);
  const sharedFolder = flattenedFolders.find(({ id }) => folderID === id);
  return sharedFolder && sharedFolder.owner !== username;
};

export const getFolderByID = (folders, folderID) => {
  const flattenedFolders = flattenFolders(folders);
  const folder = flattenedFolders.find(({ id }) => folderID === id);
  return folder;
};

export const childrenToSubfolders = items => items.map(item => ({ ...item, subfolders: Array.isArray(item.children) && item.children.length ? childrenToSubfolders(item.children) : [] }));
export const subfoldersToChildren = items => items.map(item => ({ ...item, children: Array.isArray(item.subfolders) && item.subfolders.length ? subfoldersToChildren(item.subfolders) : [] }));

export const getPortalRoot = () => {
  if (document.getElementById('listing-portal-root')) {
    return document.getElementById('listing-portal-root');
  }
  const portalRoot = document.createElement('div');
  portalRoot.id = 'listing-portal-root-temp';
  document.body.appendChild(portalRoot);
  return portalRoot;
};

export const isDark = (r, g, b) => {
  return (((r * 299) + (g * 587) + (b * 114)) / 1000) >= 128;
};

export const isHex = color => {
  return color?.substring(0, 1) === '#';
};

export const isRGB = color => {
  return color?.substring(0, 3) === 'rgb';
};

export const getRGBValuesFromHex = color => {
  if (!isHex(color)) { return false; }

  return {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16)
  };
};

export const getRGBValuesFromRGB = color => {
  if (!isRGB(color)) { return false; }

  const rgb = color.split('(')[1].split(')')[0].split(',');
  return { r: rgb[0], g: rgb[1], b: rgb[2] };
};

export const getReadableColorClass = color => {
  const colors = getRGBValuesFromHex(color) || getRGBValuesFromRGB(color) || { r: 255, g: 255, b: 255 };
  return isDark(colors.r, colors.g, colors.b) ? 'isDark' : 'isLight';
};

export const isListItemDraggable = (assetFilterType, isAssetsFolderActive) => {
  return [
    LISTING_TYPES.FORM,
    LISTING_TYPES.PORTAL,
    LISTING_TYPES.SHEET,
    LISTING_TYPES.TASK,
    LISTING_TYPES.DOCUMENT,
    ...MIX_FILTER_ENABLED_LISTING_TYPES,
    ...(isAssetsFolderActive ? ASSETS_FOLDER_LISTINGS : [])
  ].indexOf(assetFilterType) !== -1;
};

const getNormalizedProperties = assetProperties => (assetProperties || []).reduce((prev, { prop, value }) => ({ ...prev, [prop]: value }), {});

export const normalizeForm = form => {
  const {
    unread = 0,
    new: newCount,
    formProperties,
    assetProperties,
    ...remainingProperties
  } = form;
  const normalizedAssetProperties = getNormalizedProperties(assetProperties);
  const normalizedFormProperties = getNormalizedProperties(formProperties);
  const isDisabled = remainingProperties.status === 'DISABLED';

  const sanitizedNewCount = newCount !== undefined ? newCount : unread;
  return {
    ...remainingProperties,
    ...normalizedAssetProperties,
    ...normalizedFormProperties,
    isDisabled,
    new: sanitizedNewCount
  };
};

export const normalizeSharedForm = form => {
  const normalizedForm = normalizeForm(form);

  return { ...normalizedForm, favorited: normalizedForm?.favorited === '1' };
};

export const normalizeAsset = asset => {
  const {
    assetProperties,
    ...remainingProperties
  } = asset;

  const normalizedAssetProperties = getNormalizedProperties(assetProperties);

  return {
    ...remainingProperties,
    ...normalizedAssetProperties
  };
};

export const normalizeAgent = agent => {
  const defaultBgColors = ['#C8E4ED', '#EDC8C8', '#C3DCB3', '#C2CAFF', '#EEB8EA', '#FFD89F'];
  // TODO :: it is a temporary solution for missing agentBackground
  const defaultBgColorIndex = Math.floor(Math.random() * defaultBgColors.length);
  const defaultRandomBG = defaultBgColors[defaultBgColorIndex];

  return {
    ...agent,
    id: agent.uuid,
    isDisabled: agent.status === 'DISABLED',
    agentBgColor: agent.agentBackground || agent.agentBackgroundStart || agent.agentBackgroundEnd || defaultRandomBG
  };
};

export const getFolderDefaults = (currentPage, isTeamsActive, isTeamMember, isFormUser, isAssetsFolderActive) => {
  const defaultFolderList = getDefaultFolders(currentPage, isTeamsActive, isTeamMember, isFormUser, isAssetsFolderActive);
  const defaultSelectedFolder = getDefaultSelectedFolderId(currentPage, defaultFolderList);
  return { defaultFolderList, defaultSelectedFolder };
};

export const getAssetTypeFromListingType = listingType => {
  return Object.keys(ASSET_TYPE_MAP).find(assetType => ASSET_TYPE_MAP[assetType] === listingType);
};

export const getTeamAssetMoveExtraProps = (asset, assetFilterType) => {
  if (assetFilterType === LISTING_TYPES.REPORT || (assetFilterType === LISTING_TYPES.MIX && asset?.assetType === LISTING_TYPES_REVERT[LISTING_TYPES.REPORT])) {
    switch (asset.type) {
      case 'VISUAL':
        return { from: 'report' };
      case 'reports':
        return { from: 'presentation' };
      case 'digest':
        return { from: 'digest' };
      default:
        return { from: 'listing' };
    }
  }
  return {};
};

export const getIsMoveToTeamEnabledForProduct = selectedItemsListingType => {
  return MOVE_TO_TEAM_ENABLED_RESOURCES.includes(selectedItemsListingType) && !MOVE_TO_TEAM_DISABLED_RESOURCES.includes(selectedItemsListingType);
};

export const isValidSorting = (currentPage, assetFilterType, selectedFolder, optValue) => {
  const sortingOptions = getSortingOptions(currentPage, assetFilterType, selectedFolder) || [];

  return !!sortingOptions.find(sOpt => sOpt.value === optValue);
};

export const isProduction = () => {
  return window?.__jfrouter?.JOTFORM_ENV === 'PRODUCTION' || window?.JOTFORM_ENV === 'PRODUCTION';
};

export const getURLParam = key => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const guestChecker = (username = '') => {
  const [guestPrefix, guestID = ''] = username.split('_');
  return (guestPrefix === 'guest' && guestID.length === 16);
};

export const isFeatureEnabled = query => window.JOTFORM_ENV === 'DEVELOPMENT' || window.location.href.includes(`${query}=1`);

export const getAssetType = (assetFilterType, assetType) => (MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(assetFilterType) > -1 ? assetType : LISTING_TYPES_REVERT[assetFilterType]);

export const goToAssetSource = (resourceType, resourceID) => {
  let sourceURL = '';
  switch (resourceType) {
    case 'form':
      sourceURL = `/build/${resourceID}`;
      break;
    case 'sheet':
      sourceURL = `/tables/${resourceID}`;
      break;
    // case 'sign':
    //   sourceURL = `/build/${resourceID}`;
    //   break;
    case 'workflow':
      sourceURL = `/workflow/${resourceID}/build`;
      break;
    case 'portal':
      sourceURL = `/app/build/${resourceID}`;
      break;
    case 'report':
      sourceURL = `/reports/${resourceID}`;
      break;
    default:
      break;
  }
  handleCustomNavigation(sourceURL, '_blank', true);
};

export const mapAssetTypes = type => {
  switch (type) {
    case 'form':
      return 'Form';
    case 'sheet':
      return 'Table';
    // case 'sign':
    //   return 'Sign Document';
    case 'workflow':
      return window?.isWorkflowReleased ? 'Workflow' : 'Approval';
    case 'portal':
      return 'App';
    case 'report':
      return 'Report';
    default:
      break;
  }
};

export const areTheSameItems = ({
  item, assetID, assetType
}) => {
  return item?.id === assetID && (!assetType || !item?.assetType || item.assetType === assetType);
};

export const findItemByIDAndType = ({
  list, assetID, assetType
}) => {
  return list.find(listItem => areTheSameItems({ item: listItem, assetID, assetType }));
};

export const createListsFromSelectedItems = ({
  list = [], selectedItemList = []
}) => {
  return list.reduce((acc, item) => {
    if (findItemByIDAndType({ list: selectedItemList, assetID: item.id, assetType: item.assetType })) {
      return {
        ...acc,
        selectedItems: [...acc.selectedItems, item]
      };
    }
    return {
      ...acc,
      others: [...acc.others, item]
    };
  }, { selectedItems: [], others: [] });
};
