import { handleCustomNavigation } from '@jotforminc/utils';
import { layer } from '../../../utils';

const cloneApproval = (id, title, withForm, teamID) => {
  const headers = teamID ? { headers: { 'jf-team-id': teamID } } : {};
  return layer.post(`listings/workflow/${id}/clone?cloneTriggers=${withForm ? '1' : '0'}`, { title }, headers);
};

export const handleCloneApproval = ({
  id,
  approvalTitle,
  withForm,
  teamID
}) => {
  return cloneApproval(id, approvalTitle, withForm, teamID).then(res => {
    const {
      id: newID
    } = res;

    handleCustomNavigation(`/workflow/${newID}/build`, '_self');
  });
};
