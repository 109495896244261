import Tracking from '@jotforminc/tracking';
import {
  applyMiddleware, combineReducers, compose, createStore
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import uiReducer from './reducers/ui';
import listReducer from './reducers/list';
import userReducer from './reducers/user';
import folderReducer from './reducers/folder';
import featureReducer from './reducers/feature';
import teamReducer from './reducers/team';

import { rootSagaFlow } from '../sagas/root';
import { liteAppRootSagaFlow } from '../sagas/root/lite';

const createRootReducer = () => combineReducers({
  ui: uiReducer,
  user: userReducer,
  list: listReducer,
  folder: folderReducer,
  feature: featureReducer,
  team: teamReducer
});

export const createStoreWithSagas = () => {
  const rootReducer = createRootReducer({});
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      if (window.errorTracking === true) {
        Tracking.captureException(error, scope => {
          scope.setTag('section', 'saga');

          const sagaName = (sagaStack.match(/The above error occurred in task (.*?)( |\n)/) || [])[1];
          if (sagaName) scope.setTag('saga.name', sagaName);

          scope.addBreadcrumb({
            type: 'error',
            category: 'error',
            level: 'error',
            message: sagaStack
          });
        });
      }

      /* eslint-disable no-console */
      console.error(error);
      console.error(sagaStack);
      /* eslint-enable no-console */
    }
  });

  const middlewares = applyMiddleware(sagaMiddleware);

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = createStore(rootReducer, composeEnhancers(middlewares));

  const rootSaga = window.initialScreen === 'create-form' ? liteAppRootSagaFlow : rootSagaFlow;
  sagaMiddleware.run(rootSaga);
  return store;
};

export const defaultStore = createStoreWithSagas();

export default defaultStore;
