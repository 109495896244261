import { createRoot } from 'react-dom/client';
import { Wizard } from '@jotforminc/wizard-shared';
import React from 'react';
import SideBySideModal from '.';

const root = document.getElementById('create-asset-modal-container');

export const openSideBySideCreateModal = ({
  logAbTestAction, isSideBar, isMobileDevice, userFormCountLimit, isTeamAssetCreation, user,
  teamPermissions, userPermissions, accountType
}) => {
  const rootNode = createRoot(root);

  return new Promise(resolve => {
    const handleClose = () => {
      resolve({ closed: true });
      rootNode.unmount();
    };
    rootNode.render(<Wizard
      usePortal
      targetContainer="#create-asset-modal-container"
      Navigation={{ SideBySideModal }}
      DefaultView='SideBySideModal'
      logAbTestAction={logAbTestAction}
      initialVisibilityState={true}
      onHide={handleClose}
      isSideBar={isSideBar}
      userFormCountLimit={userFormCountLimit}
      isMobileDevice={isMobileDevice}
      isTeamAssetCreation={isTeamAssetCreation}
      user={user}
      teamPermissions={teamPermissions}
      userPermissions={userPermissions}
      accountType={accountType}
      wrapperProps={{ className: 'sideBySideWizard' }}
    />);
  });
};
