import { useEffect, useState } from 'react';
import { ABTestManager } from '@jotforminc/abtest-manager';
import { updateUserSettings } from '../../api';

const SupportAIAgent = ({
  user, isFormListing, isSupportAgentAllowed
}) => {
  const [abTestManager, setABTestManager] = useState(null);

  const initABTestManager = () => {
    return new ABTestManager({
      user,
      isTestEnabled: true,
      testName: 'aiSupportAgentTest',
      testVariantCode: '28802',
      controlVariantCode: '28792',
      cacheVariantCodeAtLocalStorage: true,
      customUserChecks: {
        isAllowedToSeeSupportAgent: isSupportAgentAllowed
      }
    });
  };

  const shouldSeeTheAgent = async () => {
    if (abTestManager) {
      const isTestVariant = await abTestManager.isTestVariant();
      return (isTestVariant || ['ADMIN', 'SUPPORT', 'HELPDESK'].includes(user.credentials?.account_type?.name || 'GUEST')) && isFormListing;
    }
    return false;
  };

  const initAgent = async () => {
    const shouldDisplayAgent = await shouldSeeTheAgent();
    if (shouldDisplayAgent) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-embedded-agent.js';
      script.async = true;
      script.onload = () => {
        if (window.AgentInitializer) {
          window.AgentInitializer.init({
            rootId: 'JotformAgent-0192f5c77d3a72bc953c3d6c2637de7a2cd5',
            formID: '0192f5c77d3a72bc953c3d6c2637de7a2cd5',
            queryParams: ['skipWelcome=1'],
            domain: 'https://www.jotform.com',
            isInitialOpen: false,
            isDraggable: false,
            background: 'linear-gradient(180deg, #C8CEED 0%, #C8CEED 100%)',
            buttonBackgroundColor: '#0a1551',
            buttonIconColor: '#fff',
            variant: 'aiSupportAgentTest',
            customizations: {
              greeting: 'Yes',
              greetingMessage: 'Hi! How can I help you?',
              pulse: 'Yes',
              position: 'right'
            }
          });
        }
      };
      document.body.appendChild(script);
      const logAbTestAction = abTestManager.registerABTestAction;
      if (await abTestManager.isTestVariant()) {
        logAbTestAction({
          action: 'seen',
          actor: window.user.username,
          target: 'supportAIAgent-myForms'
        });
        updateUserSettings({ aiSupportAgentEnabled: '1' });
      }
      return () => {
        document.body.removeChild(script);
      };
    }
  };

  useEffect(() => {
    if (isFormListing && isSupportAgentAllowed) {
      setABTestManager(initABTestManager());
    }
  }, [isFormListing, isSupportAgentAllowed]);

  useEffect(() => {
    if (abTestManager && isFormListing) {
      initAgent();
    }
  }, [abTestManager, isFormListing]);
};

export default SupportAIAgent;
