export const MODAL_BODY_ERROR_DESC = `The form you're moving is connected to multiple assets and has ownership issues.
If you move this form, all connected assets will be moved to the [1[{targetTeamName}]] team as well. `;
export const MODAL_BODY_DESC = 'All members of [1[{targetTeamName}]] team will gain access according to their permissions.';
export const MOVE = 'Move';
export const VIEW = 'View';
export const INFO_BOX_CONTENT = `There are connected asset whose [1[owner is not you.]] If you want to move them,
you must first [2[request ownership]] of those assets. If your admin confirms your request, those assets will be transferred.`;
export const ENCRYPTED = 'Encrypted';
export const CANNOT_MOVE_CONNECTED_ASSET = 'Unable to move the assets marked below because they’re owned by another user.';
export const CLOSE = 'Close';
export const MOVE_TO_TEAM_NAME = 'Move to [1[{teamName}]]';
export const MOVE_TO_MY_FORMS = 'Move to My Forms';
export const OWNERSHIP_TRANSFER_TEXT = 'Ownership will be transferred to you';
export const UNDO = 'Undo';
export const TRANSFER_OWNERSHIP = 'Transfer Ownership';
export const REQUEST_TRANSFER_OWNERSHIP = 'Request Transfer Ownership';
