import { createRoot } from 'react-dom/client';
import { Loading } from '@jotforminc/loading';
import React, { Suspense } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const ManageMembersModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_ManageMembersModal" */'./modal'));

export const openManageMembersModal = ({
  id,
  teamRoles,
  inviteOnly,
  isAdmin,
  rootSelector,
  user,
  returnLastTeamMembers,
  globalTeamSettings,
  teamName,
  isHIPAA
}) => {
  const selector = rootSelector || '#wizardPortal';
  const root = document.querySelector(selector);
  const rootNode = createRoot(root);

  return new Promise(resolve => {
    const handleClose = (error, teamMembers) => {
      const resolveValue = (error || returnLastTeamMembers) ? {
        ...error ? { error } : {},
        ...returnLastTeamMembers ? { teamMembers } : {}
      } : true;
      resolve(resolveValue);
      rootNode.unmount();
    };

    rootNode.render(
      <Suspense fallback={<Loading />}>
        <ManageMembersModal
          id={id}
          onClose={handleClose}
          teamRoles={teamRoles}
          inviteOnly={inviteOnly}
          isAdmin={isAdmin}
          user={user}
          globalTeamSettings={globalTeamSettings}
          teamName={teamName}
          isHIPAA={isHIPAA}
        />
      </Suspense>
    );
  });
};
