import React, { FunctionComponent } from 'react';
import { SVGIcon, EmojiRenderer } from '@jotforminc/icon-selector';
import { AvatarWrapper } from './StyledElements';

type ImageProps = {
  url?: string,
  icon?:string,
  emojiId?: string,
  emojiSize?: number | string,
  iconColor?: string,
  iconSvgRef?: string
  urlParams?: string
};

const Image: FunctionComponent<ImageProps> = ({
  url = '',
  urlParams = '',
  icon = '',
  emojiId = '',
  emojiSize = 16,
  iconColor = '',
  iconSvgRef = ''
}) => {
  if (url) return (<img src={`${url}${urlParams}`} alt="Team Avatar" data-testid="team-avatar-image" />);

  if (emojiId) return (<EmojiRenderer id={emojiId} size={emojiSize} />);

  const isIconSpriteLoaded = !!document.getElementById('team-avatar-icons');

  return isIconSpriteLoaded && iconSvgRef ? (
    <AvatarWrapper>
      <div className="jfIconSVG-wrapper xxxx">
        <svg
          fill={iconColor}
          viewBox="0 0 24 24"
          style={{ width: '100%', height: '100%' }}
        >
          <use xlinkHref={`#${iconSvgRef}`} href={`#${iconSvgRef}`} />
        </svg>
      </div>
    </AvatarWrapper>
  ) : (
    <AvatarWrapper>
      <SVGIcon
        url={icon}
        iconColor={iconColor}
      />
    </AvatarWrapper>
  );
};

export default Image;
