import React from 'react';
import { func, node } from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconXmark } from '@jotforminc/svg-icons';
import { ALL_TEXTS } from '../../constants';

export const DialogRenderer = ({ children }) => (<div className="jfBasicModal teams-modal-wrapper">{children}</div>);

DialogRenderer.propTypes = { children: node };
DialogRenderer.defaultProps = { children: null };

export const ContentRenderer = ({ children, ...props }) => (
  <div {...props} className="jfBasicModal-content-wrapper">
    <div className="jfBasicModal-content government-modal-v2 relative">
      {children}
    </div>
  </div>
);

ContentRenderer.propTypes = { children: node };
ContentRenderer.defaultProps = { children: null };

export const HeaderRenderer = ({ onCloseClick, ...props }) => (
  <button
    {...props}
    type="button"
    className="absolute cursor-pointer duration-300 border-0 z-9 w-8 h-8 right-3 top-3 radius-full
    bg-navy-75 hover:bg-navy-100 focus:bg-navy-75 flex items-center justify-center duration-300
    focus:outline-2 outline-navy-50 outline-opacity-50"
    aria-label={t(ALL_TEXTS.CLOSE_MODAL)}
    onClick={onCloseClick}
  >
    <IconXmark className="w-4 h-4 shrink-0 color-navy-500" />
  </button>
);

HeaderRenderer.propTypes = { onCloseClick: func };
HeaderRenderer.defaultProps = { onCloseClick: f => f };
