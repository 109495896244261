/* eslint-disable max-statements */
import React from 'react';
import {
  call, put, takeLatest, select, take, delay
} from 'redux-saga/effects';
import { t } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { handleCustomNavigation } from '@jotforminc/utils';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import {
  TEAM_ERRORS, isPrivacyFeatureEnabled, PRIVACY_OPTIONS, SERVER_ADMIN_DEFAULT_TEAM_SETTINGS, isServerTeamSettingsEnabled, checkUserCanInviteMembers
} from '@jotforminc/enterprise-components';
import {
  FOLDER_TYPES, LISTING_TYPES, DEFAULT_FOLDER_IDS, FEATURE_LIST, FOLDER_ITEM_ACTION_TYPES, MODAL_TYPES, FOLDER_DROP_POSITIONS,
  getTeamsSubfolderEnabledListings, ASSET_TYPE_MAP
} from '../../constants';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { SELECTORS } from '../../store/selectors';
import * as API from '../../api';
import { r } from '../../api/requestLayer';
import { openManageMembersModal } from '../../modals/ManageMembersModal/utils';
import { openMoveConfirmationModal } from '../../modals/MoveConfirmationModal/utils';
import { openCreateTeamModal } from '../../modals/CreateNewTeamModal/utils';
import {
  normalizeTeams, getDefaultSelectedFolderId, getAssetTypeFromListingType, getFolderByID, getTeamAssetMoveExtraProps, findItemByIDAndType
} from '../../utils';
import {
  moveToTeamToastCall, moveAssetToastCall, moveFromTeamToast, showError
} from '../../components/ListItem/toastForItemAction';
import { getUserPermissions, TEAM_PERMISSIONS } from '../../utils/permissions';
import { redirectToProductBuilder } from '../utils/navigationUtils';
import openAssetBeingEdited from '../../modals/AssetBeingEditedModal';
import { handleReportUpdateWizard } from '../listBased/report';
import { shouldShowJotformersTeamModal } from '../../utils/ExperienceUtils';
import { renderExperienceModals } from '../../campaigns';
import ConfirmationDialog from '../../utils/Confirmation';

function* initRoles() {
  try {
    const isGuestUser = yield select(SELECTORS.getIsGuestUser);
    if (isGuestUser) return [];

    const newTeamRoles = yield call(API.getTeamRoles);
    yield put(ACTION_CREATORS.updateTeamRoles(newTeamRoles));
    return newTeamRoles;
  } catch (err) {
    console.log(err);
  }
}

function* initServerTeamSettings() {
  try {
    const accountType = yield select(SELECTORS.getAccountType);
    const serverTeamSettings = accountType === 'ADMIN' ? SERVER_ADMIN_DEFAULT_TEAM_SETTINGS : yield call(API.getServerTeamSettings);
    yield put(ACTION_CREATORS.setServerTeamSettings(serverTeamSettings));
  } catch (err) {
    yield put(ACTION_CREATORS.setServerTeamSettings({}));
    console.log(err);
  }
}

function* initTeamNotifications() {
  try {
    const currentTeam = yield select(SELECTORS.getCurrentTeam);
    const teamPermissions = yield select(SELECTORS.getTeamPermissions);

    if (teamPermissions[TEAM_PERMISSIONS.MANAGE_TEAM] && currentTeam && currentTeam.privacy !== PRIVACY_OPTIONS.PRIVATE.type) {
      const teamPendingRequests = yield call(API.getTeamPendingRequests, currentTeam.id);

      if (teamPendingRequests && teamPendingRequests.length > 0) {
        yield put(ACTION_CREATORS.setTeamNotificationCount(teamPendingRequests.length));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRoles() {
  try {
    const teamRoles = yield select(SELECTORS.getTeamRoles);
    if (teamRoles.length > 0) return teamRoles;

    const newTeamRoles = yield call(initRoles);
    return newTeamRoles;
  } catch (err) {
    console.log(err);
  }
}

export function* getTeamsFolderSummary(folderInfo) {
  try {
    const isGuestUser = yield select(SELECTORS.getIsGuestUser);
    if (isGuestUser) return folderInfo;

    const currentPage = yield select(SELECTORS.getCurrentPage);
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const isTeamWorkspace = currentPage === LISTING_TYPES.TEAM_PAGE;
    const addSubfolders = getTeamsSubfolderEnabledListings(isAssetsFolderActive).indexOf(currentPage) > -1;

    if (!isTeamWorkspace) {
      yield put(ACTION_CREATORS.resetTeamPermissions(currentPage, isAssetsFolderActive));
    }
    let teams = yield call(API.getUserTeams, {
      addPermissions: true,
      addSubfolders,
      // do not need to fetch all teams for team workspace
      ...isTeamWorkspace ? {
        addPermissions: false,
        addSubfolders: false,
        addMembers: false,
        limit: 5,
        offset: 0
      } : {}
    });
    if (!isArray(teams)) {
      teams = [];
    }
    if (isTeamWorkspace) {
      const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
      const teamProperties = yield call(API.getTeam, { id: currentTeamID, addSubfolders });
      const teamPermissions = yield select(SELECTORS.getTeamPermissions);
      const userPermissions = yield call(API.fetchPermissionInfoUser, currentTeamID);
      yield put(ACTION_CREATORS.updateTeams([teamProperties, ...teams.filter(team => team.id !== teamProperties.id)]));
      yield put(ACTION_CREATORS.isTeamsFetched(true));
      const [teamFolder] = normalizeTeams({
        teams: [{ ...teamProperties, permissions: (Object.keys(teamPermissions).length === 0 ? userPermissions : teamPermissions) }], hasContextMenu: false, currentPage, isAssetsFolderActive
      });
      const seperatorIndex = folderInfo.folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR);
      const createFolderSeperatorIndex = folderInfo.folderList.findIndex(({ id }) => id === 'createFolderSeperator');
      return {
        ...folderInfo,
        folderList: [
          ...folderInfo.folderList.slice(0, 1),
          teamFolder,
          ...folderInfo.folderList.slice(teamPermissions[TEAM_PERMISSIONS.CREATE_FOLDER] ? createFolderSeperatorIndex : seperatorIndex)
        ]
      };
    }
    yield put(ACTION_CREATORS.updateTeams(teams));
    yield put(ACTION_CREATORS.isTeamsFetched(true));

    if (currentPage === LISTING_TYPES.TEAMS) {
      return folderInfo;
    }

    const createTeamButtonIndex = folderInfo.folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.CREATE_TEAM);
    const allTeamsSectionIndex = folderInfo.folderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_TITLE);

    if (isServerTeamSettingsEnabled() && teams.length === 0) {
      const { teamCreation } = yield select(SELECTORS.getServerTeamSettings) || {};
      if (!teamCreation) {
        yield take(ACTION_TYPES.SET_SERVER_TEAM_SETTINGS); // Wait for server team settings
      }
      const isTeamCreationButtonInvisible = yield select(SELECTORS.getIsTeamCreateButtonInvisible);

      if (isTeamCreationButtonInvisible) {
        const newList = folderInfo.folderList.filter(f => [DEFAULT_FOLDER_IDS.TEAMS_TITLE, DEFAULT_FOLDER_IDS.CREATE_TEAM, DEFAULT_FOLDER_IDS.TEAMS_SEPERATOR].indexOf(f.id) === -1);
        return {
          ...folderInfo,
          folderList: newList
        };
      }
    }
    const newFolderList = [
      ...folderInfo.folderList.slice(0, allTeamsSectionIndex + 1),
      ...normalizeTeams({
        teams, hasContextMenu: true, currentPage, isAssetsFolderActive
      }),
      ...folderInfo.folderList.slice(createTeamButtonIndex)
    ];

    return {
      ...folderInfo,
      folderList: newFolderList
    };
  } catch (e) {
    console.error(e);
    return { ...folderInfo };
  }
}

function* updateTeamFolders() {
  const currentPage = yield select(SELECTORS.getCurrentPage);

  if (currentPage !== LISTING_TYPES.TEAM_PAGE) {
    const currFolders = yield select(SELECTORS.getFolders);
    const folderInfo = { folderList: currFolders };
    const { folderList: newFolderList } = yield getTeamsFolderSummary(folderInfo);
    yield put(ACTION_CREATORS.setFolders(newFolderList));
  }
}

function showSignUpModal(callbackFunc) {
  window.setCustomFunction_signup = true;
  window.setCustomFunction_login = true;
  window.customFunction_signup = () => callbackFunc({ isNewSignUp: true });
  window.customFunction_login = () => callbackFunc({ isLogin: true });
  if (typeof window.showModal === 'function') {
    window.showModal('s1', null, null, null, null, {}, { welcomeText: 'Welcome' });
  }
}

export function* showCreateTeamModal({ redirectAfterCreation = false, source }) {
  try {
    const isTeamsFetched = yield select(SELECTORS.getIsTeamsFetched);
    const isGuestUser = yield select(SELECTORS.getIsGuestUser);

    if (!isEnterprise() && !isTeamsFetched && !isGuestUser) {
      yield take(ACTION_TYPES.IS_TEAMS_FETCHED);
    }
    const teamRoles = yield call(getRoles);
    const accountType = yield select(SELECTORS.getAccountType) || '';
    const username = yield select(SELECTORS.getUserName) || '';
    const serverTeamSettings = yield select(SELECTORS.getServerTeamSettings) || {};
    const userTeams = yield select(SELECTORS.getTeams) || [];

    if (isEnterprise() && userTeams?.length >= 250) {
      ConfirmationDialog({
        title: t('Team Creation Limit Exceeded'),
        content: (
          <>
            <div style={{ marginBottom: '8px', textAlign: 'center' }}>
              {t('You have reached the maximum limit of 250 teams.')}
            </div>
            <div style={{ textAlign: 'center' }}>
              {t('To create a new team, please leave an existing team to ensure optimal performance.')}
            </div>
          </>

        ),
        confirmButtonColor: 'primary',
        confirmText: 'OK',
        backText: 'Back',
        dialogType: 'error'
      }).then(() => {}).catch(() => {});
      return;
    }

    const { teamId: id } = yield call(openCreateTeamModal, {
      teamRoles,
      rootSelector: '#create-team-modal-container',
      user: { accountType, username },
      teamCount: userTeams.length,
      source,
      redirectAfterCreation,
      globalTeamSettings: serverTeamSettings,
      showSignUpModal
    });
    yield call(updateTeamFolders);
    const userCredentials = yield select(SELECTORS.getUserCredentials);
    yield put(ACTION_CREATORS.setSelectedFolder({ folder: id, folderType: FOLDER_TYPES.TEAM, userCredentials }));
  } catch (error) {
    // modal closed
  }
}

export function* switchToDefaultFolder(useFirstTeam = false) {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const folderList = yield select(SELECTORS.getFolders);
  const defaultFolder = getDefaultSelectedFolderId(currentPage, folderList);
  const userCredentials = yield select(SELECTORS.getUserCredentials);
  const newSelectedFolder = yield select(SELECTORS.getInitialFolderID(folderList, defaultFolder, useFirstTeam));
  const { folderType = FOLDER_TYPES.STATIC } = folderList.find(folder => folder.id === newSelectedFolder) || {};
  yield put(ACTION_CREATORS.setSelectedFolder({ folder: newSelectedFolder, folderType, userCredentials }));
}

function* handleTeamError({ error, teamID, reloadOnDeletion }) {
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const id = teamID || currentTeamID;
  if (!id) { // not team related
    return;
  }

  switch (error) {
    case TEAM_ERRORS.INSUFFICIENT_ROLE: {
      // Re-fetch user's permissions in the team and act accordingly
      if (id === currentTeamID) {
        const user = yield select(SELECTORS.getUser);
        const newPermissions = yield call(API.fetchPermissionInfoUser);
        yield put(ACTION_CREATORS.setUser({ ...user, ...(getUserPermissions(user, window.JOTFORM_ENV, newPermissions, true)) }));
      }
      break;
    }
    case TEAM_ERRORS.ACCESS_NOT_ALLOWED: {
      const currentPage = yield select(SELECTORS.getCurrentPage);
      if (currentPage === LISTING_TYPES.TEAM_PAGE) {
        if (reloadOnDeletion) {
          window.location.reload();
        } else {
          yield put(ACTION_CREATORS.showNoAccessToTeamView());
        }
      } else {
        yield put(ACTION_CREATORS.deleteTeamSuccess(id));
        yield call(switchToDefaultFolder, false);
      }
      break;
    }
    default:
  }
}

function* updateTeamMembers(teamID) {
  try {
    const currentPage = yield select(SELECTORS.getCurrentPage);
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const members = yield call(API.getTeamMembers, { id: teamID, returnMembersRoles: true });
    yield put(ACTION_CREATORS.updateTeamProperties(teamID, { members }, currentPage, isAssetsFolderActive));
  } catch (err) {
    // error
  }
}

export function* handleManageMembersModal(teamID, inviteOnly, isAdmin) {
  try {
    const teamRoles = yield call(getRoles);
    const team = yield select(SELECTORS.findTeamByID(teamID));
    const username = yield select(SELECTORS.getUserName) || '';
    const accountType = yield select(SELECTORS.getAccountType) || '';
    const serverTeamSettings = yield select(SELECTORS.getServerTeamSettings) || {};
    const isTeamPage = yield select(SELECTORS.isTeamPage);
    const isHIPAA = yield select(SELECTORS.getIsHipaa);
    const canOpenInviteSection = checkUserCanInviteMembers();

    const res = yield call(openManageMembersModal, {
      id: teamID,
      teamRoles,
      inviteOnly,
      isAdmin,
      rootSelector: '#wizardPortal',
      user: { username, accountType },
      globalTeamSettings: serverTeamSettings,
      teamName: team?.name,
      isHIPAA
    });
    if (res?.error) {
      yield call(handleTeamError, { error: res.error, teamID, reloadOnDeletion: true });
    }
    if (isTeamPage && canOpenInviteSection) {
      yield call(updateTeamMembers, teamID);
    }
    yield call(updateTeamFolders);
  } catch (err) {
    console.log(err);
    return false;
  }
}

function* handleInviteMemberModal({ inviteOnly, teamID, isAdmin }) {
  const oldID = r.getTeamID();
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const id = teamID || currentTeamID;
  r.setTeamID(id);
  try {
    yield call(handleManageMembersModal, id, inviteOnly, isAdmin);
  } catch (error) {
    // handle error
  }

  r.setTeamID(oldID);
}

export function* handleTeamsFolderContextActions({ folderID, id: actionID }) {
  const team = yield select(SELECTORS.findTeamByID(folderID));
  const allFolderMenuItems = yield select(SELECTORS.getAllFolderMenuItems);
  const slug = team ? team.slug : '';
  const teamFolderActions = Object.fromEntries(allFolderMenuItems[LISTING_TYPES.TEAM_PAGE].map(({ id }) => [id, id]));
  switch (actionID) {
    case teamFolderActions.GO_TO_TEAM_WORKSPACE: {
      handleCustomNavigation(`/myteams/${slug || folderID}`, '_self');
      break;
    }
    case teamFolderActions.MANAGE_MEMBERS: {
      try {
        const { manageTeam = false } = yield call(API.fetchPermissionInfoUser, folderID);
        yield call(handleManageMembersModal, folderID, false, manageTeam);
      } catch (err) {
        console.log(err);
      }
      break;
    }
    case teamFolderActions.GO_TO_TEAM_SETTINGS: {
      handleCustomNavigation(`/myaccount/team/${slug || folderID}/team-settings`, '_self');
      break;
    }
    default:
      break;
  }
}

function* handleControlMoveToOperation({ selectedFolder, items }) {
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const folder = yield select(SELECTORS.getFolderByID(selectedFolder)) || {};
  if (currentTeamID && ((folder?.folderType === FOLDER_TYPES.TEAM && folder?.id !== currentTeamID) || (folder?.folderType === FOLDER_TYPES.TEAM_FOLDER && currentTeamID !== folder?.team_id))) return;

  if (currentTeamID) {
    const isRemovingAction = selectedFolder === currentTeamID;
    yield put(ACTION_CREATORS.folderItemActionBulk({
      actionType: isRemovingAction ? FOLDER_ITEM_ACTION_TYPES.REMOVE : FOLDER_ITEM_ACTION_TYPES.ADD,
      items,
      folderIDs: [selectedFolder]
    }));

    if (isRemovingAction) {
      const filter = yield select(SELECTORS.getFilter);
      yield put(ACTION_CREATORS.setFilter(filter));
    }
  } else {
    yield put(ACTION_CREATORS.moveToTeamRequest(selectedFolder, items.map(item => item.id)));
  }
}

function* handleMoveToTeam({ selectedTeam, items, isRetry }) {
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const list = yield select(SELECTORS.getList);
  const folders = yield select(SELECTORS.getFolders);
  const teamID = getFolderByID(folders, selectedTeam).team_id || '';
  const teams = yield select(SELECTORS.getTeamFolders);
  const teamProps = teams.find(team => team.id === (teamID || selectedTeam)) || {};
  const assetType = getAssetTypeFromListingType(assetFilterType);
  const selectedAssets = items.map(id => {
    const asset = list.find(item => item.id === id);
    return {
      id,
      type: assetFilterType === LISTING_TYPES.MIX ? asset?.assetType : assetType,
      ...getTeamAssetMoveExtraProps(asset, assetFilterType)
    };
  });

  try {
    const username = yield select(SELECTORS.getUserName) || '';
    const isAdmin = (yield select(SELECTORS.getUserAccountTypeName) || '') === 'ADMIN';
    const { assets, movingJobs } = yield call(openMoveConfirmationModal, {
      itemList: selectedAssets,
      type: currentPage === LISTING_TYPES.MIX ? 'mix' : assetType,
      targetTeamName: teamProps?.name,
      targetTeamID: teamID || selectedTeam,
      sourceTeamID: '',
      toTeam: true,
      isRetry,
      username,
      isAdmin
    });
    yield put(ACTION_CREATORS.fetchMoveJobsSuccess(movingJobs, assetFilterType));
    const resources = assets.reduce((acc, asset) => {
      if (assetFilterType === LISTING_TYPES.MIX || assetType === asset.type) {
        acc.push(asset);
      }
      return acc;
    }, []);
    yield put(ACTION_CREATORS.listenItemMovingStatus());
    if (teamID) {
      yield call(API.moveToTeamFolder, selectedTeam, teamID, selectedAssets);
    }
    const initiators = assets.filter(asset => asset.isInitiator);
    const relatedCount = assets.length - initiators.length;
    const movedAssetsInfo = initiators.length > 1 ? initiators.length : findItemByIDAndType({ list, assetID: initiators[0]?.id, assetType: initiators[0]?.type });
    yield put(ACTION_CREATORS.moveToTeamSuccess(teamProps, resources, movedAssetsInfo, { initiators, relatedCount }));
  } catch (err) {
    yield put(ACTION_CREATORS.moveToTeamError());
  }
}

// TODO: this is not success...
function* moveToTeamSuccess({
  selectedTeam, items, movedAssetsInfo, details
}) {
  yield call(moveToTeamToastCall, items?.map(item => item.id), selectedTeam?.name || '', movedAssetsInfo, details);
}

function* handleMoveFromTeam({ items, isRetry }) {
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const teamID = yield select(SELECTORS.getCurrentTeamID);
  const list = yield select(SELECTORS.getList);
  const assetType = getAssetTypeFromListingType(assetFilterType);
  const selectedAssets = items.map(id => {
    const asset = list.find(item => item.id === id);
    return {
      id,
      type: assetFilterType === LISTING_TYPES.MIX ? asset?.assetType : assetType,
      ...getTeamAssetMoveExtraProps(asset, assetFilterType)
    };
  });

  try {
    const username = yield select(SELECTORS.getUserName) || '';
    const isAdmin = (yield select(SELECTORS.getUserAccountTypeName) || '') === 'ADMIN';
    const { assets, movingJobs } = yield call(openMoveConfirmationModal, {
      itemList: selectedAssets,
      type: currentPage === LISTING_TYPES.MIX ? 'mix' : assetType,
      targetTeamID: '',
      sourceTeamID: teamID,
      toTeam: false,
      isRetry,
      username,
      isAdmin
    });

    yield put(ACTION_CREATORS.fetchMoveJobsSuccess(movingJobs, assetFilterType));
    const resources = assets.reduce((acc, asset) => {
      if (assetFilterType === LISTING_TYPES.MIX || assetType === asset.type) {
        acc.push(asset);
      }
      return acc;
    }, []);
    yield put(ACTION_CREATORS.listenItemMovingStatus());

    const initiators = assets.filter(job => job.isInitiator).map(j => {
      const assetKey = `${j.id}_${j.type}`;
      return {
        ...j,
        title: movingJobs[assetKey]?.title || t('Selected asset')
      };
    });
    const relatedCount = assets.length - initiators.length;

    yield put(ACTION_CREATORS.moveFromTeamSuccess(resources, true, { initiators, relatedCount }));
  } catch (err) {
    yield put(ACTION_CREATORS.moveFromTeamError());
  }
}

function* handleMoveFromTeamSuccess({ details }) {
  yield call(moveFromTeamToast, details, 'info');
}

function* openAssetIsBusyModal(assetTitle, name, username, avatarURL, assetFilterType) {
  const productSpecificMap = {
    [LISTING_TYPES.PORTAL]: {
      doneButtonText: t('OK, Got it!'),
      modalClass: 'forMoveToTeam',
      assetTitle,
      name,
      username,
      avatarURL,
      assetFilterType
    },
    [LISTING_TYPES.REPORT]: {
      doneButtonText: t('OK, Got it!'),
      modalClass: 'forMoveToTeam',
      assetTitle,
      name,
      username,
      avatarURL,
      assetFilterType
    },
    [LISTING_TYPES.TASK]: {
      doneButtonText: t('OK, Got it!'),
      modalClass: 'forMoveToTeam',
      assetTitle,
      name,
      username,
      avatarURL,
      assetFilterType
    },
    [LISTING_TYPES.DOCUMENT]: {
      doneButtonText: t('OK, Got it!'),
      modalClass: 'forMoveToTeam',
      assetTitle,
      name,
      username,
      avatarURL,
      assetFilterType
    },
    [LISTING_TYPES.AGENT]: {
      doneButtonText: t('OK, Got it!'),
      modalClass: 'forMoveToTeam',
      assetTitle,
      name,
      username,
      avatarURL,
      assetFilterType
    }
  };

  try {
    yield call(openAssetBeingEdited, productSpecificMap[assetFilterType]);
  } catch {
    console.error('Modal could not be opened anyway!');
  }
}

function* checkIsResourceAvailable({ assetID, assetFilterType, resourceType }) {
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const { assetType, title: assetTitle } = yield select(SELECTORS.getItemByIDAndType(assetID, resourceType));

  const response = yield call(API.getAssetIsBusy, assetID, assetType, currentTeamID);

  if (!isEmpty(response)) {
    const {
      name, username, avatarUrl
    } = response;

    return yield call(openAssetIsBusyModal, assetTitle, name, username, avatarUrl, assetFilterType);
  }

  return true;
}

function* handleRequestEdit({ assetID, props, resourceType }) {
  const asset = yield select(SELECTORS.getItemByIDAndType(assetID, resourceType));
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const assetListing = ASSET_TYPE_MAP[asset.assetType];
  const assetType = assetListing || assetFilterType;

  const isAvailable = yield checkIsResourceAvailable({ assetID, assetFilterType: assetType, resourceType });
  // for report have own flow. It has to return back again.
  if (isAvailable && assetListing === LISTING_TYPES.REPORT) {
    yield call(handleReportUpdateWizard, { ...props, isAvailable });
  }

  if (isAvailable) {
    redirectToProductBuilder(assetType, assetListing === LISTING_TYPES.DOCUMENT
      ? asset.formID
      : assetID);
  }
}

function* handleLandingTeamCreationFlow() {
  if (!isEnterprise() && window.location.href.indexOf('createTeam') > -1) {
    yield call(showCreateTeamModal, { redirectAfterCreation: true, source: 'landingFlow' });
  }
}

function* handleCreateTeamModal() {
  const { teamCreation = '1' } = yield select(SELECTORS.getServerTeamSettings) || {};
  const canCreateTeam = teamCreation === '1';
  const source = new URLSearchParams(window.location.search).get('showCreateTeamModal');
  if (source && canCreateTeam) {
    yield call(showCreateTeamModal, { redirectAfterCreation: true, source });
  }
}

function* fetchMoveJobs(space, movingJobs) {
  try {
    const nextMovingJobs = yield movingJobs || call(API.fetchMoveJobs, space);
    const prevMovingJobs = yield select(SELECTORS.getMovingJobs);
    const assetFilterType = yield select(SELECTORS.getAssetFilterType);
    yield put(ACTION_CREATORS.fetchMoveJobsSuccess(nextMovingJobs, assetFilterType));
    const assetKeys = new Set([...Object.keys(nextMovingJobs), ...Object.keys(prevMovingJobs)]);
    // eslint-disable-next-line no-restricted-syntax
    for (const assetKey of assetKeys) {
      if (assetKey in prevMovingJobs) {
        const movedAsset = prevMovingJobs[assetKey];
        const relatedCount = Object.values(prevMovingJobs).filter(j => (j.initiator === assetKey && j.key !== assetKey)).length;

        // success notification
        if (!(assetKey in nextMovingJobs) && (movedAsset.status === 'loading' && movedAsset.isInitiator)) {
          yield call(moveAssetToastCall, movedAsset, relatedCount, 'success');
        }

        // fail notification
        if (assetKey in nextMovingJobs && (movedAsset.status === 'loading' && nextMovingJobs[assetKey].status === 'failed')) {
          yield call(moveAssetToastCall, movedAsset, relatedCount, 'error');
        }
      }
    }

    if (Object.values(nextMovingJobs).every(job => job.status !== 'loading')) {
      return false;
    }

    return Object.keys(nextMovingJobs).length > 0;
  } catch (error) {
    console.log('error: ', error);
    return false;
  }
}

export function* listenItemMovingStatus() {
  yield take(ACTION_TYPES.SET_CURRENT_TEAM_ID);
  const teams = yield select(SELECTORS.getTeams);
  if (!teams?.length) return;

  yield put(ACTION_CREATORS.preventFetchingMoveJobs(false));
  const teamId = yield select(SELECTORS.getCurrentTeamID);
  const space = teamId ? 'team' : 'personal';
  const nextMovingJobs = yield call(API.fetchMoveJobs, space);
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  yield put(ACTION_CREATORS.fetchMoveJobsSuccess(nextMovingJobs, assetFilterType));
  const shouldLoop = yield fetchMoveJobs(space, nextMovingJobs);

  if (!shouldLoop) {
    yield put(ACTION_CREATORS.preventFetchingMoveJobs(true));
  }

  let delayTime = 2000;
  const intervalMultiplier = 1.7; // higher value, less requests
  const maxDelayTime = 60000;

  while (true) {
    if (!shouldLoop) {
      yield take(ACTION_TYPES.LISTEN_MOVE_JOBS);
    }
    while (true) {
      yield delay(delayTime);
      const shouldContinue = yield fetchMoveJobs(space);
      if (!shouldContinue) {
        break;
      }
      if (delayTime < maxDelayTime) {
        delayTime = (delayTime * intervalMultiplier) % maxDelayTime;
      }
    }
  }
}

export function* handleCancelMoveStatus({ id, assetType }) {
  try {
    const response = yield call(API.deleteMoveStatus, id, assetType);
    if (response === true) {
      yield put(ACTION_CREATORS.cancelMoveStatusSuccess(id, assetType));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* handleRetryMoveJob({ id, assetType }) {
  const movingJobs = yield select(SELECTORS.getMovingJobs);
  const retryAssetKey = `${id}_${assetType}`;
  const selectedListItemIDs = [id];
  const currentJob = Object.values(movingJobs).find(job => job.initiator === retryAssetKey);

  if (currentJob?.targetTeamID) {
    yield put(ACTION_CREATORS.moveToTeamRequest(currentJob?.targetTeamID, selectedListItemIDs, true));
  } else {
    yield put(ACTION_CREATORS.moveFromTeamRequest(selectedListItemIDs, true));
  }
}

// since jotformExperince saga is not loaded in ENTERPRISE ENV, we needed to init modal here
export function* checkThenRenderJotformersTeamsPromotionalModal() {
  if (yield call(shouldShowJotformersTeamModal)) { // Only show for jotformers.com
    const credentials = yield select(SELECTORS.getUserCredentials);
    const { location: { href } } = window;
    const jotformersTeamLaunchCodeIndex = href.indexOf('JotformersTeamModal');
    const jotformersTeamLaunchCode = href.substring(jotformersTeamLaunchCodeIndex + 20);
    const campaignData = { jotformersTeamLaunchCode };
    yield call(renderExperienceModals, MODAL_TYPES.EXPERIENCE_JOTFORMERS_TEAM, credentials, campaignData);
  }
}

export function* updateTeamOrder({ teamID, referenceTeamID, position }) {
  const currFolders = yield select(SELECTORS.getFolders);
  try {
    if (!teamID || !referenceTeamID || FOLDER_DROP_POSITIONS.OVER === position || Object.values(FOLDER_DROP_POSITIONS).indexOf(position) === -1) return false;

    const teams = yield select(SELECTORS.getTeams);
    const sourceTeam = teams.find(team => team.id === teamID);
    const targetTeam = teams.find(team => team.id === referenceTeamID);

    if (!sourceTeam || !targetTeam) return;

    yield put(ACTION_CREATORS.allowReorderTeams(false));

    const isBefore = position === FOLDER_DROP_POSITIONS.BEFORE;
    const filteredFolders = currFolders.filter(folder => folder.id !== teamID);
    const targetFolderIndex = filteredFolders.findIndex(team => team.id === referenceTeamID);
    const sliceIndex = isBefore ? targetFolderIndex : targetFolderIndex + 1;
    yield put(ACTION_CREATORS.setFolders([
      ...filteredFolders.slice(0, sliceIndex), currFolders.find(folder => folder.id === teamID), ...filteredFolders.slice(sliceIndex)
    ]));

    const resp = yield call(API.reorderTeams, { sourceTeamID: teamID, targetTeamID: referenceTeamID, targetPosition: isBefore ? 'before' : 'after' });
    if (resp !== true) {
      yield put(ACTION_CREATORS.setFolders(currFolders));
      yield call(showError);
    }
    yield call(updateTeamFolders);
    yield put(ACTION_CREATORS.allowReorderTeams(true));
  } catch (e) {
    yield put(ACTION_CREATORS.setFolders(currFolders));
    yield call(showError);
    yield call(updateTeamFolders);
    yield put(ACTION_CREATORS.allowReorderTeams(true));
  }
}

export function* updateTeamSubFoldersOrder({
  teamID, folderId, targetPath, newSubfolders
}) {
  let hasError;
  try {
    if (!teamID) return false;

    const teams = yield select(SELECTORS.getTeams);
    const targetTeam = teams.find(team => team.id === teamID);

    if (!targetTeam) return;
    yield put(ACTION_CREATORS.allowReorderTeams(false));
    const order = targetPath.pop();
    const firstParent = newSubfolders[targetPath.shift()];
    const remainingPath = [...targetPath];
    const parentId = remainingPath.reduce((acc, index) => acc.subfolders[index], firstParent)?.id || targetTeam.rootFolderId;
    const resp = yield call(API.reorderTeamSubfolders, {
      teamID, parentId, folderId, order
    });
    if (resp?.errorDetails) {
      yield call(showError, resp.errorDetails);
      hasError = true;
    }
  } catch (e) {
    yield call(showError, e.data?.content || e.data?.message);
    hasError = true;
  } finally {
    const currentPage = yield select(SELECTORS.getCurrentPage);
    const filter = yield select(SELECTORS.getFilter);
    if (!hasError) {
      yield put(ACTION_CREATORS.updateTeamProperties(teamID, { subfolders: newSubfolders }, currentPage));
      yield put(ACTION_CREATORS.setFilter(filter));
    }

    yield put(ACTION_CREATORS.allowReorderTeams(true));
  }
}

export function* rootTeamFlow() {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  if (window?.teamID && currentPage === LISTING_TYPES.TEAM_PAGE) {
    yield put(ACTION_CREATORS.setCurrentTeamID(window.teamID));
  }

  yield take(ACTION_TYPES.FETCH_USER.SUCCESS);

  yield handleLandingTeamCreationFlow();

  const isTeamsActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.JOTFORM_TEAMS));
  if (!isTeamsActive) return;

  if (currentPage === LISTING_TYPES.TEAM_PAGE) {
    yield initRoles();
    if (isPrivacyFeatureEnabled()) {
      yield take(ACTION_TYPES.UPDATE_TEAMS);
      yield initTeamNotifications();
    }
  }
  if (isServerTeamSettingsEnabled()) {
    yield initServerTeamSettings();
  }
  yield handleCreateTeamModal();
  yield takeLatest(ACTION_TYPES.SHOW_CREATE_TEAM_MODAL, showCreateTeamModal);
  yield takeLatest(ACTION_TYPES.SHOW_INVITE_MEMBER_MODAL, handleInviteMemberModal);
  yield takeLatest(ACTION_TYPES.FOLDER_CONTEXT_ACTION, handleTeamsFolderContextActions);
  yield takeLatest(ACTION_TYPES.MOVE_TO_TEAM.REQUEST, handleMoveToTeam);
  yield takeLatest(ACTION_TYPES.CONTROL_MOVE_TO_OPERATION.REQUEST, handleControlMoveToOperation);
  yield takeLatest(ACTION_TYPES.MOVE_TO_TEAM.SUCCESS, moveToTeamSuccess);
  yield takeLatest(ACTION_TYPES.MOVE_FROM_TEAM.REQUEST, handleMoveFromTeam);
  yield takeLatest(ACTION_TYPES.MOVE_FROM_TEAM.SUCCESS, handleMoveFromTeamSuccess);
  yield takeLatest(ACTION_TYPES.HANDLE_TEAM_ERROR, handleTeamError);
  yield takeLatest(ACTION_TYPES.REQUEST_EDIT, handleRequestEdit);
  yield takeLatest(ACTION_TYPES.CANCEL_MOVE_JOBS.REQUEST, handleCancelMoveStatus);
  yield takeLatest(ACTION_TYPES.RETRY_MOVE_JOB, handleRetryMoveJob);
  yield takeLatest([ACTION_TYPES.SET_SELECTED_FOLDER, ACTION_TYPES.SET_TEAM_FILTER], listenItemMovingStatus);
  yield takeLatest(ACTION_TYPES.REORDER_TEAMS, updateTeamOrder);
  yield takeLatest(ACTION_TYPES.REORDER_TEAM_SUBFOLDERS, updateTeamSubFoldersOrder);

  yield checkThenRenderJotformersTeamsPromotionalModal();
}
