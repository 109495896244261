import { LISTING_API_END_POINT_MAP } from '../../constants';
import { ListActionToast } from '../../contexts/list/utils';
import { getPageFromUrl } from '../../utils';

const page = getPageFromUrl();
const currentPage = LISTING_API_END_POINT_MAP[page];
const listActionToast = new ListActionToast(currentPage);
const updateToast = listActionToast?.updateToast;
const errorToast = listActionToast?.errorToast;

const restoreCall = (id, cb) => {
  cb(id);
  updateToast({ prop: 'status', selected: [id], value: 'ENABLED' });
};

export const deleteToastCall = id => {
  updateToast({ prop: 'status', selected: [id], value: 'PURGED' });
};

export const archiveToastCall = (id, restoreItemCall) => {
  const restore = () => restoreCall(id, restoreItemCall);
  updateToast({
    prop: 'status',
    selected: [id],
    value: 'ARCHIVED',
    actions: { restore }

  });
};

export const trashToastCall = (id, restoreItemCall) => {
  const restore = () => restoreCall(id, restoreItemCall);
  updateToast({
    prop: 'status',
    value: 'TRASHED',
    selected: [id],
    actions: { restore }

  });
};

export const renameToastCall = () => {
  updateToast({ prop: 'status', value: 'RENAME' });
};

export const restoreToastCall = id => {
  updateToast({ prop: 'status', selected: [id], value: 'ENABLED' });
};

export const bulkUpdateToastCall = (prop, value, selectedForBulkUpdate, restoreItemCall, selectedWithType) => {
  updateToast({
    prop,
    value,
    selected: selectedForBulkUpdate,
    actions: {
      restore: () => {
        restoreItemCall({ prop, value: 'ENABLED', selectedList: selectedWithType });
        updateToast({ prop: 'status', selected: selectedForBulkUpdate, value: 'ENABLED' });
      }
    }
  });
};

export const bulkUpdateRestoreToastCall = selected => {
  updateToast({ prop: 'status', selected: selected, value: 'ENABLED' });
};

export const bulkUpdateDeleteToastCall = selected => {
  updateToast({ prop: 'status', selected: selected, value: 'PURGED' });
};

export const bulkUpdateDeleteSignDocsToastCall = () => {
  updateToast({ prop: 'status', selected: [], value: 'PURGE_SIGN_DOCS' });
};

export const multipleSelectWithShiftToastCall = () => {
  updateToast({ prop: 'status', selected: [], value: 'MULTIPLE_SELECT_WITH_SHIFT' });
};

export const moveToTeamToastCall = (selected, target, movedAssetsInfo, details) => {
  updateToast({
    prop: 'status',
    value: 'MOVED_TO_TEAM',
    selected,
    target,
    movedAssetsInfo,
    details
  });
};

export const moveFromTeamToastCall = selected => {
  updateToast({
    prop: 'status',
    value: 'MOVED_FROM_TEAM',
    selected
  });
};

export const moveAssetToastCall = (movedAsset, relatedCount, type) => {
  updateToast({
    value: 'MOVE_JOB_TOAST',
    movedAsset,
    relatedCount,
    type
  }, { type });
};

export const moveFromTeamToast = (details, type) => {
  updateToast({
    value: 'MOVE_FROM_TEAM_TOAST',
    details
  }, { type });
};

export const showError = (errorMessage = 'Something went wrong!') => {
  errorToast(errorMessage);
};
