import { useState, useEffect } from 'react';
import { notificationConfig } from '../constants';
import { hasFlagAtLocSt } from '../utils';

export const useAutoVisibleNotification = (notifications, initInAutoVisibleMode = false) => {
  const [autoVisibleNotificationList, setNotification] = useState([]);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (isClosed || notifications.length === 0 || !initInAutoVisibleMode) return;
    const notifList = notifications.filter(notf => notf.isAutoVisible);
    if (notifList.length === 0) return;
    const { autoVisibleLocalStActionKey } = notificationConfig[notifList[0].name];
    const hasSeenFlagInLcSt = hasFlagAtLocSt(autoVisibleLocalStActionKey);
    if (!hasSeenFlagInLcSt) {
      setNotification(notifList);
    }
  }, [notifications]);

  useEffect(() => {
    setNotification([]);
  }, [isClosed]);

  return [autoVisibleNotificationList, setIsClosed];
};
