import { handleCustomNavigation } from '@jotforminc/utils';
import { LISTING_TYPES } from '../../constants';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const sendEventTrack = (target, event) => WatchmanRecorder.trackEvent(event, `${target}`);

const trackPortalEvent = (id, actionDetails) => {
  sendEventTrack(`${id}-${actionDetails}`, 'click');
};

const handleEditApp = id => {
  trackPortalEvent(id, 'portalViewAppBuilder');
  const url = `/app/build/${id}`;
  handleCustomNavigation(url, '_self');
};

const handleEditWorkflow = id => {
  handleCustomNavigation(`/workflow/${id}`, '_self');
};

const handleEditSignDocument = id => {
  handleCustomNavigation(`/sign/${id}`, '_self');
};

const handleEditAgent = id => {
  handleCustomNavigation(`/agent/build/${id}`, '_self');
};

const NAVIGATION_UTILS = {
  handleEditApp,
  handleEditWorkflow,
  handleEditSignDocument,
  handleEditAgent
};

const productMap = {
  [LISTING_TYPES.PORTAL]: NAVIGATION_UTILS.handleEditApp,
  [LISTING_TYPES.TASK]: NAVIGATION_UTILS.handleEditWorkflow,
  [LISTING_TYPES.DOCUMENT]: NAVIGATION_UTILS.handleEditSignDocument,
  [LISTING_TYPES.AGENT]: NAVIGATION_UTILS.handleEditAgent
};

export const redirectToProductBuilder = (assetType, resourceID) => {
  productMap[assetType](resourceID);
};
