/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { forwardRef, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import classNames from 'classnames';
import { InlineEditor } from '@jotforminc/jotform-common';
import {
  arrayOf, func, shape, bool
} from 'prop-types';
import { IconFolderFilled, IconXmark } from '@jotforminc/svg-icons';
import { translationRenderer } from '@jotforminc/translation';
import {
  ScTitle, ScDesc, ScHipaaEnforcementBadge,
  ScSurveyFormBadge, ScSurveyFormBadgeWrapper,
  ScHipaaEnforcementBadgeWrapper, ScSurveyFormBadgeDesc
} from './bitsListItem';
import {
  ASSET_TYPES, LISTING_API_END_POINT_MAP, DEFAULT_FOLDER_IDS, STATUS_FOLDERS
} from '../../constants';
import {
  daysBetween, getPageFromUrl, getPortalRoot, getReadableColorClass, prettyDate
} from '../../utils';
import BuilderUtils from '../../utils/BuilderUtils';
import t from '../../utils/translate';
import Image from '../Image';

export const defaultTagRenderer = tags => {
  return tags.map(({ name, onClick }) => (<span className="listItemTag" onClick={onClick}>{name}</span>));
};

export const defaultDescriptionRenderer = description => {
  return (
    <ScDesc data-sc="listItem-desc">
      {description}
    </ScDesc>
  );
};

export const getRemainingDays = updatedAt => {
  if (!updatedAt) {
    return 0;
  }
  const today = new Date();
  const updatedDate = new Date(updatedAt.replace(/-/g, '/'));

  return (30 - daysBetween(updatedDate, today));
};

export const FolderBadgeRenderer = ({ folders = [], onRemove = f => f, canRemove = false }) => {
  return (
    <div className="lsApp-folder-list">
      {folders.map(folder => (
        <div className={classNames('lsApp-folder', getReadableColorClass(folder.color))} key={folder.id} style={{ backgroundColor: folder.color }}>
          <IconFolderFilled className="w-3 h-3 absolute left-2.5 folderIcon" aria-hidden="true" />
          <div className="lsApp-folder-name" title={folder.name || folder.text}>
            {folder.name || folder.text}
          </div>
          {canRemove ? (
            <button
              className="isRemoveIcon"
              type="button"
              aria-label={t('Delete')}
              onClick={e => {
                e.stopPropagation();
                onRemove(folder.id);
              }}
            >
              <IconXmark className="w-4 h-4" />
            </button>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export const TeamDragFolderBadgeRenderer = ({ folder = {} }) => {
  return (
    <div className="lsApp-folder-list">
      <div className={classNames('lsApp-folder team-drag-layer', getReadableColorClass(folder.color))} key={folder.id} style={{ backgroundImage: 'none' }}>
        <Image {...folder.customIconProps} />
        <div className="lsApp-folder-name" title={folder.name || folder.text}>
          {folder.name || folder.text}
        </div>
      </div>
    </div>
  );
};

TeamDragFolderBadgeRenderer.propTypes = {
  folder: shape({})
};

export const getFoldersArray = ({
  foldersProp, flattenedFolders
}) => {
  if (!foldersProp || !flattenedFolders) return [];

  return foldersProp.split(',').filter(f => f).map(folder => flattenedFolders.find(({ id: folderID }) => folderID === folder)).filter(f => f);
};

export const HipaaEnforcementBadgeRenderer = ({
  isDmca = false, isPII = false, isCC = false, isLC = false, isWarning = false, isSpam = false
}) => {
  const hipaaSection = document.getElementById('hipaa-enforcement-section');
  if (!hipaaSection) {
    const hipaaWizardElement = document.createElement('div');
    hipaaWizardElement.id = 'hipaa-enforcement-section';
    document.body.appendChild(hipaaWizardElement);
  }

  const handleClickHipaaRequiredBadge = event => {
    event.stopPropagation();
    if (isDmca || isPII || isCC || isLC || isSpam) return;
    const hipaaAppButton = document.querySelector('#enable-hipaa-button');
    if (hipaaAppButton) {
      hipaaAppButton.click();
    } else {
      BuilderUtils.openHIPAAWizard(true);
    }
  };

  const textRenderer = () => {
    if (isDmca) return 'DMCA';
    if (isPII) return 'PII';
    if (isCC) return 'Credit/Debit Card Collection';
    if (isLC) return 'Login Credentials Collection';
    if (isSpam) return 'Spam Form';

    return 'HIPAA Upgrade Required!';
  };

  return (
    <ScHipaaEnforcementBadgeWrapper>
      <ScHipaaEnforcementBadge className={classNames('hipaa-enforcement-badge', { 'badge-warning': isWarning })} onClick={handleClickHipaaRequiredBadge}>
        {t(textRenderer())}
      </ScHipaaEnforcementBadge>
    </ScHipaaEnforcementBadgeWrapper>
  );
};

HipaaEnforcementBadgeRenderer.propTypes = {
  isSpam: bool,
  isDmca: bool,
  isPII: bool,
  isCC: bool,
  isLC: bool,
  isWarning: bool
};

export const SurveyBadgeRenderer = () => {
  const surveyBadgeRef = useRef();

  const handleShowHide = show => {
    const surveyToolEl = document.getElementById('survey-badge-tooltip');
    if (show) {
      if (surveyToolEl) surveyToolEl.classList.remove('invisible');
    } else if (surveyToolEl) {
      surveyToolEl.classList.add('invisible');
    }
  };

  return (
    <ScSurveyFormBadgeWrapper>
      <ScSurveyFormBadge
        ref={surveyBadgeRef}
        onClick={event => event.stopPropagation()}
        onMouseEnter={() => {
          const surveyBadgePos = surveyBadgeRef.current.getBoundingClientRect();
          const portalRoot = getPortalRoot();
          const rightAligned = window.innerWidth - surveyBadgePos.right < 416;
          handleShowHide(true);
          const root = createRoot(portalRoot);
          root.render(
            <ScSurveyFormBadgeDesc
              id='survey-badge-tooltip'
              className={`survey-badge-desc${rightAligned ? ' right-aligned' : ''}`}
              style={{
                left: surveyBadgePos.left,
                top: surveyBadgePos.top + surveyBadgePos.height + 6
              }}
            >
              {t('This form is qualified as a Jotform Survey and can collect responses without any submission limits as part of Jotform’s Student Survey Program.')}
            </ScSurveyFormBadgeDesc>
          );
        }}
        onMouseLeave={() => handleShowHide(false)}
      >
        {t('SURVEY')}
      </ScSurveyFormBadge>
    </ScSurveyFormBadgeWrapper>
  );
};

FolderBadgeRenderer.propTypes = {
  folders: arrayOf(shape({})),
  onRemove: func,
  canRemove: bool
};

export const defaultTitleRenderer = title => {
  const page = getPageFromUrl();
  const currentPage = LISTING_API_END_POINT_MAP[page];
  return (
    <ScTitle data-sc="listItem-title">
      <span aria-label={`${currentPage} title ${title}`} tabIndex={0}>{title}</span>
    </ScTitle>
  );
};

export const editableTitleRenderer = ({
  title, handleTitleChange, placeholder = '', onBlur = f => f
}) => {
  const handleChange = newTitle => (newTitle === title ? {} : handleTitleChange(newTitle));
  const page = getPageFromUrl();
  const currentPage = LISTING_API_END_POINT_MAP[page];
  return (
    <InlineEditor
      defaultValue={title}
      onChange={handleChange}
      Renderer={forwardRef((props, ref) => (
        <ScTitle
          ref={ref} aria-label={`${currentPage} title`} role="textbox"
          {...props}
        />
      ))}
      data-sc="listItem-title"
      placeholder={placeholder}
      onClick={e => e.stopPropagation()}
      isAllowedEmpty={false}
      onBlur={onBlur}
    />
  );
};

export const getAppLinkWithUTM = (appLink, appID, source) => (`${appLink}/?utm_source=${source}&utm_medium=website&utm_campaign=portal-app&utm_term=${appID}`);

export const getDefinedSubmissionKeywords = portalType => {
  switch (portalType) {
    case 'STORE':
      return ['Order', 'ordered'];
    case 'DONATION':
      return ['Donation', 'donated'];
    default:
      return ['Submission', 'submitted'];
  }
};

const getTime = date => {
  const [, time] = date?.split(' ');
  return time;
};

const wrapDate = (text, rawDate) => {
  return (
    <span title={`${prettyDate(rawDate)} ${getTime(rawDate)}`}>
      {text}
    </span>
  );
};

export const getItemInfo = ({
  type, submissionsLink, lastActivityLogText, createdAt, updatedAt, userLanguage, orderby, lastSubmission, sharer, selectedFolder, lastEntry, totalEntry, sharerType,
  sharerID, lastSubmittedAt, unreadSubmissionKeyword, assignedAt, lastAssignedAt, isMyApprovals, count, waitingCount, instanceSummary, creationTime, modificationTime,
  lastConversationDate, conversationsLink
}) => {
  let itemInfoText;
  switch (type) {
    case ASSET_TYPES.FORM: {
      if (lastActivityLogText) return [submissionsLink, ' ', lastActivityLogText];

      itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)}`;

      if (orderby === 'updated_at') {
        itemInfoText = `${t('Last edited on')} ${prettyDate(updatedAt, userLanguage)}`;
      }

      if (orderby === 'last_submission') {
        itemInfoText = lastSubmission ? `${t('Last submission on')} ${prettyDate(lastSubmission, userLanguage)}` : t('No submission received');
      }

      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_WITH_ME && sharer) {
        return [submissionsLink, ` ${itemInfoText}. `, translationRenderer('Shared by [1[{sharer}]].')({
          renderer1: () => sharer
        })];
      }
      return [submissionsLink, ` ${itemInfoText}`];
    }
    case ASSET_TYPES.SHEET: {
      if (lastActivityLogText) return [lastActivityLogText, '. '];

      itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)} `;

      if (orderby === 'updated_at') {
        itemInfoText = `${t('Last edited on')} ${prettyDate(updatedAt || createdAt, userLanguage)} `;
      }

      if (orderby === 'last_entry') {
        itemInfoText = `${t('Last entry on')} ${prettyDate(lastEntry || createdAt, userLanguage)} `;
      }

      if (orderby === 'total_entry') {
        itemInfoText = totalEntry ? `${t('Total entry')} ${totalEntry}. ` : '';
      }

      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_SHEETS) {
        const sharerInfo = sharerType === 'LINK' ? sharer : sharerID;
        itemInfoText = (
          <span>
            {itemInfoText}
            {`${t('Shared by')} `}
            <b>{sharerInfo}</b>
            .
          </span>
        );
      }

      return itemInfoText;
    }
    case ASSET_TYPES.REPORT: {
      if (lastActivityLogText) return lastActivityLogText;

      itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)}`;

      if (orderby === 'updated_at') {
        itemInfoText = `${t('Last edited on')} ${prettyDate(updatedAt, userLanguage)}`;
      }

      if (orderby === 'last_submission') {
        itemInfoText = lastSubmission ? `${t('Last submission on')} ${prettyDate(lastSubmission, userLanguage)}` : t('No submission received');
      }

      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_REPORTS) {
        itemInfoText = (
          <span>
            {itemInfoText}
            {'. '}
            {t('Shared by')}
            {' '}
            <b>{sharer}</b>
            .
          </span>
        );
      }

      return itemInfoText;
    }
    case ASSET_TYPES.PORTAL: {
      if (lastActivityLogText) return lastActivityLogText;

      itemInfoText = wrapDate(`${t('Created on')} ${prettyDate(createdAt, userLanguage)}`, createdAt);

      if (orderby === 'updated_at') {
        itemInfoText = updatedAt && wrapDate(`${t('Last edited on')} ${prettyDate(updatedAt, userLanguage)}`, updatedAt);
      }

      if (['last_portal_submission', 'portal_submission', 'unread_portal_submission'].indexOf(orderby) > -1) {
        itemInfoText = lastSubmittedAt && wrapDate(`${t(`Last ${unreadSubmissionKeyword} at`)} ${prettyDate(lastSubmittedAt, userLanguage)}`, lastSubmittedAt);
      }
      return itemInfoText;
    }
    case ASSET_TYPES.WORKFLOW: {
      if (lastActivityLogText) return [lastActivityLogText, '. '];

      if (orderby === 'assigned_at') {
        itemInfoText = `${t('Assigned on')} ${prettyDate(assignedAt, userLanguage)}.`;
      }

      if (orderby === 'last_assigned') {
        itemInfoText = lastAssignedAt ? `${t('Last approval on')} ${prettyDate(lastAssignedAt, userLanguage)}.` : '';
      }

      const isStatusFolderSelected = STATUS_FOLDERS.indexOf(selectedFolder) > -1;
      if (isMyApprovals || isStatusFolderSelected || selectedFolder === DEFAULT_FOLDER_IDS.TASKS) {
        if (orderby === 'updated_at' && (updatedAt || createdAt)) {
          itemInfoText = `${t('Last edited on')} ${prettyDate(updatedAt || createdAt, userLanguage)}.`;
        } else if (createdAt) {
          itemInfoText = `${t('Created at')} ${prettyDate(createdAt, userLanguage)}.`;
        }
      }

      const submissionText = count && (count === 0 || count > 1) ? 'Submissions' : 'Submission';
      let descriptionStart = `${count || 0} ${t(submissionText)}.`;
      if (isMyApprovals || isStatusFolderSelected) {
        const activeCount = parseInt(instanceSummary?.active || 0, 10);
        const totalCount = parseInt(instanceSummary?.total || 0, 10);
        descriptionStart = `${totalCount > 0 ? `${totalCount} ${t('Total')}.` : ''}${activeCount > 0 ? ` ${activeCount} ${t('In-progress')}.` : ''}`;
      }
      return (
        <>
          {descriptionStart}
          {` ${itemInfoText || ''}`}
          {waitingCount > 0 && ` ${waitingCount} ${t('Waiting your approval')}.`}
        </>
      );
    }
    case ASSET_TYPES.SIGN: {
      if (['modificationTime', 'updated_at'].indexOf(orderby) > -1 && (modificationTime || updatedAt)) {
        return translationRenderer('Last edited on [1[{date}]]')({
          renderer1: () => prettyDate(modificationTime || updatedAt, userLanguage)
        });
      }
      return translationRenderer('Created on [1[{date}]]')({
        renderer1: () => prettyDate(creationTime || createdAt, userLanguage)
      });
    }
    case ASSET_TYPES.BOARD: {
      if (lastActivityLogText) return [lastActivityLogText, '. '];

      itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)}. `;

      if (orderby === 'updated_at') {
        itemInfoText = `${t('Last updated on')} ${prettyDate(updatedAt || createdAt, userLanguage)}. `;
      }

      if (orderby === 'last_entry') {
        itemInfoText = `${t('Last entry on')} ${prettyDate(lastEntry || createdAt, userLanguage)}. `;
      }

      if (orderby === 'total_entry') {
        itemInfoText = totalEntry ? `${t('Total entry')} ${totalEntry}. ` : '';
      }

      if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_BOARDS) {
        const sharerInfo = sharerType === 'LINK' ? sharer : sharerID;
        return (
          <span className='w-full min-w-0 truncate'>
            {itemInfoText}
            {`${t('Shared by')} `}
            <b>{sharerInfo}</b>
            .
          </span>
        );
      }
      return (
        <span className='w-full min-w-0 truncate'>
          {itemInfoText}
        </span>
      );
    }
    case ASSET_TYPES.AGENT: {
      itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)}.`;
      let useItemInfo;

      if (lastConversationDate) {
        itemInfoText = `${t('Last conversation on')} ${prettyDate(lastConversationDate, userLanguage)}.`;
        useItemInfo = true;
      }

      if (orderby === 'created_at') {
        itemInfoText = `${t('Created on')} ${prettyDate(createdAt, userLanguage)}.`;
      }

      if (orderby === 'updated_at') {
        itemInfoText = `${t('Last edited on')} ${prettyDate(updatedAt || createdAt, userLanguage)}.`;
      }

      if (lastActivityLogText) return [conversationsLink, useItemInfo ? ` ${itemInfoText} ` : ' ', lastActivityLogText, '. '];

      return [conversationsLink, ` ${itemInfoText}`];
    }
    default:
      return '';
  }
};
