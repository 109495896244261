import { createRoot } from 'react-dom/client';
import { Wizard } from '@jotforminc/wizard-shared';
import React from 'react';
import CreateAssetModal from '.';

const root = document.getElementById('modal-container');

export const openCreateAssetModal = ({
  teamPermissions, folderID, isAssetsFolderActive, user, userPermissions, isTeamAssetCreation
}) => {
  const rootNode = createRoot(root);

  return new Promise(resolve => {
    const handleClose = () => {
      resolve({ closed: true });
      rootNode.unmount();
    };

    const handleProductSelect = productType => {
      resolve({ closed: false, productType });
      rootNode.unmount();
    };
    rootNode.render(<Wizard
      usePortal
      targetContainer="#modal-container"
      Navigation={{ CreateAssetModal }}
      DefaultView='CreateAssetModal'
      initialVisibilityState={true}
      onHide={handleClose}
      onProductSelect={handleProductSelect}
      teamPermissions={teamPermissions}
      userPermissions={userPermissions}
      isTeamAssetCreation={isTeamAssetCreation}
      selectedFolder={folderID}
      isAssetsFolderActive={isAssetsFolderActive}
      user={user}
    />);
  });
};
