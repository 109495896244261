import React from 'react';
import { AppIcons } from '@jotforminc/header-components';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { LISTING_TYPES } from '../../constants';
import { ALL_PERMISSIONS } from '../../utils/permissions';
import { canAccessMyAgentsPage } from '../../utils';

export const TEXTS = {
  HEADER_TITLE: 'How would you like to start?',
  HEADER_TITLE_WITH_USERNAME: 'How would you like to start, [1[{username}]]?',
  HEADER_SUBTITLE: 'Your all-in-one platform to effortlessly create online forms and collect data in one place.'
};

export const PRIMARY_PRODUCT_CONFIGS = [
  {
    name: 'Form',
    description: 'Powerful forms that use conditional logic, accept payments, generate reports, and automate workflows.',
    Icon: <img className='productIcon' src='https://cdn.jotfor.ms/assets/img/my-forms/mixAssetModal/mixModalFormIcon.svg?v=1' />,
    type: LISTING_TYPES.FORM,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FORM
  },
  {
    name: 'App',
    description: 'Make a downloadable mobile app with no coding that lets you add forms, data and your branding',
    Icon: <img className='productIcon' src='https://cdn.jotfor.ms/assets/img/my-forms/mixAssetModal/mixModalAppIcon.svg?v=1' />,
    type: LISTING_TYPES.PORTAL,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_APP
  },
  {
    name: window?.isWorkflowReleased ? 'Workflow' : 'Approval',
    description: 'Create and automate workflows in minutes, streamline approvals, and track progress effortlessly.',
    Icon: <img className='productIcon' src='https://cdn.jotfor.ms/assets/img/my-forms/mixAssetModal/mixModalWorkflowIcon.png' />,
    isNew: window?.isWorkflowReleased,
    type: LISTING_TYPES.WORKFLOW,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FLOW,
    productInfo: window?.isWorkflowReleased ? 'Formerly Approvals' : '',
    createPermissionName: 'Approval'
  }
];

export const SECONDARY_PRODUCT_CONFIGS = [
  {
    name: 'Table',
    description: 'Sync, manage, analyze, share, and collaborate on data in one place.',
    Icon: AppIcons?.tables?.Selected,
    type: LISTING_TYPES.SHEET,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_SHEET
  },
  {
    name: 'E-sign',
    description: 'Create, share, and e-sign documents on any device.',
    Icon: AppIcons?.sign?.Selected,
    type: LISTING_TYPES.DOCUMENT,
    createPermissionName: 'Document',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_DOCUMENT,
    checkVisibility: ({ user }) => {
      return !isEnterprise() || user?.allowSign;
    }
  },
  {
    name: 'AI Agent',
    description: 'Build conversational, subject-trained agents.',
    Icon: AppIcons?.myAgents?.Selected,
    isBeta: true,
    type: LISTING_TYPES.AGENT,
    createPermissionName: 'Agent',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_AGENT,
    checkVisibility: ({ user }) => {
      return canAccessMyAgentsPage(user);
    }
  },
  {
    name: 'Board',
    detailsName: 'Board',
    description: 'Easily manage tasks and keep projects on track.',
    Icon: AppIcons?.boards?.Selected,
    isBeta: true,
    type: LISTING_TYPES.BOARD,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_BOARD,
    checkVisibility: ({ user }) => {
      return !!user?.allowBoards;
    }
  },
  {
    name: 'Report',
    description: 'Automatically generate reports using your online form data.',
    Icon: AppIcons?.reports?.Selected,
    type: LISTING_TYPES.REPORT,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_REPORT
  }
];
