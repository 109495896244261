/* eslint-disable camelcase */
import React, { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@jotforminc/tooltip';
import { t } from '@jotforminc/translation';
import { canCreateTeam } from '../../utils';

type Props = {
  user: {
    isDataOnlyUser: string,
    account_type?: any,
    accountType?: any
  },
  children?: ReactNode
  isTeamCreationDisabled?: boolean,
  tooltipProps?: { attach: string, align: string }
};

const TooltipContainer = styled.div`
  .jfTooltipNew-hoverTarget {
    position: relative;
  }
  .jfTooltipNew-body {
    width: 200px
  }
  button {
    opacity: .5;
  }
`;

export const CreateTeamButton: FunctionComponent<Props> = ({
  user,
  tooltipProps,
  isTeamCreationDisabled = false,
  children = 'Create Team',
  ...buttonProps
}: Props) => {
  const disableMessage = user?.isDataOnlyUser === '1' ? "You don't have permission to create team" : '';
  if (!canCreateTeam(isTeamCreationDisabled, user?.accountType || user?.account_type)) return null;

  return !disableMessage ? (
    <button
      type="button"
      {...buttonProps}
    >
      {children}
    </button>
  ) : (
    <TooltipContainer>
      <div className="jfTooltipNew-hoverTarget">
        <button
          type="button"
          {...buttonProps}
          disabled={true}
        >
          {children}
        </button>
        <Tooltip
          v2
          wrapperClassName="tooltip"
          align="Center"
          attach="Bottom"
          style={{
            backgroundColor: '#141E46'
          }}
          {...tooltipProps}
        >
          {t(disableMessage)}
        </Tooltip>
      </div>
    </TooltipContainer>
  );
};
