import React, { forwardRef } from 'react';
import { StyledModal as Modal } from '@jotforminc/styled-modal';
import {
  bool, elementType, func, string
} from 'prop-types';
import Styled from 'styled-components';
import { getRenderers } from './getRenderers';

const ScWrapper = Styled.div`
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  margin: auto auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .wBox-upper {
    position: absolute;
    left: 0;
    top: 0;
    right: 50px;
    height: 79px;
  }
  
  > div { flex: 1 1 auto; min-height: 0; }

  @media screen and (max-width: 480px) {
    border-radius: 0;
  }
  
 padding: 40px 0;
`;

const AssetBeingEditedModal = forwardRef(({
  onDone,
  doneButtonText,
  IconRenderer,
  ContentRenderer,
  FooterRenderer,
  assetTitle,
  name,
  username,
  avatarURL,
  currentListing,
  headerHasCloseButton,
  ...modalProps
}, ref) => {
  const Renderers = getRenderers(currentListing);

  // Chose your side! Prop or constant!
  const Icon = Renderers.IconRenderer || IconRenderer;
  const Content = Renderers.ContentRenderer || ContentRenderer;

  return (
    <Modal
      {...modalProps}
      ref={ref}
      headerHasCloseButton={false}
      contentRenderer={() => (
        <ScWrapper className="content-wrapper">
          <Icon
            avatarURL={avatarURL}
            currentListing={currentListing}
          />
          <Content
            assetTitle={assetTitle}
            name={name}
            username={username}
            currentListing={currentListing}
            onDone={onDone}
            doneButtonText={doneButtonText}
          />
        </ScWrapper>
      )}
    />
  );
});

AssetBeingEditedModal.propTypes = {
  onDone: func,
  doneButtonText: string,
  IconRenderer: elementType,
  ContentRenderer: elementType,
  FooterRenderer: elementType,
  assetTitle: string,
  name: string,
  username: string,
  avatarURL: string,
  currentListing: string,
  headerHasCloseButton: bool
};

AssetBeingEditedModal.defaultProps = {
  onDone: f => f,
  doneButtonText: '',
  IconRenderer: () => <div />,
  ContentRenderer: () => <div />,
  FooterRenderer: () => <div />,
  assetTitle: '',
  name: '',
  username: '',
  avatarURL: '',
  currentListing: '',
  headerHasCloseButton: false
};

export default AssetBeingEditedModal;
