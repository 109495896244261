import {
  delay, call, put, select, spawn
} from 'redux-saga/effects';

import * as API from '../../api';
import { FEATURE_LIST, ASSET_TYPES } from '../../constants';
import { openCreateNewDocumentModal } from '../../wizards/CreateNewDocumentModal';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { SELECTORS } from '../../store/selectors';
import { registerUniqueAction } from '../utils';
import { renameToastCall, showError } from '../../components/ListItem/toastForItemAction';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

export function* handleDeleteSignDocument({ id }) {
  try {
    yield call(API.deleteSignDocument, id);
    yield put(ACTION_CREATORS.deleteSignDocumentSuccess(id, ASSET_TYPES.SIGN));
  } catch (e) {
    yield put(ACTION_CREATORS.deleteSignDocumentError(id));
  }
}

export function* handleCloneSignDocument({ id }) {
  try {
    yield call(API.cloneSignDocument, id);
    yield put(ACTION_CREATORS.cloneSignDocumentSuccess(id));
    yield put(ACTION_CREATORS.fetchListRequest());
  } catch (e) {
    yield put(ACTION_CREATORS.cloneSignDocumentError(id));
  }
}

export function* handleRenameSignDocument({ id, props }) {
  try {
    yield call(API.updateSignDocument, id, props);
    yield call(renameToastCall);
    yield put(ACTION_CREATORS.renameSignDocumentSuccess(id, props, ASSET_TYPES.SIGN));
  } catch (e) {
    yield call(showError);
    yield put(ACTION_CREATORS.renameSignDocumentError(id));
  }
}

export function* handleCreateWizard({
  toFolder, folderID: folderId, teamProperties, initialView, createAssetFrom, isSideBar, onWizardMount = f => f, targetText = '', isMixPageUser,
  isSideBySideUser, closeButtonCallback, openWizardCallback
}) {
  try {
    const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
    const currentTeamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
    const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
    const userLogAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);

    yield call(openCreateNewDocumentModal, {
      ...(!!currentTeamID && { teamID: currentTeamID }),
      ...(isAssetsFolderActive && !!folderID && { folderID }),
      initialView,
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-sign-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-sign-created`, 'documents', true);
        if (createAssetFrom && userLogAbTestAction) {
          userLogAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-sign-created` });
          userLogAbTestAction({ action: 'click', target: 'actTest-signCreated' });
        }
      },
      onWizardMount,
      isMixAssetCreationModal: isMixPageUser || isSideBySideUser,
      closeButtonCallback,
      openWizardCallback,
      backToMixModalActionLogger: () => WatchmanRecorder.trackEventForCustomProject(
        'click',
        `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-sign-documents`,
        isSideBySideUser ? 'sideBySideModal' : 'mixAssetCreationModal',
        true)
    });
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleSignFavorite(value) {
  try {
    const { id } = value;
    yield delay(500);
    const sign = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.SIGN));
    if (!sign) {
      return false;
    }
    const { favorite } = sign;
    yield call(API.updateSignDocument, id, { favorite: favorite === '1' ? 1 : 0 });
  } catch (e) {
    console.log('error', e);
  }
}

export function* rootDocumentFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleSignFavorite);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_SIGN_DOCUMENT.REQUEST, handleDeleteSignDocument);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_SIGN_DOCUMENT.REQUEST, handleCloneSignDocument);
  yield spawn(registerUniqueAction, ACTION_TYPES.RENAME_SIGN_DOCUMENT.REQUEST, handleRenameSignDocument);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
