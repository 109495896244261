import { setFlagToLocSt, setUserNotificationStatus } from '../utils';

const onConfirm = ({ actionTargetName, localStActionKey }) => {
  setFlagToLocSt(localStActionKey);
  global.location.href = '/mydocuments';

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, localStActionKey, disabledKey }) => {
  setFlagToLocSt(localStActionKey);
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

// sign try
const checkSignTry = notifications => {
  let notification = notifications.find(notif => notif.name === 'signTry');
  const otherNotifications = notifications.filter(notif => notif.name !== 'signTry');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkSignTry;
