import React from 'react';
import {
  shape,
  func,
  string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { ScButtonNv } from './sc/newNotification/scButtonNv';

const ConfirmButtonSecondary = ({
  notification,
  onConfirmSecondary
}) => {
  const { confirmSecondary: { text } = {} } = notification;

  const handleClick = notifcn => {
    onConfirmSecondary(notifcn);
  };

  return (
    <ScButtonNv
      marginLeft="4px"
      lightView={notification.confirmSecondary?.lightView}
      onClick={() => handleClick(notification)}
    >
      {t(text)}
    </ScButtonNv>
  );
};

ConfirmButtonSecondary.propTypes = {
  notification: shape({
    text: string,
    confirm: shape({ text: string })
  }),
  onConfirmSecondary: func
};

ConfirmButtonSecondary.defaultProps = {
  notification: {
    text: '',
    confirm: { text: '' }
  },
  onConfirmSecondary: f => f
};

export default ConfirmButtonSecondary;
