import { useState } from 'react';

const useToggle = defaultValue => {
  const [state, setState] = useState(defaultValue);

  const toggleState = (newState = !state) => setState(newState);

  return [state, toggleState];
};

export default useToggle;
