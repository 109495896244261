/* eslint-disable @jotforminc/no-native-button */
import React, {
  useEffect, useState, useMemo, useRef
} from 'react';
import { t } from '@jotforminc/translation';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { handleCustomNavigation } from '@jotforminc/utils';
import {
  IconGearFilled,
  IconProductFormBuilderColor,
  IconAngleDown,
  IconArrowsSwitchHorizontal,
  IconCheckCircleFilled,
  IconProductAppsColor,
  IconProductApprovalsColor,
  IconProductSignColor,
  IconProductTablesColor,
  IconProductReportBuilderColor,
  IconProductTeamsColor,
  IconProductBoardsColor
} from '@jotforminc/svg-icons';
import { Badge } from '@jotforminc/magnet';

import ReduxFolderListContainer from '../FolderList/ReduxFolderContainer';
import LimitWarnings from '../../components/LimitWarnings';
import HIPAAWarnings from '../../components/HIPAAWarnings';
import Feature from '../Feature';
import ProtectedCreateButton from '../../utils/permissions/ProtectedCreateButton';
import CreateButton from './createButton';

import { FEATURE_LIST, LISTING_TYPES } from '../../constants';
import { canAccessMyAgentsPage } from '../../utils';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import Logo from '../../assets/JotformLogo.svg';
import { TEAM_PERMISSIONS } from '../../utils/permissions';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import IconAgent from '../../assets/agentIconNewPicker.svg';

const isWorkflowReleased = window?.isWorkflowReleased;

const modalList = [
  {
    name: 'Forms',
    Icon: IconProductFormBuilderColor,
    page: LISTING_TYPES.FORM,
    path: '/myforms',
    isDefault: true
  },
  {
    name: 'Apps',
    Icon: IconProductAppsColor,
    page: LISTING_TYPES.PORTAL,
    path: '/myapps'
  },
  {
    name: 'Sign Documents',
    Icon: IconProductSignColor,
    page: LISTING_TYPES.SIGN,
    path: '/mysigndocuments',
    checkVisibility: ({ userCredentials }) => !isEnterprise() || userCredentials?.allowSign
  },
  {
    name: isWorkflowReleased ? 'Workflows' : 'Approvals',
    Icon: IconProductApprovalsColor,
    page: LISTING_TYPES.WORKFLOW,
    path: isWorkflowReleased ? '/myworkflows' : '/myapprovals'
  },
  {
    name: 'Tables',
    Icon: IconProductTablesColor,
    page: LISTING_TYPES.SHEET,
    path: '/mytables'
  },
  {
    name: 'Reports',
    Icon: IconProductReportBuilderColor,
    page: LISTING_TYPES.REPORT,
    path: '/myreports'
  },
  {
    name: 'Boards',
    Icon: IconProductBoardsColor,
    page: LISTING_TYPES.BOARD,
    path: '/myboards',
    checkVisibility: ({ userCredentials }) => !!userCredentials?.allowBoards,
    isBeta: true
  },
  {
    name: 'AI Agents',
    Icon: IconAgent,
    page: LISTING_TYPES.AGENT,
    path: '/myagents',
    checkVisibility: ({ userCredentials }) => canAccessMyAgentsPage(userCredentials),
    isBeta: true
  }
];

const mixPageModalList = [
  {
    name: 'All',
    Icon: IconProductTeamsColor,
    page: LISTING_TYPES.MIX,
    path: '/myworkspace'
  },
  ...modalList.map(appEl => ({ ...appEl, path: `${appEl.path}?preventWorkspaceRedirect=1` }))
];

export default React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const appPickerRef = useRef(null);
  const [isNewAppPickerOpened, setIsNewAppPickerOpened] = useState(false);
  const foldersVisibility = useSelector(SELECTORS.getMobileViewFoldersVisibility);
  const isTeamPage = useSelector(SELECTORS.isTeamPage);
  const teamNotificationCount = useSelector(SELECTORS.getTeamNotificationCount);
  const toggleFoldersVisibility = isVisible => dispatch(ACTION_CREATORS.setMobileViewFoldersVisibility(isVisible));
  const { id: currentTeamID, slug } = useSelector(SELECTORS.getCurrentTeam) || {};
  const isDataOnlyUser = useSelector(SELECTORS.getDataOnlyUser);
  const manageTeam = useSelector(SELECTORS.getTeamPermissionFetcher)(TEAM_PERMISSIONS.MANAGE_TEAM);
  const isMobileDevice = useSelector(SELECTORS.getIsMobileDevice);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const userCredentials = useSelector(SELECTORS.getUserCredentials);
  const appModalList = (userCredentials?.mixedListingBetaUser === '1' || currentPage === LISTING_TYPES.MIX ? mixPageModalList : modalList)
    .filter(listEl => !listEl.checkVisibility || listEl.checkVisibility({ userCredentials }));
  const selectedProduct = appModalList.find(item => item.page === currentPage) || appModalList.find(item => item.isDefault);
  const isNewAppPickerActive = useSelector(SELECTORS.getIsNewAppPickerActive);

  const CreateButtonComponent = useMemo(() => (currentTeamID && !isDataOnlyUser ? CreateButton : ProtectedCreateButton), [currentTeamID, isDataOnlyUser]);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (appPickerRef.current && !appPickerRef.current.contains(event.target)) {
        setIsNewAppPickerOpened(false);
      }
    };

    if (isNewAppPickerActive) {
      WatchmanRecorder.trackEventForCustomProject('seen', `noTestUser-${isMobileDevice ? 'mobile-' : ''}newAppPicker`, 'myformsFormSidebar', true);
      document.addEventListener('click', handleOutsideClick);
    }

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isNewAppPickerActive]);

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector('header').clientHeight;
      const currentScrollY = window.scrollY;
      if (currentScrollY > headerHeight && !document.body.classList.contains('isSidebarFixed')) {
        document.body.classList.add('isSidebarFixed');
        return;
      }
      if (currentScrollY < headerHeight && document.body.classList.contains('isSidebarFixed')) {
        document.body.classList.remove('isSidebarFixed');
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClickToTeamSettings = e => {
    e.preventDefault();
    handleCustomNavigation(`/myaccount/team/${slug || currentTeamID}/team-settings`, '_self');
    WatchmanRecorder.trackEventForCustomProject('click', 'teamPageTeamSettingsButton', 'teams', true);
  };

  const handleClickExpandNewAppPickerOptions = () => {
    setIsNewAppPickerOpened(prev => !prev);
    if (!isNewAppPickerOpened) {
      WatchmanRecorder.trackEventForCustomProject('click', `noTestUser-${isMobileDevice ? 'mobile-' : ''}newAppPicker-opened`, 'myformsFormSidebar', true);
    }
  };

  const handleClickNewAppPickerOptions = path => {
    WatchmanRecorder.trackEventForCustomProject('click', `noTestUser-${isMobileDevice ? 'mobile-' : ''}${path}-newAppPicker`, 'myformsFormSidebar', true);
    handleCustomNavigation(path, '_self', true);
  };

  return (
    <div className={classNames('lsApp-sidebar', { isOpened: foldersVisibility })}>
      <div className={classNames('lsApp-sidebar-content lsApp-sidebar-ls', { isTeamPage })} ref={ref}>
        {isNewAppPickerActive ? (
          <div ref={appPickerRef} className="lsApp-sidebar-ls-wrapper">
            <button
              onClick={handleClickExpandNewAppPickerOptions} className='lsApp-sidebar-ls-button'
              type="button"
            >
              <div className={`lsApp-sidebar-ls-buttonIcon ${selectedProduct.name.toLowerCase()}`}>
                <selectedProduct.Icon />
              </div>
              <div className='lsApp-sidebar-ls-buttonText'>{t(selectedProduct.name)}</div>
              {selectedProduct.isBeta ? <Badge colorStyle='primary' className='text-uppercase' size="small">{t('Beta')}</Badge> : null}
              <div className='lsApp-sidebar-ls-buttonArrow'>
                <IconAngleDown height="100%" width="100%" />
              </div>
            </button>
            {isNewAppPickerOpened && (
            <div className='lsApp-sidebar-ls-modal'>
              <div className='lsApp-sidebar-ls-modalHeader'>
                <IconArrowsSwitchHorizontal />
                {t('SWITCH TO')}
              </div>
              <ul className='lsApp-sidebar-ls-modalList'>
                {appModalList.map(({
                  name, Icon, page, path, isBeta
                }) => (
                  <li className={currentPage === page && 'active'}>
                    <button onClick={() => handleClickNewAppPickerOptions(path)} className='lsApp-sidebar-ls-modalListButton' type='button'>
                      <div className={`lsApp-sidebar-ls-modalListButtonIcon ${name.toLowerCase()}`}>
                        <Icon />
                      </div>
                      <div className='lsApp-sidebar-ls-buttonText'>{t(name)}</div>
                      {isBeta ? <Badge colorStyle='primary' className='text-uppercase' size="small">{t('Beta')}</Badge> : null}
                      <div className='lsApp-sidebar-ls-buttonCheck'>
                        <IconCheckCircleFilled />
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        ) : (
          <div className="lsApp-sidebar-button" data-testid="lsApp-sidebarButton">
            <CreateButtonComponent className="forSidebar" isSideBar={true} />
          </div>
        )}
        <div
          role="navigation"
          aria-label={t('Sidebar Folders')}
          className={classNames('lsApp-sidebar-folders', 'forListings', 'sidebarScroll', { isOpened: foldersVisibility })}
        >
          <div className="lsApp-sidebar-logo">
            {isEnterprise() ? (
              <img className="lsApp-sidebar-logo-img forEnterprise" alt="Logo" src="/enterprise/logo.png" />
            ) : (
              <Logo className="lsApp-sidebar-logo-svg" />
            )}
          </div>
          {isNewAppPickerActive && (
          <div className="lsApp-sidebar-button" data-testid="lsApp-sidebarButton">
            <CreateButtonComponent className="forSidebar" isSideBar={true} />
          </div>
          )}
          <Feature
            name={FEATURE_LIST.LIMIT_WARNING}
            inactiveComponent={null}
            activeComponent={window.limitWarningAlignment === 'left_alt' && (<LimitWarnings />)}
          />
          <Feature
            name={FEATURE_LIST.HIPAA_WARNING}
            inactiveComponent={null}
            activeComponent={window.limitWarningAlignment === 'left_alt' && (<HIPAAWarnings />)}
          />
          <ReduxFolderListContainer />
        </div>
        {foldersVisibility && (
        <div
          onClick={() => toggleFoldersVisibility(false)}
          onKeyUp={() => toggleFoldersVisibility(false)}
          className="sb-mobileOverlay"
        />
        )}
      </div>

      <Feature
        name={FEATURE_LIST.JOTFORM_TEAMS}
        extraRequirements={isTeamPage && manageTeam}
        inactiveComponent={null}
        activeComponent={(
          <div className="lsApp-teamsettings-button">
            {(slug || currentTeamID) && (
              <a onClick={handleClickToTeamSettings} href={`/myaccount/team/${slug || currentTeamID}/team-settings`}>
                <IconGearFilled />
                {t('Team Settings')}
                {teamNotificationCount > 0 && <span>{teamNotificationCount}</span>}
              </a>
            )}
          </div>
        )}
      />
    </div>
  );
});
