/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { IconArrowDownToBracket } from '@jotforminc/svg-icons';
import type { ButtonProps, PolymorphicProps } from '@jotforminc/magnet';
import { Base } from '../ButtonBase';

const ButtonDownloadWithRef = ({ startIcon, children, ...props }: PolymorphicProps<'button', ButtonProps>, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <Base
      ref={ref}
      tabIndex={0}
      colorStyle="primary"
      startIcon={startIcon || IconArrowDownToBracket}
      {...props}
    >
      {children}
    </Base>
  );
};

export const ButtonDownload = forwardRef(ButtonDownloadWithRef);
