/* eslint-disable react/require-default-props */
import React from 'react';
import './PageSection.scss';

type PageSectionProps = {
  header?: JSX.Element;
  className?: string;
};

export const PageSection: React.FC<PageSectionProps> = ({
  header, className = '', children
}) => {
  return (
    <section className={`page-content-section ${className}`}>
      {header && <header className="page-content-header">{header}</header>}
      {children}
    </section>
  );
};
