import qs from 'qs';
import { apiCall } from '../../utils/api';

export const getTeam = (id: string, fetchTeamProperties: boolean, returnMemberRoles: boolean): Promise<any> => {
  const query = qs.stringify({
    ...fetchTeamProperties ? { returnTeamProperties: '1' } : {},
    ...returnMemberRoles ? { returnMembers: '1', returnMembersRoles: '1' } : {}
  });
  return apiCall({ path: `/team/${id}?${query}`, headers: { 'jf-team-id': id }, callback: f => f });
};

export const updateTeamProperties = (id: string, data: { [key: string]: any }): Promise<any> => {
  return apiCall({
    path: `/team/${id}/properties?${qs.stringify(data)}`,
    headers: { 'jf-team-id': id },
    method: 'put',
    callback: f => f
  });
};
