import React, { Component } from 'react';
import {
  node,
  func,
  bool,
  shape,
  string,
  number,
  oneOfType,
  elementType
} from 'prop-types';

import { isParentNode } from '../utils';

import IconDropdownArrow from '../assets/svg/iconDropdownArrow.svg';

import '../styles/style.scss';

/** A button with popover capabilities - Needs refactor */
export class ButtonField extends Component {
  constructor(props) {
    super(props);

    const { options } = props;
    this.state = {
      isDropdownOpen: options.isDropdownOpenInitially || false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  componentDidMount() {
    const { innerRef } = this.props;
    if (typeof innerRef === 'object' && innerRef !== null) {
      innerRef.current = { closeDropdown: this.closeDropdown };
    }
  }

  handleOutsideClick(e) {
    const { options: { forceDropdown = false }, listenerElementClass } = this.props;

    if (!forceDropdown && !isParentNode(this.self, e.target) && this.self !== e.target) {
      this.setState(({ isDropdownOpen }) => ({ isDropdownOpen: !isDropdownOpen }));
      const queryElement = listenerElementClass ? global.document.querySelector(`.${listenerElementClass}`) : global.document.body;
      queryElement?.removeEventListener('mousedown', this.handleOutsideClick);
    }
  }

  handleClick(e) {
    const {
      onClick,
      items,
      options,
      listenerElementClass
    } = this.props;

    const {
      isDropdownOpen
    } = this.state;

    const willBeIsDropdownOpen = !isDropdownOpen;

    if (options.preventBubblingFromChildren && e.target !== this.self) {
      e.stopPropagation();
    } else if (items) {
      this.setState({ isDropdownOpen: willBeIsDropdownOpen }, () => {
        const queryElement = listenerElementClass ? global.document.querySelector(`.${listenerElementClass}`) : global.document.body;
        if (willBeIsDropdownOpen) {
          queryElement?.addEventListener('mousedown', this.handleOutsideClick);
        } else {
          queryElement?.removeEventListener('mousedown', this.handleOutsideClick);
        }
        if (typeof onClick === 'function') {
          onClick(e, willBeIsDropdownOpen);
        }
      });
      e.stopPropagation();
    } else if (typeof onClick === 'function') {
      onClick(e);
    }
  }

  get classNames() {
    const {
      className,
      icon,
      items,
      options,
      children
    } = this.props;

    const {
      isDropdownOpen
    } = this.state;

    return [
      'jfButton',
      className.trim(),
      icon && !options.hideItemsArrow ? 'hasIcon' : null,
      items ? 'hasItems' : null,
      children ? 'hasContent' : null,
      isDropdownOpen ? 'isDropdownOpen' : null
    ].filter(a => a).join(' ');
  }

  closeDropdown() {
    this.setState({ isDropdownOpen: false });
  }

  render() {
    const {
      items,
      icon,
      options,
      children,
      appendChildren,
      onClick,
      onKeyUp,
      useMouseUp,
      tabIndex,
      ...remainingProps
    } = this.props;

    const event = useMouseUp ? {
      onMouseUp: this.handleClick
    } : {
      onClick: this.handleClick
    };

    return (
      <div
        ref={e => { this.self = e; }}
        disabled={!onClick && !items}
        {...remainingProps}
        className={this.classNames}
        onKeyUp={onKeyUp}
        tabIndex={tabIndex}
        role="button"
        {...event}
      >
        {icon && <span className="jfButton-icon">{icon}</span>}
        {children && <span className="jfButton-children">{children}</span>}
        {items && (options && !options.hideItemsArrow) ? (
          <span className="jfButton-dropdownIcon"><IconDropdownArrow /></span>
        ) : null}
        {items && (
          <div className="jfButton-items">
            {items}
          </div>
        )}
        {appendChildren && <span className="jfButton-appendedChildren">{appendChildren}</span>}
      </div>
    );
  }
}

ButtonField.propTypes = {
  children: oneOfType([elementType, node]),
  appendChildren: oneOfType([elementType, node]),
  icon: oneOfType([elementType, node]),
  items: oneOfType([
    oneOfType([elementType, node]),
    bool
  ]),
  options: shape({
    hideItemsArrow: bool,
    forceDropdown: bool,
    isDropdownOpenInitially: bool,
    preventBubblingFromChildren: bool
  }),
  title: string,
  className: string,
  onClick: func,
  useMouseUp: bool,
  onKeyUp: func,
  onMouseEnter: func,
  onMouseLeave: func,
  innerRef: shape({ current: shape({}) }),
  tabIndex: number,
  listenerElementClass: string
};

ButtonField.defaultProps = {
  children: null,
  appendChildren: null,
  icon: null,
  items: null,
  className: '',
  title: '',
  options: {
    hideItemsArrow: false,
    forceDropdown: false
  },
  onClick: null,
  useMouseUp: null,
  onKeyUp: f => f,
  onMouseEnter: f => f,
  onMouseLeave: f => f,
  innerRef: { current: {} },
  tabIndex: -1,
  listenerElementClass: ''
};
