import propTypes from 'prop-types';
import { useFeatureValue } from './useFeatureValue';

const Feature = ({ feature, children, fallback = null }) => {
  const isFeatureAvailable = useFeatureValue(feature);
  if (isFeatureAvailable) return children;
  if (fallback) return fallback;
  return null;
};

Feature.defaultProps = {
  feature: undefined,
  children: null,
  fallback: null
};

Feature.propTypes = {
  feature: propTypes.string,
  children: propTypes.node,
  fallback: propTypes.func
};

export default Feature;
