import React, { FunctionComponent, useMemo } from 'react';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { ButtonField as Button } from '@jotforminc/button-field';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { TeamNoAccess } from './StyledElements';
import { TEAM_ERROR_TEXTS } from '../../utils/constants';

type Props = {
  showTeamNotFoundView?: boolean
};

export const TeamNoAccessView: FunctionComponent<Props> = ({ showTeamNotFoundView }) => {
  const errorTexts = useMemo(() => ({
    h1: showTeamNotFoundView ? TEAM_ERROR_TEXTS.showTeamNotFoundView.h1 : TEAM_ERROR_TEXTS.showNoAccessToTeamView.h1,
    p: showTeamNotFoundView ? TEAM_ERROR_TEXTS.showTeamNotFoundView.p : TEAM_ERROR_TEXTS.showNoAccessToTeamView.p
  }), [showTeamNotFoundView]);
  return (
    <TeamNoAccess>
      <div className="icon">
        <IconExclamationTriangleFilled color="#FFA217" width="70" />
      </div>
      <h1>{t(errorTexts.h1)}</h1>
      <p>{t(errorTexts.p)}</p>
      <div className="btns">
        {!showTeamNotFoundView && (
          <Button
            onClick={() => window.location.reload()}
            className="btn primary"
          >
            {t('Try Again').toUpperCase()}
          </Button>
        )}
        <a
          href="/myforms"
          onClick={e => {
            e.preventDefault();
            handleCustomNavigation('/myforms', '_self', true);
          }}
          className="btn secondary"
        >
          {t('Go Back to My Forms').toUpperCase()}
        </a>
      </div>
    </TeamNoAccess>
  );
};

TeamNoAccessView.defaultProps = {
  showTeamNotFoundView: false
};
