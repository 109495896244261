import momentTimeZone from 'moment-timezone';

const SERVER_TIMEZONE = 'America/New_York';
const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY HH:mm';
const SUBMISSION_DATE_FORMATS = [
  'M/D/YYYY',
  'D/M/YYYY',
  'MMM D, YYYY',
  'MMMM D, YYYY',
  'ddd, MMM D, YYYY',
  'dddd, MMMM D, YYYY',
  'MMM D, YYYY HH:mm',
  DEFAULT_DATE_FORMAT,
  'ddd, MMM D, YYYY HH:mm',
  'dddd, MMMM D, YYYY HH:mm'
];

const localeSymbol = Symbol('locale');
const timezoneSymbol = Symbol('timezone');
const callbackSymbol = Symbol('callback');

class Moment {
  constructor() {
    this[localeSymbol] = 'en';
    this[timezoneSymbol] = null;
    this[callbackSymbol] = {
      setLocale: {},
      setTimezone: {}
    };
  }

  get locale() {
    return this[localeSymbol];
  }

  get timezone() {
    return this[timezoneSymbol];
  }

  get callback() {
    return this[callbackSymbol];
  }

  get formatList() {
    const formatListObject = momentTimeZone.localeData(this.locale)._longDateFormat;
    return formatListObject && Object.keys(formatListObject).length > 0 ? [
      formatListObject.LLLL,
      formatListObject.LLL,
      formatListObject.LL,
      formatListObject.L
    ] : SUBMISSION_DATE_FORMATS;
  }

  setLocale(locale = 'en') {
    this[localeSymbol] = locale;
    Object.values(this.callback.setLocale).forEach(callback => callback());
  }

  setTimezone(timezone) {
    this[timezoneSymbol] = timezone;

    Object.values(this.callback.setTimezone).forEach(callback => callback());
  }

  dateToMoment(date, dateFormat) {
    const dateObject = momentTimeZone(date, dateFormat);

    const dateObjectWithTimezone = this.timezone
      ? dateObject.clone().tz(this.timezone)
      : dateObject.clone().utcOffset(this.serverTimezoneOffset(date));

    return dateObjectWithTimezone.locale(this.locale);
  }

  /**
   * Format date with given parameters
   * @param {Date} date
   * @param {Date} dateFormat ex: 'YYYY-MM-DD HH:mm ZZ'
   * @param {Date} intendedFormat ex: 'MMM D, YYYY h:mm A'
   * @returns Formatted date.
  */
  formatDate(date, dateFormat, intendedFormat) {
    return this.dateToMoment(date, dateFormat).format(intendedFormat);
  }

  /**
   * Format date with given parameters and timezone
   * @param {Date} date
   * @param {Date} format ex: 'YYYY-MM-DD HH:mm ZZ'
   * @param {Date} intendedFormat ex: 'MMM D, YYYY h:mm A'
   * @returns Formatted date according to timezone.
  */
  formatDateWithTimezone(date, format, intendedFormat) {
    const serverTimezoneOffset = this.serverTimezoneOffset(date);
    const timezoneDate = `${date} ${serverTimezoneOffset}`;

    return this.formatDate(timezoneDate, format, intendedFormat);
  }

  serverTimezoneOffset(date) {
    return momentTimeZone(date).tz(SERVER_TIMEZONE).format('Z');
  }

  addListener(methodName, identifier, callback) {
    this.callback[methodName][identifier] = callback;
  }

  removeListener(methodName, identifier) {
    delete this.callback[methodName][identifier];
  }

  getTimeZoneOffsetWithDate(date, timeZone) {
    return momentTimeZone.tz(date, timeZone).format('Z');
  }

  getDateWithTimezone(date = undefined, timeZone, dateFormat) {
    return momentTimeZone(date).tz(timeZone).format(dateFormat);
  }

  convertServerDateToUserTimezone(date, timeZone, dateFormat) {
    return momentTimeZone.tz(date, SERVER_TIMEZONE).tz(timeZone).format(dateFormat);
  }

  /**
   * Create a date from a timestamp of a given
   * source timezone converted to a date of the
   * destination timezone.
   *
   * @param {number} timestamp
   * @param {string} srcTimezone
   * @param {string} destTimezone
   * @returns Date with the destination timezone.
   */
  getDateFromTimeStamp(timestamp, srcTimezone, destTimezone) {
    return new Date(
      momentTimeZone.tz(timestamp, srcTimezone)
        .tz(destTimezone)
        .format('YYYY/MM/DD HH:mm:ss')
    );
  }

  /**
   * Return the timezone adjusted moment object.
   */
  tz(date, timeZone) {
    return momentTimeZone.tz(date, timeZone);
  }

  now() {
    return momentTimeZone();
  }

  /**
   * Returns if the given date string is a valid date.in expected format
   * @param {string} date
   * @param {string} dateFormat
   * @returns {boolean} true if the date is valid, false otherwise
   */
  isValid(date, dateFormat) {
    return momentTimeZone(date, dateFormat).isValid();
  }
}

export const moment = new Moment();
