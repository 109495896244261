/* eslint-disable camelcase */
import { ReactElement, ReactNode } from 'react';
import { ValueOf } from 'type-fest';

export const ASSETS = {
  FORM: 'form',
  SHEET: 'sheet',
  REPORT: 'report',
  WORKFLOW: 'workflow',
  PORTAL: 'portal',
  SIGN: 'sign',
  PDF: 'pdf'
} as const;
export type AssetType = ValueOf<typeof ASSETS>;

export const LOGS = {
  FORM_CREATION: 'formCreation',
  FORM_DELETION: 'formDelete',
  FORM_UPDATE: 'formUpdate',
  FORM_PURGE: 'formPurge',
  FORM_RESTORE: 'formRestore',
  SHEET_CREATED: 'sheetCreated',
  SHEET_UPDATED: 'sheetUpdated',
  SHEET_DELETED: 'sheetDeleted',
  PORTAL_CREATED: 'portalCreated',
  PORTAL_UPDATED: 'portalUpdated',
  PORTAL_DELETED: 'portalDeleted',
  PDF_CREATED: 'pdfCreated',
  PDF_UPDATED: 'pdfUpdated',
  PDF_DELETED: 'pdfDeleted',
  PDF_SHARED: 'pdfShared',
  PDF_SHARE_DELETED: 'pdfShareDeleted',
  REPORT_CREATED: 'reportCreated',
  REPORT_PAGE_CREATED: 'reportPageCreate',
  REPORT_UPDATED: 'reportUpdated',
  REPORT_DELETED: 'reportDeleted',
  WORKFLOW_CREATED: 'workflowCreated',
  WORKFLOW_UPDATED: 'workflowUpdated',
  WORKFLOW_DELETED: 'workflowDeleted',
  DOCUMENT_CREATED: 'documentCreated',
  DOCUMENT_UPDATED: 'documentUpdated',
  DOCUMENT_DELETED: 'documentDeleted',
  AGENT_CREATED: 'agentCreated',
  AGENT_UPDATED: 'agentUpdated',
  AGENT_DELETED: 'agentDeleted',
  BOARD_CREATED: 'boardCreated',
  BOARD_UPDATED: 'boardUpdated',
  BOARD_DELETED: 'boardDeleted',
  SUBMISSION_DELETE_ALL: 'submissionDeleteAll',
  SUBMISSION_DELETE_BATCH: 'submissionDeleteBatch',
  SUBMISSION_PURGE_ALL: 'submissionPurgeAll',
  SUBMISSION_PURGE_BATCH: 'submissionPurgeBatch',
  WEBHOOK_UPDATE: 'webHookUpdate',
  CREATE_TEAM: 'createdTeam',
  ADD_TEAM_MEMBER: 'addedTeamMember',
  ADD_TEAM_MEMBER_VIA_EMAIL_INVITATION: 'addedTeamMemberViaEmailInvitation',
  ADD_CREATED_USER_TEAM_MEMBER_VIA_EMAIL_INVITATION: 'addedCreatedUserTeamMemberViaEmailInvitation',
  ADD_TEAM_MEMBER_VIA_INVITATION_LINK: 'addedTeamMemberViaInvitationLink',
  ADD_TEAM_MEMBER_WHILE_CREATING_TEAM: 'addedTeamMemberWhileCreatingTeam',
  ADD_NEW_CREATED_TEAM_MEMBER_WHILE_CREATING_TEAM: 'addedNewCreatedTeamMemberWhileCreatingTeam',
  REMOVE_TEAM_MEMBER: 'removedTeamMember',
  UPDATE_TEAM_USER_ROLE: 'updatedTeamUserRole',
  UPDATE_TEAM_TITLE: 'updatedTeamName',
  UPDATE_TEAM_AVATAR: 'updatedTeamAvatar',
  UPDATE_TEAM_SLUG: 'updatedTeamSlug',
  UPDATE_TEAM_PRIVACY: 'updatedPrivacy',
  UPDATE_TEAM_COVER_BG_COLOR: 'updatedCoverBgColor',
  UPDATE_TEAM_COVER_TITLE_COLOR: 'updatedCoverTitleColor',
  UPDATE_TEAM_COVER_BG_IMAGE_URL: 'updatedCoverBgImageURL',
  UPDATE_TEAM_AVATAR_URL: 'updatedTeamAvatarURL',
  UPDATE_TEAM_AVATAR_ICON: 'updatedTeamAvatarIcon',
  UPDATE_TEAM_AVATAR_EMOJI_ID: 'updatedTeamAvatarEmojiId',
  UPDATE_TEAM_AVATAR_ICON_SVG_REF: 'updatedTeamAvatarIconSvgRef',
  UPDATE_TEAM_AVATAR_ICON_COLOR: 'updatedTeamAvatarIconColor',
  UPDATE_TEAM_AVATAR_ICON_BG_COLOR: 'updatedTeamAvatarIconBgColor',
  FORM_ASSIGNED: 'formAssigned',
  FORM_UNASSIGNED: 'formUnassigned',
  DELETE_TEAM: 'deletedTeam',
  SIGNUP: 'userCreation',
  USER_DELETE: 'userDelete',
  FORM_SHARE: 'formShare',
  LOGIN: 'userLogin',
  FAILED_LOGIN: 'failedLogin',
  USER_TO_ADMIN: 'enterpriseUserToAdmin',
  ADMIN_TO_USER: 'enterpriseAdminToUser',
  USER_LOCKED: 'userLocked',
  USER_UNLOCKED: 'userUnlocked',
  USER_INVITE_VIA_EMAIL: 'userInviteViaEmail',
  USER_JOIN_VIA_EMAIL: 'userJoinViaEmail',
  USER_JOIN_VIA_INVITE_LINK: 'userJoinViaInviteLink',
  IP_RESTRICTION_ENABLED: 'ipRestrictionEnabled',
  IP_RESTRICTION_DISABLED: 'ipRestrictionDisabled',
  SSO_ENABLED: 'ssoEnabled',
  SSO_DISABLED: 'ssoDisabled',
  SSO_UPDATED: 'ssoUpdated',
  COMPANY_THEME_CHANGED: 'companyThemeChanged',
  COMPANY_LOGO_CHANGED: 'companyLogoChanged',
  COMPANY_FAVICON_CHANGED: 'companyFaviconChanged',
  COMPANY_TITLE_CHANGED: 'companyTitleChanged',
  LOGOUT: 'userLogout',
  USERNAME_CHANGE: 'usernameChange',
  SUBMISSION_DELETE: 'submissionDelete',
  SUBMISSION_PURGE: 'submissionPurge',
  SUBMISSION_EDIT: 'submissionEdit',
  SUBMISSION_DOWNLOAD: 'submissionDownload',
  SUBMISSION_VIEW: 'submissionView',
  EMAIL_CHANGE: 'emailChange',
  EMAIL: 'emails',
  NOTIFICATION: 'notification',
  AUTORESPOND: 'autorespond',
  MOVE_RESOURCE_TO_TEAM: 'movedFromUserToTeam',
  MOVE_RESOURCE_TO_USER: 'movedFromTeamToUser',
  // UPDATED_USER_PRIMARY_METHOD: 'updatedUserPrimaryMethod',
  ENABLED_USER_2FA: 'enabled2FA',
  ENABLED_SERVER_WIDE_2FA: 'enabledServerWide2FA',
  ENABLED_SERVER_WIDE_2FA_FORCE: 'enabledServerWide2FAForce',
  // ENABLED_SERVER_WIDE_PHONE_METHDOD: 'enabledServerWidePhoneMethod',
  DISABLED_SERVER_WIDE_2FA: 'disabledServerWide2FA',
  DISABLED_SERVER_WIDE_2FA_FORCE: 'disabledServerWide2FAForce',
  // DISABLED_SERVER_WIDE_PHONE_METHOD: 'disabledServerWidePhoneMethod',
  ENABLED_SERVER_WIDE_TRUSTED_DEVICE: 'enabledServerWideTrustedDevice',
  DISABLED_SERVER_WIDE_TRUSTED_DEVICE: 'disabledServerWideTrustedDevice',
  DELETED_METHOD: 'deletedMethod',
  DELETED_SETTINGS: 'deletedSettings',
  USE_BACKUP_CODE: 'useBackupCode',
  REGENERATE_BACKUP_CODE: 'reGenerateBackupCode',
  RESET_USER_2FA_SETTINGS: 'resetUserSettings',
  UPDATED_USER_2FA_ENFORCEMENT: 'updatedUserEnforcement',
  APPROVE_TEAM_JOIN_REQUEST: 'approvedTeamJoinRequest',
  DENY_TEAM_JOIN_REQUEST: 'deniedTeamJoinRequest',
  ADDED_TEAM_OAUTH2_SMTP: 'addedTeamOAuth2SMTPConfig',
  UPDATED_TEAM_OAUTH2_SMTP: 'updatedTeamOAuth2SMTPConfig',
  DELETED_TEAM_OAUTH2_SMTP: 'deletedTeamOAuth2SMTPConfig',
  DELETED_REPLACED_TEAM_OAUTH2_SMTP: 'deletedAndReplaceTeamOAuth2SMTPConfig',
  ADDED_TEAM_SMTP: 'addedTeamSMTPConfig',
  UPDATED_TEAM_SMTP: 'updatedTeamSMTPConfig',
  DELETED_TEAM_SMTP: 'deletedTeamSMTPConfig',
  UPDATED_SERVER_SMTP_CONFIG: 'updatedServerSMTPConfig',
  ADDED_SERVER_WIDE_OAUTH2_SMTP_CONFIG: 'addedServerWideSMTPConfig',
  ADDED_SERVER_WIDE_SMTP_CONFIG: 'addedServerWideOAuth2SMTPConfig',
  UPDATED_SERVER_WIDE_OAUTH2_SMTP_CONFIG: 'updatedServerWideOAuth2SMTPConfig',
  DELETED_SERVER_WIDE_OAUTH2_SMTP_CONFIG: 'deletedServerWideOAuth2SMTPConfig',
  UPDATED_SERVER_WIDE_SMTP_CONFIG: 'updatedServerWideSMTPConfig',
  DELETED_SERVER_WIDE_SMTP_CONFIG: 'deletedServerWideSMTPConfig'
} as const;
export type LogType = ValueOf<typeof LOGS>;

export type Log = {
  assetType: AssetType
  creator: string;
  formID: string;
  type: LogType;
  name: string;
  username: string;
  formTitle: string;
  subjectFullName: string;
  subject: string;
  previous: string;
  next: string;
  timestamp: number;
  ip: string;
  userEmail?: string;
  subUserName?: string;
  subuser?: string;
  subUserEmail?: string;
  submissionID?: string;
  email?: string;
  status?: string;
  to?: string;
  id?: string;
  sheetName?: string;
  sheetID?: string;
  portalName?: string;
  portalID?: string;
  reportTitle?: string;
  reportID?: string;
  reportType?: string;
  workflowID?: string;
  workflowName?: string;
  agentID?: string,
  agentTitle?: string,
  boardID?: string,
  boardTitle?: string,
  pdfID?: string;
  pdfTitle?: string;
  documentID?: string;
  documentTitle?: string,
  resource_id?: string,
  resource_title?: string,
  resource_type?: string,
  action_desc?: {
    country_name?: string,
    city?: string
  }
}

export type PrettyLog = Log & { prettyDate: ReactElement, prettyFormat: ReactNode };

export type LogMeta = {
  lastFormsDate?: string;
  lastSubmissionsDate?: string;
  lastTeamsDate?: string;
  lastUsersDate?: string;
}

export type EndpointType = 'team' | 'enterprise';

export const ActivityLogColumns = {
  NAME: 'name',
  ACTIVITY: 'activity',
  IP: 'ip',
  TIMESTAMP: 'timestamp'
} as const;
export type ActivityLogColumnType = ValueOf<typeof ActivityLogColumns>;

export type DateFilter = 'allTime' | 'today' | 'yesterday' | 'last7Days' | 'last30Days' | 'previousWeek' | 'previousMonth' | 'thisYear' | 'previousYear' | 'range';
export type Filter = { date?: Exclude<DateFilter, 'range'>, username?: string, action?: string };
export type CustomFilter = { date: 'range', startDate: string, endDate: string, username?: string, action?: string };
export type FilterType = 'actions' | 'users' | 'date' | 'events' | 'status' | '';

export type LogGroup = { visibleName: string, logs: LogType[], groupId: string, showInRightPanel: boolean };
