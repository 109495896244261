import Styled from 'styled-components';

export const ScNotificationBellOutline = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  background: ${({ notificationCount }) => (notificationCount >= 1 ? 'transparent' : '#F8FBFF')};
  margin: 0;
  transition: background .2s;
  cursor: pointer;
  overflow: visible;
  &>svg{
    width: 32px;
    height: 32px;
    opacity: ${({ notificationCount }) => (notificationCount >= 1 ? '1' : '0')};

    path{
      fill: ${({ notificationCount }) => (notificationCount >= 1 ? '#F38632' : '#F8FBFF')}
    }
  }
`;
