import React from 'react';
import {
  shape, arrayOf, func, bool, number
} from 'prop-types';
import Scrollbar from 'react-smooth-scrollbar';
import { t } from '@jotforminc/translation';
import { ScNotificationListScroll } from './sc/scNotificationListScroll';
import { ScNotificationList } from './sc/scNotificationList';
import { ScNotificationEmpty } from './sc/scNotificationEmpty';
import HipaaNotification from './HipaaNotification';
import Notification from './Notification';
import NotificationNewVersionRenderer from './NotificationNewVersionRenderer';

const ListBody = ({
  user,
  notifications,
  notificationCount,
  setAutoVisibleNotificationClosed,
  isAutoVisibleNotfInteracted,
  isAutoVisibleNotificationMode,
  onConfirm,
  onConfirmSecondary,
  onDismiss
}) => {
  if (notificationCount === 0) {
    return <ScNotificationEmpty>{t('No new notifications')}</ScNotificationEmpty>;
  }

  return (
    <ScNotificationListScroll>
      <Scrollbar continuousScrolling={false}>
        <ScNotificationList>
          {notifications.map(notification => {
            const NotificationType = notification.name === 'hipaaCompliance' ? HipaaNotification : Notification;

            return (
              <NotificationType
                key={notification.name}
                user={user}
                notification={notification}
                NotificationRenderer={NotificationNewVersionRenderer}
                isAutoVisibleNotificationMode={isAutoVisibleNotificationMode}
                isAutoVisibleNotfInteracted={isAutoVisibleNotfInteracted}
                setAutoVisibleNotificationClosed={setAutoVisibleNotificationClosed}
                onDismiss={onDismiss}
                onConfirm={onConfirm}
                onConfirmSecondary={onConfirmSecondary}
              />
            );
          })}
        </ScNotificationList>
      </Scrollbar>
    </ScNotificationListScroll>
  );
};

ListBody.propTypes = {
  notifications: arrayOf(shape()),
  notificationCount: number,
  user: shape({}),
  onConfirm: func,
  onConfirmSecondary: func,
  onDismiss: func,
  isAutoVisibleNotfInteracted: bool,
  isAutoVisibleNotificationMode: bool,
  setAutoVisibleNotificationClosed: func
};

ListBody.defaultProps = {
  notifications: [],
  notificationCount: 0,
  user: {},
  onConfirm: f => f,
  onConfirmSecondary: f => f,
  onDismiss: f => f,
  isAutoVisibleNotfInteracted: false,
  isAutoVisibleNotificationMode: false,
  setAutoVisibleNotificationClosed: f => f
};

export default ListBody;
