import orderByFn from 'lodash/orderBy';

const sortFormsOnClient = (sortCriteria, forms) => {
  switch (sortCriteria) {
    case 'title_az':
      return orderByFn(forms, ['title'], 'asc');
    case 'title_za':
      return orderByFn(forms, ['title'], 'desc');
    case 'last_submission':
      return orderByFn(forms, [i => new Date(i[sortCriteria])], 'desc');
    case 'count':
    case 'new':
      return orderByFn(forms, i => parseInt(i[sortCriteria], 10), 'desc');
    default: {
      return orderByFn(forms, [sortCriteria], 'desc');
    }
  }
};

const generateResponse = (fetchQuery, formDataSource) => {
  const folderDataSource = window?.__temp__sharedWithMeResources?.folders || [];
  const searchTerm = fetchQuery?.filter?.fullText;
  return {
    data: {
      content: {
        forms: sortFormsOnClient(
          fetchQuery.orderby,
          searchTerm
            ? window.__temp__sharedwithMeFormsFuse.search(`'${fetchQuery.filter.fullText}`).map(f => f.item)
            : formDataSource
        ),
        folders: folderDataSource
      }
    }
  };
};

export const querySharedwithMeResults = fetchQuery => {
  const formDataSource = window?.__temp__sharedWithMeResources?.forms || [];
  return new Promise(resolve => resolve(generateResponse(fetchQuery, formDataSource)));
};

export const querySharedWithMeResultsByUsername = username => fetchQuery => {
  const dataSource = window?.__temp__sharedWithMeResources?.forms || [];
  const userForms = dataSource.filter(({ owner, username: formUsername }) => owner === username || formUsername === username);
  return new Promise(resolve => resolve(generateResponse(fetchQuery, userForms)));
};
