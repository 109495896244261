import React, { useEffect } from 'react';
import { func } from 'prop-types';
import {
  CollaborationModal, ALL_TEXTS, ASSET_IDENTIFIER, GA_FORM_TRACKING_ITEMS, userPropType,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/ep-utils';

// A/B Test: epShareFolderModal
const ShareFolderModal = ({
  user,
  onClose,
  logAbTestAction
}) => {
  const { AB_TEST: { SHARE_FOLDER_MODAL } } = ASSET_IDENTIFIER;
  const target = SHARE_FOLDER_MODAL;
  let { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    const actionData = { action: 'seen', target };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleCloseClick = () => {
    const actionData = { action: 'click', target: `closeButton-${target}` };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleClose = () => {
    const actionData = { action: 'close', target };
    gaDataLayerPushClose(gaAttributes);
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleApplyNowClick = () => {
    const actionData = { action: 'click', target: `applyNowButton-${target}` };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target: 'applyNowButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    const actionData = { action: 'formSubmit', target };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'formSubmit' });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleApplyNowClick]);

  return (
    <CollaborationModal
      user={user}
      gaAttributes={gaAttributes}
      gaAttributesClose={gaAttributesClose}
      formUiModifier={SHARE_FOLDER_MODAL}
      gaFormTrackingItems={gaFormTrackingItems}
      description={ALL_TEXTS.APPLY_FOR_ENTERPRISE_ORGANIZATION}
      backgroundImageUrl='//cdn.jotfor.ms/assets/img/enterprise/assign-to-organization-modal/visual.png'
      onClose={handleClose}
      onCloseClick={handleCloseClick}
      onFormSubmit={handleFormSubmit}
    />
  );
};

ShareFolderModal.propTypes = {
  user: userPropType.isRequired,
  onClose: func,
  logAbTestAction: func
};

ShareFolderModal.defaultProps = {
  onClose: f => f,
  logAbTestAction: f => f
};

export default ShareFolderModal;
