const thatBlue = '#0A1551';
const thatGreen = '#01bd6f';
const someGray = '#dfdfe3';
const kindaGray = '#F8F8FB';

const fontStack = 'system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif';

const colorVars = {
  backgroundColor: {
    dark: '#262a35',
    light: '#fff'
  },
  color: {
    dark: '#fff',
    light: '#000'
  },
  headerBgColor: {
    dark: thatBlue,
    light: thatBlue
  },
  sidebarBgColor: {
    dark: 'rgba(223, 223, 227, 0.05)',
    light: '#f7f7f8'
  },
  headerMenuTextColor: '#fff',
  listItemBorder: {
    dark: '#343742',
    light: '#efefef'
  },
  listItemBorderHover: {
    dark: '#ccd0da',
    light: '#CCD0DA'
  },
  listItemBgHover: {
    dark: 'rgba(255,255,255,.1)',
    light: '#F8F8FB'
  },
  listItemBoxShadow: {
    dark: 'none',
    light: '0 0 0 0 rgba(204,205,210,0)'
  },
  listItemBoxShadowHover: {
    dark: 'none',
    light: '0 3px 8px 0 rgba(204,205,210,0.44)'
  },
  listItemTitle: {
    dark: '#e2e2e2',
    light: thatBlue
  },
  listItemDesc: {
    dark: '#6F76A7',
    light: '#6F76A7'
  },
  listItemActionLinks: {
    dark: '#e2e2e2',
    light: thatBlue
  },
  folderName: {
    dark: '#e2e2e2',
    light: thatBlue
  },
  someGray: someGray,
  inputBorderColor: {
    dark: someGray,
    light: someGray
  },
  inputBorderColorFocus: {
    dark: '#2f90ff',
    light: '#2f90ff'
  },
  green: thatGreen,
  gray: kindaGray,
  blue: thatBlue,
  noForms: {
    dark: '#5c5b71',
    light: '#5c5b71'
  }
};

const standalone = {
  headerHeight: '70px',
  sidebarWidth: '300px',
  buttonRadius: '3px',
  inputRadius: '4px',
  listItemBorderRadius: '4px',
  fixedAreasHeight: '64.25px',
  defaultTransition: `
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.13, 0.89, 0.82, 1);
  `,
  ellipsis: `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  fontFamily: `Circular, ${fontStack}`
};

const colorVarsKeys = Object.keys(colorVars);
const colorVarsValues = Object.values(colorVars);

const darkThemeColors = {};
const lightThemeColors = {};

colorVarsKeys.forEach((i, index) => {
  if ((typeof colorVarsValues[index] === 'object')
    && Object.prototype.hasOwnProperty.call(colorVarsValues[index], 'dark')) {
    darkThemeColors[i] = colorVarsValues[index].dark;
    lightThemeColors[i] = colorVarsValues[index].light;
  } else {
    darkThemeColors[i] = colorVarsValues[index];
    lightThemeColors[i] = colorVarsValues[index];
  }
});

export const breakpoints = {
  mobile: 375,
  tablet: 768
};

export const mediaQueries = {
  isMobile: `(max-width: ${breakpoints.mobile}px)`,
  isTablet: `(max-width: ${breakpoints.tablet}px)`
};

const darkTheme = Object.assign(darkThemeColors, standalone, { mediaQueries });
const lightTheme = Object.assign(lightThemeColors, standalone, { mediaQueries });

export { darkTheme, lightTheme };
