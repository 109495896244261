import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

import WatchmanRecorder from '../utils/WatchmanRecorder';

const CreateNewPageWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewPageWizard" */'./LazyWizards/LazyCreateNewPageWizard'));

export const openCreateNewPageModal = props => {
  return new Promise(resolve => {
    const containerSelector = '#modal-container';
    const root = document.querySelector(containerSelector);
    const onClickListItem = (item = '') => {
      WatchmanRecorder.trackEvent(`click-${item}-in-wizard`);
    };
    const handleClose = () => {
      resolve();
      unmountComponentAtNode(root);
    };

    lazyRender({
      root,
      children: (
        <CreateNewPageWizard
          {...props}
          user={window.user}
          onHide={handleClose}
          initialVisibilityState={true}
          onClickListItem={onClickListItem}
        />
      )
    });
  });
};
