import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef
} from 'react';
import { t } from '@jotforminc/translation';
import { Hooks } from '@jotforminc/uikit';
import CustomizeTeamAvatar from './CustomizeTeamAvatar';
import CustomizeCover from './CustomizeCover';
import { TeamProps } from '../../types';

type Props = {
  teamId?: string,
  type?: string,
  buttonClass?: string,
  buttonStyles?: { [key: string]: any },
  onVisibilityChange?: (isCustomizationVisible: boolean) => void,
  isVisible?: boolean,
  children?: ReactNode,
  coverBgColor?: string,
  coverTitleColor?: string,
  coverBgImageURL?: string,
  teamAvatarURL?: string,
  teamAvatarIcon?: string,
  teamAvatarIconColor?: string,
  teamAvatarIconBgColor?: string,
  setTeam?: (f: (TeamProps | ((currTeam: TeamProps) => TeamProps))) => void,
  onUpdate?: (newData: TeamProps) => void,
  notUpdateOnSelection?: boolean,
  popoverProps?: { [key: string]: any },
  useOnUpdateProp?: boolean
};

export const CustomizationButton: FunctionComponent<Props> = ({
  type,
  buttonClass,
  buttonStyles,
  children,
  onVisibilityChange = f => f,
  isVisible,
  ...otherProps
}) => {
  const ActiveComponent = type === 'cover' ? CustomizeCover : CustomizeTeamAvatar;
  const popoverRef = useRef<FunctionComponent>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isCustomizationVisible, setCustomizationVisibility] = Hooks.useClickOutsideStateMouseDown(!!isVisible, [popoverRef, buttonRef]);

  useEffect(() => {
    onVisibilityChange(isCustomizationVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomizationVisible]);

  useEffect(() => {
    setCustomizationVisibility(!!isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <>
      <button
        className={buttonClass}
        style={buttonStyles}
        ref={buttonRef}
        type="button"
        onClick={() => setCustomizationVisibility(true)}
        data-testid={`customization-button-${type}`}
        aria-label={t('Customization')}
      >
        {children}
      </button>
      {isCustomizationVisible && <ActiveComponent {...otherProps} ref={popoverRef} buttonRef={buttonRef} />}
    </>
  );
};

CustomizationButton.defaultProps = {
  teamId: '',
  type: '',
  buttonClass: '',
  buttonStyles: {},
  onVisibilityChange: f => f,
  isVisible: false,
  children: null,
  coverBgColor: '',
  coverTitleColor: '',
  coverBgImageURL: '',
  teamAvatarURL: '',
  teamAvatarIcon: '',
  teamAvatarIconColor: '',
  teamAvatarIconBgColor: '',
  setTeam: f => f,
  onUpdate: f => f,
  notUpdateOnSelection: false,
  popoverProps: undefined,
  useOnUpdateProp: false
};
