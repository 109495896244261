import PropTypes from 'prop-types';
import PopoverOption from '../PopoverOption';

export const ListItemSharedPropTypes = {
  hasMoreMenu: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  mainActions: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape()),
  setResourceID: PropTypes.func,
  currentPage: PropTypes.string,
  assetFilterType: PropTypes.string,
  isTeamAsset: PropTypes.bool,
  team: PropTypes.shape({ name: PropTypes.string, teamAvatarURL: PropTypes.string }),
  isSentboxDisabled: PropTypes.bool,
  currentTeamID: PropTypes.string,
  isMixStructureEnabled: PropTypes.bool
};

/* eslint react/prop-types: "off" */
export const ListItemSharedDefaultProps = {
  hasMoreMenu: false,
  isSelected: false,
  isDisabled: false,
  mainActions: [],
  title: 'List Item',
  description: '',
  tags: [],
  PopoverOptionRenderer: PopoverOption,
  PopoverProps: {
    popoverOptions: {
      placement: 'bottom-end'
    },
    className: 'listings-popover-modal'
  },
  SubmenuPopoverProps: {
    popoverOptions: {
      placement: 'left-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-13, 50]
          }
        }
      ]
    }
  },
  PopoverModifierProps: {},
  setResourceID: f => f,
  currentPage: '',
  assetFilterType: '',
  isTeamAsset: false,
  team: {},
  isSentboxDisabled: false,
  currentTeamID: '',
  isMixStructureEnabled: false
};

export const ListItemPropTypes = {
  ...ListItemSharedPropTypes
};

export const ListItemDefaultProps = {
  ...ListItemSharedDefaultProps,
  isDraggable: false
};

export const MORE_POPOVER_OPTS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions
  },
  usePortal: true,
  portalContainerSelector: '#listing-portal-root',
  className: 'listings-popover-modal isMoreMenu'
};

export const SUBMENU_POPOVER_OPTIONS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  ...ListItemSharedDefaultProps.SubmenuPopoverProps,
  className: 'listings-popover-modal isMoreMenu isMoreSubMenu',
  popoverOptions: {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 18]
        }
      }
    ]
  }
};
