import React, {
  useState, useEffect, FunctionComponent, useMemo
} from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { t } from '@jotforminc/translation';
import { IconUserFilled } from '@jotforminc/svg-icons';
import RangeSelector from '../../RangeSelector';
import FilterDropdowns from '../../FilterDropdowns';
import RightPanelFilters from './RightPanelFilters';
import {
  CustomFilter, DateFilter, Filter, FilterType, LogGroup
} from '../types';
import { ACTIVITY_LOGS_DEFAULT_DATE_RANGE } from '../constants';
import { PrettyUser } from '../../../types';

type Props = {
  type?: string,
  handleFilterChange?: (filter: Filter | CustomFilter) => void,
  filters?: Filter | CustomFilter
  count?: { [key: string]: string }
  logGroups: LogGroup[],
  filterUsers: PrettyUser[],
  userLanguage?: string
};

const UserIcon = <IconUserFilled color="#FFFFFF" width="15" />;

const ActivityLogFilters: FunctionComponent<Props> = ({
  filters = {}, type, handleFilterChange = () => {}, count, logGroups, filterUsers, userLanguage
}: Props) => {
  let startDate: string | undefined;
  let endDate: string | undefined;
  if (filters.date === 'range') {
    startDate = filters?.startDate;
    endDate = filters?.endDate;
  }
  const {
    username: userState, action: actionsState, date: dateState
  } = filters;

  const isRightPanel = type === 'right-panel';
  const initialDateFilter: DateFilter = ACTIVITY_LOGS_DEFAULT_DATE_RANGE;
  const [dateFilter, setDateFilter] = useState<{ date: Exclude<DateFilter, 'range'> | { start: string, end: string }}>({ date: initialDateFilter });
  const [userFilter, setUsersFilter] = useState<string | null>(null);
  const [actionFilter, setActionsFilter] = useState<string | null>(null);
  const [firstRender, setFirstRender] = useState(true);
  const initState = [null, null, initialDateFilter] as const;

  const userFilterObj = useMemo(() => {
    const usersObject = sortBy(filterUsers, user => user.name && user.name.toLowerCase());
    const usernames = usersObject.map(user => user.username);

    return {
      user: {
        filterType: 'users' as FilterType,
        options: usernames,
        optionsMetadata: filterUsers
      },
      userOptionsIcons: usernames.reduce((acc, currUsername) => ({ ...acc, [currUsername]: UserIcon }), { allIcon: UserIcon })
    };
  }, [filterUsers]);

  const actions = {
    filterType: 'actions' as FilterType,
    options: logGroups.map(lg => lg.groupId)
  };

  const updateFilters = (value?: string | { start?: string, end?: string } | null, filterType?: FilterType) => {
    /* eslint-disable no-param-reassign */
    if (!value) {
      value = null;
    }
    switch (filterType) {
      case 'actions': setActionsFilter(value as string);
        break;
      case 'users': setUsersFilter(value as string);
        break;
      case 'date': setDateFilter({ date: value as NonNullable<{ start: string, end: string }> });
        break;
      default:
    }
  };

  useEffect(() => {
    if (!firstRender) {
      let filterObj = {};
      if (actionFilter) {
        filterObj = { ...filterObj, action: actionFilter };
      }
      if (userFilter) {
        filterObj = { ...filterObj, username: userFilter };
      }
      if (dateFilter) {
        const rangeType = dateFilter.date;
        const dateRange = typeof rangeType === 'string' ? { date: rangeType } : { date: 'range', startDate: rangeType.start, endDate: rangeType.end };
        filterObj = { ...filterObj, ...dateRange };
      }
      handleFilterChange(filterObj);
    } else {
      const didRendered = isEqual(initState, [userFilter, actionFilter, dateFilter.date]);
      setFirstRender(didRendered);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter, actionFilter, dateFilter, firstRender]);

  useEffect(() => {
    if (dateState !== dateFilter.date) {
      const range = dateState === 'range' ? { start: startDate, end: endDate } : dateState;
      updateFilters(range, 'date');
    }
    if (actionsState !== actionFilter) {
      updateFilters(actionsState, 'actions');
    }

    if (userState !== userFilter) {
      updateFilters(userState, 'users');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState, startDate, endDate, actionsState, userState]);

  return (
    isRightPanel ? (
      <div className="forRightPanelFilters">
        <RangeSelector
          value={dateFilter.date}
          forFilters={true}
          disableFuture={true}
          showTimeFilterText={true}
          customDateRangeValue={typeof dateFilter.date === 'string' ? {} : dateFilter.date}
          onChange={updateFilters}
          showOnlyCustomText={true}
          userLanguage={userLanguage}
        />
        <RightPanelFilters
          value={actions}
          onChange={updateFilters}
          actionFilter={actionFilter}
          count={count || {}}
          logGroups={logGroups}
        />
      </div>
    ) : (
      <div className="forActivityLogFilters">
        <RangeSelector
          value={dateFilter.date}
          forFilters={true}
          disableFuture={true}
          showTimeFilterText={true}
          customDateRangeValue={typeof dateFilter.date === 'string' ? {} : dateFilter.date}
          onChange={updateFilters}
          userLanguage={userLanguage}
        />
        <FilterDropdowns
          value={userFilterObj.user}
          selectedOption={userFilter}
          searchable={true}
          onChange={updateFilters}
          logGroups={logGroups}
          optionIcons={userFilterObj.userOptionsIcons}
          ariaLabel={t('User Filter Dropdown')}
        />
        <FilterDropdowns
          selectedOption={actionFilter}
          value={actions}
          onChange={updateFilters}
          logGroups={logGroups}
          ariaLabel={t('Action Filter Dropdown')}
          firstItemName="All Activities"
        />
      </div>
    )
  );
};

ActivityLogFilters.defaultProps = {
  type: '',
  handleFilterChange: () => {},
  filters: {},
  count: {},
  userLanguage: ''
};

export default ActivityLogFilters;
