import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import DiscountBadge from '../assets/campaigns/2024/summer-camp/discount.svg';
import './styles/summer-camp.scss';

export const SummerCamp = ({ utmSource, accountTypeName }) => {
  return (
    <section className="summer-camp-wrapper">
      <div className="summer-camp-container">
        <div className='discount-badge'>
          <DiscountBadge />
        </div>
        <div className="text-container">
          <h2>
            {translationRenderer('SAVE [1[50%]]')({
              renderer1: text => <strong>{text}</strong>
            })}
          </h2>
          <h4>
            {translationRenderer('on Jotform for your [1[summer camp]] business')({
              renderer1: text => <strong>{text}</strong>
            })}
          </h4>
        </div>

        <a href={`/pricing/?utm_campaign=summercamp&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`} target="_blank" className="upgrade-now-button">
          {t('Get discount')}
        </a>
      </div>
    </section>
  );
};

SummerCamp.propTypes = {
  utmSource: string.isRequired,
  //   expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderSummerCamp = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<SummerCamp utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
