import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/spring-sale-header-banner.scss';

export const SpringSaleHeaderBanner = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="jf-spring-sale-wrapper">
        <div className="jf-spring-sale-wrapper-container">
          <div className="images">
            <img
              className="img-flowers"
              src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/flowers.svg"
              alt="Flowers"
            />
            <img
              className="img-bee"
              src="https://cdn01.jotfor.ms/assets/img/campaigns/2024/spring-sale/flyin/bee.gif"
              alt="Bee"
            />
          </div>
          <div className="text-area">
            <h6>{t('SUMMER SALE')}</h6>
            <h4>
              {translationRenderer('SAVE [1[50%]]')({
                renderer1: text => <strong>{text}</strong>
              })}
            </h4>
          </div>
          <a
            className="action-link locale"
            target="_blank"
            href={`/pricing/?utm_campaign=summerSale-2024&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
          >
            {t('Upgrade now')}
          </a>
        </div>
      </div>
    </>
  );
};

SpringSaleHeaderBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderSpringSaleHeaderBanner = ({
  container,
  accountTypeName,
  utmSource,
  username
}) => {
  const root = createRoot(container);
  root.render(
    <SpringSaleHeaderBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
