import {
  all, spawn, call, put, delay, select
} from 'redux-saga/effects';
import { handleCustomNavigation } from '@jotforminc/utils';

import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import * as API from '../../api';
import { openCreateNewPageModal } from '../../wizards/CreateNewPageModal';
import { SELECTORS } from '../../store/selectors';
import { ASSET_TYPES } from '../../constants';

function* handleCreatePage() {
  try {
    yield call(openCreateNewPageModal);
  } catch (e) {
    console.log('error:', e);
  }
}

function* handleClonePage({ id }) {
  try {
    const { homepageID } = yield call(API.clonePage, id);
    if (!homepageID) {
      yield put(ACTION_CREATORS.cloneItemError(id));
    }
    handleCustomNavigation(`/page/build/${homepageID}`, '_blank');
  } catch (error) {
    yield put(ACTION_CREATORS.cloneItemError(id));
  }
}

function* handleRestorePage({ id }) {
  yield all([call(API.updatePageStatus, id, 'ENABLED'), put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.PAGE))]);
}

function* handleTrashPage({ id }) {
  yield all([call(API.updatePageStatus, id, 'TRASHED'), put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.PAGE))]);
}

function* handlePageFavorite({ id }) {
  yield delay(500);
  const page = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.PAGE));
  if (!page) {
    return false;
  }
  const { properties: { favorite = '0' } } = page;
  yield call(API.favoritePageForUser, id, favorite === '0' ? '1' : '0');
}

export function* rootPageFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreatePage);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_ITEM.REQUEST, handleClonePage);
  yield spawn(registerUniqueAction, ACTION_TYPES.RESTORE_ITEM, handleRestorePage);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_PAGE, handleTrashPage);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handlePageFavorite);
}
