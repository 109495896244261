import { translationRenderer } from '@jotforminc/translation';
import React, {
  ElementType, ReactElement, ReactNode
} from 'react';
import { handleCustomNavigation, prettyDate } from '@jotforminc/utils';
import type { PrettyDateReturnValue } from '@jotforminc/utils';
import {
  ScFormIcon, ScSheetIcon, ScReportIcon, ScAppsIcon, ScPDFIcon, ScWorkflowIcon, ScSignDocumentIcon, ScAgentIcon, ScBoardIcon, VISIBLE_NAMES, USER_HISTORY_VISIBLE_NAMES, LOG_GROUPS
} from './constants';
import {
  Log, LogGroup, LOGS, LogType, PrettyLog
} from './types';

export const possessive = (str = ''): ReactNode => {
  if (!str) return '';
  const lastChar = str.slice(-1);
  return lastChar.toLowerCase() === 's' ? "'" : "'s";
};

// NOTE: Placeholders with curly braces must match with the corresponding renderer key in 'renderers' object
export const TEMPLATES: { [key: string]: { sentenceFormat: string, tableFormat: string } } = {
  [LOGS.LOGIN]: {
    sentenceFormat: '{user} logged in.',
    tableFormat: 'Logged in'
  },
  [LOGS.FAILED_LOGIN]: {
    sentenceFormat: '{user} failed to login.',
    tableFormat: 'Failed login attempt'
  },
  [LOGS.USER_TO_ADMIN]: {
    sentenceFormat: '{user} upgraded {possessiveSubuser} role to admin.',
    tableFormat: 'Upgraded {possessiveSubuser} role to admin'
  },
  [LOGS.ADMIN_TO_USER]: {
    sentenceFormat: '{user} downgraded {possessiveSubuser} role to user.',
    tableFormat: 'Downgraded {possessiveSubuser} role to user'
  },
  [LOGS.USER_UNLOCKED]: {
    sentenceFormat: '{user} unlocked {possessiveSubuser} account.',
    tableFormat: 'Unlocked {possessiveSubuser} account'
  },
  [LOGS.USER_LOCKED]: {
    sentenceFormat: '{user} locked {possessiveSubuser} account.',
    tableFormat: 'Locked {possessiveSubuser} account'
  },
  [LOGS.IP_RESTRICTION_ENABLED]: {
    sentenceFormat: '{user} enabled IP restriction.',
    tableFormat: 'Enabled IP restriction'
  },
  [LOGS.IP_RESTRICTION_DISABLED]: {
    sentenceFormat: '{user} disabled IP restriction.',
    tableFormat: 'Disabled IP restriction'
  },
  [LOGS.SSO_ENABLED]: {
    sentenceFormat: '{user} enabled SSO.',
    tableFormat: 'Enabled SSO'
  },
  [LOGS.SSO_DISABLED]: {
    sentenceFormat: '{user} disabled SSO.',
    tableFormat: 'Disabled SSO'
  },
  [LOGS.SSO_UPDATED]: {
    sentenceFormat: '{user} updated SSO settings.',
    tableFormat: 'Updated SSO settings'
  },
  [LOGS.COMPANY_THEME_CHANGED]: {
    sentenceFormat: '{user} changed company theme.',
    tableFormat: 'Changed company theme'
  },
  [LOGS.COMPANY_LOGO_CHANGED]: {
    sentenceFormat: '{user} changed company logo.',
    tableFormat: 'Changed company logo'
  },
  [LOGS.COMPANY_FAVICON_CHANGED]: {
    sentenceFormat: '{user} changed company favicon.',
    tableFormat: 'Changed company favicon'
  },
  [LOGS.COMPANY_TITLE_CHANGED]: {
    sentenceFormat: '{user} changed company title.',
    tableFormat: 'Changed company title'
  },
  [LOGS.LOGOUT]: {
    sentenceFormat: '{user} logged out.',
    tableFormat: 'Logged out'
  },
  [LOGS.SIGNUP]: {
    sentenceFormat: '{user} created your account.',
    tableFormat: 'Created your account'
  },
  [LOGS.USER_DELETE]: {
    sentenceFormat: '{user} deleted {deletedUser} user',
    tableFormat: 'Deleted {deletedUser} user'
  },
  [LOGS.USER_INVITE_VIA_EMAIL]: {
    sentenceFormat: '{user} invited {subuser} to the organization.',
    tableFormat: 'Invited {subuser} to the organization'
  },
  [LOGS.USER_JOIN_VIA_EMAIL]: {
    sentenceFormat: '{user} joined the organization via email.',
    tableFormat: 'Joined the organization'
  },
  [LOGS.USER_JOIN_VIA_INVITE_LINK]: {
    sentenceFormat: '{user} joined the organization via invite link.',
    tableFormat: 'Joined the organization'
  },
  [LOGS.FORM_CREATION]: {
    sentenceFormat: '{user} created. {form}',
    tableFormat: 'Created {form}'
  },
  [LOGS.FORM_UPDATE]: {
    sentenceFormat: '{user} updated. {form}',
    tableFormat: 'Updated {form}'
  },
  [LOGS.FORM_ASSIGNED]: {
    sentenceFormat: '{user} assigned. {form}.',
    tableFormat: 'Assigned {form}'
  },
  [LOGS.FORM_DELETION]: {
    sentenceFormat: '{user} deleted. {formWithoutLink}',
    tableFormat: 'Deleted {formWithoutLink}'
  },
  [LOGS.FORM_RESTORE]: {
    sentenceFormat: '{user} restored. {form}.',
    tableFormat: 'Restored {form}'
  },
  [LOGS.FORM_PURGE]: {
    sentenceFormat: '{user} purged. {formWithoutLink}',
    tableFormat: 'Purged {formWithoutLink}'
  },
  [LOGS.SHEET_CREATED]: {
    sentenceFormat: '{user} created. {sheet}',
    tableFormat: 'Created {sheet}'
  },
  [LOGS.SHEET_UPDATED]: {
    sentenceFormat: '{user} updated. {sheet}',
    tableFormat: 'Updated {sheet}'
  },
  [LOGS.SHEET_DELETED]: {
    sentenceFormat: '{user} deleted. {sheetWithoutLink}',
    tableFormat: 'Deleted {sheetWithoutLink}'
  },
  [LOGS.PORTAL_CREATED]: {
    sentenceFormat: '{user} created. {portal}',
    tableFormat: 'Created {portal}'
  },
  [LOGS.PORTAL_UPDATED]: {
    sentenceFormat: '{user} updated. {portal}',
    tableFormat: 'Updated {portal}'
  },
  [LOGS.PORTAL_DELETED]: {
    sentenceFormat: '{user} deleted. {portalWithoutLink}',
    tableFormat: 'Deleted {portalWithoutLink}'
  },
  [LOGS.PDF_CREATED]: {
    sentenceFormat: '{user} created. {pdf}',
    tableFormat: 'Created {pdf}'
  },
  [LOGS.PDF_UPDATED]: {
    sentenceFormat: '{user} updated. {pdf}',
    tableFormat: 'Updated {pdf}'
  },
  [LOGS.PDF_DELETED]: {
    sentenceFormat: '{user} deleted. {pdf}',
    tableFormat: 'Deleted {pdfWithoutLink}'
  },
  [LOGS.PDF_SHARED]: {
    sentenceFormat: '{user} shared. {pdf}',
    tableFormat: 'Shared {pdfWithoutLink}'
  },
  [LOGS.PDF_SHARE_DELETED]: {
    sentenceFormat: '{user} removed the PDF sharing. {pdf}',
    tableFormat: 'Share removed {pdfWithoutLink}'
  },
  [LOGS.REPORT_CREATED]: {
    sentenceFormat: '{user} created. {report}',
    tableFormat: 'Created {report}'
  },
  [LOGS.REPORT_PAGE_CREATED]: {
    sentenceFormat: '{user} created. {report}',
    tableFormat: 'Created {report}'
  },
  [LOGS.REPORT_UPDATED]: {
    sentenceFormat: '{user} updated. {report}',
    tableFormat: 'Updated {report}'
  },
  [LOGS.REPORT_DELETED]: {
    sentenceFormat: '{user} deleted. {reportWithoutLink}',
    tableFormat: 'Deleted {reportWithoutLink}'
  },
  [LOGS.WORKFLOW_CREATED]: {
    sentenceFormat: '{user} created. {workflow}',
    tableFormat: 'Created {workflow}'
  },
  [LOGS.WORKFLOW_UPDATED]: {
    sentenceFormat: '{user} updated. {workflow}',
    tableFormat: 'Updated {workflow}'
  },
  [LOGS.WORKFLOW_DELETED]: {
    sentenceFormat: '{user} deleted. {workflowWithoutLink}',
    tableFormat: 'Deleted {workflowWithoutLink}'
  },
  [LOGS.DOCUMENT_CREATED]: {
    sentenceFormat: '{user} created. {signDocument}',
    tableFormat: 'Created {signDocument}'
  },
  [LOGS.DOCUMENT_UPDATED]: {
    sentenceFormat: '{user} updated. {signDocument}',
    tableFormat: 'Updated {signDocument}'
  },
  [LOGS.DOCUMENT_DELETED]: {
    sentenceFormat: '{user} deleted. {signDocument}',
    tableFormat: 'Deleted {signDocument}'
  },
  [LOGS.AGENT_CREATED]: {
    sentenceFormat: '{user} created. {agent}',
    tableFormat: 'Created {agent}'
  },
  [LOGS.AGENT_UPDATED]: {
    sentenceFormat: '{user} updated. {agent}',
    tableFormat: 'Updated {agent}'
  },
  [LOGS.AGENT_DELETED]: {
    sentenceFormat: '{user} deleted. {agentWithoutLink}',
    tableFormat: 'Deleted {agentWithoutLink}'
  },
  [LOGS.BOARD_CREATED]: {
    sentenceFormat: '{user} created. {board}',
    tableFormat: 'Created {board}'
  },
  [LOGS.BOARD_UPDATED]: {
    sentenceFormat: '{user} updated. {board}',
    tableFormat: 'Updated {board}'
  },
  [LOGS.BOARD_DELETED]: {
    sentenceFormat: '{user} deleted. {boardWithoutLink}',
    tableFormat: 'Deleted {boardWithoutLink}'
  },
  [LOGS.SUBMISSION_DELETE]: {
    sentenceFormat: '{user} deleted submission from {form}.',
    tableFormat: 'Deleted submission from {form}'
  },
  [LOGS.SUBMISSION_PURGE]: {
    sentenceFormat: '{user} purged submission from {form}.',
    tableFormat: 'Purged submission from {form}'
  },
  [LOGS.SUBMISSION_EDIT]: {
    sentenceFormat: '{user} edited {submission} of {form}.',
    tableFormat: 'Edited {submission} of {form}'
  },
  [LOGS.SUBMISSION_DELETE_ALL]: {
    sentenceFormat: '{user} deleted all submissions from {form}.',
    tableFormat: 'Deleted all submissions from {form}'
  },
  [LOGS.SUBMISSION_DELETE_BATCH]: {
    sentenceFormat: '{user} deleted some submissions from {form}.',
    tableFormat: 'Deleted some submissions from {form}'
  },
  [LOGS.SUBMISSION_PURGE_ALL]: {
    sentenceFormat: '{user} purged all submissions from {form}.',
    tableFormat: 'Purged all submissions from {form}'
  },
  [LOGS.SUBMISSION_PURGE_BATCH]: {
    sentenceFormat: '{user} purged some submissions from {form}.',
    tableFormat: 'Purged some submissions from {form}'
  },
  [LOGS.EMAIL_CHANGE]: {
    sentenceFormat: '{user} changed account email to {email}.',
    tableFormat: 'Changed account email to {email}'
  },
  [LOGS.EMAIL]: {
    sentenceFormat: '{emails}',
    tableFormat: '{emails}'
  },
  [LOGS.WEBHOOK_UPDATE]: {
    sentenceFormat: '{user} updated WebHook URL for form {form}.',
    tableFormat: 'Updated WebHook URL for form {form}'
  },
  [LOGS.CREATE_TEAM]: {
    sentenceFormat: '{user} created the team.',
    tableFormat: 'Created team'
  },
  [LOGS.ADD_TEAM_MEMBER]: {
    sentenceFormat: '{affectedUser} is added to the team by {user}.',
    tableFormat: 'Added {affectedUser}'
  },
  [LOGS.ADD_TEAM_MEMBER_VIA_EMAIL_INVITATION]: {
    sentenceFormat: '{affectedUser} is added to the team by {user}.',
    tableFormat: 'Added {affectedUser}'
  },
  [LOGS.ADD_CREATED_USER_TEAM_MEMBER_VIA_EMAIL_INVITATION]: {
    sentenceFormat: '{affectedUser} is added to the team by {user}.',
    tableFormat: 'Added {affectedUser}'
  },
  [LOGS.ADD_TEAM_MEMBER_VIA_INVITATION_LINK]: {
    sentenceFormat: '{user} joined the team.',
    tableFormat: 'Joined the team'
  },
  [LOGS.ADD_TEAM_MEMBER_WHILE_CREATING_TEAM]: {
    sentenceFormat: '{affectedUser} is added to the team by {user}.',
    tableFormat: 'Added {affectedUser}'
  },
  [LOGS.ADD_NEW_CREATED_TEAM_MEMBER_WHILE_CREATING_TEAM]: {
    sentenceFormat: '{affectedUser} is added to the team by {user}.',
    tableFormat: 'Added {affectedUser}'
  },
  [LOGS.REMOVE_TEAM_MEMBER]: {
    sentenceFormat: '{user} removed {affectedUser}.',
    tableFormat: 'Removed {affectedUser}'
  },
  [LOGS.UPDATE_TEAM_USER_ROLE]: {
    sentenceFormat: '{user} changed the role of {affectedUser} from {prev} to {next}.',
    tableFormat: 'Changed the role of {affectedUser} from {prev} to {next}'
  },
  [LOGS.UPDATE_TEAM_TITLE]: {
    sentenceFormat: '{user} changed the team title from {prev} to {next}.',
    tableFormat: 'Changed the team title from {prev} to {next}'
  },
  [LOGS.UPDATE_TEAM_AVATAR]: {
    sentenceFormat: '{user} changed the team avatar.',
    tableFormat: 'Changed the team avatar'
  },
  [LOGS.UPDATE_TEAM_SLUG]: {
    sentenceFormat: '{user} changed the team slug from {prev} to {next}.',
    tableFormat: 'Changed the team slug from {prev} to {next}'
  },
  [LOGS.UPDATE_TEAM_PRIVACY]: {
    sentenceFormat: '{user} changed the privacy from {prev} to {next}.',
    tableFormat: 'Changed the privacy from {prev} to {next}'
  },
  [LOGS.UPDATE_TEAM_COVER_BG_COLOR]: {
    sentenceFormat: '{user} changed team cover background image.',
    tableFormat: 'Changed team cover background image'
  },
  [LOGS.UPDATE_TEAM_COVER_TITLE_COLOR]: {
    sentenceFormat: '{user} changed the team cover title color.',
    tableFormat: 'Changed the team cover title color'
  },
  [LOGS.UPDATE_TEAM_COVER_BG_IMAGE_URL]: {
    sentenceFormat: '{user} changed the team cover background image.',
    tableFormat: 'Changed the team cover background image'
  },
  [LOGS.UPDATE_TEAM_AVATAR_URL]: {
    sentenceFormat: '{user} changed the team avatar.',
    tableFormat: 'Changed the team avatar'
  },
  [LOGS.UPDATE_TEAM_AVATAR_ICON]: {
    sentenceFormat: '{user} changed the team avatar.',
    tableFormat: 'Changed the team avatar'
  },
  [LOGS.UPDATE_TEAM_AVATAR_EMOJI_ID]: {
    sentenceFormat: '{user} changed the team avatar.',
    tableFormat: 'Changed the team avatar'
  },
  [LOGS.UPDATE_TEAM_AVATAR_ICON_SVG_REF]: {
    sentenceFormat: '{user} changed the team avatar.',
    tableFormat: 'Changed the team avatar'
  },
  [LOGS.UPDATE_TEAM_AVATAR_ICON_COLOR]: {
    sentenceFormat: '{user} changed the team avatar icon color.',
    tableFormat: 'Changed the team avatar icon color'
  },
  [LOGS.UPDATE_TEAM_AVATAR_ICON_BG_COLOR]: {
    sentenceFormat: '{user} changed the team avatar icon background color.',
    tableFormat: 'Changed the team avatar icon background color'
  },
  [LOGS.MOVE_RESOURCE_TO_TEAM]: {
    sentenceFormat: '{user} moved {movedResourceType} from {user} to team. {movedResourceWithLogo}',
    tableFormat: 'Moved {movedResource} from {user} to team'
  },
  [LOGS.MOVE_RESOURCE_TO_USER]: {
    sentenceFormat: '{user} moved {movedResourceType} from team to {user} user. {movedResourceWithLogo}',
    tableFormat: 'Moved {movedResource} from team to {user} user'
  },
  // [LOGS.UPDATED_USER_PRIMARY_METHOD]: {
  //   sentenceFormat: '{user} updated primary method from x to x.',
  //   tableFormat: 'Updated primary method from {prev} to {next}.'
  // },
  [LOGS.ENABLED_USER_2FA]: {
    sentenceFormat: '{user} enabled 2FA. ',
    tableFormat: 'Enabled 2FA.'
  },
  [LOGS.ENABLED_SERVER_WIDE_2FA]: {
    sentenceFormat: '{user} enabled server wide 2FA.',
    tableFormat: 'Enabled server wide 2FA.'
  },
  [LOGS.ENABLED_SERVER_WIDE_2FA_FORCE]: {
    sentenceFormat: '{user} enabled server wide 2FA enforcement.',
    tableFormat: 'Enabled server wide 2FA enforcement'
  },
  // [LOGS.ENABLED_SERVER_WIDE_PHONE_METHDOD]: {
  //   sentenceFormat: '{user} enabled server wide phone method.',
  //   tableFormat: 'Enabled server wide phone method.'
  // },
  [LOGS.DISABLED_SERVER_WIDE_2FA]: {
    sentenceFormat: '{user} disabled server wide 2FA.',
    tableFormat: 'Disabled server wide 2FA.'
  },
  [LOGS.DISABLED_SERVER_WIDE_2FA_FORCE]: {
    sentenceFormat: '{user} disabled server wide 2FA enforcement.',
    tableFormat: 'Disabled server wide 2FA enforcement.'
  },
  [LOGS.ENABLED_SERVER_WIDE_TRUSTED_DEVICE]: {
    sentenceFormat: '{user} enabled remember device for server wide 2FA.',
    tableFormat: 'Enabled remember device for server wide 2FA.'
  },
  [LOGS.DISABLED_SERVER_WIDE_TRUSTED_DEVICE]: {
    sentenceFormat: '{user} disabled remember device for server wide 2FA.',
    tableFormat: 'Disabled remember device for server wide 2FA.'
  },
  // [LOGS.DISABLED_SERVER_WIDE_PHONE_METHOD]: {
  //   sentenceFormat: '{user} disabled server wide phone method.',
  //   tableFormat: 'Disabled server wide phone method.'
  // },
  [LOGS.DELETED_METHOD]: {
    sentenceFormat: '{user} deleted {prev} method.',
    tableFormat: 'Deleted {prev} method'
  },
  [LOGS.DELETED_SETTINGS]: {
    sentenceFormat: '{user} deleted 2FA settings.',
    tableFormat: 'Deleted 2FA settings.'
  },
  [LOGS.RESET_USER_2FA_SETTINGS]: {
    sentenceFormat: '{user} reset 2FA settings.',
    tableFormat: '{adminName} reset {targetName} 2FA settings.'
  },
  [LOGS.UPDATED_USER_2FA_ENFORCEMENT]: {
    sentenceFormat: '{user} updated user 2FA enforcement.',
    tableFormat: '{adminName} {enforcementStatus} {targetName} 2FA enforcement.'
  },
  [LOGS.USE_BACKUP_CODE]: {
    sentenceFormat: '{user} used recovery code.',
    tableFormat: 'Used recovery code from {city}, {countryName}.'
  },
  [LOGS.REGENERATE_BACKUP_CODE]: {
    sentenceFormat: '{user} genereated new recovery codes.',
    tableFormat: 'Generated new recovery codes.'
  },
  [LOGS.APPROVE_TEAM_JOIN_REQUEST]: {
    sentenceFormat: '{user} accepted {possesiveAffectedUser} join request.',
    tableFormat: 'Accepted {possesiveAffectedUser} join request'
  },
  [LOGS.DENY_TEAM_JOIN_REQUEST]: {
    sentenceFormat: '{user} denied {possesiveAffectedUser} join request.',
    tableFormat: 'Denied {possesiveAffectedUser} join request'
  },
  [LOGS.ADDED_TEAM_SMTP]: {
    sentenceFormat: '{user} added a new SMTP sender email',
    tableFormat: '{user} added a new SMTP sender email'
  },
  [LOGS.ADDED_TEAM_OAUTH2_SMTP]: {
    sentenceFormat: '{user} added a new OAuth2 SMTP sender email',
    tableFormat: '{user} added a new OAuth2 SMTP sender email'
  },
  [LOGS.UPDATED_TEAM_SMTP]: {
    sentenceFormat: '{user} updated SMTP sender email',
    tableFormat: '{user} updated SMTP sender email'
  },
  [LOGS.UPDATED_TEAM_OAUTH2_SMTP]: {
    sentenceFormat: '{user} updated OAuth2 SMTP sender email',
    tableFormat: '{user} updated OAuth2 SMTP sender email'
  },
  [LOGS.DELETED_TEAM_SMTP]: {
    sentenceFormat: '{user} deleted SMTP sender email',
    tableFormat: '{user} deleted SMTP sender email'
  },
  [LOGS.DELETED_TEAM_OAUTH2_SMTP]: {
    sentenceFormat: '{user} deleted OAuth2 SMTP sender email',
    tableFormat: '{user} deleted OAuth2 SMTP sender email'
  },
  [LOGS.DELETED_REPLACED_TEAM_OAUTH2_SMTP]: {
    sentenceFormat: '{user} deleted and replaced OAuth2 SMTP sender email',
    tableFormat: '{user} deleted and replaced OAuth2 SMTP sender email'
  },
  [LOGS.ADDED_SERVER_WIDE_OAUTH2_SMTP_CONFIG]: {
    sentenceFormat: '{user} added Server OAuth2 SMTP Sender Email',
    tableFormat: '{user} added Server OAuth2 SMTP Sender Email'
  },
  [LOGS.UPDATED_SERVER_WIDE_OAUTH2_SMTP_CONFIG]: {
    sentenceFormat: '{user} updated Server OAuth2 SMTP Sender Email',
    tableFormat: '{user} updated Server OAuth2 SMTP Sender Email'
  },
  [LOGS.DELETED_SERVER_WIDE_OAUTH2_SMTP_CONFIG]: {
    sentenceFormat: '{user} deleted Server OAuth2 SMTP Sender Email',
    tableFormat: '{user} deleted Server OAuth2 SMTP Sender Email'
  },
  [LOGS.ADDED_SERVER_WIDE_SMTP_CONFIG]: {
    sentenceFormat: '{user} added Server SMTP Sender Email',
    tableFormat: '{user} added Server SMTP Sender Email'
  },
  [LOGS.UPDATED_SERVER_WIDE_SMTP_CONFIG]: {
    sentenceFormat: '{user} updated Server SMTP Sender Email',
    tableFormat: '{user} updated Server SMTP Sender Email'
  },
  [LOGS.DELETED_SERVER_WIDE_SMTP_CONFIG]: {
    sentenceFormat: '{user} deleted Server SMTP Sender Email',
    tableFormat: '{user} deleted Server SMTP Sender Email'
  }
};

const getReportBaseFromType = (reportType?: string): string => {
  switch (reportType) {
    case 'cal':
      return 'calendar';
    case 'visual':
    case 'presentation':
      return 'report';
    default:
      return reportType || 'reports';
  }
};

const getMovedResource = (resourceType?: string, reportType?: string): { baseUrl: string, resourceType: string, icon: ElementType, iconType?: string } => {
  switch (resourceType) {
    case 'FORM':
      return { baseUrl: 'form', resourceType: 'form', icon: ScFormIcon };
    case 'SHEET':
      return { baseUrl: 'tables', resourceType: 'table', icon: ScSheetIcon };
    case 'REPORT':
      return {
        baseUrl: getReportBaseFromType(reportType), resourceType: 'report', icon: ScReportIcon, iconType: reportType
      };
    case 'WORKFLOW':
      return { baseUrl: 'workflow', resourceType: 'approval', icon: ScWorkflowIcon };
    case 'PORTAL':
      return { baseUrl: 'app', resourceType: 'app', icon: ScAppsIcon };
    case 'SIGN':
      return { baseUrl: 'sign', resourceType: 'sign document', icon: ScSignDocumentIcon };
    case 'PDF':
      return { baseUrl: 'pdf-editor', resourceType: 'PDF', icon: ScPDFIcon };
    case 'AI-AGENT':
      return { baseUrl: 'agent', resourceType: 'agent', icon: ScAgentIcon };
    case 'BOARD':
      return { baseUrl: 'boards', resourceType: 'board', icon: ScBoardIcon };
    default:
      return { baseUrl: '', resourceType: 'asset', icon: ScFormIcon };
  }
};

const assetRenderer = ({
  typeAssetRenderer, assetID, assetTitle, AssetIcon, titleStyles, withoutLink = false, iconType = '', withoutLogo = false, styles
}: {
  typeAssetRenderer?: string, assetID?: string, assetTitle?: string, AssetIcon?: ElementType, titleStyles?: { [key: string]: any},
  withoutLink?: boolean, iconType?: string, withoutLogo?: boolean, styles?: { [key: string]: any}
}) => {
  if (withoutLogo) {
    return withoutLink ? (<b>{assetTitle || assetID}</b>) : (
      <a
        aria-label={`go to /${typeAssetRenderer}/${assetID}`}
        target='_blank'
        href={`/${typeAssetRenderer}/${assetID}`}
        onClick={e => {
          e.preventDefault();
          handleCustomNavigation(`/${typeAssetRenderer}/${assetID}`, '_blank');
        }}
        style={{ textDecoration: 'none', ...(styles || {}) }}
      >
        <b>{assetTitle || assetID}</b>
      </a>
    );
  }
  const content = AssetIcon && (
    <>
      <AssetIcon
        type={iconType || 'LEGACY'}
        style={{
          display: 'inline-block',
          height: '1.33em',
          backgroundSize: 'contain',
          minWidth: '1.33em',
          verticalAlign: 'middle',
          margin: '0 4px 3px 0'
        }}
      />
      <b
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 240,
          ...(titleStyles || {})
        }}
        title={assetTitle || assetID}
      >
        {assetTitle || assetID}
      </b>
    </>
  );
  return withoutLink ? content : (
    <a
      aria-label={`go to /${typeAssetRenderer}/${assetID}`}
      target='_blank'
      href={`/${typeAssetRenderer}/${assetID}`}
      onClick={e => {
        e.preventDefault();
        if (typeAssetRenderer === 'digest') {
          handleCustomNavigation(`/myreports?edit-digest=${assetID}`, '_blank', true);
        } else {
          handleCustomNavigation(`/${typeAssetRenderer}/${assetID}`, '_blank');
        }
      }}
      style={{
        textDecoration: 'none',
        color: '#000',
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'middle'
      }}
    >
      {content}
    </a>
  );
};

const withArticle = (text: string): string => {
  if (!text?.length) return '';
  const pattern = new RegExp(/^([aeiou])/, 'i');
  return `${pattern.test(text[0]) ? 'an' : 'a'} ${text}`;
};

export const getPrettyLog = (log: Log, sentenceFormat?: boolean): ReactNode => {
  const {
    type, name, username, formID, formTitle, subjectFullName, subject, previous, next,
    subUserName, subuser, subUserEmail, submissionID, email, status, to, id, sheetName, sheetID,
    portalName, portalID, pdfID, pdfTitle, reportTitle, reportID, reportType, workflowID, workflowName, documentTitle,
    resource_type: resType, resource_title: resTitle, resource_id: resID, action_desc: actionDesc, agentID, agentTitle,
    boardID, boardTitle
  } = log;
  if (!type) return '';
  const template = type === LOGS.UPDATED_SERVER_SMTP_CONFIG
    ? subject
    : TEMPLATES[type][sentenceFormat ? 'sentenceFormat' : 'tableFormat'];
  if (template) {
    const movedResource = getMovedResource(resType, reportType);
    const reportBase = reportType ? getReportBaseFromType(reportType) : '';
    const titleStyles = { marginBottom: 3 };

    const formRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'form', assetID: formID, assetTitle: formTitle, AssetIcon: ScFormIcon, withoutLink, withoutLogo
    });
    const sheetRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'tables', assetID: sheetID, assetTitle: sheetName, titleStyles, AssetIcon: ScSheetIcon, withoutLink, withoutLogo
    });
    const portalRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'app', assetID: portalID, assetTitle: portalName, titleStyles, styles: titleStyles, AssetIcon: ScAppsIcon, withoutLink, withoutLogo
    });
    const pdfRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'pdf-editor', assetID: pdfID, assetTitle: pdfTitle, titleStyles, AssetIcon: ScPDFIcon, withoutLink, withoutLogo
    });
    const reportRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: reportBase, assetID: reportID, assetTitle: reportTitle, titleStyles, AssetIcon: ScReportIcon, iconType: reportType, withoutLink, withoutLogo
    });
    const workflowRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'workflow', assetID: workflowID, assetTitle: workflowName, titleStyles, AssetIcon: ScWorkflowIcon, withoutLink, withoutLogo
    });
    const signRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'sign', assetID: formID, assetTitle: documentTitle, titleStyles, AssetIcon: ScSignDocumentIcon, withoutLink, withoutLogo
    });
    const agentRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'agent', assetID: agentID, assetTitle: agentTitle, titleStyles, AssetIcon: ScAgentIcon, withoutLink, withoutLogo
    });
    const boardRenderer = (withoutLink?: boolean, withoutLogo?: boolean) => assetRenderer({
      typeAssetRenderer: 'boards', assetID: boardID, assetTitle: boardTitle, titleStyles, AssetIcon: ScBoardIcon, withoutLink, withoutLogo
    });

    const renderers: ({ [key: string]: () => any }) = {
      email: () => email,
      user: () => <b>{name || username}</b>,
      // with link with logo
      form: () => formRenderer(),
      sheet: () => sheetRenderer(),
      report: () => reportRenderer(),
      portal: () => portalRenderer(),
      pdf: () => pdfRenderer(),
      workflow: () => workflowRenderer(),
      signDocument: () => signRenderer(),
      agent: () => agentRenderer(),
      board: () => boardRenderer(),
      // without link with logo
      formWithoutLink: () => formRenderer(true),
      sheetWithoutLink: () => sheetRenderer(true),
      portalWithoutLink: () => portalRenderer(true),
      pdfWithoutLink: () => pdfRenderer(true),
      reportWithoutLink: () => reportRenderer(true),
      workflowWithoutLink: () => workflowRenderer(true),
      agentWithoutLink: () => agentRenderer(true),
      boardWithoutLink: () => boardRenderer(true),
      // with link without logo
      formWithoutLogo: () => formRenderer(false, true),
      sheetWithoutLogo: () => sheetRenderer(false, true),
      portalWithoutLogo: () => portalRenderer(false, true),
      pdfWithoutLogo: () => pdfRenderer(false, true),
      reportWithoutLogo: () => reportRenderer(false, true),
      workflowWithoutLogo: () => workflowRenderer(false, true),
      signDocumentWithoutLogo: () => signRenderer(false, true),
      agentWithoutLogo: () => agentRenderer(false, true),
      boardWithoutLogo: () => boardRenderer(false, true),
      // without link without logo
      formText: () => formRenderer(true, true),
      sheetText: () => sheetRenderer(true, true),
      portalText: () => portalRenderer(true, true),
      pdfText: () => pdfRenderer(true, true),
      reportText: () => reportRenderer(true, true),
      workflowText: () => workflowRenderer(true, true),
      agentText: () => agentRenderer(true, true),
      boardText: () => boardRenderer(true, true),
      // moved resources
      movedResource: () => assetRenderer({
        typeAssetRenderer: movedResource.baseUrl, assetID: resID, assetTitle: resTitle, withoutLogo: true
      }),
      movedResourceWithLogo: () => assetRenderer({
        typeAssetRenderer: movedResource.baseUrl, assetID: resID, assetTitle: resTitle, titleStyles, AssetIcon: movedResource.icon, iconType: movedResource.iconType
      }),
      movedResourceType: () => withArticle(movedResource.resourceType),
      affectedUser: () => <b>{subjectFullName || subject}</b>,
      prev: () => <b>{previous}</b>,
      next: () => <b>{next}</b>,
      nextBinary: () => (next && next !== '0' ? 'on' : 'off'),
      possessiveSubuser: () => `{boldSubuser}${possessive(subUserName || subuser)}`,
      subuser: () => subUserName || subuser,
      deletedUser: () => `${subUserName} ${subUserEmail ? `(${subUserEmail})` : ''}`,
      adminName: () => <b>{name || username}</b>,
      targetName: () => <b>{previous || 'unknown'}</b>,
      enforcementStatus: () => (next === '1' ? 'enabled' : 'disabled'),
      submission: () => (
        <span>
          <a
            aria-label={`go to /inbox/${formID}/${submissionID}`}
            className="link-special"
            target='_blank'
            href={`/inbox/${formID}/${submissionID}`}
            onClick={e => {
              e.preventDefault();
              handleCustomNavigation(`/inbox/${formID}/${submissionID}`, '_blank');
            }}
          >
            submission
          </a>
        </span>
      ),
      emails: () => (
        <>
          <span>
            <a
              aria-label={`go to /emails/${id}`}
              target='_blank'
              href={`/emails/${id}`}
              className="link-special"
              onClick={e => {
                e.preventDefault();
                handleCustomNavigation(`/emails/${id}`, '_blank');
              }}
            >
              Email
            </a>
          </span>
          {` ${status && status.toLowerCase()} to ${to} `}
          <a
            aria-label={`go to /form/${formID}`}
            target='_blank'
            href={`/form/${formID}`}
            onClick={e => {
              e.preventDefault();
              handleCustomNavigation(`/form/${formID}`, '_blank');
            }}
          >
            {`${formTitle || formID}`}
          </a>
        </>
      ),
      boldSubuser: () => <b>{subUserName || subuser}</b>,
      countryName: () => <b>{actionDesc?.country_name}</b>,
      city: () => <b>{actionDesc?.city || 'Unknown'}</b>,
      possesiveAffectedUser: () => `{affectedUser}${possessive(subjectFullName || subject)}`
    };

    const replacedRenderers = ['movedResourceType', 'possessiveSubuser', 'possesiveAffectedUser'];

    const replacedTemplate = replacedRenderers.reduce((accTemplate, rendererKey) => {
      return accTemplate.replaceAll(`{${rendererKey}}`, renderers[rendererKey]());
    }, template);

    // These formatted versions are required for translationRenderer function.

    // '{form} is created by {user}' is converted to '[2[{form}]] is created by [1[{user}]]'
    // 2 refers to the index of the 'form' key of renderers object.
    const formattedTemplate = Object.keys(renderers).reduce((accTemplate, renderer, index) => {
      return accTemplate.replaceAll(`{${renderer}}`, `[${index + 1}[{${renderer}}]]`);
    }, replacedTemplate);
    // Keys of the renderers object are converted to 'renderer{index}' for translationRenderer function.
    // For example, { user: USER, form: FORM } is converted to { renderer1: USER, renderer2: FORM }
    const formattedRenderers = Object.values(renderers).reduce((acc, renderer, index) => {
      return { ...acc, [`renderer${index + 1}`]: renderer };
    }, {});

    return translationRenderer(formattedTemplate)(formattedRenderers);
  }
  return null;
};

export const formatTime = (epochTime: Date | number, userLanguage?: string): ReactElement => {
  const { tokens: [dateStr, timeStr] = [] } = prettyDate(typeof epochTime === 'number' ? epochTime * 1000 : epochTime, true, false, false, {}, userLanguage) as PrettyDateReturnValue || {};
  return (
    <div>
      <span>{dateStr}</span>
      <br />
      <span style={{ color: '#a0a9bc' }}>{timeStr}</span>
    </div>
  );
};

export const formatLogs: (logs: Log[], allowedLogs: LogType[], sentenceFormat?: boolean, userLanguage?: string) => PrettyLog[] = (logEntries, allowedLogs, sentenceFormat, userLanguage) => {
  return logEntries.filter(logEntry => allowedLogs.findIndex(log => log === logEntry.type) > -1).map(logEntry => {
    const epochTime = new Date(logEntry.timestamp * 1000);
    return {
      ...logEntry,
      prettyDate: formatTime(epochTime, userLanguage),
      prettyFormat: getPrettyLog(logEntry, sentenceFormat)
    };
  });
};

export const groupLogs = (groupId: string, logGroups: LogGroup[]): LogType[] => {
  const logGroup = logGroups.find(lg => lg.groupId === groupId);
  return logGroup ? logGroup.logs : [];
};

export const logGroupOptions = LOG_GROUPS.map(lg => lg.groupId);

export const groupVisibleName = (groupId: string, logGroups: LogGroup[]): string => {
  const logGroup = logGroups.find(lg => lg.groupId === groupId);
  return logGroup ? logGroup.visibleName : '';
};

export const visibleName = (logType: string, isUserHistory: boolean): string => (isUserHistory ? USER_HISTORY_VISIBLE_NAMES[logType] : VISIBLE_NAMES[logType]);
