import React from 'react';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';
import ReactDOM from 'react-dom';

import { fetchFormQuestions } from '../api';

const CreateNewReportWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewReportWizard" */'./LazyWizards/LazyCreateNewReportWizard'));

const rootElement = document.getElementById('modal-container');

export const openCreateNewReportModal = ({
  forms, selectedID, ...props
}) => {
  return new Promise((resolve, reject) => {
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      ReactDOM.unmountComponentAtNode(rootElement);
      if (props.closeButtonCallback) {
        props.closeButtonCallback();
      }
    };
    const skipValue = selectedID ? { form: true } : null;

    const createReport = (formID, report) => new Promise((resolveCreateReport, rejectCreateReport) => {
      resolve({
        formID,
        report,
        rejectCreateReport,
        resolveCreateReport
      });
    });

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    const handleShow = () => {
      if (props.openWizardCallback) props.openWizardCallback();
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewReportWizard
          disableActionLogger
          usePortal
          fetchFormQuestions={fetchFormQuestions}
          forms={forms}
          initialVisibilityState={true}
          onShow={handleShow}
          onHide={handleClose}
          targetContainer='#modal-container'
          formId={selectedID}
          skip={skipValue}
          onSave={createReport}
          onViewChange={onViewChange}
          {...props}
        />
      )
    });
  });
};
