/* eslint-disable react/jsx-max-props-per-line */
import React, { forwardRef } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { ColorPicker } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { IconPlus } from '@jotforminc/svg-icons';

const TextRenderer = ({ text, ...rest }) => <span {...rest}>{t(text)}</span>;

TextRenderer.propTypes = {
  text: string.isRequired
};

const ColorRenderer = forwardRef(({ onClick }, ref) => {
  const handleClick = e => {
    e.stopPropagation();
    onClick();
  };

  return (
    <span
      ref={ref}
      onClick={handleClick}
      className="context-menu-color context-menu-color-select"
    >
      <IconPlus />
    </span>
  );
});

ColorRenderer.propTypes = {
  onClick: func.isRequired
};

const TextInputRenderer = forwardRef((rest, ref) => <div {...rest} ref={ref} />);

const ColorSelectionRenderer = ({ palette = [], onClick = f => f, text }) => {
  const handleChange = color => onClick({ selectedColor: color });

  return (
    <>
      <span>{text}</span>
      <div className="context-menu-palette">
        {palette.map(color => (
          <span
            key={color}
            className="context-menu-color"
            style={{ backgroundColor: color }}
            onClick={e => {
              e.stopPropagation();
              handleChange(color);
            }}
          />
        ))}
        <div onClick={e => e.stopPropagation()}>
          <ColorPicker
            onChange={handleChange}
            ColorRenderer={ColorRenderer}
            TextInputRenderer={TextInputRenderer}
          />
        </div>
      </div>
    </>
  );
};

ColorSelectionRenderer.propTypes = {
  text: string.isRequired,
  palette: arrayOf(string),
  onClick: func
};

const ContextMenuItemMap = {
  TEXT: TextRenderer,
  COLOR: ColorSelectionRenderer
};

export default ContextMenuItemMap;
