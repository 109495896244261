/* eslint-disable max-len */
import Styled from 'styled-components';
import { breakpoints } from '../../../constants';
import { getIconStyles } from '../../../utils';

export const ScNotificationTitleNv = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 52px;
  position: relative;
  margin-bottom: 16px;

  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center center;
    ${({ notification }) => (notification.iconName ? `${getIconStyles(notification.iconName)};` : '')}

    @media screen and (max-width:${breakpoints.tablet}){
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
    }
  }

  ${({ showRedDot = false }) => (showRedDot ? `
    &::after{
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #FF6100;
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
    }
  ` : '')}

  div {
    flex-basis: 264px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &.row {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  p, strong {
    display: inline-flex;
    font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
    font-size: 14px;
    line-height: 24px;
    color: #343C6A;
    margin: 0;
  }

  .addSpacer {
    margin-right: 4px;
  }
`;
