/* eslint-disable max-len */
import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScDismissButton = Styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  text-decoration: none;
  line-height: 20px;
  color: #343C6A;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: .2s;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: auto;
  width: 32px;
  height: 32px;
  min-height: auto;
  padding: auto;
  border-radius: 50%;

  @media screen and (max-width:${breakpoints.tablet}){
    min-height: 42px;
    padding: 12px 6px;
  }

  ${({ hideCrossIcon }) => {
    if (!hideCrossIcon) {
      return `
      &::before{
        content: "";
        flex-shrink: 0;
        display: block;
        width: 8px;
        height: 8px;
        margin: 0 4px 0 0;
        transition: .2s;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.80474 1.13807C8.06509 0.877722 8.06509 0.455612 7.80474 0.195262C7.54439 -0.0650874 7.12228 -0.0650874 6.86193 0.195262L4 3.05719L1.13807 0.195262C0.877722 -0.0650874 0.455612 -0.0650874 0.195262 0.195262C-0.0650874 0.455612 -0.0650874 0.877722 0.195262 1.13807L3.05719 4L0.195262 6.86193C-0.0650874 7.12228 -0.0650874 7.54439 0.195262 7.80474C0.455612 8.06509 0.877722 8.06509 1.13807 7.80474L4 4.94281L6.86193 7.80474C7.12228 8.06509 7.54439 8.06509 7.80474 7.80474C8.06509 7.54439 8.06509 7.12228 7.80474 6.86193L4.94281 4L7.80474 1.13807Z' fill='%23343C6A'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        margin: 0;
      }
      `;
    }
  }}

  &:focus{
    outline: 2px solid rgb(227, 229, 245, .5);
    background: #F3F3FE;
  }

  &:hover {
    color: #FFFFFF;
    background: #F3F3FE;

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width=\'8\' height=\'8\' viewBox=\'0 0 8 8\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M7.80474 1.13807C8.06509 0.877722 8.06509 0.455612 7.80474 0.195262C7.54439 -0.0650874 7.12228 -0.0650874 6.86193 0.195262L4 3.05719L1.13807 0.195262C0.877722 -0.0650874 0.455612 -0.0650874 0.195262 0.195262C-0.0650874 0.455612 -0.0650874 0.877722 0.195262 1.13807L3.05719 4L0.195262 6.86193C-0.0650874 7.12228 -0.0650874 7.54439 0.195262 7.80474C0.455612 8.06509 0.877722 8.06509 1.13807 7.80474L4 4.94281L6.86193 7.80474C7.12228 8.06509 7.54439 8.06509 7.80474 7.80474C8.06509 7.54439 8.06509 7.12228 7.80474 6.86193L4.94281 4L7.80474 1.13807Z\' fill=\'%23343C6A\'/%3E%3C/svg%3E%0A");
    }
  }
`;
