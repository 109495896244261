import { call, put, spawn } from 'redux-saga/effects';
import * as API from '../../api';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { registerUniqueAction } from '../utils';
import { ASSET_TYPES } from '../../constants';

function* handleDiscardDraftAction({ ID, isOldSAC, formID }) {
  try {
    const result = yield call(!isOldSAC ? API.discardDraft : API.discardSubmissionDraft, ID, formID);
    if (!result) {
      yield put(ACTION_CREATORS.deleteItemError());
      return;
    }
    yield put(ACTION_CREATORS.deleteItemSuccess(ID, ASSET_TYPES.FORM));
  } catch (e) {
    yield put(ACTION_CREATORS.deleteItemError());
  }
}

export function* rootDraftFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.DISCARD_DRAFT.REQUEST, handleDiscardDraftAction);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
