import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppPicker } from '@jotforminc/app-picker';
import { navigationItems } from '@jotforminc/header-components';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { arrayOf, string } from 'prop-types';
import store from '../../store/store';
import './appPicker.tmp.scss';
import { SELECTORS } from '../../store/selectors';
import {
  APP_PICKER_LISTING_MAPPINGS, FEATURE_LIST, LISTING_TYPES, TEAM_SECTION_ENABLED_PAGES, TEAM_ENABLED_RESOURCES
} from '../../constants';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { canAccessMyAgentsPage } from '../../utils';

const AppPickerWithTeamSection = ({ appList, currentApp, ...props }) => {
  const dispatch = useDispatch();
  const openCreateTeamModal = () => dispatch(ACTION_CREATORS.showCreateTeamModal(true, 'navigation'));

  const teams = useSelector(SELECTORS.getTeams);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const isTeamsActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.JOTFORM_TEAMS));
  const isTeamMember = useSelector(SELECTORS.getIsTeamMember);
  const isTeamCreationDisabled = useSelector(SELECTORS.getIsTeamCreationDisabled);
  const isTeamBrowsingDisabled = useSelector(SELECTORS.getIsTeamBrowsingDisabled);
  const canUserAccessMixPage = useSelector(SELECTORS.canAccessMixedListing);
  const isNewAppPickerActive = useSelector(SELECTORS.getIsNewAppPickerActive);
  const credentials = useSelector(SELECTORS.getUserCredentials);
  const canUserAccessMyAgentsPage = canAccessMyAgentsPage(credentials);

  let newAppList = [...appList];
  if (canUserAccessMyAgentsPage) {
    newAppList = [...newAppList, 'myAgents'];
  }
  if (canUserAccessMixPage) {
    newAppList = [...newAppList, 'myWorkspace'];
  }

  const filteredAppList = currentTeamID ? newAppList.filter(app => [...TEAM_ENABLED_RESOURCES, LISTING_TYPES.AGENT].includes(APP_PICKER_LISTING_MAPPINGS[app])) : newAppList;
  const isTeamPage = currentPage === LISTING_TYPES.TEAM_PAGE;

  if (isNewAppPickerActive) {
    return null;
  }

  if (isTeamPage) {
    if (!isTeamMember) {
      // Hide navigation for users that are not in team (They are visiting team via all teams page)
      return null;
    }

    if (teams.length === 0) {
      // Teams is not fetched yet, so we cannot show team name in navigation. Instead, hide it.
      return null;
    }
  }

  return (
    <AppPicker
      {...props}
      appList={filteredAppList}
      currentApp={currentApp}
      teams={teams}
      openCreateTeamModal={openCreateTeamModal}
      showTeamSection={isTeamsActive && (isTeamPage || TEAM_SECTION_ENABLED_PAGES.includes(currentPage))}
      teamID={currentTeamID}
      isTeamPage={isTeamPage}
      isTeamCreationDisabled={isTeamCreationDisabled}
      isTeamBrowsingDisabled={isTeamBrowsingDisabled}
      isInListings
    />
  );
};

AppPickerWithTeamSection.propTypes = {
  appList: arrayOf(string).isRequired,
  currentApp: string.isRequired
};

/* Dear developer;
 in order to reduce network load on listings/myforms projects,
 we made an unfortunate copy of Common/cdnEntries/AppPicker.js
 If you plan to update this file make sure you updated that file too.
*/

const appendAppPicker = async () => {
  const appList = ['myForms', 'myTables'];
  if (!window.isDataOnlyUser) {
    if (window.allowMyReports) {
      appList.push('myReports');
    }

    appList.push('myTasks');

    if (window.allowMyKiosks) {
      appList.push('myKiosks');
    }
    if (window.allowMyApps) {
      appList.push('myApps');
    }

    if (window.allowMyDocuments) {
      appList.push('myDocuments');
    }

    if (window.allowMyPages) {
      appList.push('myPages');
    }

    if (window.JOTFORM_ENV === 'DEVELOPMENT' || window.location.href.indexOf('useMyContacts') > -1) {
      appList.push('myContacts');
    }

    if (window.allowMyBoards) {
      appList.push('myBoards');
    }
  }
  window.appPickerInitialized = false;

  const initAppPicker = async () => {
    if (window.appPickerInitialized) {
      return;
    }

    window.appPickerInitialized = true;

    const headerWrapper = document.querySelector('.jfHeader-logoWrapper');
    if (!headerWrapper) return;
    const existingAppPicker = headerWrapper.querySelector('.jfHeader-appPicker');
    if (existingAppPicker?.remove) {
      existingAppPicker.remove();
    }

    const appPickerContainer = document.createElement('div');
    appPickerContainer.classList.add('jfHeader-appPicker');
    headerWrapper.appendChild(appPickerContainer);
    headerWrapper.classList.add('app-picker');

    const currentApp = Object.keys(navigationItems).find(name => {
      if (!navigationItems[name]) return;

      return navigationItems[name].appLink === `/${window.initialApp}/` || window.location.pathname.includes(navigationItems[name].appLink);
    });
    const root = createRoot(appPickerContainer);
    root.render(
      <Provider store={store}>
        <AppPickerWithTeamSection
          appList={appList}
          showBackToMyForms={false}
          hideIfEmpty={true}
          currentApp={currentApp || 'myForms'}
          user={window.user}
        />
      </Provider>
    );

    const isGDPR = document.getElementsByClassName('gdpr-secure-account')[0];
    const isHipaa = document.getElementsByClassName('hipaa-secure-account')[0];

    if (isGDPR || isHipaa) {
      document.querySelector('.jNewHeader-appSelector').classList.add('withBadge');
    }
  };

  window.initAppPicker = initAppPicker;

  // sometimes 'appendAppPicker' runs after common-header's 'initAppPicker' call
  // this will help for auto init
  window.addEventListener('jfheader-user-ready', () => {
    initAppPicker();
  });

  if (window.user) {
    window.dispatchEvent(new window.CustomEvent('jfheader-user-ready'));
  }
};

export default appendAppPicker;
