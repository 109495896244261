/* eslint-disable no-undef */
import {
  createAIForm
} from '../api';
import {
  GenerateFormType
} from '../typings/actions';

export const generateForm: GenerateFormType = async prompt => {
  try {
    const AIForm = await createAIForm({ prompt });

    return AIForm;
  } catch (error) {
    const errorMsg = error instanceof Error ? error.message : 'Unknown error.';
    throw new Error(errorMsg);
  }
};
