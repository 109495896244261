import { useKeyTrap } from '../useKeyTrap';

const useKeyNavigation = key => (ref1, ref2, otherTraps = []) => {
  useKeyTrap([
    {
      keyCode: key,
      callback: e => {
        if (e.key === key) {
          if (e.shiftKey) {
            if (document.activeElement.className === ref1.current.className) {
              ref2.current.focus();
              e.preventDefault();
            }
          } else if (document.activeElement.className === ref2.current.className) {
            ref1.current.focus();
            e.preventDefault();
          }
        }
      }
    },
    ...otherTraps
  ]);
};
export const useTabNavigation = useKeyNavigation('Tab');
