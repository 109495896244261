/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/sort-comp */
import React, {
  Component, ReactNode, createRef, RefObject, KeyboardEvent, MouseEvent
} from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import classnames from 'classnames';
import { t, setDatepickerLocale } from '@jotforminc/translation';
import { IconClock, IconInfoCircle } from '@jotforminc/svg-icons';
import isEqual from 'lodash/isEqual';

import '../../styles/TimeRange.scss';

import {
  optionList, labelClass, defaultTimeFilterType
} from './constants';
import {
  TimeRangeProps, TimeRangeState, DateFilter
} from './types';

export class TimeRange extends Component<TimeRangeProps, TimeRangeState> {
  wrapperRef: RefObject<HTMLDivElement | null>;

  static defaultProps: any;

  constructor(props: TimeRangeProps) {
    super(props);
    this.wrapperRef = createRef<HTMLDivElement | null>();

    const {
      disableFuture,
      timeFilterText,
      isContextMenuOpened,
      customDateRangeValue,
      userLanguage
    } = this.props;
    setDatepickerLocale({ locale: userLanguage || 'en-US', registerLocale, setDefaultLocale });
    const { start, end } = customDateRangeValue || {};
    const startAllTime = new Date('2006-01-01');
    const startTime = start ? new Date(start) : startAllTime;
    const endTime = end ? new Date(end) : new Date();
    const initialSelectedDate = end ? endTime : null;
    this.state = {
      startAllTime,
      startDate: startTime,
      endDate: endTime,
      selectedDate: initialSelectedDate,
      timeFilterType: timeFilterText,
      showDatePicker: 'start',
      isOpen: isContextMenuOpened,
      disableFuture,
      showCustomWarning: false
    };
  }

  componentDidUpdate(prevProps: TimeRangeProps): void {
    const {
      timeFilterText: currentTimeFilterText,
      customDateRangeValue: currentRangeValue,
      isContextMenuOpened: currIsContextMenuOpened,
      customWarningEnabled
    } = this.props;
    const {
      timeFilterText: prevTimeFilterText,
      customDateRangeValue: prevRangeValue,
      isContextMenuOpened: prevIsContextMenuOpened
    } = prevProps;

    if (prevTimeFilterText !== currentTimeFilterText) {
      const isCustom = currentTimeFilterText === 'custom';
      const showCustomWarning = customWarningEnabled && isCustom;
      const newState = !isCustom
        ? { ...this.getDateThresholdRange(currentTimeFilterText), showCustomWarning }
        : { timeFilterType: currentTimeFilterText, showCustomWarning };
      this.setState(newState as TimeRangeState); // eslint-disable-line react/no-did-update-set-state
    }

    const { start, end } = currentRangeValue || {};
    if (!isEqual(prevRangeValue, currentRangeValue) && start && end) {
      const startTime = new Date(start);
      const endTime = new Date(end);
      this.setState({ startDate: startTime, endDate: endTime, selectedDate: endTime }); // eslint-disable-line react/no-did-update-set-state
    }

    if (prevIsContextMenuOpened && !currIsContextMenuOpened) {
      document.body.removeEventListener('click', this.handleClickOutside as () => void, { capture: true });
      document.body.removeEventListener('keyup', this.handleEsc as () => void, { capture: true });
      this.setState({ timeFilterType: currentTimeFilterText }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleClick = (): void => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.hideMenu();
    } else {
      document.body.addEventListener('click', this.handleClickOutside as () => void, { capture: true });
      document.body.addEventListener('keyup', this.handleEsc as () => void, { capture: true });
      this.setState({ isOpen: true });
    }
  };

  handleClickOutside = (e: MouseEvent<any>): void => {
    const { isOpen } = this.state;
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(e.target as any)) {
      if (isOpen) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.hideMenu();
    }
  };

  handleKeyUp = (e: KeyboardEvent<any>, keyCodes: number [], cbFunc: () => void): void => {
    if (keyCodes.indexOf(e.keyCode) > -1) {
      cbFunc();
    }
  };

  handleEsc = (e: KeyboardEvent<any>): void => this.handleKeyUp(e, [27], this.hideMenu);

  handleEnter = (e: KeyboardEvent<any>): void => this.handleKeyUp(e, [13, 32], this.handleClick);

  handleTimeRangeOptionClick = (timeFilterType: DateFilter) => (): void => {
    const { timeFilterType: currentType } = this.state;
    const { customWarningEnabled } = this.props;
    if (currentType !== timeFilterType) {
      const dateTresholdRange = this.getDateThresholdRange(timeFilterType) as TimeRangeState;
      this.setState({
        ...dateTresholdRange,
        showCustomWarning: customWarningEnabled && timeFilterType === 'custom'
      }, () => this.handleSave());
    }
  };

  handleChangeStart = (startDate: Date): void => {
    const { endDate } = this.state;
    const { customWarningEnabled } = this.props;

    if (startDate > endDate) {
      this.setState({
        endDate: startDate,
        selectedDate: startDate,
        showCustomWarning: customWarningEnabled,
        timeFilterType: 'custom'
      }, () => this.handleSave());
    } else {
      this.setState({
        startDate,
        selectedDate: endDate,
        showDatePicker: 'end',
        showCustomWarning: customWarningEnabled,
        timeFilterType: 'custom'
      }, () => this.handleSave());
    }
  };

  handleChangeEnd = (endDate: Date): void => {
    const { startDate } = this.state;
    const { customWarningEnabled } = this.props;

    if (endDate < startDate) {
      this.setState({
        startDate: endDate,
        selectedDate: endDate,
        showCustomWarning: customWarningEnabled,
        timeFilterType: 'custom'
      }, () => this.handleSave());
    } else {
      this.setState({
        endDate,
        showDatePicker: 'start',
        selectedDate: startDate,
        showCustomWarning: customWarningEnabled,
        timeFilterType: 'custom'
      }, () => this.handleSave());
    }
  };

  toISODate = (date: Date): string => {
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
  };

  handleSave(forceSave?: boolean): void {
    const { startDate, endDate, timeFilterType } = this.state;
    const { onlySaveOnClose, handleTimeRangeOptionsChange } = this.props;
    if (onlySaveOnClose && !forceSave) {
      return;
    }
    const formatedStartDate = `${this.toISODate(startDate)} 00:00:00`;
    const formatedEndDate = `${this.toISODate(endDate)} 23:59:59`;
    handleTimeRangeOptionsChange(timeFilterType || defaultTimeFilterType, formatedStartDate, formatedEndDate);
  }

  hideMenu = (): void => {
    const { onlySaveOnClose } = this.props;
    if (onlySaveOnClose) {
      this.handleSave(true);
    }
    document.body.removeEventListener('click', this.handleClickOutside as () => void, { capture: true });
    document.body.removeEventListener('keyup', this.handleEsc as () => void, { capture: true });
    this.setState({ isOpen: false });
  };

  getDateThresholdRange = (timeFilterType?: DateFilter): TimeRangeState => {
    let { startDate, endDate } = this.state;
    const { startAllTime } = this.state;
    const currDate = new Date();
    const newStartDate = new Date();
    const newEndDate = new Date();

    switch (timeFilterType) {
      case 'allTime':
        startDate = startAllTime;
        endDate = newEndDate;
        break;
      case 'today':
        startDate = newStartDate;
        endDate = newEndDate;
        break;
      case 'yesterday':
        newStartDate.setDate(currDate.getDate() - 1);
        startDate = newStartDate;
        endDate = newStartDate;
        break;
      case 'last7Days':
        newStartDate.setDate(currDate.getDate() - 6);
        startDate = newStartDate;
        endDate = newEndDate;
        break;
      case 'last30Days':
        newStartDate.setDate(currDate.getDate() - 29);
        startDate = newStartDate;
        endDate = newEndDate;
        break;
      case 'previousWeek':
        newStartDate.setDate(currDate.getDate() - (currDate.getDay() + 6));
        startDate = newStartDate;
        newEndDate.setDate(currDate.getDate() - currDate.getDay());
        endDate = newEndDate;
        break;
      case 'previousMonth':
        newStartDate.setDate(1);
        newStartDate.setMonth(newStartDate.getMonth() - 1);
        startDate = newStartDate;
        newEndDate.setDate(0);
        endDate = newEndDate;
        break;
      case 'thisYear':
        newStartDate.setDate(1);
        newStartDate.setMonth(0);
        startDate = newStartDate;
        endDate = newEndDate;
        break;
      case 'previousYear':
        newStartDate.setDate(1);
        newStartDate.setMonth(-12);
        startDate = newStartDate;
        newEndDate.setMonth(0);
        newEndDate.setDate(0);
        endDate = newEndDate;
        break;
      case 'custom':
        newStartDate.setDate(newStartDate.getDate() - 1);
        startDate = newStartDate;
        endDate = newEndDate;
        break;
      default:
        break;
    }

    return {
      startDate,
      endDate,
      selectedDate: endDate,
      timeFilterType,
      showDatePicker: 'start'
    } as TimeRangeState;
  };

  renderTimeRangeOptions(): ReactNode {
    const { timeFilterType } = this.state;
    const { hiddenCustomFilters } = this.props;
    return (
      <ul className="jSheetDateFormat forTimeRange">
        <li>
          {Object.keys(optionList).map(opt => {
            if (hiddenCustomFilters && hiddenCustomFilters.indexOf(opt as DateFilter) > -1) return;

            return (
              <button
                type="button"
                className="contextMenu-item"
                onClick={this.handleTimeRangeOptionClick(opt as DateFilter)}
                key={opt}
              >
                <span className={`contextMenu-itemLabel${timeFilterType === opt ? ' active' : ''}`}>
                  {t(optionList[opt as DateFilter])}
                </span>
              </button>
            );
          })}
        </li>
      </ul>
    );
  }

  renderCustomWarning(): ReactNode {
    const { customWarningEnabled, customWarning } = this.props;
    const { showCustomWarning } = this.state;
    if (!customWarningEnabled || !showCustomWarning) return null;

    return (
      <div className="jSheetDatePicker-custom-warning">
        <IconInfoCircle />
        {customWarning}
      </div>
    );
  }

  renderCustomDateOptions(): ReactNode {
    const {
      showDatePicker, startDate, endDate, selectedDate, isOpen, disableFuture
    } = this.state;
    const { maxRange, customWarningEnabled } = this.props;

    if (!isOpen) return null;

    let maxDate = disableFuture ? new Date() : undefined;
    let minDate;
    if (maxRange) {
      minDate = new Date(endDate);
      minDate.setDate(minDate.getDate() - maxRange);
      const newMaxDate = new Date(startDate);
      newMaxDate.setDate(newMaxDate.getDate() + maxRange);

      if (!maxDate || newMaxDate < maxDate) {
        maxDate = newMaxDate;
      }
    }

    return (
      <div className="jSheetTimeRange jSheetContextMenu" role="alert" aria-label="The date picker is open. Press escape to close.">
        <div className={`jSheetDatePicker-container${showDatePicker === 'start' ? ' showStartDate' : ' showEndDate'}${customWarningEnabled ? ' customWarningEnabled' : ''}`}>
          <DatePicker
            inline
            calendarClassName="jSheetDatePicker-startDate"
            selected={selectedDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            allowSameDay={true}
            onChange={this.handleChangeStart}
          />
          <DatePicker
            inline
            calendarClassName="jSheetDatePicker-endDate"
            selected={selectedDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            allowSameDay={true}
            onChange={this.handleChangeEnd}
          />
          {this.renderCustomWarning()}
        </div>
        <div className="jSheetDatePicker-buttons">
          {this.renderTimeRangeOptions()}
        </div>
      </div>
    );
  }

  getCustomButtonText = (): string => {
    const { timeFilterType, startDate, endDate } = this.state;
    const { showTimeFilterText } = this.props;

    const sMonth = startDate.toLocaleString('en-US', { month: 'long' });
    const eMonth = endDate.toLocaleString('en-US', { month: 'long' });
    const start = `${t(sMonth)} ${startDate.getDate()}, ${startDate.getFullYear()}`;
    const end = `${t(eMonth)} ${endDate.getDate()}, ${endDate.getFullYear()}`;

    let customButtonText = `${start} - ${end}`;

    if (timeFilterType === 'allTime') customButtonText = t('All Time');

    if (showTimeFilterText && timeFilterType !== 'custom' && Object.keys(optionList).indexOf(timeFilterType as DateFilter) !== -1) {
      customButtonText = t(optionList[timeFilterType as DateFilter]);
    }

    return customButtonText;
  };

  render(): ReactNode {
    const {
      timeFilterType, selectedDate
    } = this.state;
    const {
      iconTimeFilter, showOnlyCustomText, customWarningEnabled
    } = this.props;

    const customButtonText = this.getCustomButtonText();

    if (!selectedDate) {
      const dateTresholdRange = this.getDateThresholdRange(timeFilterType);
      this.setState({
        ...dateTresholdRange,
        showCustomWarning: customWarningEnabled && timeFilterType === 'custom'
      }, () => this.handleSave(true));
    }

    return (
      <div ref={this.wrapperRef as RefObject<HTMLDivElement>}>
        {
          showOnlyCustomText ? (
            <label className={classnames(labelClass, { forOnlyCustomText: true })} tabIndex={0}>
              {iconTimeFilter}
              <span className="jSheetButton-text">{customButtonText}</span>
            </label>
          ) : (
            /* eslint-disable */
            <>
              <label className={labelClass} onClick={this.handleClick} tabIndex={0} onKeyUp={this.handleEnter} aria-label='Opens the date picker'>
                {iconTimeFilter}
                <span className="jSheetButton-text">{customButtonText}</span>
              </label>
              {this.renderCustomDateOptions()}
            </>
            /* eslint-enable */
          )
        }
      </div>
    );
  }
}

TimeRange.defaultProps = {
  timeFilterText: defaultTimeFilterType,
  iconTimeFilter: <IconClock className="jSheetSVG-timeFilter" width="17" height="18" />,
  showTimeFilterText: false,
  disableFuture: false,
  customDateRangeValue: {},
  onlySaveOnClose: false,
  showOnlyCustomText: false,
  maxRange: null,
  hiddenCustomFilters: [],
  customWarningEnabled: false,
  customWarning: '',
  userLanguage: ''
};
