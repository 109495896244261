import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import { func, object } from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  PrefilledLeadFormAside, ASSET_IDENTIFIER, ALL_TEXTS, GA_FORM_TRACKING_ITEMS, userPropType,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import EnterpriseIcon from '../../../../assets/svg/enterpriseRocketBlue.svg';
import '../../../../styles/lunchAndLearnModal.scss';
import '@jotforminc/jotform.css';

const LunchAndLearnModal = (({
  user,
  modalProps,
  onCloseClick,
  onClose
}) => {
  const uikitModalRef = useRef(null);
  const { WEBINAR: { LUNCH_AND_LEARN_MODAL } } = ASSET_IDENTIFIER;
  const target = LUNCH_AND_LEARN_MODAL;
  let { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();
    onCloseClick();
    setEPActions({ asset: target, target: 'closeButton', action: 'seen' });
  };

  const handleFormSubmit = () => {
    setEPActions({ asset: target, target, action: 'formSubmit' });
  };

  const handleSubmitClick = () => {
    setEPActions({ asset: target, target, action: 'click' });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleSubmitClick]);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <div className="flex flex-col md:flex-row radius-md">

        <div
          className="flex flex-col items-start pt-4 md:pt-12 relative md:min-h-120 md:max-w-92 radius-tl-md radius-tr-md md:radius-tr-none md:radius-bl-md"
          style={{
            background: "url('https://cdn.jotfor.ms/assets/img/enterprise/lunch-and-learn-modal/bg.png') no-repeat",
            backgroundSize: 'cover'
          }}
        >
          <span
            className='mr-4 md:mr-10 ml-4 md:ml-10 font-circular font-medium text-sm md:text-md line-height-xl
            flex items-center pl-2 md:pl-3 pr-2 md:pr-3 md:pr-4 py-2 md:py-3 radius mb-3 md:mb-6 bg-white bg-opacity-40 color-blue-500 tracking-lg'
          >
            <EnterpriseIcon
              className="w-5 h-5 inline-flex mr-2 enterprise-icon"
            />
            {t(ALL_TEXTS.LUNCH_LEARN)}
          </span>

          <p className='mr-4 md:mr-10 ml-4 md:ml-10 font-circular font-medium text-lg md:text-2xl line-height-xl md:line-height-3xl mb-3 md:mb-4 color-navy-700 tracking-sm'>
            {t(ALL_TEXTS.LUNCH_LEARN_DESCRIPTION)}
          </p>

          <img
            alt={t(ALL_TEXTS.LUNCH_LEARN_DESCRIPTION)}
            src="//cdn.jotfor.ms/assets/img/enterprise/lunch-and-learn-modal/visual.png?v=1.4"
            className="hidden md:block w-full h-auto mt-auto"
          />
        </div>

        {/* aside */}
        <div className="right-col flex-1 p-0 relative">
          <PrefilledLeadFormAside
            user={user}
            isIframeEmbed={true}
            formID='230455407536960'
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.WEBINAR_BADGE)}
            formUiModifier={LUNCH_AND_LEARN_MODAL}
            classNames="form-iframe block w-full border-0 h-100vh md:min-h-120 radius-br-md radius-bl-md md:radius-tr-md px-0 md:px-4"
            onFormSubmit={handleFormSubmit}
          />
        </div>
      </div>
    </Modal>
  );
});

LunchAndLearnModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  onClose: func,
  onCloseClick: func
};

LunchAndLearnModal.defaultProps = {
  modalProps: {},
  onClose: f => f,
  onCloseClick: f => f
};

export default LunchAndLearnModal;
