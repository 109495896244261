import React, { useEffect, useState } from 'react';
import {
  bool, object, number,
  shape,
  arrayOf,
  string
} from 'prop-types';
import classNames from 'classnames';
import { Badge } from '@jotforminc/magnet';
import { t, translationRenderer } from '@jotforminc/translation';
import { Loading } from '@jotforminc/loading';
import { WizardHeader } from '@jotforminc/wizard-shared';
import {
  PRIMARY_PRODUCT_CONFIGS, SECONDARY_PRODUCT_CONFIGS, TEXTS
} from './constants';
import { ScWizardBody, ScWizardItem } from './ScWizardBody';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { LISTING_TYPES, TEAM_ENABLED_CREATION_RESOURCES } from '../../constants';

const MixAssetModal = ({
  isMobileDevice = false,
  hasUserForms = false,
  user = {},
  isSideBar = false,
  userFormCountLimit = null,
  isMixPageUser = false,
  teamPermissions = {},
  userPermissions = [],
  isTeamAssetCreation = false,
  selectedFolder = '',
  isAssetsFolderActive = false,
  accountType = ''
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userName = user?.name || user?.username || '';
  const assetModalContainer = document.querySelector('#create-asset-modal-container');

  const toggleModalVisibility = show => {
    if (show) {
      setIsLoading(false);
      assetModalContainer?.removeAttribute('style');
      return;
    }
    setIsLoading(true);
    assetModalContainer?.setAttribute('style', 'opacity: 0; pointer-events: none; height: 0; z-index: 499;');
  };

  useEffect(() => {
    toggleModalVisibility(true);
    WatchmanRecorder.trackEventForCustomProject('seen', `${isMobileDevice ? 'mobile-' : ''}mixAssetCreationModal`, 'mixAssetCreationModal', true);
  }, []);

  const handleOpenCreateProductWizard = productType => {
    window.openCreateProductWizard?.({
      productType,
      createAssetFrom: 'mixAssetModal',
      formCountLimit: userFormCountLimit,
      isMixPageUser,
      isSideBar,
      closeButtonCallback: () => {
        toggleModalVisibility(true);
      },
      openWizardCallback: () => {
        toggleModalVisibility(false);
      }
    });
  };

  const handleProductSelect = (type, name) => {
    if (isTeamAssetCreation) {
      WatchmanRecorder.trackEventForCustomProject('click', `teamPageSelectProduct - ${name}`, 'teams', true);
    } else {
      WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}${name}-mixAssetCreationModal`, 'mixAssetCreationModal', true);
    }
    setIsLoading(true);
    handleOpenCreateProductWizard(type);
  };

  const checkProductVisible = product => {
    const {
      name, createPermissionName, checkVisibility, userCreatePermissionType
    } = product;

    const hasPermission = isTeamAssetCreation ? teamPermissions[`create${createPermissionName || name}`] : userPermissions?.includes(userCreatePermissionType);

    if (!checkVisibility) return hasPermission;
    return hasPermission && checkVisibility({ user });
  };

  const primaryFilteredConfig = PRIMARY_PRODUCT_CONFIGS.filter(checkProductVisible);

  const secondaryFilteredConfig = SECONDARY_PRODUCT_CONFIGS.filter(checkProductVisible);

  if (isLoading) return <Loading />;

  return (
    <ScWizardItem>
      <WizardHeader
        noDecodeURI
        title={userName && accountType !== 'GUEST'
          ? translationRenderer(TEXTS.HEADER_TITLE_WITH_USERNAME)({
            renderer1: () => {
              return userName.split(' ')?.[0] || '';
            }
          })
          : t(TEXTS.HEADER_TITLE)}
        subTitle={t(TEXTS.HEADER_SUBTITLE)}
      />
      <ScWizardBody>
        <ul className='productItem-list primaryProducts withBetaProducts'>
          {primaryFilteredConfig
            .map(({
              name,
              description,
              Icon,
              type,
              isNew,
              productInfo
            }) => (
              <li>
                <button
                  key={name}
                  className={classNames('productItem', name.toLowerCase().replace(' ', ''), {
                    disabled: isTeamAssetCreation && (!TEAM_ENABLED_CREATION_RESOURCES.includes(type) || (
                      !!selectedFolder && type !== LISTING_TYPES.FORM && !isAssetsFolderActive
                    ))
                  })}
                  onClick={() => handleProductSelect(type, name.toLowerCase().replace(' ', ''))}
                  data-productCount={primaryFilteredConfig.length}
                  type="button"
                  tabIndex="0"
                  aria-label={t(name)}
                >
                  {Icon && <div className="productIconWrapper">{Icon}</div>}
                  <div className="productContentWrapper">
                    <div className='productName'>
                      {t(name)}
                      {!!productInfo && (<div className="productInfo">{`(${t(productInfo)})`}</div>)}
                      {isNew && (
                        <Badge colorStyle='success' className='text-xs text-uppercase newProduct' size="small">{t('New')}</Badge>
                      )}
                    </div>
                    <div className='productDescription'>{t(description)}</div>
                    <button type="button" className="productButton" aria-hidden="true">{t(`Create ${name}`)}</button>
                  </div>
                </button>
              </li>
            ))}
        </ul>
        {hasUserForms ? (
          <>
            <div className="divider" />
            <ul className='productItem-list secondaryProducts withBetaProducts'>
              {secondaryFilteredConfig
                .map(({
                  name,
                  description,
                  Icon,
                  type,
                  isBeta
                }) => (
                  <li>
                    <button
                      key={name}
                      className={classNames('productItem', name.toLowerCase().replace(' ', ''), {
                        disabled: isTeamAssetCreation && (!TEAM_ENABLED_CREATION_RESOURCES.includes(type) || (
                          !!selectedFolder && type !== LISTING_TYPES.FORM && !isAssetsFolderActive
                        ))
                      })}
                      onClick={() => handleProductSelect(type, name.toLowerCase().replace(' ', ''))}
                      data-productCount={secondaryFilteredConfig.length}
                      type="button"
                      tabIndex="0"
                      aria-label={t(name)}
                    >
                      { Icon && <div className="productIconWrapper"><Icon className='productIcon' /></div>}
                      <div className="productContentWrapper">
                        <div className='productName'>
                          {t(name)}
                          {isBeta && (
                            <Badge colorStyle='primary' className='text-xs text-uppercase betaProduct' size="small">{t('Beta')}</Badge>
                          )}
                        </div>
                        <div className='productDescription'>{t(description)}</div>
                      </div>
                    </button>
                  </li>
                ))}
            </ul>
          </>
        ) : null}
      </ScWizardBody>
    </ScWizardItem>
  );
};

MixAssetModal.propTypes = {
  user: object,
  isMobileDevice: bool,
  hasUserForms: bool,
  isSideBar: bool,
  userFormCountLimit: number,
  isMixPageUser: bool,
  teamPermissions: shape({}),
  userPermissions: arrayOf(string),
  isTeamAssetCreation: bool,
  selectedFolder: string,
  isAssetsFolderActive: bool,
  accountType: string
};

export default MixAssetModal;
