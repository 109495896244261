import '../../styles/Button.scss';
import React, {
  useState, useEffect, ReactElement
} from 'react';
import { t } from '@jotforminc/translation';
import Dropdown, { DropdownItem } from '../Dropdown';
import ActionRecorder from '../../utils/actionRecorder';
import { groupLogs } from '../ActivityLog/utils';
import { DownloadDropdownProps, DownloadStatus, IntervalID } from './types';
import { Button } from '../Button';
import { STATUS } from './constants';

function DownloadDropdown<SortOptions>({
  filters = {}, sort, searchTerm, logGroups, teamID, dropdownId, target, exportLogs, checkExportStatus, showToast = () => {}
}: DownloadDropdownProps<SortOptions>): ReactElement {
  let jobID: string;
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>({
    waiting: false,
    percentage: '0',
    status: null,
    downloadID: null
  });
  const [intervalID, setIntervalID] = useState<IntervalID>(null);
  const { waiting: loadingStatus } = downloadStatus;

  const startDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const checkStatus = async () => {
    if (!jobID) return;
    const statusRes = await checkExportStatus(jobID, teamID);
    if (statusRes) {
      const {
        status, percentage, url, id
      } = statusRes;
      if (status === STATUS.FAILED) {
        setDownloadStatus({
          waiting: false, status, percentage: '0', downloadID: null
        });
        showToast();
      } else if (status === STATUS.GENERATING) {
        setDownloadStatus({
          waiting: true, status, percentage, downloadID: id
        });
      } else if (status === STATUS.COMPLETED && percentage === '100' && url) {
        startDownload(url);
        setDownloadStatus({
          waiting: false, status, percentage, downloadID: null
        });
      }
    }
  };

  const startToCheckStatus = () => {
    const sid = setInterval(checkStatus, 1500);
    setIntervalID(sid);
  };

  const stopToCheckStatus = () => {
    if (intervalID) {
      clearInterval(intervalID);
    }
  };

  const onDownloadClick = async (type: string) => {
    ActionRecorder.tick({ action: `download${type.toUpperCase()}Clicked`, target });
    const { status } = downloadStatus;
    if (status !== STATUS.GENERATING) {
      const query = {
        ...filters,
        ...sort,
        ...{ type },
        ...(filters.action && logGroups?.length && { action: groupLogs(filters.action, logGroups) }),
        ...searchTerm ? { searchTerm } : {}
      };
      const resp = await exportLogs(query, teamID);
      if (resp?.id) {
        jobID = resp.id;
        setDownloadStatus({
          waiting: true, status: STATUS.GENERATING, percentage: '0', downloadID: resp.id
        });
        startToCheckStatus();
      } else {
        showToast();
      }
    }
  };

  useEffect(() => {
    const { waiting } = downloadStatus;

    if (waiting === false) {
      stopToCheckStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadStatus]);

  return (
    <Dropdown
      className="forDownloadForms w-fill xs:w-auto"
      ariaLabel='Download dropdown button'
      itemAriaRole='combobox'
      content={(
        <Button.Download
          className="w-full xs:w-auto"
          tabIndex={-1}
          loader={loadingStatus}
        >
          {t('Download')}
        </Button.Download>
      )}
      dropdownId={dropdownId}
    >
      <DropdownItem
        type="button"
        onClick={() => onDownloadClick('xlsx')}
      >
        {t('Download as Excel')}
      </DropdownItem>
      <DropdownItem
        type="button"
        onClick={() => onDownloadClick('csv')}
      >
        {t('Download as CSV')}
      </DropdownItem>
    </Dropdown>
  );
}

DownloadDropdown.defaultProps = {
  filters: {},
  sort: {},
  searchTerm: '',
  teamID: '',
  dropdownId: '',
  logGroups: [],
  showToast: () => {}
};

export default DownloadDropdown;
