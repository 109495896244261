/* eslint-disable max-len */
import styled from 'styled-components';

export const CustomizationWrapper = styled.div`
  background: #FFFFFF;
  max-width: 350px;
  width: 100%;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #B2B4CC;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);

  &.cover {
    max-width: 485px;
    width: 100%;

    // FIXME: This is a hack to fix the width of the cover
    .content-fileupload .imageUpload .tabContent .galleryWrapper > div .prevUploads-list .prevUploads-listItem {
      width: 100%;
      height: auto;
      border-radius: 4px;
      aspect-ratio: 16 / 9;

      img {
        height: 100% !important;
      }
    }
  }

  .heading {
    height: 40px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: #2E3345;
    padding: 12px 16px;
  }

  .content-heading {
    align-self: flex-start;
    color: #141E46;
    font-weight: 500;
    margin-bottom: 8px;
    position: relative;

    &.centering {
      align-self: center;
      margin: 0;
    }
  }

  .content-option-colorpicker {
    position: relative;

    input {
      background: #FFFFFF;
      border: 1px solid #B2B4CC;
      border-radius: 4px;
      color: #333A71;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 32px 8px 8px;

      html[dir="rtl"] & {
        padding: 8px 43px 8px 8px;
      }
    }
    .jfColors-picker {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 24px;
      height: 24px;
      border-radius: 2px;

      &:before {
        content: '';
        width: 1px;
        height: 34px;
        background-color: #B2B4CC;
        display: block;
        position: absolute;
        right: 30px;
        top: -5px;
      }
    }
  }

  .content {
    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      width: 100%;

      &.col {
        flex-direction: column;

        input {width: 100%}
      }
    }

    .double-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      flex: 1 0 auto;
      flex-wrap: wrap;
      z-index: 5;
      position: relative;

      &.midSize {
        max-width: 70%;

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }

      .col {
        width: 50%;
      }
    }

    .forBody {
      @media screen and (max-width: 600px) {
        max-height: 35vh;
      }

      @media screen and (max-height: 568px) {
        max-height: 20vh;
      }
    }
  }

  .content-fileupload {
    width: 100%;
    border: 1px solid #B2B4CC;
    border-radius: 4px;

    div[data-tabid="unsplash"] {
      .prevUploads-listItem {
        height: auto;
        cursor: pointer;

        img {
          width: 100%;
          max-height: 100% !important;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }

        p {
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 10px;
          color: #999;
          overflow: hidden;
          height: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          a {
            color: inherit;
            margin-left: 2px;
          }
        }
      }
    }

    .imageUpload {
      .tabMenu {
        border-bottom: 1px solid #B2B4CC
      }
      
      .tabMenu-inner-link {
        padding: 6px 0;
        font-size: 12px;
        color: #333A71;
        border-bottom-width: 2px;
        border-right: 1px solid #B2B4CC;
        display: grid;
        place-items: center;
        line-height: 1;

        &:last-of-type {
          border-right: none;
        }

        &.is-active {
          border-bottom-color: #18235C;
          border-bottom-width: 2px;
        }
      }

      .tabUnsplash {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      .tabContent {
        padding: 12px 8px;
        max-height: 300px;
        overflow: auto;
        min-width: 314px;

        .galleryWrapper {
          > div {
            color: #9FA0B9;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 16px;

            .prevUploads-list {
              border: none;
              padding: 0;
              margin: 6px auto 24px;
              min-height: auto;
              max-height: auto;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              padding: 4px;

              @media (max-width: 768px) {
                gap: 1vw;
              }

              .prevUploads-listItem {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                margin: 5px;
                cursor: pointer;
                margin: 0;

                img {
                  width: 100%;
                  height: 100% !important;
                  object-fit: cover;
                }
              }
            }
          }
        }

        div[data-tabid="upload"] {
          label.fileUploadLabel {
            background-color: #0099FF;
          }
          .fileUpload {
            min-height: 135px;

            &-verticalAlign-text {
              color: #9FA0B9;
              font-size: 12px;
              line-height: 16px;
              margin-top: 8px;
            }
          }
        }

        div[data-tabid="unsplash"] {
          .prevUploads-list {
            border: none;
            overflow: auto;
            margin: 0;
            padding: 0;

            display: grid;
            grid-gap: 8px;
            grid-template-columns: repeat(auto-fit, minmax(102px, 1fr));
            margin-top: 12px;

            .prevUploads-listItem {
              width: 100%;
              max-width: 100%;
              float: initial;
              overflow: initial;
              margin: initial;
            }
          }

          .searchBox {
            background-color: #F5F6FE;
            border: 1px solid #C8CEED;
            border-radius: 4px;
            font-size: 14px;
            line-height: 18px;
          }
        }

        div[data-tabid="link"] {
          label {
            display: flex;

            input {
              background: #F5F6FE;
              border: 1px solid #C8CEED;
              border-radius: 4px;
              color: #797EA8;
            }
          }

          .line {
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a.btn {
              background-color: #0099FF;
              border-radius: 4px;
              line-height: 1.5;

              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .withBorder {
    border-bottom: 1px solid rgba(225, 226, 236, 0.7);
  }

  .jfTabs-body {
    padding: 0;
  }

  .icon-selector-wizard {
    padding: 0;

    svg {
      fill: #18235C;
    }
  }

  .icon-tab-wrapper {
    width: 100%;

    .jfTabs {
      border: 1px solid #B2B4CC;
      border-radius: 4px;
    }

    .forTabs {      
      z-index: 4;

      .jfTabs-tabs {
        min-height: 32px;
        height: 32px;
        border-bottom: 1px solid #B2B4CC;

        &-button {
          color: #333A71;
          font-size: 12px;
          line-height: 16px;
          border-right: 1px solid #B2B4CC;

          &:last-of-type {
            border-right: none;
          }
        }
      }
    }

    .forBody {
      .iconSelectorPanel {
        .tabMenu {
          background-color: #F3F3FE;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 8px;

          .tabMenu-link.is-active {
            position: relative;

            &:after {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: #0061FF;
              bottom: -7px;
              left: 0;
            }
          }

          input {
            margin: 0;
            background-color: #FFF !important;
            color: #797EA8;
            background-position: 5%;
            padding: .625em .625em .625em 40px;
          }

          a {
            cursor: pointer;

            svg {
              fill: #797EA8;
            }
          }
        }

        .tabContent {
          .SeachIconWrapper {
            input {
              margin: 0;
              padding: 8px 0 8px 36px;
              background-color: transparent !important;
              color: #797EA8;
              background-position: 12px center;
              border-bottom: 1px solid #E1E2EC;
              border-radius: 0 !important;
            }
          }
          .LogoIconsWrapper {
            .content-item-wrapper {
              border-radius: 50%;
            }
            .content-item-label {
              border-radius: 50%;

              &:hover {
                background-color: #7698EF;

                svg {
                  fill: #B4C8FC;
                }
              }
            }
          }
        }
      }
      .emoji-mart {
        button:not(.emoji-mart-search-icon) {
          border: none;
          padding: 8px;
        }

        &-search-icon {
          padding: 0 4px 0 0;
          border: none;
          min-height: auto;
        }

        &-category-label {
          color: #9FA0B9;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          text-align: left;
        }
      }
    }
  }
`;

export const CoverImgWrapper = styled.div`
  svg.jfLoading-loader {
    width: 40px;
    top: 50px;
    left: 50px;
  }
`;
export const CoverWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  .teamCardWrapper &:hover {
    .teamcover .teamcover-container {
      transform: translateY(-100px);
    }

    .teamcover-bg::before {
      background: initial;
    }

    .teamcover-container {
      bottom: 8px;
    }
    .teamName {
      margin-top: 8px;
    }
    
    .teamMembers-count-normal {
      display: none
    }
    .teamMembers-count-hover {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    z-index: 0;

    .isMyaccount & {
      position: relative;
      z-index: 20;
    }
  }

  body.jfWizard--isVisible:not(.jfWizard--isModalView) & {
    display: none;
  }

  .teamcover {
    width: 100%;
    min-height: 200px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    // overflow: hidden;
    transition: all .2s;

    &-container {
      position: relative;
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &-imageCropper {
      position: relative;

      .wrapper {
        height: 200px;
        position: relative;
      }
    
      .wrapperButtons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 51px;

        position: absolute;
        right: 0;
        bottom: 4px;
        background: #FFFFFF61;
        padding: 8px;
        border-radius: 4px;
    
        button {
          cursor: pointer;
          transition: .2s all ease;
          margin: 0;
          padding: 0;
          border: none;
          outline: none;
          font-family: inherit;
          border-radius: 4px;
          width: 72px;
          height: 28px;
          font-size: 12px;
        }
        .wrapperCancel {
          background-color: #dddfe9;
          color: #23283a;
          margin-right: 12px;
        }
        .wrapperSave {
          background-color: #4573e3;
          color: #fff;
          margin-right: 15px;
        }
      }

      &-info {
        position: absolute;
        z-index: 10;
        color: #141E46;
        top: 50%;
        background: rgba(255, 255, 255, 0.8);
        padding: 8px;
        border-radius: 4px;
        left: 50%;
        margin: -16px 0 0 -96px;
        pointer-events: none;
      }
    }

    &-avatar {
      position: relative;
      background-color: transparent;
      border: none;
      width: 64px;
      margin: 0 auto;

      &-wrapper {
        width: 64px;
        height: 64px;
        border: 2px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        padding: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
    
        &.hasBgColor {
          // padding: 12px;
        }
    
        .jfIconSVG-wrapper {
          width: 100%;
          height: 100%;
          padding: 12px;
        }
    
        .emoji-mart-emoji {
          // transform: scale(.7);
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          
          span {
            font-size: 40px;
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            //margin: 2px 0 0 0;
          } 
    
          .emoji-mart-search {
            .emoji-mart-search-icon {
              //padding: 0 4px 0 0 !important; // FIXME
            }
          }
        }
      } 
      
      img {
        width: 100%;
        background-size: cover;
        height: 100%;
      }
    
      &-edit {
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: #D3DCEF;
        border-radius: 50%;
        padding: 4px;
        right: -2px;
        bottom: -2px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));        
      }
    
      .hoverSettingButton {
        opacity: 1;
        transition: all .1s ease;
      }
      
      &:hover {
        .hoverSettingButton {
          opacity: 1;
        }
      }
    }

    &-name {
      color: #fff;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      margin: 8px 0 0 0;
      word-break: break-all;
    }

    &-members {
      position: absolute;
      top: 0;
      right: 12px;
      z-index: 15;
      display: flex;
      justify-content: center;
      align-items: center;

      html[dir="rtl"] & {
        right: initial;
        left: 12px;
      }
    }

    &-btnset {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 12px;

      & > * {
        margin-left: 4px;
      }

      .teamSettingsButton {
        padding: 4px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.8);
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: background .2s ease;
        z-index: 2;
        order: 2;
      
        &:hover + .teamcover-btnset-customization {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0);
        }
      }

      &-customization {
        display: flex;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        border: none;
        transition: background-color .2s ease;

        order: 1;

        button {
          display: flex;
          justify-content: center;
          align-items: center;

          border: none;
          background-color: transparent;
          padding: 4px 8px;
          color: #141E46;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          border-right: 1px solid #141E46;

          &:last-of-type {
            border-right: none;
          }

          svg {
            width: 12px;
            margin-right: 4px;

            html[dir="rtl"] & {
              margin-right: 0px;
              margin-left: 4px;
            }
          }
        }
      }
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      z-index: 0;
      border: none;
      margin: 0;
      padding: 0;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(159, 160, 185, 0.6) 0%, rgba(159, 160, 185, 0) 123.33%);
        mix-blend-mode: multiply;
      }

      &-wrapper {
        position: relative;
        top: 0;
        left: 0;
        transition: background .3s ease;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
      }

      &-img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        position: absolute;
        inset: 0;
        z-index: 1;
        will-change: transform;    
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-invite {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 12px 0 12px auto;

      ul {
        display: flex;

        li {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: -6px;
          
          img {
            width: 100%;
            object-fit: cover;
            height: 100%;
          }

          button {
            border: none;
            width: 100%;
            height: 100%;
            color: #6F76A7;
            background-color: #E2E6F9;
            display: flex;
            margin: 0;
            padding: 0;
            justify-content: center;
            align-items: center;

            width: 32px;
            height: 32px;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid rgb(200, 206, 237);
          }

          .jfTooltipNew-body {
            span {
              display: block;
              margin-bottom: 8px;
              text-align: left;

              &:nth-child(1) {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
              }
              &:nth-child(2) {
                color: #a9aacb;
              }
              &:nth-child(3) {
                background-color: #404b6b;
                border-radius: 30px;
                color: #f3f7ff;
                font-size: 12px;
                padding: 8px 16px;
                align-self: flex-start;
                margin-bottom: 0;
                white-space: nowrap;
              }
            }
          }
        }
      }

      > button {
        background: rgba(255, 255, 255, 0.8);
        padding: 4px 12px;
        border-radius: 4px;
        border: none;
        color: #141E46;
        font-weight: 500;
        line-height: 24px;
        font-size: 12px;
        transition: background .2s ease;
        margin-left: 8px;

        html[dir="rtl"] & {
          margin-left: 0;
          margin-right: 8px;
        }

        &:hover {
          background: rgba(255, 255, 255, 1);
        } 
      }
    }

    &-cta {
      background: #0A1551;
      border-radius: 4px;
      border: none;
      color: #FFF;
      padding: 6px;
      line-height: 22px;
    }

    &-select {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      label {
        color: #141E46;
        font-size: 16px;
        line-height: 21px;
        margin-right: 12px;
      }

      select {
        background: #E3E5F5;
        border-radius: 4px;
      }
    }

    &-wrapper {
      z-index: 7;
      width: 100%;

      [data-popper-placement] {
        z-index: 7;
      }

      &.isEditable {
        z-index: 8;
        .teamcover-bg {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  }
`;
