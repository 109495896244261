import { RequestLayer } from '@jotforminc/request-layer';

const apiResponseInterceptor = (ctx: any) => {
  const { resp } = ctx;

  if (resp && resp.data.message && typeof resp.data.message.responseCode === 'number' && (resp.data.message.responseCode < 200 || resp.data.message.responseCode > 299)) {
    ctx.result = Promise.reject(new Error(resp?.data?.message));
  }
};

export const requestLayer = new RequestLayer(
  '/ai-builder/FORM', {
    interceptorConfig: {
      customResponseInterceptors: [apiResponseInterceptor],
      disableResponseNormalizer: true,
      shouldCache: true,
      logCachePayload: {
        projectName: 'ai-builder'
      }
    }
  }
);
