import { string } from 'prop-types';
import React from 'react';
import Styled from 'styled-components';
import {
  AppsLoader, PdfLoader, ReportsLoader, WorkFlowLoader, AiBuilder
} from '@jotforminc/apploaders';

const loaders = {
  'LISTING@PORTAL': AppsLoader,
  'LISTING@REPORT': ReportsLoader,
  'LISTING@TASK': WorkFlowLoader,
  'LISTING@DOCUMENT': PdfLoader,
  'LISTING@AGENT': AiBuilder
};

const ScAvatar = Styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .avatar-circle {
    position: relative;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .icon-animation {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: white;
    z-index: 2;
    right: -4px;
    bottom: -4px;

    & > div {
      width: 24px;
      height: 24px;
      max-width: unset;
      max-height: unset;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
`;

const Icon = ({ avatarURL, currentListing }) => {
  const AppsLoading = loaders[currentListing];
  return (
    <ScAvatar>
      <div className="avatar-circle">
        <img src={avatarURL} alt="avatar_url" />
        <div className="icon-animation">
          <AppsLoading />
        </div>
      </div>
    </ScAvatar>
  );
};

Icon.propTypes = {
  avatarURL: string.isRequired,
  currentListing: string.isRequired
};

export default Icon;
