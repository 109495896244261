export const notificationConfig = {
  languageSwitch: {
  },
  hipaaCompliance: {
  },
  paymentOverdue: {
  },
  unreadSupportAnswers: {
  },
  uxrSurvey: {
  },
  appRecommendation: {
    localStActionKey: 'notification-cent-app-recommendation'
  },
  storeRecommendation: {
    localStActionKey: 'notification-cent-store-recommendation'
  },
  newsletter: {
  },
  signTry: {
    localStActionKey: 'notification-trysign'
  },
  signAnnouncement: {
    localStActionKey: 'notification-sign'
  },
  hipaaEnforcement: {
    localStActionKey: 'notification-hipaa-enforcement'
  },
  upToFiftyPercentDiscount: {
    localStActionKey: 'notification-up-to-fifty-percent-discount',
    autoVisibleLocalStActionKey: 'notification-up-to-fifty-percent-discount-autovisible'
  },
  hubspotMilestone: {
    localStActionKey: 'notification-hubspot-milestone'
  },
  // auto visible notifications
  // you can run only one auto visible notification with url param and to see that
  // which one check the shouldShowAutoVisibleNotification util on myforms
  lunchAndLearn: {
    actionTargetName: 'lunch-and-learn-notification',
    apiAssetName: 'lunchAndLearnWebinarNotification',
    autoVisibleLocalStActionKey: 'notification-lal-autovisible'
  },
  enterprisePriceAdjustment: {
    apiAssetName: 'fomoA1FirstNotification',
    localStActionKey: 'notification-price-adjustment-a1-third',
    autoVisibleLocalStActionKey: 'notification-fomo-a1-third-autovisible'
  },
  resellerLaunch: {
    apiAssetName: 'resellerLaunch',
    localStActionKey: 'resellerLaunch',
    autoVisibleLocalStActionKey: 'resellerLaunch-autovisible'
  },
  mentalHealthClinic: {
    apiAssetName: 'mentalHealthClinic',
    localStActionKey: 'mentalHealthClinic',
    autoVisibleLocalStActionKey: 'nc-mhc-autovisible'
  },
  fqhc: {
    apiAssetName: 'fqhc',
    localStActionKey: 'fqhc',
    autoVisibleLocalStActionKey: 'nc-fqhc-autovisible'
  }
};
