import Styled from 'styled-components';

export const ScNotificationList = Styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;


  &>li{
    &:last-child{
      border-bottom: none;
    }
  }
`;
