import React from 'react';
import { toast } from 'react-toastify';
import { translationRenderer, t } from '@jotforminc/translation';
import { isPlural } from '../../utils';
import { MOVE_STATUS_ACTION_RESULTS, MOVE_TO_USER_PRODUCT_NAMES, NAMING_MAP } from '../../constants';
import translate from '../../utils/translate';

export class ListActionToast {
  constructor(currentPage) {
    this.currentPage = currentPage;
  }

  // eslint-disable-next-line complexity, max-statements
  updateToastContent = ({
    value, selected = [], actions, target, movedAssetsInfo, movedAsset, relatedCount, type, details
  }) => {
    const productName = NAMING_MAP[this.currentPage] || this.currentPage;
    const pluralOrSingularName = translate(isPlural(selected.length) ? `${productName}s` : `${productName}`);
    const selectedText = `${selected.length} ${isPlural(selected.length) ? `${productName}s` : productName}`;
    const moveAssetsInfoName = movedAssetsInfo?.title || movedAssetsInfo?.name || movedAssetsInfo;

    if (value === 'ENABLED') {
      const capitalPluralOrSingularName = pluralOrSingularName.charAt(0).toUpperCase() + pluralOrSingularName.slice(1);
      if (productName === 'board') {
        const toastMessage = selected.length > 1
          ? translationRenderer(`[1[{itemCount}]] ${pluralOrSingularName} restored successfully`)({
            renderer1: () => selected.length
          })
          : t(`${capitalPluralOrSingularName} restored successfully`);
        return (
          <>
            {toastMessage}
          </>
        );
      }
      return (
        <>
          {translationRenderer(`[1[{itemCount}]] ${pluralOrSingularName} restored successfully.`)({
            renderer1: () => selected.length
          })}
        </>
      );
    }

    if (value === 'ARCHIVED') {
      const itemCount = selected.length;
      const entityName = pluralOrSingularName;
      const capitalEntityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
      if (productName === 'board') {
        const toastMessage = itemCount > 1
          ? translationRenderer('[1[{itemCount}]] [2[{entityName}]] archived successfully')({
            renderer1: () => itemCount,
            renderer2: () => entityName
          })
          : t(`${capitalEntityName} archived successfully`);
        return (
          <>
            {toastMessage}
            <button type='button' onClick={actions.restore}>{translate('Undo')}</button>
          </>
        );
      }
      return (
        <>
          {translationRenderer('[1[{itemCount}]] [2[{entityName}]] moved to archive.')({
            renderer1: () => itemCount,
            renderer2: () => entityName
          })}
          <button type='button' onClick={actions.restore}>{translate('Undo')}</button>
        </>
      );
    }

    if (value === 'PURGED') {
      const capitalEntityName = pluralOrSingularName.charAt(0).toUpperCase() + pluralOrSingularName.slice(1);
      if (productName === 'board') {
        const toastMessage = selected.length > 1
          ? translationRenderer('[1[{itemCount}]] [2[{entityName}]] deleted permanently')({
            renderer1: () => selected.length,
            renderer2: () => pluralOrSingularName
          })
          : t(`${capitalEntityName} deleted permanently`);
        return (
          <>
            {toastMessage}
          </>
        );
      }
      return (
        <>
          {translationRenderer(`[1[{itemCount}]] ${pluralOrSingularName} permanently deleted.`)({
            renderer1: () => selected.length
          })}
        </>
      );
    }
    if (value === 'TRASHED') {
      let itemCount = selected.length;
      let entityName = pluralOrSingularName;
      if (productName === 'board' && itemCount === 1) {
        // the reason of the use let value is only dynamic values changed for this status(trashed) belongs to board
        // In other cases all texts changed for board. For this reason used let variables in this condition
        itemCount = '';
        entityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
      }
      return (
        <>
          {translationRenderer(
            '[1[{itemCount}]] [2[{entityName}]] moved to trash.'
          )({
            renderer1: () => itemCount,
            renderer2: () => entityName
          })}
          &nbsp;
          {
            (productName !== 'sign document' ? t('Deleted {productName} will be permanently deleted after 30 days.').replace('{productName}', translate(`${productName}s`)) : '')
          }
          <button type='button' onClick={actions.restore}>{translate('Undo')}</button>
        </>
      );
    }

    if (value === 'RENAME') {
      const product = productName ? productName.charAt(0).toUpperCase() + productName.slice(1) : 'Asset';
      return translate(`${product} renamed successfully.`);
    }

    if (value === 'MOVED_TO_TEAM') {
      const { initiators, relatedCount: _rCount } = details;
      return (
        <div className='move-team-toast'>
          <h2>{translate('Transfer in progress')}</h2>
          {_rCount > 0
            ? translationRenderer(
              `[1[table]] ${(movedAssetsInfo?.title || movedAssetsInfo?.name) ? '' : 'assets'} and ${initiators.length > 1 ? 'their' : 'its'} connected assets are being moved to [2[teamName]] team.`
            )({
              renderer1: () => (
                <b>{moveAssetsInfoName || selectedText}</b>
              ),
              renderer2: () => (
                <b>{target}</b>
              )
            })
            : translationRenderer(`[1[table]] ${(movedAssetsInfo?.title || movedAssetsInfo?.name) ? '' : 'assets'} ${initiators.length > 1 ? 'are' : 'is'} being moved to [2[teamName]] team.`)({
              renderer1: () => (
                <b>{moveAssetsInfoName || selectedText}</b>
              ),
              renderer2: () => (
                <b>{target}</b>
              )
            })}
        </div>
      );
    }

    if (value === 'MOVED_FROM_TEAM') {
      const movedTo = MOVE_TO_USER_PRODUCT_NAMES[this.currentPage] || 'forms';
      return translate(`${selectedText} moved to my ${movedTo}.`);
    }

    if (value === 'PURGE_SIGN_DOCS') {
      return translate('Sign documents can not be purged.');
    }

    if (value === 'MOVE_JOB_TOAST') {
      let text = '';
      let title = 'Transfer Completed';
      if (type === 'success') {
        text = MOVE_STATUS_ACTION_RESULTS[type].single;
        if (relatedCount > 0) {
          text = MOVE_STATUS_ACTION_RESULTS[type].multiple;
        }
      } else if (type === 'error') {
        title = 'Transfer Failed';
        text = MOVE_STATUS_ACTION_RESULTS[type];
      }

      return (
        <div className='move-team-toast'>
          <h2>{translate(title)}</h2>
          {translationRenderer(text)({
            renderer1: () => (
              movedAsset.title ? (
                <b>{movedAsset.title}</b>
              ) : movedAsset.type
            )
          })}
        </div>
      );
    }

    if (value === 'MOVE_FROM_TEAM_TOAST') {
      const { initiators, relatedCount: _rCount } = details;
      const toBe = initiators.length > 1 ? 'are' : 'is';
      const assetName = initiators.length === 1 ? initiators[0].title : `${initiators.length} assets`;
      let text = `[1[asset_name]] ${toBe} being moved.`;
      if (_rCount > 0) {
        text = `[1[asset_name]] and ${initiators.length > 1 ? 'their' : 'its'} connected assets are being moved.`;
      }

      return (
        <div className='move-team-toast'>
          <h2>{translate('Transfer in progress')}</h2>
          {translationRenderer(text)({
            renderer1: () => (
              <b>{assetName}</b>
            )
          })}
        </div>
      );
    }

    if (value === 'MULTIPLE_SELECT_WITH_SHIFT') {
      return translate(`Hold shift and mouse click to select/deselect multiple ${productName}s.`);
    }
  };

  updateToast = (contentProps, toastProps = { }) => {
    const content = this.updateToastContent(contentProps);
    toast.info(content, toastProps);
  };

  errorToast = errorMessage => {
    toast.error(translate(errorMessage));
  };
}
