import React, {
  useRef, forwardRef, useImperativeHandle, useEffect
} from 'react';
import {
  node, element, oneOfType, elementType, func, bool, arrayOf, shape, string
} from 'prop-types';
import {
  Popover, SelectionGroup, Hooks, Button
} from '@jotforminc/uikit';
import classNames from 'classnames';

const PopoverMenu = forwardRef(({
  options = [],
  children = null,
  useHover = false,
  extraVisibilityCheck = undefined,
  ButtonRenderer = Button,
  RenderWhenVisible = null,
  onButtonClick = f => f,
  popoverProps = {},
  onSelectionChange = f => f,
  otherRefs = [],
  MenuRenderer = props => (<SelectionGroup {...props} isMultiSelect={false} OptionContainerRendererProps={{ tabIndex: 0 }} />),
  withoutChildren = false,
  selectSingleItem = f => f,
  popoverButtonRendererClassname = '',
  onPopoverPlacementChange = f => f,
  useOnlyContent = false,
  isMenuOpen = false,
  onVisibilityChange = f => f,
  additionalContent = null,
  buttonLabel = '',
  autoFocus = false,
  autoFocusToFirstChild = false,
  ...selectionGroupProps
}, ref) => {
  const menuRef = useRef();
  const buttonRef = useRef();
  const menuContentRef = useRef();
  const [isMenuVisible, setMenuVisibility] = Hooks.useClickOutsideState(isMenuOpen, [menuRef, buttonRef, ...otherRefs.filter(val => val)]);

  const handleButtonClick = e => {
    if (useHover) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    if (onButtonClick && typeof onButtonClick === 'function') {
      onButtonClick(isMenuVisible);
    }

    selectSingleItem();
    setMenuVisibility(!isMenuVisible);
  };

  const onSelect = option => {
    if (!Array.isArray(option)) {
      const selectedOption = options.find(({ value }) => value === option);
      if (!selectedOption || !selectedOption.preventHideMenu) {
        setMenuVisibility(false);
      }
    }

    onSelectionChange(option);
  };

  const handleMouseEnter = () => {
    if (!useHover) {
      return;
    }

    setMenuVisibility(true);
  };

  const handlePopoverPlacementChange = placement => {
    if (placement) {
      onPopoverPlacementChange(placement);
    }
  };

  const _isMenuVisible = isMenuVisible && (extraVisibilityCheck === undefined || !!extraVisibilityCheck);

  useImperativeHandle(ref, () => ({
    menuRef,
    setMenuVisibility,
    isMenuRendered: _isMenuVisible
  }));

  useEffect(() => {
    if (isMenuOpen) {
      setMenuVisibility(isMenuOpen);
    }

    return () => {
      onVisibilityChange(false);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (_isMenuVisible && autoFocus) {
      // setTimeout(() => {
      if (menuContentRef?.current) {
        menuContentRef.current.focus();
      }
      // });
    }
  }, [_isMenuVisible]);

  return (
    <>
      {withoutChildren ? (
        <ButtonRenderer
          ref={buttonRef}
          onClick={handleButtonClick}
          onMouseEnter={handleMouseEnter}
          className={_isMenuVisible ? 'isMenuVisible' : ''}
        />
      )
        : (
          <ButtonRenderer
            ref={buttonRef}
            onClick={handleButtonClick}
            onMouseEnter={handleMouseEnter}
            className={classNames(popoverButtonRendererClassname)}
            aria-label={buttonLabel}
            role="button"
            tabIndex={0}
          >
            {typeof children === 'function' ? children(isMenuVisible) : children}
            {isMenuVisible && RenderWhenVisible}
          </ButtonRenderer>
        )}

      {_isMenuVisible && (
        <>
          {useOnlyContent ? (
            <>
              <MenuRenderer
                {...selectionGroupProps}
                options={options}
                onSelectionChange={onSelect}
                ref={menuRef}
              />
              {additionalContent}
            </>
          ) : (
            <Popover
              {...popoverProps}
              ref={menuRef}
              targetRef={buttonRef}
              onPlacementChange={handlePopoverPlacementChange}
            >
              <MenuRenderer
                {...selectionGroupProps}
                options={options}
                onSelectionChange={onSelect}
                autoFocusToFirstChild={autoFocusToFirstChild}
                ref={menuContentRef}
              />
              {additionalContent}
            </Popover>
          )}
        </>
      )}
    </>
  );
});

PopoverMenu.propTypes = {
  onSelectionChange: func,
  children: oneOfType([node, element]),
  popoverProps: Popover.propTypes,
  ButtonRenderer: elementType,
  onButtonClick: func,
  RenderWhenVisible: oneOfType([node, element]),
  useHover: bool,
  otherRefs: arrayOf(shape),
  extraVisibilityCheck: bool,
  withoutChildren: bool,
  MenuRenderer: elementType,
  selectSingleItem: func,
  options: arrayOf(shape({})),
  popoverButtonRendererClassname: string,
  onPopoverPlacementChange: func,
  useOnlyContent: bool,
  isMenuOpen: bool,
  onVisibilityChange: func,
  additionalContent: elementType,
  buttonLabel: string,
  autoFocus: bool,
  autoFocusToFirstChild: bool
};

export default PopoverMenu;
