import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/church.scss';
import ChurchImage from '../assets/campaigns/2024/church/church.svg';

export const ChurchIndustry = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="church-industry-wrapper">
        <div className="church-industry-container">
          <div className="image">
            <ChurchImage />
          </div>
          <div className="title-area">
            <div className="top-title">
              {t('Nonprofits')}
            </div>
            <div className="bottom-title">
              {t('SAVE 50%')}
            </div>
          </div>
          <div className="action-button">
            <a
              className="locale"
              target="_blank"
              href={`/online-church/pricing/?utm_campaign=church-2024&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
            >
              {t('Get Discount')}
            </a>
          </div>

        </div>
      </div>

    </>
  );
};

ChurchIndustry.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderChurchIndustry = ({
  container, accountTypeName, utmSource, username
}) => {
  const root = createRoot(container);
  root.render(
    <ChurchIndustry
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
