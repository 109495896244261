/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import axios from 'axios';
import { getEnterpriseLogo } from '@jotforminc/request-layer';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { setCookie, readCookie } from '@jotforminc/utils';

import './styles.scss';

const CloseIcon = ({ fill, stroke }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    stroke={stroke}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0301 0.625582C11.5576 0.153102 10.7915 0.153166 10.319 0.625723L6.32803 4.61668L2.41345 0.702095C1.94097 0.229615 1.17486 0.229679 0.702305 0.702236C0.229747 1.17479 0.229684 1.9409 0.702163 2.41338L4.61675 6.32796L0.62579 10.3189C0.153232 10.7915 0.153169 11.5576 0.625648 12.0301C1.09813 12.5025 1.86423 12.5025 2.33679 12.0299L6.32774 8.03896L10.2423 11.9535C10.7148 12.426 11.4809 12.426 11.9535 11.9534C12.426 11.4808 12.4261 10.7147 11.9536 10.2423L8.03903 6.32768L12.03 2.33672C12.5025 1.86417 12.5026 1.09806 12.0301 0.625582Z" fill="white"
    />
  </svg>
);
CloseIcon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string
};

CloseIcon.defaultProps = {
  fill: 'none',
  stroke: 'none'
};
const AppIcon = () => (isEnterprise()
  ? (
    <img
      alt="mobile-app-sheet-img"
      src={getEnterpriseLogo()}
      className='mobile-app-sheet-img'
    />
  )
  : (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="39"
        height="39"
        rx="8.775"
        fill="white"
      />
      <path d="M14.1184 31.0822C14.608 31.5569 14.262 32.3702 13.5667 32.3702H9.2153C8.35677 32.3702 7.6582 31.693 7.6582 30.8607V26.6424C7.6582 25.9683 8.49714 25.6329 8.9868 26.1076L14.1184 31.0822Z" fill="#0A1551" />
      <path d="M20.9204 31.4103C19.6323 30.1307 19.6323 28.056 20.9204 26.7764L25.5688 22.1582C26.8569 20.8786 28.9452 20.8786 30.2332 22.1582C31.5212 23.4378 31.5212 25.5125 30.2332 26.7922L25.5847 31.4103C24.2967 32.69 22.2084 32.69 20.9204 31.4103Z" fill="#FFB629" />
      <path d="M8.6396 19.8061C7.35158 18.5265 7.35158 16.4518 8.6396 15.1722L16.4625 7.39478C17.7505 6.11515 19.8388 6.11515 21.1269 7.39478C22.4149 8.67441 22.4149 10.7491 21.1269 12.0287L13.304 19.8061C12.0159 21.0857 9.92763 21.0857 8.6396 19.8061Z" fill="#0099FF" />
      <path d="M15.0351 25.3496C13.7471 24.07 13.7471 21.9953 15.0351 20.7156L25.6053 10.2144C26.8933 8.93473 28.9816 8.93473 30.2696 10.2144C31.5576 11.494 31.5576 13.5687 30.2696 14.8483L19.6995 25.3496C18.4114 26.6292 16.3231 26.6292 15.0351 25.3496Z" fill="#FF6100" />
    </svg>
  ));

const isiOS = () => window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
const isAndroid = () => window.navigator.userAgent.match(/Android/i);
const isMobile = () => isAndroid() || isiOS();

// window.mobileDeeplink for Custom Enterprise apps.
// and if it exists, we will show the sheet for the Enterprises which has a custom mobile app.
const isCustomEnterpriseApp = !!window.mobileDeeplink;
async function fetchTheme() {
  return axios.get('/API/enterprise/theme/current?detailed=1');
}
function MobileAppSheet({
  pages, project
}) {
  const [shouldRender, setShouldRender] = useState(false);
  const [theme, setTheme] = useState();
  useEffect(() => {
    if (
      !isMobile() || readCookie(`mobile-app-sheet-shown-${project}`) === 'true'
    ) {
      setShouldRender(false);
      return;
    }

    const path = window.location.pathname;
    let match = 0;
    for (let i = 0; i < pages.length; i++) {
      const pattern = new RegExp(pages[i]);
      if (pattern.test(path)) {
        match++;
      }
    }

    if (!match) {
      setShouldRender(false);
      return;
    }
    // data?.content?.backgroundColor && setBgColor(data.content.backgroundColor);
    // data?.content?.textColor && setTextColor(data.content.textColor);
    // data?.content?.buttonColor && setButtonColor(data.content.buttonColor);

    setShouldRender(true);
  }, [pages]);
  const defaultTheme = {
    backgroundColor: '#0A1551',
    textColor: '#fff',
    buttonColor: '#0099FF',
    loginBackgroundColor: '#F3F3FE',
    loginTextColor: '#0A1551',
    loginButtonColor: '#78BB07',
    colors: {
      backgroundColor: '#0A1551',
      textColor: '#fff',
      buttonColor: '#0099FF',
      loginBackgroundColor: '#F3F3FE',
      loginTextColor: '#0A1551',
      loginButtonColor: '#78BB07'
    },
    theme: 'light'
  };
  useEffect(() => {
    if (isEnterprise()) {
      try {
        (async () => {
          const { data } = await fetchTheme();
          setTheme(data.content);
        })();
      } catch (e) {
        setTheme(defaultTheme);
      }
    } else {
      setTheme(defaultTheme);
    }
  }, []);

  const close = () => {
    setShouldRender(false);
    setCookie(`mobile-app-sheet-shown-${project}`, 'true', 7);
  };

  const title = isEnterprise() ? `${window?.companyTitle} App` : 'Jotform Mobile'.locale();
  const desc = isiOS() ? 'FREE - On the App Store'.locale() : 'FREE - On Google Play'.locale();
  const button = 'Open App'.locale();
  let deeplinkUrl = window?.user?.healthAppUser === '1' || window?.user?.isHIPAA === '1' || window?.user?.coronaFighter === '1'
    ? `https://g5qt.adj.st/?adjust_t=cwvwk2i&adj_adgroup=${project}&adjust_campaign=deep_link&adjust_deeplink=jfhealth%3A%2F%2F`
    : `https://rulm.adj.st/?adjust_t=jui72ri&adj_adgroup=${project}&adjust_campaign=deep_link&adjust_deeplink=jotform%3A%2F%2F`;
  if (isEnterprise()) {
    deeplinkUrl = `https://${window.location.hostname}/mobile?campaign=smart-app-banner`;
  }
  if (isCustomEnterpriseApp) {
    deeplinkUrl = window.mobileDeeplink;
  }

  const buttonHref = `${deeplinkUrl}&jotformSource=${encodeURIComponent(window.location.hostname + window.location.pathname)}`;

  return shouldRender && createPortal((
    <div id="mobile-app-sheet">
      <div className="mobile-app-sheet-inner" style={{ backgroundColor: theme?.backgroundColor }}>
        <button
          type="button" className="mobile-app-sheet-close"
          onClick={close}
        >
          <CloseIcon fill={theme?.buttonColor} stroke={theme?.buttonColor} />
        </button>
        <div className="mobile-app-sheet-logo">
          <AppIcon />
        </div>
        <div className="mobile-app-sheet-content">
          <div className="mobile-app-sheet-title" style={{ color: theme?.textColor }}>{title}</div>
          {!isEnterprise() && <div className="mobile-app-sheet-desc">{desc}</div>}
        </div>
        <a
          className="mobile-app-sheet-button" href={buttonHref}
          style={{ backgroundColor: theme?.buttonColor }}
        >
          {button}
        </a>
      </div>
    </div>
  ), document.querySelector('body'));
}

MobileAppSheet.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.string),
  project: PropTypes.string
};

MobileAppSheet.defaultProps = {
  pages: [
    '^/(../)?myforms(.*)'
  ],
  project: 'myforms'
};

export default MobileAppSheet;
