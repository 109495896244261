export const isTranslationRendererNeeded = text => text.match(/\[(\d+)\[/);

export const setFlagToLocSt = localStKey => global.localStorage.setItem(localStKey, '1');

export const hasFlagAtLocSt = localStKey => !!global.localStorage.getItem(localStKey);
export const getPortalRoot = () => {
  if (document.getElementById('listing-portal-root')) {
    return document.getElementById('listing-portal-root');
  }
  const portalRoot = document.createElement('div');
  portalRoot.id = 'listing-portal-root-temp';
  document.body.appendChild(portalRoot);
  return portalRoot;
};
