import React from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';

export default class ProtectedUI extends React.Component {
  checkPermissions() {
    const { userPermissions = [], requiredPermissions, oneperm = false } = this.props;

    if (oneperm) {
      return intersection(userPermissions, requiredPermissions).length;
    }

    return difference(requiredPermissions, userPermissions).length === 0;
  }

  render() {
    const {
      children, userPermissions = [], requiredPermissions, renderOtherwise = null
    } = this.props;

    if (!children || !userPermissions || !requiredPermissions) {
      return null;
    }

    if (this.checkPermissions()) {
      return children;
    } if (renderOtherwise) {
      return renderOtherwise;
    }
    return null;
  }
}

ProtectedUI.propTypes = {
  oneperm: PropTypes.bool,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  requiredPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
  renderOtherwise: PropTypes.element
};
