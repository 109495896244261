import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScNotificationListTitle = Styled.span`
  text-transform: uppercase;
  border-bottom: 1px solid #C8CEED;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  transition: color .2s;
  width: 100%;
  background-color: #FFFFFF;
  text-transform: capitalize;
  border-radius: 8px 8px 0 0;

  ${({ isEmptyNotification }) => (isEmptyNotification ? `
    color: #8894B0;
    ` : `
    color: #0A1551;
    `
  )}

  @media screen and (max-width:${breakpoints.tablet}) {
    position: relative;
    height: auto;
    padding-left: 20px;
    flex-shrink: 0;
  }
`;
