import { updateUserSettings } from '../utils';

const onConfirm = ({ actionTargetName }) => {
  global.location.href = '/myaccount/billing?pay-invoice=true';

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName }) => {
  updateUserSettings('dismissOverduePaymentNotification', '1');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

// payment overdue
const checkPaymentOverdue = notifications => {
  let notification = notifications.find(notif => notif.name === 'paymentOverdue');
  const otherNotifications = notifications.filter(notif => notif.name !== 'paymentOverdue');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkPaymentOverdue;
