import { updateTeamProperties } from './api';
import { TeamProps } from '../../types';
import { isErrored } from '../../utils';

export const handleUpdateTeamProps = async (
  teamId: string,
  newData: TeamProps,
  onUpdate: (newData: TeamProps) => void,
  setTeam: (f: (TeamProps | ((currTeam: TeamProps) => TeamProps))) => void
): Promise<any> => {
  try {
    const res = await updateTeamProperties(teamId, newData);
    if (!isErrored(res)) {
      setTeam((currTeam: TeamProps): TeamProps => ({ ...currTeam, ...newData }));
      onUpdate(newData);
    }
  } catch (e) {
    // handle errors
  }
};
