import React, { createRef, useImperativeHandle } from 'react';
import {
  elementType, string, func, bool
} from 'prop-types';
import Styled from 'styled-components';
import { Utils } from '@jotforminc/uikit';

const ScInlineEditor = Styled.div`
  user-select: auto;
`;

const InlineEditor = React.forwardRef(({
  defaultValue, onChange, onBlur, Renderer, contentEditable, isAllowedEmpty, ...rest
}, ref) => {
  const contentEditableRef = createRef();

  const handleKeyDown = event => {
    event.nativeEvent.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    switch (true) {
      case Utils.isPressedKeyEnter(event): {
        contentEditableRef.current.blur();
        break;
      }
      case Utils.isPressedKeyEscape(event): {
        contentEditableRef.current.textContent = defaultValue;
        contentEditableRef.current.blur();
        break;
      }
      default:
        break;
    }
  };

  const handleBlur = e => {
    if (!isAllowedEmpty && !contentEditableRef.current.textContent?.trim()) {
      contentEditableRef.current.textContent = defaultValue;
      contentEditableRef.current.blur();
      return;
    }
    onBlur(e);
    onChange(contentEditableRef.current.textContent);
  };

  useImperativeHandle(ref, () => ({
    contentEditableRef
  }));

  return (
    <Renderer
      ref={contentEditableRef}
      {...rest}
      contentEditable={contentEditable}
      suppressContentEditableWarning
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      {defaultValue}
    </Renderer>

  );
});

InlineEditor.propTypes = {
  Renderer: elementType,
  defaultValue: string.isRequired,
  onChange: func.isRequired,
  onBlur: func,
  contentEditable: bool,
  isAllowedEmpty: bool
};

InlineEditor.defaultProps = {
  Renderer: ScInlineEditor,
  onBlur: x => x,
  contentEditable: true,
  isAllowedEmpty: true
};

export default InlineEditor;
