import React, { FunctionComponent, ReactNode } from 'react';
import { t } from '@jotforminc/translation';
import TeamEmptyIcon from '../../assets/svg/iconEmptyUsers.svg';
import IconEmpty from '../../assets/svg/iconEmpty.svg';

import '../../styles/EmptyPage.scss';

type EmptyPageProps = {
  icon?: ReactNode,
  classes?: string,
  headerText?: string,
  text?: string,
  button?: ReactNode,
  hasSearch?: boolean,
  isTeamTable?: boolean
};

export const EmptyPage: FunctionComponent<EmptyPageProps> = ({
  icon, classes, headerText = '', text = '', button, hasSearch, isTeamTable
}) => (
  <div className={`jfTableEmpty ${classes}`} role="alert">
    <div className="jfTableEmpty-image">
      {hasSearch && <IconEmpty />}
      {!hasSearch && (isTeamTable ? <TeamEmptyIcon /> : icon)}
    </div>
    <div className="jfTableEmpty-text-wrapper">
      <p className="jfTableEmpty-text-header">{hasSearch ? t('NO RESULTS FOUND') : t(headerText)}</p>
      <p className="jfTableEmpty-text">{t(text)}</p>
    </div>
    {button}
  </div>
);

EmptyPage.defaultProps = {
  icon: null,
  classes: '',
  headerText: 'THERE ARE NO DATA YET',
  text: '',
  button: null,
  hasSearch: false,
  isTeamTable: false
};
