import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@jotforminc/uikit';
import {
  func, bool, object, string
} from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { IconRocketFilled } from '@jotforminc/svg-icons';
import {
  PrefilledLeadFormAside, ASSET_IDENTIFIER,
  ALL_TEXTS, userPropType, GA_FORM_TRACKING_ITEMS,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, setEpUserSettings, setEPActions
} from '@jotforminc/ep-utils';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import '../../../../styles/fqhcModal.scss';

const FqhcModal = (({
  user,
  seenAtKey,
  modalProps,
  onClose,
  onCloseClick
}) => {
  const uikitModalRef = useRef(null);

  const { CAMPAIGN: { FQHC_MODAL: target } } = ASSET_IDENTIFIER;
  const { [target]: gaFormTrackingItems } = GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  const [isFormSent] = useState(false); // setIsFormSent

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('fqhcm')) return;

    setEPActions({ asset: target, target, action: 'seen' });
    setEpUserSettings(seenAtKey);
  }, []);

  const handleClose = () => {
    gaDataLayerPushClose(gaAttributes);
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleCloseClick = () => {
    uikitModalRef.current?.hide();
    onCloseClick();
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  // const handleSendClick = () => {
  //   setIsFormSent(!isFormSent);
  // };

  const badgeClasses = cx({
    badge: true,
    sent: isFormSent
  });

  const titleClasses = cx({
    title: true,
    sent: isFormSent
  });

  const iframeClasses = cx({
    'form-iframe': true,
    sent: isFormSent
  });

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <span className={badgeClasses}>
        <IconRocketFilled />
        {t(ALL_TEXTS.ENTERPRISE_CAPITALIZED)}
      </span>

      <h1 className={titleClasses}>
        {t(ALL_TEXTS.FQHC_TITLE)}
      </h1>

      <PrefilledLeadFormAside
        classNames={iframeClasses}
        user={user}
        formID='233523783866063'
        gaAttributes={gaAttributes}
        gaFormTrackingItems={gaFormTrackingItems}
        title={t(ALL_TEXTS.FQHC_TITLE)}
      />

      {/* <button type='button' onClick={handleSendClick}>sdasd</button> */}
    </Modal>
  );
});

FqhcModal.propTypes = {
  user: userPropType.isRequired,
  seenAtKey: string.isRequired,
  modalProps: object,
  defaultVisible: bool,
  onCloseClick: func,
  onClose: func
};

FqhcModal.defaultProps = {
  modalProps: {},
  defaultVisible: true,
  onCloseClick: f => f,
  onClose: f => f
};

export default FqhcModal;
