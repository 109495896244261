export type RoleChangeDisableReason = 'singleAdmin' | 'teamOwner' | 'serverAdmin' | 'dataOnlyUser';

export const ROLE_CHANGE_DISABLE_TEXTS = {
  singleAdmin: 'The role can\'t be changed since there is only one team admin.',
  teamOwner: 'The role can\'t be changed since this user created the team.',
  serverAdmin: 'The role can\'t be changed since this member is an admin in the organization.',
  dataOnlyUser: 'The role can\'t be selected since this member is a data only user in the organization.'
};

export const TEAM_ERRORS = {
  INSUFFICIENT_ROLE: 'TEAM_INSUFFICIENT_ROLE',
  ACCESS_NOT_ALLOWED: 'TEAM_ACCESS_NOT_ALLOWED'
};

export const TEAM_ACTIONS = {
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE'
};

export const PAGE = {
  MEMBERS: 'MEMBERS',
  INVITE: 'INVITE',
  PENDING: 'PENDING'
};

export const DELETION_BLOCKED_REASONS = [
  'teamOwner',
  'singleAdmin'
];

export const TEAM_ADMIN_MANAGE_ROLES_TEXTS = {
  CHANGE_YOUR_ROLE_TITLE: 'Changing your team role',
  CHANGE_YOUR_ROLE_DESCRIPTION: 'You are about to change your team admin role. Once you do change it, you will not be able to access team settings. Are you sure you want to proceed?',
  REMOVE_TITLE: 'Remove from Team',
  REMOVE_YOURSELF_DESCRIPTION: 'Are you sure you want to remove yourself from the team? Once you remove, you will not be able to access the assets in the team.',
  REMOVE_SOMEONE_ELSE_DESCRIPTION: 'Are you sure you want to remove the [1[{userName}]] from the team? Once you remove, [1[{userName}]] will not be able to access the assets in the team.',
  BACK_TEXT: 'Cancel',
  CONFIRM_TEXT: 'Proceed'
};
