import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScNotificationListScroll = Styled.div`
  @media screen and (max-width:${breakpoints.tablet}){
    flex: 1;
    display: flex;
    max-height: -webkit-fill-available;
  }

  &>section {
    background: #FFFFFF;
    max-height: 600px;
    padding: 0!important;
    border-radius: 8px;

    @media screen and (max-width:${breakpoints.tablet}){
      max-height: calc(100vh - 132px);
      width: 100%;
    }

    @media screen and (min-width:${breakpoints.tablet}) and (max-height:${breakpoints.tablet}){
      max-height: calc(100vh - 180px);
      min-height: 120px;
    }
    .scrollbar-track{
       background-color: transparent;
       .scrollbar-thumb{
          background-color: rgba(255, 255, 255, .5)
       }
    }
  }
`;
