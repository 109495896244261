import React, { useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import {
  bool, func, object, string
} from 'prop-types';
import cx from 'classnames';
import { IconTagPercentFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import PrefilledLeadFormAside from '../PrefilledLeadFormAside';
import {
  ALL_TEXTS, LEAD_FORM_MAP, gaAttributesPropType, gaFormTrackingItemsPropType, userPropType
} from '../../constants';
import { ContentRenderer, DialogRenderer, HeaderRenderer } from './Renderers';
import EnterpriseIcon from '../../assets/icon.svg';
import '@jotforminc/jotform.css';

const CollaborationModal = ({
  user,
  description,
  formID,
  devFormID,
  badgeText,
  preDescription,
  isNoLabelMode,
  formUiModifier,
  modalProps,
  gaAttributes,
  gaAttributesClose,
  textColorClass,
  descriptionClasses,
  preDescriptionClasses,
  preDescColorClass,
  descriptionWithoutStrong,
  backgroundColor,
  backgroundImageUrl,
  supTitleTextColor,
  supTitleBackground,
  gaFormTrackingItems,
  onCloseClick,
  onClose,
  onFormSubmit,
  hasEducationDiscountBadge,
  isAdForm
}) => {
  const uikitModalRef = useRef(null);

  const handleCloseButton = () => {
    uikitModalRef.current?.hide();
    onCloseClick();
  };

  const iframeWrapperClassNames = cx('flex flex-1 iframe-wrapper relative bg-white', {
    'short-form': isNoLabelMode
  });

  const imageClassNames = cx('w-full h-0 md:h-auto radius-bl-lg', {
    'md:-mb-5 md:-mt-8': isNoLabelMode
  });

  return (
    <Modal
      {...modalProps}
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseButton} />}
      onModalClose={onClose}
    >
      <div className='flex flex-col md:flex-row'>
        <div
          className='flex flex-col items-start relative md:basis-92 radius-tl-lg radius-tr-lg md:radius-tr-none md:radius-bl-lg overflow-hidden'
          style={{ background: backgroundColor }}
        >
          <div className='px-4 md:px-10 pt-4 md:pt-12'>
            <div
              className='inline-flex p-2 md:p-3 items-center justify-center radius text-sm md:text-md md:line-height-xl font-medium mb-3 md:mb-5 font-circular'
              style={{
                color: supTitleTextColor,
                background: supTitleBackground
              }}
            >
              <EnterpriseIcon
                className="w-5 h-5 inline-flex mr-2 enterprise-icon"
              />
              {t(badgeText)}
            </div>
            {!!preDescription && (
              <h2 className={`${preDescriptionClasses} ${preDescColorClass}`}>
                {t(preDescription)}
              </h2>
            )}
            <h4 className={`${descriptionClasses} ${textColorClass}`}>
              {descriptionWithoutStrong ? t(description) : (
                <strong key="text-1 font-bold">{t(description)}</strong>
              )}
            </h4>
          </div>
          {backgroundImageUrl && (
          <img
            src={backgroundImageUrl}
            alt={ALL_TEXTS.COLLABORATOR_AVATARS}
            className={imageClassNames}
          />
          )}

          {hasEducationDiscountBadge && (
            <div className='font-circular education-discount-line'>
              <IconTagPercentFilled />
              {t(ALL_TEXTS.EDUCATION_DISCOUNT_PERCENT)}
            </div>
          )}
        </div>
        {/* image */}
        <div className={iframeWrapperClassNames}>
          <PrefilledLeadFormAside
            user={user}
            formID={formID}
            devFormID={devFormID}
            gaAttributes={gaAttributes}
            formUiModifier={formUiModifier}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.JOTFORM_ENTERPRISE_TITLE)}
            classNames="w-full border-0 radius-br-lg radius-bl-lg md:radius-bl-none md:radius-tr-lg md:min-h-120 md:px-5"
            onFormSubmit={onFormSubmit}
            isAdForm={isAdForm}
          />
        </div>

        <style>
          {
          `
          .enterprise-icon path {
            fill: ${supTitleTextColor}
          }

          .jfBasicModal.teams-modal-wrapper>div[role=dialog] {
            width: 100%;
            max-width: 808px;
            border-radius: 8px;
            overflow: auto;
          }

          .iframe-wrapper {
            min-height: 610px;
          }

          .short-form.iframe-wrapper {
            min-height: 512px;
          }

          @media screen and (min-width:375px) {
            .iframe-wrapper {
              min-height: 540px;
            }
          }

          .education-discount-line {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1rem;
            background: radial-gradient(836.45% 141.42% at 0% 0%, #A2DEFF 0%, #B3BFFF 100%);
            box-shadow: 0 -2px 4px 0 rgba(10, 11, 13, 0.04), 0 -8px 16px 0 rgba(10, 11, 13, 0.32);
            color: #0A1551;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2.25rem;
            letter-spacing: -0.011rem;
            width: 100%;
          }

          @media screen and (min-width:768px) {
            .education-discount-line {
              line-height: 2.75rem;
              border-bottom-left-radius: .5rem;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
            }
          }

          .education-discount-line > svg {
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: .5rem;
          }

          `
        }
        </style>

      </div>
    </Modal>
  );
};

CollaborationModal.propTypes = {
  user: userPropType.isRequired,
  opener: string,
  modalProps: object,
  formID: string,
  devFormID: string,
  isNoLabelMode: bool,
  formUiModifier: string,
  description: string.isRequired,
  badgeText: string,
  preDescription: string,
  gaAttributes: gaAttributesPropType.isRequired,
  gaAttributesClose: gaAttributesPropType.isRequired,
  gaFormTrackingItems: gaFormTrackingItemsPropType.isRequired,
  textColorClass: string,
  preDescColorClass: string,
  backgroundColor: string,
  backgroundImageUrl: string,
  supTitleTextColor: string,
  supTitleBackground: string,
  onClose: func,
  onCloseClick: func,
  logAbTestAction: func,
  onFormSubmit: func,
  hasEducationDiscountBadge: bool,
  descriptionClasses: string,
  preDescriptionClasses: string,
  descriptionWithoutStrong: bool,
  isAdForm: bool
};

CollaborationModal.defaultProps = {
  opener: '',
  modalProps: {},
  formID: LEAD_FORM_MAP.COMMON.LIVE,
  devFormID: LEAD_FORM_MAP.COMMON.DEV,
  isNoLabelMode: false,
  formUiModifier: '',
  textColorClass: 'color-white',
  preDescColorClass: 'color-white',
  backgroundColor: 'radial-gradient(150% 100% at 100% 100%, #273CA5 0%, #050C34 100%)',
  backgroundImageUrl: '',
  supTitleTextColor: '#A2ABF6',
  supTitleBackground: 'rgba(61, 83, 194, 0.2)',
  descriptionClasses: 'font-normal text-lg md:text-2xl line-height-xl md:line-height-3xl mt-0 mb-4 md:mb-12 font-circular md:min-h-24',
  preDescriptionClasses: 'font-normal text-lg md:text-xl line-height-xl md:line-height-3xl mt-0 mb-4 md:mb-5 font-circular',
  badgeText: ALL_TEXTS.JOTFORM_ENTERPRISE_TITLE,
  preDescription: '',
  onClose: f => f,
  onCloseClick: f => f,
  logAbTestAction: f => f,
  onFormSubmit: f => f,
  hasEducationDiscountBadge: false,
  descriptionWithoutStrong: false,
  isAdForm: false
};

export default CollaborationModal;
