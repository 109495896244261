import {
  call, put, spawn, select
} from 'redux-saga/effects';
import * as API from '../../api';

import { openCreateNewContactModal } from '../../wizards/CreateNewContactModal';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import { ASSET_TYPES } from '../../constants';

export function* handleContactCreateWizard({ callback = f => f }) {
  try {
    const
      { contactDetails, resolveCreateContact } = yield call(openCreateNewContactModal, {}, callback);

    const response = yield call(API.createContact, contactDetails);

    if (response) {
      resolveCreateContact(contactDetails);
      yield put(ACTION_CREATORS.fetchListRequest());
    }
  } catch (e) {
    console.log('error:', e);
  }
}

function* handleContactFavorite({ id }) {
  const { favorite } = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.CONTACT));
  yield call(API.favoriteContact, id, favorite);
}

export function* rootContactFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleContactCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleContactFavorite);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
