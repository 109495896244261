import React, { useEffect, useState } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  array, bool, func, string
} from 'prop-types';
import {
  Button, Modal, ModalHeader, ModalBody, ModalActions
} from '@jotforminc/magnet';

import { IconFolderCaretRightFilled, IconExclamationCircle, IconLockFilled } from '@jotforminc/svg-icons';
import { AssetIconRenderer, generateError } from '@jotforminc/enterprise-components';
import { getResolvedAssets, moveAssets } from '../../api';
import AssetList from './assetList';
import { MoveToButtonPlaceholder, MoveToListPlaceholder, MoveToTextPlaceholder } from './moveToListPlaceholder';
import { goToAssetSource, mapAssetTypes } from '../../utils';
import {
  CANNOT_MOVE_CONNECTED_ASSET,
  CLOSE, ENCRYPTED, MODAL_BODY_DESC, MODAL_BODY_ERROR_DESC, MOVE, MOVE_TO_MY_FORMS, MOVE_TO_TEAM_NAME, VIEW
} from './constants';

const MoveConfirmationModal = ({
  onClose = f => f, itemList = [], targetTeamName = '', targetTeamID = '', sourceTeamID = '', toTeam = false, isRetry = false, onSave = f => f, isAdmin = false
}) => {
  const [groupedAssets, setGroupedAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [conflictedAssets, setConflictedAssets] = useState([]);
  const [isExpandedList, setExpandedList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [moveToError, setMoveToError] = useState({
    errorType: '', errorText: '', assetName: '', assetType: '', assetID: '', assetResourceType: '', connectedAssetName: '', connectedAssetType: '', connectedAssetResourceType: ''
  });

  const handleModalClose = () => {
    onClose();
  };
  const isErrored = res => !res || (res.responseCode && res.responseCode !== 200) || (res.content && res.responseCode && res.responseCode !== 200);

  const fetchAssets = () => {
    setLoading(true);
    getResolvedAssets({ assets: itemList }, toTeam, sourceTeamID).then(resp => {
      const { flatten, message } = resp;
      if (!isErrored(resp)) {
        const grouped = Object.groupBy(flatten, ({ initiatorAssetKey }) => initiatorAssetKey);
        const conflictedAssetList = Object.keys(grouped).filter(currKey => grouped[currKey].some(asset => asset.ownerConflict)).map(currKey => ({ [currKey]: false }));
        setConflictedAssets(conflictedAssetList);
        setGroupedAssets(grouped);
        setExpandedList(Object.keys(grouped).map(key => ({ [key]: false })));
        setSelectedAssets(Object.keys(grouped).map(key => key));
        setLoading(false);
      } else {
        console.error(message);
      }
    }).catch(({
      response: {
        data: {
          message, message: {
            assetType, assetName, connectedAssetName, assetResourceType = 'LEGACY', assetID = '',
            connectedAssetType = 'form', connectedAssetResourceType = 'LEGACY', type, initiatorAssetName = '',
            initiatorAssetType = '', initiatorAssetID = ''
          }
        }
      }
    }) => {
      setLoading(false);
      setMoveToError({
        errorType: type,
        errorText: generateError(message),
        assetName: assetName,
        assetType: assetType,
        assetID: assetID,
        connectedAssetName: initiatorAssetName || connectedAssetName,
        assetResourceType: initiatorAssetType || assetResourceType,
        connectedAssetType: initiatorAssetID || connectedAssetType,
        connectedAssetResourceType: connectedAssetResourceType
      });
    });
  };

  const prepareAssetData = list => {
    const preparedList = list.map(({
      id, type: listType, from, originalID, ownerConflict
    }) => {
      const transfer = {};
      if (ownerConflict) {
        if (isAdmin) transfer.transferPreferred = true;
        else transfer.requestPreferred = true;
      } else if (isAdmin) transfer.transferPreferred = false;
      else transfer.requestPreferred = false;
      return ({
        id: id, type: listType, from: from, ...({ [Object.keys(transfer)[0]]: Object.values(transfer)[0] }), ...(originalID ? { originalID: originalID } : {})
      });
    });

    return preparedList;
  };

  const handleSelect = ({ target: { checked } }, assetName) => {
    if (checked) {
      setSelectedAssets([...selectedAssets, assetName]);
    } else {
      setSelectedAssets(selectedAssets.filter(asset => asset !== assetName));
    }
  };

  const handleExpand = id => {
    setExpandedList(isExpandedList.map(asset => {
      return (Object.keys(asset)[0] === id ? ({ [id]: !asset[id] }) : { [Object.keys(asset)[0]]: false });
    }));
  };

  const handleMoveAssets = () => {
    const assets = prepareAssetData(selectedAssets.reduce((acc, curr) => [...acc, ...groupedAssets[curr].filter(asset => asset?.type !== 'form' || asset?.resource?.type !== 'sign')], []));
    const checkedAssets = prepareAssetData(selectedAssets.reduce((acc, curr) => [...acc, ...groupedAssets[curr]], []).filter(item => item?.isOptional));

    moveAssets({
      assets,
      checkedAssets,
      targetTeamID,
      sourceTeamID,
      toTeam,
      isRetry
    }).then(resp => {
      if (!isErrored(resp) && resp) {
        onSave(resp);
      } else {
        console.error(resp?.data || resp);
      }
    }).catch(({ data: { message, message: { type, assetName } } }) => {
      setMoveToError({ errorType: type, assetName: assetName, errorText: generateError(message) });
    });
  };

  const handleTransfer = key => {
    setConflictedAssets(conflictedAssets.map(asset => (Object.keys(asset)[0] === key ? { [key]: !asset[key] } : { ...asset })));
  };

  const hasConflicts = () => {
    const selectedConflictedAssets = conflictedAssets.filter(conflictedAsset => (selectedAssets.includes(Object.keys(conflictedAsset)[0])));
    const selectedAndResolvedConflictedAssets = conflictedAssets.filter(conflictedAsset => (selectedAssets.includes(Object.keys(conflictedAsset)[0]) && Object.values(conflictedAsset)[0]));

    if (selectedAssets.length === 0) {
      return false;
    }

    if (selectedConflictedAssets.length === 0) {
      return true;
    }

    if (selectedConflictedAssets.length === conflictedAssets.length) {
      return conflictedAssets.every(asset => Object.values(asset)[0]);
    }

    if (selectedConflictedAssets.length === selectedAndResolvedConflictedAssets.length) {
      return true;
    }

    return false;
  };

  const renderInfoBox = () => {
    const selectedConflictedAssets = conflictedAssets.filter(conflictedAsset => (selectedAssets.includes(Object.keys(conflictedAsset)[0])));
    const selectedAndResolvedConflictedAssets = conflictedAssets.filter(conflictedAsset => (selectedAssets.includes(Object.keys(conflictedAsset)[0]) && Object.values(conflictedAsset)[0]));

    if (selectedAndResolvedConflictedAssets.length !== selectedConflictedAssets.length) {
      return (
        <div className='info-box color-navy-700 border-yellow-300 bg-yellow-100 border-solid border  px-2 py-2 mt-4 radius text-sm flex items-center gap-2'>
          <div className='h-4'>
            <IconExclamationCircle className='w-4 h-4 fill-yellow-600' />
          </div>
          {/* <span> TEMPORARILY DISABLED UNTILL THE TRANSFER IMPLEMENTATION IS COMPLETE
            {translationRenderer(INFO_BOX_CONTENT)({
              renderer1: text => (<strong>{text}</strong>),
              renderer2: text => (<strong>{text}</strong>)
            })}
          </span> */}
          <span>
            {t(CANNOT_MOVE_CONNECTED_ASSET)}
          </span>
        </div>
      );
    }
  };

  const renderMoveToErrorContent = () => {
    const {
      errorText, errorType, assetName, assetType, assetID, assetResourceType, connectedAssetName, connectedAssetType, connectedAssetResourceType
    } = moveToError;
    switch (errorType) {
      case 'ASSET_CANNOT_MOVED':
      case 'REPORT_NOT_CONNECTED_TO_FORM':
      case 'MOVING_NOT_SUPPORTED':
        return (
          <div>
            <div className='px-2 text-sm warning-message text-center color-navy-700 mb-2'>
              {t(errorText)}
            </div>
            <div className='moveToErrorGeneralWrapper flex flex-col gap-2 move-svg-container'>
              <div>
                <div className='flex gap-1 moveTo-standart-form justify-between'>
                  <div className='flex items-center gap-1'>
                    <AssetIconRenderer type={assetType} isNavy={false} resourceType={assetResourceType} />
                    {assetName}
                  </div>
                  {assetType !== 'sign' && (
                    <Button className='viewAssetButtons outline-0 border-0 bg-transparent p-0 radius' variant='ghost' onClick={() => goToAssetSource(assetType, assetID)}>
                      {t(VIEW)}
                      {' '}
                      {t(mapAssetTypes(assetType))}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 'ENCRYPTED_CONNECTED_ASSET':
        return (
          <div className='encrypted-wrapper'>
            <div className='px-2 text-sm warning-message text-center color-navy-700 mb-2'>
              {t(errorText)}
            </div>
            <div className='move-error-form-container'>
              <div className='flex gap-1 items-center moveTo-standart-form text-md'>
                <AssetIconRenderer type={connectedAssetType} isNavy={false} resourceType={connectedAssetResourceType} />
                {connectedAssetName}
              </div>
              <div className='move-error-form-wrapper '>
                <div className='encyrpted-connected-sub-form justify-between'>
                  <div className='flex items-center gap-1'>
                    <AssetIconRenderer type={assetType} isNavy={false} resourceType={assetResourceType} />
                    {assetName}
                    <div className='flex items-center gap-1 encrypt-badge-wrapper bg-yellow-600'>
                      <IconLockFilled className='encrypt-badge' />
                      <span>{t(ENCRYPTED)}</span>
                    </div>
                  </div>
                  {assetType !== 'sign' && (
                  <Button className='viewAssetButtons outline-0 border-0 bg-transparent p-0 radius' variant='ghost' onClick={() => goToAssetSource(assetType, assetID)}>
                    {t(VIEW)}
                    {' '}
                    {t(mapAssetTypes(assetType))}
                  </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case 'ENCRYPTED_ASSET':
        return (
          <div className='encrypt-container'>
            <div className='moveToErrorModalWrapper'>
              <div className='encryption'>
                <div className='px-2 text-sm warning-message text-center color-navy-700 mb-2 encrypted-asset-form encrypted-asset font-normal text-sm radius'>
                  {t(errorText)}
                </div>
              </div>
              <div className='encrypt-sub-container gap-2 flex flex-col'>
                <div className='flex items-center gap-1 encrypted-asset-form encrypted-asset moveTo-standart-form justify-between font-normal text-sm radius'>
                  <div className='flex items-center gap-1 encrypt-formIcon-wrapper'>
                    <AssetIconRenderer
                      type={assetType} isNavy={false}
                      resourceType={assetResourceType}
                    />
                    {assetName}
                    <div className='flex items-center gap-1 encrypt-badge-wrapper bg-yellow-600'>
                      <IconLockFilled className='encrypt-badge' />
                      <span>{t(ENCRYPTED)}</span>
                    </div>
                  </div>
                  {assetType !== 'sign' && (
                  <Button className='viewAssetButtons outline-0 border-0 bg-transparent p-0 radius' variant='ghost' onClick={() => goToAssetSource(assetType, assetID)}>
                    {t(VIEW)}
                    {' '}
                    {t(mapAssetTypes(assetType))}
                  </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className='default-error-wrapper'>
            <div className='moveTo-warning-icon'>
              <div className='ml-auto w-16 h-16 mr-auto default-error-icon-wrapper pl-4 pt-4 radius'>
                <IconExclamationCircle />
              </div>
              <div className='text-center mt-4 color-navy-700 text-sm'>
                {t(errorText)}
              </div>
            </div>
          </div>
        );
    }
  };

  const renderMoveToError = () => {
    return (
      <>
        <div className='max-h-md overflow-scroll'>
          <ModalBody className='modalTextContainer'>
            {renderMoveToErrorContent()}
          </ModalBody>
          <ModalActions className='modal-action-container mt-8'>
            <Button
              className='ml-auto'
              type='Button' colorStyle="secondary" variant="filled"
              onClick={handleModalClose}
            >
              {t(CLOSE)}
            </Button>
          </ModalActions>
        </div>
      </>
    );
  };

  const renderMoveToModalContent = () => {
    return (
      <>
        <div className='max-h-md overflow-scroll'>
          <ModalBody className='modalTextContainer pt-4'>
            <div className='px-2 text-sm warning-message text-center color-navy-700 mb-2'>
              {conflictedAssets.length > 0 && translationRenderer(MODAL_BODY_ERROR_DESC)({
                renderer1: () => targetTeamName
              })}
              {translationRenderer(MODAL_BODY_DESC)({
                renderer1: () => targetTeamName
              })}
            </div>
            <div>
              {renderInfoBox()}
            </div>
          </ModalBody>
          <>
            {Object.keys(groupedAssets).map((key, index) => (
              <AssetList
                handleSelect={handleSelect} assets={groupedAssets[key]} assetName={key}
                checked={selectedAssets.includes(key)}
                handleTransfer={() => handleTransfer(key)}
                isMoveable={conflictedAssets.some(asset => typeof asset[key] !== 'undefined')}
                shouldTransfer={conflictedAssets.some(asset => asset[key])}
                isExpanded={isExpandedList.length > 0 && isExpandedList[index][key]}
                isSelected={selectedAssets.includes(key)}
                handleExpand={handleExpand}
                isAdmin={isAdmin}
              />
            ))}
          </>
        </div>
        <ModalActions className='modal-action-container mt-8'>
          <Button
            className='ml-auto'
            type='Button' colorStyle="primary" variant="filled"
            onClick={handleMoveAssets}
            disabled={!hasConflicts()}
          >
            {t(MOVE)}
          </Button>
        </ModalActions>
      </>
    );
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <Modal
      open={true}
      onClose={handleModalClose}
      size='large'
    >
      <div className={`moveToModalHeader${moveToError.errorType ? ' moveToErrorModalHeader' : ''}`}>
        <div className='moveToModalHeader-container'>
          <ModalHeader
            icon={IconFolderCaretRightFilled} logoIcon={true}
            title={targetTeamName ? translationRenderer(MOVE_TO_TEAM_NAME)({
              renderer1: () => (targetTeamName)
            }) : t(MOVE_TO_MY_FORMS)}
            onClose={handleModalClose}
          />
        </div>
        {!isLoading && (moveToError.errorText ? renderMoveToError() : renderMoveToModalContent())}
        {isLoading && (
          <div>
            <ModalBody>
              <MoveToTextPlaceholder />
              {itemList.map(() => (
                <div className='bg-navy-25 radius-md mb-2'>
                  <MoveToListPlaceholder />
                </div>
              ))}
            </ModalBody>
            <ModalActions>
              <MoveToButtonPlaceholder />
            </ModalActions>
          </div>
        )}
      </div>
    </Modal>
  );
};

MoveConfirmationModal.propTypes = {
  onClose: func,
  itemList: array,
  targetTeamName: string,
  sourceTeamID: string,
  toTeam: bool,
  isRetry: false,
  targetTeamID: string,
  onSave: func,
  isAdmin: bool
};

export default MoveConfirmationModal;
