import React from 'react';
import {
  shape,
  func,
  string,
  number,
  bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { hipaaSteps, hipaaFirstStep } from '../constants';
import { ScButtonNv } from './sc/newNotification/scButtonNv';

const HipaaDismissButton = ({
  notification,
  showText,
  onDismiss,
  hipaaStep,
  hipaaFirstStepAnswer,
  onHipaaStepChange
}) => {
  const { dismiss: { text, textAlternative } } = notification;

  const isHipaaFirstStep = hipaaStep === hipaaSteps.firstStep;
  const isHipaaSecondStep = hipaaStep === hipaaSteps.secondStep;
  const isFirstStepAnswerNo = hipaaFirstStepAnswer === hipaaFirstStep.no;

  const handleDismiss = notifcn => {
    if (isHipaaFirstStep) {
      onHipaaStepChange({ firstStepAnswer: hipaaFirstStep.no });
      onDismiss(notifcn, hipaaStep);
      return;
    }

    if (isHipaaSecondStep) {
      onDismiss(notifcn, hipaaStep, hipaaFirstStepAnswer);
    }
  };

  const getText = () => {
    if (!showText) return '';

    if (isHipaaSecondStep && isFirstStepAnswerNo) {
      return t(textAlternative);
    }

    return t(text);
  };

  return (
    <ScButtonNv
      hideCrossIcon={isHipaaFirstStep}
      lightView={notification.dismiss.lightView}
      onClick={() => handleDismiss(notification)}
    >
      {getText()}
    </ScButtonNv>
  );
};

HipaaDismissButton.propTypes = {
  notification: shape({
    text: string,
    dismiss: shape({ text: string }),
    name: string
  }),
  hipaaFirstStepAnswer: string,
  hipaaStep: number,
  showText: bool,
  onDismiss: func,
  onHipaaStepChange: func
};

HipaaDismissButton.defaultProps = {
  notification: {
    text: '',
    dismiss: { text: '' },
    name: ''
  },
  hipaaFirstStepAnswer: null,
  hipaaStep: 0,
  showText: true,
  onDismiss: f => f,
  onHipaaStepChange: f => f
};

export default HipaaDismissButton;
