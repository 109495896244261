import '../styles/Loader.scss';
import React, { ReactElement } from 'react';
import { t } from '@jotforminc/translation';
import IconLoading from '../assets/svg/iconLoading.svg';
import { LoaderProps } from '../types';

const Loader = ({
  withAnimation, text = '', teamAnimation, mobileAnimation
}: LoaderProps): ReactElement => {
  if (mobileAnimation) {
    return (
      <div className="jfLoader-mobile-wrapper">
        <div className="jfLoader-mobile-elements jfLoader-mobile-top" />
        <div className="jfLoader-mobile-elements jfLoader-mobile-middle" />
        <div className="jfLoader-mobile-elements jfLoader-mobile-bottom" />
        <div className="jfLoader-mobile-elements jfLoader-mobile-icon" />
      </div>
    );
  }
  return (
    <>
      {withAnimation ? (
        <div className="jfLoader-wrapper jfLoaderTeam">
          {teamAnimation ? (
            <div className="container">
              <div className="triangle-wrapper-hack">
                <div className="triangle" />
              </div>
              <div className="square blue" />
              <div className="circle" />
              <div className="square yellow" />
            </div>
          ) : <IconLoading className="jfLoader-circle" />}
          <p className="jfLoader-text">{t(text)}</p>
        </div>
      ) : (<p className="jfLoader-text">{t(text)}</p>)}
    </>
  );
};

Loader.defaultProps = {
  withAnimation: false,
  text: '',
  teamAnimation: false,
  mobileAnimation: false
};

export default Loader;
