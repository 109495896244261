import React from 'react';
import { func } from 'prop-types';
import {
  IconExclamationCircle,
  IconArrowRotateLeft
} from '@jotforminc/svg-icons';
import { Button } from '@jotforminc/magnet';

import translate from '../../utils/translate';

const ResetAssetFilterNoContent = ({ handleResetFilter }) => {
  return (
    <div className='lsApp-noAssets'>
      <div className='lsApp-noAssets-icon'>
        <IconExclamationCircle width={40} color='#F9A400' />
      </div>
      <h3 className="lsApp-noAssets-text">{translate('There is no asset to show as you have filtered all types of assets')}</h3>
      <p className="lsApp-noAssets-text sublabel">{translate('Reset filter or select which assets you prefer to see from the filter.')}</p>
      <div className='lsApp-noAssets-cta'>
        <Button
          colorStyle="teams"
          startIcon={IconArrowRotateLeft}
          onClick={handleResetFilter}
        >
          {translate('Reset Filter')}
        </Button>
      </div>
    </div>
  );
};

ResetAssetFilterNoContent.propTypes = {
  handleResetFilter: func.isRequired
};

export default ResetAssetFilterNoContent;
