import {
  select, put, takeLatest, all, call, spawn
} from 'redux-saga/effects';
import { r } from '../../api/requestLayer';
import { fetchPermissionInfoUser } from '../../api';

import { getSheetFolderSummary } from '../listBased/sheet';
import { getReportFolderSummary } from '../listBased/report';
import { getTaskFolderSummary } from '../listBased/approval';
import {
  getAssetFolderSummary, getFormFolderSummary, handleCreateFolder
} from '../listBased/assetFolder';
import { getAssignedFolderSummary } from '../listBased/assigned';

import { SELECTORS } from '../../store/selectors';
import {
  flattenFolders, getCookie, getFolderDefaults, isValidSorting
} from '../../utils';

import {
  FEATURE_LIST, FOLDER_TYPES, LISTING_TYPES, STATUS_FOLDERS, getDefaultOrderByValue, ASSETS_FOLDER_LISTINGS, DEFAULT_FOLDER_IDS
} from '../../constants';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { rootTeamFlow } from '../listBased/team';
import { spawnListBasedFlows } from './list';
import { getTeamsFolderSummary } from './team';
import { getMixFolderSummary } from './mix';
import { getUserPermissions } from '../../utils/permissions';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

export function* fetchFolderSummary() {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const isTeamsActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.JOTFORM_TEAMS));
  const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const isTeamMember = yield select(SELECTORS.getIsTeamMember);
  const isFormUser = yield select(SELECTORS.isFormUser);
  const { defaultFolderList, defaultSelectedFolder } = getFolderDefaults(currentPage, isTeamsActive, isTeamMember, isFormUser, isAssetsFolderActive);
  let folderInfo = { folderList: defaultFolderList, selectedFolder: defaultSelectedFolder };

  if (currentPage === LISTING_TYPES.MIX || (ASSETS_FOLDER_LISTINGS.indexOf(currentPage) > -1 && isAssetsFolderActive)) {
    const folderInfoWithFolders = yield getAssetFolderSummary(folderInfo);
    folderInfo = folderInfoWithFolders;
  }

  const teamsTitleIndex = defaultFolderList.findIndex(({ id }) => id === DEFAULT_FOLDER_IDS.TEAMS_TITLE);
  if (isTeamsActive && teamsTitleIndex > -1) {
    const { folderList: folderListWithTeams } = yield getTeamsFolderSummary(folderInfo);
    folderInfo.folderList = folderListWithTeams;
  }
  switch (currentPage) {
    case LISTING_TYPES.FORM:
      return yield getFormFolderSummary(folderInfo);
    case LISTING_TYPES.TASK:
      return yield getTaskFolderSummary(folderInfo);
    case LISTING_TYPES.ASSIGNED_FORM:
      return yield getAssignedFolderSummary(folderInfo);
    case LISTING_TYPES.REPORT:
      return yield getReportFolderSummary(folderInfo);
    case LISTING_TYPES.SHEET:
      return yield getSheetFolderSummary(folderInfo);
    case LISTING_TYPES.TEAM_PAGE:
      return yield getTeamsFolderSummary(folderInfo);
    case LISTING_TYPES.MIX:
      return yield getMixFolderSummary(folderInfo);
    default:
      return folderInfo;
  }
}

export function* initializeFolders() {
  const { folderList, selectedFolder: initialSelectedFolder } = yield fetchFolderSummary();
  const flattenedFolders = flattenFolders(folderList);
  const selectedFolder = yield select(SELECTORS.getInitialFolderID(flattenedFolders, initialSelectedFolder, false));
  const { folderType = FOLDER_TYPES.STATIC } = flattenedFolders.find(folder => folder.id === selectedFolder) || {};
  const userCredentials = yield select(SELECTORS.getUserCredentials);
  const actionCreatorPutList = [
    put(ACTION_CREATORS.setFolders(folderList, selectedFolder)),
    put(ACTION_CREATORS.setSelectedFolder({ folder: selectedFolder, folderType, userCredentials }))
  ];

  const sortingCookie = getCookie(`${selectedFolder}Sorting`);
  const assetFilterType = yield select(SELECTORS.getAssetFilterType);
  const currentPage = yield select(SELECTORS.getCurrentPage);

  if (sortingCookie && isValidSorting(currentPage, assetFilterType, selectedFolder, sortingCookie)) {
    actionCreatorPutList.push(put(ACTION_CREATORS.setOrderBy(sortingCookie)));
  } else {
    const defaultVal = getDefaultOrderByValue(currentPage, assetFilterType, selectedFolder);

    if (defaultVal) {
      actionCreatorPutList.push(put(ACTION_CREATORS.setOrderBy(defaultVal)));
    }
  }

  // TODO: remove this if check after refactor
  if (!sortingCookie && assetFilterType === LISTING_TYPES.REPORT) {
    actionCreatorPutList.push(put(ACTION_CREATORS.setOrderBy('form_id')));
  }

  yield all(actionCreatorPutList);
}

export function* switchSpace({ toTeam, teamID }) {
  yield put(ACTION_CREATORS.setCurrentTeamID(toTeam ? teamID : ''));
  r.setTeamID(toTeam ? teamID : '');

  const user = yield select(SELECTORS.getUser);
  if (toTeam) {
    const currentPage = yield select(SELECTORS.getCurrentPage);
    if (currentPage !== LISTING_TYPES.MIX) {
      yield spawn(rootTeamFlow);
    }
    const newPermissions = yield call(fetchPermissionInfoUser);
    yield put(ACTION_CREATORS.setUser({ ...user, ...(getUserPermissions(user, window.JOTFORM_ENV, newPermissions, true)) }));
  } else if (user?.permissionForTeam) {
    yield spawn(spawnListBasedFlows);
    // Fetch regular permissions and set permissionForTeam to false to prevent unnecessary api calls on repetitive non-team folder selections
    const newPermissions = yield call(fetchPermissionInfoUser);
    yield put(ACTION_CREATORS.setUser({ ...user, ...(getUserPermissions(user, window.JOTFORM_ENV, newPermissions, false)) }));
  }
}

function* handleFolderSelect({ folderType, folder, currentTeamID: teamFilterID }) {
  // Override list based flows with team flow
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const selectedFolder = yield select(SELECTORS.getFolderByID(folder));
  if (currentPage !== LISTING_TYPES.TEAM_PAGE) {
    const teamFolderSelected = folderType === FOLDER_TYPES.TEAM;
    const teamSubfolderSelected = folderType === FOLDER_TYPES.TEAM_FOLDER;
    const isTeamFilterAllowedFolder = STATUS_FOLDERS.includes(folder);
    const teamFilter = isTeamFilterAllowedFolder ? teamFilterID : '';
    const currentTeam = currentTeamID || teamFilter;
    const toTeam = (teamFolderSelected || teamSubfolderSelected) || (isTeamFilterAllowedFolder && !!currentTeam);
    const teamID = teamFolderSelected ? folder : (currentTeam || selectedFolder.team_id);

    if (teamFolderSelected) {
      WatchmanRecorder.trackEventForCustomProject('teamFolderVisit', teamID, 'teams', true);
    }

    yield call(switchSpace, { toTeam, teamID });
  }
}

function* handleTeamFilter({ teamFilter }) {
  yield call(switchSpace, { toTeam: !!teamFilter, teamID: teamFilter });
}

function* folderButtonAction({ folder: folderID }) {
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const currentPage = yield select(SELECTORS.getCurrentPage);
  switch (folderID) {
    case 'createFolder': {
      if (currentPage === LISTING_TYPES.TEAM_PAGE) {
        yield handleCreateFolder({ parent: undefined }, currentTeamID, 'teamPage');
      } else {
        yield handleCreateFolder({}, null, 'leftPanel');
      }
      break;
    }
    case 'createFolderToolbar': {
      yield handleCreateFolder({ parent: undefined }, currentTeamID, 'addToFolderToolbar');
      break;
    }
    case 'createTeam': {
      yield put(ACTION_CREATORS.showCreateTeamModal(true, 'leftPanel'));
      break;
    }
    default:
      break;
  }
}

export function* rootFolderFlow() {
  yield takeLatest(ACTION_TYPES.FETCH_FOLDERS.REQUEST, initializeFolders);
  yield takeLatest(ACTION_TYPES.SET_SELECTED_FOLDER, handleFolderSelect);
  yield takeLatest(ACTION_TYPES.FOLDER_BUTTON_ACTION, folderButtonAction);
  yield takeLatest(ACTION_TYPES.SET_TEAM_FILTER, handleTeamFilter);
}
