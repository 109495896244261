import { AssetBeingEditedModal } from '@jotforminc/modals';
import React from 'react';
import { createRoot } from 'react-dom/client';

const root = document.getElementById('modal-container');

const openAssetBeingEdited = ({
  doneButtonText,
  IconRenderer,
  ContentRenderer,
  FooterRenderer,
  assetTitle,
  name,
  username,
  avatarURL,
  assetFilterType
} = {}) => {
  const rootNode = createRoot(root);

  return new Promise((resolve, reject) => {
    const handleClose = () => rootNode.unmount();

    const handleReject = () => {
      reject();
      handleClose();
    };

    const handleResolve = () => {
      resolve();
      handleClose();
    };

    rootNode.render(
      <AssetBeingEditedModal
        doneButtonText={doneButtonText}
        IconRenderer={IconRenderer}
        ContentRenderer={ContentRenderer}
        FooterRenderer={FooterRenderer}
        onDone={handleResolve}
        onClose={handleReject}
        defaultVisible={true}
        assetTitle={assetTitle}
        name={name}
        username={username}
        avatarURL={avatarURL}
        currentListing={assetFilterType}
      />
    );
  });
};

export default openAssetBeingEdited;
