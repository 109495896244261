import React, { useEffect, useState } from 'react';

import { IconArrowUpRightFromSquareSm, IconProductFormBuilderColorBorder } from '@jotforminc/svg-icons';

import * as API from '../../api';

const SelectedFormsList = () => {
  // expected response type
  // const testList = [{ formTitle: 'Object Test Form 1', formID: 233602551775962 }, { formTitle: null, formID: 233602551775962 }];
  const [filteredPaymentFormList, setFilteredPaymentFormList] = useState([]);
  const [errorExist, setErrorExist] = useState();
  const { user: { username } } = window;
  const url = window.location.host;

  const handlePaymentFormList = async () => {
    let filteredList;

    if (!username) {
      return;
    }
    try {
      filteredList = await API.getFilteredPaymentForms(username);
      setFilteredPaymentFormList(JSON.parse(filteredList));
    } catch (error) {
      if (error.response.data.responseCode !== 200) {
        setErrorExist(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    handlePaymentFormList();
  }, [username]);

  const formList = filteredPaymentFormList.map(key => (
    (key.formID !== null && key.formTitle !== null)
    && (
    <a href={`https://${url}/build/${key.formID}`} target="_blank" rel="noopener noreferrer">
      <div className="flex justify-between items-center px-3 py-2 mb-2 radius border border-navy-50">
        <div className="flex justify-start items-center">
          <IconProductFormBuilderColorBorder className="w-6 h-auto mr-2 shrink-0" />
          <span className="color-navy-700 text-sm">{key.formTitle}</span>
        </div>
        <IconArrowUpRightFromSquareSm className="w-5 h-5 ml-1 shrink-0" />
      </div>
    </a>
    )
  ));

  return (
    <>
      {filteredPaymentFormList.length > 0
        ? <div className="formList-dialog">{formList}</div>
        : <p className="font-medium text-sm line-height-md pt-4 text-center mb-4">{errorExist}</p>}
    </>
  );
};

export default SelectedFormsList;
