import React from 'react';
import './PageSectionRow.scss';

type SectionRowProps = Partial<{
  left: JSX.Element;
  right: JSX.Element;
  className: string;
  hidden: boolean;
}>

export const PageSectionRow: React.VFC<SectionRowProps> = ({
  left, right, hidden, className = '', ...props
}) => {
  if (hidden) return null;
  return (
    <div {...props} className={`section-row ${className}`}>
      {left ? <div className="section-row-left">{left}</div> : null}
      {right ? <div className="section-row-right">{right}</div> : null}
    </div>
  );
};
