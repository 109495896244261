/* eslint-disable complexity */
import React, { forwardRef } from 'react';
import {
  shape,
  func,
  string,
  number,
  oneOf,
  bool
} from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { isTranslationRendererNeeded } from '../utils';
import {
  ScNotificationOutlineNv
} from './sc/newNotification/scNotificationOutlineNv';
import ConfirmButtonSecondary from './ConfirmButtonSecondary';
import DismissButton from './DismissButton';
import { ScNotificationTitleNv } from './sc/newNotification/scNotificationTitleNv';
import { ScNotificationImageNv } from './sc/newNotification/scNotificationImageNv';
import { ScNotificationDescNv } from './sc/newNotification/scNotificationDescNv';
import { ScNotificationActionsNv } from './sc/newNotification/scNotificationActionsNv';
import ConfirmButton from './ConfirmButton';
import HipaaDismissButton from './HipaaDismissButton';

const NotificationNewVersionRenderer = forwardRef(({
  user,
  minHeight,
  hipaaStep,
  notification,
  hipaaFirstStepAnswer,
  isAutoVisibleNotificationMode,
  isAutoVisibleNotfInteracted,
  ConfirmButtonSecondaryRenderer,
  onDismiss,
  onConfirm,
  onConfirmSecondary,
  onHipaaStepChange
}, scNotificationOutlineRef) => {
  const showRedDot = !isAutoVisibleNotificationMode
  && !notification.isSeen
  && (!notification.isAutoVisible || (notification.isAutoVisible && !isAutoVisibleNotfInteracted));

  return (
    <ScNotificationOutlineNv
      minHeight={minHeight}
      notification={notification}
      ref={scNotificationOutlineRef}
    >
      {/* title */}
      {notification.text && (
      <ScNotificationTitleNv
        showRedDot={showRedDot}
        isBold={notification.textSecondary || notification.name === 'hubspotMilestone'}
        notification={notification}
      >
        <div className={notification.name === 'hubspotMilestone' ? 'row' : ''}>
          {/* temporary: for hubspot milestone automation */}
          {notification.name === 'hubspotMilestone' && (isTranslationRendererNeeded(notification.titleWithDataNew)
            ? translationRenderer(notification.titleWithDataNew)({
              renderer1: text => (
                <>
                  <strong className="addSpacer" key="0">
                    {text}
                    {!user.name && <>!</>}
                  </strong>
                </>
              ),
              renderer2: () => (
                <>
                  {user.name && (
                  <strong key="1">
                    {user.name}
                    !
                  </strong>
                  )}
                </>
              )
            }) : <p>{t(notification.titleWithDataNew)}</p>)}
          {/* for others */}
          {notification.name !== 'hubspotMilestone' && (isTranslationRendererNeeded(notification.text)
            ? translationRenderer(notification.text)({
              renderer1: text => (<p key="0">{text}</p>),
              renderer2: text => (<p key="1">{text}</p>)
            }) : <p>{t(notification.text)}</p>)}
        </div>
        {notification.name !== 'hipaaCompliance' && (
        <DismissButton
          showText={false}
          notification={notification}
          onDismiss={onDismiss}
        />
        )}
      </ScNotificationTitleNv>
      )}
      {/* image */}
      {notification.imgUrl && <ScNotificationImageNv src={notification.imgUrl} alt={notification.imgAlt} />}
      {/* description */}
      {notification.textSecondary && (
      <ScNotificationDescNv
        notification={notification}
      >
        <p>
          {/* temporary: for hubspot milestone automation */}
          {notification.name === 'hubspotMilestone' && (translationRenderer(notification.textSecondary)({
            renderer1: text => (
              <>
                <strong key="0">{text}</strong>
                <br />
              </>
            ),
            renderer2: text => (<a key="1" href={notification.learnMoreUrl} target="_blank">{text}</a>)
          }))}
          {/* temporary: enterprise price adjustment */}
          {notification.name === 'enterprisePriceAdjustment' && (translationRenderer(notification.textSecondary)({
            renderer1: text => (
              <>
                <p key="0">{text}</p>
                <br />
              </>
            ),
            renderer2: text => <strong>{text}</strong>
          }))}
          {/* for others */}
          {notification.name !== 'hubspotMilestone' && notification.name !== 'enterprisePriceAdjustment' && (isTranslationRendererNeeded(notification.textSecondary)
            ? translationRenderer(notification.textSecondary)({
              renderer1: text => (<p key="0">{text}</p>),
              renderer2: text => (<p key="1">{text}</p>)
            }) : <p>{t(notification.textSecondary)}</p>)}
        </p>
      </ScNotificationDescNv>
      )}
      {/* buttons */}
      <ScNotificationActionsNv>
        {/* primary */}
        {(notification.confirm || notification.name === 'hipaaCompliance') && (
        <ConfirmButton
          hipaaStep={hipaaStep}
          notification={notification}
          onConfirm={onConfirm}
          onHipaaStepChange={onHipaaStepChange}
        />
        )}
        {/* secondary */}
        {(notification.confirmSecondary || notification.name === 'hipaaCompliance') && (
        <ConfirmButtonSecondaryRenderer
          hipaaStep={hipaaStep}
          notification={notification}
          hipaaFirstStepAnswer={hipaaFirstStepAnswer}
          onDismiss={onDismiss}
          onHipaaStepChange={onHipaaStepChange}
          onConfirmSecondary={onConfirmSecondary}
        />
        )}
      </ScNotificationActionsNv>
    </ScNotificationOutlineNv>
  );
});

NotificationNewVersionRenderer.propTypes = {
  user: shape({ name: string }),
  notification: shape({
    text: string,
    confirm: shape({}),
    dismiss: shape({})
  }),
  hipaaStep: number,
  hipaaFirstStepAnswer: string,
  onDismiss: func,
  onConfirm: func,
  onHipaaStepChange: func,
  onConfirmSecondary: func,
  minHeight: number,
  ConfirmButtonSecondaryRenderer: oneOf([ConfirmButtonSecondary, HipaaDismissButton]),
  isAutoVisibleNotificationMode: bool,
  isAutoVisibleNotfInteracted: bool
};

NotificationNewVersionRenderer.defaultProps = {
  user: { name: '' },
  notification: {},
  hipaaStep: 0,
  hipaaFirstStepAnswer: null,
  onDismiss: f => f,
  onConfirm: f => f,
  onConfirmSecondary: f => f,
  onHipaaStepChange: f => f,
  minHeight: 0,
  ConfirmButtonSecondaryRenderer: ConfirmButtonSecondary,
  isAutoVisibleNotificationMode: false,
  isAutoVisibleNotfInteracted: false
};

export default NotificationNewVersionRenderer;
