import { handleCustomNavigation } from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';
import { setUserNotificationStatus } from '../utils';

export const setAppRecNotfToLocSt = () => StorageHelper.setLocalStorageItem({
  key: 'notification-cent-app-recommendation', value: '1'
});

const onConfirm = async notification => {
  handleCustomNavigation(`${window.location.origin}/myapps/?action=createRecommendedApp&recSource=2`, '_blank');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: notification.actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, disabledKey }) => {
  setAppRecNotfToLocSt();
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

// app recommendation (this is for jotform apps)
const checkAppRecommendation = notifications => {
  let notification = notifications.find(notif => notif.name === 'appRecommendation');
  const otherNotifications = notifications.filter(notif => notif.name !== 'appRecommendation');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkAppRecommendation;
