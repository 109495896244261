import React, { ReactNode } from 'react';
import { translationRenderer } from '@jotforminc/translation';
import { TeamRoleSlugs } from '../types';

export const TeamRoleDescription = (slug?: TeamRoleSlugs): ReactNode | string => {
  switch (slug) {
    case 'team_admin':
      return (
        <>
          {
            translationRenderer('Can [1[manage]] members and [2[view, move, edit,]] and [3[delete]] forms and data')({
              renderer1: (str: string) => <strong>{str}</strong>,
              renderer2: (str: string) => <strong>{str}</strong>,
              renderer3: (str: string) => <strong>{str}</strong>
            })
          }
        </>
      );
    case 'data_collaborator':
      return (
        <>
          {
            translationRenderer('Can [1[view]] forms and [2[view & edit]] data')({
              renderer1: (str: string) => <strong>{str}</strong>,
              renderer2: (str: string) => <strong>{str}</strong>
            })
          }
        </>
      );
    case 'form_collaborator':
      return (
        <>
          {
            translationRenderer('Can [1[view]] and [2[edit]] forms but [3[can\'t view]] data')({
              renderer1: (str: string) => <strong>{str}</strong>,
              renderer2: (str: string) => <strong>{str}</strong>,
              renderer3: (str: string) => <strong>{str}</strong>
            })
          }
        </>
      );
    case 'data_read_only':
      return (
        <>
          {
            translationRenderer('Can only [1[view]] forms and data')({
              renderer1: (str: string) => <strong>{str}</strong>
            })
          }
        </>
      );
    default:
      return '';
  }
};
