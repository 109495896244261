import React from 'react';
import {
  string, arrayOf, shape, func, number, node
} from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  ScGroupTitle,
  ScGroupWrapper,
  ScGroupTitleWrapper,
  ScGroupTitleContainer,
  ScCaretDown,
  ScGroupTitleIcon
} from './bitsListing';
import useToggle from '../../hooks/useToggle';
import useInView from '../../hooks/useInView';
import { LIST_FETCH_LIMIT } from '../../constants';

const Group = ({
  title,
  items,
  rowRenderer,
  icon = null
}) => {
  const [isVisible, toggleVisibility] = useToggle(true);
  const renderedItems = items.map((item, i) => rowRenderer({
    view: 'grouped', key: `item-${i}`, item, style: { height: '62px' }
  }));

  const handleTitleClick = () => toggleVisibility();

  return (
    <ScGroupWrapper role="row">
      <ScGroupTitleWrapper role="gridcell">
        <ScGroupTitleContainer onClick={handleTitleClick}>
          <ScGroupTitle aria-label={isVisible ? t(`collapse ${title}s`) : t(`expand ${title}s`)} type="button">
            {icon && (
            <ScGroupTitleIcon>
              {icon}
            </ScGroupTitleIcon>
            )}
            {title}
          </ScGroupTitle>
          <ScCaretDown isExpanded={isVisible} />
        </ScGroupTitleContainer>
      </ScGroupTitleWrapper>
      {isVisible && renderedItems}
    </ScGroupWrapper>
  );
};

Group.propTypes = {
  title: string.isRequired,
  items: arrayOf(shape()).isRequired,
  rowRenderer: func.isRequired,
  icon: node
};

const InfiniteLoader = ({ loadMoreRows, totalCount }) => {
  const [elRef] = useInView(() => loadMoreRows({ startIndex: totalCount - LIST_FETCH_LIMIT }));
  return (<div ref={elRef}>&nbsp;</div>);
};

InfiniteLoader.propTypes = {
  loadMoreRows: func.isRequired,
  totalCount: number.isRequired
};

const generateGroups = listSource => Array.from(listSource.reduce((acc, item) => {
  const formID = item.form_id || item.formID;

  // If no group for a form is defined yet, define it.
  if (!acc.has(formID)) {
    acc.set(formID,
      {
        id: formID,
        title: item.form_title || (item.form?.title) || 'Form',
        items: []
      });
  }

  // Add this item to its form's group
  acc.get(formID).items.push(item);
  return acc;
}, new Map()).values());

const GroupedList = ({
  totalCount, listSource, rowRenderer, loadMoreRows, icon = null
}) => {
  const groupedList = generateGroups(listSource).map((group, index) => (
    <Group
      {...group}
      icon={icon}
      rowRenderer={rowRenderer}
      key={`${group.id}_${index}`} // eslint-disable-line react/no-array-index-key
    />
  ));

  const showLoader = (listSource.length > 0 && (listSource.length < totalCount));

  const loadingItems = Array(totalCount - listSource.length).fill({}).map(rowRenderer);

  return (
    <>
      {groupedList}
      {showLoader && <InfiniteLoader loadMoreRows={loadMoreRows} totalCount={totalCount} /> }
      {loadingItems}
    </>
  );
};

GroupedList.propTypes = {
  listSource: arrayOf(shape()).isRequired,
  totalCount: number.isRequired,
  loadMoreRows: func.isRequired,
  rowRenderer: func.isRequired,
  icon: node
};

export default GroupedList;
