/* eslint-disable complexity */
import React, {
  useMemo, useState, useCallback, useEffect
} from 'react';
import {
  arrayOf, shape, string, func, bool, oneOfType, number, node, element
} from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { Hooks } from '@jotforminc/uikit';
import { useDrop, useDrag } from 'react-dnd';

import BasicFolder from '../Folder/BasicFolder';

import {
  FOLDER_DROP_POSITIONS,
  FOLDER_TYPES,
  LISTING_TYPES
} from '../../constants';
import { TEAM_PERMISSIONS } from '../../utils/permissions';
import { calculateHoverPosition, getFolderRenderer } from '../Folder/utils';
import { PreviewRenderer, OverPreviewRenderer } from '../Folder/PreviewRenderer';

const TeamFolder = props => {
  const {
    id, className = '', color = '#6F76A7', text = '', badge = '', subfolders = [], canDrag = false, hasContextMenu = false, hasNewBadge = false, selectedFolderID = '',
    handleClick = f => f, isDroppable = false, hasIcon = false, onDrop = f => f, children, folderType = FOLDER_TYPES.STATIC, team_id: teamId = '', subText = '',
    extendedSection = null, menuItems = [], customIconProps = {
      iconBgColor: '', url: '', icon: '', emojiId: '', emojiSize: '', iconColor: '', iconSvgRef: ''
    }, user = {}, isTeamCreationDisabled = false, teamPermissions = {}, canExpand = false, isFocussable = false, isTeamDroppable = false, teamFolderMenuItems = [], currentTeamID = '',
    canReorderSubfolders = false
  } = props;

  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [hoverPosition, setHoverPosition] = useState(false);

  const isTeamSubFolder = folderType === FOLDER_TYPES.TEAM_FOLDER;
  const isTeamFolder = folderType === FOLDER_TYPES.TEAM;
  const isTeamRelatedFolder = isTeamFolder || isTeamSubFolder;
  const teamID = isTeamFolder ? id : teamId;
  const preventReorderSubfolders = isTeamSubFolder && !canReorderSubfolders;

  const getHoverPos = ({
    isOver, monitor, isTeamItem
  }) => {
    // eslint-disable-next-line no-use-before-define
    return isOver ? calculateHoverPosition(combinedRef?.current, monitor, isTeamItem) : false;
  };

  const debouncedUpdateHoverPosition = debounce(newHoverPos => {
    if (hoverPosition !== newHoverPos) setHoverPosition(newHoverPos);
  }, 100);

  const [{
    isHighlighted,
    isItemHovering,
    draggingItemType,
    isTeamDragging,
    isOtherTeamAssetDragging
  }, dropRef] = useDrop(() => ({
    canDrop: currentDraggingItem => {
      const isTeamItem = currentDraggingItem.folderType === FOLDER_TYPES.TEAM;
      if (currentDraggingItem.type === 'folder' && (isTeamItem ? isTeamSubFolder : isTeamRelatedFolder)) {
        return false;
      }
      return isTeamItem ? isTeamDroppable : isDroppable;
    },
    accept: [...Object.values(LISTING_TYPES), 'folder'],
    drop: (item, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (!isOver && (isTeamRelatedFolder || folderType === FOLDER_TYPES.ALL_TEAM_RESOURCES)) {
        return null;
      }
      const isTeamItem = item.folderType === FOLDER_TYPES.TEAM;
      onDrop({
        item,
        monitor,
        folderID: id,
        position: getHoverPos({ isOver, monitor, isTeamItem })
      });
    },
    collect: monitor => {
      const item = monitor.getItem();
      const itemType = monitor.getItemType();
      const isOver = monitor.isOver({ shallow: true });
      const isTeamItem = item?.folderType === FOLDER_TYPES.TEAM;
      const preventHovering = isTeamItem ? isTeamSubFolder : isTeamRelatedFolder;
      const isOtherTeamAsset = item?.type === 'asset' && !!currentTeamID && currentTeamID !== teamID;
      debouncedUpdateHoverPosition(getHoverPos({ isOver, monitor, isTeamItem }));

      return {
        isItemHovering: isOver && itemType === 'folder' && !preventHovering && !isOtherTeamAsset,
        isHighlighted: isOver && !isOtherTeamAsset && Object.values(LISTING_TYPES).indexOf(itemType) !== -1,
        draggingItemType: itemType,
        isTeamDragging: isTeamItem,
        isOtherTeamAssetDragging: isOtherTeamAsset
      };
    }
  }), [isDroppable, isTeamDroppable, currentTeamID, teamID]);
  const [{ draggingItem }, dragRef] = useDrag(() => ({
    item: {
      id,
      text,
      color,
      canDrag,
      folderType,
      type: 'folder',
      customIconProps,
      subText
    },
    type: 'folder',
    canDrag: () => {
      return canDrag;
    },
    collect: monitor => ({
      draggingItem: monitor.getItem()
    }),
    previewOptions: {
      DraggingState: true
    }
  }));

  useEffect(() => {
    if (!draggingItemType) {
      setHoverPosition(false);
    }
  }, [draggingItemType]);

  const refList = [canDrag && dragRef, (isDroppable || isTeamDroppable) && dropRef].filter(val => val);
  const combinedRef = Hooks.useCombinedRefs(...refList);
  const isCurrentFolderSelected = selectedFolderID === id;
  const hasSubfolder = subfolders.length > 0;
  const showDraggingFolderPreview = isItemHovering && draggingItem && draggingItem.id !== id && draggingItem.type === 'folder';

  const classes = classnames({
    isSelected: isCurrentFolderSelected,
    folderItem: true,
    [className]: true,
    isContextMenuOpen,
    isHighlighted: isHighlighted && (!showDraggingFolderPreview || hoverPosition === FOLDER_DROP_POSITIONS.OVER),
    isTeamDragging
  });

  const changeContextMenuVisibility = isExpanded => setContextMenuOpen(isExpanded);

  const handleRendererClick = e => {
    e.stopPropagation();
    handleClick(id, folderType, teamID);
  };

  const Renderer = useMemo(() => getFolderRenderer(folderType), [folderType]);
  const FolderPreview = useCallback(() => (
    <BasicFolder
      {...draggingItem}
      WrapperRenderer={PreviewRenderer}
    />
  ), [draggingItem]);

  const OverFolderPreview = useCallback(() => (
    <BasicFolder
      {...draggingItem}
      WrapperRenderer={OverPreviewRenderer}
    />
  ), [draggingItem]);

  useEffect(() => {
    if (folderType === FOLDER_TYPES.TEAM && isCurrentFolderSelected && combinedRef?.current) {
      combinedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [folderType, isCurrentFolderSelected]);

  return (
    <Renderer
      key={id}
      ref={combinedRef}
      className={classes}
      onClick={handleRendererClick}
      {...folderType === FOLDER_TYPES.CREATE_TEAM_BUTTON ? { user, isTeamCreationDisabled } : {}}
    >
      {showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.BEFORE ? (
        <FolderPreview />
      ) : null}
      <BasicFolder
        id={id}
        text={text}
        color={color}
        badge={badge}
        hasIcon={hasIcon || !!(teamId && color)}
        folderType={folderType}
        hasSubfolder={hasSubfolder}
        hasContextMenu={folderType === FOLDER_TYPES.TEAM_FOLDER ? teamPermissions[TEAM_PERMISSIONS.CREATE_FOLDER] || hasContextMenu : hasContextMenu}
        hasNewBadge={hasNewBadge}
        subText={subText}
        isItemHovering={isItemHovering}
        changeContextMenuVisibility={changeContextMenuVisibility}
        forceContextMenuVisibility={hoverPosition === FOLDER_DROP_POSITIONS.OVER && !(draggingItemType === 'folder' && isTeamRelatedFolder) && !isOtherTeamAssetDragging}
        extendedSection={extendedSection}
        menuItems={folderType === FOLDER_TYPES.TEAM_FOLDER ? teamFolderMenuItems : menuItems}
        customIconProps={customIconProps}
        canExpand={canExpand}
        isFocussable={isFocussable}
        isTeamDragging={isTeamDragging}
        isDragAnimationHidden={preventReorderSubfolders}
      >
        {children}
        {showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.OVER && !isTeamDragging ? (
          <OverFolderPreview />
        ) : null}
      </BasicFolder>
      {showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.AFTER ? (
        <FolderPreview />
      ) : null}
    </Renderer>
  );
};

TeamFolder.propTypes = {
  id: string.isRequired,
  className: string,
  text: string,
  color: string,
  badge: oneOfType([string, number]),
  subfolders: arrayOf(shape({})),
  canDrag: bool,
  hasContextMenu: bool,
  hasNewBadge: bool,
  selectedFolderID: string,
  handleClick: func,
  isDroppable: bool,
  hasIcon: bool,
  onDrop: func,
  children: shape({}).isRequired,
  folderType: string,
  team_id: string,
  subText: oneOfType([string, node, element]),
  extendedSection: oneOfType([node, element]),
  menuItems: arrayOf(shape({})),
  customIconProps: shape({}),
  user: shape({}),
  isTeamCreationDisabled: bool,
  teamPermissions: shape({}),
  canExpand: bool,
  isFocussable: bool,
  isTeamDroppable: bool,
  teamFolderMenuItems: arrayOf(shape({})),
  currentTeamID: oneOfType([string, number]),
  canReorderSubfolders: bool
};

export default TeamFolder;
