import { createRoot } from 'react-dom/client';
import React from 'react';
import { NotificationCenter } from '@jotforminc/notification-center';
import { isEnterprise } from '@jotforminc/enterprise-utils';

let root;

export const renderNotificationCenter = ({
  user,
  initInAutoVisibleMode = false
}) => {
  const rootEl = document.querySelector('#notification-center');
  const bellContainerElForMobile = document.querySelector('#notification-center-mobile');

  if (!rootEl || !bellContainerElForMobile) return;

  if (!root) {
    root = createRoot(rootEl);
  }
  root.unmount();
  root = createRoot(rootEl);

  root.render(<NotificationCenter
    user={user}
    enterpriseTheme={isEnterprise()}
    initInAutoVisibleMode={initInAutoVisibleMode}
    bellContainerElForMobile={bellContainerElForMobile}
  />);
};
