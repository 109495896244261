import React from 'react';
import ReactDOM from 'react-dom';
import { t } from '@jotforminc/translation';
import { CloneModal } from '@jotforminc/create-new-wizards';
import * as API from '../api';
import { showError } from '../components/ListItem/toastForItemAction';
import WatchmanRecorder from '../utils/WatchmanRecorder';

const CLONE_AGENT_SETTINGS = {
  WITH_RESOURCES: '1',
  WITHOUT_RESOURCES: '0'
};

const CLONE_AGENT_OPTIONS = [
  {
    showWhenSelected: t('Keep connection with forms'),
    text: t('Keep connection with forms'),
    desc: t('Create a copy of the agent while retaining its form connection.'),
    value: CLONE_AGENT_SETTINGS.WITH_RESOURCES
  }, {
    showWhenSelected: t('Remove forms connection'),
    text: t('Remove forms connection'),
    desc: t('Create a copy of the agent and move forward without the forms.'),
    value: CLONE_AGENT_SETTINGS.WITHOUT_RESOURCES
  }
];

const openCloneAgentModal = ({
  agent,
  id
}) => {
  const containerSelector = '#modal-container';
  const root = document.querySelector(containerSelector);
  const agentTitle = agent?.title || 'Agent';
  const isThereAnyForm = agent?.resources?.forms?.length > 0;

  return new Promise((resolve, reject) => {
    const handleSelectionChange = selectedOpt => {
      WatchmanRecorder.trackEvent('click', `cloneAgent${selectedOpt === CLONE_AGENT_SETTINGS.WITH_RESOURCES ? '-withFormsOption' : '-withoutFormsOption'}`, 'agents');
    };

    const onClone = async ({ title, cloneSetting }) => {
      const selectedOptionText = cloneSetting === CLONE_AGENT_SETTINGS.WITH_RESOURCES ? '-withForms' : '-withoutForms';
      const defaultClonedTitle = t(`Clone of ${agentTitle}`);
      WatchmanRecorder.trackEvent('click', `cloneAgentButton${title !== defaultClonedTitle ? '-dirtyTitle' : ''}${!isThereAnyForm ? '-withoutOptions' : selectedOptionText}`, 'agents');
      try {
        const newAgentID = await API.cloneAgent({ id, title, cloneResources: cloneSetting });
        resolve({ newAgentID });
        ReactDOM.unmountComponentAtNode(root);
      } catch (err) {
        showError();
        reject();
        ReactDOM.unmountComponentAtNode(root);
      }
    };

    const handleClose = () => {
      reject();
      ReactDOM.unmountComponentAtNode(root);
    };

    ReactDOM.render((
      <CloneModal
        root={root}
        title={agentTitle}
        defaultSetting={CLONE_AGENT_SETTINGS.WITH_RESOURCES}
        onClone={onClone}
        onClose={handleClose}
        isThereAnyForm={agent?.resources?.forms?.length > 0}
        handleSelectionChange={handleSelectionChange}
        header={{
          text: 'Clone Agent',
          description: 'Duplicate your agent, including all settings.'
        }}
        settingsProps={{
          inputLabel: 'Agent Name',
          inputPlaceholder: 'Enter an agent name',
          settingLabel: 'Agent Settings',
          options: CLONE_AGENT_OPTIONS
        }}
        buttonText='Clone Agent'
        preventEmptyTitle={true}
        emptyTitleErrorMessage='Agent name cannot be empty!'
        modalHeaderProps={{
          customIconColorStyle: 'aiAgentsBackground'
        }}
      />
    ), root);
  });
};

export default openCloneAgentModal;
