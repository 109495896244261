import React, { Component, ErrorInfo } from 'react';
import { t } from '@jotforminc/translation';
import Tracking from '@jotforminc/tracking';

import IconExclamation from '../assets/svg/IconExclamation.svg';
import style from '../styles/errorBoundry.module.scss';

type Props = {
  setBreakingErrorState?: (error: Error | unknown) => void
}

class ErrorBoundry extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { setBreakingErrorState } = this.props;
    setBreakingErrorState?.(error);

    Tracking.captureException(error, (scope: {
      addBreadcrumb: (breadcrumbs: Record<string, unknown>) => void;
    }) => {
      scope.addBreadcrumb({
        type: 'error',
        level: 'error',
        category: 'aiBuilderError',
        message: errorInfo.componentStack
      });
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className={style.container}>
          <div className={style.modal}>
            <div className={style.icon}>
              <IconExclamation />
            </div>
            <div className={style.text}>
              <span className={style.title}>
                {t('An unexpected error occurred')}
              </span>
              <p className={style.desc}>
                {t('We’ve been notified of the issue and aim to fix it shortly. Please try again later.')}
              </p>
            </div>
            <a href='/myforms' className={style.backButton}>
              {t('Back to My Forms')}
            </a>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundry;
