import { setFlagToLocSt, setUserNotificationStatus } from '../utils';

const urlParams = new URLSearchParams(window.location.search);

const onDismiss = (notification, isAutoVisibleNotificationMode) => {
  if (!isAutoVisibleNotificationMode && !urlParams.get(notification.urlParam)) {
    setFlagToLocSt(notification.localStActionKey);
    setUserNotificationStatus(notification.disabledKey);
  }
  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: notification.actionTargetName
    });
  }
};

const checkEnterprisePriceAdjustment = notifications => {
  let notification = notifications.find(notif => notif.name === 'enterprisePriceAdjustment');
  const otherNotifications = notifications.filter(notif => notif.name !== 'enterprisePriceAdjustment');

  if (!notification) return notifications;

  notification = {
    ...notification,
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkEnterprisePriceAdjustment;
