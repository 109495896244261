/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogBody,
  DialogTitle,
  DialogDescription,
  DialogContent,
  DialogActions,
  DialogCloseButton
} from '@jotforminc/magnet';

import { LogoSquareDark } from '@jotforminc/svg-icons';

import SelectedFormsList from './SelectedFormsList';

const GatewayWarningDialog = ({ shouldShow = false, onClose = f => f }) => {
  return (
    <>
      <Dialog
        open={shouldShow}
        onClose={onClose}
        ariaLabel="Dialog Title"
      >
        <DialogBody className="pt-8 px-8">
          <div className="radius bg-red-100 py-5 mb-8 max-w-80 w-full text-center mx-auto">
            <LogoSquareDark className="w-28 h-auto" />
          </div>
          <DialogTitle>Your action is required</DialogTitle>
          <DialogDescription className="line-height-md">
            The following forms have Square integration and are collecting subscription payments.
            <span className="font-bold"> They will stop receiving payments unless reconnected until March 1, 2024.</span>
          </DialogDescription>
          <DialogContent className="px-8">
            <SelectedFormsList />
          </DialogContent>
        </DialogBody>
        <DialogActions>
          <Button colorStyle="primary" variant="filled" onClick={onClose}>OK, Got it!</Button>
        </DialogActions>
        <DialogCloseButton onClick={onClose} />
      </Dialog>
    </>
  );
};

GatewayWarningDialog.propTypes = {
  shouldShow: PropTypes.bool,
  onClose: PropTypes.func
};

export default GatewayWarningDialog;
