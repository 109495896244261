import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

export const r = new RequestLayer('/API/listings', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer],
    shouldCache: true,
    logCachePayload: {
      projectName: 'listings'
    }
  }
});
