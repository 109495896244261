import { setSurveyOffered } from '../utils';

const onConfirm = ({ actionTargetName, surveyFormId }, user) => {
  if (!surveyFormId) return;

  global.open(`https://form.jotform.com/${surveyFormId}?username=${user.username}`, '_blank');
  setSurveyOffered(surveyFormId);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, surveyFormId }) => {
  if (!surveyFormId) return;

  setSurveyOffered(surveyFormId);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkUxrSurvey = notifications => {
  let notification = notifications.find(notif => notif.name === 'uxrSurvey');
  const otherNotifications = notifications.filter(notif => notif.name !== 'uxrSurvey');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkUxrSurvey;
