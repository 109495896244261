import React, { useCallback } from 'react';
import RightPanel from '@jotforminc/right-panel';
import { IconClockArrowRotateRight } from '@jotforminc/svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityLog } from '@jotforminc/enterprise-components';
import { t } from '@jotforminc/translation';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

const TeamActivityPanel = () => {
  const isVisible = useSelector(SELECTORS.getIsTeamActivityPanelOpen);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const teamMembers = useSelector(SELECTORS.getCurrentTeamMembers);
  const userCredentials = useSelector(SELECTORS.getUserCredentials);
  const userLanguage = useSelector(SELECTORS.getUserLanguage);
  if (!window.userTimeZone) {
    window.userTimeZone = userCredentials?.time_zone || 'America/New_York';
  }
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(ACTION_CREATORS.toggleTeamActivityPanel(false));
  }, []);

  if (!isVisible) return null;

  return (
    <RightPanel
      isVisible={isVisible}
      onClose={onClose}
      panelTitle={t('Activity')}
      theme="light"
      headerIcon={<IconClockArrowRotateRight />}
      closeOnClickOutside={false}
    >
      {isVisible && (
        <div id='activityLogContainer' style={{ height: '100%', overflow: 'auto' }}>
          <ActivityLog
            scrollableTarget='activityLogContainer'
            teamID={currentTeamID}
            rightPanelVisible={false}
            titleVisible={false}
            downloadButtonVisible={false}
            isListView
            filterUsers={teamMembers}
            userLanguage={userLanguage}
          />
        </div>
      )}
    </RightPanel>
  );
};

export default TeamActivityPanel;
