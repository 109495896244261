import React, {
  forwardRef, useState, useMemo, useCallback, useImperativeHandle, useRef
} from 'react';
import {
  func, oneOf, shape, string
} from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';

import { ResourcePicker, resourceConfigs } from '@jotforminc/resource-picker';
import { ScPickerModal } from './ScPickerModal';
import { ScButton } from './ScButton';

const ResourcePickerModal = forwardRef(({
  resourceType,
  defaultValue = '',
  onResourceSelectionChange = f => f,
  onResourceSelectionComplete = f => f,
  onCreateNewResource = null,
  headerDescription = '',
  footerCreateNew = t('Create New'),
  footerCancel = t('Cancel'),
  footerDone = t('Done'),
  // eslint-disable-next-line
  classNames = '',
  pickerProps = {},
  ...rest
}, ref) => {
  const [selectedResource, setSelectedResource] = useState('');
  const [resources, setResources] = useState([]);
  const pickerRef = useRef();

  useImperativeHandle(ref, () => ({
    ...pickerRef.current,
    showPicker: content => {
      setSelectedResource('');
      setResources(content);
      pickerRef.current.show();
    }
  }));

  const onChange = useCallback(resourceID => {
    if (resourceID !== selectedResource && resourceID !== '') {
      setSelectedResource(resourceID);
    }
  }, [selectedResource]);

  const hidePicker = useCallback(() => {
    pickerRef.current.hide();
  }, []);

  const handleCreateNew = useCallback(() => {
    onCreateNewResource();
    hidePicker();
  }, []);

  const isDoneButtonActive = useMemo(() => !selectedResource, [selectedResource]);

  const onDoneClick = useCallback(() => {
    if (selectedResource) {
      onResourceSelectionComplete(selectedResource);
    }
    hidePicker();
  }, [selectedResource, resources]);

  const Footer = useMemo(() => (
    <div className="pm-f">
      {onCreateNewResource && typeof onCreateNewResource === 'function' ? (
        <ScButton className="taste isGray" onClick={handleCreateNew}>
          {footerCreateNew}
        </ScButton>
      ) : (
        <ScButton className="taste isGray" onClick={hidePicker}>
          {footerCancel}
        </ScButton>
      )}
      {resources.length !== 0 && (
      <ScButton className="taste isGreen" onClick={onDoneClick} disabled={isDoneButtonActive}>
        {footerDone}
      </ScButton>
      )}
    </div>
  ), [isDoneButtonActive, resources]);

  const Header = useMemo(() => (
    <>
      <div className="pm-t">{t(resourceConfigs[resourceType].headerTitle || '')}</div>
      <p className="pm-d">{headerDescription}</p>
    </>
  ), []);

  Hooks.useEffectIgnoreFirst(() => {
    onResourceSelectionChange(selectedResource);
  }, [selectedResource]);

  return (
    <ScPickerModal
      ref={pickerRef}
      header={Header}
      footer={Footer}
      usePortal={true}
      {...rest}
    >
      <ResourcePicker
        resourceType={resourceType}
        resources={resources}
        defaultValue={defaultValue}
        onSelectionChange={onChange}
        iconColor="#FFF"
        {...pickerProps}
      />
    </ScPickerModal>
  );
});

ResourcePickerModal.propTypes = {
  resourceType: oneOf(Object.keys(resourceConfigs)).isRequired,
  defaultValue: string,
  onResourceSelectionChange: func,
  onResourceSelectionComplete: func,
  onCreateNewResource: func,
  headerDescription: string,
  footerCreateNew: string,
  footerCancel: string,
  footerDone: string,
  classNames: string,
  pickerProps: shape({})
};

export default ResourcePickerModal;
