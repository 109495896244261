/* eslint-disable max-len */
import Styled from 'styled-components';

export const ScAutoVisibleNotificationOverlay = Styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  background: radial-gradient(65.82% 137.95% at 85.45% 34.18%, rgba(12, 21, 78, 0.21) 0%, rgba(255, 255, 255, 0) 100%);
`;
