import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewWorkflowWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewWorkflowWizard" */'./LazyWizards/LazyCreateNewWorkflowWizard'));

export const openCreateNewWorkflowModal = props => {
  return new Promise((resolve, reject) => {
    const containerSelector = '#modal-container';
    const rootElement = document.querySelector(containerSelector);
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    const handleClose = () => {
      reject();
      window.history.replaceState({}, '', window.location.pathname);
      unmountComponentAtNode(rootElement);
      if (props.closeButtonCallback) {
        props.closeButtonCallback();
      }
    };

    const handleCreate = ({ type }) => {
      resolve(type);
    };

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    const handleShow = () => {
      if (props.openWizardCallback) props.openWizardCallback();
    };

    lazyRender({
      root: rootElement,
      children: (
        <CreateNewWorkflowWizard
          {...props}
          onShow={handleShow}
          onHide={handleClose}
          onCreate={handleCreate}
          initialVisibilityState={true}
          onViewChange={onViewChange}
        />
      )
    });
  });
};
