import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { Loading } from '@jotforminc/loading';

import { safeLazy } from '@jotforminc/safe-lazy-import';
import { FEATURE_LIST, LISTING_TYPES } from '../../constants';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { TEAM_PERMISSIONS } from '../../utils/permissions';

const TeamCover = safeLazy(() => import(/* webpackChunkName: "LazyComponent_TeamCover" */'@jotforminc/enterprise-components/src/components/Cover').then(module => ({ default: module.Cover })));

const Cover = ({ canEdit = false }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const teamRoles = useSelector(SELECTORS.getTeamRoles);
  const teamId = useSelector(SELECTORS.getCurrentTeamID) || '';
  const team = useSelector(SELECTORS.getCurrentTeam) || {};
  const manageTeam = useSelector(SELECTORS.getTeamPermissionFetcher)(TEAM_PERMISSIONS.MANAGE_TEAM);
  const username = useSelector(SELECTORS.getUserName) || '';
  const accountType = useSelector(SELECTORS.getAccountType) || '';
  const serverTeamSettings = useSelector(SELECTORS.getServerTeamSettings) || {};
  const isHIPAA = useSelector(SELECTORS.getIsHipaa);
  const isAssetsFolderActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));

  const onUpdate = newData => {
    dispatch(ACTION_CREATORS.updateTeamProperties(teamId, newData, currentPage, isAssetsFolderActive));
  };

  const onCurrentUserUpdate = error => {
    dispatch(ACTION_CREATORS.handleTeamError(error, teamId, true));
  };

  return (currentPage === LISTING_TYPES.TEAM_PAGE && teamId) ? (
    <Suspense fallback={<Loading />}>
      <TeamCover
        canEdit={canEdit}
        onUpdate={onUpdate}
        isAdmin={manageTeam}
        teamRoles={teamRoles}
        user={{ username, accountType }}
        onCurrentUserUpdate={onCurrentUserUpdate}
        showRequestToJoinButton={true}
        globalTeamSettings={serverTeamSettings}
        teamData={team}
        isHIPAA={isHIPAA}
      />
    </Suspense>
  ) : null;
};

Cover.propTypes = {
  canEdit: bool
};

export default Cover;
