import React from 'react';
import { EducationBdmFlowBanner } from '../../../Banners';

// A/B Test: epEducationBdmFlow
const EducationBdmFlow = ({
  ...props
}) => {
  return (
    <EducationBdmFlowBanner
      {...props}
    />
  );
};

export default EducationBdmFlow;
