import Styled from 'styled-components';
import { breakpoints } from '../../../constants';

export const ScButtonNv = Styled.button`
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.006em;
  min-width: 64px;
  transition: .2s;
  padding: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-right: ${({ marginRight }) => marginRight};
  margin-left:  ${({ marginLeft }) => marginLeft};
  color: #FFFFFF;
  background: #18235C;

  ${({ lightView }) => {
    if (lightView) {
      return `
        color: #343C6A;
        background: transparent;
        border: 1px solid #C8CEED;
        padding: 12px 24px;
      `;
    }
  }}

  @media screen and (max-width:${breakpoints.tablet}){
    min-height: 42px;
    padding: 12px;
  }

  &:focus{
    outline: 3px solid rgba(48, 60, 119, .5);

    ${({ lightView }) => {
    if (lightView) {
      return `
      background: #F3F3FE;
      outline: 3px solid rgb(227, 229, 245, .5);
        `;
    }
  }}
  }

  &:hover {
    background: #0C1540;

    ${({ lightView }) => {
    if (lightView) {
      return `
      background: #F3F3FE;
          `;
    }
  }}
  }
`;
