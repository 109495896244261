/* eslint-disable complexity */
/* eslint-disable max-statements */
import {
  call, put, select, takeLatest, all, delay, race, spawn, take
} from 'redux-saga/effects';
import { t } from '@jotforminc/translation';

import { rootFormFlow } from '../listBased/form';
import { rootDraftFlow } from '../listBased/draft';
import { rootSheetFlow } from '../listBased/sheet';
import { rootTaskFlow } from '../listBased/approval';
import { rootBoardFlow } from '../listBased/board';
import { rootPortalFlow } from '../listBased/portal';
import { rootDocumentFlow } from '../listBased/document';
import { rootAgentFlow } from '../listBased/agent';
import { rootCommonListFlow } from '../listBased/common';
import { rootAssetFolderFlow } from '../listBased/assetFolder';
import { rootFolderLayoutFlow } from '../listBased/assetFolderLayout';
import { rootReportFlow, formReportFlow, portalReportFlow } from '../listBased/report';
import { rootTeamFlow } from '../listBased/team';
import { rootMixFlow } from '../listBased/mix';
import { rootContactFlow } from '../listBased/contact';
import { rootPageFlow } from '../listBased/page';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';
import {
  getAssetTypeFromListingType, getPageFromUrl, getAssetType, findItemByIDAndType, areTheSameItems, createListsFromSelectedItems, canAccessMyAgentsPage
} from '../../utils';
import { renameToastCall, showError } from '../../components/ListItem/toastForItemAction';
import {
  normalizeFetchedList,
  registerUniqueAction,
  filterFrontEnd,
  sortList,
  removeListDuplications,
  removeListDuplicationsWithType,
  syncRequestSaga
} from '../utils';
import { ListActionToast } from '../../contexts/list/utils';

import {
  LISTING_TYPES,
  LIST_FETCH_LIMIT,
  LISTING_API_END_POINT_MAP,
  filterKeys,
  FOLDER_TYPES,
  FOLDER_ITEM_ACTION_TYPES,
  FEATURE_LIST,
  ALL_ASSETS_ID,
  ASSETS_FOLDER_LISTINGS,
  MIX_ENDPOINT_DISABLED_FOLDERS,
  LISTING_TYPES_REVERT,
  DEFAULT_FOLDER_IDS,
  STATUS_FOLDERS,
  ASSIGNED_FORM_ENABLED_PAGES,
  ASSET_TYPES
} from '../../constants';
import { querySharedwithMeResults, querySharedWithMeResultsByUsername } from '../utils/sharedWithMeLocalFetcher.tmp';
import { TEAM_PERMISSIONS } from '../../utils/permissions';

const listFetchers = {
  forms: API.fetchForms,
  approvals: API.fetchMyApprovals,
  folderApprovals: API.fetchFolderApprovals,
  assignedApprovals: API.fetchApprovals,
  folderForms: isShared => (query, selectedFolder) => API.fetchFolderForms({ query, folderID: selectedFolder, isShared }),
  newFolderForms: API.fetchFolderFormsNew,
  assignedForms: API.fetchAssignedForms,
  draftForms: API.fetchDraftForms,
  apps: API.fetchApps,
  folderApps: API.fetchFolderApps,
  tables: API.fetchTables,
  folderTables: API.fetchFolderTables,
  sharedWithMeForms: API.fetchSharedWithMeForms,
  sharedWithMeByOwnerV2: owner => query => API.fetchSharedWithMeByOwnerV2({ query, owner }),
  sharedWithMeAllV2: isGuestUser => query => API.fetchSharedWithMeAllV2({ query, isGuestUser }),
  reports: API.fetchReports,
  folderReports: API.fetchFolderReports,
  signDocuments: API.fetchSignDocuments,
  folderSignDocuments: API.fetchFolderSignDocuments,
  sharedReports: API.fetchSharedReports,
  sharedTables: API.fetchSharedTables,
  sharedWithMe: API.fetchSharedWithMe,
  mySubmissions: API.fetchUserSubmissions,
  filledForms: API.fetchFilledForms,
  contacts: API.fetchContactsByUserName,
  pages: API.fetchPagesByUsername,
  teamAssets: ({
    isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
  }) => (query, selectedFolder, folderType, currentTeamID) => API.fetchTeamAssets({
    query, selectedFolder, folderType, currentTeamID, isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
  }),
  teamAssetsByType: ({
    type, teamFilter, folderType, currentTeamID, isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
  }) => (query, selectedFolder) => API.fetchTeamAssets({
    query, selectedFolder, type, teamFilter, folderType, currentTeamID, isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
  }),
  emptyFetcher: () => new Promise(resolve => resolve({ data: { content: [] } })),
  teams: () => API.getUserTeams({ disableJotFormNormalize: true }),
  mixAssets: ({
    assetFilter, assetFilterType, teamFilter, isAllAssetFilterSelected, currentPage, canAccessMyAgents
  }) => (query, selectedFolder, folderType, currentTeamID) => API.fetchMixAssets({
    query, selectedFolder, folderType, currentTeamID, assetFilter, assetFilterType, teamFilter, isAllAssetFilterSelected, currentPage, canAccessMyAgents
  }),
  boards: API.fetchBoards,
  folderBoards: API.fetchFolderBoards,
  sharedBoards: API.fetchSharedBoards,
  folderAgents: API.fetchFolderAgents,
  agents: ({ assetFilter }) => query => API.fetchAgents({ query, assetFilter })
};
const page = LISTING_API_END_POINT_MAP[getPageFromUrl()];
const apiAction = new API.CommonActions(page);
const shareActions = new API.ShareActions(page);

// eslint-disable-next-line complexity
const getListFetcher = ({
  currentPage, selectedFolder, folderType, teamFilter, currentTeamID, isAssetsFolderActive, isSharedWithMeV2, folderObject, isGuestUser,
  assetFilter, assetFilterType, isMixStructureEnabled, isAllAssetFilterSelected, canAccessMyAgents
}) => {
  const isSharedFolder = selectedFolder === DEFAULT_FOLDER_IDS.SHARED_WITH_ME || folderType === FOLDER_TYPES.USERNAME;
  const isAnyAssetFolderSelected = [FOLDER_TYPES.ASSET_FOLDER, FOLDER_TYPES.TEAM_FOLDER].indexOf(folderType) > -1;
  const shouldUseTeamFilter = STATUS_FOLDERS.indexOf(selectedFolder) > -1;

  if (isMixStructureEnabled && !isSharedFolder && !isAnyAssetFolderSelected && MIX_ENDPOINT_DISABLED_FOLDERS.indexOf(selectedFolder) === -1) {
    return listFetchers.mixAssets({
      assetFilter, assetFilterType, teamFilter: shouldUseTeamFilter ? teamFilter : '', isAllAssetFilterSelected, currentPage, canAccessMyAgents
    });
  }
  if ((folderType === FOLDER_TYPES.TEAM || folderType === FOLDER_TYPES.TEAM_FOLDER) && currentPage !== LISTING_TYPES.TEAM_PAGE) {
    return listFetchers.teamAssetsByType({
      type: getAssetTypeFromListingType(currentPage), teamFilter, folderType, currentTeamID, isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
    });
  }
  if (teamFilter && shouldUseTeamFilter) {
    return listFetchers.teamAssetsByType({
      type: getAssetTypeFromListingType(currentPage), teamFilter, folderType, isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
    });
  }
  if (currentPage === LISTING_TYPES.TEAM_PAGE) {
    return listFetchers.teamAssets({
      isAllAssetFilterSelected, currentPage, assetFilter, canAccessMyAgents
    });
  }
  if (ASSIGNED_FORM_ENABLED_PAGES.indexOf(currentPage) > -1 && selectedFolder === DEFAULT_FOLDER_IDS.ASSIGNED) {
    return listFetchers.assignedForms;
  }
  if (ASSIGNED_FORM_ENABLED_PAGES.indexOf(currentPage) > -1 && selectedFolder === DEFAULT_FOLDER_IDS.DRAFTS) {
    return listFetchers.draftForms;
  }
  if (currentPage === LISTING_TYPES.ASSIGNED_FORM && selectedFolder === DEFAULT_FOLDER_IDS.SHARED_WITH_ME) {
    return listFetchers.sharedWithMe;
  }
  if (selectedFolder === DEFAULT_FOLDER_IDS.MY_SUBMISSIONS) {
    return listFetchers.mySubmissions;
  }
  if (selectedFolder === DEFAULT_FOLDER_IDS.FILLED_FORMS) {
    return listFetchers.filledForms;
  }
  if ((currentPage === LISTING_TYPES.FORM || isMixStructureEnabled) && isSharedFolder) {
    // We promise, we'll delete this block in a few days..
    if (isSharedWithMeV2) {
      return selectedFolder === DEFAULT_FOLDER_IDS.SHARED_WITH_ME ? listFetchers.sharedWithMeAllV2(isGuestUser) : listFetchers.sharedWithMeByOwnerV2(folderObject?.owner);
    }
    const hasLocalSource = Array.isArray(window?.__temp__sharedWithMeResources?.forms);
    if (hasLocalSource) {
      if (folderType === FOLDER_TYPES.USERNAME) {
        return querySharedWithMeResultsByUsername(selectedFolder);
      }
      return querySharedwithMeResults;
    }
    return listFetchers.sharedWithMeForms;
  }
  if (currentPage === LISTING_TYPES.FORM || (currentPage === LISTING_TYPES.MIX && assetFilterType === LISTING_TYPES.FORM)) {
    if (!selectedFolder || [ALL_ASSETS_ID[currentPage], ...STATUS_FOLDERS].indexOf(selectedFolder) > -1) {
      return listFetchers.forms;
    }

    return isAssetsFolderActive ? listFetchers.newFolderForms : listFetchers.folderForms(folderObject?.isShared);
  }

  const isAssetFolderSelected = isAssetsFolderActive && selectedFolder && [ALL_ASSETS_ID[currentPage], ...STATUS_FOLDERS].indexOf(selectedFolder) === -1;

  if (currentPage === LISTING_TYPES.TASK) {
    if (selectedFolder === DEFAULT_FOLDER_IDS.TASKS) return listFetchers.assignedApprovals;

    return isAssetFolderSelected ? listFetchers.folderApprovals : listFetchers.approvals;
  }
  if (currentPage === LISTING_TYPES.PORTAL) {
    return isAssetFolderSelected ? listFetchers.folderApps : listFetchers.apps;
  }
  if (currentPage === LISTING_TYPES.SHEET) {
    if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_SHEETS) return listFetchers.sharedTables;

    return isAssetFolderSelected ? listFetchers.folderTables : listFetchers.tables;
  }
  if (currentPage === LISTING_TYPES.REPORT) {
    if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_REPORTS) return listFetchers.sharedReports;

    return isAssetFolderSelected ? listFetchers.folderReports : listFetchers.reports;
  }
  if (currentPage === LISTING_TYPES.DOCUMENT) {
    return isAssetFolderSelected ? listFetchers.folderSignDocuments : listFetchers.signDocuments;
  }
  if (currentPage === LISTING_TYPES.TEAMS) {
    return listFetchers.teams;
  }
  if (currentPage === LISTING_TYPES.CONTACTS) {
    return listFetchers.contacts;
  }
  if (currentPage === LISTING_TYPES.PAGES) {
    return listFetchers.pages;
  }
  if (currentPage === LISTING_TYPES.BOARD) {
    if (selectedFolder === DEFAULT_FOLDER_IDS.SHARED_BOARDS) return listFetchers.sharedBoards;

    return isAssetFolderSelected ? listFetchers.folderBoards : listFetchers.boards;
  }
  if (currentPage === LISTING_TYPES.AGENT) {
    return isAssetFolderSelected ? listFetchers.folderAgents : listFetchers.agents({ assetFilter });
  }
};

function* buildListFetchQuery(parameters = {}) {
  const [currentPage, baseFilter, orderBy, filterID, isFilterSet] = yield all([
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getFilter),
    select(SELECTORS.getOrderBy),
    select(SELECTORS.getFilterID),
    select(SELECTORS.getIsFilterSet)
  ]);

  const baseFiltered = { ...baseFilter, ...(baseFilter.fullText && { fullText: baseFilter.fullText.replace('%', '\\%') }) };

  let filter = filterID ? { ...baseFiltered, form_id: [filterID] } : baseFiltered;

  // Fetch by source form id
  if (currentPage === LISTING_TYPES.BOARD) {
    filter = filterID ? { ...baseFiltered, source_form_id: filterID } : baseFiltered;
  }

  if (!isFilterSet && [...ASSETS_FOLDER_LISTINGS, LISTING_TYPES.FORM, LISTING_TYPES.MIX, LISTING_TYPES.TEAM_PAGE, LISTING_TYPES.AGENT].indexOf(currentPage) > -1) {
    yield delay(0);
  }

  return {
    filter,
    offset: 0,
    orderby: orderBy,
    limit: LIST_FETCH_LIMIT,
    ...parameters
  };
}

export function* filterMySubmisson() {
  const [filter, list, order, assetFilterType, isMixStructureEnabled] = yield all([
    select(SELECTORS.getFilter),
    select(SELECTORS.getBaseList),
    select(SELECTORS.getOrderBy),
    select(SELECTORS.getAssetFilterType),
    select(SELECTORS.getIsMixStructureEnabled)
  ]);
  const filteredContent1 = yield call(filterFrontEnd, filter.fullText || '', list, filterKeys);
  const sorted = yield call(sortList, filteredContent1, order);
  const fetchQuery = yield call(buildListFetchQuery);
  yield put(ACTION_CREATORS.fetchListSuccess(sorted, fetchQuery, assetFilterType, isMixStructureEnabled));
}

export function* listFetchFlow(parameters = {}) {
  try {
    const [
      assetFilter,
      assetFilterType,
      currentPage,
      selectedFolder,
      folderType,
      isInitial,
      teamFilter,
      currentTeamID,
      isAssetsFolderActive,
      isSharedWithMeV2,
      folderObject,
      isGuestUser,
      isMixStructureEnabled,
      isMixEnabledPage,
      isAllAssetFilterSelected,
      credentials
    ] = yield all([
      select(SELECTORS.getAssetFilter),
      select(SELECTORS.getAssetFilterType),
      select(SELECTORS.getCurrentPage),
      select(SELECTORS.getSelectedFolder),
      select(SELECTORS.getFolderType),
      select(SELECTORS.getIsListInitialState),
      select(SELECTORS.getTeamFilter),
      select(SELECTORS.getCurrentTeamID),
      select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT)),
      select(SELECTORS.isActiveFeature(FEATURE_LIST.SHARED_WITH_ME_V2)),
      select(SELECTORS.getSelectedFolderObject),
      select(SELECTORS.getIsGuestUser),
      select(SELECTORS.getIsMixStructureEnabled),
      select(SELECTORS.getIsMixEnabledPage),
      select(SELECTORS.getIsAllAssetFilterTypesSelected),
      select(SELECTORS.getUserCredentials)
    ]);
    const listFetcher = getListFetcher({
      currentPage,
      selectedFolder,
      folderType,
      teamFilter,
      currentTeamID,
      isAssetsFolderActive,
      isSharedWithMeV2,
      folderObject,
      isGuestUser,
      assetFilter,
      assetFilterType,
      isMixStructureEnabled,
      isAllAssetFilterSelected,
      canAccessMyAgents: canAccessMyAgentsPage(credentials)
    });
    if (!listFetcher) {
      console.log('list fetcher missing on saga');
      return;
    }
    const fetchQuery = yield call(buildListFetchQuery, parameters);
    const { data: { content } } = yield call(listFetcher, fetchQuery, selectedFolder, folderType, currentTeamID);
    const normalizedContent = normalizeFetchedList({
      list: content, currentPage, selectedFolder, folderType, isAssetsFolderActive, isSharedWithMeV2, isMixEnabledPage, assetFilterType
    });
    let filteredContent = normalizedContent;
    if (isInitial && selectedFolder === DEFAULT_FOLDER_IDS.MY_SUBMISSIONS) {
      yield put(ACTION_CREATORS.setFilteredList(filteredContent));
    }

    // This is causing redundant requests to fetchMoveJobs, SET_SELECTED_FOLDER in rootTeamFlow also makes calls this API
    const preventFetchingMoveJobs = yield select(SELECTORS.getIsPreventFetchingMoveJobs);
    if (!preventFetchingMoveJobs) {
      yield put(ACTION_CREATORS.listenItemMovingStatus());
    }

    const movingJobs = yield select(SELECTORS.getMovingJobs);
    filteredContent = filteredContent.reduce((acc, curr) => {
      const tempContent = curr;
      const assetKey = `${tempContent.id}_${getAssetType(assetFilterType, tempContent.assetType)}`;
      if (movingJobs[assetKey]) {
        tempContent.moveStatus = movingJobs[assetKey].status;
        if (!movingJobs[assetKey].isInitiator && movingJobs[assetKey].status === 'failed') {
          tempContent.moveStatus = '';
        }
      }
      acc.push(tempContent);
      return acc;
    }, []);

    yield put(ACTION_CREATORS.fetchListSuccess(filteredContent, fetchQuery, assetFilterType, isMixStructureEnabled));
  } catch (e) {
    // TODO sometimes assigned forms dont have form properties attached which makes
    // reduce fn fail
    console.error(e);
    yield put(ACTION_CREATORS.fetchListError());
    yield put(ACTION_CREATORS.handleTeamError(e?.response?.data?.message));
  }
}

function* debouncedResetAndListFetchFlow({ offset = 0 } = {}) {
  const isInitialState = yield select(SELECTORS.getIsListInitialState); // small perf update for reducers
  const checkFolder = yield select(SELECTORS.getSelectedFolder);
  const baseList = yield select(SELECTORS.getBaseList);

  if (!isInitialState) {
    yield delay(500);
  }

  // if offset > 0 means loading more record and no need to reset list
  if (checkFolder === DEFAULT_FOLDER_IDS.MY_SUBMISSIONS && baseList && offset === 0) {
    yield call(filterMySubmisson);
    return;
  }

  if (offset === 0 && !isInitialState) {
    yield put(ACTION_CREATORS.resetList());
  }

  yield call(listFetchFlow, { offset });
}

export function* spawnListBasedFlows() {
  const isMixStructureEnabled = yield select(SELECTORS.getIsMixStructureEnabled);

  const currentPage = yield select(SELECTORS.getCurrentPage);
  if (isMixStructureEnabled) {
    yield spawn(rootMixFlow);
    yield spawn(rootAssetFolderFlow);
    if ([...ASSETS_FOLDER_LISTINGS, LISTING_TYPES.FORM, LISTING_TYPES.MIX].indexOf(currentPage) > -1) {
      yield spawn(rootFolderLayoutFlow);
    }
    yield spawn(rootCommonListFlow);
    return;
  }
  switch (currentPage) {
    case LISTING_TYPES.TASK: {
      yield spawn(rootTaskFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.PORTAL: {
      yield spawn(rootPortalFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(portalReportFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.DOCUMENT: {
      yield spawn(rootDocumentFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.REPORT: {
      yield spawn(rootReportFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.SHEET: {
      yield spawn(rootSheetFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.FORM: {
      yield spawn(rootFormFlow);
      yield spawn(rootDraftFlow);
      yield spawn(formReportFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.AGENT: {
      yield spawn(rootAgentFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootFolderLayoutFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.TEAM_PAGE: {
      yield spawn(rootTeamFlow);
      yield spawn(rootAssetFolderFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.ASSIGNED_FORM: {
      yield spawn(rootFormFlow);
      yield spawn(rootDraftFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    case LISTING_TYPES.CONTACTS: {
      yield spawn(rootContactFlow);
      break;
    }
    case LISTING_TYPES.PAGES: {
      yield spawn(rootPageFlow);
      break;
    }
    case LISTING_TYPES.BOARD: {
      yield spawn(rootBoardFlow);
      yield spawn(rootCommonListFlow);
      break;
    }
    default:
      break;
  }
}

function* getActionResult(callback, id, assetType, ...args) {
  let item = yield select(SELECTORS.getItemByIDAndType(id, assetType));

  if (!item) {
    const deletedItems = yield select(SELECTORS.getDeletedItems);
    item = findItemByIDAndType({ list: deletedItems, assetID: id, assetType });
  }
  const isMixEnabledPage = yield select(SELECTORS.getIsMixEnabledPage);

  let action;
  if (isMixEnabledPage && callback.withCustomListType && item) {
    action = callback.withCustomListType(item?.assetType);
  } else {
    action = callback;
  }
  return yield call(action, id, ...args);
}

function* commonDeleteAction({ id, customPath = null, assetType }) {
  try {
    const result = yield call(getActionResult, apiAction.deleteItem, id, assetType, customPath);
    if (!result) {
      yield put(ACTION_CREATORS.deleteItemError(id));
      return;
    }
    const list = yield select(SELECTORS.getList);
    const deletedItems = list.filter(item => areTheSameItems({ item, assetID: id, assetType }));
    yield put(ACTION_CREATORS.setDeletedItems(deletedItems));
    yield put(ACTION_CREATORS.deleteItemSuccess(id, assetType));
  } catch (e) {
    yield put(ACTION_CREATORS.deleteItemError(id));
  }
}

function* commonOnRevokeUserAction({ id, shareID, assetType }) {
  try {
    const result = yield call(shareActions.onRevokeUser, shareID);
    if (!result) {
      yield put(ACTION_CREATORS.onRevokeUserError(id));
      return;
    }

    yield put(ACTION_CREATORS.onRevokeUserSuccess(id, shareID, assetType));
  } catch (e) {
    yield put(ACTION_CREATORS.onRevokeUserError(id));
  }
}

function* commonOnResendInvitationAction({ id, resolve }) {
  try {
    const result = yield call(shareActions.onResendInvitation, id);
    const message = result?.data?.message;
    if (!result) {
      yield put(ACTION_CREATORS.onResendInvitationError(id));
      return;
    }
    resolve({
      success: true,
      message
    });
    yield put(ACTION_CREATORS.onResendInvitationSuccess(id));
  } catch (e) {
    yield put(ACTION_CREATORS.onResendInvitationError(id));
  }
}

function* commonOnAssigneePermissionChangeAction({ id, shareValue, assetType }) {
  try {
    const { shareID, submissionPermission } = shareValue;
    const result = yield call(shareActions.onAssigneePermissionChange, shareID, submissionPermission);
    if (!result) {
      yield put(ACTION_CREATORS.onAssigneePermissionChangeError(id));
      return;
    }
    yield put(ACTION_CREATORS.onAssigneePermissionChangeSuccess(id, shareID, submissionPermission, assetType));
  } catch (e) {
    yield put(ACTION_CREATORS.onAssigneePermissionChangeError(id));
  }
}

function* commonOnRevokeMultipleUserAction({ id, shareIDs, assetType }) {
  try {
    const result = yield call(shareActions.onRevokeMultipleUser, shareIDs);
    if (!result) {
      yield put(ACTION_CREATORS.onRevokeMultipleUserError(id));
      return;
    }

    yield put(ACTION_CREATORS.onRevokeMultipleUserSuccess(id, shareIDs, assetType));
  } catch (e) {
    yield put(ACTION_CREATORS.onRevokeMultipleUserError(id));
  }
}

function* commonOnSendAction({ id, props, assetType }) {
  try {
    const result = yield call(getActionResult, shareActions.onSend, id, assetType, props);
    if (!result) {
      yield put(ACTION_CREATORS.onSendError(id));
      return;
    }
    const shareResult = yield call(getActionResult, shareActions.getSheetShareInfo, id, assetType);
    yield put(ACTION_CREATORS.onSendSuccess(id, assetType, shareResult));
  } catch (e) {
    yield put(ACTION_CREATORS.onSendError(id));
  }
}

/**
 * @param action
 * @param action.shareID The ID assigned to the share object. This only exists if the item has had a shareLink generated.
 * @param action.sharedID The resource ID of the list item
 * @param action.shareData The properties to be updated on the share object of a list item.
 * @returns {Generator<*, void, *>}
 * @desc Updates share property values on a list item. If the list item has never been shared before a share link needs
 * to first be generated for the item.
 */
function* commonOnPropertyChangeAction(action) {
  const {
    sharedID: resourceID, shareData: permissions, assetType
  } = action;
  let { shareID: resourceShareID } = action;

  try {
    const { onPropertyChange } = shareActions;
    const item = yield select(SELECTORS.getItemByIDAndType(resourceID, assetType));

    if (assetType === ASSET_TYPES.SHEET) {
      // The API doesn't care about `assigneeSubmissionPermission`, update `role` in the share object and clean up permissions
      if (typeof permissions.assigneeSubmissionPermission !== 'undefined') {
        permissions.role = permissions.assigneeSubmissionPermission;
        delete permissions.assigneeSubmissionPermission;
      }

      // If we change sheet to public, update the role to the default shareLink role
      if (permissions.assigneeProtected === 'No') {
        permissions.role = 'readOnly';
      }

      // For sheet items that are having its properties updated for the first time, we should generate a share link first.
      if (!item?.share?.shareLink) {
        yield put(ACTION_CREATORS.generateShareLinkRequest({ id: resourceID, firstTime: true, assetType }));

        const { success, fail } = yield race({
          success: take(ACTION_TYPES.INIT_SHARE),
          fail: take(ACTION_TYPES.GENERATE_SHARE_LINK.ERROR)
        });

        // List Item has no share properties
        if (fail || !success?.shareData?.id) {
          return console.error(fail || 'Couldn\'t create a resource.');
        }

        // Update the shareID, so we can run onPropertyChange and update its permissions
        resourceShareID = success?.shareData.id;
      }
    }

    // Update permissions for this List Item
    const result = yield call(getActionResult, onPropertyChange(assetType === 'sheet' ? 'sheets' : assetType), resourceShareID || item?.share?.id || resourceID, assetType, { permissions });

    if (!result) {
      console.error('Couldn\'t create a resource.');
      return yield put(ACTION_CREATORS.onPropertyChangeError(resourceShareID));
    }
    yield put(ACTION_CREATORS.onPropertyChangeSuccess(resourceID, permissions, assetType));
  } catch (e) {
    console.error(e);
    yield put(ACTION_CREATORS.onPropertyChangeError(resourceShareID));
  }
}

/**
 * @param action
 * @param action.id The resource ID of the list item
 * @returns {Generator<*, void, *>}
 * @desc Makes a request to generate a shareLink for a list item, then fetches the new share properties for the list item
 * and populates the store
 */
function* commonGenerateShareLinkAction({ id: resourceID, firstTime, assetType }) {
  try {
    const currentShare = yield select(SELECTORS.getGeneratedShareByAssetType(resourceID, assetType));
    // Create a copy of the current share permissions properties, updating any necessary fields needed for generating a share link.
    const permissions = Object.assign(currentShare.permissions || {}, {
      ...currentShare.permissions,
      ...({
        assignLinkExpireDate: '',
        assignLinkExpireTimezone: ''
      }),
      ...(firstTime ? {
        assigneeProtected: currentShare.assigneeProtected === 'public' ? 'No' : 'Yes',
        ssoProtected: 'No',
        organizationAccess: '',
        role: 'readOnly'
      } : {})
    });

    // Generate new share link for list item
    const newShareLink = yield call(getActionResult, shareActions.generateShareLink, resourceID, assetType, {
      permissions,
      role: currentShare.role
    });

    if (!newShareLink) {
      yield put(ACTION_CREATORS.generateShareLinkError(resourceID));
      return;
    }

    yield put(ACTION_CREATORS.generateShareLinkSuccess(resourceID, newShareLink, assetType));

    // Fetch and update the store with new share properties
    try {
      const newShareProps = yield call(getActionResult, shareActions.getShareProps, resourceID, assetType);

      yield put(ACTION_CREATORS.initShare(resourceID, newShareProps[resourceID], assetType));
    } catch (e) {
      console.error(e);
      yield put(ACTION_CREATORS.initShareError(resourceID));
    }
  } catch (e) {
    console.error(e);
    yield put(ACTION_CREATORS.generateShareLinkError(resourceID));
  }
}

function* commonUpdateListAction({
  id, payload, resolveReportUpdate, assetType
}) {
  try {
    const result = yield call(getActionResult, apiAction.updateItem, id, assetType, payload);
    if (!result || (result?.data?.responseCode && result.data.responseCode !== 200)) {
      yield put(ACTION_CREATORS.updateItemError(id));
      yield put(ACTION_CREATORS.handleTeamError(result?.data?.message));
      return;
    }
    const status = payload?.status;
    const assetFilterType = yield select(SELECTORS.getAssetFilterType);
    switch (status) {
      case 'ENABLED':
      case 'DISABLED':
        if (assetFilterType === LISTING_TYPES.FORM || (assetFilterType === LISTING_TYPES.MIX && assetType === LISTING_TYPES_REVERT[LISTING_TYPES.FORM])) {
          const newPayload = { ...payload, isDisabled: status === 'DISABLED' };
          yield put(ACTION_CREATORS.updateItemSuccess({
            id, payload: newPayload, resolveReportUpdate, result, assetType
          }));
        } else {
          yield call(listFetchFlow);
        }
        break;
      case 'TRASHED':
      case 'ARCHIVED':
        const deletedItem = yield select(SELECTORS.getItemByIDAndType(id, assetType));
        yield put(ACTION_CREATORS.deleteItemSuccess(id, assetType));
        yield put(ACTION_CREATORS.setDeletedItems([deletedItem]));
        break;
      default:
        yield put(ACTION_CREATORS.updateItemSuccess({
          id, payload, resolveReportUpdate, result, assetType
        }));
        break;
    }
  } catch (e) {
    yield put(ACTION_CREATORS.updateItemError(id));
  }
}

function* commonRenameListAction({ id, payload, assetType }) {
  try {
    const result = yield call(getActionResult, apiAction.updateItem, id, assetType, payload);
    if (!result || (result?.data?.responseCode && result.data.responseCode !== 200)) {
      yield call(showError);
      yield put(ACTION_CREATORS.renameItemError(id));
      yield put(ACTION_CREATORS.handleTeamError(result?.data?.message));
      return;
    }
    yield call(renameToastCall);
    yield put(ACTION_CREATORS.renameItemSuccess(id, payload, result, assetType));
  } catch (e) {
    yield call(showError);
    yield put(ACTION_CREATORS.renameItemError(id));
  }
}

function* commonBulkUpdateListAction({
  prop, value, withUndo, selectedList
}) {
  try {
    const selectedItems = yield select(SELECTORS.getSelectedItems);
    const selectedStatus = yield select(SELECTORS.getSelectionStatus);
    const selectedItemsCount = Object.keys(selectedItems).length;
    const defaultSelectedList = Object.keys((value === 'ENABLED' && selectedItemsCount === 0) ? selectedStatus : selectedItems).map(selectedItemKey => {
      const separatedKey = selectedItemKey.split('_');
      const selectedItemID = separatedKey.length > 0 ? separatedKey[0] : selectedItemKey;
      const selectedItemAssetType = separatedKey.length > 1 ? separatedKey[1] : '';
      return {
        id: selectedItemID,
        assetType: selectedItemAssetType
      };
    });
    const selectedItemList = selectedList?.length > 0 ? selectedList : defaultSelectedList;
    const isMixEnabledPage = yield select(SELECTORS.getIsMixEnabledPage);
    const teamID = yield select(SELECTORS.getCurrentTeamID);
    let conditions = JSON.stringify({ includes: selectedItemList.map(selectedItem => selectedItem.id) });

    if (isMixEnabledPage) {
      const list = yield select(SELECTORS.getList);
      const deletedItems = yield select(SELECTORS.getDeletedItems);
      const selectedItemsWithInfo = selectedItemList.map(({ id, assetType }) => {
        return findItemByIDAndType({ list: [...list, ...deletedItems], assetID: id, assetType });
      }).filter(e => e);
      const valueTypesShouldDeleted = ['workflow'];
      const conditionManipulation = (assetType, bulkValue) => {
        if (prop !== 'status' || !valueTypesShouldDeleted.includes(assetType)) {
          return {};
        }
        // Not all types are TRASHED!
        if (bulkValue === 'TRASHED') {
          return { value: 'DELETED' };
        }
        return {};
      };
      conditions = JSON.stringify(selectedItemsWithInfo.map(({ id, assetType }) => ({ id, type: assetType, ...conditionManipulation(assetType, value) })));
    }
    const result = yield call(apiAction.bulkUpdateList, prop, value, conditions, teamID);
    if (!result) {
      yield put(ACTION_CREATORS.bulkUpdateListError());
      return;
    }

    const [list, selectedFolder] = yield all([
      select(SELECTORS.getList),
      select(SELECTORS.getSelectedFolder)
    ]);
    switch (true) {
      case prop === 'status': {
        switch (value) {
          case 'DELETED':
          case 'TRASHED': {
            const { selectedItems: deletedItems, others: newList } = createListsFromSelectedItems({ list, selectedItemList });
            yield put(ACTION_CREATORS.setList(newList));
            yield put(ACTION_CREATORS.setDeletedItems(deletedItems));
            break;
          }
          case 'ARCHIVED': {
            if (selectedFolder === DEFAULT_FOLDER_IDS.ARCHIVE && withUndo) {
              const deletedItems = yield select(SELECTORS.getDeletedItems) || [];
              yield put(ACTION_CREATORS.setList([...deletedItems, ...list]));
              yield put(ACTION_CREATORS.setDeletedItems([]));
            } else {
              const { selectedItems: deletedItems, others: newList } = createListsFromSelectedItems({ list, selectedItemList });
              yield put(ACTION_CREATORS.setList(newList));
              yield put(ACTION_CREATORS.setDeletedItems(deletedItems));
            }
            break;
          }
          case 'ENABLED':
          case 'DISABLED': {
            const selectedAssetType = yield select(SELECTORS.getSelectedItemsListingType);
            if ([LISTING_TYPES.SHEET, LISTING_TYPES.REPORT, LISTING_TYPES.PAGES].indexOf(selectedAssetType) > -1 || value === 'ENABLED') {
              yield delay(2000);
              yield call(listFetchFlow);
              break;
            }

            if (selectedAssetType === LISTING_TYPES.FORM && ([DEFAULT_FOLDER_IDS.TRASH, DEFAULT_FOLDER_IDS.ARCHIVE].indexOf(selectedFolder) > -1)) {
              const newList = list.filter(item => !findItemByIDAndType({ list: selectedItemList, assetID: item.id, assetType: item.assetType }));
              yield put(ACTION_CREATORS.setList(newList));
              break;
            }

            const isDisabled = value === 'DISABLED';
            const newList = list.map(item => {
              if (!findItemByIDAndType({ list: selectedItemList, assetID: item.id, assetType: item.assetType })) {
                return item;
              }

              return { ...item, isDisabled };
            });
            yield put(ACTION_CREATORS.setList(newList));
            break;
          }
          default:
            break;
        }
        break;
      }
      case prop === 'favorite': {
        const newList = list.map(item => {
          if (!findItemByIDAndType({ list: selectedItemList, assetID: item.id, assetType: item.assetType })) {
            return item;
          }

          return { ...item, favorite: value };
        });
        yield put(ACTION_CREATORS.setList(newList));
        break;
      }
      default:
        break;
    }
  } catch (e) {
    yield put(ACTION_CREATORS.bulkUpdateListError());
  }
}

function* commonBulkDeleteAction({ excludedItems }) {
  try {
    const selectedItemsWithInfo = yield select(SELECTORS.getSelectedItemsWithInfo);
    // excluding sign items for team listings as they can not be purged
    const deletedItemList = selectedItemsWithInfo.filter(selectedItem => !excludedItems || !excludedItems.includes(selectedItem.id));
    const isMixEnabledPage = yield select(SELECTORS.getIsMixEnabledPage);
    const teamID = yield select(SELECTORS.getCurrentTeamID);
    let conditions = JSON.stringify({ includes: deletedItemList.map(deletedItem => deletedItem.id) });

    if (isMixEnabledPage) {
      conditions = JSON.stringify(deletedItemList.map(({ id, assetType }) => ({ id, type: assetType })));
    }
    const result = yield call(apiAction.bulkDeleteList, conditions, teamID);
    if (!result) {
      yield put(ACTION_CREATORS.bulkDeleteError());
      return;
    }
    yield put(ACTION_CREATORS.bulkDeleteSuccess(deletedItemList));
  } catch (e) {
    yield put(ACTION_CREATORS.bulkDeleteError());
  }
}

function* handleFolderActionsBulk({ actionType, folderIDs, items }) {
  // Currently we handle it one by one but in future if a bulk item update endpoint is implemented
  // This saga should be changed to use that.
  const folders = removeListDuplications(folderIDs);
  const itemList = removeListDuplicationsWithType(items);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  const selectedItems = yield select(SELECTORS.getItemsByList(itemList));

  if (folders.includes(currentTeamID)) {
    for (let i = 0; i < selectedItems.length; i++) {
      const item = selectedItems[i];
      const itemFolders = item.folders?.split?.(',')?.filter?.(f => f) || [];
      for (let j = 0; j < itemFolders.length; j++) {
        const folderID = itemFolders[j];
        yield put(ACTION_CREATORS.folderItemAction({
          actionType, itemID: item.id, folderID, isBulk: true, assetType: item.assetType
        }));
      }
    }
  } else {
    for (let i = 0; i < folders.length; i++) {
      const folderID = folders[i];
      for (let j = 0; j < itemList.length; j++) {
        const item = itemList[j];
        yield put(ACTION_CREATORS.folderItemAction({
          actionType, itemID: item.id, folderID, isBulk: true, assetType: item.assetType
        }));
      }
    }
  }
}

function* handleFolderActions({
  actionType, folderID, itemID, isBulk, assetType
}) {
  let apiMethod;
  const teamID = yield select(SELECTORS.getCurrentTeamID);
  const item = yield select(SELECTORS.getItemByIDAndType(itemID, assetType));
  const filter = yield select(SELECTORS.getFilter);
  const teamPermissions = yield select(SELECTORS.getTeamPermissions);
  const isCreateTeamFolderEnabled = teamPermissions[TEAM_PERMISSIONS.CREATE_FOLDER];
  const type = assetType;
  if (!isCreateTeamFolderEnabled && teamID) {
    return;
  }
  switch (actionType) {
    case FOLDER_ITEM_ACTION_TYPES.ADD:
      // Prevent duplicate
      if (item?.folders?.includes(folderID)) {
        return;
      }
      apiMethod = teamID ? API.addToTeamFolder : API.addFormToFolder;
      break;
    case FOLDER_ITEM_ACTION_TYPES.REMOVE:
      apiMethod = teamID ? API.removeItemFromTeamFolder : API.removeFormFromFolder;
      break;
    default:
      return;
  }
  yield put({
    type: ACTION_TYPES.FOLDER_ITEM_ACTION_PROCESS, folderID, itemID, actionType
  });
  try {
    yield call(apiMethod, {
      folderID,
      formID: itemID,
      teamID,
      type
    });
    const selectedFolderID = yield select(SELECTORS.getSelectedFolder);
    if (actionType === FOLDER_ITEM_ACTION_TYPES.REMOVE && folderID === selectedFolderID) {
      yield put(ACTION_CREATORS.setFilter(filter));
    }
  } catch (err) {
    if (isBulk) {
      yield put(ACTION_CREATORS.setFilter(filter));
    } else {
      yield put({
        type: ACTION_TYPES.FOLDER_ITEM_ACTION_PROCESS, folderID, itemID, actionType: actionType === FOLDER_ITEM_ACTION_TYPES.ADD ? FOLDER_ITEM_ACTION_TYPES.REMOVE : FOLDER_ITEM_ACTION_TYPES.ADD
      });
    }
    const errorToast = new ListActionToast('item')?.errorToast;
    errorToast(t('Something went wrong.'));
  }
}

export function* rootListFlow() {
  yield takeLatest([
    ACTION_TYPES.SET_FILTER,
    ACTION_TYPES.SET_ORDERBY,
    ACTION_TYPES.SET_FULLTEXT,
    ACTION_TYPES.SET_FILTER_ID,
    ACTION_TYPES.FETCH_LIST.REQUEST,
    ACTION_TYPES.SET_TEAM_FILTER,
    ACTION_TYPES.SET_ASSET_FILTER
  ], debouncedResetAndListFetchFlow);
  // yield takeLatest(ACTION_TYPES.SET_SELECTED_FOLDER, onSelectFolder);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_ITEM.REQUEST, commonDeleteAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.UPDATE_ITEM.REQUEST, commonUpdateListAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.RENAME_ITEM.REQUEST, commonRenameListAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.GENERATE_SHARE_LINK.REQUEST, commonGenerateShareLinkAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_REVOKE_USER.REQUEST, commonOnRevokeUserAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_RESEND_INVITATION.REQUEST, commonOnResendInvitationAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_ASSIGNEE_PERMISSION.REQUEST, commonOnAssigneePermissionChangeAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_REVOKE_MULTIPLE_USER.REQUEST, commonOnRevokeMultipleUserAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_SEND.REQUEST, commonOnSendAction);
  yield spawn(syncRequestSaga, ACTION_TYPES.ON_PROPERTY_CHANGE.REQUEST, commonOnPropertyChangeAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.BULK_UPDATE_LIST.REQUEST, commonBulkUpdateListAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.BULK_DELETE.REQUEST, commonBulkDeleteAction);
  yield spawn(registerUniqueAction, ACTION_TYPES.FOLDER_ITEM_ACTION.REQUEST, handleFolderActions);
  yield spawn(registerUniqueAction, ACTION_TYPES.FOLDER_ITEM_ACTION_BULK.REQUEST, handleFolderActionsBulk);

  yield spawn(spawnListBasedFlows);
}
