import { dismissUnreadSupportAnswers } from '../utils';

const onConfirm = ({ actionTargetName }) => {
  global.location.href = '/answers/asked';

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName }) => {
  dismissUnreadSupportAnswers();

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

// unread support answers
const checkUnreadSupportAnswers = notifications => {
  let notification = notifications.find(notif => notif.name === 'unreadSupportAnswers');
  const otherNotifications = notifications.filter(notif => notif.name !== 'unreadSupportAnswers');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkUnreadSupportAnswers;
