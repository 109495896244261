import { capitalizeFirstLetter } from '@jotforminc/utils';

const defaultAvailablePlans = ['BRONZE', 'SILVER', 'GOLD'];

export const getAvailablePlansForUser = userType => {
  switch (userType) {
    case 'BRONZE':
      return ['SILVER', 'GOLD'];
    case 'SILVER':
      return ['GOLD'];
    case 'FREE':
    default:
      return defaultAvailablePlans;
  }
};

export const getPlanClassName = accountTypeName => `is${capitalizeFirstLetter(accountTypeName === 'FREE' ? 'STARTER' : accountTypeName.toLowerCase())}`;

const developmentPlans = [
  { name: 'BRONZE', campaign_status: 'ON' },
  { name: 'SILVER', campaign_status: 'ON' },
  { name: 'GOLD', campaign_status: 'ON' }
];

const developmentEnvPrices = {
  prices: {
    monthly: 20,
    yearly: 200,
    biyearly: 348
  },
  plimusIDs: {
    monthly: 3276766,
    yearly: 3276768,
    biyearly: 3276770,
    'yearly-single': 1850066,
    'biyearly-single': 1851946
  },
  fastSpringURLs: {
    monthly: 'http://sites.fastspring.com/interlogy/instant/jotformbronzemonthly',
    yearly: 'http://sites.fastspring.com/interlogy/instant/jotformbronzeyearly',
    biyearly: 'http://sites.fastspring.com/interlogy/instant/jotformbronze2year'
  },
  fullPrices: {
    monthly: 40,
    yearly: 348,
    biyearly: 348
  },
  limits: {
    submissions: 100,
    overSubmissions: 200,
    sslSubmissions: 100,
    payments: 10,
    uploads: 104857600,
    tickets: 0,
    subusers: 1,
    'api-daily-limit': 1000,
    views: 1000,
    formCount: 5,
    hipaaCompliance: false,
    emails: {
      reminderEmailBlocks: 1
    }
  }
};

export const filterPlansForUserType = (plans, userAccountType, campaignName = '') => {
  if (window.JOTFORM_ENV === 'DEVELOPMENT') {
    return developmentPlans.map(plan => ({ ...plan, ...developmentEnvPrices }));
  }
  return plans?.filter(plan => {
    const availablePlans = getAvailablePlansForUser(userAccountType);
    return (
      availablePlans.includes(plan?.name)
      && (['NONPROFIT', 'EDUCATION', 'ONEDOLLARSILVER'].includes(campaignName) || plan?.campaign_status?.toLowerCase() === 'on')
    );
  });
};
