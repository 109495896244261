import React, {
  useState, useEffect, useMemo, forwardRef
} from 'react';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionGroup } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import { IconProductReportBuilderFilled, IconAngleDown } from '@jotforminc/svg-icons';
import { handleCustomNavigation } from '@jotforminc/utils';
import classNames from 'classnames';

import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';
import PopoverText from '../../PopoverText';

import { getDigest, getFormReports, getReport } from '../../../api';
import translate from '../../../utils/translate';
import { ListItemSharedDefaultProps } from '../../ListItem/propTypes';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import { constructEditReportData } from '../../../utils';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';
import { SELECTORS } from '../../../store/selectors';

const ButtonWithRref = () => forwardRef((props, ref) => (
  <Button
    ref={ref}
    data-testid="report-action"
    {...props}
    colorStyle="secondary"
    variant="outline"
    startIcon={IconProductReportBuilderFilled}
    // eslint-disable-next-line react/prop-types
    className={classNames('forReportsButton', props.className)}
  >
    {translate('Reports')}
  </Button>
));

const OptionTextRenderer = ({ text, className = '', ...rest }) => <div className={classNames('isMenuButton', className)} {...rest}>{translate(text)}</div>;
OptionTextRenderer.propTypes = { text: string.isRequired, className: string };

const ActionDisabledReportTypes = ['pdf-editor', 'reports', 'digest'];

const OptionTextRendererWithActions = ({
  id, onEdit, onDelete, type, ...rest
}) => (
  <>
    <OptionTextRenderer {...rest} />
    {ActionDisabledReportTypes.includes(type) ? null : (
      <>
        <div
          className="forEdit"
          title="Edit"
          onClick={e => onEdit(id, e)}
          onKeyUp={() => onEdit(rest)}
        />
        <div
          className="forDelete"
          title="Delete"
          onClick={e => onDelete(id, e)}
          onKeyUp={() => onDelete(rest)}
        />
      </>
    )}
  </>
);
OptionTextRendererWithActions.propTypes = { onEdit: func.isRequired, onDelete: func.isRequired, ...OptionTextRenderer.propTypes };

const POPOVER_PROPS = {
  ...ListItemSharedDefaultProps.PopoverProps,
  popoverOptions: {
    ...ListItemSharedDefaultProps.PopoverProps.popoverOptions,
    ...ListItemSharedDefaultProps.PopoverModifierProps
  },
  className: 'listings-popover-modal isMoreMenu isToolbarMoreMenu'
};

const ReportAction = ({ selectedID }) => {
  const [reportList, setReportList] = useState([]);
  const dispatch = useDispatch();
  const userCredentials = useSelector(SELECTORS.getUserCredentials);
  const handleOpenCreateReportWizard = () => { dispatch(ACTION_CREATORS.createReportWizard({ selectedID, setReportList })); };
  const handleUpdateReportWizard = (reportType, formID, reportID, reportData) => { dispatch(ACTION_CREATORS.updateReportWizard(reportType, formID, reportID, reportData, setReportList)); };
  const fetchList = () => { dispatch(ACTION_CREATORS.fetchListRequest()); };
  const deleteReport = reportID => { dispatch(ACTION_CREATORS.formDeleteReport(reportID, setReportList, fetchList)); };

  const handleEditReport = async (reportID, e) => {
    e.stopPropagation();
    const {
      title, settings, isProtected, password, fields, list_type: type
    } = await getReport(reportID);
    const reportData = constructEditReportData({
      title,
      settings,
      isProtected,
      password,
      fields,
      type
    });
    handleUpdateReportWizard(type, selectedID, reportID, reportData);
  };

  const handleEditDigest = async id => {
    const digest = await getDigest(id);
    handleUpdateReportWizard('digest', selectedID, id, { digest, user: userCredentials });
  };

  const handleDeleteReport = (id, e) => {
    e.stopPropagation();
    deleteReport(id);
  };

  const options = useMemo(() => {
    const getReportType = type => {
      return type === 'cal' ? 'calendar' : type;
    };

    if (reportList.length === 0) {
      return [{
        disabled: true,
        hideOnDisable: false,
        Renderer: PopoverText,
        text: <OptionTextRenderer className="noItems" text="You have no reports yet." />
      }];
    }

    return [
      ...reportList.map(({ id, title, type }) => ({
        Renderer: PopoverOption,
        value: `/${getReportType(type)}/${id}`,
        text: (
          <OptionTextRendererWithActions
            text={title}
            type={type}
            onDelete={handleDeleteReport}
            onEdit={handleEditReport}
            id={id}
          />),
        icon: getReportType(type),
        iconType: 'reports'
      }))
    ];
  }, [reportList]);

  const handleReportList = async () => {
    if (selectedID) {
      const tmpAppList = await getFormReports(selectedID);
      setReportList(tmpAppList);
    }
  };

  useEffect(() => {
    handleReportList();
  }, [selectedID]);

  const handleSelectionChange = value => {
    // Force Digest reports to Edit flow
    if (value.startsWith('/digest/')) {
      handleEditDigest(value.split('/').pop());
      return;
    }

    if (value !== 'createNewReport') {
      handleCustomNavigation(value, '_blank');
      return;
    }
    handleOpenCreateReportWizard();
    // discoverabilityTracking
    WatchmanRecorder.trackEventOnceForCustomProject('click', 'toolbar-addNewReport', 'discoverabilityTracking');
  };

  const handleReportButtonClick = () => {
    WatchmanRecorder.trackEvent('click', 'reportsButton', 'myformsFormToolbar');
  };
  // eslint-disable-next-line react/prop-types
  const ContainerRenderer = forwardRef(({ children, ...rest }, containerRef) => (
    <>
      <ul
        aria-label={translate('reports list')} role="listbox" className="listings-popover-modal-item-list"
        ref={containerRef} {...rest}
      >
        {children}
      </ul>
      <ul className="listings-popover-modal-item-actions">
        <li className="listings-popover-more-action">
          <Button
            className="isCreateButton w-full"
            onClick={() => handleSelectionChange('createNewReport')}
          >
            {translate('Add New Report')}
          </Button>
        </li>
      </ul>
    </>
  ));
  const MenuRenderer = useMemo(() => menuProps => (<SelectionGroup {...menuProps} ContainerRenderer={ContainerRenderer} OptionContainerRendererProps={{ tabIndex: 0 }} />), []);
  return (
    <PopoverMenu
      withoutChildren
      options={options}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonWithRref()}
      onSelectionChange={handleSelectionChange}
      MenuRenderer={MenuRenderer}
      onButtonClick={handleReportButtonClick}
      autoFocusToFirstChild={true}
    >
      {isVisible => (
        <Button
          className={classNames('forReportsButton', { isMenuVisible: isVisible })}
          colorStyle="secondary"
          variant="outline"
          startIcon={IconProductReportBuilderFilled}
          endIcon={IconAngleDown}
        >
          {translate('Reports')}
        </Button>
      )}
    </PopoverMenu>
  );
};

ReportAction.propTypes = {
  selectedID: string.isRequired
};

export default ReportAction;
