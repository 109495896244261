import React, { useState, useEffect, ReactElement } from 'react';
import isEqual from 'lodash/isEqual';
import { t } from '@jotforminc/translation';

type ClearFilterButtonProps = {
  filters?: {[key: string]: any},
  search?: string,
  setFilters: (filter: {[key: string]: any}) => void,
  setSearch: (change: string) => void,
  initialFilter: {[key: string]: any}
};

export const ClearFilterButton = ({
  filters,
  setFilters,
  search,
  setSearch,
  initialFilter
}: ClearFilterButtonProps): ReactElement => {
  const [clearFilterActive, setClearFilterActive] = useState(false);

  const clearFilters = () => {
    setSearch('');
    setFilters(initialFilter);
  };

  useEffect(() => {
    const newFilters = filters ? Object.keys(filters).reduce((prev: {[key: string]: any}, curr: string): {[key: string]: any} => {
      if (!filters[curr]) return prev;

      return { ...prev, [curr]: filters[curr] };
    }, {}) : {};
    const isInitialFilters = isEqual(initialFilter, newFilters) && search === '';
    setClearFilterActive(!isInitialFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, search]);

  return (
    <>
      {!!clearFilterActive && (
        <button
          type="button"
          onClick={clearFilters}
          className="forClearFilter"
        >
          {t('Clear Filter')}
        </button>
      )}
    </>
  );
};

ClearFilterButton.defaultProps = {
  filters: {},
  search: ''
};
