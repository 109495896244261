import React, { FunctionComponent } from 'react';

import {
  IconProductFormBuilderColorBorder as LegacyFormIcon,
  IconProductFormCardColorBorder as CardFormIcon,
  IconProductTablesColorBorder as SheetIcon,
  IconProductReportBuilderColorBorder as ReportIcon,
  IconProductApprovalsColorBorder as WorkflowIcon,
  IconProductAppsColorBorder as PortalIcon,
  IconProductSignColorBorder as SignIcon,
  IconFormFilled as NavyLegacyFormIcon,
  IconProductFormCardFilled as NavyCardFormIcon,
  IconProductTablesFilled as NavySheetIcon,
  IconProductReportBuilderFilled as NavyReportIcon,
  IconProductApprovalsFilled as NavyWorkflowIcon,
  IconProductAppsFilled as NavyPortalIcon,
  IconProductSignFilled as NavySignIcon,
  IconProductFormBuilderMono as LegacyFormIconMono,
  IconProductFormCardMono as CardFormIconMono,
  IconProductTablesMono as SheetIconMono,
  IconProductReportBuilderMono as ReportIconMono,
  IconProductApprovalsMono as WorkflowIconMono,
  IconProductAppsMono as PortalIconMono,
  IconProductSignMono as SignIconMono
} from '@jotforminc/svg-icons';

type Props = {
  type: string,
  isNavy: boolean,
  resourceType?: string,
  isMono?: boolean
};

const SVGComponents: { [key: string]: any } = {
  LegacyFormIcon,
  CardFormIcon,
  SheetIcon,
  ReportIcon,
  WorkflowIcon,
  PortalIcon,
  SignIcon,
  NavyLegacyFormIcon,
  NavyCardFormIcon,
  NavySheetIcon,
  NavyReportIcon,
  NavyWorkflowIcon,
  NavyPortalIcon,
  NavySignIcon,
  LegacyFormIconMono,
  CardFormIconMono,
  SheetIconMono,
  ReportIconMono,
  WorkflowIconMono,
  PortalIconMono,
  SignIconMono
};

const AssetIconRenderer: FunctionComponent<Props> = ({
  type, isNavy, resourceType, isMono
}) => {
  const formResourceType = resourceType && type === 'form' ? `${resourceType.charAt(0)?.toUpperCase() + resourceType.slice(1)?.toLowerCase()}` : '';
  const componentName = type ? `${isNavy ? 'Navy' : ''}${formResourceType}${type.charAt(0).toUpperCase() + type.slice(1)}Icon${isMono ? 'Mono' : ''}` : '';
  const SVGComponent = SVGComponents[componentName];

  if (!SVGComponent) return isNavy ? <NavyLegacyFormIcon /> : <LegacyFormIcon />;

  return <SVGComponent name={`${type}${isNavy ? '-navy' : ''}`} />;
};

AssetIconRenderer.defaultProps = {
  resourceType: '',
  isMono: false
};

export default AssetIconRenderer;
