/* eslint-disable max-len */
import Styled from 'styled-components';
import { breakpoints, durations } from '../../../constants';

export const ScNotificationOutlineNv = Styled.li`
  margin: 0;
  border-bottom: solid 1px #E3E5F5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px;` : 'inherit;')}
  padding: 20px;
  overflow: hidden;
  transition: .2s;
  transition-delay: ${durations.dismiss.delay}ms;
  background: #FFFFFF;
  
  ${({ notification }) => {
    if (notification.isHidden) {
      return `
        height: 0;
        min-height: 0;
        padding: 0;
        border-bottom: none;

        @media screen and (max-width:${breakpoints.tablet}){
          padding: 0;
        }

        &:after{
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          background: linear-gradient(-45deg, #FFFFFF, #F3F3FE, #FFFFFF, #F3F3FE);
          background-size: 400% 400%;
          animation: gradientNotification ${durations.dismiss.gradient}ms ease infinite;
        }
        
          @keyframes gradientNotification {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
      `;
    }
  }}
`;
