import React, { useEffect, useState } from 'react';
import {
  shape, func, string, elementType
} from 'prop-types';
import { hipaaSteps, hipaaFirstStep } from '../constants';
import { ScHipaaNotification } from './sc/scHipaaNotification';
import { ScHipaaNotificationOutline } from './sc/scHipaaNotificationOutline';
import Notification from './Notification';
import HipaaDismissButton from './HipaaDismissButton';

const HipaaNotification = ({
  user,
  notification,
  NotificationRenderer,
  onConfirm,
  onDismiss
}) => {
  const [currentStep, setCurrentStep] = useState(hipaaSteps.firstStep);
  const [firstStepAnswer, setFirstStepAnswer] = useState(null);

  // jump to the second step if user answered the first step
  useEffect(() => {
    const prevSetting = user.hipaaComplienceAccountBoxOffer;
    const isUserPassedFirstStepBefore = prevSetting === '0' || prevSetting === '-1';
    if (!isUserPassedFirstStepBefore) return;

    setCurrentStep(hipaaSteps.secondStep);

    if (prevSetting === '0') {
      setFirstStepAnswer(hipaaFirstStep.yes);
    } else if (prevSetting === '-1') {
      setFirstStepAnswer(hipaaFirstStep.no);
    }
  }, [user]);

  const handleHipaaStepChange = ({ firstStepAnswer: answer }) => {
    setCurrentStep(hipaaSteps.secondStep);
    setFirstStepAnswer(answer);
  };

  const getStepNotification = ({ firstStep, secondStep, ...baseNotification }, step) => {
    if (step === hipaaSteps.firstStep) {
      return { ...baseNotification, ...firstStep };
    }

    if (step === hipaaSteps.secondStep) {
      return { ...baseNotification, ...secondStep };
    }
  };

  return (
    <ScHipaaNotificationOutline>
      <ScHipaaNotification hipaaStep={currentStep}>
        {/* step1 */}
        <Notification
          hipaaStep={currentStep}
          key={hipaaSteps.firstStep}
          hipaaFirstStepAnswer={firstStepAnswer}
          DismissButtonRenderer={HipaaDismissButton}
          ConfirmButtonSecondaryRenderer={HipaaDismissButton}
          NotificationRenderer={NotificationRenderer}
          notification={getStepNotification(notification, hipaaSteps.firstStep)}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
          onHipaaStepChange={handleHipaaStepChange}
        />
        {/* step2 */}
        <Notification
          hipaaStep={currentStep}
          key={hipaaSteps.secondStep}
          hipaaFirstStepAnswer={firstStepAnswer}
          DismissButtonRenderer={HipaaDismissButton}
          ConfirmButtonSecondaryRenderer={HipaaDismissButton}
          NotificationRenderer={NotificationRenderer}
          notification={getStepNotification(notification, hipaaSteps.secondStep)}
          onConfirm={onConfirm}
          onDismiss={onDismiss}
          onHipaaStepChange={handleHipaaStepChange}
        />
      </ScHipaaNotification>
    </ScHipaaNotificationOutline>
  );
};

HipaaNotification.propTypes = {
  user: shape({}),
  notification: shape({
    text: string,
    confirm: shape({}),
    dismiss: shape({})
  }),
  NotificationRenderer: elementType.isRequired,
  onDismiss: func,
  onConfirm: func
};

HipaaNotification.defaultProps = {
  user: {},
  notification: {},
  onDismiss: f => f,
  onConfirm: f => f
};

export default HipaaNotification;
