import React, {
  useRef, forwardRef, useState
} from 'react';
import {
  string, node, func, bool
} from 'prop-types';

import { Modal } from '@jotforminc/uikit';
import { setCookie } from '@jotforminc/utils';
import { IconXmark } from '@jotforminc/svg-icons';
import { Loading } from '@jotforminc/loading';

import './style.scss';

const DialogRenderer = ({ children }) => (
  <div className='jfBasicModal'>
    {children}
  </div>
);

DialogRenderer.propTypes = {
  children: node.isRequired
};

const ContentRenderer = ({ children }) => (
  <div className="uxr-survey-content-wrapper">
    <div>{children}</div>
  </div>
);

ContentRenderer.propTypes = {
  children: node.isRequired
};

const HeaderRenderer = forwardRef(({ iframeLoaded }, uikitModalRef) => {
  if (!iframeLoaded) return <></>;

  return (
    <button className="uxr-survey-close" type="button" onClick={() => { uikitModalRef?.current?.hide(); }}>
      <IconXmark />
    </button>
  );
});

HeaderRenderer.propTypes = {
  iframeLoaded: bool.isRequired
};

/**
 * The actual component.
 */
export const UXRSurveyModal = ({ formID, username, onClose }) => {
  const uikitModalRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  setCookie(`UXRSurvey-${formID}`, '1', 3);
  setCookie(`UXRSurvey-user-${formID}-${username}`, '1', 30);

  return (
    <div className='uxr-survey-container'>
      <Modal
        ref={uikitModalRef}
        usePortal={true}
        portalContainerSelector='.uxr-survey-container'
        defaultVisible={true}
        closeOnEscPress={true}
        closeOnOutsideClick={true}
        DialogRenderer={DialogRenderer}
        ContentRenderer={ContentRenderer}
        HeaderRenderer={() => <HeaderRenderer ref={uikitModalRef} iframeLoaded={iframeLoaded} />}
        onModalClose={onClose}
      >
        <div className="modal-content">
          {!iframeLoaded && (
            <Loading strokeColor='#FFF' />
          )}
          <iframe
            title="UXR Survey"
            src={`https://form.jotform.com/${formID}&username=${username}`} style={{ width: '100%', height: '100%', border: 'none' }}
            onLoad={() => setIframeLoaded(true)}
          />
        </div>
      </Modal>
    </div>
  );
};

UXRSurveyModal.propTypes = {
  formID: string.isRequired,
  username: string.isRequired,
  onClose: func
};

UXRSurveyModal.defaultProps = {
  onClose: () => {}
};
