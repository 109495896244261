/* eslint-disable complexity */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StorageHelper } from '@jotforminc/storage-helper';
import translate from '../../utils/translate';
import {
  ICON_MAP, NAMING_MAP, ITEM_NO_CONTENT_TITLE, NO_CONTENT_NAMING, LISTING_TYPES, FOLDER_TYPES, ALL_ASSETS_ID, DEFAULT_FOLDER_IDS, WITHOUT_CREATE_BUTTON_LIST,
  isWorkflowReleased
} from '../../constants';
import ProtectedCreateButton from '../../utils/permissions/ProtectedCreateButton';
import { Button } from '../Button';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { SELECTORS } from '../../store/selectors';
import { TEAM_PERMISSIONS } from '../../utils/permissions';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { getDefaultAssetFilter } from '../../utils';
import ResetAssetFilterNoContent from './ResetAssetFilterNoContent';

const NoContent = ({
  selectedFolder, filter, isSharedWithMeFolder, isTeamFolder = false
}) => {
  const dispatch = useDispatch();
  const manageTeam = useSelector(SELECTORS.getTeamPermissionFetcher)(TEAM_PERMISSIONS.MANAGE_TEAM);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const isResetAssetFilterVisible = useSelector(SELECTORS.getIsResetAssetFilterVisible);
  const userCredentials = useSelector(SELECTORS.getUserCredentials);

  const folder = isSharedWithMeFolder ? DEFAULT_FOLDER_IDS.SHARED_WITH_ME : selectedFolder;
  const folderByID = useSelector(SELECTORS.getFolderByID(folder));

  let showCreateButton = false;
  let naming = NAMING_MAP[folder];
  let Icon = ICON_MAP[folder];

  const handleInviteMembers = useCallback(() => {
    WatchmanRecorder.trackEventForCustomProject('click', `${currentPage === LISTING_TYPES.TEAM_PAGE ? 'teamPage' : ''}NoContentPageInviteMembersButton`, 'teams', true);
    dispatch(ACTION_CREATORS.showInviteMemberModal(true, null, manageTeam));
  }, [manageTeam]);

  const folderName = folderByID?.folderType === FOLDER_TYPES.TEAM_FOLDER ? folderByID.text : 'This team';
  const noContentSubject = isTeamFolder ? `${folderName} doesn't` : 'You don\'t';
  const noContentTitle = ITEM_NO_CONTENT_TITLE[currentPage];

  if (noContentTitle) {
    if (WITHOUT_CREATE_BUTTON_LIST.includes(folder)) {
      naming = `${NO_CONTENT_NAMING[folder]} ${noContentTitle}`;
      Icon = ICON_MAP[noContentTitle];
    } else {
      naming = noContentTitle;
      Icon = ICON_MAP[noContentTitle];
      if (Object.values(ALL_ASSETS_ID).indexOf(folder) > -1 || isTeamFolder || folderByID?.folderType === FOLDER_TYPES.ASSET_FOLDER) {
        showCreateButton = true;
      }
    }
  }
  if (filter.fullText) {
    return (
      <div className="emptyList">
        <div className="emptyList-icon">
          <div className="emptyList-icon-app lsApp-noForms-icon topCenter" />
          <div className="emptyList-icon-app lsApp-noForms-icon bottomLeft" />
          <div className="emptyList-icon-app lsApp-noForms-icon bottomRight" />
          <div className="emptyList-icon-magnifier" />
        </div>
        <div className="emptyList-title">{translate('No result found.')}</div>
        <div className="emptyList-desc">{translate('We couldn’t find any matching assets.')}</div>
      </div>
    );
  }

  // if (selectedFolder === ALL_ASSETS_ID[LISTING_TYPES.FORM] && !isTeamFolder && currentPage === LISTING_TYPES.FORM) {
  //   const logAbTestAction = useSelector(SELECTORS.getUserLogAbTestAction);
  //   return (
  //     <MixNoContent
  //       logAbTestAction={logAbTestAction}
  //       onProductSelect={(productType, initialView, createAssetFrom) => dispatch(ACTION_CREATORS.openProductCreateWizard({ productType, initialView, createAssetFrom }))}
  //       isMobileDevice={isMobileDevice}
  //     />
  //   );
  // }

  if (isResetAssetFilterVisible) {
    return (
      <ResetAssetFilterNoContent
        handleResetFilter={() => {
          WatchmanRecorder.trackEvent('click', 'noContent-resetFilter', 'mixAssetFilter', true);
          const filteredDefaultAssetFilter = getDefaultAssetFilter({ currentPage, userCredentials });
          dispatch(ACTION_CREATORS.setAssetFilter(filteredDefaultAssetFilter));
          StorageHelper.setLocalStorageItem({
            key: `jfLastSelectedAssetFilter${currentPage}${window.teamID || ''}`,
            value: filteredDefaultAssetFilter
          });
        }}
      />
    );
  }

  return naming ? (
    <div className={classNames('lsApp-noForms noForms', folder)}>
      {showCreateButton ? (
        <Icon className="lsApp-noForms-icon-standalone" />
      ) : (
        <div className="lsApp-noForms-icon">
          <div className="lsApp-noForms-icon-tip">
            <svg
              className="lsApp-noForms-icon-tip-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="14"
              fill="none"
              viewBox="0 0 12 14"
            >
              <path
                fill="currentColor"
                d="M6.822.822a1 1 0 00-1.397 0L.707 5.432a1 1 0 00-.085 1.336l4.718 5.95a1 1 0 001.567 0l4.718-5.95a1 1 0 00-.085-1.337L6.822.821z"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="lsApp-noForms-text isPrimary">
        {translate(`${noContentSubject.toUpperCase()} HAVE ANY ${naming.toUpperCase()} YET`)}
      </div>
      <p className="lsApp-noForms-text isSecondary">
        {!isTeamFolder && translate(`Your ${naming} will appear here.`)}
      </p>
      <div className={classNames('lsApp-noForms-actionButtons', { teamListing: isTeamFolder })}>
        {showCreateButton && <ProtectedCreateButton className={isTeamFolder ? 'forSidebar' : 'forNoForms'} theme={isWorkflowReleased ? 'isDark' : ''} />}
        {isTeamFolder && manageTeam && (
        <Button
          className={classNames('inviteMembers')}
          wide="true"
          type="button"
          onClick={handleInviteMembers}
        >
          {`+ ${translate('INVITE MEMBERS')}`}
        </Button>
        )}
      </div>
    </div>
  ) : null;
};

NoContent.propTypes = {
  selectedFolder: string.isRequired,
  filter: shape().isRequired,
  isSharedWithMeFolder: bool.isRequired,
  isTeamFolder: bool
};

export default NoContent;
