/* eslint-disable react/prop-types */
import React, {
  createRef, useEffect, forwardRef, FunctionComponent, MouseEvent, useCallback
} from 'react';
import classnames from 'classnames';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { handleKeyUp } from '../../utils';
import '../../styles/Dropdown.scss';
import { DropdownItemProps, DropdownProps } from './types';

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({
  content,
  children,
  className = '',
  searchable = false,
  onSearch = () => {},
  clearSearch = () => {},
  searchTerm = '',
  ariaLabel = '',
  handleClick = () => {},
  itemAriaRole = '',
  dropdownId = ''
}, wrapperRef) => {
  const inputRef = createRef<HTMLInputElement>();
  const dropdownRef = createRef<HTMLDivElement>();

  const [isVisible, setVisible] = Hooks.useClickOutsideStateMouseDown(false, [dropdownRef]);
  const toggleDropdown = useCallback((e?: MouseEvent<HTMLDivElement>) => {
    if (e) handleClick(e);
    setVisible(prev => !prev);
  }, [handleClick, setVisible]);

  const handleSearch = () => {
    if (isVisible) {
      inputRef.current?.focus();
    } else {
      clearSearch();
    }
  };

  useEffect(() => {
    if (searchable) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <div
      className={classnames('jfDropdown', className, { isVisible })}
      onKeyUp={e => !isVisible && handleKeyUp(e, toggleDropdown)}
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      <div
        className="jfDropdown-content" tabIndex={0} role={itemAriaRole || 'combobox'}
        aria-label={ariaLabel}
        id={dropdownId}
        aria-controls={`${dropdownId}Controls`}
        aria-expanded={isVisible ? 'true' : 'false'}
      >
        {searchable ? (
          <input
            className={classnames('jfDropdown-search', { isVisible })}
            type="text"
            ref={inputRef}
            onChange={onSearch}
            value={searchTerm}
            aria-label={t('Dropdown Search')}
          />
        ) : ''}
        {content}
      </div>
      <div
        className="jfDropdown-wrapper" tabIndex={-1} ref={wrapperRef}
        role="listbox"
        aria-labelledby={dropdownId}
        id={`${dropdownId}Controls`}
      >
        {children}
      </div>
    </div>
  );
});

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({
  children, className, icon, onClick, separator, isSelected, ...props
}) => {
  return (
    <>
      {!separator ? (
        <button
          className={classnames('jfDropdown-item', className, icon && 'hasIcon')} onClick={onClick} type="button"
          {...props}
          role="option"
          aria-selected={!!isSelected}
        >
          {icon && <div className="jfDropdown-item-icon">{icon}</div>}
          {(icon && children) ? <div className="jfDropdown-item-children" {...props}>{children}</div> : children}
        </button>
      ) : <hr role="none" className="jfDropdown-item-separator" />}
    </>
  );
};

export default Dropdown;
