/* eslint-disable react/prop-types */
import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  Dispatch
} from 'react';

import {
  createInitial, AIActionTypes, MessageTypes
} from '../constants';
import { logActions } from '../api';
import { useFullstory } from '../hooks/useFullStory';
import { MessageState, UserState } from '../typings/state';

type FormProperties = Record<string, any>

type State = {
  promptValue: string,
  form: {
    form_id: string,
    url?: string
  },
  messages: MessageState[],
  manual_changes: null,
  formProperties: FormProperties
  user: UserState,
  fullstoryUrl: string
}

type ActionType = keyof typeof AIActionTypes
type DispatchAction<Type extends ActionType, Payload> = [Payload] extends [never] ?
  { type: Type } :
  { type: Type, payload: Payload, override?: Partial<State> }

export type DispatchAIBuilderAction =
  DispatchAction<'CREATE_AI_FORM', {
    messages: MessageState[],
    form: State['form'],
    formProperties: State['formProperties']
  }> |
  DispatchAction<'ADD_MESSAGE_TO_CHAT', {
    message?: MessageState['content']
    type?: MessageState['message_type']
  }> |
  DispatchAction<'SHOW_ERROR_MESSAGE', {
    message: MessageState['content']
  }> |
  DispatchAction<'SET_FULLSTORY_URL', string>

export type AIBuilderReducer = (state: State, action: DispatchAIBuilderAction) => State
export type DispatchAIBuilder = Dispatch<DispatchAIBuilderAction>
export type Context = { AIState: State, dispatchAIBuilder: Dispatch<DispatchAIBuilderAction> }

const AIContext = createContext<Context | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const reducer: AIBuilderReducer = (state, { type, payload, override = {} }) => {
  switch (type) {
    case AIActionTypes.CREATE_AI_FORM:
      return {
        ...state,
        messages: payload.messages,
        form: {
          ...state.form,
          ...payload.form
        },
        formProperties: { ...payload.formProperties }
      };
    case 'ADD_MESSAGE_TO_CHAT':
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            content: payload.message,
            message_type: payload.type || 'USER',
            message_id: new Date().valueOf() // temp random id
          }
        ],
        ...override
      };
    case 'SHOW_ERROR_MESSAGE':
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            content: payload.message,
            message_type: MessageTypes.ERROR,
            message_id: new Date().valueOf() // temp random id
          }
        ]
      };
    case 'SET_FULLSTORY_URL':
      return {
        ...state,
        fullstoryUrl: payload
      };
    default:
      return state;
  }
};

export const AIProvider: React.FC<{ user: UserState }> = ({
  children, user
}) => {
  const [AIState, dispatchAIBuilder] = useReducer(
    reducer,
    createInitial(user)
  );

  useEffect(() => {
    logActions({ action: 'modalShown', target: 'initialScreen' });
  }, []);

  useFullstory({ user, dispatchAIBuilder });

  return (
    <AIContext.Provider
      value={{ AIState, dispatchAIBuilder }}
    >
      <div className='w-full h-full'>
        {children}
      </div>
    </AIContext.Provider>
  );
};

export const useAIContext = () => useContext(AIContext) as Context;
