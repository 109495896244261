class EnterprisePromotionSingleton {
  constructor() {
    this.isAdLeadFlowExitIntentModalAvailable = false;

    // shouldShowEnterprisePromotionAsset sets these properties
    this.assetType = '';
    this.seenAtKey = '';
    this.details = null;
  }
}

export const enterprisePromotionSingleton = new EnterprisePromotionSingleton();
