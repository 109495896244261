import React, { Suspense } from 'react';
import { Loading } from '@jotforminc/loading';
import { unmountComponentAtNode, render } from 'react-dom';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import { getPortalRoot, setFlagToLocSt } from '../utils';

const HubSpotModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_HubSpotModal" */'../lazyComponents/HubSpotModal'));

const renderLazy = (RenderedComponent, rootEl) => render(
  <Suspense fallback={<Loading />}>
    {RenderedComponent}
  </Suspense>,
  rootEl
);

const onConfirm = (notification, user) => {
  const rootEl = getPortalRoot();
  if (!rootEl) return;

  renderLazy(<HubSpotModal user={user} onClose={() => unmountComponentAtNode(rootEl)} />, rootEl);
  setFlagToLocSt(notification.localStActionKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: notification.actionTargetName
    });
  }
};

const onDismiss = notification => {
  setFlagToLocSt(notification.localStActionKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: notification.actionTargetName
    });
  }
};

// hubspot milestone
const checkHubspotMilestone = notifications => {
  let notification = notifications.find(notif => notif.name === 'hubspotMilestone');
  const otherNotifications = notifications.filter(notif => notif.name !== 'hubspotMilestone');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkHubspotMilestone;
