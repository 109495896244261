import { createRoot } from 'react-dom/client';
import { Loading } from '@jotforminc/loading';
import React, { Suspense } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const CreateNewTeamModal = safeLazy(() => import(/* webpackChunkName: "LazyModal_CreateNewTeamModal" */'./modal'));

export const openCreateTeamModal = ({
  teamRoles, rootSelector, user, source, redirectAfterCreation, globalTeamSettings, showSignUpModal, teamCount
}) => {
  const selector = rootSelector || '#wizardPortal';
  const root = document.querySelector(selector);
  const rootNode = createRoot(root);

  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      WatchmanRecorder.trackEventForCustomProject('click', 'CreateTeamModalClose-1', 'teams', true);
      rootNode.unmount();
    };

    const handleTeamCreate = team => {
      resolve(team);
      if (!redirectAfterCreation) {
        rootNode.unmount();
      }
    };

    rootNode.render(
      <Suspense fallback={<Loading />}>
        <CreateNewTeamModal
          onTeamCreate={handleTeamCreate}
          onClose={handleClose}
          teamRoles={teamRoles}
          closeOnOutsideClick={false}
          user={user}
          source={source}
          redirectToWorkspace={redirectAfterCreation}
          globalTeamSettings={globalTeamSettings}
          showSignUpModal={showSignUpModal}
          teamCount={teamCount}
        />
      </Suspense>
    );
  });
};
