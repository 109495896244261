/* eslint-disable max-len */
export const UPGRADE_PDF_ATTACHMENT = '[1[Upgrade your account]] to attach multiple PDFs.';
export const ESTIMATION_WARNING = 'You will likely reach your [1[{limitType}]] limit by [2[{estimationDate}]].';
export const UPGRADE_NOW = 'Upgrade Now';
export const BUILDER_BRANDING_WARNING = 'If you want to remove Jotform Branding, [1[please upgrade your account]]';
export const BUILDER_EMAIL_LIMIT_WARNING = '[1[Upgrade your account]] to use multiple [2[{emailType}]] emails.';
export const OVERQUOTA_WARNING = 'You have reached [1[{messageContent}]] limit.';
export const ALMOST_FULL_WARNING = 'You have almost reached [1[{messageContent}]] limit.';

export const GET_MORE_FORMS = 'Get more forms - for free';
export const LIMITED_TO_FIVE_FORMS = 'You’re currently limited to [1[{formCount}]] [2[forms]]. Want to create more forms? Invite others to Jotform and get one extra form per signup! Once an invitee verifies their email, you’ll receive your extra form.';
export const TRACK_YOUR_EARNINGS = 'Track your earnings from [1[Increase Limits]]';
export const TWITTER_SHARE_TEXT = 'Cards are fun and friendly online forms! They interact with respondents like a real person would';
