import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { t, translationRenderer } from '@jotforminc/translation';
import '../../assets/listings-limit-warnings.scss';
import { UPGRADE_NOW } from '../../constants/texts';

const ListingsLimitWarnings = ({
  currentLimit,
  limitType,
  messageHeader,
  messageContent,
  estimationDate,
  handleCustomNavigation,
  link,
  alignment
}) => {
  const [value, setStartValue] = useState(0);
  const [endValue, setEndValue] = useState(0);
  const [degree, setDegree] = useState(value);
  const [trackColor, setTrackColor] = useState();
  const [pathColor, setPathColor] = useState();

  useMemo(() => {
    if (currentLimit?.color === 'red') {
      setTrackColor('#DC2626');
      setPathColor('#DC262625');
    } else if (currentLimit?.color === 'orange') {
      setTrackColor('#F49200');
      setPathColor('#F4920025');
    } else if (currentLimit?.color === 'yellow') {
      setTrackColor('#0075E3');
      setPathColor('#0075E325');
      setStartValue(50);
    }
  }, [currentLimit?.color]);

  useMemo(() => {
    setEndValue(parseInt(currentLimit?.data?.percent ?? 0, 10));
  }, [currentLimit?.data?.percent]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartValue(value + 1);
      setDegree((value + 1) * 3.6);
    }, 20);

    if (value >= endValue || value >= 100) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [value, endValue]);

  const handleUpgradeClick = () => {
    handleCustomNavigation(link, '_self');
  };

  return (
    <div className={`lsApp-sidebar-warning ${alignment}`}>
      <div className={`sidebar-upgrade-wrapper-new ${alignment} ${currentLimit?.color} ${currentLimit?.display}`}>
        <div className='sidebar-upgrade-content'>
          {value !== 0 && (
            <div className='circular-progress-container'>
              <div className='circular-progress' style={{ background: `conic-gradient(${trackColor} ${degree}deg, ${pathColor} 0deg)` }}>
                <span className='progress-value'>
                  {value}
                  <span className='percent'>%</span>
                </span>
              </div>
            </div>
          )}
          <p className='limit-description'>
            {limitType === 'estimation' ? (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>),
                  renderer2: () => (<b>{t(estimationDate)}</b>)
                })}
              </>
            ) : (
              <>
                {translationRenderer(messageHeader)({
                  renderer1: () => (<b>{t(messageContent)}</b>)
                })}
              </>
            )}
          </p>
        </div>
        <div className='button-wrapper'>
          <button
            type="button"
            className="upgrade-now locale"
            data-campaign={`${currentLimit?.campaign}`}
            data-content={`${currentLimit?.content}`}
            data-term={`${currentLimit?.term}`}
            onClick={() => handleUpgradeClick()}
          >
            {t(UPGRADE_NOW)}
          </button>
        </div>
      </div>
    </div>
  );
};

ListingsLimitWarnings.propTypes = {
  currentLimit: PropTypes.object,
  limitType: PropTypes.string,
  messageHeader: PropTypes.string,
  messageContent: PropTypes.string,
  estimationDate: PropTypes.string,
  handleCustomNavigation: PropTypes.func,
  link: PropTypes.string,
  alignment: PropTypes.string
};

ListingsLimitWarnings.defaultProps = {
  currentLimit: {},
  limitType: '',
  messageHeader: '',
  messageContent: '',
  estimationDate: '',
  handleCustomNavigation: f => f,
  link: '',
  alignment: ''
};

export default ListingsLimitWarnings;
