import { select, take } from 'redux-saga/effects';
import { handleCustomNavigation } from '@jotforminc/utils';
import { LISTING_TYPES } from '../../constants';
import { ACTION_TYPES } from '../../store/actionTypes';
import { SELECTORS } from '../../store/selectors';

export function getMixFolderSummary(folderInfo) {
  return folderInfo; // TODO :: it will be updated according to design.
}

export function* rootMixFlow() {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  if (currentPage === LISTING_TYPES.MIX) {
    yield take(ACTION_TYPES.FETCH_USER.SUCCESS);
    const canUserAccessMixPage = yield select(SELECTORS.canAccessMixedListing);
    if (!canUserAccessMixPage) {
      handleCustomNavigation('/myforms', '_self');
    }
  }
}
