import React, {
  useState, useRef, useEffect, ChangeEvent, FormEvent
} from 'react';
import cx from 'classnames';
import { Checkbox } from '@jotforminc/magnet';
import { t, translationRenderer } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { useAIContext } from '../../context';
import { generateForm } from '../../actions';
import {
  changeUserSettings,
  logActions,
  saveFullStorySessionURL,
  sendAIFormBuilderBetaAgreementSubmission
} from '../../api';
import AiLogo from '../../assets/svg/AILogo.svg';
import Loading from '../../components/Loading';
import AiLogoWhite from '../../assets/svg/ai-logo-white.svg';
import { ExampleBuildPrompts, ExamplePromptBoxes, PROMT_PROPS } from '../../constants';
import { promptLengthLimit } from '../../utils';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

type Props = {
  showHeader: boolean,
  creationLogger?: (txt: string) => void,
  onFormCreationSuccess?: (formID: string) => void
}

const CreateAIForm: React.FC<Props> = ({
  showHeader,
  creationLogger = f => f,
  onFormCreationSuccess = formID => handleCustomNavigation(`/build/${formID}`, '_self')
}) => {
  const { AIState: { user, fullstoryUrl } } = useAIContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const isAlreadyAgreed = user.aiFormBuilderBetaUserAgreement === '1';

  const [prompt, setPrompt] = useState('');
  const [isAgreed, setAgreed] = useState(isAlreadyAgreed);
  const [errors, setErrors] = useState<Record<string, string | null> | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    let count = 0;
    const texts = ExampleBuildPrompts;
    let text = t(texts[count]);
    let index = 0;

    function displayWord() {
      if (index < text.length) {
        setPlaceholder(prevPlaceholder => `${prevPlaceholder + text[index++]}`);
      } else {
        setPlaceholder(() => {
          count = count === 3 ? 0 : count + 1;
          text = t(texts[count]);
          index = 0;
          return '';
        });
      }
    }

    const interval = setInterval(displayWord, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (prompt.length < 10) {
      setErrors(errs => ({ ...errs, prompt: t('Must be at least 10 characters.') }));
      return;
    }

    if (!isAgreed) {
      setErrors(errs => ({ ...errs, agreement: t('You must agree to the Beta Program Terms and Conditions to continue.') }));
      return;
    }

    if (!isAlreadyAgreed) {
      const { responseCode, message } = await changeUserSettings({ aiFormBuilderBetaUserAgreement: '1' });
      if (responseCode !== 200) {
        // eslint-disable-next-line no-console
        console.log('User Settings Change Error:', message);
        return;
      }
      window.user.aiFormBuilderBetaUserAgreement = '1';
      sendAIFormBuilderBetaAgreementSubmission(user);
    }
    creationLogger('ai');
    logActions({ action: 'CreateForm-clicked', target: 'initialScreen' });
    const promptWords = prompt.split(' ');
    if (promptWords.length > 1) {
      logActions({ action: `typedWords-${prompt.split(' ').length}`, target: 'initialScreen' });
    }
    try {
      setIsLoading(true);
      const response = await generateForm(prompt);
      if (response) {
        const { resource_id: formID } = response;
        if (formID && fullstoryUrl) {
          saveFullStorySessionURL({ fullstoryUrl, formID });
        }
        logActions({ action: 'sessionInitialized', target: formID });
        if (user.usage?.form_count === '0') {
          logActions({ action: 'firstFormIsAIForm', target: 'initialScreen' });
        }
        onFormCreationSuccess(formID);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsLoading(false);
      setErrors(errs => ({ ...errs, creation: "Something went wrong, we couldn't complete your request. Try again." }));
    }
  };

  const handlePromtChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: promptVal } = e.target;
    setPrompt(promptVal);
    if (!isDirty) {
      logActions({ action: 'CustomFormPrompt', target: 'initialScreen' });
      setIsDirty(true);
    }
    if (promptVal.length > 10) {
      setErrors(errs => ({ ...errs, prompt: null }));
    }
  };

  const handleAgreementCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors(null);
    setAgreed(e.target.checked);
  };

  const handleExamplePromtClick = (exPrompt: typeof ExamplePromptBoxes[number]) => {
    setPrompt(t(exPrompt.prompt));
    logActions({ action: 'SuggestionList-selected', target: 'initialScreen' });
    setErrors(errs => ({ ...errs, prompt: null }));
  };

  return (
    <>
      {isLoading && (
        <div className='absolute w-full h-full top-0 left-0 bg-white flex items-center justify-center z-9'>
          <Loading />
        </div>
      )}
      <div className='overflow-auto p-5 flex items-center  justify-start sm:justify-center h-full w-full text-center flex-col'>
        <div className={cx(showHeader && 'relative pb-20')}>
          {showHeader && (
            <>
              <AiLogo className="w-14 h-14 mb-12" />
              <h2 className='text-2xl sm:text-4xl font-medium color-navy-700 mb-3.5'>{t('Generate a form in seconds with Copilot')}</h2>
              <p className='color-navy-300 mb-16 text-sm sm:text-md'>{t('Describe the form you want and the data you want to collect')}</p>
            </>
          )}
          <div className='flex items-center flex-col'>
            <div className={cx('w-full', showHeader ? 'mb-14 sm:mb-20' : 'mb-16')}>
              <form onSubmit={handleSubmit} className='flex justify-center'>
                <div className='max-width-custom-ai w-full flex-col flex justify-center relative  md:flex-row '>
                  <div className='grow-1 relative'>
                    <input
                      ref={inputRef}
                      placeholder={t(placeholder)}
                      className='h-16 p-3 border w-full radius border-navy-100 border-color-ai-hover placeholder-navy-300 items-start'
                      id='prompt-input'
                      value={prompt}
                      aria-label={t('Prompt Input')}
                      onChange={handlePromtChange}
                      onPaste={() => logActions({ action: 'promptPasted', target: 'initialScreen' })}
                      autoComplete='off'
                      autoCorrect='off'
                      maxLength={PROMT_PROPS.MAX_LENGTH}
                    />
                    <div className='absolute right-2 bottom-1 text-xs color-navy-300'>{promptLengthLimit(prompt.length)}</div>
                  </div>
                  <button
                    disabled={isLoading} type='submit'
                    className='w-full sm:w-40 bg-ai radius p-5 flex items-center justify-center color-white h-16 md:mt-0 md:ml-3 xs:ml-0 mt-5 whitespace-nowrap'
                  >
                    <AiLogoWhite className="w-5 h-5 mr-2" />
                    {t('Create Form')}
                  </button>
                  <div className='absolute top-full left-0 flex items-start pt-4 justify-center w-full'>
                    {errors?.prompt && <ErrorMessage message={errors.prompt} autofocus={true} />}
                  </div>
                </div>
              </form>
            </div>
            {errors?.creation && <ErrorMessage className='mb-4' message={errors.creation} autofocus={true} />}
            <div className='flex flex-wrap gap-4'>
              {ExamplePromptBoxes.map(exPrompt => (
                <button
                  className='p-4 radius-full bg-navy-25 text-sm sm:text-md'
                  type='button' onClick={() => handleExamplePromtClick(exPrompt)}
                >
                  {t(exPrompt.slug)}
                </button>
              ))}
            </div>
          </div>
          {!isAlreadyAgreed && (
            <div className='flex flex-col items-center mt-10 relative'>
              <div className='flex items-center mb-2'>
                <Checkbox
                  size="small"
                  checked={isAgreed}
                  aria-label={t('Agreement checkbox')}
                  onChange={handleAgreementCheckboxClick}
                />
                <p className='ml-1 text-sm font-normal'>
                  {translationRenderer('I have read and accept the [1[Beta Program Terms and Conditions]].')({
                    // eslint-disable-next-line max-len
                    renderer1: (text: string) => <a href='https://www.jotform.com/jotform-inc-beta-tester-and-confidentiality-agreement/' className='color-blue-500 underline' target='_blank'>{text}</a>
                  })}
                </p>
              </div>
              {errors?.agreement && <div className='absolute top-full'><ErrorMessage message={errors.agreement} autofocus={true} /></div>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateAIForm;
