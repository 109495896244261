import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageWithWrapper } from '@jotforminc/enterprise-components';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { FEATURE_LIST } from '../../constants';

const Image = props => {
  const dispatch = useDispatch();
  const id = useSelector(SELECTORS.getCurrentTeamID) || '';
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const isAssetsFolderActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));

  return (
    <ImageWithWrapper
      {...props}
      teamId={id}
      onUpdate={newData => {
        dispatch(ACTION_CREATORS.updateTeamProperties(id, newData, currentPage, isAssetsFolderActive));
      }}
    />
  );
};

export default Image;
