import React from 'react';

import { createRoot } from 'react-dom/client';

import { t } from '@jotforminc/translation';

import { IconShieldLockFilled } from '@jotforminc/svg-icons';
import { Button, Badge } from '@jotforminc/magnet';
import { handleCustomNavigation } from '@jotforminc/utils';

import './styles/announcement-two-factor.scss';
import { string } from 'prop-types';

export const TwoFactorAnnouncement = ({ username = '' }) => {
  const announcing2FATracker = window.JotFormActions('announcing-2fa-banner');
  const handleEnableClick = () => {
    if (window.JOTFORM_ENV === 'PRODUCTION') {
      announcing2FATracker.tick({ action: 'clickedEnable2FA', target: 'announcing-2fa-banner', actor: username });
    }
    handleCustomNavigation('/myaccount/security', '_self');
  };

  return (
    <section className='announcement-2fa-wrapper'>
      <div className='announcement-2fa-container'>
        <div className='announcement-2fa-info-wrapper'>
          <div className='announcement-2fa-icon-container'>
            <IconShieldLockFilled color='#0A1551' width='32px' height='32px' />
          </div>
          <div className='announcement-2fa-info-text-container'>
            <div className='announcement-2fa-info-title-container'>
              <span className='announcement-2fa-info-title'>
                {t('Secure your account with two-factor authentication (2FA)')}
              </span>
              <Badge variant="filled" colorStyle="primary" rounded={false}>{t('Beta')}</Badge>
            </div>
            <span className='announcement-2fa-info-desc'>{t('You can now set up 2FA to give your account an added layer of security')}</span>
          </div>
        </div>
        <Button
          colorStyle='success'
          className='announcement-2fa-enable-button'
          variant='filled'
          onClick={handleEnableClick}
        >
          {t('Enable 2FA')}
        </Button>
      </div>
    </section>
  );
};

TwoFactorAnnouncement.propTypes = {
  username: string
};

export const renderTwoFactorAnnouncement = ({
  container, expirationDate, accountTypeName, utmSource, username
}) => {
  const root = createRoot(container);
  root.render(<TwoFactorAnnouncement
    utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName}
    username={username}
  />);
};
