const generatePromiseActionType = baseName => ({
  REQUEST: `${baseName}/REQUEST`,
  SUCCESS: `${baseName}/SUCCESS`,
  ERROR: `${baseName}/ERROR`
});

export const ACTION_TYPES = {
  SET_USER: 'SET_USER',
  PURGE_ITEM: 'PURGE_ITEM',
  RESET_LIST: 'RESET_LIST',
  SET_FILTER: 'SET_FILTER',
  SET_FILTERED_LIST: 'SET_FILTERED_LIST',
  SELECT_ALL: 'SELECT_ALL',
  SET_FOLDERS: 'SET_FOLDERS',
  SET_ORDERBY: 'SET_ORDERBY',
  SELECT_ITEM: 'SELECT_ITEM',
  SET_LAST_SELECTED_LISTING_ITEM: 'SET_LAST_SELECTED_LISTING_ITEM',
  DISABLE_MULTIPLE_SELECT_SHIFT_TOAST: 'DISABLE_MULTIPLE_SELECT_SHIFT_TOAST',
  SET_FULLTEXT: 'SET_FULLTEXT',
  SET_FEATURES: 'SET_FEATURES',
  SET_FAVORITE: 'SET_FAVORITE',
  SET_ARCHIVED: 'SET_ARCHIVED',
  RESTORE_ITEM: 'RESTORE_ITEM',
  OPEN_LIMIT_DIALOG: 'OPEN_LIMIT_DIALOG',
  SELECT_SINGLE_ITEM: 'SELECT_SINGLE_ITEM',
  RESET_FEATURES: 'RESET_FEATURES',
  SET_SELECTED_FOLDER: 'SET_SELECTED_FOLDER',
  FOLDER_BUTTON_ACTION: 'FOLDER_BUTTON_ACTION',
  SET_MOBILE_VIEW_FOLDERS_VISIBLITY: 'SET_MOBILE_VIEW_FOLDERS_VISIBLITY',
  FOLDER_CONTEXT_ACTION: 'FOLDER_CONTEXT_ACTION',
  SET_LIST: 'SET_LIST',
  BULK_MARK_AS_READ: 'BULK_MARK_AS_READ',
  CREATE_APP: 'CREATE_APP',
  CREATE_DIGEST: 'CREATE_DIGEST',
  UPDATE_DIGEST: 'UPDATE_DIGEST',
  FOLDER_ITEM_ACTION_PROCESS: 'FOLDER_ITEM_ACTION_PROCESS',
  FOLDER_ITEM_ACTION: generatePromiseActionType('FOLDER_ITEM_ACTION'),
  FOLDER_ITEM_ACTION_BULK: generatePromiseActionType('FOLDER_ITEM_ACTION_BULK'),
  TRANSFER_ITEM: generatePromiseActionType('TRANSFER_ITEM'),
  CLONE_ITEM: generatePromiseActionType('CLONE_ITEM'),
  ADD_FOLDER: generatePromiseActionType('ADD_FOLDER'),
  DELETE_FOLDER: generatePromiseActionType('DELETE_FOLDER'),
  FETCH_LIST: generatePromiseActionType('FETCH_LIST'),
  FETCH_USER: generatePromiseActionType('FETCH_USER'),
  DELETE_ITEM: generatePromiseActionType('DELETE_ITEM'),
  DELETE_TASK_ITEM: generatePromiseActionType('DELETE_TASK_ITEM'),
  FETCH_FOLDERS: generatePromiseActionType('FETCH_FOLDERS'),
  UPDATE_ITEM: generatePromiseActionType('UPDATE_ITEM'),
  RENAME_ITEM: generatePromiseActionType('RENAME_ITEM'),
  UPDATE_FOLDERS: generatePromiseActionType('UPDATE_FOLDERS'),
  FETCH_ALL_FORMS: generatePromiseActionType('FETCH_ALL_FORMS'),
  INIT_SHARE: 'INIT_SHARE',
  GENERATE_SHARE_LINK: generatePromiseActionType('GENERATE_SHARE_LINK'),
  ON_REVOKE_USER: generatePromiseActionType('ON_REVOKE_USER'),
  ON_RESEND_INVITATION: generatePromiseActionType('ON_RESEND_INVITATION'),
  ON_ASSIGNEE_PERMISSION: generatePromiseActionType('ON_ASSIGNEE_PERMISSION'),
  ON_REVOKE_MULTIPLE_USER: generatePromiseActionType('ON_REVOKE_MULTIPLE_USER'),
  ON_SEND: generatePromiseActionType('ON_SEND'),
  ON_PROPERTY_CHANGE: generatePromiseActionType('ON_PROPERTY_CHANGE'),
  CLONE_SIGN_DOCUMENT: generatePromiseActionType('CLONE_SIGN_DOCUMENT'),
  DELETE_SIGN_DOCUMENT: generatePromiseActionType('DELETE_SIGN_DOCUMENT'),
  RENAME_SIGN_DOCUMENT: generatePromiseActionType('RENAME_SIGN_DOCUMENT'),
  FORM_DELETE_REPORT: generatePromiseActionType('FORM_DELETE_REPORT'),
  OPEN_INBOX_PANEL: 'OPEN_INBOX_PANEL',
  CHANGE_INBOX_PANEL: 'CHANGE_INBOX_PANEL',
  TOGGLE_INBOX_PANEL: 'TOGGLE_INBOX_PANEL',
  PORTAL_UPDATE: generatePromiseActionType('PORTAL_UPDATE'),
  ON_DELETE_MULTIPLE_PORTALS: generatePromiseActionType('ON_DELETE_MULTIPLE_PORTALS'),
  BULK_UPDATE_LIST: generatePromiseActionType('BULK_UPDATE_LIST'),
  BULK_DELETE: generatePromiseActionType('BULK_DELETE'),
  DISCARD_DRAFT: generatePromiseActionType('DISCARD_DRAFT'),
  CREATE_WIZARD: 'CREATE_WIZARD',
  CREATE_REPORT_WIZARD: 'CREATE_REPORT_WIZARD',
  UPDATE_REPORT_WIZARD: 'UPDATE_REPORT_WIZARD',
  REORDER_FOLDERS: 'REORDER_FOLDERS',
  REORDER_TEAMS: 'REORDER_TEAMS',
  REORDER_TEAM_SUBFOLDERS: 'REORDER_TEAM_SUBFOLDERS',
  UPDATE_FOLDER_LAYOUT: generatePromiseActionType('UPDATE_FOLDER_LAYOUT'),
  UPDATE_FOLDER_LAYOUT_BACKUP: generatePromiseActionType('UPDATE_FOLDER_LAYOUT_BACKUP'),
  SET_FILTER_ID: 'SET_FILTER_ID',
  SET_FILTER_FORM: 'SET_FILTER_FORM',
  SHOW_CREATE_TEAM_MODAL: 'SHOW_CREATE_TEAM_MODAL',
  DELETE_TEAM: generatePromiseActionType('DELETE_TEAM'),
  UPDATE_TEAM_ROLES: 'UPDATE_TEAM_ROLES',
  UPDATE_TEAMS: 'UPDATE_TEAMS',
  SHOW_INVITE_MEMBER_MODAL: 'SHOW_INVITE_MEMBER_MODAL',
  UPDATE_TEAM_PROPERTIES: 'UPDATE_TEAM_PROPERTIES',
  SET_URL_ID: 'SET_URL_ID',
  SET_CURRENT_TEAM_ID: 'SET_CURRENT_TEAM_ID',
  TOGGLE_TEAM_ACTIVITY_PANEL: 'TOGGLE_TEAM_ACTIVITY_PANEL',
  CONTROL_MOVE_TO_OPERATION: generatePromiseActionType('CONTROL_MOVE_TO_OPERATION'),
  MOVE_TO_TEAM: generatePromiseActionType('MOVE_TO_TEAM'),
  MOVE_FROM_TEAM: generatePromiseActionType('MOVE_FROM_TEAM'),
  SET_TEAM_FILTER: 'SET_TEAM_FILTER',
  SET_CONTACT_DETAIL: 'SET_CONTACT_DETAIL',
  SHOW_NO_ACCESS_TO_TEAM_VIEW: 'SHOW_NO_ACCESS_TO_TEAM_VIEW',
  HANDLE_TEAM_ERROR: 'HANDLE_TEAM_ERROR',
  SET_DELETED_ITEMS: 'SET_DELETED_ITEMS',
  TOGGLE_ADD_TO_FOLDER_MENU_VISIBILITY: 'TOGGLE_ADD_TO_FOLDER_MENU_VISIBILITY',
  TOGGLE_MOVE_TO_TEAM_MENU_VISIBILITY: 'TOGGLE_MOVE_TO_TEAM_MENU_VISIBILITY',
  SET_TEAM_NOTIFICATION_COUNT: 'SET_TEAM_NOTIFICATION_COUNT',
  REQUEST_EDIT: 'REQUEST_EDIT',
  EDIT_RESOURCE: 'EDIT_RESOURCE',
  UPDATE_USER_PROPERTY: 'UPDATE_USER_PROPERTY',
  SET_SERVER_TEAM_SETTINGS: 'SET_SERVER_TEAM_SETTINGS',
  RESET_ALL_TEAM_PERMISSIONS: 'RESET_ALL_TEAM_PERMISSIONS',
  ADD_AUTOMATED_SDR_EMAIL: 'ADD_AUTOMATED_SDR_EMAIL',
  LISTEN_MOVE_JOBS: 'LISTEN_MOVE_JOBS',
  CANCEL_MOVE_JOBS: generatePromiseActionType('CANCEL_MOVE_JOBS'),
  FETCH_MOVE_JOBS: generatePromiseActionType('FETCH_MOVE_JOBS'),
  RETRY_MOVE_JOB: 'RETRY_MOVE_JOB',
  DELETE_PAGE: 'DELETE_PAGE',
  PREVENT_FETCHING_MOVE_JOBS: 'PREVENT_FETCHING_MOVE_JOBS',
  ALLOW_REORDER_TEAMS: 'ALLOW_REORDER_TEAMS',
  ALLOW_REORDER_FOLDERS: 'ALLOW_REORDER_FOLDERS',
  UPDATE_ALL_FOLDERS_VISIBILITY: 'UPDATE_ALL_FOLDERS_VISIBILITY',
  SET_MOBILE_DEVICE: 'SET_MOBILE_DEVICE',
  IS_TEAMS_FETCHED: 'IS_TEAMS_FETCHED',
  REMOVE_EXPANDED_FOLDERS: 'REMOVE_EXPANDED_FOLDERS',
  ADD_EXPANDED_FOLDERS: 'ADD_EXPANDED_FOLDERS',
  UPDATE_FORM_STATUS: generatePromiseActionType('UPDATE_FORM_STATUS'),
  OPEN_PRODUCT_CREATE_WIZARD: 'OPEN_PRODUCT_CREATE_WIZARD',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_ASSET_FILTER: 'SET_ASSET_FILTER',
  TEMPLATE_SUGGESTION_MODAL: 'TEMPLATE_SUGGESTION_MODAL',
  UPDATE_USER: 'UPDATE_USER',
  FETCH_USER_PERMISSIONS: generatePromiseActionType('FETCH_USER_PERMISSIONS'),
  FETCH_SYSTEM_PLANS: generatePromiseActionType('FETCH_SYSTEM_PLANS'),
  OPEN_MYWORKSPACE_FEEDBACK_MODAL: 'OPEN_MYWORKSPACE_FEEDBACK_MODAL',
  DELETE_AGENT: generatePromiseActionType('DELETE_AGENT'),
  CLONE_AGENT: generatePromiseActionType('CLONE_AGENT'),
  RENAME_AGENT: generatePromiseActionType('RENAME_AGENT'),
  ARCHIVE_AGENT: generatePromiseActionType('ARCHIVE_AGENT'),
  RESTORE_AGENT: generatePromiseActionType('RESTORE_AGENT'),
  ENABLE_AGENT: generatePromiseActionType('ENABLE_AGENT'),
  DISABLE_AGENT: generatePromiseActionType('DISABLE_AGENT'),
  FETCH_ALL_FORMS_WITH_PROPS: generatePromiseActionType('FETCH_ALL_FORMS_WITH_PROPS'),
  ON_DELETE_MULTIPLE_AGENTS: generatePromiseActionType('ON_DELETE_MULTIPLE_AGENTS')
};
