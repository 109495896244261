import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScNotificationListContent = Styled.div`
  @media screen and (max-width:${breakpoints.tablet}){
    display: flex;
    flex-direction: column;
    height: auto;

    @media (hover: none) and (pointer: coarse) {
      height: -webkit-fill-available;
    }
  }
`;
