import {
  call, delay, select, spawn, all, put, take
} from 'redux-saga/effects';

import { channel } from 'redux-saga';
import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { registerUniqueAction } from '../utils';
import { ASSET_TYPES, FEATURE_LIST } from '../../constants';

import { openCreateNewBoardModal } from '../../wizards/CreateNewBoardModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { ACTION_CREATORS } from '../../store/actionCreators';

const apiAction = new API.CommonActions('board');

const fetchAllFormsChannel = channel();

export function* handleCreateWizard({
  toFolder, teamProperties, folderID: folderId, isSideBar, isSideBySideUser, createAssetFrom, targetText = '', isMixPageUser, closeButtonCallback, openWizardCallback
}) {
  const [
    user,
    assetFilter,
    currentPage,
    isAllAssetFilterSelected
  ] = yield all([
    select(SELECTORS.getUser),
    select(SELECTORS.getAssetFilter),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getIsAllAssetFilterTypesSelected)
  ]);
  const isAssetsFolderActive = yield select(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const teamID = toFolder ? teamProperties?.id : yield select(SELECTORS.getCurrentTeamID);
  const fetchedUserForms = yield select(SELECTORS.getFetchedUserForms);
  const fetchedTeamForms = yield select(SELECTORS.getFetchedTeamForms);
  const folderID = toFolder ? folderId : yield select(SELECTORS.getSelectedFolderIDForCreation);
  const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
  const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);

  try {
    const forms = teamID ? fetchedTeamForms[teamID] : fetchedUserForms;

    yield call(openCreateNewBoardModal, {
      user,
      fetchFormsCallback: teamID ? () => API.fetchTeamForms({
        teamID,
        isAllAssetFilterSelected,
        currentPage,
        assetFilter
      }) : API.fetchAllFormsInfo,
      fetchFormsSuccess: (fetchedForms, teamId) => {
        fetchAllFormsChannel.put({ fetchedForms, teamId });
      },
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-board-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-board-created`, 'boards', true);
        if (createAssetFrom && logAbTestAction) {
          logAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-board-created` });
          logAbTestAction({ action: 'click', target: 'actTest-boardCreated' });
        }
      },
      teamID,
      ...(isAssetsFolderActive ? { folderID } : {}),
      forms: forms?.filter(form => !['DELETED', 'PURGED'].includes(form.status)),
      isMixAssetCreationModal: isSideBySideUser || isMixPageUser,
      closeButtonCallback,
      openWizardCallback,
      backToMixModalActionLogger: () => {
        WatchmanRecorder.trackEventForCustomProject(
          'click',
          `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-boards`,
          isSideBySideUser ? 'sideBySideModal' : 'mixAssetCreationModal',
          true
        );
      }
    });
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleBoardFavorite({ id }) {
  yield delay(500);
  const sheet = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.BOARD));
  const { favorite } = sheet;
  if (!sheet) {
    return false;
  }
  const newItem = { favorite: favorite };
  yield call(apiAction.updateItem, id, newItem);
}

function* watchFetchAllFormsChannel() {
  while (true) {
    const { fetchedForms, teamId } = yield take(fetchAllFormsChannel);
    yield put(ACTION_CREATORS.fetchAllFormsWithPropsSuccess(fetchedForms, teamId));
  }
}

export function* rootBoardFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleBoardFavorite);
  yield spawn(watchFetchAllFormsChannel);
}
// use registerUniqueAction for listBased sagas, otherwise they will be duplicated because of handleFolderSelect function in main/folder.js file
