/**
 * Determine if two nodes have parent relationship between each other.
 * @function
 * @param  {HTMLElement} parentNode Parent node
 * @param  {HTMLElement} childNode  Child node
 * @return {boolean}                Return status
 */
export const isParentNode = (parentNode, childNode) => {
  let sourceNode = childNode;

  while (sourceNode.parentNode) {
    if (sourceNode.parentNode !== parentNode) {
      sourceNode = sourceNode.parentNode;
    } else {
      return true;
    }
  }
  return false;
};
