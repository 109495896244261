import { createRoot } from 'react-dom/client';
import React, { useEffect } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string, func } from 'prop-types';
import './styles/launch.scss';
// import SparkleSvg from '../../assets/campaigns/teams/sparkle.svg';

export const LaunchBanner = ({
  utmSource, accountTypeName, username, tracker = f => f
}) => {
  const target = 'encryptedFormsMyformsHeaderBanner';

  useEffect(() => {
    tracker({ action: 'seen', target, actor: username });
  }, []);

  return (
    <>
      <div className="launch-container">
        <div className="campaign-content-item">
          <picture className="donation">
            <source srcSet="https://cdn.jotfor.ms/assets/img/campaigns/2023/donation-apps/donation-new.gif?v=1" media="(prefers-reduced-motion: no-preference)" />
            <img
              alt="{t('Announcing Donation Apps')}"
              width="100%" height="auto" loading="lazy"
              decoding="async" src="https://cdn.jotfor.ms/assets/img/landing/blank.png" className="lazyloaded"
              data-pin-no-hover="true"
            />
          </picture>
          <div className="item text locale">
            {translationRenderer('Announcing [1[Donation Apps]]')({
              renderer1: str => (<strong>{str}</strong>)
            })}
          </div>
          <div className="item button">
            <a
              className="locale"
              target="_blank"
              onClick={() => tracker({ action: 'click', target, actor: username })}
              href={`/products/apps/donation/?utm_campaign=jotform-donation-apps&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_term=${username}`}
            >
              {t('Discover Now')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

LaunchBanner.propTypes = {
  tracker: func.isRequired,
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderLaunch = ({
  container, accountTypeName, utmSource, username
}) => {
  let tracker = f => f;
  if (window.JotFormActions) {
    ({ tick: tracker } = window.JotFormActions('encryptedFormsLaunchMyformsAsset'));
  }
  const root = createRoot(container);
  root.render(
    <LaunchBanner
      tracker={tracker}
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
