import { render } from 'react-dom';
import React, { Suspense } from 'react';
import { Loading } from '@jotforminc/loading';

const renderLazy = ({
  root,
  callback,
  children,
  fallback = <Loading />
}) => render(
  <Suspense fallback={fallback}>
    {children}
  </Suspense>,
  root,
  callback
);

export default renderLazy;
