import React, {
  forwardRef, useRef, FunctionComponent
} from 'react';
import { IconXmark } from '@jotforminc/svg-icons';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { Hooks } from '@jotforminc/uikit';
import { HeaderProps, RightPanelPropType } from './types';
import './styles/RightPanel.scss';

const PanelHeader: FunctionComponent<HeaderProps> = ({ title, onClick, headerIcon }: HeaderProps): JSX.Element => (
  <div className="jfRightPanel-header">
    <div className="jfRightPanel-title">
      {headerIcon}
      {title}
    </div>
    <button
      className="jfRightPanel-close"
      onClick={onClick}
      type="button"
      aria-label={t('Close')}
    >
      <IconXmark />
    </button>
  </div>
);

const RightPanel = forwardRef<FunctionComponent, RightPanelPropType>((props, ref): JSX.Element => {
  const {
    isVisible,
    asideClass,
    wrapperClass,
    bodyClass,
    onClose,
    RightPanelHeader,
    panelTitle,
    clickableRefList,
    appName,
    isPanelHeaderHidden,
    closeOnClickOutside,
    theme,
    headerIcon,
    children,
    CustomPanelHeader,
    ...otherProps
  } = props;
  const innerRef = useRef();
  const panelRef = Hooks.useCombinedRefs(innerRef, ref);
  Hooks.useClickOutsideStateWithCallback({
    refs: clickableRefList ? [...clickableRefList, panelRef] : [panelRef],
    callback: closeOnClickOutside ? onClose : null
  });
  return (
    <aside
      ref={panelRef}
      className={classNames(
        'jfRightPanel',
        asideClass,
        { isPanelVisible: isVisible },
        { [`for${appName}`]: appName },
        theme
      )}
      {...otherProps}
    >
      <div className={classNames('jfRightPanel-wrapper', wrapperClass)}>
        {!isPanelHeaderHidden
          ? RightPanelHeader && <RightPanelHeader title={panelTitle} onClick={() => onClose()} headerIcon={headerIcon} />
          : CustomPanelHeader && <CustomPanelHeader />}
        <div className={classNames('jfRightPanel-body', bodyClass)}>
          {children}
        </div>
      </div>
    </aside>
  );
});

RightPanel.defaultProps = {
  isVisible: false,
  asideClass: '',
  wrapperClass: '',
  bodyClass: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  RightPanelHeader: PanelHeader,
  panelTitle: '',
  clickableRefList: [],
  appName: '',
  isPanelHeaderHidden: false,
  closeOnClickOutside: true,
  headerIcon: null,
  theme: '',
  children: null,
  CustomPanelHeader: null
};

export default RightPanel;
