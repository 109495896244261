import afZA from 'date-fns/locale/af';
import trTR from 'date-fns/locale/tr';
import arAR from 'date-fns/locale/ar';
import bgBG from 'date-fns/locale/bg';
import caES from 'date-fns/locale/ca';
import daDA from 'date-fns/locale/da';
import deDE from 'date-fns/locale/de';
import elGR from 'date-fns/locale/el';
import enUS from 'date-fns/locale/en-US';
import esES from 'date-fns/locale/es';
import fiFI from 'date-fns/locale/fi';
import frFR from 'date-fns/locale/fr';
import heIL from 'date-fns/locale/he';
import huHU from 'date-fns/locale/hu';
import idID from 'date-fns/locale/id';
import itIT from 'date-fns/locale/it';
import jaJP from 'date-fns/locale/ja';
import kaGE from 'date-fns/locale/ka';
import koKR from 'date-fns/locale/ko';
import nlNL from 'date-fns/locale/nl';
import noNO from 'date-fns/locale/nb';
import plPL from 'date-fns/locale/pl';
import ptBR from 'date-fns/locale/pt-BR';
import ptPT from 'date-fns/locale/pt';
import roRO from 'date-fns/locale/ro';
import ruRU from 'date-fns/locale/ru';
import svSE from 'date-fns/locale/sv';
import srSP from 'date-fns/locale/sr';
import ukUA from 'date-fns/locale/uk';
import zhCN from 'date-fns/locale/zh-CN';
import zhHK from 'date-fns/locale/zh-HK';

const locales = {
  trTR,
  afZA,
  arAR,
  bgBG,
  caES,
  daDA,
  deDE,
  elGR,
  enUS,
  esES,
  fiFI,
  frFR,
  heIL,
  huHU,
  idID,
  itIT,
  jaJP,
  kaGE,
  koKR,
  nlNL,
  noNO,
  ptBR,
  plPL,
  ptPT,
  roRO,
  ruRU,
  srSP,
  srBA: srSP,
  svSE,
  ukUA,
  zhCN,
  zhHK
};

/**
 * Set the language of the react-datepicker instances
 * in the package.
 * @param {object} props
 * @param {string} props.locale Language code for the user language
 * in the ISO 639-1 dash ISO 3166‑1 format, for instance en-US, tr-TR.
 * @param {(locale: string, locale: Locale) => void} props.registerLocale
 * function to register a locale to the react-datepicker instance.
 * @param {(locale: string) => void} props.setDefaultLocale function to
 * set the default locale of react-datepicker.
 */
export const setDatepickerLocale = ({ locale, registerLocale, setDefaultLocale }) => {
  // Parse away '-' to search
  const searchFor = locale.replace('-', '');
  if (locales[searchFor]) {
    // Check if our locale exists, register and set it.
    registerLocale(locale, locales[searchFor]);
    setDefaultLocale(locale);
  } else {
    // If it does not exist, register en-US.
    registerLocale('en-US', locales.enUS);
    setDefaultLocale('en-US');
  }
};
