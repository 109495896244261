import '../../styles/Activity.scss';
import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { t } from '@jotforminc/translation';

import './SummaryCard.scss';

const CardLoading = () => (
  <div className="jfSummaryCard-loading">
    <div className="jfSummaryCard-loading-line">
      <div className="jfSummaryCard-loading-line-inner" />
    </div>
  </div>
);

type SummaryCardProps = {
  activeCard?: string | null,
  card?: string,
  onClick?: (card: string) => void,
  background?: ReactNode,
  title?: string,
  data?: { [key: string]: any },
  text?: string,
  hasLoading?: boolean,
  className?: string
};

const SummaryCard = ({
  activeCard,
  card = '',
  onClick = () => {},
  background,
  title = '',
  data = {},
  text,
  hasLoading,
  className
}: SummaryCardProps): ReactElement => {
  const isActive = activeCard === card;
  const handleClick = () => {
    onClick(card);
  };
  const showTooltip = typeof data.exactNumber !== 'undefined' && data.exactNumber !== data.number;
  return (
    <div onClick={handleClick} className={classNames('jfSummaryCard', { [card]: true }, { isActive })} tabIndex={0}>
      <div className={classNames('jfSummaryCard-body', className)}>
        {background && <div className="jfSummaryCard-background">{background}</div>}
        <div className="jfSummaryCard-row forTitle">
          <div className="jfSummaryCard-title">{t(title)}</div>
        </div>
        <div className="jfSummaryCard-row forStatistics">
          {isEmpty(data)
            ? (hasLoading && <CardLoading />)
            : (
              <>
                <div className="jfSummaryCard-data">
                  {data.number}
                  {data.unit && <span className="forUnit">{data.unit}</span>}
                  {showTooltip && (
                    <span className="forExact-number">
                      {data.exactNumber}
                      <span className="forExact-text">{t('Total')}</span>
                    </span>
                  )}
                </div>
                {text && <div className="jfSummaryCard-text">{t(text)}</div>}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

SummaryCard.defaultProps = {
  activeCard: '',
  card: '',
  onClick: () => {},
  background: null,
  title: '',
  data: {},
  text: '',
  hasLoading: false,
  className: ''
};

export default SummaryCard;
