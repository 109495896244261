import React from 'react';
import { node, shape, any } from 'prop-types';
import DesktopWrapper from './DesktopWrapper';

const ResponsiveAdapter = ({ children, popoverProps }) => {
  return (
    <DesktopWrapper {...popoverProps}>{children}</DesktopWrapper>
  );
};

ResponsiveAdapter.propTypes = {
  children: node,
  popoverProps: shape({
    containerRef: shape({ current: any }).isRequired,
    targetRef: shape({ current: any }).isRequired
  }).isRequired
};

ResponsiveAdapter.defaultProps = {
  children: null
};

export default ResponsiveAdapter;
