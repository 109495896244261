/* eslint-disable react/prop-types */
import React from 'react';
import Styled from 'styled-components';
import Container from '../Container';

export const LoaderSvg = Styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;
  .linee {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2.6px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: backwards;
    animation-timing-function: cubic-bezier(0.01, 0.63, 0.1, 0.99);
    stroke-dasharray: 10px;
    stroke-dashoffset: 10px;
    &.line1 {
      animation-name: drawLine2;
    }
    &.line2 {
      animation-name: drawLine3;
    }
    &.line3 {
      animation-name: drawLine1;
    }
    @keyframes drawLine3 {
      0% {
        stroke-dashoffset: 10px;
      }
      15% {
        stroke-dashoffset: 10px;
      }
      25% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes drawLine2 {
      0% {
        stroke-dashoffset: 10px;
      }
      10% {
        stroke-dashoffset: 10px;
      }
      20% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes drawLine1 {
      0% {
        stroke-dashoffset: 10px;
      }
      5% {
        stroke-dashoffset: 10px;
      }
      15% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
  }
  .paper {
    animation-name: scalePaper;
    animation-duration: 1.5s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.01, 0.63, 0.1, 0.99);
    @keyframes scalePaper {
      0% {
        transform: scale(1);
      }
      10% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.2);
      }
      30% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

const Loader = () => {
  return (
    <Container>
      <LoaderSvg
        style={{ overflow: 'visible' }}
        width="20"
        height="25"
        viewBox="0 0 20 25"
        fill="none"
      >
        <g className="paper">
          <path
            d="M2.92241 24.6578H16.8039C18.4179 24.6578 19.7263 23.2779 19.7263 21.5756V5.81814L14.0605 0H2.92241C1.30841 0 0 1.37996 0 3.08223V21.5756C0 23.2779 1.30841 24.6578 2.92241 24.6578Z"
            fill="#D3DEF9"
          />
          <path
            d="M14.0601 0L19.7301 5.81814H16.7693C15.4075 5.81814 14.0601 4.71417 14.0601 3.35236V0Z"
            fill="#B1C3E9"
          />
        </g>
        <line
          stroke="#0099FF"
          className="linee line1"
          x1="4.97"
          y1="8.37"
          x2="14.64"
          y2="8.37"
        />
        <line
          stroke="#FF6100"
          className="linee line2"
          x1="4.97"
          y1="12.77"
          x2="14.64"
          y2="12.77"
        />
        <line
          stroke="#FFB629"
          className="linee line3"
          x1="4.97"
          y1="17.17"
          x2="14.64"
          y2="17.17"
        />
      </LoaderSvg>
    </Container>
  );
};

export default Loader;
