/* eslint-disable max-statements */
/* eslint-disable complexity */
import {
  all, call, takeLatest, put, select, take
} from 'redux-saga/effects';
import isObject from 'lodash/isObject';
import Tracking from '@jotforminc/tracking';
import { translation } from '@jotforminc/translation';
import { setCookie, handleCustomNavigation, isSalesforceEnv } from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { ABTestManager } from '@jotforminc/abtest-manager';

import * as API from '../../api';
import { redirectToLoginPage, redirectToLogoutPage, canAccessMyAgentsPage } from '../../utils';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { getUserPermissions } from '../../utils/permissions';
import { SELECTORS } from '../../store/selectors';

import { ACTION_TYPES } from '../../store/actionTypes';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import { LISTING_TYPES, NEW_USER_MIXED_LISTING_TEST_LIMIT_FEATURE_DATE } from '../../constants';

export function* initializeTranslation(language) {
  try {
    if (!language || language.toLowerCase() === 'en-us') {
      return false;
    }
    yield call(translation.addDictionary, language);
  } catch (e) {
    console.log('Error while fetching translations');
    console.error(e);
    return false;
  }
}

export function initializeFullStoryIdentifier(email) {
  if (!window?.FS?.identify || !email) {
    return;
  }

  window.FS.identify(email, { email });
  window.FS.setUserVars({ usesListings_bool: true });
}

export function initializeSentryIdentifier(email) {
  if (window.errorTracking === true) {
    Tracking.configureGlobalScope(scope => {
      scope.setExtra({ user: email });
    });
  }
}

export function initializeCampaings() {
  return {
    campaingType: window.campaingType,
    availableCampaingList: window.availableCampaingList
  };
}

export function* getPropertiesByUserType(userType) {
  if (userType !== 'USER') return {};

  const properties = {};
  try {
    const sharedAccounts = yield call(API.fetchUserOwners);
    properties.sharedAccounts = sharedAccounts;
  } catch (err) {
    // error
  }

  try {
    const systemPlans = yield call(API.fetchUserPlans);
    properties.systemPlans = systemPlans;
    yield put(ACTION_CREATORS.fetchSystemPlansSuccess());
  } catch (err) {
    yield put(ACTION_CREATORS.fetchSystemPlansError());
    yield take(ACTION_TYPES.FETCH_SYSTEM_PLANS.SUCCESS); // do not continue
    // error
  }

  return properties;
}

function* initializeAssetCreationTests(user) {
  const currentPage = yield select(SELECTORS.getCurrentPage);

  if (window.innerWidth <= 650 || currentPage !== LISTING_TYPES.FORM) return;

  const { credentials } = user;
  const userAccountType = credentials?.accountType || credentials?.account_type;
  const userAccountTypeName = userAccountType && isObject(userAccountType) ? userAccountType.name : userAccountType;
  const {
    isAppFirstUser, allowMixedListing, mixedListingAbTestNewUser, mixedListingAbTestExistingUser
  } = credentials || {};

  const initializeNewSideBySideCreationFlowTest = async () => {
    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'sideBySideCreationFlow',
      controlVariantCode: '28622',
      testVariantCode: '28632',
      urlParam: 'sideBySideCreation',
      user: credentials,
      customUserChecks: {
        isNotMixedUser: !allowMixedListing,
        isNotMixedListingAbTestNewUser: mixedListingAbTestNewUser !== '1',
        isNotMixedListingAbTestExistingUser: mixedListingAbTestExistingUser !== '1',
        isNotAppFirstUser: !isAppFirstUser,
        isNotGuestUser: userAccountTypeName !== 'GUEST',
        isNotFromSalesforceApp: !isSalesforceEnv()
      }
    });

    try {
      const isTestVariant = await abTestManager.isTestVariant();
      const logAbTestAction = abTestManager.registerABTestAction;
      return { isTestVariant, logAbTestAction };
    } catch (error) {
      return { isTestVariant: false, logAbTestAction: f => f };
    }
  };

  const {
    isTestVariant, logAbTestAction
  } = yield call(initializeNewSideBySideCreationFlowTest);

  if (isTestVariant) {
    yield put(ACTION_CREATORS.updateUser({ isSideBySideCreationFlowTestUser: true, logAbTestAction }));
  }
}

function* initializeMyworkspaceTests(user) {
  const currentPage = yield select(SELECTORS.getCurrentPage);
  const isSideBySideCreationFlowTestUser = yield select(SELECTORS.getIsSideBySideCreationFlowTestUser);

  const { credentials } = user;
  const userAccountType = credentials?.accountType || credentials?.account_type;
  const userAccountTypeName = userAccountType && isObject(userAccountType) ? userAccountType.name : userAccountType;

  if (userAccountTypeName === 'ADMIN') return;

  const {
    username, isAppFirstUser, allowBoards, mixedListingBetaUser, mixedListingAbTestNewUser, mixedListingAbTestExistingUser
  } = credentials || {};

  const runMixedListingAbTestNewUser = async () => {
    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'mixedListingAbTestNewUser',
      controlVariantCode: '28752',
      testVariantCode: '28762',
      urlParam: 'mixedListingAbTestNewUser',
      user: credentials,
      customUserChecks: {
        isAllowedUserForTest: mixedListingAbTestNewUser === '1' || mixedListingBetaUser !== '1',
        isNewSignup: credentials?.created_at && new Date(credentials.created_at) >= new Date(NEW_USER_MIXED_LISTING_TEST_LIMIT_FEATURE_DATE),
        isNotMixedListingAbTestExistingUser: mixedListingAbTestExistingUser !== '1',
        isNotSideBySideCreationFlowUser: !isSideBySideCreationFlowTestUser,
        isNotBoardsUsers: !allowBoards,
        isNotAppFirstUser: !isAppFirstUser,
        isNotGuestUser: userAccountTypeName !== 'GUEST',
        isNotFromSalesforceApp: !isSalesforceEnv()
      }
    });

    try {
      const isTestVariantNewUser = await abTestManager.isTestVariant();
      const logAbTestActionNewUser = abTestManager.registerABTestAction;
      return { isTestVariantNewUser, logAbTestActionNewUser };
    } catch (error) {
      return { isTestVariantNewUser: false, logAbTestActionNewUser: f => f };
    }
  };

  const runMixedListingAbTestExistingUser = async () => {
    const abTestManager = new ABTestManager({
      isTestEnabled: true,
      testName: 'mixedListingAbTestExistingUser',
      controlVariantCode: '28952',
      testVariantCode: '28962',
      urlParam: 'mixedListingAbTestExistingUser',
      user: credentials,
      customUserChecks: {
        isAllowedUserForTest: mixedListingAbTestExistingUser === '1' || mixedListingBetaUser !== '1',
        isExistingSignup: credentials?.created_at && new Date(credentials.created_at) < new Date(NEW_USER_MIXED_LISTING_TEST_LIMIT_FEATURE_DATE),
        isNotMixedListingAbTestNewUser: mixedListingAbTestNewUser !== '1',
        isNotSideBySideCreationFlowUser: !isSideBySideCreationFlowTestUser,
        isNotBoardsUsers: !allowBoards,
        isNotAppFirstUser: !isAppFirstUser,
        isNotGuestUser: userAccountTypeName !== 'GUEST',
        isNotFromSalesforceApp: !isSalesforceEnv()
      }
    });

    try {
      const isTestVariantExistingUser = await abTestManager.isTestVariant();
      const logAbTestActionExistingUser = abTestManager.registerABTestAction;
      return { isTestVariantExistingUser, logAbTestActionExistingUser };
    } catch (error) {
      return { isTestVariantExistingUser: false, logAbTestActionExistingUser: f => f };
    }
  };

  const {
    isTestVariantNewUser, logAbTestActionNewUser
  } = yield call(runMixedListingAbTestNewUser);

  if (isTestVariantNewUser) {
    window.mixedListingAbTestLogger = logAbTestActionNewUser;
    Tracking.enableFS({ mixedListingAbTestNewUser_bool: true });

    if (mixedListingAbTestNewUser !== '1') {
      try {
        yield call(API.updateUserSettings, { mixedListingAbTestNewUser: 1 });
      } catch {
        const data = {
          project: 'mixedListingAbTestNewUser',
          actor: username
        };
        API.actionTrack(data);
      }
    }

    if (currentPage !== LISTING_TYPES.MIX && !window.location.search.includes('preventWorkspaceRedirect')) handleCustomNavigation('/myworkspace/', '_self');
  } else {
    const {
      isTestVariantExistingUser, logAbTestActionExistingUser
    } = yield call(runMixedListingAbTestExistingUser);

    if (isTestVariantExistingUser) {
      window.mixedListingAbTestLogger = logAbTestActionExistingUser;
      Tracking.enableFS({ mixedListingAbTestExistingUser_bool: true });

      if (mixedListingAbTestExistingUser !== '1') {
        try {
          yield call(API.updateUserSettings, { mixedListingAbTestExistingUser: 1 });
        } catch {
          const data = {
            project: 'mixedListingAbTestExistingUser',
            actor: username
          };
          API.actionTrack(data);
        }
      }

      if (currentPage !== LISTING_TYPES.MIX && !window.location.search.includes('preventWorkspaceRedirect')) handleCustomNavigation('/myworkspace/', '_self');
    }
  }
}

function* fetchPermissions() {
  try {
    const permissions = yield call(API.fetchPermissionInfoUser);
    yield put(ACTION_CREATORS.fetchUserPermissionsSuccess());
    return permissions;
  } catch (err) {
    yield put(ACTION_CREATORS.fetchUserPermissionsError());
  }
}

function* handleFetchPermissions(iteration = 0) {
  let permissions = yield call(fetchPermissions);

  if (!permissions && iteration < 4) {
    permissions = yield call(handleFetchPermissions, iteration + 1);
  }
  return permissions;
}

export function* initializeUser() {
  const user = yield call(API.fetchUser);
  const { type, credentials } = user;

  const { mixedListingBetaUser } = credentials;

  if (mixedListingBetaUser && mixedListingBetaUser === '1') {
    Tracking.enableFS({ mixedListingBetaUser_bool: true });
  }

  if (window.JOTFORM_ENV === 'ENTERPRISE' && credentials?.account_type?.name === 'GUEST') {
    yield call(redirectToLoginPage);
    return;
  }
  if (user?.code === '401' && window.localStorage.getItem('prevent401Redirect') !== 'yes') {
    yield call(redirectToLogoutPage);
    return;
  }
  const { reactivationWarning } = credentials;

  if (reactivationWarning) {
    window.limitWarningAlignment = 'left_alt';
  }

  const currentPage = yield select(SELECTORS.getCurrentPage);
  const currentTeamID = yield select(SELECTORS.getCurrentTeamID);
  if (currentPage === LISTING_TYPES.DOCUMENT && isEnterprise() && !credentials?.allowSign) {
    handleCustomNavigation('/myforms', '_self');
    return;
  }
  if (currentPage === LISTING_TYPES.TEAM_PAGE && !currentTeamID) {
    yield take(ACTION_TYPES.SET_URL_ID);
  }
  if (currentPage === LISTING_TYPES.AGENT && !canAccessMyAgentsPage(credentials)) {
    handleCustomNavigation('/myforms', '_self');
    return;
  }

  const getTranslationLanguage = () => {
    try {
      return window?.currentLocale || credentials?.language;
    } catch (error) {
      console.log('Error in getTranslationLanguage', error);
    }
  };

  const permissions = yield call(handleFetchPermissions);

  if (!permissions) {
    yield take(ACTION_TYPES.FETCH_USER_PERMISSIONS.SUCCESS); // do not continue
  }

  // Don't wait initial load following values fetched on back-end
  const [{ campaingType, availableCampaingList }] = yield all([
    call(initializeCampaings),
    call(initializeTranslation, getTranslationLanguage()),
    call(initializeFullStoryIdentifier, credentials?.email),
    call(initializeSentryIdentifier, credentials?.email),
    call(initializeAssetCreationTests, user),
    call(initializeMyworkspaceTests, user)
  ]);

  if (currentPage === LISTING_TYPES.AGENT) {
    const accountTypeName = credentials?.account_type?.name;
    if (accountTypeName === 'ADMIN') {
      Tracking.enableFS({ myAgentsListingAdmins_bool: true });
    } else {
      Tracking.enableFS({ myAgentsListingUsers_bool: true });
    }
  }

  let teamRoles = [];
  const isTeamMember = yield select(SELECTORS.getIsTeamMember);
  try {
    if (currentPage === LISTING_TYPES.TEAM_PAGE && isTeamMember) {
      teamRoles = yield call(API.fetchUserTeamRoles);
    }
  } catch (e) {
    console.log(e);
  }
  const userSettings = yield user.type !== 'FORM_USER' ? call(API.getUserSettings) : {};

  const newSheets = credentials.spreadsheets !== '10';
  const specialProperties = yield getPropertiesByUserType(user.type);

  yield put(ACTION_CREATORS.setUser({
    type,
    newSheets,
    credentials: {
      ...credentials,
      ...userSettings
    },
    campaingType,
    availableCampaingList,
    ...specialProperties,
    ...(getUserPermissions(user, window.JOTFORM_ENV, permissions, !!currentTeamID)),
    teamRoles
  }));
  WatchmanRecorder.setUser(credentials?.username);
  WatchmanRecorder.setUserForCustomEvents(credentials?.username);

  // set browser language as cookie for language switch offer notification
  const navLang = window.navigator.userLanguage || window.navigator.language;
  setCookie('navLang', navLang);
}

export function* rootUserFlow() {
  yield takeLatest(ACTION_TYPES.FETCH_USER.REQUEST, initializeUser);
}
