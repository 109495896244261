import React, { ChangeEventHandler, ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { t } from '@jotforminc/translation';
import '../../styles/SearchInput.scss';

type Props = {
  placeholder?: string,
  onChange: ChangeEventHandler<HTMLInputElement>
} & ComponentPropsWithoutRef<'input'>;

const SearchInput: FunctionComponent<Props> = ({
  placeholder,
  onChange,
  ...props
}) => (
  <>
    <input
      type='text'
      className='jfInput-text'
      placeholder={placeholder}
      onChange={onChange}
      {...props}
    />
  </>
);

SearchInput.defaultProps = {
  placeholder: t('Search')
};

export default SearchInput;
