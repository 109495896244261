/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
import React, {
  forwardRef, useMemo, useRef, useState, useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconExclamationCircleFilled,
  IconFolderFilled,
  IconChevronDown,
  IconAngleRightCircleFilled,
  IconClockArrowRotateRight,
  IconMagnifyingGlass,
  IconXmark,
  IconBoxArchiveArrowDownFilled,
  IconTrashFilled,
  IconStarFilled,
  IconEllipsisVertical
} from '@jotforminc/svg-icons';
import classNames from 'classnames';
import { bool } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { t } from '@jotforminc/translation';
import { Button } from '@jotforminc/uikit';
import { Button as MagnetButton, InputText } from '@jotforminc/magnet';
import { handleCustomNavigation } from '@jotforminc/utils';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';

import { Dropdown } from '../../Dropdown';

import {
  CLASS_MAP, FEATURE_LIST, FOLDER_TYPES, getListingNames, getSearchables, LISTING_TYPES, NAMING_MAP, STATUS_FOLDERS, TEAM_USER_ROLES, DEFAULT_FOLDER_IDS, TOOLBAR_MORE_ACTIONS
} from '../../../constants';

import translate from '../../../utils/translate';
import ActionButtonList from './ActionButtonList';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';
import Feature from '../../../containers/Feature';
import Image from '../../Image';
import { SELECTORS } from '../../../store/selectors';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import NewSortMenu from '../../SortMenu';
import SortMenu from '../../SortMenu/SortMenu';
import MultipleAssetFilterDropdown from './MultipleAssetFilterDropdown';

// eslint-disable-next-line complexity
const ListingToolbar = ({
  allSelected,
  selectedFolder,
  filter,
  isSelected,
  form,
  sortingOptions,
  orderby,
  filterForm,
  clearFormFilter,
  toggleFoldersVisibility,
  showFilterBar,
  setToolbar,
  setFullText,
  handleSelectAll,
  handleSubmissions,
  handleMultipleDelete,
  handleMultipleRestore,
  handleMultipleTrash,
  handleSortingSelect,
  handleMultipleArchive,
  handleMultipleStar,
  handleMultipleEnable,
  handleMultipleDisable,
  handleMultipleMarkAsRead,
  isActionButtonsAllowed = true,
  isOwnerOfSelectedFolder = true,
  teamFilter,
  isAddToFolderEnabled = false,
  isMoveToTeamEnabled = false,
  isMoveFromTeamEnabled = false,
  selectedSorting
}) => {
  const [isTeamOptionVisible, setTeamOptionVisible] = useState(false);
  const moreMenuRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const filterText = useMemo(() => filter?.fullText || '', [filter]);
  const folderType = useSelector(SELECTORS.getFolderType);
  const currentTeam = useSelector(SELECTORS.getCurrentTeam);
  const teams = useSelector(SELECTORS.getTeams);
  const isActivityPanelOpen = useSelector(SELECTORS.getIsTeamActivityPanelOpen);
  const userRolesForTeam = useSelector(SELECTORS.getUserRolesForTeam);
  const isTeamMember = useSelector(SELECTORS.getIsTeamMember);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const assetFilterType = useSelector(SELECTORS.getAssetFilterType);
  const isTeamFeatureEnabled = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.JOTFORM_TEAMS));
  const isNewCommonSortMenuActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.COMMON_SORT_MENU));
  const accountTypeName = useSelector(SELECTORS.getUserAccountTypeName);
  const user = useSelector(SELECTORS.getUserCredentials);
  const isGAMBannerAvailable = useSelector(SELECTORS.getGAMBannerAvailable);

  const handleInputChange = () => {
    WatchmanRecorder.trackEvent('click', 'clearSearchTextButton', 'myformsFormToolbar');
    setFullText('');
  };

  const handleMoreOptionSelect = option => {
    switch (option) {
      case TOOLBAR_MORE_ACTIONS.ARCHIVE:
        handleMultipleArchive();
        WatchmanRecorder.trackEvent('click', 'archiveSelection', 'myformsFormToolbar', true);
        break;
      case TOOLBAR_MORE_ACTIONS.UNARCHIVE:
        handleMultipleRestore();
        WatchmanRecorder.trackEvent('click', 'unarchiveSelection', 'myformsFormToolbar', true);
        break;
      case TOOLBAR_MORE_ACTIONS.STAR:
        handleMultipleStar('1', false);
        break;
      case TOOLBAR_MORE_ACTIONS.UNSTAR:
        handleMultipleStar('0', selectedFolder === DEFAULT_FOLDER_IDS.FAVORITES);
        break;
      case TOOLBAR_MORE_ACTIONS.ENABLE:
        handleMultipleEnable();
        WatchmanRecorder.trackEvent('click', 'enableSelection', 'myformsFormToolbar', true);
        break;
      case TOOLBAR_MORE_ACTIONS.DISABLE:
        handleMultipleDisable();
        WatchmanRecorder.trackEvent('click', 'disableSelection', 'myformsFormToolbar', true);
        break;
      case TOOLBAR_MORE_ACTIONS.MARK_AS_READ:
        handleMultipleMarkAsRead();
        WatchmanRecorder.trackEvent('click', 'markReadSelection', 'myformsFormToolbar', true);
        break;
      default:
        break;
    }
  };

  const sortingOptionList = selectedFolder === DEFAULT_FOLDER_IDS.SHARED_REPORTS ? sortingOptions.filter(opt => opt.view !== 'grouped') : sortingOptions;
  const hasSortingOptionList = !!sortingOptionList.length;
  const isUserTeamAdmin = userRolesForTeam && userRolesForTeam.includes(TEAM_USER_ROLES.team_admin);
  const hasPermissionForActionButtons = folderType === FOLDER_TYPES.TEAM || isOwnerOfSelectedFolder || currentPage !== LISTING_TYPES.FORM || isUserTeamAdmin;
  const isClosedBanner = STATUS_FOLDERS.indexOf(selectedFolder) > -1;

  const searchablePlaceholder = getSearchables(assetFilterType)(!!currentTeam);

  const removeFormId = () => {
    history.push('/');
  };

  const handleSearchBarInputBlur = e => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      e.target.blur(); // Hide keyboard after user hits enter
    }
  };

  // eslint-disable-next-line no-shadow
  const OptionsRenderer = ({ option, isSelected }) => (
    <div className='team-option' data-value={option.id} data-selected={isSelected}>
      {(option.type === 'team') ? (
        <Image
          iconBgColor={option.teamAvatarIconBgColor}
          url={option.teamAvatarURL}
          icon={option.teamAvatarIcon}
          emojiId={option.teamAvatarEmojiId}
          emojiSize={24}
          iconColor={option.teamAvatarIconColor}
          iconSvgRef={option.teamAvatarIconSvgRef}
          hasCustomization={false}
          isVisible={true}
        />
      ) : (
        <div className='default-icon'>
          {option.icon}
        </div>
      )}
      <span>{option.text}</span>
    </div>
  );

  const handleGoToTeamPage = () => {
    WatchmanRecorder.trackEventForCustomProject('click', 'myFormsRedirectingToTeamPage', 'teams', true);
    setTimeout(() => {
      handleCustomNavigation(`/myteams/${currentTeam?.slug || currentTeam?.id}`, '_self');
    }, 400);
  };

  const handleClickToTeamActivity = () => {
    WatchmanRecorder.trackEventForCustomProject('click', 'teamPageTeamActivityLogButton', 'teams', true);
    dispatch(ACTION_CREATORS.toggleTeamActivityPanel(!isActivityPanelOpen));
  };

  useEffect(() => {
    const closeHeaderBannerClassName = 'closeHeaderBannerForTeams';
    if (isTeamFeatureEnabled && currentPage !== LISTING_TYPES.TEAM_PAGE && (folderType === FOLDER_TYPES.TEAM || folderType === FOLDER_TYPES.TEAM_FOLDER)) {
      document.body.classList.add(closeHeaderBannerClassName);
    } else {
      document.body.classList.remove(closeHeaderBannerClassName);
    }
  }, [folderType]);

  useEffect(() => {
    const closeHeader2FABannerClassName = 'closeHeaderBannerFor2FA';
    if (isClosedBanner) {
      document.body.classList.add(closeHeader2FABannerClassName);
    } else {
      document.body.classList.remove(closeHeader2FABannerClassName);
    }
  }, [selectedFolder]);

  const iconStyle = 'w-4 h-4 shrink-0';

  const checkDesignSystemIcon = folderId => {
    let currentIcon;
    switch (folderId) {
      case DEFAULT_FOLDER_IDS.FAVORITES:
        currentIcon = <IconStarFilled aria-hidden="true" className={classNames('color-yellow-300', iconStyle)} />;
        break;
      case DEFAULT_FOLDER_IDS.TRASH:
        currentIcon = <IconTrashFilled aria-hidden="true" className={classNames('color-navy-300', iconStyle)} />;
        break;
      case DEFAULT_FOLDER_IDS.ARCHIVE:
        currentIcon = <IconBoxArchiveArrowDownFilled aria-hidden="true" className={classNames('color-navy-300', iconStyle)} />;
        break;
      default:
        currentIcon = <IconFolderFilled aria-hidden="true" className={classNames('color-navy-300', iconStyle)} />;
        break;
    }
    return currentIcon;
  };

  if (window.location.search.includes('listingTestError')) {
    throw new Error('Listings Test Error');
  }

  return (
    <>
      {showFilterBar && (
      <div
        className={classNames('lsApp-toolbar', { isSelected })}
        ref={setToolbar}
        data-sc="searchBar"
      >
        <div id="lsApp-toolbar-campaign-container">
          {isGAMBannerAvailable && <GrowthAssetManager assetType="banner" assetProps={{ accountTypeName, user }} />}
        </div>
        <div className="sb-content">
          {form && (
            <div className="sb-filter-form">
              <span title={form.title}>{form.title}</span>
              <button type='button' onClick={removeFormId}>x</button>
            </div>
          )}
          <MagnetButton
            className="sb-folderToggle"
            colorStyle="secondary"
            variant="outline"
            startIcon={IconEllipsisVertical}
            aria-label="Folder Toggle"
            onClick={() => toggleFoldersVisibility(true)}
          />
          <div className='sb-bulkActions'>
            {filterForm && (
            <MagnetButton
              colorStyle="secondary"
              variant="outline"
              endIcon={IconXmark}
              className="forFilterButton"
              onClick={clearFormFilter}
            >
              {filterForm.title}
            </MagnetButton>
            )}
            {(isActionButtonsAllowed && hasPermissionForActionButtons) ? (
              <ActionButtonList
                moreMenuRef={moreMenuRef}
                handleSubmissions={handleSubmissions}
                handleMultipleTrash={handleMultipleTrash}
                handleMultipleDelete={handleMultipleDelete}
                handleMultipleRestore={handleMultipleRestore}
                handleMoreOptionSelect={handleMoreOptionSelect}
                isAddToFolderEnabled={isAddToFolderEnabled}
                isMoveToTeamEnabled={isMoveToTeamEnabled}
                isMoveFromTeamEnabled={isMoveFromTeamEnabled}
              />
            ) : (
              <Feature
                name={FEATURE_LIST.JOTFORM_TEAMS}
                extraRequirements={
                  currentPage !== LISTING_TYPES.TEAM_PAGE
                  && (
                    folderType === FOLDER_TYPES.TEAM
                    || folderType === FOLDER_TYPES.TEAM_FOLDER
                  )
                }
                inactiveComponent={null}
                activeComponent={(
                  <div className="lsApp-teamToolbar-wrapper">
                    <Image
                      iconBgColor={currentTeam?.teamAvatarIconBgColor}
                      url={currentTeam?.teamAvatarURL}
                      icon={currentTeam?.teamAvatarIcon}
                      emojiId={currentTeam?.teamAvatarEmojiId}
                      emojiSize={18}
                      iconColor={currentTeam?.teamAvatarIconColor}
                      iconSvgRef={currentTeam?.teamAvatarIconSvgRef}
                      hasCustomization={false}
                    />
                    <div className="teamTitle">
                      <button type="button" className="teamTitle-button" onClick={handleGoToTeamPage}>
                        <span className="teamTitle-name">{currentTeam?.name}</span>
                        <span className='teamTitle-gotoLink'>
                          {t('Team Workspace')}
                          <IconAngleRightCircleFilled />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              />
            )}
            <Feature
              name={FEATURE_LIST.JOTFORM_TEAMS}
              extraRequirements={
                currentPage !== LISTING_TYPES.TEAM_PAGE
                && STATUS_FOLDERS.indexOf(selectedFolder) > -1
                && teams.length > 0
                && !isActionButtonsAllowed
                && !isSelected
              }
              activeComponent={(
                <Dropdown
                  defaultValue={teamFilter}
                  options={[
                    {
                      text: t(getListingNames()[currentPage]), value: '', Renderer: OptionsRenderer, icon: <IconFolderFilled className="w-4 h-4 shrink-0" fill="#ffffff" />
                    },
                    ...teams.map(team => {
                      return ({
                        ...team, text: team.name || team.id, value: team.id, type: 'team', Renderer: OptionsRenderer
                      });
                    })
                  ]}
                  onOptionSelect={({ value }) => dispatch(ACTION_CREATORS.setTeamFilter(value))}
                  onOptionsVisibilityChanged={setTeamOptionVisible}
                  ButtonRenderer={forwardRef(({ option: { text, value, ...teamProps } = {}, placeholder, ...props }, ref) => (
                    <Button
                      ref={ref}
                      type="button"
                      className={classNames('forTeamDelete', { forTeamDeleteSelected: isTeamOptionVisible })}
                      data-value={value}
                      {...props}
                    >
                      {value ? (
                        <Image
                          iconBgColor={teamProps.teamAvatarIconBgColor}
                          url={teamProps.teamAvatarURL}
                          icon={teamProps.teamAvatarIcon}
                          emojiId={teamProps.teamAvatarEmojiId}
                          emojiSize={18}
                          iconColor={teamProps.teamAvatarIconColor}
                          iconSvgRef={teamProps.teamAvatarIconSvgRef}
                          hasCustomization={false}
                          isVisible={true}
                        />
                      ) : checkDesignSystemIcon(selectedFolder)}
                      {text || placeholder}
                      <IconChevronDown className="w-3 h-3 shrink-0" />
                    </Button>
                  ))}
                  popoverProps={{
                    popoverOptions: {
                      placement: 'bottom-start'
                    },
                    dontUpdate: true
                  }}
                  OptionContainerRenderer={props => <li tabIndex={0} {...props} />}
                />
              )}
              inactiveComponent={null}
            />
          </div>
          <div className={classNames('sb-listActions', { showToolbar: !(isActionButtonsAllowed && hasPermissionForActionButtons) })}>
            {/* TODO: make it work for all listing types */}
            {currentPage === LISTING_TYPES.REPORT && selectedFolder !== DEFAULT_FOLDER_IDS.SHARED_REPORTS && (
            <>
              {/* select all disabled for now */}
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={allSelected}
                style={{ display: 'none' }}
              />
            </>
            )}
            <MultipleAssetFilterDropdown />
            {hasSortingOptionList && isNewCommonSortMenuActive ? <NewSortMenu /> : '' }
            {hasSortingOptionList && !isNewCommonSortMenuActive ? (
              <SortMenu
                sortingOptions={sortingOptions}
                handleSortingSelect={handleSortingSelect}
                selectedSorting={selectedSorting}
                orderby={orderby}
                selectedFolder={selectedFolder}
              />
            ) : ''}
            {searchablePlaceholder && (
              <div className="sb-inputW min-w-60 flex-1">
                <InputText
                  className={classNames('sb-input items-center', { isEmptyInput: !filter.fullText })}
                  aria-label={t('Search')}
                  prefix={{ icon: IconMagnifyingGlass, 'aria-hidden': true }}
                  suffix={{
                    as: 'button', icon: IconXmark, className: 'pl-1 pr-2 inline-flex magnet-input-button', onClick: handleInputChange, 'aria-label': 'Clear Searchbar'
                  }}
                  placeholder={translate(searchablePlaceholder)}
                  value={filterText}
                  onChange={e => {
                    WatchmanRecorder.debouncedTrackEvent('change', 'updateSearchText', 'myformsFormToolbar');
                    setFullText(e.target.value);
                  }}
                  onKeyDown={e => handleSearchBarInputBlur(e)}
                />
              </div>
            )}
            <Feature
              name={FEATURE_LIST.JOTFORM_TEAMS}
              extraRequirements={!!isTeamMember && currentPage === LISTING_TYPES.TEAM_PAGE}
              inactiveComponent={null}
              activeComponent={(
                <MagnetButton
                  colorStyle="secondary"
                  variant="outline"
                  startIcon={IconClockArrowRotateRight}
                  className="sb-teamActivity"
                  onClick={handleClickToTeamActivity}
                >
                  {t('Activity')}
                </MagnetButton>
                )}
            />
          </div>
        </div>
      </div>
      )}
      {(assetFilterType !== LISTING_TYPES.DOCUMENT && selectedFolder === DEFAULT_FOLDER_IDS.TRASH)
        && (
          <div className="lsApp-message">
            <div className="lsApp-message-icon">
              <IconExclamationCircleFilled className="w-5 h-5" />
            </div>
            <div className="lsApp-message-text">{translate(`Deleted ${NAMING_MAP[CLASS_MAP[assetFilterType]]} will be permanently deleted after 30 days.`)}</div>
          </div>
        )}
    </>
  );
};

ListingToolbar.propTypes = {
  isActionButtonsAllowed: bool,
  isOwnerOfSelectedFolder: bool,
  isAddToFolderEnabled: bool,
  isMoveToTeamEnabled: bool,
  isMoveFromTeamEnabled: bool
};

export default ListingToolbar;
