import { AppIcons } from '@jotforminc/header-components';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { LISTING_TYPES } from '../../constants';
import { ALL_PERMISSIONS } from '../../utils/permissions';

export const TEXTS = {
  HEADER_TITLE: 'Select what you want to create'
};

export const PRODUCT_CONFIGS = [
  {
    name: 'Form',
    Icon: AppIcons?.formBuilder?.Selected,
    backgroundColor: '#FF6100',
    type: LISTING_TYPES.FORM,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FORM
  },
  {
    name: 'Table',
    Icon: AppIcons?.tables?.Selected,
    backgroundColor: '#149F43',
    type: LISTING_TYPES.SHEET,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_SHEET
  },
  {
    name: 'Report',
    Icon: AppIcons?.reports?.Selected,
    backgroundColor: '#344067',
    type: LISTING_TYPES.REPORT,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_REPORT
  },
  {
    name: window?.isWorkflowReleased ? 'Workflow' : 'Approval',
    Icon: AppIcons?.approvals?.Selected,
    backgroundColor: '#007862',
    type: LISTING_TYPES.TASK,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FLOW,
    createPermissionName: 'Approval'
  },
  {
    name: 'Sign Document',
    Icon: AppIcons?.sign?.Selected,
    backgroundColor: '#7BB60F',
    type: LISTING_TYPES.DOCUMENT,
    createPermissionName: 'Document',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_DOCUMENT,
    checkVisibility: ({ user }) => {
      return !isEnterprise() || user?.allowSign;
    }
  },
  {
    name: 'App',
    Icon: AppIcons?.portal?.Selected,
    backgroundColor: '#8D4ECC',
    type: LISTING_TYPES.PORTAL,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_APP
  }
];
