import { ACTION_TYPES } from '../actionTypes';
import {
  getNormalizedTeamProps, normalizeTeam, flattenFolders, constructFolderLayoutIDs
} from '../../utils';
import { FOLDER_TYPES } from '../../constants';

const initialState = {
  folders: [],
  folderType: '',
  selectedFolder: '',
  folderLayout: new Map(),
  isReorderFoldersAllowed: true,
  folderLayoutBackup: {},
  folderLayoutIDs: []
};

const removeFolder = (folders, folderIDToRemove) => {
  const newFolders = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    const folder = folders[i];
    if (folder.id === folderIDToRemove) {
      continue;
    }
    if (folder.subfolders) {
      folder.subfolders = removeFolder(folder.subfolders, folderIDToRemove);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

const addFolder = (folders, parentID, folderToAdd) => {
  const newFolders = [];
  if (!parentID) {
    // TODO Find a better way to handle main create a folder button
    return folders.reduce((prev, curr) => {
      if (curr.id === 'createFolder') {
        return [...prev, folderToAdd, curr];
      }
      return [...prev, curr];
    }, []);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    const folder = folders[i];
    if (folder.id === parentID) {
      if (!folder.subfolders?.length) folder.subfolders = [];
      folder.subfolders.push(folderToAdd);
    }
    if (folder.subfolders) {
      folder.subfolders = addFolder(folder.subfolders, parentID, folderToAdd);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

const modifyFolder = (folders, folderID, newAttributes) => {
  const newFolders = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    let folder = folders[i];
    if (folder.id === folderID) {
      folder = { ...folder, ...newAttributes };
    }
    if (folder.subfolders) {
      folder.subfolders = modifyFolder(folder.subfolders, folderID, newAttributes);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

const removeTeam = (folders, teamIDToRemove) => folders.filter(folder => folder.id !== teamIDToRemove);

const updateTeam = (folders, teamID, newProperties, currentPage, isAssetsFolderActive) => {
  const newFolders = folders.map(folder => {
    if (folder.id !== teamID) return folder;

    const updatedFolder = { ...folder, ...newProperties };
    const normalizedProps = getNormalizedTeamProps(updatedFolder, currentPage, isAssetsFolderActive);
    return {
      ...updatedFolder,
      ...normalizedProps
    };
  });

  return newFolders;
};

const traverseFolders = (folders, selectedFolder) => {
  const newFolders = [];
  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < folders.length; i++) {
    let folder = folders[i];
    if (folder.subfolders) {
      const flattenedFolders = flattenFolders(folder.subfolders);
      if (folder.id !== selectedFolder && flattenedFolders.find(f => f.id === selectedFolder)) {
        folder = { ...folder, canExpand: true };
      }
      folder.subfolders = traverseFolders(folder.subfolders, selectedFolder);
    }
    newFolders.push(folder);
  }
  return newFolders;
};

// eslint-disable-next-line complexity
export default function folderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_SELECTED_FOLDER: {
      const { folder, folderType } = action;
      return { ...state, selectedFolder: folder, folderType };
    }
    case ACTION_TYPES.SET_FOLDERS: {
      const { folders, selectedFolder } = action;
      return { ...state, folders: traverseFolders(folders, selectedFolder) };
    }
    case ACTION_TYPES.DELETE_FOLDER.REQUEST: {
      const { folderID } = action;
      return { ...state, folders: removeFolder(state.folders, folderID) };
    }
    case ACTION_TYPES.ADD_FOLDER.REQUEST: {
      const { name, color, parent } = action.data || action;
      const { teamID } = action;
      const tempFolder = {
        id: 'temp',
        name,
        color,
        text: name,
        hasIcon: true,
        isDroppable: true
      };
      return { ...state, folders: addFolder(state.folders, parent || teamID, tempFolder) };
    }
    case ACTION_TYPES.ADD_FOLDER.SUCCESS: {
      const { folder: folderResult } = action;
      return { ...state, folders: modifyFolder(state.folders, 'temp', folderResult) };
    }
    case ACTION_TYPES.ADD_FOLDER.ERROR: {
      return { ...state, folders: removeFolder(state.folders, 'temp') };
    }
    case ACTION_TYPES.UPDATE_FOLDERS.SUCCESS: {
      const { folderID, attributes } = action;
      return { ...state, folders: modifyFolder(state.folders, folderID, attributes) };
    }
    case ACTION_TYPES.UPDATE_FOLDER_LAYOUT.SUCCESS: {
      const { folderLayout } = action;
      const rootFolderLayout = folderLayout ? [...folderLayout.values()][0] : new Map();
      const folderLayoutIDs = rootFolderLayout ? constructFolderLayoutIDs(rootFolderLayout) : [];
      return { ...state, folderLayout, folderLayoutIDs };
    }
    case ACTION_TYPES.UPDATE_FOLDER_LAYOUT_BACKUP.SUCCESS: {
      const { folderLayoutBackup } = action;
      return { ...state, folderLayoutBackup };
    }
    case ACTION_TYPES.DELETE_TEAM.SUCCESS: {
      const { teamID } = action;
      return { ...state, folders: removeTeam(state.folders, teamID) };
    }
    case ACTION_TYPES.UPDATE_TEAM_PROPERTIES: {
      const {
        teamID, newProperties, currentPage, isAssetsFolderActive
      } = action;
      const updatedTeamFolders = updateTeam(state.folders, teamID, newProperties, currentPage, isAssetsFolderActive);
      return { ...state, folders: updatedTeamFolders };
    }
    case ACTION_TYPES.RESET_ALL_TEAM_PERMISSIONS: {
      const { folders } = state;
      const { currentPage, isAssetsFolderActive } = action;
      return {
        ...state,
        folders: folders.map(folder => {
          if (folder.folderType !== FOLDER_TYPES.TEAM) {
            return folder;
          }
          return normalizeTeam({
            team: { ...folder, permissions: null }, hasContextMenu: true, currentPage, isAssetsFolderActive
          });
        })
      };
    }
    case ACTION_TYPES.ALLOW_REORDER_FOLDERS: {
      const { isAllowed } = action;

      return { ...state, isReorderFoldersAllowed: isAllowed };
    }
    default:
      return state;
  }
}
