import React, {
  createContext, useState, useRef, useContext
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ConfirmationModal } from '@jotforminc/modals';

const defaultOptions = {
  cancelButtonText: 'CANCEL'
};

const Confirmation = createContext(Promise.reject);

export const withConfirmation = (...args) => {
  return useContext(Confirmation).withConfirmation(...args);
};

export const ConfirmationProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null);

  const awaitingPromiseRef = useRef(null);

  const modalRef = useRef(null);

  // eslint-disable-next-line no-shadow
  const withConfirmation = (action, options) => async (...args) => {
    if (args && args[0] && args[0].persist) {
      args[0].persist();
    }

    setConfirmationState({ ...defaultOptions, ...options });

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
      modalRef.current.show();
    }).then(() => action(...args)).catch(f => f);
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    modalRef.current.hide();
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    modalRef.current.hide();
  };

  return (
    <Confirmation.Provider value={{ withConfirmation }}>
      {children}
      <ConfirmationModal
        className={classNames('confirmationModal', confirmationState ? confirmationState.modalClass : '')}
        ref={modalRef}
        usePortal
        onConfirm={handleSubmit}
        onBack={handleClose}
        type='warning'
        {...confirmationState}
      />
    </Confirmation.Provider>
  );
};

ConfirmationProvider.propTypes = {
  children: PropTypes.node.isRequired
};
