import React, { ReactElement } from 'react';
import isEqual from 'lodash/isEqual';
import { RowRenderer } from './RowRenderer';
import { SortProps, TableMetadataType } from './types';
import { OrderType } from './constants';

type Props<SortOptions> = {
  value?: SortProps<SortOptions>,
  tableMetaData: TableMetadataType<SortOptions>,
  handleOrder?: (sortProps: SortProps<SortOptions>) => void,
  isTableEmpty?: boolean
}
export function SortableTableHeader<SortOptions>({
  value = { sortBy: '', sortWay: '' }, tableMetaData, handleOrder = () => {}, isTableEmpty
}: Props<SortOptions>): ReactElement {
  const updateOrder = (sortBy: SortOptions, sortWay: OrderType) => {
    if (!isEqual(value.sortBy, sortBy) || !isEqual(value.sortWay, sortWay)) {
      handleOrder({ sortBy: sortBy, sortWay: sortWay });
    }
  };

  return (
    <RowRenderer
      rowData={tableMetaData.HEADERS}
      tableMetaData={tableMetaData}
      isHeader={true}
      changeSortOption={updateOrder}
      activeSort={value}
      isTableEmpty={isTableEmpty}
    />
  );
}

SortableTableHeader.defaultProps = {
  value: {
    sortBy: '',
    sortWay: ''
  },
  handleOrder: () => {},
  isTableEmpty: false
};
