import { IconBuilding, IconLockFilled } from '@jotforminc/svg-icons';
import { AccountType, ACCOUNTS, TeamRoleSlugs } from '../types';

export const ACCOUNT_TYPES: { [key in AccountType]: { type: string; visibleName: string; isActive: boolean; }} = {
  [ACCOUNTS.ADMIN]: {
    type: 'ADMIN',
    visibleName: 'Admin',
    isActive: true
  },
  [ACCOUNTS.USER]: {
    type: 'USER',
    visibleName: 'User',
    isActive: true
  },
  [ACCOUNTS.DATA_ONLY_USER]: {
    type: 'DATA_ONLY_USER',
    visibleName: 'Data Only User',
    isActive: window.ENABLE_DATA_ONLY_USER === '1'
  }
};

export const DEFAULT_ROLE_SLUG: TeamRoleSlugs = 'team_admin';

export const DEFAULT_PENDING_ROLE_SLUG: TeamRoleSlugs = 'team_admin';

export const PRIVACY_OPTIONS = {
  PUBLIC: {
    type: 'public',
    label: 'Organization Only',
    description: 'Let other members of your organization view this team in the Teams directory',
    Icon: IconBuilding
  },
  PRIVATE: {
    type: 'private',
    label: 'Private',
    description: 'Make this team visible to team members only',
    Icon: IconLockFilled
  }
};

export const SERVER_ADMIN_DEFAULT_TEAM_SETTINGS = {
  teamBrowsing: '1',
  teamCreation: '1',
  teamNewUserInvitation: '1'
};

export const TEAM_ERROR_TEXTS = {
  showNoAccessToTeamView: {
    h1: 'You don\'t have access to this team.',
    p: 'You can try reloading or go back to My Forms.'
  },
  showTeamNotFoundView: {
    h1: 'Team not found.',
    p: 'The team you are trying to access does not exist.'
  }
};
