import Styled from 'styled-components';

export const ScNotificationClearAllButton = Styled.button`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #343C6A
  background-color: transparent;
  cursor: pointer;
  border: none;
  transition: .2s all;
  background-color: #FFFFFF;
  padding: 8px 12px;
  border-radius: 4px;

  &:hover {
    background-color: #F3F3FE;
  }
  &:focus{
    background-color: #F3F3FE;
    outline: 2px solid #E3E5F5;
  }

  ${({ isDisabled }) => (isDisabled && `
  cursor: now-allowed;
  pointer-events: none;
  color: #979DC6;
`)}

`;
