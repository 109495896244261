import classNames from 'classnames';
import {
  bool, func, number, string
} from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { translationRenderer } from '@jotforminc/translation';
import { LISTING_TYPES, TYPE_MAP, MIX_FILTER_ENABLED_LISTING_TYPES } from '../../constants';
import { isListItemDraggable } from '../../utils';

const ListingDragPreview = ({
  // eslint-disable-next-line react/prop-types
  connect, isDragging = false, assetFilterType = LISTING_TYPES.FORM, itemCount = 1, offset, isAssetsFolderActive = false
}) => {
  const containerRef = useRef(false);
  useEffect(() => {
    if (containerRef.current) {
      connect(containerRef.current);
    }
  }, [connect, containerRef.current]);
  const classname = classNames('isPreview', { isDragging });

  return isListItemDraggable(assetFilterType, isAssetsFolderActive) ? (
    <div
      className={classname} ref={containerRef} style={{
        // eslint-disable-next-line react/prop-types
        top: (offset?.y) || -200,
        // eslint-disable-next-line react/prop-types
        left: (offset?.x) || -200
      }}
    >
      {translationRenderer(`Move [1[{itemCount}]] ${MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(assetFilterType) > -1 ? 'asset' : TYPE_MAP[assetFilterType]}${itemCount > 1 ? 's' : ''}`)({
        renderer1: () => itemCount
      })}
    </div>
  ) : null;
};

ListingDragPreview.propTypes = {
  connect: func.isRequired,
  isDragging: bool,
  itemCount: number,
  assetFilterType: string,
  isAssetsFolderActive: bool
};

export default ListingDragPreview;
