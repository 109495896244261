/* eslint-disable max-len */
import Styled from 'styled-components';
import { breakpoints } from '../../../constants';
import { getIconStyles } from '../../../utils';

export const ScNotificationDescNv = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 16px;

  ${({ notification }) => {
    if (notification.showIconOnTextSecondary) {
      return `
        padding-left: 62px;
      `;
    }
  }}

  ${({ notification }) => {
    if (notification.showIconOnTextSecondary) {
      return `
        &::before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background: #FFFFFF;
          background-repeat: no-repeat;
          background-position: center center;
          ${() => (notification.iconName ? `${getIconStyles(notification.iconName)};` : '')}
      
          @media screen and (max-width:${breakpoints.tablet}){
            top: 24px;
            left: 24px;
            width: 40px;
            height: 40px;
          }
        }
      `;
    }
  }}

  ${({ notification }) => {
    if (notification.showIconOnTextSecondary) {
      return `
        div {
          flex-basis: 264px;
        }
      `;
    }
  }}


  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.006em;
    color: #343C6A;
    margin: 0;

    a {
      color: #0066C3;
    }
  }
`;
