import React from 'react';
import {
  arrayOf, shape, string, func, bool
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { ImageWithWrapper, CreateTeamButton } from '@jotforminc/enterprise-components';
import { handleCustomNavigation } from '@jotforminc/utils';
import classNames from 'classnames';
import { isPlural } from '../../utils/index';

import IconNoTeam from '../../assets/svg/teamselection-noteam.svg';
import '../../styles/_jfAppPickerTeamSelection.scss';

const TeamSection = ({
  user,
  teams = [],
  openCreateTeamModal = () => {},
  currentTeamID = '',
  isTeamPage = false,
  isTeamCreationDisabled = false,
  isTeamBrowsingDisabled = false
}) => {
  return (
    <div className="teamSelection">
      <h4 className="teamSelection-title">
        {t('MY TEAMS')}
      </h4>
      <div className="teamSelection-list">
        {teams.length
          ? teams.slice(0, 3).map(team => (
            <a
              key={team?.id || team?.slug}
              href={`/myteams/${team?.slug || team?.id}`}
              onClick={e => {
                e.preventDefault();
                handleCustomNavigation(`/myteams/${team?.slug || team?.id}`, '_self');
              }}
              className={classNames({ selected: isTeamPage && currentTeamID === team?.id })}
            >
              <ImageWithWrapper
                iconBgColor={team.teamAvatarIconBgColor}
                url={team.teamAvatarURL}
                icon={team.teamAvatarIcon}
                emojiId={team.teamAvatarEmojiId}
                iconColor={team.teamAvatarIconColor}
                iconSvgRef={team.teamAvatarIconSvgRef}
                hasCustomization={false}
                isVisible={true}
              />
              <div className="content">
                <div className="name">{team?.name || ''}</div>
                <div className="member">
                  {team?.memberCount}
                  &nbsp;
                  {isPlural(team?.memberCount) ? t('members') : t('member')}
                </div>
              </div>
            </a>
          )) : (
            <div className="teamSelection-list-noteam">
              <div className="icon">
                <IconNoTeam />
              </div>
              <p>{`${t("You don't have any teams yet. Create your first team and start collaborating with your colleagues.")}`}</p>
            </div>
          )}
      </div>
      {!isTeamBrowsingDisabled && !!teams.length && (
      <div className='teamSelection-viewAll'>
        <a target='_blank' href='/all-teams/my-teams'>{t('View All')}</a>
      </div>
      )}
      <div className="teamSelection-createTeambtn">
        <CreateTeamButton
          user={user}
          className="teamSelection-createTeam"
          onClick={openCreateTeamModal}
          tooltipProps={{ attach: 'Top' }}
          isTeamCreationDisabled={isTeamCreationDisabled}
        >
          {`+ ${t('CREATE A TEAM')}`}
        </CreateTeamButton>
      </div>
    </div>
  );
};

TeamSection.propTypes = {
  teams: arrayOf(shape({})),
  isTeamCreationDisabled: bool,
  isTeamBrowsingDisabled: bool,
  openCreateTeamModal: func,
  currentTeamID: string,
  isTeamPage: bool,
  user: shape({
    username: string,
    account_type: shape({
      name: string
    })
  }).isRequired
};

export default TeamSection;
