import React from 'react';

import { featureFlag } from '@jotforminc/feature-flag';
import {
  IconTrashFilled,
  IconUsersArrowRightFilled,
  IconProductTablesFilled,
  IconProductAppsFilled,
  IconArrowRotateLeft,
  IconPaperPlaneDiagonalPlusFilled
} from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import WatchmanRecorder from '../../../../utils/WatchmanRecorder';
import AppAction from '../AppAction';
import MoreAction from '../MoreAction';
import AddToFolder from '../AddToFolder';
import MoveToTeam from '../MoveToTeam';
import ReportAction from '../ReportAction';
import BoardAction from '../BoardAction';
import { TEAM_PERMISSIONS } from '../../../../utils/permissions';
import { LISTING_TYPES, getListingNames, DEFAULT_FOLDER_IDS } from '../../../../constants';

const ACTIONS = {
  CREATE_APP: 'createApp',
  CREATE_DIGEST: 'createDigest',
  PURGE: 'purge',
  RESTORE: 'restore',
  TABLES: 'tables',
  REPORT: 'report',
  BOARDS: 'boards',
  APP: 'app',
  ADD_TO_FOLDER: 'addToFolder',
  MOVE_TO_TEAM: 'moveToTeam',
  MOVE_FROM_TEAM: 'moveFromTeam',
  MORE: 'more',
  DELETE: 'delete'
};

// eslint-disable-next-line complexity
export const getVisibleActionButtons = ({
  selectedItemsListingType, currentTeamID, selectedPortalItem, isDigestEnabled, anyEncryptedFormSelected, isMoveToDisabled, getTeamPermission,
  isSharedWithMeFolder, selectedFolder, isSelectedItemPlural, extraPermissions, isAppsActive, isAddToFolderEnabled, isMoveToTeamEnabled,
  isMoveFromTeamEnabled, isPurgeDisabled, isBoardEnabled, currentPage
}) => {
  const deleteFormPerm = getTeamPermission(TEAM_PERMISSIONS.DELETE_FORM);
  if (selectedFolder === DEFAULT_FOLDER_IDS.TRASH) {
    return {
      [ACTIONS.PURGE]: !isPurgeDisabled && deleteFormPerm,
      [ACTIONS.RESTORE]: deleteFormPerm
    };
  }

  const submissionsPerm = getTeamPermission(TEAM_PERMISSIONS.SUBMISSIONS);
  const viewReportsPerm = getTeamPermission(TEAM_PERMISSIONS.VIEW_REPORTS);
  const isFormListing = selectedItemsListingType === LISTING_TYPES.FORM;
  const isSingleFormSelected = isFormListing && !isSelectedItemPlural;

  if (isSharedWithMeFolder) {
    const hasSubmissionsPerm = isSingleFormSelected && extraPermissions?.submissions;
    return {
      [ACTIONS.TABLES]: hasSubmissionsPerm && submissionsPerm,
      [ACTIONS.REPORT]: hasSubmissionsPerm && !isEnterprise() && viewReportsPerm
    };
  }

  const isAssignedFolder = selectedFolder === DEFAULT_FOLDER_IDS.ASSIGNED;
  const isAppsAllowed = isAppsActive && (window.isAppsReleased || window.allowMyApps);
  const isMultiFormSelected = isFormListing && isSelectedItemPlural;
  const isCreateDigestEnabled = isMultiFormSelected && !anyEncryptedFormSelected;
  const isPortalListing = selectedItemsListingType === LISTING_TYPES.PORTAL;
  const isCreateDigestEnabledForPortal = isPortalListing && !isSelectedItemPlural && selectedPortalItem?.digestID === null;
  const isBoardsAllowed = isBoardEnabled || window.allowMyBoards;

  return {
    [ACTIONS.CREATE_APP]: isMultiFormSelected && isAppsAllowed && getTeamPermission(TEAM_PERMISSIONS.CREATE_APP),
    [ACTIONS.CREATE_DIGEST]: isDigestEnabled && (isCreateDigestEnabled || isCreateDigestEnabledForPortal) && getTeamPermission(TEAM_PERMISSIONS.CREATE_REPORT),
    [ACTIONS.TABLES]: isSingleFormSelected && !isAssignedFolder && submissionsPerm,
    [ACTIONS.REPORT]: isSingleFormSelected && !anyEncryptedFormSelected && !isAssignedFolder && viewReportsPerm,
    // eslint-disable-next-line max-len
    [ACTIONS.BOARDS]: isSingleFormSelected && !anyEncryptedFormSelected && !isAssignedFolder && isBoardsAllowed && [LISTING_TYPES.TEAM_PAGE, LISTING_TYPES.MIX].indexOf(currentPage) === -1 && !currentTeamID,
    [ACTIONS.APP]: isSingleFormSelected && isAppsAllowed && !isAssignedFolder && getTeamPermission(TEAM_PERMISSIONS.CREATE_APP),
    [ACTIONS.ADD_TO_FOLDER]: currentTeamID ? isAddToFolderEnabled && getTeamPermission(TEAM_PERMISSIONS.CREATE_FOLDER) : isAddToFolderEnabled,
    [ACTIONS.MOVE_TO_TEAM]: isMoveToTeamEnabled && !isMoveToDisabled,
    [ACTIONS.MOVE_FROM_TEAM]: isMoveFromTeamEnabled && !isMoveToDisabled && getTeamPermission(TEAM_PERMISSIONS.MOVE_TO),
    [ACTIONS.MORE]: [LISTING_TYPES.SHEET, LISTING_TYPES.FORM, LISTING_TYPES.PORTAL, LISTING_TYPES.TASK, LISTING_TYPES.BOARD, LISTING_TYPES.AGENT].indexOf(selectedItemsListingType) > -1
      && !isAssignedFolder && getTeamPermission(TEAM_PERMISSIONS.MORE_BUTTON),
    [ACTIONS.DELETE]: !isAssignedFolder && deleteFormPerm
  };
};

export const getActionHandlers = ({
  handleCreateApp, handleCreateDigest, selectedItemsListingType, handleMultipleDelete, handleSubmissions, selectedIds, openMoveFromTeamModal, handleMultipleTrash, handleMultipleRestore
}) => {
  return {
    [ACTIONS.CREATE_APP]: () => {
      WatchmanRecorder.trackEvent('click', 'createAppClicked', 'myformsFormToolbar', true);
      handleCreateApp();
    },
    [ACTIONS.CREATE_DIGEST]: () => {
      WatchmanRecorder.trackEvent('click', 'createDigestClicked', 'myformsFormToolbar', true);
      handleCreateDigest(selectedItemsListingType);
    },
    [ACTIONS.PURGE]: () => {
      WatchmanRecorder.trackEvent('click', 'purgeButtonClicked', 'myformsFormToolbar', true);
      handleMultipleDelete();
    },
    [ACTIONS.RESTORE]: () => {
      WatchmanRecorder.trackEvent('click', 'restoreButtonClicked', 'myformsFormToolbar', true);
      handleMultipleRestore();
    },
    [ACTIONS.TABLES]: () => {
      WatchmanRecorder.trackEvent('click', 'submissionsButtonClicked', 'myformsFormToolbar', true);
      handleSubmissions();
    },
    [ACTIONS.MOVE_FROM_TEAM]: () => {
      WatchmanRecorder.trackEventForCustomProject('click', 'myformsToolbarMoveFromTeam', 'teams', true);
      openMoveFromTeamModal(selectedIds);
    },
    [ACTIONS.DELETE]: () => {
      WatchmanRecorder.trackEvent('click', 'trashButtonClicked', 'myformsFormToolbar', true);
      handleMultipleTrash();
    }
  };
};

export const getButtonRenderers = ({
  visibleButtons, actionHandlers, selectedItemsListingType, selectedIds, currentTeamID, moreMenuRef,
  anyUnread, anyEnabled, anyFavorite, anyDisabled, selectedFolder, handleMoreOptionSelect, currentPage
}) => {
  return [
    {
      key: 'create-new-app',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.CREATE_APP],
      onClick: actionHandlers[ACTIONS.CREATE_APP],
      text: 'Create New App',
      className: 'forAppsButton',
      featureFlag: featureFlag.getList().APP_BUILDER,
      IconRenderer: IconProductAppsFilled
    },
    {
      key: 'create-new-digest',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.CREATE_DIGEST],
      onClick: actionHandlers[ACTIONS.CREATE_DIGEST],
      className: 'forReportsButton',
      featureFlag: featureFlag.getList().REPORT_BUILDER,
      IconRenderer: IconPaperPlaneDiagonalPlusFilled,
      text: 'Create New Digest',
      hasNewBadge: selectedItemsListingType === LISTING_TYPES.PORTAL
    },
    {
      key: 'purge',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.PURGE],
      onClick: actionHandlers[ACTIONS.PURGE],
      className: 'forDeleteButton',
      IconRenderer: IconTrashFilled,
      buttonColor: 'error',
      text: 'Purge'
    },
    {
      key: 'restore',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.RESTORE],
      onClick: actionHandlers[ACTIONS.RESTORE],
      className: 'forRestoreButton',
      IconRenderer: IconArrowRotateLeft,
      text: 'Restore'
    },
    {
      key: 'submissions',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.TABLES],
      onClick: actionHandlers[ACTIONS.TABLES],
      className: 'forSubmissionButton',
      IconRenderer: IconProductTablesFilled,
      text: 'Submissions',
      featureFlag: featureFlag.getList().TABLES
    },
    {
      key: 'report',
      isVisible: visibleButtons[ACTIONS.REPORT],
      featureFlag: featureFlag.getList().REPORT_BUILDER,
      ContentRenderer: <ReportAction selectedID={selectedIds[0]} />
    },
    {
      key: 'boards',
      isVisible: visibleButtons[ACTIONS.BOARDS],
      featureFlag: featureFlag.getList().BOARDS,
      ContentRenderer: <BoardAction selectedID={selectedIds[0]} />
    },
    {
      key: 'app',
      isVisible: visibleButtons[ACTIONS.APP],
      featureFlag: featureFlag.getList().APP_BUILDER,
      ContentRenderer: <AppAction selectedID={selectedIds[0]} {...(!!currentTeamID && { teamID: currentTeamID })} />
    },
    {
      key: 'add-to-folder',
      isVisible: visibleButtons[ACTIONS.ADD_TO_FOLDER],
      ContentRenderer: <AddToFolder />
    },
    {
      key: 'move-to-team',
      isVisible: visibleButtons[ACTIONS.MOVE_TO_TEAM],
      ContentRenderer: <MoveToTeam />
    },
    {
      key: 'move-from-team',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.MOVE_FROM_TEAM],
      onClick: actionHandlers[ACTIONS.MOVE_FROM_TEAM],
      className: 'forMoveToTeamButton',
      IconRenderer: IconUsersArrowRightFilled,
      text: `Move to ${currentPage === LISTING_TYPES.MIX ? 'My Workspace' : getListingNames()[selectedItemsListingType]}`
    },
    {
      key: 'more',
      isVisible: visibleButtons[ACTIONS.MORE],
      ContentRenderer: (
        <MoreAction
          ref={moreMenuRef}
          anyUnread={anyUnread}
          anyEnabled={anyEnabled}
          anyFavorite={anyFavorite}
          anyDisabled={anyDisabled}
          selectedFolder={selectedFolder}
          selectedItemsListingType={selectedItemsListingType}
          onSelectionChange={handleMoreOptionSelect}
        />
      )
    },
    {
      key: 'move-to-trash',
      isPlainButton: true,
      isVisible: visibleButtons[ACTIONS.DELETE],
      onClick: actionHandlers[ACTIONS.DELETE],
      className: 'forTrashButton',
      IconRenderer: IconTrashFilled,
      buttonColor: 'error',
      text: 'Move to Trash'
    }
  ];
};
