import React, { useEffect, useRef } from 'react';
import { Hooks, Popover } from '@jotforminc/uikit';
import classNames from 'classnames';
import {
  elementType, func, oneOfType, shape
} from 'prop-types';
import { IconEllipsisVertical } from '@jotforminc/svg-icons';

const FolderContextMenuContainer = ({ onClick, children }) => {
  const containerRef = useRef();
  const contentRef = useRef();
  const targetRef = useRef();
  const [isExpanded, setExpanded] = Hooks.useClickOutsideState(false, [containerRef, contentRef, targetRef]);
  const handleClick = e => {
    e.stopPropagation();
    setExpanded(!isExpanded);
  };
  const className = classNames('folder-context-button', 'folder-button', { isExpanded });

  useEffect(() => {
    onClick(isExpanded);
    if (isExpanded) {
      setTimeout(() => {
        if (contentRef?.current) {
          contentRef.current.focus();
        }
      });
    }
  }, [isExpanded]);

  return (
    <>
      <button
        type="button"
        className={className}
        ref={targetRef}
        onClick={handleClick}
        aria-label="folder context menu settings"
      >
        <IconEllipsisVertical className="w-4 h-4" />
        {isExpanded && (
          <Popover
            ref={containerRef}
            usePortal={true}
            targetRef={targetRef}
            className="isFolderContextMenu"
            popoverOptions={{
              placement: 'bottom-start',
              modifiers: [
                { name: 'offset', options: { offset: [0, 10] } }
              ]
            }}
          >
            <div ref={contentRef} tabIndex={0}>
              {children}
            </div>
          </Popover>
        )}
      </button>
    </>
  );
};

FolderContextMenuContainer.propTypes = {
  onClick: func.isRequired,
  children: oneOfType([elementType, shape({})]).isRequired
};

export default FolderContextMenuContainer;
