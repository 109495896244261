import { ABTestManager } from '@jotforminc/abtest-manager';

// A/B Test: ctAppGeneratorIII
export const initCtAppGeneratorIII = async ({ user, isAppFirstUser }) => {
  let isTestVariant = false;
  let logAbTestAction = f => f;

  const VARIANT_MAP = {
    CONTROL_A: '17361',
    TEST_B: '17371'
  };

  const abTestManager = new ABTestManager({
    user: user.credentials,
    isTestEnabled: false,
    testName: 'ctAppGeneratorIII',
    controlVariantCode: VARIANT_MAP.CONTROL_A,
    testVariantCode: VARIANT_MAP.TEST_B,
    urlParam: 'ctagiii',
    customUserChecks: {
      isAppFirstUser
    },
    cacheVariantCodeAtLocalStorage: true
    // debugMode: {
    //   // forceUserEligible: true,
    //   logTestState: true
    //   // forceTestVariant: true
    // }
  });

  try {
    const isTestVar = await abTestManager.isTestVariant();
    isTestVariant = isTestVar;
    logAbTestAction = abTestManager.registerABTestAction;
  } catch (e) {
    isTestVariant = false;
  }

  return { isTestVariant, logAbTestAction };
};
