import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/animal-shelter.scss';
import DogAndCat from '../assets/campaigns/2024/animal-shelter/dog-cat.svg';

export const AnimalShelter = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="animal-shelter-wrapper">
        <div className="animal-shelter-container">
          <div className="image">
            <DogAndCat />
          </div>
          <div className="title-area">
            <div className="top-title">
              {t('Nonprofits')}
            </div>
            <div className="bottom-title">
              {t('SAVE 50%')}
            </div>
          </div>
          <div className="action-button">
            <a
              className="locale"
              target="_blank"
              href={`/animal-shelter/pricing/?utm_campaign=animal-shelter&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
            >
              {t('Get Discount')}
            </a>
          </div>

        </div>
      </div>

    </>
  );
};

AnimalShelter.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderAnimalShelter = ({
  container, accountTypeName, utmSource, username
}) => {
  const root = createRoot(container);
  root.render(
    <AnimalShelter
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
