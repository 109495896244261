import React, { ReactElement, ReactNode, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PrettyDateReturnValue, prettyDate } from '@jotforminc/utils';
import classNames from 'classnames';
import Loader from '../Loader';
import { PrettyLog } from './types';
import { PrettyUser } from '../../types';
import { LetterAvatar } from '../LetterAvatar';

import '../../styles/Activity.scss';
import '../../styles/User.scss';

type ActivityLogListProps = {
  logs: PrettyLog[],
  members?: PrettyUser[],
  loadMore?: () => void,
  hasMore?: boolean,
  scrollableTarget?: string,
  teamID?: string,
  userLanguage?: string
};

export function ActivityLogList({
  logs,
  members,
  loadMore = () => {},
  hasMore = true,
  scrollableTarget,
  teamID,
  userLanguage
}: ActivityLogListProps): ReactElement {
  const logElements = useMemo(() => {
    let lastDate: number;
    return logs
      .reduce((prevLogs, currentLog) => {
        const { timestamp, name, username } = currentLog;
        const avatar = members?.find(({ username: memberName }) => memberName === username)?.avatar;
        const logDate = new Date(timestamp * 1000).getDate();
        const nextLogs: ReactNode[] = [...prevLogs];
        const { tokens: [date, time] = [] } = prettyDate(timestamp * 1000, true, false, false, { month: 'long' }, userLanguage) as PrettyDateReturnValue || {};
        // Add a horizontal line when the date changes so that items are grouped by date
        if (logDate !== lastDate) {
          nextLogs.push(<hr className="jfActivity-listings-seperator" key={`seperator${timestamp}`} />);
        }

        nextLogs.push(
          <div className="jfActivity-listings">
            {avatar && avatar.indexOf('letter-avatar.png') === -1
              ? (
                <div
                  className={classNames('jfUser-table-column-avatar-wrapper')}
                  aria-label="user avatar"
                  role="img"
                  style={{ backgroundImage: `url("${avatar}")` }}
                />
              )
              : (
                <LetterAvatar
                  name={name || username}
                  className={classNames('jfUser-table-column-avatar-wrapper')}
                  aria-label="user avatar"
                />
              )}
            <div className="jfActivity-listings-content">
              <div className="info">{currentLog.prettyFormat}</div>
              <time>{`${date} at ${time}`}</time>
            </div>
          </div>
        );
        lastDate = logDate;
        return nextLogs;
      }, [] as ReactNode[]);
  }, [logs, members, userLanguage]);
  return (
    <InfiniteScroll
      hasMore={hasMore}
      dataLength={logs.length}
      hasChildren={!!logs.length}
      next={loadMore}
      scrollableTarget={scrollableTarget}
      loader={<Loader withAnimation={true} teamAnimation={!!teamID} text='Loading...' />}
    >
      <div className="jfActivity-listings-wrapper">
        {logElements}
      </div>
    </InfiniteScroll>
  );
}

ActivityLogList.defaultProps = {
  members: [],
  loadMore: () => {},
  hasMore: true,
  scrollableTarget: null,
  teamID: '',
  userLanguage: ''
};
