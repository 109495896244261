import React from 'react';
import ReactDOM from 'react-dom';
import { CLONE_APPROVAL_SETTINGS, CLONE_MODAL_PROPERTIES } from './constants';
import CloneModal from '../../../CloneModal';
import { handleCloneApproval } from '../../core/handleCloneApproval';

const openCloneApprovalModal = ({
  id,
  title,
  workflowRoot,
  teamID,
  formID
}) => {
  const isThereAnyForm = formID;

  const onClone = ({ title: changedTitle, cloneSetting }) => {
    return handleCloneApproval({
      id,
      approvalTitle: changedTitle,
      withForm: !isThereAnyForm ? false : cloneSetting === CLONE_APPROVAL_SETTINGS.WITH_FORMS,
      teamID
    });
  };
  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      ReactDOM.unmountComponentAtNode(workflowRoot);
    };
    ReactDOM.render((
      <CloneModal
        title={title}
        root={workflowRoot}
        onClone={onClone}
        onClose={handleClose}
        isThereAnyForm={isThereAnyForm}
        {...CLONE_MODAL_PROPERTIES}
      />
    ), workflowRoot);
  });
};

export default openCloneApprovalModal;
