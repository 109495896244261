import React, { useMemo } from 'react';
import { bool, func } from 'prop-types';
import { useDrop } from 'react-dnd';
import { IconAngleUp, IconAngleDown } from '@jotforminc/svg-icons';
import translate from '../../utils/translate';
import { LISTING_TYPES } from '../../constants';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const ShowMoreLessButton = ({
  isAllFoldersVisible = false, setIsAllFoldersVisible = f => f, isUserFoldersGroup = false
}) => {
  const [, dropRef] = useDrop(() => ({
    canDrop: () => {
      return true;
    },
    accept: [...Object.values(LISTING_TYPES), 'folder'],
    collect: monitor => {
      if (monitor.isOver({ shallow: true })) {
        setIsAllFoldersVisible(true);
      }
    }
  }), []);

  const Icon = useMemo(() => (isAllFoldersVisible ? IconAngleUp : IconAngleDown), [isAllFoldersVisible]);

  return (
    <button
      className='showMoreLessButton'
      type="button"
      ref={dropRef}
      onClick={() => setIsAllFoldersVisible(curr => {
        WatchmanRecorder.trackEvent('click', `${!curr ? 'showMore' : 'showLess'}${isUserFoldersGroup ? 'Folder' : 'Team'}ButtonClicked`, 'showMoreShowLessButtons');
        return !curr;
      })}
    >
      <Icon width="20" />
      {translate(isAllFoldersVisible ? 'Show less' : 'Show more')}
    </button>
  );
};

ShowMoreLessButton.propTypes = {
  isAllFoldersVisible: bool,
  setIsAllFoldersVisible: func,
  isUserFoldersGroup: bool
};

export default ShowMoreLessButton;
