export const COLOR_MAPPINGS = [
  {
    bgColor: '#FFEEDE',
    fontColor: '#FF9D46'
  },
  {
    bgColor: '#CCE6FF',
    fontColor: '#48A5FF'
  },
  {
    bgColor: '#E1FDE6',
    fontColor: '#74D5A1'
  },
  {
    bgColor: '#FFF8E1',
    fontColor: '#FED02C'
  },
  {
    bgColor: '#EEE7FF',
    fontColor: '#AA83FF'
  }
];
