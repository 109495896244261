import {
  all, spawn, put, takeLatest
} from 'redux-saga/effects';

import { rootUserFlow } from '../main/user';
import { handleFormCreateWizard } from '../listBased/form';

import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';

export const flowList = [
  rootUserFlow
];

export const initialActionList = [
  ACTION_CREATORS.fetchUser
];

export function* liteAppRootSagaFlow() {
  const flowSpawnList = flowList.map(flow => spawn(flow));
  yield takeLatest(ACTION_TYPES.CREATE_WIZARD, handleFormCreateWizard);
  yield all(flowSpawnList);
  yield put(ACTION_CREATORS.fetchUser());
  yield put(ACTION_CREATORS.createWizard({ canClose: false }));
}
