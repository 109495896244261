import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScNotificationBellButton = Styled.button`
  width: 48px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none!important;
  background: transparent!important;
  margin-top: 7.5px;

  @media screen and (max-width:${breakpoints.tablet}){
    margin-top: 0;
    padding: 0;
    width: 45px;
    height: 45px;
  }

  ${({ enterpriseTheme }) => {
    return `
        .circle {
          display: block;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          background-color: ${enterpriseTheme ? '#DADEF3' : '#454E80'};
          outline: 1px solid transparent;
          transition: all .2s;
        }

        &:hover {
          .circle {
            outline-color: #FF6100;
          }
        }

        &.listVisible {
          .circle {
            background-color: ${enterpriseTheme ? '#0A1551' : '#FFFFFF'};
            outline-color: ${enterpriseTheme ? '#0A1551' : '#FFFFFF'};
          }
        }`;
  }}

  &:focus{
    outline: thin dotted #FFFFFF;

    body.tile-black .jfHeader.light-mode & {
      outline-color: #FFFFFF;
    }

    body.tile-black .jfHeader.light-mode .jfHeader-content.isExpanded & ,
    .jfHeader-content.isExpanded & ,
    .jfHeader.light-mode & {
      outline-color: #000000;
    }

    ${({ isNotificationListVisible }) => (isNotificationListVisible ? 'outline: none;' : '')}
  }

  &:hover{
    &:focus{
      outline: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    background: #FFFFFF;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
    top: 6px;
    right: 2px;
    box-shadow: 0 0 0 2px transparent, inset 0 0 0 5px #E71D1D;
    opacity: 0;
    transition: opacity .2s;

    ${({ showRedDot, enterpriseTheme }) => (`
      width: 8px;
      height: 8px;
      top: 4px;
      right: 4px;
      border-radius: 8px;
      box-shadow: none;
      background: #FF6100;
      border: 2px solid ${enterpriseTheme ? '#FFFFFF' : '#0A1551'};

      @media screen and (min-width:${breakpoints.tablet}){
        top: 10px;
        right: 6px;
      }

      ${(showRedDot && `
        opacity: 1;
      `)}
    `)}
  }

  &:after{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 48px;
    height: 55px;
  }
    `;
