/* eslint-disable camelcase */
import { KeyboardEvent } from 'react';
import { isEnterprise } from '@jotforminc/enterprise-utils';

import {
  AccountType, User, ACCOUNTS, PrettyUser, RolesType, ServerTeamSettingsType
} from '../types';
import { ACCOUNT_TYPES } from './constants';
import { TeamRoleDescription as getTeamRoleDescription } from '../components/TeamRoleDescription';
import { apiCall } from './api';

export const handleKeyUp = (e: KeyboardEvent<HTMLElement>, func: () => void): void => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    func();
  }
};

export const visibleName = (accountType: AccountType): string => ACCOUNT_TYPES[accountType].visibleName;

export const toFormattedNumber = (num: number): string => {
  return num ? num.toLocaleString('en-US') : '0';
};

export const isDataOnlyUser = (user: User | PrettyUser): boolean => {
  return user.isDataOnlyUser === '1';
};

export const mappedAccountType = (user: User | PrettyUser | false): AccountType => {
  if (!user) return ACCOUNTS.USER;
  if (isDataOnlyUser(user)) return ACCOUNTS.DATA_ONLY_USER;
  return user.account_type === ACCOUNTS.ADMIN ? ACCOUNTS.ADMIN : ACCOUNTS.USER;
};

export const parseUser = (user: User | PrettyUser, isTeamMember?: boolean): PrettyUser => ({
  ...user,
  fullText: `${user.username}${user.name}${user.email}${user.jobTitle}`,
  avatar: isTeamMember ? user.avatar : user.avatarUrl,
  visibleUserType: isTeamMember ? user?.role?.role_name || '' : visibleName(mappedAccountType(user)),
  visibleUserName: user.name || '',
  formCount: user.form_count,
  formCountForUI: toFormattedNumber(user.form_count)
});

// eslint-disable-next-line
export const isErrored = (res: any): boolean => !res || (res.responseCode && res.responseCode !== 200) || (res.data && res.data.responseCode && res.data.responseCode !== 200);

export const createTeamRolesWithDescriptions = (teamRoles: RolesType[]): RolesType[] => teamRoles.map(role => ({
  ...role,
  role_description: role.role_description || getTeamRoleDescription(role.role_slug)
}));

export const checkUserCanInviteMembers = (): boolean => isEnterprise();

export const checkUserCanCreateTeam = (teamCount: number): boolean => isEnterprise() || !teamCount || teamCount < 5;

export const isPrivacyFeatureEnabled = (): boolean => isEnterprise();

export const hasQueryParams = (url: string): boolean => !!url && url.indexOf('?') > -1;

export const isServerTeamSettingsEnabled = (): boolean => isEnterprise();

export const canAddNewUsers = (serverTeamSettings: ServerTeamSettingsType, accountType: string): boolean => {
  return !serverTeamSettings?.teamNewUserInvitation || serverTeamSettings?.teamNewUserInvitation !== '0' || accountType === 'ADMIN';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const canCreateTeam = (isTeamCreationDisabled: boolean, accountType: any): boolean => !isTeamCreationDisabled || accountType === 'ADMIN' || accountType?.name === 'ADMIN';

export const addBodyClass = (className: string): void => document.body.classList.add(className);

export const removeBodyClass = (className: string): void => document.body.classList.remove(className);

export const isBillingTabActive = async (): Promise<boolean> => {
  const { host } = window.location;

  const isDevelopmentEnv = host.indexOf('jotform.biz') > -1;
  const testEnvironmentList = [
    'invoicetestinternal.jotform.com', 'www.jotformers.com',
    'portosudeste.jotform.com', 'talend.jotform.com', 'chcsno.jotform.com', 'sierrageotech.jotform.com',
    'vivachicken.jotform.com', 'vyanet.jotform.com', 'batsoncook.jotform.com', 'bespokeguardians.jotform.com',
    'solidplatforms.jotform.com', 'campallen.jotform.com', '1callclosers.jotform.com', '3deventsla.jotform.com',
    'baycare.jotform.com', 'lgcypower.jotform.com', 'atomsupplies.jotform.com', 'cjrdc.jotform.com',
    'nfpcanada.jotform.com', 'metroplains.jotform.com', 'engglo.jotform.com', 'mjelectric.jotform.com'
  ];

  // Old method of checking for feature flag. Use the api instead to update the server config
  if ((testEnvironmentList.indexOf(host) > -1 || isDevelopmentEnv)) return true;

  return apiCall({ path: '/enterprise-dashboard/server/customization/billing/status' });
};

export const getDateFilter = (dateFilter?: string | { start: string, end: string }): {[key: string]: string} => {
  if (!dateFilter) return {};

  return typeof dateFilter === 'string' ? { date: dateFilter } : { date: 'range', startDate: dateFilter.start, endDate: dateFilter.end };
};

/**
 * @description Filters a list of users against a search term and/or an excluded user list
 */
export const filterUsers = ({
  searchTerm, destinations, excludedUsers
}: {
  searchTerm: string,
  destinations: Partial<User>[],
  excludedUsers?: Partial<User>[]
}): Partial<User>[] => {
  return destinations.filter(destination => {
    const { name = '', username = '', email = '' } = destination;

    const searchRegex = new RegExp(searchTerm, 'i');

    // Filter out users we're from excluded users list
    if (excludedUsers?.some(excludedUser => excludedUser.username === username)) return false;

    // Filter target destination list by search term
    return searchRegex.test(name) || searchRegex.test(username) || searchRegex.test(email);
  });
};
