/* eslint-disable max-len */
import { createRoot } from 'react-dom/client';
import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import { number, string } from 'prop-types';

import useInterval from '../hooks/useInterval';

import './styles/newuser.scss';

export const NewUserCampaignBanner = ({ utmSource, accountTypeName, expirationDate }) => {
  const getDistance = () => {
    // Get today's date and time
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    const distance = expirationDate - now;
    return distance;
  };
  const [countdown, setCountdown] = useState(getDistance() < 0 ? '00 : 00 : 00' : '24 : 00 : 00');
  const [delay, setDelay] = useState(getDistance() < 0 ? null : 1000);
  useInterval(() => {
    const distance = getDistance();
    if (distance < 0) {
      setCountdown('00 : 00 : 00');
      setDelay(null);
    }
    // Time calculations for days, hours, minutes and seconds
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (hours < 10) hours = `0${hours}`;

    if (minutes < 10) minutes = `0${minutes}`;

    if (seconds < 10) seconds = `0${seconds}`;

    // Output the result in an element with id="demo"
    setCountdown(`${hours} : ${minutes} : ${seconds} `);
  }, delay);
  return (
    <div className="campaign-container">
      <div className="campaign-content-container">

        <div className="balloon-anime">
          <img
            alt="Red balloon" loading="lazy" className="lightbox-balloon rBalloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/rBalloon.png?v=0.2"
          />
          <img
            alt="Green balloon" loading="lazy" className="lightbox-balloon gBalloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/gBalloon.png?v=0.2"
          />
          <img
            alt="yellow balloon" loading="lazy" className="lightbox-balloon yBalloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/yBalloon.png?v=0.2"
          />
          <img
            alt="Red balloon" loading="lazy" className="lightbox-balloon rBalloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/rBalloon.png?v=0.2"
          />
          <img
            alt="Green balloon" loading="lazy" className="lightbox-balloon g2Balloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/gBalloon.png?v=0.2"
          />
          <img
            alt="yellow balloon" loading="lazy" className="lightbox-balloon y2Balloon"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/yBalloon.png?v=0.2"
          />
          <div className="modal-gifts">
            {' '}
            <img loading="lazy" src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/modal-gifts.svg?v=0.2" alt="" />
          </div>
        </div>
        <div className="textTop">
          <div className="textStyle locale">
            <span>{t('TODAY ONLY!')}</span>
            {' '}
            {t('SAVE 50%')}
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.05 100" height="75"
          className="podo"
        >
          <defs><clipPath id="a"><path transform="matrix(1 -.00314 .00314 1 -.1 .11)" fill="none" d="M18.57 7.4h32.12v47.43H18.57z" /></clipPath></defs>
          <g fill="#dc692d">
            <circle cx="2.22" cy="75.19" r="2.22" />
            <circle cx="3.91" cy="75.09" r="2.22" />
            <circle cx="5.39" cy="75.06" r="2.22" />
            <circle cx="6.69" cy="75.1" r="2.22" />
            <circle cx="7.8" cy="75.2" r="2.22" />
            <circle cx="8.74" cy="75.36" r="2.22" />
            <circle cx="9.53" cy="75.59" r="2.22" />
            <circle cx="10.17" cy="75.88" r="2.22" />
            <circle cx="10.68" cy="76.23" r="2.22" />
            <circle cx="11.08" cy="76.64" r="2.22" />
            <circle cx="11.37" cy="77.11" r="2.22" />
            <circle cx="11.56" cy="77.64" r="2.22" />
            <circle cx="11.67" cy="78.22" r="2.22" />
            <circle cx="11.72" cy="78.86" r="2.22" />
            <circle cx="11.7" cy="79.55" r="2.22" />
            <circle cx="11.64" cy="80.3" r="2.22" />
            <circle cx="11.55" cy="81.1" r="2.22" />
            <circle cx="11.45" cy="81.95" r="2.22" />
            <circle cx="11.33" cy="82.85" r="2.22" />
            <circle cx="11.22" cy="83.79" r="2.22" />
            <circle cx="11.13" cy="84.79" r="2.22" />
            <circle cx="11.06" cy="85.84" r="2.22" />
            <circle cx="11.04" cy="86.92" r="2.22" />
            <circle cx="11.08" cy="88.06" r="2.22" />
            <circle cx="11.18" cy="89.24" r="2.22" />
            <circle cx="11.36" cy="90.46" r="2.22" />
            <circle cx="11.64" cy="91.72" r="2.22" />
            <circle cx="12.02" cy="93.02" r="2.22" />
            <circle cx="12.52" cy="94.37" r="2.22" />
            <circle cx="13.14" cy="95.75" r="2.22" />
            <circle cx="13.69" cy="96.75" r="2.22" />
            <circle cx="14.25" cy="97.49" r="2.22" />
            <circle cx="14.89" cy="98.15" r="2.22" />
            <circle cx="15.62" cy="98.74" r="2.22" />
            <circle cx="16.42" cy="99.25" r="2.22" />
            <circle cx="17.28" cy="99.69" r="2.22" />
            <circle cx="18.19" cy="100.05" r="2.22" />
            <circle cx="19.15" cy="100.33" r="2.22" />
            <circle cx="20.14" cy="100.54" r="2.22" />
            <circle cx="21.15" cy="100.67" r="2.22" />
            <circle cx="22.18" cy="100.73" r="2.22" />
            <circle cx="23.22" cy="100.71" r="2.22" />
            <circle cx="24.25" cy="100.62" r="2.22" />
            <circle cx="25.27" cy="100.45" r="2.22" />
            <circle cx="26.26" cy="100.21" r="2.22" />
            <circle cx="27.22" cy="99.89" r="2.22" />
            <circle cx="28.14" cy="99.5" r="2.22" />
            <circle cx="29.02" cy="99.03" r="2.22" />
            <circle cx="29.82" cy="98.49" r="2.22" />
            <circle cx="30.56" cy="97.87" r="2.22" />
            <circle cx="31.22" cy="97.18" r="2.22" />
            <circle cx="31.8" cy="96.42" r="2.22" />
            <circle cx="32.27" cy="95.58" r="2.22" />
            <circle cx="32.63" cy="94.67" r="2.22" />
            <circle cx="32.88" cy="93.69" r="2.22" />
            <circle cx="33" cy="92.63" r="2.22" />
            <circle cx="32.99" cy="91.5" r="2.22" />
            <circle cx="32.83" cy="90.29" r="2.22" />
          </g>
          <path d="M13.32 59.51c-1.9-3-6.28-12.11-3.25-11.09 1.16.39 2.29.82 3.48 1.1.46.11 5.5 2 5.95 1.66" fill="#ed9321" />
          <path d="M14 58.77c-1.45-2.28-4.81-9.26-2.49-8.48.89.3 1.75.63 2.66.84.35.09 4.2 1.52 4.55 1.28" fill="#dc692d" />
          <path d="M57.2 59.51c1.9-3 6.29-12.11 3.25-11.09-1.15.39-2.29.82-3.48 1.1-.46.11-5.49 2-5.94 1.66" fill="#ed9321" />
          <path d="M51.83 52.41c.34.24 4.19-1.19 4.54-1.28a27.36 27.36 0 002.63-.84c2.32-.78-1 6.2-2.48 8.48" fill="#dc692d" />
          <path d="M20 50.56a25.12 25.12 0 00-2.31 2.2c-.3.46-1.62.28-2.17.23s-.83.19-.26.65.83.67-.47.74a4.56 4.56 0 00-1.66.26s-1.55.25.37.66 3.19.7 3.84-.37S20 51.5 20.33 51s.36-.62-.33-.44zm36.12 9.87a22.68 22.68 0 00-.75-2.77c-.24-.43.46-1.39.74-1.82s.2-.73-.41-.47-.88.36-.39-.69a3.85 3.85 0 00.5-1.43s.46-1.33-.68 0-1.9 2.24-1.34 3.21 1.58 3.59 1.85 4.06.36.55.48-.09z" fill="#f3a41d" />
          <path d="M29.13 66.74c0 2.22 2.65 4 5.92 4S41 69 41 66.74s-2.64-4-5.91-4-5.96 1.78-5.96 4z" fill="#f7c847" />
          <path d="M38.66 64.64a17.41 17.41 0 00-1.79-1.09c-.35-.1-.47-1-.53-1.33s-.27-.49-.46 0-.28.64-.55-.17-.44.48-.44.48-.79-1.26-.71 0 .46 1 1.25 1.21 2.65 1.1 3 1.21.47.1.23-.31z" fill="#f7c847" />
          <g fill="#f3a41d">
            <path d="M29 115a2.24 2.24 0 10-2.24-2.24A2.24 2.24 0 0029 115z" />
            <path d="M29 113.06a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M29 111.17a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M29 109.28a2.24 2.24 0 10-2.25-2.28 2.24 2.24 0 002.25 2.28z" />
            <path d="M29 107.39a2.25 2.25 0 10-2.24-2.25 2.24 2.24 0 002.24 2.25z" />
            <path d="M29 105.49a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M29 103.6a2.24 2.24 0 10-2.24-2.24A2.24 2.24 0 0029 103.6z" />
            <path d="M29 101.71a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M29 99.82a2.24 2.24 0 10-2.24-2.24A2.24 2.24 0 0029 99.82z" />
          </g>
          <g fill="#f3a41d">
            <path d="M41 115a2.24 2.24 0 10-2.24-2.24A2.24 2.24 0 0041 115z" />
            <path d="M41 113.06a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M41 111.17a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M41 109.28a2.24 2.24 0 10-2.28-2.28 2.24 2.24 0 002.28 2.28z" />
            <path d="M41 107.39a2.25 2.25 0 10-2.24-2.25 2.24 2.24 0 002.24 2.25z" />
            <path d="M41 105.49a2.24 2.24 0 10-2.24-2.24 2.24 2.24 0 002.24 2.24z" />
            <path d="M41 103.6a2.24 2.24 0 10-2.24-2.24A2.24 2.24 0 0041 103.6z" />
            <path d="M41 101.71a2.24 2.24 0 10-2.25-2.24 2.23 2.23 0 002.25 2.24z" />
            <path d="M41 99.82a2.24 2.24 0 10-2.25-2.24A2.23 2.23 0 0041 99.82z" />
          </g>
          <path d="M12.53 62.69c.41-7.73 7-14.18 16.07-16.32 1.43-.41 2.73-.74 2.73-.74.38-1.47 2-4.94 2.57-3.69.22.47.42 1 .68 1.41a21.56 21.56 0 001.13 2.28h.12a12.13 12.13 0 00.89-1.76c.2-.34.35-.71.52-1.07.43-.94 1.65 1.68 1.94 2.8 0 0 3 .76 4.32 1.29 8.33 2.62 14.22 9 14.22 16.56v9.29s.05 5 0 5v3a14.71 14.71 0 01-2.38 8 .37.37 0 01-.07.14c-.37.43.71.85 1.13 1s.3.47-.63.54-1.37.11-.6.68.76.86.76.86.7.8-1.06.29a11.12 11.12 0 01-1.87-.57 21.86 21.86 0 01-6.45 4.48l-4 1.47a28.18 28.18 0 01-7.46 1C22.94 98.62 13 91 12.53 81.52z" fill="#f5a51c" />
          <path d="M34.21 92.07c0 .34.4.62.9.62s.91-.28.91-.62-.4-.62-.91-.62-.9.28-.9.62z" fill="#db6a2a" />
          <path fill="#e6332a" d="M25.71 109.19H32v9.01h-6.29z" />
          <rect
            x="25.39" y="109.13" width="6.93"
            height="1.39" rx=".7" fill="#f7c847"
          />
          <path d="M32.19 118.7a3.92 3.92 0 00-3.68-4.12 3.92 3.92 0 00-3.69 4.12z" fill="#ad2620" />
          <path d="M32.08 117.71a4.62 4.62 0 01.11 1h-7.37a4.62 4.62 0 01.11-1" fill="#731a15" />
          <path fill="#e6332a" d="M38.08 109.17h6.29v9.01h-6.29z" />
          <rect
            x="37.81" y="109.12" width="6.82"
            height="1.37" rx=".68" fill="#f7c847"
          />
          <path d="M45.18 118.68a3.92 3.92 0 00-3.68-4.12 3.92 3.92 0 00-3.69 4.12z" fill="#ad2620" />
          <path d="M37.81 118.68a4.55 4.55 0 01.11-1h7.15a4.55 4.55 0 01.11 1" fill="#731a15" />
          <path d="M56.51 64.37c-.49.14-.93.26-1.31.34-.09 9.11-9 16.47-19.94 16.47s-19.94-7.43-19.94-16.61v-1a1 1 0 01-.08-.52c.15-.54-1-.46-1.47-.46s-.47-.29.34-.76 1.2-.68.26-.87-1.05-.45-1.05-.45-1-.43.83-.72a10.19 10.19 0 012-.2c2.31-6.2 8.87-10.84 16.85-11.57.29-.21.11-1.08 0-1.47s.23-.51.8.23.84 1.09.9.13.3-1.1.3-1.1.29-1 .82.72c.16.53.31 1 .4 1.41 8.59.38 15.74 5.29 18.07 11.91.37.54.71 1.11.66 1.37-.09.56 1 .35 1.51.3s.5.23-.25.78-1.11.82-.16.9 1.1.33 1.1.33 1.12.35-.64.84z" fill="#f7c847" />
          <path d="M41.76 61.94a4 4 0 104-4 4 4 0 00-4 4z" fill="#fff" />
          <path d="M49 62a3.22 3.22 0 11-3.22-3.21A3.22 3.22 0 0149 62z" fill="#211f1f" />
          <path d="M47.56 60.24a.55.55 0 01-.11 1.09.55.55 0 01.11-1.09zm-1.99 2.64a.82.82 0 01.72.89.81.81 0 01-1.61-.17.82.82 0 01.89-.72zm-1.18-3.04a1.17 1.17 0 011 1.28 1.12 1.12 0 01-1.23 1 1.16 1.16 0 01-1-1.27 1.13 1.13 0 011.23-1.01zm-23.66 2.1a4 4 0 104-4 4 4 0 00-4 4z" fill="#fff" />
          <path d="M27.93 62a3.21 3.21 0 11-3.21-3.21A3.22 3.22 0 0127.93 62z" fill="#211f1f" />
          <path d="M26.52 60.24a.54.54 0 01.49.6.55.55 0 01-1.09-.11.54.54 0 01.6-.49zm-1.99 2.64a.81.81 0 01.72.89.8.8 0 01-.88.71.8.8 0 01.16-1.6zm-1.17-3.04a1.17 1.17 0 011 1.28 1.13 1.13 0 01-1.24 1 1.16 1.16 0 01-1-1.27 1.14 1.14 0 011.24-1.01z" fill="#fff" />
          <path d="M44.56 68.9c0 .88 1.63 1.6 3.63 1.6s3.64-.72 3.64-1.6-1.63-1.61-3.64-1.61-3.63.71-3.63 1.61zm-27.1-.09c0 .89 1.62 1.61 3.63 1.61s3.63-.72 3.63-1.61-1.63-1.6-3.63-1.6-3.63.72-3.63 1.6z" fill="#f3a41d" opacity=".6" />
          <path fill="#f7c847" d="M35.05 66.28v1.14" />
          <path d="M34.62 66.28v1.14a.44.44 0 00.43.43.43.43 0 00.42-.43v-1.14a.42.42 0 00-.42-.43.44.44 0 00-.43.43z" fill="#f2a41e" />
          <path d="M35.93 65.32a1.42 1.42 0 00-.11.18.7.7 0 00.6-.29.34.34 0 00-.07-.47 1.43 1.43 0 00-.9-.41 2.56 2.56 0 00-1.5.25c-.24.13-.38.29-.33.54s.24.35.54.36c0-.06-.08-.12-.12-.17l.29.3a.92.92 0 00.88.24.66.66 0 00.48-.31 1.84 1.84 0 01.17-.22z" fill="#211f1f" fillRule="evenodd" />
          <g>
            <path d="M30.94 67.92c0 1.85 1.87 3.35 4.18 3.35s4.18-1.5 4.18-3.36z" fill="#8b1b1d" />
            <path d="M37.19 70.82a5 5 0 01-4.36-.08 3.51 3.51 0 012.22-.9 2.73 2.73 0 012.14.98z" fill="#cd6ea7" />
          </g>
          <g>
            <path d="M74.92 10.94C72.21 2.75 63.8-1.84 56.13.7s-11.7 11.22-9 19.41C49.75 28 57.71 32.59 65.18 30.58a1.82 1.82 0 00-.78 2l3.53-1.17a1.84 1.84 0 00-1.78-1.18c7.52-2.59 11.44-11.23 8.77-19.29z" fill="#e6332a" />
            <path d="M53.68 28.4a16.39 16.39 0 007 2.58 13.81 13.81 0 003.72-3.78c4.42-6.76 2.16-16.07-5.06-20.79a16.39 16.39 0 00-7.05-2.58 14 14 0 00-3.68 3.78c-4.42 6.76-2.15 16.07 5.07 20.79z" fill="#f94541" />
            <path d="M74.69 46.12A10.73 10.73 0 0071.06 41c-1.1-.88-3.24-2.72-3.87-4.58a13.37 13.37 0 01-.62-4.44l-1 .29A13.22 13.22 0 0068 39.69c.72.9 4.18 3.32 5.12 5.12 3.53 6.8-2.1 13.84-2.1 13.84s.6 2.54 3.51-4.23a11.56 11.56 0 00.93-4.72 12.35 12.35 0 00-.77-3.58z" fill="#646984" />
          </g>
          <rect
            x="68.27" y="56.34" width="4.46"
            height="1.89" rx=".95" transform="rotate(82.17 70.506 57.283)"
            fill="#ed9321"
          />
          <g>
            <rect
              x="11.8" y="85.28" width="11.39"
              height="1.33" rx=".66" transform="rotate(21.79 17.484 85.938)"
              fill="#66cbec"
            />
            <path fill="#00a9e0" d="M15.43 97.9l-4.7-1.88 1.9-11.3 9.22 3.68-6.42 9.5z" />
          </g>
          <path
            d="M55.83 75.43s13.4-4 16.13-16.66" fill="none" stroke="#f5a51c"
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"
          />
          <rect
            x="70.84" y="55.05" width="4.94"
            height="6.31" rx="2.2" transform="rotate(16.91 73.282 58.176)"
            fill="#f5a51c"
          />
          <path
            d="M25.62 74.34s-6.09 1-5.94 8.31" fill="none" stroke="#f5a51c"
            strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"
          />
          <g>
            <path d="M20.71 48.8c-.83-.73-1.29 2.14-1.29 2.14s-.52 2.32 5.82 3.92S40.37 56 47.88 53.53c0 0 2.61-.68 2.78-2.58.1-1.13-1.29-3.14-1.9-2.28-2.5 3.54-21.32 6.01-28.05.13z" fill="#c26f40" opacity=".61" />
            <g
              fill="none" stroke="#fff" strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M34.77 11.87a2.55 2.55 0 01.76-1.9 9.94 9.94 0 011.78-1A3.83 3.83 0 0039 7a2.41 2.41 0 00-.53-2.45" />
              <path d="M34.49 4.67A2.16 2.16 0 0136 6.48a2.6 2.6 0 01-.83 1.67A6.17 6.17 0 0034 9.64a2.12 2.12 0 00.79 2.43" />
              <path d="M34.77 12.09a3.47 3.47 0 012.11-1.71 6.43 6.43 0 012.76-.16 4.1 4.1 0 001.94 0A2.57 2.57 0 0043 8.48 1.33 1.33 0 0042.71 7a.91.91 0 00-1.21.3 1.31 1.31 0 000 1.31" />
            </g>
            <path d="M50.63 50.18L34.56 10.5 18.74 50.28a1.23 1.23 0 00-.1.46c0 2.29 7.2 4.12 16.06 4.09s16-1.9 16-4.19a1.27 1.27 0 00-.1-.46z" fill="#6f2de0" />
            <path d="M49.37 52.32c.87-.52 1.36-1.08 1.36-1.68a1.27 1.27 0 00-.1-.46L34.56 10.5l-1.06 2.65z" fill="#9658ff" />
            <path d="M37.83 18.59a60 60 0 01-7.28 2l-2.41 6.07A61.78 61.78 0 0040 23.81z" fill="#f09b53" />
            <g clipPath="url(#a)" fill="#f09b53"><path d="M42.3 29.62a61.19 61.19 0 01-16.91 3.95l-2.22 5.58a61.4 61.4 0 0021.24-4.33zm6.55 16.16l-2.09-5.16A61 61 0 0120.44 46l-1.7 4.28a1.21 1.21 0 000 1 61 61 0 0030.08-5.47z" /></g>
            <path d="M47.05 46.59l1.8-.81-2.09-5.16c-.61.28-1.21.54-1.81.79zm-4.49-11.07c.62-.22 1.23-.45 1.85-.7l-2.11-5.2c-.62.24-1.23.47-1.85.69zm-4.49-11.09c.62-.19 1.25-.4 1.88-.62l-2.12-5.22c-.63.22-1.25.42-1.88.61z" fill="#fff" />
            <path
              fill="none" stroke="#fff" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="2" d="M35.92 23.98l1.99 5.1m1.14 2.91l.57 1.46"
            />
          </g>
        </svg>
        <div className="textStyleRight">
          <span className="mobile-only locale">{t('SAVE 50%')}</span>
          <span className="locale white">{t('Offer expires')}</span>
          {' '}
          <span id="countdown-for-newuser">
            {countdown}
          </span>
        </div>
        <div className="gifts-mobile">
          {' '}
          <img loading="lazy" src="https://cdn.jotfor.ms/assets/img/campaigns/2019/eoy/myforms/header-gifts-mobile.svg?v=0.2" alt="" />
        </div>
        <div className="item button">
          <a className="locale" href={`/pricing?utm_campaign=newUser&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}>{t('Save Now')}</a>
        </div>
      </div>
    </div>
  );
};

NewUserCampaignBanner.propTypes = {
  utmSource: string.isRequired,
  expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderNewUserCampaign = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(
    <NewUserCampaignBanner
      utmSource={utmSource}
      expirationDate={expirationDate}
      accountTypeName={accountTypeName}
    />
  );
};
