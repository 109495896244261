import { t } from '@jotforminc/translation';
import texts from '../../core/texts';

export const CLONE_APPROVAL_SETTINGS = {
  WITH_FORMS: 'withForms',
  WITHOUT_FORMS: 'withoutForms'
};

export const DEFAULT_CLONE_APPROVAL_SETTING = CLONE_APPROVAL_SETTINGS.WITH_FORMS;

export const CLONE_APPROVAL_OPTIONS = [
  {
    showWhenSelected: t(texts.CLONE_APPROVAL_FLOW_WITH_FORM),
    text: t(texts.CLONE_APPROVAL_FLOW_WITH_FORM_TEXT),
    desc: t('Create a copy of the flow and the forms it includes.'),
    value: CLONE_APPROVAL_SETTINGS.WITH_FORMS
  }, {
    showWhenSelected: t(texts.CLONE_APPROVAL_FLOW),
    text: t(texts.CLONE_APPROVAL_FLOW),
    desc: t('Create a copy of the flow without cloning your forms.'),
    value: CLONE_APPROVAL_SETTINGS.WITHOUT_FORMS
  }
];

export const CLONE_MODAL_PROPERTIES = {
  header: {
    text: texts.CLONE_MODAL_TEXT,
    description: texts.CLONE_MODAL_DESCRIPTION
  },
  buttonText: texts.CLONE_MODAL_TEXT,
  appName: 'approvals',
  defaultSetting: DEFAULT_CLONE_APPROVAL_SETTING,
  settingsProps: {
    options: CLONE_APPROVAL_OPTIONS,
    inputLabel: texts.CLONE_MODAL_INPUT,
    settingLabel: texts.CLONE_MODAL_SETTINGS
  }
};
