import styled from 'styled-components';
import { WizardBody, WizardItem } from '@jotforminc/wizard-shared';

export const ScWizardItem = styled(WizardItem)`
  &.jfWizard-item {
    .jfWizard-body{
      margin: 0;
    }

    .jfWizard-header{
      margin: 0;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .jfWizard-header-title {
      color: #343C6A;
      font-size: 28px;
      font-weight: 700;
      margin: 0;
    }

    .jfWizard-header-subtitle {
      display: flex;
      justify-content: center;
      font-size: 18px;
      line-height: 26px;
      margin-top: 22px;
      color: #6C73A8;
      font-weight: 400;
      width: 440px;
    }

    &.jfWizard-gutter.withMaxWidth{
      width: 100%;
      max-width: unset;
    }

    @media screen and (max-height: 620px) {
      margin: 0;
    }
  }

  .jfWizard-close{
      top: 40px:
      right: 40px;
    }

  .sideBySideWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 24px;
    gap: 90px;
    
    @media screen and (max-width: 1200px) {
      gap: 30px;
    }
  }

  .betaProduct,
  .newProduct {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 10px 8px;
  }

  .productDetails {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: #E3E5F5;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    height: 100%;
    flex-basis: 50%;
    position: relative;
    padding: 0 20px;

    &-name {
      position: relative;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.017em;
      text-align: center;
      color: #0A1551;
      padding: 0 50px;

      .betaProduct,
      .newProduct {
        position: absolute;
        top: -8px;
        right: 2px;
      }
    }

    &-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.011em;
      text-align: center;
      color: #343C6A;
      margin: 20px auto 70px;
      max-width: 486px;
    }

    .productImage {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      transition: opacity .3s ease-in-out;
      max-width: 80%;
      max-height: 60%;

      &.transition {
        opacity: 0;
      }

      img {
        height: 100%;
        width: 100%;
      }

      &.loading{
        opacity: 0;
      }
    }
    &.app .productImage {
      max-height: 50%;

      img {
        border-radius: 32px;
      }
    }
    &.report, &.board, &.workflow, &.agent {
      .productImage {
        margin-top: 20px;
      }
    }
    &.form, &.workflow, &.e-sign, &.agent, &.table, &.report {
      .productImage img {
        height: 85%;
      }
    }
  }

  .productWrapper {
    display: flex;
    flex-direction: column;
    margin-top: -40px;
    gap: 48px;
    align-items: center;
    flex-basis: 45%;
  }
`;

export const ScWizardBody = styled(WizardBody)`
  .productListWrapper {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 700px;
    gap: 48px;

    .createProductButton {
      width: 100%;
      background: #0075E3;
      color: #FFFFFF;
      border-radius: 4px;
      height: 48px;
      border: 0;

      text-align: center;
      font-family: Circular;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      &:hover {
        background: #0066C3;
      }
    }
  }

  .productItem {
    background-color: #FFFFFF;
    border: 1px solid #C8CEED;
    border-radius: 12px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 14px 4px;
    min-height: 125px;

    &.isSelected {
      background-color: #ECF0FF;
      border: 1.6px solid #0075E3;
    }

    &:hover {
      background-color: #ECF0FF;
    }

    &.report path{
      fill: #5369AB;
    }

    &-list {
      display: flex;
      align-items: flex-start;
      display: flex;
      flex-directions: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 16px;

      &.moreThanFour {
        flex-wrap: wrap !important;

        & > li {
          flex-basis: calc((100% - 32px) / 3) !important;
        }
      }
      &.moreThanSix {
        flex-wrap: wrap !important;

        & > li {
          flex-basis: calc((100% - 48px) / 4) !important;
        }
      }

      & > li {
        flex-basis: auto;
        
        @media screen and (max-width: 880px) {
          flex-basis: calc((100% - 32px) / 3) !important;
          max-width: 125px;
        }

        @media screen and (max-width: 468px) {
          flex-basis: calc((100% - 16px) / 3) !important;

          .productItem {
            width: 100%;
          }
        }
      }
    }
  }

  .productContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    line-height: 26px;

    @media screen and (max-width: 468px) {
      align-items: flex-start;
      text-align: left;
    }
  }

  .productIconWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;

    @media screen and (max-width: 468px) {
      width:100%
    }

    &.isSelected .productName {
      color: #FFFFFF;
      font-weight: 700;
    }
  }

  .productIcon {
    max-width: 48px;
    width: 10vw;

    @media screen and (max-width: 768px) {
      width: 80px;
    }

    @media screen and (max-width: 468px) {
      width: 60px;
    }
  }

  .productName {
    font-size: 16px;
    text-align: center;
    color: #0A1551;
    font-family: Circular;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: -10px;

    @media screen and (max-width: 468px) {
      font-size: 3.7vw;
    }
  }
`;
