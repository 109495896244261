import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/one-dollar-header-banner.scss';
import Coin from '../assets/campaigns/2024/onedollar/coin.png';

export const OneDollarHeaderBanner = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="one-dollar-campaign-wrapper">
        <div className="one-dollar-campaign-container">
          <div className="badge">
            <div className="badge-inner">
              {t('LIMITED TIME OFFER')}
            </div>
          </div>
          <div className="coin">
            <img src={Coin} alt="Coin" />
          </div>
          <div className="title-wrapper">
            <div className="title-1">
              {t('Silver Plan')}
            </div>
            <div className="title-2">
              <div className="first">
                {t('Only $1')}
              </div>
              <div className="second">
                {t('FOR A YEAR')}
                *
              </div>
            </div>
          </div>
          <a
            className="action-link locale"
            target="_blank"
            href={`/pricing/silver/yearly/?utm_campaign=onedollarsilver&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
          >
            {t('Upgrade now')}
          </a>
        </div>

      </div>
    </>
  );
};

OneDollarHeaderBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderOneDollarHeaderBanner = ({
  container,
  accountTypeName,
  utmSource,
  username
}) => {
  const root = createRoot(container);
  root.render(
    <OneDollarHeaderBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
