import React, {
  useState, useRef, useEffect, useContext
} from 'react';
import {
  shape,
  string,
  elementType,
  func,
  bool
} from 'prop-types';
import { ActionContext } from '../context';

const Notification = ({
  user,
  notification,
  NotificationRenderer,
  isAutoVisibleNotfInteracted,
  isAutoVisibleNotificationMode,
  setAutoVisibleNotificationClosed,
  ...props
}) => {
  const scNotificationOutlineRef = useRef(null);
  const [minHeight, setMinHeight] = useState(0);
  const logNotificationCenterActions = useContext(ActionContext);

  useEffect(() => {
    setMinHeight(scNotificationOutlineRef.current.offsetHeight);

    logNotificationCenterActions({
      action: 'seen',
      target: notification.actionTargetName,
      notification
    });

    return () => notification.isAutoVisible && setAutoVisibleNotificationClosed(true);
  }, []);

  return (
    <NotificationRenderer
      {...props}
      user={user}
      minHeight={minHeight}
      notification={notification}
      ref={scNotificationOutlineRef}
      isAutoVisibleNotificationMode={isAutoVisibleNotificationMode}
      isAutoVisibleNotfInteracted={isAutoVisibleNotfInteracted}
    />
  );
};

Notification.propTypes = {
  user: shape({}),
  notification: shape({
    text: string,
    confirm: shape({}),
    dismiss: shape({})
  }),
  NotificationRenderer: elementType.isRequired,
  isAutoVisibleNotificationMode: bool,
  isAutoVisibleNotfInteracted: bool,
  setAutoVisibleNotificationClosed: func
};

Notification.defaultProps = {
  user: {},
  notification: {},
  isAutoVisibleNotificationMode: false,
  isAutoVisibleNotfInteracted: false,
  setAutoVisibleNotificationClosed: f => f
};

export default Notification;
