import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/education.scss';
import EducationImage from '../assets/campaigns/2024/education/edu.svg';

export const EducationCampaignBanner = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="education-container">
        <div className="campaign-content-item">
          <EducationImage className="edu-img" />
          <div className="education-text">
            <h5>{t('Educators')}</h5>
            <h6>
              {translationRenderer('SAVE [1[50%]]')({
                renderer1: text => <strong>{text}</strong>
              })}
            </h6>
          </div>
          <a
            className="button locale"
            target="_blank"
            href={`/education/pricing/?utm_campaign=education2024&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
          >
            {t('Get Discount')}
          </a>
        </div>
      </div>
    </>
  );
};

EducationCampaignBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderEducationCampaign = ({
  container, accountTypeName, utmSource, username
}) => {
  const root = createRoot(container);
  root.render(
    <EducationCampaignBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
