import '../../styles/Dashboard.scss';
import '../../styles/Table.scss';
import '../../styles/styleOverrides.scss';
import '../../styles/Toolbar.scss';
import React, {
  ReactElement, ReactNode, useCallback, useEffect, useState, useRef
} from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { IconChevronDown, IconChevronUp } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { Button } from '../Button';
import {
  getActivityLogsTableMetadata, ACTIVITY_LOGS_DEFAULT_DATE_RANGE, ALLOWED_TEAM_LOGS, ALLOWED_LOGS, LOG_GROUPS, TEAM_LOG_GROUPS
} from './constants';
import { getLogs, exportLogs, checkExportStatus } from './api';
import { toFormattedNumber } from '../../utils';
import { ORDER_DIR_OPTIONS } from '../LazyTable/constants';
import { formatLogs, groupLogs } from './utils';
import {
  ActivityLogColumnType,
  CustomFilter,
  Filter, LogGroup, LogMeta, LogType, PrettyLog
} from './types';
import { SortProps, TableMetadataType, RowProperties } from '../LazyTable/types';
import { InfiniteScrollTable } from '../InfiniteScrollTable';
import DownloadDropdown from '../DownloadDropdown';
import { UserDetails } from './UserDetails';
import { ClearFilterButton } from '../ClearFilterButton';
import { InputInfoWrapper } from '../InputInfoWrapper';
import ActivityLogFilters from './ActivityLogFilters';
import { PrettyUser } from '../../types';
import { ActivityLogList } from './ActivityLogList';
import { PageSection } from '../PageSection';
import { PageSectionRow } from '../PageSectionRow';
import SummaryCard from '../SummaryCard';
import RangeSelector from '../RangeSelector';
import { DateFilter } from '../TimeRange/types';

const LOG_FETCH_LIMIT = 100;

const generateTableData = (
  list: PrettyLog[],
  active: SortProps<ActivityLogColumnType>,
  filters: Filter | CustomFilter,
  handleFilters: (filters: Filter | CustomFilter) => void,
  hideIP?: boolean,
  members?: PrettyUser[]
): TableMetadataType<ActivityLogColumnType> & { data: { rowContent: { value: ReactNode }[], rowProperties?: RowProperties }[], activeSort: SortProps<ActivityLogColumnType> } => {
  return {
    ...getActivityLogsTableMetadata(hideIP),
    data: list.map(item => {
      return {
        rowContent: [
          {
            value: <UserDetails
              logEntry={item}
              filters={filters}
              handleFiltersChange={handleFilters}
              members={members}
            />
          },
          { value: item.prettyFormat },
          ...(hideIP ? [] : [{ value: item.ip }]),
          { value: item.prettyDate }
        ]
      };
    }),
    activeSort: active
  };
};

const SearchInfo = () => (
  <div className="searchInfo">
    {t('You can search these fields:')}
    <ul>
      <li>{t('Name')}</li>
      <li>{t('Email')}</li>
      <li>{t('IP Address')}</li>
      <li>{t('Form Name')}</li>
      <li>{t('Form ID')}</li>
    </ul>
  </div>
);

export type ActivityLogProps = {
  /**
   * Specify which log types should be listed. Rest logs will be filtered.
   */
  allowedLogs?: LogType[],
  /**
   * Specify grouped log information if you want to show only group names in filters
   */
  logGroups?: LogGroup[],
  /**
   * If specified, logs related to that team will be listed.
   */
  teamID?: string,
  /**
   * id of the DOM element which is responsible for handling infinite scroll
   * @see https://www.npmjs.com/package/react-infinite-scroll-component for detailed explanation
   */
  scrollableTarget?: string,
  /**
   * If true, title will be visible
   * @default true
   */
  titleVisible?: boolean,
  /**
   * Title which is shown on header, if header is visible
   * @default 'Activity Logs'
   */
  title?: ReactNode,
  /**
   * If true, download button will be visible
   * @default true
   */
  downloadButtonVisible?: boolean,
  /**
   * If true, right panel for group filtering will be visible
   * @default true
   */
  rightPanelVisible?: boolean,
  /**
   * Background color of the header section
   * @default '#FFF'
   */
  headerBackgroundColor?: string,
  /**
   * User list which will be used to filter logs
   */
  filterUsers?: PrettyUser[],
  /**
   * If true, activity logs will be shown in a list view (every row will be a complete sentence rather than a tabular format)
   */
  isListView?: boolean,
  /**
   * Show error toast
   */
  showToast?: () => void,

  /**
   * User account language
   */
  userLanguage?: string
};

/**
 * Fetchs necessary logs from the API and renders them in a tabular format
 */
export const ActivityLog = ({
  allowedLogs,
  logGroups: propLogGroups,
  teamID,
  scrollableTarget,
  titleVisible,
  title = t('Activity Logs'),
  downloadButtonVisible,
  rightPanelVisible,
  headerBackgroundColor,
  filterUsers = [],
  isListView,
  showToast,
  userLanguage
}: ActivityLogProps): ReactElement => {
  const logGroups = propLogGroups || (teamID ? TEAM_LOG_GROUPS : LOG_GROUPS);
  const [logs, setLogs] = useState<PrettyLog[]>([]);
  const [lastItem, setLastItem] = useState<LogMeta | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<Filter | CustomFilter>({ date: isListView ? 'allTime' : ACTIVITY_LOGS_DEFAULT_DATE_RANGE });
  const [count, setCount] = useState<{ [key: string]: string }>({});
  const [hasMore, setHasMore] = useState(true);
  const [filtersCollapsed, setFiltersCollapsed] = useState(true);
  const [activeSort, setActiveSort] = useState(getActivityLogsTableMetadata().DEFAULT_SORT);
  const { date: filterDate, startDate: filterStartDate, endDate: filterEndDate } = filters as CustomFilter;

  const tableRef = useRef<HTMLDivElement>(null);

  const reset = () => {
    setLogs([]);
    setLastItem(null);
    setHasMore(true);
  };

  const load = () => {
    const sortBy = activeSort && activeSort.sortBy;
    const isAsc = activeSort && activeSort.sortWay === ORDER_DIR_OPTIONS.ASC;
    const queryParams = {
      next: LOG_FETCH_LIMIT,
      returnedGroupedData: true,
      ...(lastItem && lastItem),
      ...(searchTerm && { searchTerm }),
      ...filters,
      ...(sortBy && { sortBy: sortBy }),
      ...(isAsc && { sortWay: ORDER_DIR_OPTIONS.ASC }),
      ...(filters.action && { action: groupLogs(filters.action, logGroups) })
    };

    getLogs(queryParams, teamID).then(data => {
      const { logs: resLogs, meta: resMeta } = data;
      const formattedLogs = formatLogs(resLogs, allowedLogs || (teamID ? ALLOWED_TEAM_LOGS : ALLOWED_LOGS), isListView, userLanguage);
      setLogs(prevLogs => [...prevLogs, ...formattedLogs]);
      setLastItem(resMeta);
      setHasMore(resLogs.length >= LOG_FETCH_LIMIT);
    });
  };

  const loadInitial = () => {
    load();
  };

  const loadMore = () => {
    if (lastItem === null) return;
    load();
  };

  const handleFilters = (logFilters: Filter | CustomFilter) => {
    if (!isEqual(filters, logFilters)) {
      reset();
      setFilters(logFilters);
    }
  };

  const handleSort = (newSort: SortProps<ActivityLogColumnType>) => {
    reset();
    setActiveSort(newSort);
  };

  const getCounts = () => {
    const {
      date, startDate, endDate, username
    } = filters as CustomFilter;

    const queryParams = {
      countOnly: 1,
      ...(searchTerm && { searchTerm }),
      ...(date && { date }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(username && { username })
    };

    getLogs(queryParams).then(cValues => {
      if (cValues) {
        const groupCounts: { [key: string]: string } = {};
        logGroups.forEach(({ groupId, logs: gLogs }) => {
          let groupCount = 0;
          gLogs.forEach(logType => {
            if (typeof cValues[logType] !== 'undefined') {
              groupCount += cValues[logType];
            }
          });
          groupCounts[groupId] = toFormattedNumber(groupCount);
        });
        setCount(groupCounts);
      }
    });
  };

  const handleSearch = (val: string) => {
    reset();
    setSearchTerm(val);
    setSearchText(val);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchCallback = useCallback(debounce(handleSearch, 500), []);

  const debouncedSearch = (value: string) => {
    setSearchText(value);
    debouncedSearchCallback(value);
  };

  const scrollToTop = () => {
    if (tableRef?.current) tableRef.current.scrollTop = 0;
  };

  useEffect(() => {
    scrollToTop();
    loadInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filters, activeSort]);

  useEffect(() => {
    if (rightPanelVisible) {
      getCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, filterStartDate, filterEndDate, filters.username, searchTerm]);

  const tableData = generateTableData(logs, activeSort, filters, handleFilters, !!teamID, filterUsers);

  const filtersElement = (
    <div className="activity-log-tools-wrapper">
      <ActivityLogFilters
        filters={filters}
        handleFilterChange={handleFilters}
        logGroups={logGroups}
        filterUsers={filterUsers}
        userLanguage={userLanguage}
      />
      <ClearFilterButton
        filters={filters}
        setFilters={handleFilters}
        setSearch={handleSearch}
        search={searchTerm}
        initialFilter={{ date: ACTIVITY_LOGS_DEFAULT_DATE_RANGE }}
      />
    </div>
  );

  const subheader = (
    <>
      <div className={classNames('jfDashboard-panel-row', 'forSubheader', { oneLine: !downloadButtonVisible })}>
        <div className="jfToolbar-panel isLeftPanel forActivityLogTools">
          <InputInfoWrapper
            inputValue={searchText}
            onChange={debouncedSearch}
            infoTooltip={!teamID && <SearchInfo />}
          />
          {downloadButtonVisible ? (
            <DownloadDropdown
              filters={filters}
              sort={activeSort}
              searchTerm={searchTerm}
              logGroups={logGroups}
              teamID={teamID}
              dropdownId="forSubheaderDownloadDropdown"
              target="activityLog"
              exportLogs={exportLogs}
              checkExportStatus={checkExportStatus}
              showToast={showToast}
            />
          ) : filtersElement}
        </div>
      </div>
      {downloadButtonVisible && filtersElement}
    </>
  );

  return (
    <main id="activity-log-panel" className="jfActivityLog">
      <div className="page-content">
        {rightPanelVisible ? (
          <PageSection
            header={(
              <RangeSelector
                /* TODO: RangeSelector's DateFilter and filters DateFilter are slightly different but incompatible. Can
                    we standardize these two to prevent forcing types? */
                value={filters.date as DateFilter}
                showTimeFilterText={true}
                showOnlyCustomText={true}
                userLanguage={userLanguage}
              />
            )}
          >
            <div className={`jfSummaryCards jfSummaryCards-grid ${filtersCollapsed ? 'jfSummaryCards-grid-collapsed' : ''}`}>
              {logGroups.filter(logGroup => logGroup.showInRightPanel).map(logGroup => (
                <SummaryCard
                  activeCard={filters.action}
                  card={logGroup.groupId}
                  onClick={() => handleFilters({ ...filters, action: logGroup.groupId })}
                  title={logGroup.visibleName}
                  data={{ number: count[logGroup.groupId] || 0 }}
                  key={logGroup.groupId}
                />
              ))}
            </div>
            <PageSectionRow
              right={(
                <Button.Base
                  colorStyle='secondary'
                  variant='ghost'
                  size="small"
                  onClick={() => setFiltersCollapsed(prevState => !prevState)}
                  startIcon={filtersCollapsed ? IconChevronDown : IconChevronUp}
                >
                  {t(filtersCollapsed ? 'Show More' : 'Show Less')}
                </Button.Base>
              )}
            />
          </PageSection>
        ) : null}
        <PageSection header={titleVisible ? (<h1>{title}</h1>) : undefined}>
          <div className="jfTable-tools noSticky noFlex" style={{ backgroundColor: headerBackgroundColor }}>
            {!isListView && subheader}
            {!isListView && downloadButtonVisible && (
              <DownloadDropdown
                filters={filters}
                sort={activeSort}
                searchTerm={searchTerm}
                logGroups={logGroups}
                teamID={teamID}
                dropdownId="DownloadDropdown"
                target="activityLog"
                exportLogs={exportLogs}
                checkExportStatus={checkExportStatus}
                showToast={showToast}
              />
            )}
          </div>
          {isListView
            ? (
              <div
                className="forActivityLogs-overflow"
                id="forActivityLogs-scroll"
                tabIndex={0}
                role="menuitem"
              >
                <ActivityLogList
                  logs={logs}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  scrollableTarget={scrollableTarget}
                  members={filterUsers}
                  teamID={teamID}
                  userLanguage={userLanguage}
                />
              </div>
            )
            : (
              <div
                className="forActivityLogs-overflow"
                tabIndex={0}
                role="menuitem"
                ref={tableRef}
              >
                <InfiniteScrollTable
                  tableData={tableData}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  scrollableTarget={scrollableTarget}
                  changeSortOption={handleSort}
                  tableClass={classNames('forActivityLogs', { oneLine: !downloadButtonVisible })}
                  teamID={teamID}
                  emptyPage={{
                    hasSearch: !!searchText || !!filters
                  }}
                />
              </div>
            )}
        </PageSection>
      </div>
    </main>
  );
};

ActivityLog.defaultProps = {
  allowedLogs: undefined,
  teamID: undefined,
  logGroups: undefined,
  scrollableTarget: 'activity-log-panel',
  titleVisible: true,
  title: t('Activity Logs'),
  downloadButtonVisible: true,
  rightPanelVisible: true,
  headerBackgroundColor: 'transparent',
  filterUsers: [],
  isListView: false,
  showToast: () => {},
  userLanguage: ''
};
