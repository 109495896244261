import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { getColors } from './utils';

type Props = {
  /**
   * String which will be used to generate letter avatar
   */
  name?: string,
  /**
   * Size in pixel
   * @default 40
   */
  size?: number,
  /**
   * Font size in pixel
   * @default 14
   */
  fontSize?: number
} & ComponentPropsWithoutRef<'div'>;

/**
 * Component which takes the name of the user and renders a letter avatar
 */
export const LetterAvatar: FunctionComponent<Props> = ({
  name = '',
  size = 40,
  fontSize = 14,
  ...rest
}) => {
  const { bgColor: backgroundColor, fontColor: color } = getColors(name);
  const initials = name
    .split(' ')
    .filter(word => word !== '' && word !== ',')
    .slice(0, 2)
    .map(part => part.charAt(0).toUpperCase())
    .join('');
  return (
    <div
      style={{
        backgroundColor,
        color,
        height: size,
        width: size,
        fontSize,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        flex: '0 0 auto'
      }}
      {...rest}
    >
      {initials}
    </div>
  );
};

LetterAvatar.defaultProps = {
  name: '',
  size: 40,
  fontSize: 14
};
