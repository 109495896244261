import { Button as UIKitButton } from '@jotforminc/uikit';
import styled from 'styled-components';

// button types should be determine like primary, icon etc
// color and style props can be declared here or in theme file
export const Button = styled(UIKitButton)`
  background: ${p => p.theme.green};
  font-family: ${p => p.theme.fontFamily};
  font-size: 1em;
  padding: .625em .75em;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  width: ${p => (p.wide ? '100%' : 'auto')};
  border: 0;
  border-radius: ${p => p.theme.buttonRadius};

  span {
    white-space: nowrap;
  }
`;

export const PlainButton = styled(Button)``;
