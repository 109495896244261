import Styled, { css } from 'styled-components';
import { hipaaSteps } from '../../constants';

const hideCss = css`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
`;

export const ScHipaaNotification = Styled.ul`
  position: relative;

  & > li {
    transition: all .2s;
    opacity: 1;
  }

  ${({ hipaaStep }) => (hipaaStep === hipaaSteps.firstStep ? `
    & li:nth-child(1) {
      opacity: 1;
    }

    & li:nth-child(2) {
      ${hideCss}
    }
  ` : `
    & li:nth-child(1) {
      ${hideCss}
    }

    & li:nth-child(2) {
      opacity: 1;
    }
  `)}
`;
