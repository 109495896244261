import { FEATURE_LIST } from '../../constants';
import { shouldShowLimitWarnings } from '../../sagas/utils';
import { ACTION_TYPES } from '../actionTypes';
import { isAssetFoldersSupported } from '../../utils';

// to disable some servers
const SHARED_WITH_ME_V2_DISABLED_SERVERS = [
  'hallodeutschschule.jotform.com'
];

const COMMON_SORT_MENU_ENABLED_SERVERS = [
  'eeproduct1.jotform.com',
  'eeproduct2.jotform.com'
];

const initialState = {
  [FEATURE_LIST.LIMIT_WARNING]: shouldShowLimitWarnings(), // not here :/
  [FEATURE_LIST.WORKFLOW_USE_TEMPLATE]: window.JOTFORM_ENV !== 'ENTERPRISE' || window.ENTERPRISE_USE_WORKFLOW_TEMPLATES,
  [FEATURE_LIST.JOTFORM_TEAMS]: !window.enterpriseCustomizations?.listingsCustomizations?.isTeamsDisabled,
  [FEATURE_LIST.APP_BUILD_STORE]: window?.allowStoreBuilder || false,
  [FEATURE_LIST.APP_BUILD_STORE_LANDING]: window.location.href.indexOf('fromLandingStore') > -1,
  [FEATURE_LIST.APP_BUILD_DONATION]: window.location.search.includes('useDonationFeature'),
  [FEATURE_LIST.APP_BUILD_DONATION_LANDING]: window.location.href.indexOf('fromLandingDonation') > -1,
  [FEATURE_LIST.HIPAA_WARNING]: window.JOTFORM_ENV !== 'ENTERPRISE',
  [FEATURE_LIST.PREFILL]: !window.enterpriseCustomizations?.listingsCustomizations?.isPrefillDisabled,
  [FEATURE_LIST.APPS]: !window.enterpriseCustomizations?.listingsCustomizations?.isAppsDisabled,
  [FEATURE_LIST.WORKFLOW]: !window.enterpriseCustomizations?.listingsCustomizations?.isWorkflowDisabled,
  [FEATURE_LIST.GATEWAY_WARNING]: window?.__userInfo?.credentials?.squareWarningEnabled,
  [FEATURE_LIST.ASSETS_FOLDER_SUPPORT]: isAssetFoldersSupported(),
  [FEATURE_LIST.SHARED_WITH_ME_V2]: !window.location.search.includes('sharedWithMeOld') && SHARED_WITH_ME_V2_DISABLED_SERVERS.indexOf(window.location.host) === -1,
  [FEATURE_LIST.COMMON_SORT_MENU]: window.location.search.includes('newCommonSortMenu') && COMMON_SORT_MENU_ENABLED_SERVERS.indexOf(window.location.host) > -1
};

export default function featureReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.RESET_FEATURES:
      return initialState;
    case ACTION_TYPES.SET_FEATURES: {
      const { featureList = [] } = action;
      return Object.keys(state).reduce((prev, featureKey) => ({
        ...prev,
        [featureKey]: featureList.indexOf(featureKey) > -1 ? true : state[featureKey]
      }), {});
    }
    case ACTION_TYPES.FETCH_USER.SUCCESS: {
      const { user } = action;
      return {
        ...state,
        [FEATURE_LIST.ASSETS_FOLDER_SUPPORT]: isAssetFoldersSupported(user?.credentials)
      };
    }
    default:
      return state;
  }
}
