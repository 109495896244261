export const BG_IMAGE_SET = [
  {
    text: 'NATURE',
    images: [
      'https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?w=1200&h=80',
      'https://images.unsplash.com/photo-1491147334573-44cbb4602074?w=1200&h=80',
      'https://images.unsplash.com/photo-1490358930084-2d26f21dc211?w=1200&h=80',
      'https://images.unsplash.com/photo-1576806817362-9ed6edf33591?w=1200&h=80',
      'https://images.unsplash.com/photo-1500964757637-c85e8a162699?w=1200&h=80',
      'https://images.unsplash.com/photo-1556103727-777acb371272?w=1200&h=80',
      'https://images.unsplash.com/photo-1551376347-075b0121a65b?w=1200&h=80'
    ]
  },
  {
    text: 'ART & CULTURE',
    images: [
      'https://images.unsplash.com/photo-1621886292650-520f76c747d6?w=1200&h=80',
      'https://images.unsplash.com/photo-1515448315142-dca41dcde981?w=1200&h=80',
      'https://images.unsplash.com/photo-1547891654-e66ed7ebb968?w=1200&h=80',
      'https://images.unsplash.com/photo-1554139681-1adb48e035cb?w=1200&h=80'
    ]
  },
  {
    text: 'SPACE',
    images: [
      'https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?w=1200&h=80',
      'https://images.unsplash.com/photo-1519681393784-d120267933ba?w=1200&h=80',
      'https://images.unsplash.com/photo-1532798369041-b33eb576ef16?w=1200&h=80',
      'https://images.unsplash.com/photo-1545243424-0ce743321e11?w=1200&h=80',
      'https://images.unsplash.com/photo-1589225529399-8705282f98e2?w=1200&h=80',
      'https://images.unsplash.com/photo-1451187580459-43490279c0fa?w=1200&h=80'
    ]
  },
  {
    text: 'BUSINESS',
    images: [
      'https://images.unsplash.com/photo-1507904139316-3c7422a97a49?w=1200&h=80',
      'https://images.unsplash.com/photo-1535498730771-e735b998cd64?w=1200&h=80',
      'https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?w=1200&h=80',
      'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?w=1200&h=80'
    ]
  }
];

export const AVATAR_SET = [
  {
    text: 'ANIMALS',
    images: [
      'https://images.unsplash.com/photo-1517849845537-4d257902454a',
      'https://images.unsplash.com/photo-1537151608828-ea2b11777ee8',
      'https://images.unsplash.com/photo-1581300134629-4c3a06a31948',
      'https://images.unsplash.com/photo-1547721064-da6cfb341d50',
      'https://images.unsplash.com/photo-1514571948039-d3cb9e8f9750',
      'https://images.unsplash.com/photo-1535076766578-839cd64d7a73',
      'https://images.unsplash.com/photo-1535062311770-93adb0401917',
      'https://images.unsplash.com/photo-1612372992697-81c95501d0d1',
      'https://images.unsplash.com/photo-1605092676920-8ac5ae40c7c8',
      'https://images.unsplash.com/photo-1590079015089-875baa76cc8d',
      'https://images.unsplash.com/photo-1522069169874-c58ec4b76be5',
      'https://images.unsplash.com/photo-1590787996529-a542c86ca267',
      'https://images.unsplash.com/photo-1591025207163-942350e47db2'
    ]
  },
  {
    text: 'NATURE',
    images: [
      'https://images.unsplash.com/photo-1522688306378-fbb315c6ba9a',
      'https://images.unsplash.com/photo-1606041008023-472dfb5e530f',
      'https://images.unsplash.com/photo-1540490190210-9e2825f75418',
      'https://images.unsplash.com/photo-1604820849194-837928614a4a',
      'https://images.unsplash.com/photo-1453791052107-5c843da62d97',
      'https://images.unsplash.com/photo-1502003148287-a82ef80a6abc'
    ]
  },
  {
    text: 'BUSINESS',
    images: [
      'https://images.unsplash.com/photo-1513171920216-2640b288471b',
      'https://images.unsplash.com/photo-1555323912-3526a765162b'
    ]
  },
  {
    text: 'ARTS & CULTURE',
    images: [
      'https://images.unsplash.com/photo-1646001943936-f1edc87d4ac5',
      'https://images.unsplash.com/photo-1634986666676-ec8fd927c23d',
      'https://images.unsplash.com/photo-1628526799996-0cfe210f5fbc'
    ]
  },
  {
    text: 'TECHNOLOGY',
    images: [
      'https://images.unsplash.com/photo-1485827404703-89b55fcc595e',
      'https://images.unsplash.com/photo-1536697246787-1f7ae568d89a'
    ]
  }
];

export const DEFAULT_SELECTED_OPTIONS = {
  teamAvatarURL: '',
  teamAvatarIcon: '',
  teamAvatarIconSvgRef: '',
  teamAvatarIconColor: '',
  teamAvatarIconBgColor: '#18235C',
  teamAvatarEmojiId: 'european_castle'
};

export const DEFAULT_TEAM = {
  id: '',
  name: '',
  showCoverImage: '',
  teamAvatarIconBgColor: '',
  teamAvatarIconColor: '',
  teamAvatarURL: '',
  teamAvatarIcon: '',
  teamAvatarEmojiId: '',
  coverTitleColor: '',
  teamAvatarIconSvgRef: '',
  coverBgImageURL: '',
  coverBgColor: '',
  coverBgImageCropInfo: '',
  members: []
};
