import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewTableWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewTableWizard" */'./LazyWizards/LazyCreateNewTableWizard'));

export const openCreateNewTableModal = ({ showRecommendation, showOnlineStoreRecommendation, ...props }) => {
  const containerSelector = '#modal-container';
  const rootElement = document.querySelector(containerSelector);
  const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

  const handleClose = () => {
    window.history.replaceState({}, '', window.location.pathname);
    unmountComponentAtNode(rootElement);
    if (props.closeButtonCallback) {
      props.closeButtonCallback();
    }
  };

  const appTemplatesCountCheck = props?.fromOnLineStoreLandingFeature ? window?.APP_TEMPLATES_COUNT_FROM_LANDING?.SHORT : window?.APP_TEMPLATES_COUNT?.SHORT;

  const onViewChange = isMain => {
    if (createFlowWrapperElement) {
      if (isMain) {
        createFlowWrapperElement.classList.remove('isHidden');
      } else {
        createFlowWrapperElement.classList.add('isHidden');
      }
    }
  };

  const handleShow = () => {
    if (props.openWizardCallback) props.openWizardCallback();
  };

  return lazyRender({
    root: rootElement,
    children: (
      <CreateNewTableWizard
        {...props}
        showCloneApp={true}
        onShow={handleShow}
        onHide={handleClose}
        initialVisibilityState={true}
        showRecommendation={showRecommendation}
        showBuildStoreApp={props?.showBuildStoreAppFeature || false}
        showOnlineStoreRecommendation={showOnlineStoreRecommendation}
        showBuildDonationApp={props?.showBuildDonationAppFeature || false}
        fromOnLineStoreLanding={props?.fromOnLineStoreLandingFeature || false}
        appTemplatesCount={appTemplatesCountCheck || window?.APP_TEMPLATES_COUNT?.SHORT}
        onViewChange={onViewChange}
      />
    )
  });
};
