/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import Fuse from 'fuse.js';
import querystring, { stringify } from 'qs';
import isArray from 'lodash/isArray';

import { SAC_API } from '@jotforminc/save-and-continue';
import { saveCustomerAsHubspotFormByUsername } from '@jotforminc/ep-utils';
import {
  normalizeForm, saveBlobAsFile, saveURLAsFile, isProduction, isV2EndpointEnabled,
  getAssetTypeFromListingType
} from '../utils';
import {
  ASSET_TYPES, FOLDER_TYPES, LISTING_TYPES, LISTING_TYPES_REVERT
} from '../constants';
import { r } from './requestLayer';

const withPrefetchedData = (prefetchedKey, handler) => {
  if (typeof window[prefetchedKey] === 'undefined') {
    return handler;
  }

  return (...params) => {
    let prefetchedData = window[prefetchedKey];
    if (typeof prefetchedData === 'undefined') {
      return handler(...params);
    }
    if (Array.isArray(window[prefetchedKey])) {
      prefetchedData = [...window[prefetchedKey]];
    } else {
      prefetchedData = { ...window[prefetchedKey] };
    }
    delete window[prefetchedKey];
    return Promise.resolve(prefetchedData);
  };
};

export function fetchCombinedUser() {
  return r.get('user/combinedinfo?formCount=1&isAppsCreationFlowAvailable=1&campaign=1&listings=1');
}

export const fetchUser = withPrefetchedData('__userInfo', fetchCombinedUser);

export function fetchUserPermissions(teamID = '') {
  return r.get('user/permissions?productType=myforms', { headers: teamID ? { 'jf-team-id': teamID } : {} });
}

export const fetchPermissionInfoUser = withPrefetchedData('__userPermissionsInfo', fetchUserPermissions);

export const fetchUserTeamRoles = () => {
  return r.get('/API/team/roles/me');
};

export const fetchUserEmailBounce = () => {
  return r.get('user/bounced');
};

export const isAustralianEnterpriseUser = username => {
  return r.get(`user/australian-enterprise-check/${username}`);
};

export const checkUserRegistrationHour = (hour = 24) => {
  return r.get(`/server/userRegistrationTimeCheck/${hour}`);
};

export const actionTrack = data => r.post('t/jfrev', data, {
  headers: {
    'Content-Type': 'application/json'
  }
});

export const fetchUserOwnersAPI = () => {
  return r.get('user/subusers/owners');
};

export const fetchUserOwners = withPrefetchedData('__sharedAccounts', fetchUserOwnersAPI);

export function fetchUserPlansAPI() {
  return r.get('system/plans');
}

export const fetchUserPlans = withPrefetchedData('__systemPlans', fetchUserPlansAPI);

export const hipaaEnforcementProgramSubmission = (filter, lightBoxActionKey) => {
  return r.get(`hipaaEnforcementProgramSubmission?filter=${filter}&lightboxStatus=${lightBoxActionKey}`);
};

export const fetchHIPAAEnforcementTerminatedUser = () => r.get('hipaa-enforcement/user');

export const closeEnterpriseBanner = (username, action) => {
  return r.get(`enterpriseLeadGeneration/user/${username}/${action}`);
};

export const abandonedUserModalClose = () => {
  const formData = querystring.stringify({ abandonedUser: '2' });
  return r.post('user/settings', formData);
};

export const acceptKVKK = () => {
  const formData = querystring.stringify({ kvkk: '1' });
  return r.post('user/settings', formData);
};

export function fetchAssignedForms(query) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    addProperties: 1
  };

  return r.get(`formuser/forms?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export function fetchForm(formID) {
  return r.get(`form/${formID}`);
}

export function fetchDraftForms(query) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    addProperties: 1
  };

  return r.get(`formuser/draftForms?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true }).then(response => {
    const { data } = response;
    return {
      ...response,
      data: {
        ...data,
        content: data.content.map(item => {
          const { id: formID, pendingSub, saclSub } = item;

          return {
            ...item,
            formID,
            id: pendingSub?.submission_id || saclSub
          };
        })
      }
    };
  });
}

function prepareFormQuery(query) {
  return {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    addProperties: 1
  };
}

function prepareApprovalsQuery(query) {
  return {
    ...query,
    filter: JSON.stringify({
      ...query?.filter,
      'type:eq': 'default'
    })
  };
}

function prepareAppsQuery(query) {
  const { filter = {}, orderby = '', ...rest } = query;
  const hasFilter = !!Object.keys(filter).length;
  return {
    ...rest,
    ...(orderby ? { orderby } : {}), // include orderby only if meaningful..
    ...(hasFilter ? { filter: JSON.stringify(filter) } : {}) // include filter only if meaningful..
  };
}

function prepareTablesQuery(query) {
  return {
    ...query,
    includeForms: 1,
    includeShare: 1,
    includeTotal: 1,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
}

function prepareReportsQuery(query) {
  return {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
}

function prepareSignDocumentsQuery(query) {
  return {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
}

function prepareBoardsQuery(query) {
  const filter = query.filter || {};
  filter.form_type = ['BOARD'];
  return {
    ...query,
    includeForms: 1,
    includeShare: 1,
    includeTotal: 1,
    filter: JSON.stringify(filter)
  };
}

function prepareAgentsQuery(query) {
  return {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
}

function getUserAssetsQueryParams({ type, query }) {
  switch (type) {
    case LISTING_TYPES.FORM: {
      const { isCreationTest, ...restQuery } = query;
      return {
        ...restQuery,
        ...(!isCreationTest ? { addProperties: 1, includeSharedForms: 0 } : {}),
        filter: restQuery.filter ? JSON.stringify(restQuery.filter) : undefined
      };
    }
    case LISTING_TYPES.SHEET: {
      return prepareTablesQuery(query);
    }
    case LISTING_TYPES.REPORT: {
      return prepareReportsQuery(query);
    }
    case LISTING_TYPES.PORTAL: {
      return prepareAppsQuery(query);
    }
    case LISTING_TYPES.WORKFLOW: {
      return prepareApprovalsQuery(query);
    }
    case LISTING_TYPES.DOCUMENT: {
      return prepareSignDocumentsQuery(query);
    }
    case LISTING_TYPES.MIX: { // todo :: need to be refactored
      return {
        ...query,
        addProperties: 1,
        includeSharedForms: 0,
        filter: query.filter ? JSON.stringify(query.filter) : undefined,
        ...prepareTablesQuery(query),
        ...prepareReportsQuery(query),
        ...prepareAppsQuery(query),
        ...prepareApprovalsQuery(query),
        ...prepareSignDocumentsQuery(query)
      };
    }
    case LISTING_TYPES.BOARD: {
      return prepareBoardsQuery(query);
    }
    default:
      return {};
  }
}

export async function fetchUserForms(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  const { disableTeamIDHeader = false, ...rest } = query || {};
  return r.get(`user/forms?${querystring.stringify(getUserAssetsQueryParams({ type: LISTING_TYPES.FORM, query: rest }))}`, { disableResponseNormalizer: true, disableTeamIDHeader });
}

export const fetchForms = withPrefetchedData('__forms', fetchUserForms);

export async function fetchFormProductInfo(formID) {
  return r.get(`form/${formID}/product/info?productList=app,report,approval,pdf,boards,appStore,ai-agent`);
}

export function fetchAllFormsWithSearch(payload = {}) {
  const extendedQuery = {
    ...payload,
    filter: payload.filter ? JSON.stringify(payload.filter) : undefined
  };

  return r.get(`user/forms?${querystring.stringify(extendedQuery)}`);
}

export function fetchAllForms({
  limit = 10000,
  offset = 0
} = {}) {
  return r.get(`user/forms?limit=${limit}&offset=${offset}`);
}
export const fetchAllFormsInfo = withPrefetchedData('__allFormsInfo', fetchAllForms);

export function fetchTeamAllFormsWithAgentInfo({
  limit = 10000,
  offset = 0
} = {}) {
  const queryParams = {
    limit,
    offset,
    addAIAgentInfo: 1,
    assetTypes: ['form']
  };
  return r.get(`mixed-listing/assets?${querystring.stringify(queryParams)}`);
}

export function fetchAllFormsWithAgentInfo({
  limit = 10000,
  offset = 0
} = {}) {
  return r.get(`user/forms?limit=${limit}&offset=${offset}&addAIAgentInfo=1`);
}

export const fetchSharedWithMeInfo = withPrefetchedData('__sharedWithMe', async () => {
  return r.get('/server/get-shared-resources');
});

export async function fetchSharedWithMeStructure() {
  return r.get('share/shared-with-me/structure');
}

export async function fetchSharedWithMeByOwnerV2({ query, owner }) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    owner
  };
  const response = await r.get(`share/shared-with-me/owner/resources?${querystring.stringify(extendedQuery)}`);

  return {
    data: { content: response }
  };
}

export async function fetchSharedWithMeAllV2({ query, isGuestUser }) {
  if (isGuestUser) {
    return Promise.resolve({
      data: { content: [] }
    });
  }

  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    sharedWithMeV2: '1'
  };
  const response = await r.get(`share/getAllShareResources?${querystring.stringify(extendedQuery)}`);

  return {
    data: { content: response }
  };
}

export async function fetchSharedWithMeForms() {
  // The endpoint is api/myforms/shared/resources -> notice the lowercase "api"
  const response = await fetchSharedWithMeInfo();

  const { forms = [], folders = [], ...remainingData } = response;
  const normalizedForms = forms.map(normalizeForm);
  window.__temp__sharedWithMeResources = { forms: normalizedForms, folders, ...remainingData };
  window.__temp__sharedwithMeFormsFuse = new Fuse(normalizedForms, {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 300,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['title', 'id'],
    useExtendedSearch: true
  });
  return {
    data: {
      content: { forms, folders, ...remainingData }
    }
  };
}

// FOLDERS - BEGIN

export async function fetchFolderForms({ query = {}, folderID, isShared }) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  const queryParams = {
    ...query,
    ...isShared ? { returnAccessType: 1 } : {}
  };
  return r.get(`folder/${folderID}/forms?${querystring.stringify(prepareFormQuery(queryParams))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderFormsNew(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`folder/${folderID}/assets/forms?${querystring.stringify(prepareFormQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderApprovals(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  return r.get(`folder/${folderID}/assets/workflows?${querystring.stringify(prepareApprovalsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderApps(query, folderID) {
  return r.get(`folder/${folderID}/assets/portals?${querystring.stringify(prepareAppsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderTables(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`folder/${folderID}/assets/sheets?${querystring.stringify(prepareTablesQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderBoards(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`folder/${folderID}/assets/sheets?${querystring.stringify(prepareBoardsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderAgents(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`folder/${folderID}/assets/agents?${querystring.stringify(prepareAgentsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderReports(query, folderID) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  return r.get(`folder/${folderID}/assets/reports?${querystring.stringify(prepareReportsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchFolderSignDocuments(query, folderID) {
  return r.get(`folder/${folderID}/assets/documents?${querystring.stringify(prepareSignDocumentsQuery(query))}`).then(response => ({
    data: {
      content: response,
      resultSet: {
        count: response.length,
        limit: 50,
        offset: 0
      }
    }
  }));
}
export const fetchFoldersAPI = isV2 => {
  return r.get(`user/folders?addForms=0${isV2 ? '&v2=1' : ''}`);
};

export const fetchFolders = withPrefetchedData('__folders', fetchFoldersAPI);

export const updateAssetFolder = (id, data) => {
  return r.put(`folder/${id}`, data);
};

export const updateTeamFolderProps = (folderID, teamID, data) => {
  return r.put(`team/${teamID}/folder/${folderID}/properties`, data, { headers: { 'jf-team-id': teamID } });
};

export const addAssetFolder = data => {
  return r.post('folder', querystring.stringify(data));
};

export const addAssetFolderToTeam = (data, teamID) => {
  return r.post(`team/${teamID}/folder/create`, querystring.stringify(data), { headers: { 'jf-team-id': teamID } });
};

export const deleteAssetFolder = (folderID, isV2) => {
  return r.delete(`folder/${folderID}${isV2 ? '?v2=1' : ''}`);
};

export const deleteTeamFolder = (teamID, folderID) => {
  return r.delete(`team/${teamID}/folder/${folderID}`, { headers: { 'jf-team-id': teamID } });
};

export const addFormToFolder = ({ folderID, formID }) => {
  return r.post(`form/${formID}/folder/${folderID}`);
};

export const addToTeamFolder = ({
  folderID,
  formID,
  teamID,
  type
}) => {
  const payload = querystring.stringify({
    resources: [
      {
        resource_id: formID,
        resource_type: type
      }
    ],
    folder_id: folderID
  });
  return r.put(`team/${teamID}/folder/${folderID}/add?${payload}`, { headers: { 'jf-team-id': teamID } });
};

export const removeFormFromFolder = ({ formID, folderID }) => {
  return r.delete(`form/${formID}/folder/${folderID}`);
};

export const removeItemFromTeamFolder = ({
  formID,
  folderID,
  teamID,
  type
}) => {
  const payload = querystring.stringify({
    resources: [
      {
        resource_id: formID,
        resource_type: type
      }
    ],
    folder_id: folderID
  });
  return r.delete(`team/${teamID}/folder/${folderID}/remove?${payload}`, { headers: { 'jf-team-id': teamID } });
};

// FOLDERS - END

export function fetchApps(query) {
  return r.get(`user/portals?${querystring.stringify(prepareAppsQuery(query))}`, { disableResponseNormalizer: true });
}

export function fetchReports(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`user/reports?${querystring.stringify(prepareReportsQuery(query))}`, { disableResponseNormalizer: true });
}

export function fetchSharedReports() {
  return r.get('user/sharedReports', { disableResponseNormalizer: true });
}

export function fetchSharedTables(query) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
  return r.get(`share/sheets/withme?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export function fetchSharedBoards(query) {
  const filter = query.filter || {};
  filter.form_type = ['BOARD'];
  const extendedQuery = {
    ...query,
    filter: JSON.stringify(filter)
  };
  return r.get(`share/sheets/withme?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export function fetchSharedWithMe(query) {
  const extendedQuery = {
    ...query,
    products: 'inbox,pdf-editor,form-builder',
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
  return r.get(`sharedWithMe?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export function fetchUserSubmissions() {
  const extendedQuery = {
    // ...query,
    // filter: query.filter ? JSON.stringify(query.filter) : undefined,
    addForm: 1,
    nocache: 1
  };

  return r.get(`mysubmissions?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export function fetchFilledForms(query) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined,
    nocache: 1
  };
  return r.get(`filledforms?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export async function fetchContactsByUserName(query) {
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };
  const contacts = await r.get(`contacts?${querystring.stringify(extendedQuery)}`);
  return {
    data: {
      content: contacts
    }
  };
}

export async function fetchPagesByUsername(query) {
  const { filter } = query;
  const { favorite } = filter;
  const pages = await r.get(`pages?${new URLSearchParams({
    ...query,
    filter: JSON.stringify({ ...filter, ...(favorite && { properties: { favorite } }) })
  })}`);
  return {
    data: {
      content: pages.map(page => ({ ...page, ...(page?.properties || {}) }))
    }
  };
}

export async function clonePage(projectID) {
  return r.post(`page/projects/${projectID}/clone`);
}

export async function updatePageStatus(projectID, status) {
  return r.post(`page/projects/${projectID}`, new URLSearchParams({
    data: JSON.stringify({
      status
    })
  }));
}

export const favoritePageForUser = (projectID, favorite) => {
  const formData = new window.FormData();
  formData.append('data', JSON.stringify({
    properties: {
      favorite: favorite === '0' ? '' : '1'
    }
  }));

  return r.post(`page/projects/${projectID}`, formData);
};

export function fetchFormQuestions(formID) {
  const formData = querystring.stringify({ action: 'getQuestions', formID, onlyDataFields: true });

  return r.post('/server.php', formData).then(({ questions }) => questions);
}

export function createReport(formID, report) {
  const newReport = { ...report };
  delete newReport.form_id;
  return r.post(`form/${formID}/reports`, querystring.stringify(newReport));
}

export function createContact(contactDetails) {
  return r.post('contacts', { properties: contactDetails });
}

export function cloneReport(reportID) {
  return r.get(`report/${reportID}/clone`);
}

export function downloadReport(reportID) {
  return r.get(`presentation/${reportID}/download/pdf`, { responseType: 'blob' });
}

export function downloadVisualReport({
  id, title
}) {
  downloadReport(id).then(response => saveBlobAsFile(new global.Blob([response], { type: 'application/pdf' }), `${title}.pdf`));
}

export function handleDownloadReport({
  type, url, title, id
}) {
  if (type === 'reports') { // visual reports
    downloadVisualReport({ id, title });
  } else if (['cal', 'grid', 'table', 'rss'].indexOf(type) > -1) { // listing reports
    saveURLAsFile(url, title);
  } else {
    window.location = url;
  }
}

export function getReportsSummary() {
  return r.get('reports/summary');
}

export function updateApp(portalID, properties) {
  return r.post(`portal/${portalID}`, properties);
}

export function getSummary() {
  return r.get('formuser/summary');
}

export function discardSubmissionDraft(pendingID) {
  return r.delete(`user/pendingsubmission/${pendingID}`);
}

export function discardDraft(continueKey, formID) {
  return SAC_API.remove(formID, continueKey);
}

export function deleteFlow(flowID) {
  return r.delete(`workflow/${flowID}`);
}

export function purgeFlowItem(flowID) {
  return r.delete(`workflow/${flowID}`);
}

export async function fetchTables(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`user/sheets?${querystring.stringify(prepareTablesQuery(query))}`, { disableResponseNormalizer: true });
}

export async function fetchBoards(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }

  return r.get(`user/sheets?${querystring.stringify(prepareBoardsQuery(query))}`, { disableResponseNormalizer: true });
}

export function getTablesSummary() {
  return r.get('user/sheets/summary');
}

export function onRemoveAccessRequest(id) {
  return r.post(`sheets/share/sheets/${id}/link`);
}

export function onApproveAccessRequest(id) {
  return r.post(`sheets/share/sheets/${id}/link`);
}

export function getApprovalsSummary() {
  return r.get('workflow/approvalsSummary');
}

export function fetchSignDocuments(query) {
  return r.get(`sign?${querystring.stringify(prepareSignDocumentsQuery(query))}`).then(response => ({
    data: {
      content: response,
      resultSet: {
        count: response.length,
        limit: 50,
        offset: 0
      }
    }
  }));
}

// ABTest: signWalkthroughForRegular and signWalkthroughForTemplate
const runSignWalkthroughAbTest = () => {
  const isMobile = window.innerWidth >= 800 && window.innerHeight >= 440 ? '0' : '1';
  return r.get(`/API/abtest/sign-walkthrough?template=0&mobile=${isMobile}`);
};

export async function createSignDocument({
  files, isDemo: demo = '0', modifyContent = false, teamID, folderID, variantCode = ''
}) {
  if (demo && isProduction()) {
    // 241344004659049 is our new sample agreement with ready-to-use annotations.
    // 241364552402045 is our new sample agreement without annotations which showTipsByDefault is set as "Yes".
    return r.post(`/API/sign/${variantCode === '22281' ? '241364552402045' : '241344731896059'}/clone`, { keepTitle: true });
  }

  const formData = new global.FormData();

  formData.append('demo', demo);
  formData.append('modifyContent', modifyContent);
  if (folderID) formData.append('folderID', folderID);

  files.forEach(file => {
    formData.append('file[]', file);
  });

  runSignWalkthroughAbTest();
  return r.post('sign', formData, { headers: { ...teamID ? { 'jf-team-id': teamID } : {}, disableTeamIDHeader: true } });
}

// commonized for sheet & report for now. @TODO convert class to func
export class ShareActions {
  constructor(currentPage) {
    this.currentPage = currentPage === 'sheet' ? 'sheets' : currentPage;
    this.onPropertyChange = assetType => (assetType === 'sheets' ? this.onTablePropertyChange : this.onReportPropertyChange);
    Object.getOwnPropertyNames(this).forEach(item => {
      const property = this[item];
      if (typeof property === 'function') {
        property.withCustomListType = type => (...args) => {
          const currentType = this.currentPage;
          if (['teamPage', 'mix'].indexOf(this.currentPage) > -1) {
            this.currentPage = type === 'sheet' ? 'sheets' : type;
          }
          const result = property(...args);
          this.currentPage = currentType;
          return result;
        };
      }
    });
  }

  generateShareLink = (id, config) => r.post(`share/${this.currentPage}/${id}/link`, querystring.stringify(config));

  getShareProps = id => r.get(`/API/sheets/sheet/${id}/shareResources`);

  onSend = (id, props) => {
    const { emails: users, ...rest } = props;
    return r.post(`/API/sheets/share/${this.currentPage}/${id}/bulkShare`, querystring.stringify({ users, ...rest }));
  };

  onResendInvitation = id => r.get(`/API/sheets/share/${id}/resendInvitation`, { disableResponseNormalizer: true });

  onRevokeUser = id => r.post('/API/sheets/share/bulkDelete', querystring.stringify({ resourceShareIDs: [id] }));

  onRevokeMultipleUser = ids => r.post('/API/sheets/share/bulkDelete', querystring.stringify({ resourceShareIDs: ids }));

  onAssigneePermissionChange = (id, role) => r.post(`/API/sheets/share/${id}/update`, querystring.stringify({ permissions: { role } }));

  onTablePropertyChange = (id, payload) => r.post(`/API/sheets/share/${id}/update`, querystring.stringify(payload));

  onReportPropertyChange = async (presentationID, payload) => {
    // It seems that posting the change to /listings causes a 404.
    // as such this request should be sent to /sheets directly
    return r.post(
      `/API/sheets/presentation/${presentationID}/properties?${querystring.stringify({ properties: { ...payload.permissions } })}`,
      undefined,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );
  };

  getSheetShareInfo = id => r.get(`/API/sheets/share/${this.currentPage}/${id}`);
}

export async function fetchApprovals(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'GUEST') {
    // We want guests to see empty list and create flow if they want.
    // API returns 401 for guests on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  const extendedQuery = {
    ...query,
    filter: query.filter ? JSON.stringify(query.filter) : undefined
  };

  return r.get(`/API/inbox/user/tasks?${querystring.stringify(extendedQuery)}`, { disableResponseNormalizer: true });
}

export async function fetchMyApprovals(query) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  return r.get(`user/workflows?${querystring.stringify(prepareApprovalsQuery(query))}`, { disableResponseNormalizer: true });
}

export async function updateApprovalProperties(id, payload) {
  return r.post(`workflow/${id}/properties`, payload);
}

export function cloneSignDocument(documentID) {
  return r.post(`sign/${documentID}/clone`);
}

export function deleteSignDocument(documentID) {
  return r.delete(`sign/${documentID}`);
}

export function updateSignDocument(documentID, props) {
  return r.post(`sign/${documentID}`, props);
}

export function updateAgent(agentID, props) {
  return r.put(`ai-agent-builder/agents/${agentID}`, props);
}

export function purgeAgent(agentID) {
  return r.delete(`ai-agent-builder/agents/${agentID}`);
}

export function cloneAgent({ id, title, cloneResources }) {
  return r.post(`ai-agent-builder/agents/${id}/clone`, { title, cloneResources });
}

function getUserSettingAPI() {
  return r.get('user/settings');
}

export const getUserSettings = withPrefetchedData('__userSettings', getUserSettingAPI);

export function fetchUserFolderLayout() {
  return r.get('user/settings/folderLayout?parseJSON=1');
}

export const fetchFolderLayout = withPrefetchedData('__folderLayout', fetchUserFolderLayout);

export function fetchFolder(folderID) {
  return r.get(`folder/${folderID}`);
}

export function updateUserSettings(data) {
  const formData = new global.FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return r.post('user/settings', formData);
}

export function transferForm({ formID, username, folders = '' }) {
  return r.post('/server.php',
    querystring.stringify({
      username,
      action: 'moveForm',
      model: JSON.stringify({
        id: formID,
        action: 'transfer',
        attributes: { folders, username, owner: username }
      })
    }),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } });
}

export const cloneForm = ({ formID, username }) => {
  return r.post(
    '/server.php',
    querystring.stringify({
      formID,
      username,
      action: 'cloneForm',
      preserveFormType: '1'
    }),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } }
  );
};

export const archiveForm = (formID, archive) => r.post(`form/${formID}/archive`, querystring.stringify({ archive: archive ? '1' : '0' }));

export const favoriteForm = (formID, favorite) => r.post(`form/${formID}/favorite`, querystring.stringify({ favorite: favorite ? '1' : '0' }));

export const favoriteContact = (contactID, favorite) => r.post(`contacts/${contactID}/favorite`, querystring.stringify({ favorite }));

export const favoriteReport = (reportID, star) => r.post(`report/${reportID}/properties`, { star });

export const updateFormItem = (formID, properties) => r.post(`form/${formID}/properties`, querystring.stringify({ properties }));

export const purgeFormItem = formID => r.delete(`form/${formID}/purge`);

export const restoreFormItem = formID => r.get(`form/${formID}/restore`);

export const bulkMarkAsReadForm = formIDList => r.post('form/markAsRead', querystring.stringify({ formIDList }));

export const getFormPortals = formID => r.get(`form/${formID}/portals`);

export const getFormReports = formID => r.get(`form/${formID}/allReports`);

export const getFormBoards = formID => fetchBoards({ filter: { source_form_id: formID, status: 'ENABLED' }, limit: 100 });

export const getReport = reportID => r.get(`report/${reportID}`);

export const getDigest = digestID => r.get(`digest/${digestID}`);

export class CommonActions {
  constructor(currentPage) {
    this.currentPage = currentPage === 'document' ? 'sign' : currentPage;
    this.currentPage = currentPage === 'board' ? 'sheet' : currentPage;
    Object.getOwnPropertyNames(this).forEach(item => {
      const property = this[item];
      if (typeof property === 'function') {
        property.withCustomListType = type => (...args) => {
          const currentType = this.currentPage;
          if (['teamPage', 'mix'].indexOf(this.currentPage) > -1) {
            this.currentPage = type;
          }
          const result = property(...args);
          this.currentPage = currentType;
          return result;
        };
      }
    });
  }

  normalizeURL(url) {
    return url.startsWith('/') ? url.slice(1) : url;
  }

  deleteItem = (id, customPath = null) => {
    const listingType = this.currentPage === 'page' ? `${this.currentPage}/projects` : this.currentPage;
    return r.delete(this.normalizeURL(customPath || `/${listingType}/${id}`));
  };

  bulkUpdateList = (prop, value, conditions, teamID) => {
    if (this.currentPage === 'mix') {
      return r.post('mixed-listing/assets/bulk_update', querystring.stringify({ prop, value, assets: conditions }));
    }

    const urlParam = this.currentPage === 'sheet' ? '?forceCreate=1' : '';
    const isTeamPage = this.currentPage === 'teamPage';
    let listingType = this.currentPage === 'page' ? `${this.currentPage}/projects` : this.currentPage;

    if (this.currentPage === 'agent') {
      listingType = 'ai-agent-builder';
    }
    return r.post(
      `${isTeamPage ? `team/${teamID}/resources` : listingType}/bulk_update${urlParam}`,
      querystring.stringify({ prop, value, [isTeamPage ? 'resources' : 'conditions']: conditions })
    );
  };

  bulkDeleteList = (conditions, teamID) => {
    if (this.currentPage === 'mix') {
      return r.post('mixed-listing/assets/bulk_delete', querystring.stringify({ assets: conditions }));
    }
    const isTeamPage = this.currentPage === 'teamPage';
    const listingType = this.currentPage === 'page' ? `${this.currentPage}/projects` : this.currentPage;
    return r.post(`${isTeamPage ? `team/${teamID}/resources` : listingType}/bulk_delete`, querystring.stringify({ [isTeamPage ? 'resources' : 'conditions']: conditions }));
  };

  updateItem = (id, props) => {
    const { customPath, method = 'post', ...otherProps } = props;
    if (this.currentPage === 'form') {
      return updateFormItem(id, props);
    }

    const urlParam = this.currentPage === 'sheet' ? '?forceCreate=1' : '';
    if (method === 'post') {
      return r.post(this.normalizeURL(customPath || `/${this.currentPage}/${id}${urlParam}`), otherProps);
    }
    if (method === 'put') {
      return r.put(this.normalizeURL(customPath || `/${this.currentPage}/${id}${urlParam}`), otherProps);
    }
  };

  getSharedWithme = () => r.get(`share/${this.currentPage}/withme`);
}

export function deleteMultipleApps(portalIDs) {
  return r.post('portal/bulk_delete', { portalIDs });
}

export function deleteMultipleAgents(agentIDs) {
  return r.post('ai-agent-builder/bulk_delete', querystring.stringify({ agentIDs }));
}

export const favoriteAppForUser = (portalID, favorite) => {
  return r.post(`portaluser/portal/${portalID}/properties`, { favorite });
};

export async function cloneApp(id, withForm, appProperties) {
  return r.post(`portal/${id}/clone?cloneForms=${withForm ? '1' : '0'}`, { appProperties });
}

export async function getUserTeams({
  disableJotFormNormalize, addMemberCount = true, addProperties = true, addMembers = true, addPermissions = false, addSubfolders = true, limit, offset
} = {}) {
  const query = querystring.stringify({
    addMemberCount: addMemberCount ? '1' : '0',
    returnTeamProperties: addProperties ? '1' : '0',
    returnMembers: addMembers ? '1' : '0',
    addTeamPermissions: addPermissions ? '1' : '0',
    ...(addSubfolders && { addSubfolders: '1' }),
    ...(limit ? { limit } : {}),
    ...(typeof offset !== 'undefined' ? { offset } : {})
  });

  return r.get(`team/user/me?${query}`, { disableTeamIDHeader: true, disableResponseNormalizer: !!disableJotFormNormalize });
}

export function getTeam({
  id, addMemberCount = true, addProperties = true, addMembers = true, addSubfolders = true
}) {
  const query = querystring.stringify({
    addMemberCount: addMemberCount ? '1' : '0',
    returnTeamProperties: addProperties ? '1' : '0',
    returnMembers: addMembers ? '1' : '0',
    ...(addSubfolders && { addSubfolders: '1' })
  });
  return r.get(`team/${id}?${query}`);
}

export function getTeamMembers({ id, returnMembersRoles = true }) {
  const query = querystring.stringify({
    ...returnMembersRoles ? { returnMembersRoles: true } : {}
  });
  return r.get(`team/${id}/members?${query}`);
}

export function getTeamRoles() {
  return r.get('team/list/roles');
}

export function favoriteTeamAsset(teamID, id, assetType, value) {
  return r.post(`team/${teamID}/${assetType}/${id}/${value ? '' : 'un'}favorite`);
}

// eslint-disable-next-line complexity
function getTeamQueryParams({
  query = {}, type = ''
}) {
  const {
    filter: { // Extract these from filter since team assets have user based properties (favorite)
      star,
      favorite,
      ...filter
    } = {},
    orderby = '',
    offset,
    ...rest
  } = query;
  const hasFilter = !!Object.keys(filter).length;
  let filterKey = '_filter';
  if (type && type !== LISTING_TYPES.MIX) filterKey = 'filter';
  let extendedFilter;
  if (!type || type === LISTING_TYPES.MIX) {
    const { resourceFilters, ...restFilters } = filter;
    if (resourceFilters) {
      extendedFilter = Object.entries(resourceFilters).reduce((acc, [listingType, filters]) => {
        acc[listingType] = {
          ...filters,
          ...restFilters,
          ...(listingType === 'workflow' && {
            properties: { // workflow specific
              filter: {
                prop: 'type',
                value: 'default'
              }
            }
          })
        };
        return acc;
      }, {});
    } else {
      extendedFilter = restFilters;
    }
  } else if (['workflow', LISTING_TYPES.WORKFLOW].includes(type)) {
    extendedFilter = {
      ...filter,
      properties: { // workflow specific
        filter: {
          prop: 'type',
          value: 'default'
        }
      }
    };
  } else {
    extendedFilter = filter;
  }

  return {
    ...rest,
    team_offset: offset,
    ...(orderby ? { orderby } : {}), // include orderby only if meaningful..
    ...(hasFilter ? { [filterKey]: JSON.stringify(extendedFilter) } : {}), // include filter only if meaningful..
    ...((!type || [ASSET_TYPES.FORM, LISTING_TYPES.FORM, LISTING_TYPES.MIX].includes(type)) && { // form specific
      addProperties: 1,
      includeSharedForms: 0
    }),
    ...((!type || [ASSET_TYPES.SHEET, LISTING_TYPES.SHEET, LISTING_TYPES.MIX].includes(type)) && { // sheet specific
      includeForms: 1,
      includeShare: 1,
      includeTotal: 1
    }),
    favorite,
    getLastLog: 1
  };
}

function getMixQueryParams({
  query = {}, isAllAssetFilterSelected = false, assetFilter, addAIAgents = false
}) {
  const {
    filter: { // Extract these from filter since team assets have user based properties (favorite)
      star,
      favorite,
      fullText,
      resourceFilters = {},
      ...filter
    } = {},
    orderby = '',
    ...rest
  } = query;
  const { mix } = resourceFilters;

  let assetTypes;
  if (!isAllAssetFilterSelected) {
    assetTypes = isArray(assetFilter) ? assetFilter.map(assetType => LISTING_TYPES_REVERT[assetType]) : [];
  }

  return {
    ...rest,
    ...filter,
    ...(orderby ? { orderby } : {}),
    ...mix && mix.statusV2 && { status: mix.statusV2 },
    ...!!fullText && { search: fullText },
    ...(assetTypes ? { assetTypes } : {}),
    ...(addAIAgents ? { addAIAgents: 1 } : {})
  };
}

export async function fetchAgents({ query, assetFilter }) {
  // eslint-disable-next-line camelcase
  if (window?.user?.account_type?.name === 'FORM_USER') {
    // We want form users to see empty list and create flow if they want.
    // API returns 401 for form users on list fetch and this causes to redirect to listing home page. I made this as a quick hack.
    return Promise.resolve({
      data: {
        content: []
      }
    });
  }
  const queryParams = getMixQueryParams({
    query, isAllAssetFilterSelected: false, assetFilter, addAIAgents: true
  });

  return r.get(`mixed-listing/assets?${querystring.stringify(queryParams)}`, { disableResponseNormalizer: true });
}

export function fetchTeamAssets({
  query = {}, selectedFolder, type = '', disableJotFormNormalize = true, teamFilter, folderType, currentTeamID, isAllAssetFilterSelected, currentPage, assetFilter,
  skipV2 = false, canAccessMyAgents
}) {
  const headerTeamID = currentTeamID || teamFilter;
  let url = `team/${headerTeamID || selectedFolder}/assets${type && `/${type}`}`;
  const isV2Enabled = !skipV2 && isV2EndpointEnabled({
    currentPage, folderType, selectedFolder, teamFilter
  });
  let queryParams = getTeamQueryParams({ query, type });
  const isAgentPage = currentPage === LISTING_TYPES.AGENT;
  if (isV2Enabled) {
    url = 'mixed-listing/assets';
    queryParams = getMixQueryParams({
      query, isAllAssetFilterSelected: isAgentPage ? false : isAllAssetFilterSelected, assetFilter, addAIAgents: isAgentPage || canAccessMyAgents
    });
    if (assetFilter.length === 0) {
      return { data: { content: [] } };
    }
  }
  const queryString = querystring.stringify(queryParams);
  if (folderType === FOLDER_TYPES.TEAM_FOLDER) {
    return r.get(
      `team/${currentTeamID || ''}/folder/${selectedFolder}/resources${type ? `/${type}` : ''}?${queryString}`,
      { headers: currentTeamID ? { 'jf-team-id': currentTeamID } : {}, disableResponseNormalizer: !!disableJotFormNormalize }
    );
  }

  return r.get(`${url}?${queryString}`, {
    headers: headerTeamID ? { 'jf-team-id': headerTeamID } : {},
    disableResponseNormalizer: !!disableJotFormNormalize
  });
}

export function fetchTeamForms({
  teamID,
  isAllAssetFilterSelected,
  currentPage,
  assetFilter
} = {}) {
  return fetchTeamAssets({
    selectedFolder: teamID,
    type: getAssetTypeFromListingType(LISTING_TYPES.FORM),
    disableJotFormNormalize: false,
    isAllAssetFilterSelected,
    currentPage,
    assetFilter,
    folderType: FOLDER_TYPES.TEAM,
    skipV2: true
  });
}

export function fetchMixAssets({ // todo :: it will be refactored
  query = {},
  currentTeamID,
  assetFilter,
  assetFilterType,
  isAllAssetFilterSelected,
  teamFilter,
  disableJotFormNormalize = true,
  currentPage,
  canAccessMyAgents
}) {
  const { offset, ...others } = query;
  const headerTeamID = currentTeamID || teamFilter;
  let url = 'mixed-listing/assets';
  let queryParams = getUserAssetsQueryParams({ query: others, type: assetFilterType });
  if (currentPage !== LISTING_TYPES.MIX) {
    url = `${url}/${LISTING_TYPES_REVERT[assetFilterType]}`;
  }
  if (headerTeamID || currentPage === LISTING_TYPES.MIX) {
    if (assetFilter.length === 0) {
      return { data: { content: [] } };
    }
    queryParams = getMixQueryParams({
      query, isAllAssetFilterSelected, assetFilter, addAIAgents: currentPage === LISTING_TYPES.MIX && canAccessMyAgents
    });
  }

  return r.get(`${url}?${querystring.stringify({ ...queryParams, offset })}`, {
    headers: headerTeamID ? { 'jf-team-id': headerTeamID } : {},
    disableResponseNormalizer: !!disableJotFormNormalize
  });
}

export function moveToTeam(selectedTeam, assets) {
  const formData = querystring.stringify({ assets });
  return r.post(`team/${selectedTeam}/move/asset`, formData, { headers: { 'jf-team-id': selectedTeam } });
}

export const moveToTeamFolder = (folderID, teamID, assets) => {
  const payload = {
    resources: [
      {
        resource_id: assets[0].id,
        resource_type: assets[0].type
      }
    ],
    folder_id: folderID
  };
  return r.put(`team/${teamID}/folder/${folderID}/add`, payload, { headers: { 'jf-team-id': teamID } });
};

export const fetchMoveJobs = space => {
  return r.get(`team/move-jobs/${space}`);
};

export const deleteMoveStatus = (id, type) => {
  return r.delete(`team/delete/job/${id}/${type}`);
};

export function moveFromTeam(teamID, assets) {
  const formData = querystring.stringify({ assets });
  return r.post(`team/${teamID}/move/asset/to/user`, formData, { headers: { 'jf-team-id': teamID } });
}

export function getTeamPendingRequests(teamID) {
  return r.get(`team/${teamID}/request-join`, { headers: { 'jf-team-id': teamID } });
}

export function getAssetIsBusy(resourceID, resourceType, teamID) {
  return r.get(`team/asset/${resourceID}/${resourceType}/get-is-resource-busy`, { headers: { 'jf-team-id': teamID } });
}

export function getServerTeamSettings() {
  return r.get('team/list/global-settings');
}

export function addAutomatedSDREmail(source) {
  return saveCustomerAsHubspotFormByUsername(source);
}

export function checkUXRSurvey(slug) {
  return r.get(`/API/uxr-survey-management/load-survey/${slug}`);
}

export function checkIfMobileAppDownloaded(username) {
  return r.get(`mobile/${username}/isdownloaded`, { disableResponseNormalizer: true });
}

export function reorderTeams({ sourceTeamID, targetTeamID, targetPosition }) {
  const formData = querystring.stringify({ sourceTeamID, targetTeamID, targetPosition });
  return r.post('team/reorder', formData, { disableTeamIDHeader: true });
}

export function reorderTeamSubfolders({
  parentId, folderId, order, teamID
}) {
  const formData = querystring.stringify({
    parentId, folderId, order, teamID
  });
  return r.post('team/folder/reorder', formData);
}

export function getReactivationCampaignDetails(username) {
  return r.get(`user/${username}/remain-reactivation-campaign`, { disableResponseNormalizer: true });
}

export function getFilteredPaymentForms(username) {
  return r.get(`/API/payment/listings/gatewayWarning/${username}`);
}

export function getUserLimitExceeded() {
  return r.get('user-limit/form-limit-exceeded');
}

export function getAgentlimitExceeded() {
  return r.get('user-limit/ai-agents-limit-exceeded');
}

export function createTable(name) {
  return r.post('/API/sheet', name, { headers: {} });
}

export function getResolvedAssets(assets, toTeam) {
  const { teamID } = assets;
  const data = { ...assets, moveTo: toTeam ? 'team' : 'user' };
  return r.get(`/API/team/find/assets-tree?flattenOnly=1&${stringify(data)}`, { headers: teamID ? { 'jf-team-id': teamID } : {} });
}

export function moveAssets({
  assets,
  checkedAssets,
  targetTeamID,
  sourceTeamID,
  toTeam,
  isRetry
}) {
  const teamID = toTeam ? targetTeamID : sourceTeamID;
  const formData = stringify({
    v2: '1',
    assets,
    checkedAssets,
    isRetry,
    moveTo: toTeam ? 'team' : 'user'
  });

  return r.post(`/API/team/${teamID}/move/asset${!toTeam ? '/to/user' : ''}`, formData, { headers: teamID ? { 'jf-team-id': teamID } : {} });
}

export const checkUserHasEducationEmail = () => r.get('user/has-education-email');

export function createAgent(formList = []) {
  const resourceData = stringify({
    resources: formList.map(sForm => ({
      resource_id: sForm,
      resource_type: 'FORM'
    })),
    materials: [
      {
        type: 'TEXT'
      }
    ]
  });
  return r.post('/API/ai-agent-builder/agent', resourceData, { headers: {} });
}

export const sendFeedbackSubmission = (submissionData, formID) => {
  const submission = querystring.stringify(submissionData);
  const url = `https://submit.jotformpro.com/submit/${formID}/`;

  r.post(url, submission, { withCredentials: false });
};
