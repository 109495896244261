import React from 'react';
import {
  shape,
  any,
  node
} from 'prop-types';
import { Popover } from '@jotforminc/uikit';
import { useRerenderOnResize } from '../hooks';

const DesktopWrapper = ({
  containerRef,
  targetRef,
  children
}) => {
  const popoverStyles = {
    zIndex: '1000' // 10010
  };
  useRerenderOnResize();

  const popoverOptions = {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [12, 3] } }]
  };

  return (
    <Popover
      ref={containerRef}
      targetRef={targetRef}
      usePortal={true}
      style={popoverStyles}
      popoverOptions={popoverOptions}
    >
      {children}
    </Popover>
  );
};

DesktopWrapper.propTypes = {
  containerRef: shape({ current: any }).isRequired,
  targetRef: shape({ current: any }).isRequired,
  children: node
};

DesktopWrapper.defaultProps = {
  children: null
};

export default DesktopWrapper;
