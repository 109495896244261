/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {
  useRef, useEffect, Suspense, useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { useIsMobile } from '@jotforminc/hooks';
import { Loading } from '@jotforminc/loading';
import { translationRenderer } from '@jotforminc/translation';
import {
  capitalizeFirstLetter, getUrlParameter, handleCustomNavigation, setCookie
} from '@jotforminc/utils';
import { Hooks } from '@jotforminc/uikit';
import { AIBuilder } from '@jotforminc/ai-builder';
import {
  Dialog,
  DialogCloseButton
} from '@jotforminc/magnet';

import GroupedList from './GroupedList';
import { ACTION_CREATORS } from '../../store/actionCreators';
import FlatList from './FlatList';
import {
  ScDescriptionItem, ScModalDescriptionPart
} from './bitsListing';
import { ScFormIcon } from '../ListItem/bitsListItem';
import NoContent from './NoContent';
import Feature from '../../containers/Feature';
import ListPlaceholder from '../ListItem/listPlaceholder';
import ListingDragPreview from '../ListItem/ListingDragPreview';

import translate from '../../utils/translate';
import ConfirmationDialog from '../../utils/Confirmation';
import {
  bulkUpdateToastCall, bulkUpdateRestoreToastCall, bulkUpdateDeleteToastCall, bulkUpdateDeleteSignDocsToastCall
} from '../ListItem/toastForItemAction';
import {
  getCookie, isPlural, getNameFromListingType, isListItemDraggable, getURLParam, getAssetType, findItemByIDAndType
} from '../../utils';

import LimitWarnings from '../LimitWarnings';
import HIPAAWarnings from '../HIPAAWarnings';
import GatewayWarning from '../GatewayWarning';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

import {
  FEATURE_LIST, LISTING_TYPES, DELETE_NAMING_MAP, ASSET_TYPE_MAP, FOLDER_TYPES, MOBILE_BREAKDOWN_WIDTH, DEFAULT_FOLDER_IDS, ASSET_TYPES
} from '../../constants';
import { SELECTORS } from '../../store/selectors';
import Toolbar from './Toolbar/Toolbar';
import PIIWarnings from '../PIIWarnings';

import IconAiBuilder from '../../assets/iconAiBuilder.svg';

const LazyInbox = React.lazy(/* webpackChunkName: "InboxWrapper" */ () => import('./InboxWrapper'));
const LazyListItem = React.lazy(/* webpackChunkName: "ListItem" */ () => import('../ListItem'));
const LazyContactDetails = React.lazy(/* webpackChunkName: "ContactDetails" */ () => import('./ContactDetails'));
const ReactivationWarning = React.lazy(/* webpackChunkName: "ReactivationWarning" */ () => import('../ReactivationWarning'));

const ListingView = props => {
  const {
    isNewSheetUser = true, bulkUpdate, totalCount = 0, listSource = [], setToolbar = () => { }, toggleFoldersVisibility = () => { console.log('called toggleFoldersVisibility'); },
    showFilterBar = true, sortingOptions, orderby, filter, setFullText, newCreatedItems, selectSingleItem,
    allSelected, selectedItems, selectedFolderObject = undefined, bulkDelete, onDeleteMultiplePortals, userEmail = {}, form = undefined, selectItem, getItemActionListByAssetType, bulkMarkAsRead,
    isOwnerOfSelectedFolder, selectedForBulkUpdate, isActionButtonsAllowed, filterForm = undefined, clearFormFilter,
    lastOpenedInboxPanelId = '', isInboxPanelOpen = false, closeInboxPanel = () => {}, renewList = () => {}, isSharedWithMeFolder, isTeamFolder = false, teamFilter = '', isAddToFolderEnabled = false,
    isMoveToTeamEnabled = false, isMoveFromTeamEnabled = false, visibleFlattenedFolders = [], bulkRestoreItemCall, selectAll, selectedIds, onDeleteMultipleAgents
  } = props;

  const contactDetailRef = useRef();
  const dispatch = useDispatch();
  const setContactDetail = contactId => dispatch(ACTION_CREATORS.setContactDetail(contactId));
  const contactDetail = useSelector(state => state.ui.contactDetail);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const isTeamMember = useSelector(SELECTORS.getIsTeamMember);
  const userReactivationData = useSelector(SELECTORS.getUserReactivationData);
  const isAssetsFolderActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const user = useSelector(SELECTORS.getUser);
  const username = useSelector(SELECTORS.getUserName);
  const plans = useSelector(SELECTORS.getUserSystemPlans);
  const isNewUserCampaignAvailable = useSelector(SELECTORS.getNewUserCampaignAvailability);
  const selectedSorting = useSelector(SELECTORS.getOrderBy);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const assetFilterType = useSelector(SELECTORS.getAssetFilterType);
  const isMixEnabledPage = useSelector(SELECTORS.getIsMixEnabledPage);
  const isMixStructureEnabled = useSelector(SELECTORS.getIsMixStructureEnabled);
  const selectedItemIds = useSelector(SELECTORS.getSelectedItemIDs);
  const shouldShowReactivationBanner = !getCookie('dontShowReactivationWarning');
  const reactivationWarningParam = getURLParam('reactivationWarning');
  const [resourceID, setResourceID] = useState('');
  const [showAIFormCreator, setShowAIFormCreator] = useState(false);
  const isMobile = useIsMobile(MOBILE_BREAKDOWN_WIDTH);
  const selectedFolder = selectedFolderObject?.id;
  const handleSelectSingleItemWithDeselect = ({ id, assetType }) => selectSingleItem({ id, checked: true, assetType });
  let selectAction = selectItem;
  if (!isTeamFolder && !isOwnerOfSelectedFolder && currentPage === LISTING_TYPES.FORM) {
    selectAction = selectSingleItem;
  } else if (!isActionButtonsAllowed) { // If you cannot see action buttons, you cannot select multiple
    selectAction = handleSelectSingleItemWithDeselect;
  }
  if (!window?.openCreateWizard) {
    window.openCreateWizard = payload => dispatch(ACTION_CREATORS.createWizard(payload));
  }
  if (!window?.openCreateProductWizard) {
    window.openCreateProductWizard = payload => dispatch(ACTION_CREATORS.openProductCreateWizard(payload));
  }
  window.liteInboxTeamID = currentTeamID;
  window.liteInboxIsTeamMember = !!currentTeamID && isTeamMember;

  // This was made because somehow providing dependencies to useDrag effect in ListingView breaks the drag preview, for now.
  const selectedFolderRef = useRef(selectedFolderObject);
  useEffect(() => {
    selectedFolderRef.current = selectedFolderObject;
  }, [selectedFolderObject]);
  useEffect(() => {
    if (!contactDetailRef.current) return;
    if (!contactDetail) {
      contactDetailRef.current.close();
      return;
    }
    contactDetailRef.current.open();
  }, [contactDetail]);

  useEffect(() => {
    if (!isInboxPanelOpen) {
      setResourceID('');
    }
  }, [isInboxPanelOpen]);

  useEffect(() => {
    const shouldShowInstantModal = window.location.href.includes('AIFormCreator') || window.location.href.includes('AIFormBuilder');
    if (window.JOTFORM_ENV !== 'ENTERPRISE' && user?.type && shouldShowInstantModal && !window.AIInitialized) {
      setShowAIFormCreator(true);
      window.AIInitialized = true;
    }
  }, [user]);

  // To deselect listing items when outside click happens
  const listRef = useRef(null);

  const useHandleClickOutSide = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (['sortOption', 'assetFilterOption'].indexOf(event.target.className) > -1) return;
        // do not remove selections on these elements
        const bulkActionsComponent = document.querySelector('.sb-bulkActions');
        const toastComponent = document.querySelector('.Toastify');
        const modalContainer = document.querySelector('#modal-container');
        const listingPortalContainer = document.querySelector('#listing-portal-root');
        const inboxContainer = document.querySelector('#jingle-submission');
        const uikitModal = document.querySelector("[data-uikit-modal-wrapper='true']");
        const uikitModalContainer = document.querySelector("[data-uikit-modal-container='true']");
        const confirmationModal = document.querySelector('#listing-utils-root');
        const headlessPortalContainer = document.querySelector('#headlessui-portal-root');
        const headlessDialogContainer = document.querySelector('[id^="headlessui-dialog"]');
        if (ref.current
            && !(ref.current.contains(event.target)
                || bulkActionsComponent?.contains(event.target)
                || toastComponent?.contains(event.target)
                || modalContainer?.contains(event.target)
                || listingPortalContainer?.contains(event.target)
                || inboxContainer?.contains(event.target)
                || confirmationModal?.contains(event.target)
                || uikitModal?.contains(event.target)
                || uikitModalContainer?.contains(event.target)
                || headlessPortalContainer?.contains(event.target)
                || headlessDialogContainer?.contains(event.target)
                || event.target.nodeName === 'HTML')) {
          selectAll(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useHandleClickOutSide(listRef);

  const sendEventTrack = (target, customProject, isEnterpriseAllowed) => WatchmanRecorder.trackEvent('click', `${target}${getNameFromListingType(currentPage)}`, customProject, isEnterpriseAllowed);

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    const { fetchListSource } = props;
    return fetchListSource(startIndex, stopIndex);
  };

  const elementsFromPoint = document.elementsFromPoint || document.msElementsFromPoint;

  const getListItemFromPoint = ({ x = 0, y = 0 }) => {
    return Array.from(elementsFromPoint.call(document, x, y) || []).find(elem => elem.matches('[data-sc="listItem"]'));
  };

  const getSelectedItemElements = () => Array.from(document.querySelectorAll('[data-sc="listItem"].isSelected')).filter(f => f);

  const getSelectedItemIDs = () => {
    return getSelectedItemElements().map(e => e.id);
    // TODO find a better way. Unfortunately following line doesn't work
    // return Object.keys(selectedItems);
  };

  const [{
    isDragging, draggedListItem, offset, isTeamItem
  }, dragRef, preview] = useDrag(() => ({
    item: monitor => {
      const listItem = getListItemFromPoint(monitor.getInitialClientOffset());
      const selectedItemIDs = getSelectedItemIDs();
      if (listItem) {
        const ids = selectedItemIDs.includes(listItem.id) ? selectedItemIDs : [listItem.id];
        const isTeamAsset = Object.keys(ASSET_TYPE_MAP).indexOf(listItem?.dataset?.assetType) > -1 && listItem?.dataset?.isTeamAsset === '1';
        return {
          ids, isTeamAsset, type: 'asset', itemAssetType: listItem?.dataset?.assetType
        };
      }
      return null;
    },
    type: assetFilterType,
    collect: monitor => ({
      isTeamItem: monitor.getItem()?.folderType === FOLDER_TYPES.TEAM,
      isDragging: !!monitor.isDragging(),
      offset: monitor.getClientOffset(),
      draggedListItem: getListItemFromPoint(monitor.getInitialClientOffset() || { x: 0, y: 0 })
    }),
    canDrag: monitor => {
      const listItem = getListItemFromPoint(monitor.getInitialClientOffset());
      if (isMixEnabledPage && !isAssetsFolderActive && listItem) {
        const selectedItemElements = getSelectedItemElements();
        const movingItems = selectedItemElements?.find(sItem => sItem.id === listItem.id) ? selectedItemElements : [listItem];
        if (movingItems.find(movingItem => movingItem?.dataset?.assetType !== 'form')) return false;
      }
      if (!isListItemDraggable(assetFilterType, isAssetsFolderActive) || selectedFolderRef.current?.canDragItems !== true) return false;
      if (listItem) return true;
      return false;
    },
    previewOptions: {
      // captureDraggingState: true
    }
  }));

  const combinedRef = Hooks.useCombinedRefs(dragRef, listRef);

  const rowRenderer = ({
    // eslint-disable-next-line react/prop-types
    key, index, style, item, view = 'list'
  }) => {
    const renderedItem = item || listSource[index];

    if (!renderedItem) {
      return (
        <div key={key} style={style}>
          <ListPlaceholder />
        </div>
      );
    }

    const { id, assetType } = renderedItem;
    const isNew = newCreatedItems[id];
    const isSelected = findItemByIDAndType({ list: selectedItems, assetID: id, assetType });
    const handleSelectSingleItem = () => selectSingleItem({ id, assetType: getAssetType(assetFilterType, assetType) });
    const itemActionList = getItemActionListByAssetType(assetType);

    return (
      <Suspense fallback={<Loading />} key={key}>
        <LazyListItem
          index={index}
          view={view}
          orderby={orderby}
          userEmail={userEmail}
          isNew={Boolean(isNew)}
          containerStyle={style}
          selectedFolder={selectedFolder}
          currentPage={currentPage}
          assetFilterType={assetFilterType}
          isMixStructureEnabled={isMixStructureEnabled}
          newCreatedItems={newCreatedItems}
          selectItem={selectAction}
          selectSingleItem={handleSelectSingleItem}
          isSelected={Boolean(allSelected || isSelected)}
          itemActionList={itemActionList}
          isDraggable={isListItemDraggable(assetFilterType, isAssetsFolderActive)}
          isNewSheetUser={isNewSheetUser}
          setResourceID={itemID => setResourceID(itemID)}
          resourceID={resourceID}
          isSharedWithMeFolder={isSharedWithMeFolder}
          isAddToFolderEnabled={isAddToFolderEnabled}
          isMoveToTeamEnabled={isMoveToTeamEnabled}
          isMoveFromTeamEnabled={isMoveFromTeamEnabled}
          visibleFlattenedFolders={visibleFlattenedFolders}
          currentTeamID={currentTeamID}
          {...renderedItem}
        />
      </Suspense>
    );
  };

  const handleSelectAll = e => {
    sendEventTrack('allSelected');
    const { checked } = e.target;
    selectAll(checked);
  };
  const isSelectedItemPlural = selectedItems.length > 1;
  const isKiosk = window.location.pathname.indexOf('mykiosks') > -1;

  const getDescItemPart = itemTextProp => selectedItems.map(item => {
    if (!item) return;

    const itemText = item[itemTextProp] || item.title;
    return (
      <ScDescriptionItem key={item.id}><span>{itemText}</span></ScDescriptionItem>
    );
  }, []).slice(0, 3);

  const getDescMorePart = () => {
    const selectedText = <ScModalDescriptionPart>{`+${selectedItems.length - 3} ${translate('More')}`}</ScModalDescriptionPart>;
    return (selectedIds.length > 3) && selectedText;
  };

  const generateCustomModalDescription = (
    itemTextProp = 'name',
    additionalParts = [],
    showItemsPart = true
  ) => {
    const itemsPart = showItemsPart && getDescItemPart(itemTextProp);
    const morePart = getDescMorePart();
    return [itemsPart, morePart, ...additionalParts];
  };

  const getPortalDescText = () => {
    const areSelectedsMany = isSelectedItemPlural;
    const type = window.location.pathname.indexOf('mykiosks') > -1 ? 'kiosk' : 'app';

    const additionalPart = (
      <ScModalDescriptionPart>
        {translate(`
          If you delete the selected ${type}${isSelectedItemPlural ? 's' : ''}${isSelectedItemPlural ? ' above' : ''},
          users will no longer have access to ${isSelectedItemPlural ? 'them' : 'it'}.  You can still access the forms and view previous submissions.
        `)}
      </ScModalDescriptionPart>
    );

    return generateCustomModalDescription('title', [additionalPart], areSelectedsMany);
  };

  const handleMultipleTrashForm = () => {
    ConfirmationDialog({
      title: translate(`Delete Form${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected forms' : 'this form'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Form${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack('multipleTrashForm');
      bulkUpdate({ prop: 'status', value: 'DELETED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashSheet = () => {
    ConfirmationDialog({
      title: translate(`Delete Table${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected tables' : 'this table'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Table${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack('multipleTrashSheet');
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashReport = () => {
    ConfirmationDialog({
      title: translate(`Delete Report${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected reports' : 'this report'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Report${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack('multipleTrashReport');
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashWorkflow = () => {
    ConfirmationDialog({
      title: translate(`Delete ${window?.isWorkflowReleased ? 'Workflow' : 'Approval'}${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected ' : 'this '}${window?.isWorkflowReleased ? 'workflow' : 'approval'}${isSelectedItemPlural ? 's' : ''}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete ${window?.isWorkflowReleased ? 'Workflow' : 'Approval'}${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack('multipleTrashWorkflow');
      bulkUpdate({ prop: 'status', value: 'DELETED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashBoard = () => {
    ConfirmationDialog({
      title: translate(`Delete Board${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected boards' : 'this board'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Board${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleTrash`);
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultiplePortalsTrash = () => {
    ConfirmationDialog({
      title: isKiosk ? translate('Delete Kiosks') : translate(`Delete App${isSelectedItemPlural ? 's' : ''}`),
      content: isKiosk ? translate('Are you sure you want to delete these?') : translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected apps' : 'this app'}?`),
      backText: translate('Cancel'),
      confirmText: isKiosk ? translate('Delete') : translate(`Delete App${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleTrash`);
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultiplePortalsDelete = () => {
    ConfirmationDialog({
      content: getPortalDescText(),
      title: isKiosk ? 'Delete Kiosks' : translate(`Delete App${isSelectedItemPlural ? 's' : ''}`),
      description: isKiosk ? 'Are you sure you want to delete these?' : translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected apps' : 'this app'}?`),
      backText: translate('Cancel'),
      confirmText: isKiosk ? 'Delete' : translate(`Delete App${isSelectedItemPlural ? 's' : ''}`)
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleDelete`);
      onDeleteMultiplePortals();
    }).catch(() => {});
  };

  const selectedItemCount = selectedItems.length;

  const handleMultipleAgentsDelete = () => {
    ConfirmationDialog({
      title: translate(`Delete Agent${isSelectedItemPlural ? 's' : ''}`),
      description: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected agents' : 'this agent'}?`),
      content: translationRenderer(`Selected [1[{selectedItemCount}]] agent${isSelectedItemPlural ? 's' : ''} will be deleted permanently. This action cannot be undone!`)({
        renderer1: () => selectedItemCount
      }),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Agent${isSelectedItemPlural ? 's' : ''}`)
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleDelete`);
      onDeleteMultipleAgents();
    }).catch(() => {});
  };

  const handleMultipleTrashSignDocuments = () => {
    ConfirmationDialog({
      title: translate(`Delete Sign Document${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected documents' : 'this document'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Sign Document${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleTrash`);
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashAgent = () => {
    ConfirmationDialog({
      title: translate(`Delete Agent${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected agents' : 'this agent'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Agent${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack(`${selectedIds.length}-multipleTrash`);
      bulkUpdate({ prop: 'status', value: 'DELETED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultipleTrashAsset = () => {
    ConfirmationDialog({
      title: translate(`Delete Item${isSelectedItemPlural ? 's' : ''}`),
      content: translate(`Are you sure you want to delete ${isSelectedItemPlural ? 'selected items' : 'this item'}?`),
      backText: translate('Cancel'),
      confirmText: translate(`Delete Item${isSelectedItemPlural ? 's' : ''}`),
      customClass: 'text-center'
    }).then(() => {
      sendEventTrack('multipleTrashAsset');
      bulkUpdate({ prop: 'status', value: 'TRASHED' });
      bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
    }).catch(() => {});
  };

  const handleMultiplePagesRestore = () => {
    sendEventTrack('multipleRestore');
    bulkUpdate({ prop: 'status', value: 'ENABLED' });
    bulkUpdateRestoreToastCall(selectedForBulkUpdate);
  };

  const handleMultipleTrash = () => {
    if (assetFilterType === LISTING_TYPES.FORM) {
      return handleMultipleTrashForm();
    }

    if (assetFilterType === LISTING_TYPES.SHEET) {
      return handleMultipleTrashSheet();
    }

    if (assetFilterType === LISTING_TYPES.REPORT) {
      return handleMultipleTrashReport();
    }

    if (assetFilterType === LISTING_TYPES.TASK) {
      return handleMultipleTrashWorkflow();
    }

    if (assetFilterType === LISTING_TYPES.PORTAL) {
      return handleMultiplePortalsTrash();
    }

    if (assetFilterType === LISTING_TYPES.DOCUMENT) {
      return handleMultipleTrashSignDocuments();
    }

    if (assetFilterType === LISTING_TYPES.BOARD) {
      return handleMultipleTrashBoard();
    }

    if (assetFilterType === LISTING_TYPES.AGENT) {
      return handleMultipleTrashAgent();
    }

    if (assetFilterType === LISTING_TYPES.MIX) {
      return handleMultipleTrashAsset();
    }

    const statusValue = [LISTING_TYPES.FORM, LISTING_TYPES.TASK].indexOf(assetFilterType) > -1 ? 'DELETED' : 'TRASHED';
    sendEventTrack('multipleTrash');
    bulkUpdate({ prop: 'status', value: statusValue });
    bulkUpdateToastCall('status', 'TRASHED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
  };

  const handleMultipleArchive = () => {
    sendEventTrack('multipleArchive');
    bulkUpdate({ prop: 'status', value: 'ARCHIVED' });
    bulkUpdateToastCall('status', 'ARCHIVED', selectedForBulkUpdate, bulkRestoreItemCall, selectedItems);
  };

  const handleMultipleStar = (val, removeSelected = false) => {
    sendEventTrack('multipleStar', '', true);
    bulkUpdate({ prop: 'favorite', value: val, removeSelected });
  };

  const handleMultipleRestore = () => {
    if (assetFilterType === LISTING_TYPES.PAGES) {
      return handleMultiplePagesRestore();
    }

    sendEventTrack('multipleRestore');
    bulkUpdate({ prop: 'status', value: 'ENABLED' });
    bulkUpdateRestoreToastCall(selectedForBulkUpdate);
  };

  const handleDeleteModalConfirmation = () => {
    sendEventTrack('deleteConfirmed');
    const selectedSignItemIDs = selectedItems.reduce(
      (ids, currItem) => {
        if (currItem.assetType === ASSET_TYPES.SIGN) ids.push(currItem.id);
        return ids;
      }, []
    );
    bulkDelete(selectedSignItemIDs); // bulkdDelete(excludedItems)
    const selectedForBulkUpdateExceptSign = selectedForBulkUpdate.filter(
      selectedID => !selectedSignItemIDs.includes(selectedID)
    );
    bulkUpdateDeleteToastCall(selectedForBulkUpdateExceptSign);
    if (selectedSignItemIDs.length > 0) {
      bulkUpdateDeleteSignDocsToastCall();
    }
  };

  const handleFormPurgeModalConfirmation = () => {
    ConfirmationDialog({
      title: `Delete Form${isPlural(selectedItemCount) ? 's' : ''}`,
      content: `Selected ${selectedItemCount} form${isPlural(selectedItemCount) ? 's' : ''} will be deleted permanently. This action cannot be undone!`,
      description: `Are you sure you want to delete selected form${isPlural(selectedItemCount) ? 's' : ''}?`
    }).then(handleDeleteModalConfirmation).catch(() => {});
  };

  const handleMultiplePurgeBoard = () => {
    const name = DELETE_NAMING_MAP[assetFilterType];
    const pluralSuffix = isPlural(selectedItemCount) ? 's' : '';
    const nameWithSuffix = `${name}${pluralSuffix}`;

    const multipleBoard = selectedItemCount > 1;
    const boardCount = multipleBoard ? selectedItemCount : '';

    ConfirmationDialog({
      title: translate(`Delete {boardCount} ${nameWithSuffix} permanently?`.replace('{boardCount}', (boardCount))),
      description: translate(`Are you sure you want to delete ${multipleBoard
        ? '{boardCount} boards'.replace('{boardCount}', boardCount)
        : 'this board'
      }? This action is permanent and can’t be undone.`),
      backText: translate('No, Keep'),
      confirmText: translate('Yes, Delete')
    }).then(handleDeleteModalConfirmation).catch(() => {});
  };

  const handleContactPurgeModalConfirmation = () => {
    let selectedContacts = selectedItems.map(item => {
      const { details: { name } } = item;
      return ` ${name?.prettyFormat?.trim() || 'Untitled'}`;
    });

    const selectedContactsLength = selectedContacts.length;
    const isMultiple = selectedContactsLength > 1 ? 's' : '';

    if (selectedContactsLength <= 4 && selectedContactsLength !== 1) {
      const lastContact = selectedContacts.pop();
      selectedContacts.push(` and ${lastContact}`);
    } else if (selectedContactsLength > 4) {
      selectedContacts = selectedContacts.slice(0, 4);
      const slicedLength = selectedContacts.length;
      const isSlicedMultiple = selectedContactsLength - slicedLength > 1 ? 's' : '';
      selectedContacts.push(` and ${selectedContactsLength - slicedLength} more contact${isSlicedMultiple}`);
    }

    ConfirmationDialog({
      title: `Delete selected contact${isMultiple}`,
      content: (
        <>
          <span className='sureDelete'>Are you sure you want to remove</span>
          <span className='contactNames'>
            {`${selectedContacts}`}
            ?
          </span>
        </>
      ),
      subtitle: (
        <>
          <span className='cannotBe'>This action cannot be </span>
          <span className='undone'>undone!</span>
        </>
      )
    }).then(handleDeleteModalConfirmation).catch(() => {});
  };

  const handleMultipleDelete = () => {
    sendEventTrack('multipleDeleteBeforeConfirm');
    if (assetFilterType === LISTING_TYPES.PORTAL) {
      return handleMultiplePortalsDelete();
    }

    if (assetFilterType === LISTING_TYPES.AGENT) {
      return handleMultipleAgentsDelete();
    }

    if (assetFilterType === LISTING_TYPES.FORM) {
      return handleFormPurgeModalConfirmation();
    }

    if (assetFilterType === LISTING_TYPES.CONTACTS) {
      return handleContactPurgeModalConfirmation();
    }

    if (assetFilterType === LISTING_TYPES.BOARD) {
      return handleMultiplePurgeBoard();
    }

    const name = DELETE_NAMING_MAP[assetFilterType];
    const pluralSuffix = isPlural(selectedItemCount) ? 's' : '';
    const nameWithSuffix = `${name}${pluralSuffix}`;

    ConfirmationDialog({
      title: translate(`Delete ${capitalizeFirstLetter(nameWithSuffix)}`),
      content: translationRenderer(`Selected [1[{selectedItemCount}]] ${nameWithSuffix} will be deleted permanently. This action cannot be undone!`)({
        renderer1: () => selectedItemCount
      }),
      description: translate(`Are you sure you want to delete selected ${nameWithSuffix}?`)
    }).then(handleDeleteModalConfirmation).catch(() => {});
  };

  const handleSortingSelect = (value, key) => {
    sendEventTrack(`${isMobile ? 'mobile-' : ''}sort-${key || value}-`, '', true);
    const { setOrderby } = props;
    setOrderby(value);
    setCookie(`${selectedFolder}Sorting`, value, 7200);
  };

  const handleUpdateListItem = (id, payload) => {
    dispatch(ACTION_CREATORS.updateItemSuccess({ id, payload, assetType: ASSET_TYPES.FORM }));
  };

  const handleMultipleEnable = () => {
    bulkUpdate({ prop: 'status', value: 'ENABLED' });
  };

  const handleMultipleDisable = () => {
    bulkUpdate({ prop: 'status', value: 'DISABLED' });
  };

  const handleMultipleMarkAsRead = () => {
    bulkMarkAsRead();
  };

  const handleSubmissions = () => {
    const submissionPath = isNewSheetUser ? 'tables' : 'submissions';
    handleCustomNavigation(`/${submissionPath}/${selectedIds[0]}`);
    // discoverabilityTracking
    WatchmanRecorder.trackEventOnceForCustomProject('click', 'toolbar-submissions', 'discoverabilityTracking');
  };

  const handleResourceID = itemID => {
    setResourceID(itemID);
  };

  const option = (!filter?.form_id?.length && (sortingOptions || [])?.find(({ value }) => orderby === value)) || {};
  const { view = 'list' } = option;
  const RenderedList = view === 'grouped' ? GroupedList : FlatList;
  const isSelected = !!selectedItemIds?.length;

  const draggedListItemCount = selectedItems.find(item => item.id === draggedListItem?.id) ? selectedItems.length : 1;
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Toolbar
          filterForm={filterForm}
          allSelected={allSelected}
          selectedFolder={selectedFolder}
          filter={filter}
          isSelected={isSelected}
          form={form}
          sortingOptions={sortingOptions}
          orderby={orderby}
          toggleFoldersVisibility={toggleFoldersVisibility}
          showFilterBar={showFilterBar}
          setToolbar={setToolbar}
          setFullText={setFullText}
          clearFormFilter={clearFormFilter}
          handleSelectAll={handleSelectAll}
          handleSubmissions={handleSubmissions}
          handleMultipleDelete={handleMultipleDelete}
          handleMultipleRestore={handleMultipleRestore}
          handleMultipleTrash={handleMultipleTrash}
          handleSortingSelect={handleSortingSelect}
          handleMultipleArchive={handleMultipleArchive}
          handleMultipleStar={handleMultipleStar}
          handleMultipleEnable={handleMultipleEnable}
          handleMultipleDisable={handleMultipleDisable}
          handleMultipleMarkAsRead={handleMultipleMarkAsRead}
          isActionButtonsAllowed={isActionButtonsAllowed}
          isOwnerOfSelectedFolder={isOwnerOfSelectedFolder}
          teamFilter={teamFilter}
          isAddToFolderEnabled={isAddToFolderEnabled}
          isMoveToTeamEnabled={isMoveToTeamEnabled}
          isMoveFromTeamEnabled={isMoveFromTeamEnabled}
          selectedSorting={selectedSorting}
        />
      </Suspense>
      {((user?.credentials?.kycAssetsDisabled && ['STARTED', 'LACK_OF_DOCUMENTS'].includes(user?.credentials?.kycStatus)) || getUrlParameter('showKycBanner') === '1') && (
        <PIIWarnings
          piiDisabled={user?.credentials?.kycAssetsDisabled}
          piiStatus={user?.credentials?.kycStatus}
          piiDisabledAt={user?.credentials?.kycDisableAssetsAt}
          piiDocuments={user?.credentials?.kycLackOfDocumentsReason}
          piiUrl={user?.credentials?.kycUrl}
        />
      )}
      <Feature
        name={FEATURE_LIST.LIMIT_WARNING}
        inactiveComponent={null}
        activeComponent={window.limitWarningAlignment === 'middle_alt' && (<LimitWarnings />)}
      />
      {(reactivationWarningParam || (userReactivationData && shouldShowReactivationBanner)) ? (
        <Suspense fallback={null}>
          <ReactivationWarning
            user={user}
            username={username}
            plans={plans}
            reactivationWarningParam={reactivationWarningParam}
            isNewUserCampaignAvailable={isNewUserCampaignAvailable}
            userReactivationData={userReactivationData}
          />
        </Suspense>
      ) : null}
      <Feature
        name={FEATURE_LIST.GATEWAY_WARNING}
        inactiveComponent={null}
        activeComponent={
          (
            <Suspense fallback={null}>
              <GatewayWarning
                user={user}
                username={username}
              />
            </Suspense>
          )
        }
      />
      <Feature
        name={FEATURE_LIST.HIPAA_WARNING}
        inactiveComponent={null}
        activeComponent={window.limitWarningAlignment === 'middle_alt' && (<HIPAAWarnings />)}
      />
      <div role={view === 'grouped' ? 'grid' : undefined} ref={combinedRef} className={classNames('lsApp-list', { isEmpty: !totalCount })}>
        {!isTeamItem && (
          <ListingDragPreview
            connect={preview}
            offset={offset}
            assetFilterType={assetFilterType}
            isDragging={isDragging}
            itemCount={draggedListItemCount}
            isAssetsFolderActive={isAssetsFolderActive}
          />
        )}
        {totalCount > 0 ? (
          <RenderedList
            totalCount={totalCount}
            height={selectedFolder === DEFAULT_FOLDER_IDS.MY_SUBMISSIONS && 48}
            listSource={listSource}
            rowRenderer={rowRenderer}
            loadMoreRows={loadMoreRows}
            filter={filter}
            icon={<ScFormIcon type='MY_SUBMISSION' />}
          />
        ) : (
          <NoContent
            selectedFolder={selectedFolder}
            filter={filter}
            isSharedWithMeFolder={isSharedWithMeFolder}
            isTeamFolder={isTeamFolder}
          />
        )}
        {
          contactDetail && (
            <Suspense fallback={<Loading />}>
              <LazyContactDetails
                ref={contactDetailRef} onClose={() => setContactDetail(null)} isOpened={contactDetail}
                contactId={contactDetail}
              />
            </Suspense>
          )
        }
        {isInboxPanelOpen && (
          <Suspense fallback={<Loading />}>
            <LazyInbox
              target="jingle-submission"
              formID={lastOpenedInboxPanelId}
              resourceID={resourceID}
              setResourceID={handleResourceID}
              onEvent={closeInboxPanel}
              selectedFolder={selectedFolder}
              renewList={renewList}
              updateListItem={handleUpdateListItem}
            />
          </Suspense>
        )}
        {showAIFormCreator && (
          <Suspense fallback={<Loading />}>
            <Dialog
              zIndex={10001}
              open={showAIFormCreator} onClose={() => {}}
              className="overflow-hidden absolute inset-5 bg-white radius-lg flex flex-col"
            >
              <div className='min-h-18 px-5 border-b border-navy-100 h-18 flex items-center'>
                <div className='flex items-center'>
                  <IconAiBuilder className="h-5 mr-3" />
                  <h3>Copilot</h3>
                  <span className='bg-purple-400 color-white py-1 px-2 radius-full font-bold ml-2' style={{ fontSize: 12 }}>BETA</span>
                </div>
              </div>
              <div className='relative h-full'>
                <AIBuilder user={user.credentials} />
              </div>
              <DialogCloseButton
                aria-label={translate('Close')}
                onClick={() => {
                  if (document.getElementById('prompt-input')) {
                    fetch('/ai-builder/actions/CloseButton-clicked/initialScreen');
                  } else {
                    fetch('/ai-builder/actions/CloseButton-clicked/builderScreen');
                  }
                  setShowAIFormCreator(false);
                }}
              />
            </Dialog>

          </Suspense>
        )}
      </div>
    </>
  );
};

ListingView.propTypes = {
  isNewSheetUser: PropTypes.bool,
  userEmail: PropTypes.string,
  form: PropTypes.shape({}),
  fetchListSource: PropTypes.func,
  listSource: PropTypes.arrayOf(PropTypes.shape()),
  totalCount: PropTypes.number,
  setToolbar: PropTypes.func,
  selectedFolderObject: PropTypes.shape({ id: PropTypes.string }),
  toggleFoldersVisibility: PropTypes.func,
  showFilterBar: PropTypes.bool,
  sortingOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  orderby: PropTypes.string.isRequired,
  setOrderby: PropTypes.func.isRequired,
  filter: PropTypes.shape().isRequired,
  setFullText: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  bulkUpdate: PropTypes.func.isRequired,
  bulkDelete: PropTypes.func.isRequired,
  bulkMarkAsRead: PropTypes.func.isRequired,
  selectSingleItem: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onDeleteMultiplePortals: PropTypes.func.isRequired,
  newCreatedItems: PropTypes.shape({}).isRequired,
  selectItem: PropTypes.func.isRequired,
  getItemActionListByAssetType: PropTypes.func.isRequired,
  isOwnerOfSelectedFolder: PropTypes.bool.isRequired,
  selectedForBulkUpdate: PropTypes.arrayOf(PropTypes.string).isRequired,
  isActionButtonsAllowed: PropTypes.bool.isRequired,
  filterForm: PropTypes.shape({}),
  clearFormFilter: PropTypes.func.isRequired,
  lastOpenedInboxPanelId: PropTypes.string,
  isInboxPanelOpen: PropTypes.bool,
  closeInboxPanel: PropTypes.func,
  renewList: PropTypes.func,
  isSharedWithMeFolder: PropTypes.bool.isRequired,
  isTeamFolder: PropTypes.bool,
  teamFilter: PropTypes.string,
  isAddToFolderEnabled: PropTypes.bool,
  isMoveToTeamEnabled: PropTypes.bool,
  isMoveFromTeamEnabled: PropTypes.bool,
  visibleFlattenedFolders: PropTypes.arrayOf(PropTypes.shape({})),
  bulkRestoreItemCall: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onDeleteMultipleAgents: PropTypes.func
};

export default ListingView;
