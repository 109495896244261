import React from 'react';

export const PreviewRenderer = liProps => (
  <li
    {...liProps}
    className="folderItem isHighlighted"
  />
);

export const OverPreviewRenderer = liProps => (
  <ul className="lsApp-sidebar-folder">
    <li
      {...liProps}
      className="folderItem isHighlighted"
    />
  </ul>
);
