import React, { useRef, useEffect, HTMLProps } from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';
import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';

type Props = HTMLProps<HTMLDivElement> & {
  message?: string,
  autofocus?: boolean,
  className?: string
}

const ErrorMessage: React.FC<Props> = ({
  message,
  autofocus,
  className,
  ...rest
}) => {
  const errorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (autofocus) {
      const { current } = errorRef;
      current?.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cx('p-1 bg-red-400 color-white mt-1 radius top-full text-xs flex', className)}
      ref={errorRef}
      tabIndex={0}
      {...rest}
    >
      <IconExclamationCircleFilled className='fill-white mr-1 w-4 h-4' />
      {message}
    </div>
  );
};

ErrorMessage.propTypes = {
  message: string,
  className: string,
  autofocus: bool
};

ErrorMessage.defaultProps = {
  message: '',
  className: '',
  autofocus: false
};

export default ErrorMessage;
