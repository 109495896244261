import React, { useState, useEffect } from 'react';
import {
  bool, number, shape, arrayOf, string
} from 'prop-types';
import classNames from 'classnames';
import { t, translationRenderer } from '@jotforminc/translation';
import { Badge } from '@jotforminc/magnet';
import { WizardHeader } from '@jotforminc/wizard-shared';
import { Loading } from '@jotforminc/loading';
import { PRODUCT_CONFIGS } from './constants';
import { ScWizardBody, ScWizardItem } from './ScWizardBody';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const SideBySideModal = ({
  isMobileDevice = false,
  isSideBar = false,
  userFormCountLimit = null,
  teamPermissions = {},
  userPermissions = [],
  isTeamAssetCreation = false,
  user = {},
  accountType = ''
}) => {
  const [selectedProduct, setSelectedProduct] = useState(PRODUCT_CONFIGS.find(product => product.isDefault));
  const [isLoading, setIsLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const userName = user?.name || user?.username || '';
  const assetModalContainer = document.querySelector('#create-asset-modal-container');

  const toggleModalVisibility = show => {
    if (show) {
      setIsProductLoading(false);
      assetModalContainer?.removeAttribute('style');
      return;
    }
    setIsProductLoading(true);
    assetModalContainer?.setAttribute('style', 'opacity: 0; pointer-events: none; height: 0; z-index: 499;');
  };

  useEffect(() => {
    toggleModalVisibility(true);
    WatchmanRecorder.trackEventForCustomProject('seen', `${isMobileDevice ? 'mobile-' : ''}sideBySideModal`, 'sideBySideModal', true);
  }, []);

  const handleOpenCreateProductWizard = productType => {
    window.openCreateProductWizard?.({
      productType,
      createAssetFrom: 'sideBySideModal',
      formCountLimit: userFormCountLimit,
      isSideBar,
      isSideBySideUser: true,
      closeButtonCallback: () => {
        toggleModalVisibility(true);
      },
      openWizardCallback: () => {
        toggleModalVisibility(false);
      }
    });
  };

  const updateSelectedProduct = product => {
    const { name: pName, type } = product;
    if (type === selectedProduct?.type) {
      return;
    }
    const name = pName.toLowerCase().replace(' ', '');
    if (isTeamAssetCreation) {
      WatchmanRecorder.trackEventForCustomProject('click', `teamPageSelectProduct - ${name}`, 'teams', true);
    } else {
      WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}${name}-sideBySideModal`, 'sideBySideModal', true);
    }
    setIsLoading(true);
    setSelectedProduct(product);
  };

  const handleCreate = () => {
    if (selectedProduct?.type) {
      setIsProductLoading(true);
      WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}${selectedProduct.name}-createButton`, 'sideBySideModal', true);
      handleOpenCreateProductWizard(selectedProduct.type);
    }
  };

  const handleOptionDoubleClick = (selectedName, selectedType) => {
    setIsProductLoading(true);
    if (isTeamAssetCreation) {
      WatchmanRecorder.trackEventForCustomProject('click', `teamPageSelectProduct - ${selectedName} - withDoubleClick`, 'teams', true);
    } else {
      WatchmanRecorder.trackEventForCustomProject('click', `${isMobileDevice ? 'mobile-' : ''}${selectedName}-sideBySideModal-withDoubleClick`, 'sideBySideModal', true);
    }
    handleOpenCreateProductWizard(selectedType);
  };

  const filteredConfig = PRODUCT_CONFIGS.filter(({
    name, createPermissionName, checkVisibility, userCreatePermissionType
  }) => {
    const hasPermission = isTeamAssetCreation ? teamPermissions[`create${createPermissionName || name}`] : userPermissions?.includes(userCreatePermissionType);

    if (!checkVisibility) return hasPermission;
    return hasPermission && checkVisibility({ user });
  });

  const moreThanSixClass = filteredConfig.length > 6 ? 'moreThanSix' : '';

  const handleLoad = () => {
    setIsLoading(false);
  };

  if (isProductLoading) return <Loading />;

  return (
    <ScWizardItem className="forSideBySideCreation">
      <div className="sideBySideWrapper">
        <div className="productWrapper">
          <WizardHeader
            noDecodeURI
            title={userName && accountType !== 'GUEST'
              ? translationRenderer('How would you like to start, [1[{username}]]?')({
                renderer1: () => {
                  return userName.split(' ')?.[0] || '';
                }
              })
              : t('How would you like to start?')}
            subTitle={t('Your all-in-one platform to effortlessly create online forms and collect data in one place.')}
          />
          <ScWizardBody>
            <div className="productListWrapper">
              <ul className={classNames('productItem-list', moreThanSixClass || (filteredConfig.length > 4 ? 'moreThanFour' : ''))}>
                {filteredConfig
                  .map(product => {
                    const {
                      name,
                      Icon,
                      type,
                      isBeta,
                      isNew,
                      visibleName
                    } = product;
                    return (
                      <li>
                        <button
                          key={name}
                          className={classNames('productItem', name.toLowerCase().replace(' ', ''), {
                            isSelected: selectedProduct?.type === type
                          })}
                          onClick={() => updateSelectedProduct(product)}
                          data-productCount={filteredConfig.length}
                          type="button"
                          tabIndex="0"
                          aria-label={t(name)}
                          onDoubleClick={() => handleOptionDoubleClick(name, type)}
                        >
                          {isBeta && (
                            <Badge colorStyle='primary' className='text-xs text-uppercase betaProduct' size="small">{t('Beta')}</Badge>
                          )}
                          {isNew && (
                            <Badge colorStyle='success' className='text-xs text-uppercase newProduct' size="small">{t('New')}</Badge>
                          )}
                          {Icon && <div className="productIconWrapper"><Icon className='productIcon' /></div>}
                          <div className="productContentWrapper">
                            <div className='productName'>{t(visibleName || name)}</div>
                          </div>
                        </button>
                      </li>
                    );
                  })}
              </ul>
              <button
                type="button"
                onClick={handleCreate}
                className='createProductButton'
              >
                {t(`Create ${selectedProduct?.visibleName || selectedProduct?.name || ''}`)}
              </button>
            </div>
          </ScWizardBody>
        </div>
        {selectedProduct && (
          <div
            className={classNames('productDetails', selectedProduct.name.toLowerCase().replace(' ', ''))}
          >
            <div className="productDetails-name">
              {t(selectedProduct.detailsName)}
              {selectedProduct.isBeta && (
                <Badge colorStyle='primary' className='text-xs text-uppercase betaProduct' size="small">{t('Beta')}</Badge>
              )}
              {selectedProduct.isNew && (
                <Badge colorStyle='success' className='text-xs text-uppercase newProduct' size="small">{t('New')}</Badge>
              )}
            </div>
            <div className="productDetails-description">{t(selectedProduct.description)}</div>
            {isLoading && <Loading />}
            {selectedProduct.imgSource && (
              <div className={classNames('productImage', isLoading && 'loading')}>
                <img src={selectedProduct.imgSource} onLoad={handleLoad} />
              </div>
            )}
          </div>
        )}
      </div>
    </ScWizardItem>
  );
};

SideBySideModal.propTypes = {
  isMobileDevice: bool,
  isSideBar: bool,
  userFormCountLimit: number,
  teamPermissions: shape({}),
  userPermissions: arrayOf(),
  isTeamAssetCreation: bool,
  user: shape({}),
  accountType: string
};

export default SideBySideModal;
