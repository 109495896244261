import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/eoy.scss';
import Tree from '../assets/campaigns/2023/eoy/header-banner/tree.svg';
import Stars from '../assets/campaigns/2023/eoy/header-banner/stars.svg';
import Confetti1 from '../assets/campaigns/2023/eoy/header-banner/confetti.svg';
import Confetti2 from '../assets/campaigns/2023/eoy/header-banner/confetti2.svg';
import { CampaignCountDown } from './utils/CampaignCountDown';

export const EndOfYearCampaignBanner = ({
  utmSource, accountTypeName, username, campaign
}) => {
  const { assetsVersion } = campaign;
  const isAssetVersionV2 = assetsVersion === 'v2';
  return (
    <div className={`eoy-2023-wrapper ${isAssetVersionV2 ? 'eoy-2023-banner-v2' : ''}`}>
      {!isAssetVersionV2 && (
      <div className="star-bg">
        <Stars />
      </div>
      )}
      <div className="eoy-2023-container">
        {isAssetVersionV2 ? (
          <div className="eoy-2023-banner-v2--wrapper">
            <Confetti1 />
            <CampaignCountDown />
            <div className="eoy-2023-banner-v2--text">
              {translationRenderer('[1[50%]] OFF')({
                renderer1: str => (<strong>{str}</strong>)
              })}
            </div>
            <Confetti2 />
          </div>
        ) : (
          <>
            <div className="eoy-2023-tree">
              <Tree />
            </div>
            <CampaignCountDown />
            <div className="eoy-2023-title-wrapper">
              <div className='eoy-2023-discount-title'>
                {translationRenderer('SAVE [1[50%]]')({
                  renderer1: str => (<strong>{str}</strong>)
                })}
              </div>
              <div className='eoy-2023-bottom-title'>
                {t('ON ANNUAL PLANS')}
                <sup>*</sup>
              </div>
            </div>
          </>
        )}
        <div className="campaign-content-button">
          <a className="locale" target="_blank" href={`/pricing/?utm_campaign=eoy2023&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_term=${username}`}>
            {t('Upgrade Now')}
          </a>
        </div>
      </div>
    </div>
  );
};

EndOfYearCampaignBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired,
  campaign: string.isRequired
};

export const renderEndOfYearCampaign = ({
  container, accountTypeName, utmSource, username, campaign
}) => {
  const root = createRoot(container);
  root.render(<EndOfYearCampaignBanner
    utmSource={utmSource} accountTypeName={accountTypeName} username={username}
    campaign={campaign}
  />);
};
