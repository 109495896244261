import { useSelector } from 'react-redux';

import { SELECTORS } from '../../store/selectors';

const Feature = ({
  name, activeComponent = null, inactiveComponent = null, extraRequirements = true
}) => {
  const isActive = useSelector(SELECTORS.isActiveFeature(name));
  if (isActive && extraRequirements) {
    return activeComponent;
  }
  return inactiveComponent;
};

export default Feature;
