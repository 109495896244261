import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FolderList from '../../components/FolderList';
import TeamFolderList from '../../components/TeamFolderList';

import { getFolderByID, isFolderShared } from '../../utils';

import {
  FOLDER_ITEM_ACTION_TYPES, FOLDER_TYPES, LISTING_TYPES, DEFAULT_FOLDER_IDS, FEATURE_LIST, ASSETS_FOLDER_LISTINGS, ASSET_TYPE_MAP, ALL_ASSETS_ID, STATIC_FOLDERS
} from '../../constants';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

const ReduxFolderListContainer = () => {
  const dispatch = useDispatch();

  const folders = useSelector(SELECTORS.getFolders);
  const folderListToMap = useSelector(SELECTORS.getFolderListToMap);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const selectedFolder = useSelector(SELECTORS.getSelectedFolder);
  const isInboxPanelOpen = useSelector(SELECTORS.getIsInboxPanelOpen);
  const user = useSelector(SELECTORS.getUserCredentials);
  const isTeamCreationDisabled = useSelector(SELECTORS.getIsTeamCreationDisabled);
  const teamPermissions = useSelector(SELECTORS.getTeamPermissions);
  const currentTeamID = useSelector(SELECTORS.getCurrentTeamID);
  const isReorderTeamsAllowed = useSelector(SELECTORS.getIsReorderTeamsAllowed);
  const isReorderFoldersAllowed = useSelector(SELECTORS.getIsReorderFoldersAllowed);
  const isAssetFolderActive = useSelector(SELECTORS.isActiveFeature(FEATURE_LIST.ASSETS_FOLDER_SUPPORT));
  const teamFolderMenuItems = useSelector(SELECTORS.getTeamFolderMenuItems);
  const selectedFolderObj = useSelector(SELECTORS.getFolderByID(selectedFolder));
  const showAllFolders = useSelector(SELECTORS.getIsAllFoldersVisible);
  const filterForm = useSelector(SELECTORS.getFilterForm);

  const transferItem = params => dispatch(ACTION_CREATORS.transferItem(params));
  const reorderFolders = paramters => dispatch(ACTION_CREATORS.reorderFolders(paramters));
  const reorderTeams = parameters => dispatch(ACTION_CREATORS.reorderTeams(parameters));
  const resetAllFoldersVisibility = () => dispatch(ACTION_CREATORS.updateAllFoldersVisibility(false));
  const addItemsToFolder = ({ folderID, items }) => {
    dispatch(ACTION_CREATORS.folderItemActionBulk({
      actionType: FOLDER_ITEM_ACTION_TYPES.ADD,
      items,
      folderIDs: [folderID]
    }));
  };

  const handleDropAssets = ({
    itemType, item, itemIDs, items, folderID, folder
  }) => {
    const { owner: username, folderType } = folder;
    const assetType = (itemType === LISTING_TYPES.MIX && item.itemAssetType && ASSET_TYPE_MAP[item.itemAssetType]) ? ASSET_TYPE_MAP[item.itemAssetType] : itemType;
    switch (assetType) {
      case LISTING_TYPES.FORM:
        if (itemIDs.length === 0) return;

        if ([FOLDER_TYPES.TEAM, FOLDER_TYPES.TEAM_FOLDER].indexOf(folderType) > -1) {
          WatchmanRecorder.trackEventForCustomProject('dragAndDropMoveToTeam', folderID, 'teams', true);
          dispatch(ACTION_CREATORS.controlMoveToOperation(folderID, items));
          return;
        }

        if (!isFolderShared(folders, folderID, user?.username)) {
          addItemsToFolder({ folderID, items });
          return;
        }
        transferItem({ username, itemIDs, folders: folderType === FOLDER_TYPES.USERNAME ? '' : folderID });
        break;
      case LISTING_TYPES.PORTAL:
      case LISTING_TYPES.SHEET:
      case LISTING_TYPES.TASK:
      case LISTING_TYPES.DOCUMENT:
        if (folderType === FOLDER_TYPES.TEAM) {
          WatchmanRecorder.trackEventForCustomProject('dragAndDropMoveToTeam', folderID, 'teams', true);
          dispatch(ACTION_CREATORS.moveToTeamRequest(folderID, itemIDs));
        }
        break;
      default:
        break;
    }
  };

  const onDrop = params => {
    const {
      item, monitor, position, folderID
    } = params;
    const itemType = monitor.getItemType();
    const itemIDs = item.ids;
    const items = itemIDs.map(itemID => ({ id: itemID, assetType: item.itemAssetType }));
    const folder = getFolderByID(folders, folderID);
    const { folderType } = folder;

    if (currentPage === LISTING_TYPES.TEAM_PAGE) {
      if (itemIDs.length === 0) return;

      if (folderType === FOLDER_TYPES.TEAM_FOLDER) {
        WatchmanRecorder.trackEventForCustomProject('dragAndDropMoveToTeam', folderID, 'teams', true);
        dispatch(ACTION_CREATORS.controlMoveToOperation(folderID, items));
        return;
      }

      if (!isFolderShared(folders, folderID, user?.username)) {
        addItemsToFolder({ folderID, items });
      }
      return;
    }
    if (itemType === 'folder') {
      if (folderID === item.id) return;

      if (item.folderType === FOLDER_TYPES.TEAM) {
        reorderTeams({
          position, teamID: item.id, referenceTeamID: folderID
        });
        return;
      }
      reorderFolders({
        position, folderID: item.id, referenceFolderID: folderID
      });
      return;
    }

    handleDropAssets({
      itemType, item, itemIDs, items, folderID, folder
    });
  };

  const selectFolder = (folder, folderType) => {
    if (filterForm && (currentTeamID || [FOLDER_TYPES.TEAM, FOLDER_TYPES.TEAM_FOLDER].indexOf(folderType) > -1)) {
      dispatch(ACTION_CREATORS.setFilterForm(null));
      dispatch(ACTION_CREATORS.setFilterID(''));
    }
    dispatch(ACTION_CREATORS.setSelectedFolder({
      folder, folderType, currentTeamID, userCredentials: user
    }));
    if (isInboxPanelOpen) {
      dispatch(ACTION_CREATORS.toggleInboxPanel(false));
    }
    if (folder === DEFAULT_FOLDER_IDS.FILLED_FORMS) {
      WatchmanRecorder.trackEvent('clickFilledForms', 'Folders', 'jotform-myforms');
    }
    if (currentPage === LISTING_TYPES.MIX && folder === ALL_ASSETS_ID[LISTING_TYPES.MIX]) {
      WatchmanRecorder.trackEvent('click', 'allAssetsFolder', 'mixAssetFilter', true);
    }
    if (STATIC_FOLDERS.indexOf(folder) > -1) {
      WatchmanRecorder.trackEvent('click', `${folder}Folder`, 'myFormsFolders');
    }

    if (folderType === FOLDER_TYPES.ASSET_FOLDER) {
      WatchmanRecorder.trackEvent('click', 'formFolder', 'myFormsFolders');
    }

    try {
      const scrollingElement = window?.document?.scrollingElement;

      if (scrollingElement) {
        scrollingElement.scrollTop = 0;
      }
    } catch {
      // noop.
    }
  };
  const folderButtonClicked = folder => dispatch(ACTION_CREATORS.folderButtonAction(folder));
  const handleClick = (folder, folderType, teamID) => {
    switch (folderType) {
      case FOLDER_TYPES.BUTTON:
      case FOLDER_TYPES.CREATE_TEAM_BUTTON:
        folderButtonClicked(folder);
        break;
      default:
        if (currentPage !== LISTING_TYPES.TEAM_PAGE) {
          dispatch(ACTION_CREATORS.setCurrentTeamID(teamID || ''));
        }
        selectFolder(folder, folderType);
        break;
    }
  };

  const hasContextMenu = listing => {
    const listingsWithContextMenu = [LISTING_TYPES.FORM, LISTING_TYPES.MIX, ...(isAssetFolderActive ? ASSETS_FOLDER_LISTINGS : [])];
    return listingsWithContextMenu.indexOf(listing) !== -1;
  };

  const isDroppable = listing => {
    const listingWithDroppableFolders = [LISTING_TYPES.FORM, LISTING_TYPES.MIX, LISTING_TYPES.PORTAL, ...(isAssetFolderActive ? ASSETS_FOLDER_LISTINGS : [])];
    return listingWithDroppableFolders.indexOf(listing) !== -1;
  };

  return folderListToMap.map(({ id, subfolders }) => {
    const FolderListRenderer = [DEFAULT_FOLDER_IDS.CREATE_TEAM_FOLDER, DEFAULT_FOLDER_IDS.TEAM_FOLDER_GROUP, DEFAULT_FOLDER_IDS.TEAM_PAGE_ALL_FOLDER].indexOf(id) > -1 ? TeamFolderList : FolderList;

    return (
      <FolderListRenderer
        key={id}
        className={id}
        folders={subfolders}
        handleClick={handleClick}
        selectedFolderID={selectedFolder}
        selectedFolder={selectedFolderObj}
        isDroppable={isDroppable(currentPage)}
        hasContextMenu={hasContextMenu(currentPage)}
        onDrop={onDrop}
        user={user}
        isTeamCreationDisabled={isTeamCreationDisabled}
        teamPermissions={teamPermissions}
        isReorderTeamsAllowed={isReorderTeamsAllowed}
        isReorderFoldersAllowed={isReorderFoldersAllowed}
        isUserFoldersGroup={DEFAULT_FOLDER_IDS.USER_FOLDER_GROUP === id}
        isRootFolder={true}
        folderId={id}
        teamFolderMenuItems={teamFolderMenuItems}
        showAllFolders={showAllFolders}
        resetAllFoldersVisibility={resetAllFoldersVisibility}
        currentTeamID={currentTeamID}
      />
    );
  });
};

export default ReduxFolderListContainer;
