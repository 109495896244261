import { createRoot } from 'react-dom/client';
import React from 'react';
import { t } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/workflows-header-banner.scss';

export const WorkflowsHeaderBanner = ({ utmSource, accountTypeName, username }) => {
  return (
    <>
      <div className="workflows-wrapper">
        <div className="workflows-container">
          <div className="image">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/workflows/banner/workflows-img-new.png" alt="Banner" />
          </div>
          <div className="title-wrapper">
            <h5>{t('Announcing')}</h5>
            <h4>{t('Jotform Workflows')}</h4>
          </div>

          <a
            className="action-link locale"
            target="_blank"
            href={`/products/workflows/?utm_campaign=workflows&utm_source=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header&utm_term=${username}`}
          >
            {t('Discover Now')}
          </a>
        </div>

      </div>
    </>
  );
};

WorkflowsHeaderBanner.propTypes = {
  utmSource: string.isRequired,
  username: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderWorkflowsHeaderBanner = ({
  container,
  accountTypeName,
  utmSource,
  username
}) => {
  const root = createRoot(container);
  root.render(
    <WorkflowsHeaderBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
      username={username}
    />
  );
};
