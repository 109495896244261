import React from 'react';
import { shape } from 'prop-types';

const PopoverText = ({
  option: { text }
}) => {
  return (
    <div className="listings-popover-more-text">{text}</div>
  );
};

PopoverText.propTypes = {
  option: shape({}).isRequired
};

export default PopoverText;
