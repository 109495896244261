import Styled from 'styled-components';

const Wrapper = Styled.div`
.jNewHeader-buttonNew {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  transition: .2s ease-in;
  font-family: inherit;
  &.isAppSelectorDisabled {
    cursor: unset;
  }
  &.lineButton {
    position: relative;
    border: 1px solid #DEE0E7;
    border-radius: 30px;
    padding: 10px;
    color: #737D97;
    z-index: 1;
    svg {
      max-width: 20px;
      max-height: 20px;
      fill: #737D97;
      transition: .2s all;
    }
    &:hover {
      color: #192731;
      border-color: #192731;
      svg {
        fill: #192731;
      }
    }
  }
  &.withIconLeft {
    .jNewHeader-icon {
      display: inline-block;
      margin-right: 8px;
    }
  }
  &.withIconRight {
    .jNewHeader-icon {
      display: inline-block;
      margin-left: 8px;
    }
  }
  &:focus {
    outline: none;
  }

  .jNewHeader-selectorIcon {
    margin-left: 5px;
    width: 16px;
    height: 16px;
    padding: 2px !important;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: .3s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px !important;
      height: 12px !important;
      fill: #fff;
    }

    html[dir="rtl"] &{
      margin-left: inherit;
      margin-right: 5px;
    }

  }

  .currentApp {
    overflow: visible;
    position: relative;
    margin-right: 0;
    margin-left: 0;

    .teams & {
      overflow: hidden !important;

      @media screen and (max-width: 490px) {
        max-width: 30vw !important;
      }
    }
  }

  &.notification {
    .currentApp {
      padding-right: 12px;

      &:after {
        content: '';
        background: #ED3F3F;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 0;
        top: 1px;
        display: block;
      }
    }
  }
}

.jNewHeader-appBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 332px;
  background: #FFFFFF;
  padding: 7px;
  border-radius: 0 3px 3px 0;
  box-shadow: 0 0 10px rgba(0,0,0,.3);
  font-size: 12px;
  transition: .2s ease-in;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  .toMyForms {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 12px;
    padding: 8px;
    text-decoration: none;
    color: #192731;
    background: #F4F6F8;
    margin: 3px;
    border-radius: 3px;
    transition: .3s ease-in-out;
    .appIcon {
      padding: 4px;
      margin-left: 5px;
      background: rgba(20, 29, 70, .1);
      border-radius: 50%;
      transition: .3s ease-in;
      flex-shrink: 0;
      svg {
        display: block;
        transition: .3s fill;
        width: 8px;
        height: 8px;
        fill: #B3B5C6;
      }
    }
    &:hover {
      background: #E8EDEE;
      .appIcon {
        transform: translateX(3px);
        svg {
          fill: #192731;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    width: 100%;
    border-radius: 0;
  }

  .appItem {
    align-items: center;
    border-bottom-color: rgb(244, 246, 248);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(244, 246, 248);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(244, 246, 248);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(244, 246, 248);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: rgb(94, 103, 110);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: "Circular Std Book", -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px;
    height: 78px;
    justify-content: center;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    outline-color: rgb(94, 103, 110);
    outline-style: none;
    outline-width: 0;
    padding-bottom: 0;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 0;
    pointer-events: all;
    position: relative;
    text-align: center;
    text-decoration-color: rgb(94, 103, 110);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-rendering: optimizeLegibility;
    user-select: none;
    visibility: visible;
    width: 100px;
    -webkit-font-smoothing: antialiased;

    .appIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      position: relative;
    }

    &.notification {
      .appIcon {
        :after {
          content: attr(data-count);
          font-size: 11px;
          color: #fff;
          background: #ED3F3F;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          position: absolute;
          right: -4px;
          top: -4px;
          display: flex;
          align-items: center;
          justify-content: center;

          html[dir="rtl"] & {
            right: auto;
          }
        }
      }
    }

    &.newBadge {
      .appIcon {
        :after {
          content: "NEW";
          font-size: 11px;
          color: #fff;
          background: #0099FF;
          border-radius: 10px;
          width: 37px;
          height: 14px;
          position: absolute;
          right: 4px;
          top: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .appName {
      margin-top: 3px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      transition: .3s ease-in;
    }

    &[data-icon="forForm"] svg {
      width: 16px;
      height: 18px;
    }
    &[data-icon="forInbox"] svg {
      width: 18px;
      height: 14px;
    }
    &[data-icon="forBuilder"] svg, &[data-icon="forSheets"] svg, &[data-icon="forCards"] svg, &[data-icon="forUploads"] svg {
      width: 16px;
      height: 16px;
    }
    &[data-icon="forPdfEditor"] svg, &[data-icon="forAnalytics"] svg {
      width: 14px;
      height: 18px;
    }
    &[data-icon="forReports"] svg {
      width: 18px;
      height: 18px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      background: #F4F6F8;
      transition: .3s ease-in;
      pointer-events: none;
      z-index: -1;
    }

    &:hover {
      color: #192731;
      background-color: #EFEFF5;
      transition: background-color .3s;
    }

    @media screen and (max-width: 360px) {
      width: calc(33.3% - 6px);
    }
  }
}


&.appBoxOpened {
  .jNewHeader-appBox {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}
`;

export default Wrapper;
