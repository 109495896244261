import React from 'react';
import ReactDOM from 'react-dom';
import { getRootElement, ENTERPRSE_PROMOTIONS_ROOT_EL_ID } from '@jotforminc/ep-utils';
import { FolderLimitModal } from '../components';

const renderFolderLimitModal = (
  rootId,
  user
) => {
  const rootElId = rootId || ENTERPRSE_PROMOTIONS_ROOT_EL_ID;
  const rootEl = getRootElement(rootElId);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(rootEl);
  };

  ReactDOM.render(
    <FolderLimitModal
      user={{ ...user, ...window.user }}
      onClose={handleClose}
    />,
    rootEl
  );
};

export default renderFolderLimitModal;
