import { eventChannel } from 'redux-saga';
import {
  call, put, select, takeLatest
} from 'redux-saga/effects';

import { SELECTORS } from '../../store/selectors';
import { ACTION_TYPES } from '../../store/actionTypes';
import { ACTION_CREATORS } from '../../store/actionCreators';

export function* setFeaturesFromHash(hash) {
  if (!hash) {
    yield put(ACTION_CREATORS.resetFeatures());
    return;
  }

  const featureList = hash.replace('#', '').split(',');
  yield put(ACTION_CREATORS.setFeatures(featureList));
}

export function hashEventChannel() {
  return eventChannel(emitter => {
    const handleHashChange = () => emitter(window.location.hash);
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  });
}

export function* manageFeaturesFlow() {
  const featureList = yield select(SELECTORS.getFeatures);
  const featureEntryList = Object.entries(featureList);
  for (let i = 0; i < featureEntryList.length; i++) {
    const [featureKey] = featureEntryList[i];
    switch (featureKey) {
      default:
        break;
    }
  }
}

export function* rootFeatureFlow() {
  yield takeLatest([ACTION_TYPES.SET_FEATURES, ACTION_TYPES.RESET_FEATURES], manageFeaturesFlow);
  yield setFeaturesFromHash(window.location.hash);
  const channel = yield call(hashEventChannel);
  yield takeLatest(channel, setFeaturesFromHash);
}
