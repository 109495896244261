import { readCookie } from '@jotforminc/utils';
import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

/**
 * Fetch the UXR Survey Form ID from API.
 */
function getUXRSurvey(slug) {
  const layer = new RequestLayer('/API', {
    interceptorConfig: {
      teamID: global.teamID,
      customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
    }
  });
  return layer.get(`uxr-survey-management/load-survey/${slug}`);
}

/**
 * Check if UXR Survey Modal will be rendered or not.
 */
export const checkUXRSurvey = async (slug, isGuest, username) => {
  const formID = await getUXRSurvey(slug);
  const isSurveyAvailable = typeof formID === 'string' && formID !== '';
  const uxrSurveyFormID = isSurveyAvailable && formID;

  if (!isSurveyAvailable || !uxrSurveyFormID || uxrSurveyFormID === '' || isGuest) {
    return false;
  }

  const cookieCheck = !readCookie(`UXRSurvey-${uxrSurveyFormID}`) && !readCookie(`UXRSurvey-user-${uxrSurveyFormID}-${username}`);

  const disabledUxrForms = ['212094297016959', '212093491636962'];

  if (cookieCheck && !disabledUxrForms.includes(uxrSurveyFormID)) {
    return uxrSurveyFormID;
  }
};
