import { handleCustomNavigation } from '@jotforminc/utils';
import { setFlagToLocSt, setUserNotificationStatus } from '../utils';

const onConfirm = ({ actionTargetName, localStActionKey }) => {
  setFlagToLocSt(localStActionKey);
  handleCustomNavigation(`${window.location.origin}/myaccount/data`);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, localStActionKey, disabledKey }) => {
  setFlagToLocSt(localStActionKey);
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkHipaaEnforcement = notifications => {
  let notification = notifications.find(notif => notif.name === 'hipaaEnforcement');
  const otherNotifications = notifications.filter(notif => notif.name !== 'hipaaEnforcement');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkHipaaEnforcement;
