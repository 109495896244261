import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  ConfirmationModal
} from '@jotforminc/modals';
import { t } from '@jotforminc/translation';

const root = document.getElementById('modal-container');
const askConfirmation = ({
  title = t('Delete Folders'),
  description = t('Are you sure you want to delete selected folder? This folder and all sub-folders will be removed.'),
  subtitle = '',
  showDontShowAgain = false,
  ...rest
} = {}) => {
  const rootNode = createRoot(root);
  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      rootNode.unmount();
    };
    const handleConfirm = ({ dontShowAgain = false }) => {
      resolve({ dontShowAgain });
      rootNode.unmount();
    };

    rootNode.render(<ConfirmationModal
      // usePortal
      showDontShowAgain={showDontShowAgain}
      title={title}
      description={description}
      subtitle={subtitle}
      onBack={handleClose}
      defaultVisible={true}
      onClose={handleClose}
      onConfirm={handleConfirm}
      {...rest}
    />);
  });
};

export default askConfirmation;
