import React, { useEffect, useRef, useState } from 'react';
import {
  shape, object, bool
} from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { useNotifications, useAutoVisibleNotification } from '../hooks';
import { ActionContext, ABTestActionContext } from '../context';
import { initNotificationCenterActions, setFlagToLocSt, updateUserSettings } from '../utils';
import { hipaaSteps, notificationConfig } from '../constants';
import ResponsiveAdapter from './ResponsiveAdapter';
import NotificationBell from './Bell';
import List from './List';
import { ScAutoVisibleNotificationOverlay } from './sc/newNotification/scAutoVisibleNotificationOverlay';

const NotificationCenter = ({
  user,
  initInAutoVisibleMode,
  enterpriseTheme,
  bellContainerElForMobile
}) => {
  const containerRef = useRef(false);
  const notificationBellRef = useRef(false);

  // notifications
  const [notifications, setNotifications] = useNotifications(user);

  // action logger
  const logNotificationCenterActions = initNotificationCenterActions();

  // decide if user should see the notificatin center or not
  const [notificationCenterShouldRender, setNotificationcenterShouldRender] = useState(false);

  // manage visiblity of notification list
  const [visible, setVisible] = Hooks.useClickOutsideState(false, [notificationBellRef, containerRef]);

  // auto visible notification
  // if there is any auto visible notification it appears to user automatically once within jotform experience flow in myforms
  const [autoVisibleNotificationList, setAutoVisibleNotificationClosed] = useAutoVisibleNotification(notifications, initInAutoVisibleMode);
  const isAutoVisibleNotificationMode = autoVisibleNotificationList.length > 0;

  useEffect(() => {
    if (isAutoVisibleNotificationMode) {
      setVisible(true);
    }
  }, [autoVisibleNotificationList]);

  const renderingNotifications = isAutoVisibleNotificationMode ? autoVisibleNotificationList : notifications;

  const notificationCount = notifications.filter(notification => !notification.isHidden).length;

  // used in rendering red dot on a notification
  // if user not interacted (confirm, confirm secondary, dismiss click) notification with auto visibility feature
  // will have a red dot top corner of it
  const [isAutoVisibleNotfInteracted, setIsAutoVisibleNotfInteracted] = useState(false);

  useEffect(() => {
    if (isAutoVisibleNotfInteracted) {
      // used in rendering a red dot on a notification
      const seenNotifications = notifications.map(notif => (notif.isAutoVisible ? { ...notif, isSeen: true } : { ...notif }));
      const autoVisibleNotification = notifications.filter(notif => notif.isAutoVisible)[0];
      setNotifications(seenNotifications);

      // used in rendering autovisible notification
      if (!autoVisibleNotification) return;
      const { autoVisibleLocalStActionKey } = notificationConfig[autoVisibleNotification.name];
      setFlagToLocSt(autoVisibleLocalStActionKey);
    }
  }, [isAutoVisibleNotfInteracted]);

  // used in rendering a red dot on a notification
  useEffect(() => {
    if (isAutoVisibleNotificationMode || visible || notifications.length === 0) return;
    const seenNotificationsLcSt = notifications.reduce((acc, current) => {
      return { ...acc, [current.name]: { id: current.id, name: current.name, isSeen: true } };
    }, {});
    window.localStorage.setItem('ncseen', JSON.stringify(seenNotificationsLcSt));
    const seenNotifications = notifications.map(notif => ({ ...notif, isSeen: true }));
    setNotifications(seenNotifications);
  }, [visible]);

  // used in rendering a red dot on the bell
  const unseenNotifications = notifications.filter(notif => !notif.isSeen);
  const unseenNotificationCount = unseenNotifications.length;
  const hasUnseenNotification = unseenNotificationCount > 0;

  const hideNotificationPure = (notification, notifcs) => {
    return notifcs.map(notf => ({
      ...notf,
      isHidden: notf.id === notification.id ? true : notf.isHidden
    }));
  };

  const hideNotification = (notification, hipaaStep) => {
    if (hipaaStep === hipaaSteps.firstStep) return;
    const newNotifications = hideNotificationPure(notification, notifications);
    setNotifications(newNotifications);
  };

  // bell click
  const handleBellClick = () => {
    if (isAutoVisibleNotificationMode) {
      setAutoVisibleNotificationClosed(true);
      setVisible(true);
      return;
    }
    setVisible(!visible);
  };

  // confirm
  const handleConfirm = (notification, hipaaStep = false) => {
    const { dismissAfterConfirm = false } = notification.confirm;
    const confirmCb = notification.confirm && notification.confirm.callback;

    if (typeof confirmCb === 'function') {
      confirmCb(notification, user);
    }
    if (hipaaStep) {
      hideNotification(notification, hipaaStep);
    }
    // auto visibility management
    if (isAutoVisibleNotificationMode) {
      setIsAutoVisibleNotfInteracted(true);
    }
    if (dismissAfterConfirm) {
      hideNotification(notification);
    }
  };

  // confirm secondary
  const handleConfirmSecondary = notification => {
    const { dismissAfterConfirm = false } = notification.confirmSecondary;
    const confirmSecondary = notification.confirmSecondary && notification.confirmSecondary.callback;

    if (typeof confirmSecondary === 'function') {
      confirmSecondary(notification, user);
    }
    if (isAutoVisibleNotificationMode) {
      setIsAutoVisibleNotfInteracted(true);
    }
    if (dismissAfterConfirm) {
      hideNotification(notification);
    }
  };

  // dismiss
  const handleDismiss = (notification, hipaaStep = false, hipaaFirstStepAnswer = null) => {
    const dismissCb = notification.dismiss && notification.dismiss.callback;
    if (hipaaStep && hipaaStep === hipaaSteps.secondStep && typeof dismissCb === 'function') {
      dismissCb(hipaaFirstStepAnswer);
    } else if (typeof dismissCb === 'function') {
      dismissCb(notification, isAutoVisibleNotificationMode, user);
    }
    if (!isAutoVisibleNotificationMode) {
      hideNotification(notification, hipaaStep);
    }
    // auto visibility management
    if (isAutoVisibleNotificationMode) {
      setVisible(false);
      setIsAutoVisibleNotfInteracted(true);
    }
  };

  // dismiss all
  const handleDismissAll = () => {
    const dismissedNotifications = notifications.filter(notf => notf.dismissable);
    dismissedNotifications.forEach(notf => {
      const dismissCb = notf.dismiss && notf.dismiss.callback;
      if (typeof dismissCb === 'function') dismissCb(notf);
    });

    const newNotifications = notifications.map(notf => ({
      ...notf,
      isHidden: notf.dismissable ? true : notf.isHidden
    }));
    setNotifications(newNotifications);

    logNotificationCenterActions({
      action: 'dismiss-all',
      target: 'notification-list'
    });
  };

  // enabler
  useEffect(() => {
    const isUserHasNotification = notificationCount > 0;
    const isNcEnabledForUser = user.notificationCenterEnabled === '1';
    if (isUserHasNotification) {
      setNotificationcenterShouldRender(true);
      if (!isNcEnabledForUser) {
        updateUserSettings('notificationCenterEnabled', '1');
      }
    }
  }, [user, notifications, updateUserSettings]);

  if (!notificationCenterShouldRender) return null;

  return (
    <>
      {isAutoVisibleNotificationMode && <ScAutoVisibleNotificationOverlay />}
      <ABTestActionContext.Provider value={{ actionsLoaded: false, registerJotformAction: f => f }}>
        <ActionContext.Provider value={logNotificationCenterActions}>
          <NotificationBell
            ref={notificationBellRef}
            isNotificationListVisible={visible}
            notificationCount={notificationCount}
            enterpriseTheme={enterpriseTheme}
            hasUnseenNotification={hasUnseenNotification}
            bellContainerElForMobile={bellContainerElForMobile}
            onBellClick={handleBellClick}
          />
          {visible
          && (
          <ResponsiveAdapter
            containerRef={containerRef}
            popoverProps={{ containerRef, targetRef: notificationBellRef }}
          >
            <List
              headerProps={{
                notificationCount,
                hideHeader: isAutoVisibleNotificationMode,
                onDismissAll: handleDismissAll,
                onBackButtonClick: handleBellClick
              }}
              bodyProps={{
                user,
                notificationCount,
                isAutoVisibleNotfInteracted,
                isAutoVisibleNotificationMode,
                setAutoVisibleNotificationClosed,
                notifications: renderingNotifications,
                onConfirm: handleConfirm,
                onDismiss: handleDismiss,
                onConfirmSecondary: handleConfirmSecondary
              }}
            />
          </ResponsiveAdapter>
          )}
        </ActionContext.Provider>
      </ABTestActionContext.Provider>
    </>
  );
};

NotificationCenter.propTypes = {
  user: shape({}).isRequired,
  bellContainerElForMobile: object.isRequired,
  initInAutoVisibleMode: bool,
  enterpriseTheme: bool
};

NotificationCenter.defaultProps = {
  initInAutoVisibleMode: false,
  enterpriseTheme: false
};

export default NotificationCenter;
