import React, { useState } from 'react';

import { t } from '@jotforminc/translation';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
// import { setCookie } from '@jotforminc/utils';
import GatewayWarningDialog from './GatewayWarningDialog';

const GatewayWarning = () => {
  const [shouldShow, setShouldShow] = useState(false);

  const getTexts = () => {
    return {
      title: 'Your action is required',
      subtitle: 'Please reconnect your Square forms until March 1, 2024 to continue collecting subscriptions.',
      cta: 'See Connected Forms'
    };
  };

  const {
    title, subtitle, cta
  } = getTexts();

  return (
    <div className="flex flex-col justify-start items-center m-2 py-2 px-3 radius bg-red-100 border border-solid border-red-300 xs:flex-row">
      <div className="flex mb-2 justify-start items-center grow-1  xs:mb-0">
        <IconExclamationTriangleFilled className="flex shrink-0 w-6 h-6 fill-red-500 mr-3" />
        <div className="text-center xs:text-left">
          <p className="color-navy-700 text-sm font-bold line-height-md tracking-lg">{t(title)}</p>
          <p className="color-navy-700 text-sm font-normal line-height-md tracking-lg">{t(subtitle)}</p>
        </div>
      </div>
      <button
        type="button"
        className="flex shrink-0 bg-navy-700 color-white radius py-2 px-3 text-xs font-medium line-height-xs tracking-lg hover:bg-navy-600"
        onClick={() => setShouldShow(prevShouldShow => !prevShouldShow)}
      >
        {t(cta)}
      </button>
      {shouldShow
        && (
        <GatewayWarningDialog
          onClose={() => setShouldShow(false)}
          shouldShow={shouldShow}
        />
        )}
    </div>
  );
};

export default GatewayWarning;
