import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewContactWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewContactWizard" */'./LazyWizards/LazyCreateNewContactWizard'));

const root = document.getElementById('modal-container');

export const openCreateNewContactModal = ({
  props
}) => {
  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      unmountComponentAtNode(root);
    };

    const createContact = contactDetails => new Promise((resolveCreateContact, rejectCreateContact) => {
      resolve({
        contactDetails,
        resolveCreateContact,
        rejectCreateContact
      });
    });

    lazyRender({
      root,
      children: (
        <CreateNewContactWizard
          initialVisibilityState={true}
          targetContainer="#modal-container"
          usePortal
          onHide={handleClose}
          onSubmit={createContact}
          {...props}
        />
      )
    });
  });
};
