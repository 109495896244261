import React from 'react';

import '@jotforminc/jotform.css';
import './ai.scss';

import type { User } from '@jotforminc/types';
import { AIProvider } from '../context';

import CreateAIForm from './CreateAIForm';

import ErrorBoundry from '../hocs/ErrorBoundry';

type Props = {
  user: User
  showHeader?: boolean,
  creationLogger?: (txt: string) => void,
  onFormCreationSuccess?: (formID: string) => void
}

const Root: React.FC<Props> = ({
  user, showHeader = true, creationLogger = f => f, onFormCreationSuccess
}) => {
  return (
    <ErrorBoundry>
      <AIProvider user={user}>
        <CreateAIForm onFormCreationSuccess={onFormCreationSuccess} showHeader={showHeader} creationLogger={creationLogger} />
      </AIProvider>
    </ErrorBoundry>
  );
};

export default Root;
