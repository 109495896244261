import React, { ReactNode } from 'react';
import { t, translationRenderer } from '@jotforminc/translation';

export const generateError = (message: { type?: string, assetType?: string, assetName?: string, connectedAssetName?: string }): string | ReactNode => {
  const {
    type, assetName = '', assetType = '', connectedAssetName = ''
  } = message || {};

  switch (type) {
    case 'ASSET_CANNOT_MOVED':
      return translationRenderer("[1[{assetName}]] can't be moved.")({
        renderer1: () => <b>{assetName}</b>
      });
    case 'ENCRYPTED_CONNECTED_ASSET':
      return translationRenderer('[1[{assetName}]] and its connected asset [2[{connectedAssetName}]] have form encryption. You can try moving forms without form encryption.')({
        renderer1: () => <b>{assetName}</b>,
        renderer2: () => <b>{connectedAssetName}</b>
      });
    case 'ENCRYPTED_ASSET':
      return translationRenderer('[1[{assetName}]] has form encryption. You can try moving forms without form encryption.')({
        renderer1: () => <b>{assetName}</b>
      });
    case 'REPORT_NOT_CONNECTED_TO_FORM':
      return translationRenderer('The reports [1[{assetName}]] must be connected to a form in order to be moved.')({
        renderer1: () => <b>{assetName}</b>
      });
    case 'MOVING_NOT_SUPPORTED':
      return translationRenderer('Moving is not supported for [1[assetType]].')({
        renderer1: () => <b>{assetType}</b>
      });
    default:
      return t('An error occured while calculating connected assets.');
  }
};
