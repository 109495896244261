import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { LetterAvatar } from '../LetterAvatar';
import { PrettyUser } from '../../types';
import { CustomFilter, Filter, PrettyLog } from './types';

type UserDetailsProps = {
  handleFiltersChange: (filter: Filter | CustomFilter) => void,
  logEntry: PrettyLog,
  filters: Filter | CustomFilter,
  members?: PrettyUser[]
};

export const UserDetails = ({
  logEntry,
  filters,
  handleFiltersChange,
  members
}: UserDetailsProps): ReactElement => {
  const {
    username,
    name,
    userEmail
  } = logEntry;

  const avatar = members?.find(({ username: memberName }) => memberName === username)?.avatar;

  const onFullnameClick = (e: any) => {
    const value = e.target.getAttribute('data-username');
    if (value) {
      const newFilter = { username: value, ...filters };
      handleFiltersChange(newFilter);
    }
  };

  const userSecondaryInfo = userEmail || username;

  return (
    <div className="userDetails-wrapper">
      {avatar && avatar.indexOf('letter-avatar.png') === -1 ? (
        <div
          className={classNames('jfUser-table-column-avatar-wrapper')}
          aria-label="user avatar"
          style={{ backgroundImage: `url("${avatar}")`, position: 'static' }}
          role="img"
        />
      ) : (
        <LetterAvatar
          name={name || username}
          aria-label="user avatar"
        />
      )}
      <div className="userDetails">
        {name
        && (
          <span
            className="userFullname"
            data-username={username}
            onClick={onFullnameClick}
            onKeyDown={onFullnameClick}
          >
            {name}
          </span>
        )}
        {userSecondaryInfo
        && (
          <span className="userEmail">
            {userSecondaryInfo}
          </span>
        )}
      </div>
    </div>
  );
};

UserDetails.defaultProps = {
  members: []
};
