/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import classnames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import {
  string, number, oneOfType, bool, func, node, elementType, element, arrayOf, shape
} from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@jotforminc/tooltip';

import {
  IconGridDotsVertical,
  IconBellDiagonalFilled,
  IconChevronDown,
  IconFolderFilled,
  IconUserFilled,
  IconBoxArchiveArrowDownFilled,
  IconTrashFilled,
  IconStarFilled
} from '@jotforminc/svg-icons';

import FolderContextMenu from './FolderContextMenu/index';
import Image from '../Image';

import WatchmanRecorder from '../../utils/WatchmanRecorder';
import translate from '../../utils/translate';

import { DEFAULT_FOLDER_IDS, FOLDER_TYPES } from '../../constants';
import { FolderBadgeRenderer, TeamDragFolderBadgeRenderer } from '../ListItem/utils';
import { SELECTORS } from '../../store/selectors';
import { ACTION_CREATORS } from '../../store/actionCreators';

const SubTextWrapper = ({ subText = '', children }) => {
  return subText ? <div className="teamFolderType">{children}</div> : children;
};

SubTextWrapper.propTypes = {
  subText: oneOfType([string, node, element]),
  children: node.isRequired
};

const BasicFolder = ({
  id,
  text = '',
  subText = '',
  color,
  badge,
  hasIcon = true,
  folderType,
  hasSubfolder = false,
  isItemHovering = false,
  hasContextMenu = false,
  hasNewBadge = false,
  forceContextMenuVisibility = false,
  children,
  changeContextMenuVisibility = f => f,
  extendedSection = null,
  menuItems = [],
  customIconProps = {
    iconBgColor: '',
    url: '',
    icon: '',
    emojiId: '',
    emojiSize: '',
    iconColor: '',
    iconSvgRef: ''
  },
  canExpand = false,
  isFocussable = true,
  isTeamDragging = false,
  preventReorder = false,
  hasSharerIcon = false,
  isDragAnimationHidden = false
}) => {
  const dispatch = useDispatch();
  const newReorderFolderTypesArray = [FOLDER_TYPES.TEAM_FOLDER, FOLDER_TYPES.ASSET_FOLDER];
  const isNewReorderFolder = newReorderFolderTypesArray.indexOf(folderType) > -1 && !preventReorder;
  const expandedFolders = useSelector(SELECTORS.getExpandedFolders(folderType));
  const isFolderExpanded = expandedFolders.indexOf(id) > -1;
  const [isExpanded, setExpanded] = useState(isNewReorderFolder ? isFolderExpanded : false);
  const isStaticText = [FOLDER_TYPES.STATIC, FOLDER_TYPES.BUTTON, FOLDER_TYPES.CREATE_TEAM_BUTTON, FOLDER_TYPES.ALL_TEAM_RESOURCES].indexOf(folderType) > -1;

  const hasCustomIcon = [FOLDER_TYPES.TEAM, FOLDER_TYPES.ALL_TEAM_RESOURCES].indexOf(folderType) > -1 || customIconProps.hasCustomIcon;
  const ref = useRef(true);
  const firstRender = ref.current;
  const handleCollapseSubfolders = expanded => {
    if (!isNewReorderFolder) return;

    const nestableItemChildren = document.querySelector(`.nestable-item-${id}`)?.querySelector('ol');
    if (nestableItemChildren) {
      if (expanded) {
        nestableItemChildren.style.display = 'block';
      } else {
        nestableItemChildren.style.display = 'none';
      }
    }
  };
  const handleExpandClick = event => {
    event.stopPropagation();
    setExpanded(!isExpanded);
  };

  useEffect(() => {
    handleCollapseSubfolders(isExpanded);
    if (isExpanded) {
      if (!isFolderExpanded) {
        dispatch(ACTION_CREATORS.addExpandedFolders({ id, folderType }));
      }
    } else if (isFolderExpanded) {
      dispatch(ACTION_CREATORS.removeExpandedFolders({ id, folderType }));
    }
  }, [isExpanded]);

  useEffect(() => {
    if (!isExpanded) {
      if (isItemHovering && !isTeamDragging) {
        setExpanded(true);
      }
      if (firstRender) {
        setExpanded(folderType === FOLDER_TYPES.ALL_TEAM_RESOURCES ? true : (canExpand || isFolderExpanded));
      }
    }
    ref.current = false;
  }, [isItemHovering, isFolderExpanded, isExpanded, isTeamDragging]);

  const renderCustomIcons = () => {
    if (customIconProps.FolderIconComponent) {
      const IconRenderer = customIconProps.FolderIconComponent;
      return <IconRenderer className="folder-icon w-5 h-5 shrink-0" />;
    }
    return <Image {...customIconProps} />;
  };

  const handleClickToContextMenu = isVisible => {
    if (folderType === FOLDER_TYPES.TEAM && isVisible) WatchmanRecorder.trackEventForCustomProject('click', 'teamFolderContextMenuButton', 'teams', true);
    changeContextMenuVisibility(isVisible);
  };

  const folderText = isStaticText ? translate(text) : text;
  const isHoverActive = [FOLDER_TYPES.BUTTON, FOLDER_TYPES.CREATE_TEAM_BUTTON].indexOf(folderType) === -1 && folderText?.length > 20;

  const iconStyle = 'w-4 h-4 mr-1.5 shrink-0';

  const checkDesignSystemIcon = folderId => {
    switch (folderId) {
      case DEFAULT_FOLDER_IDS.FAVORITES:
        return {
          icon: <IconStarFilled aria-hidden="true" className={classnames('color-yellow-300', iconStyle)} />
        };
      case DEFAULT_FOLDER_IDS.TRASH:
        return {
          icon: <IconTrashFilled aria-hidden="true" className={classnames('color-navy-300', iconStyle)} />
        };
      case DEFAULT_FOLDER_IDS.ARCHIVE:
        return {
          icon: <IconBoxArchiveArrowDownFilled aria-hidden="true" className={classnames('color-navy-300', iconStyle)} />
        };
      default:
        return {
          icon: null
        };
    }
  };
  const getFolderText = () => {
    if (text === 'MY FORMS') {
      return <h1>{folderText}</h1>;
    }
    return <span>{folderText}</span>;
  };

  return (
    <>
      <div
        className={`folder ${isHoverActive ? 'jfTooltipNew-hoverTarget' : ''}`}
        data-title={text}
        aria-label={text}
        tabIndex={isFocussable ? '0' : '-1'}
      >
        <div className={classnames('folder-drag-overlay', { isDragAnimationHidden })} style={{ display: 'none' }} draggable={true}>
          {folderType === FOLDER_TYPES.TEAM ? (
            <TeamDragFolderBadgeRenderer folder={{
              id, text, color, customIconProps
            }}
            />
          ) : (
            <FolderBadgeRenderer folders={[{
              id, text, color
            }]}
            />
          )}
        </div>
        {checkDesignSystemIcon(id).icon ? checkDesignSystemIcon(id).icon : (
          <div className={classnames('folder-dragIcon', { isDragAnimationHidden })}>
            <IconGridDotsVertical aria-hidden="true" />
          </div>
        )}
        {hasSharerIcon && <IconUserFilled className="folder-icon w-4 h-4 shrink-0" fill="#6F76A7" />}
        {hasIcon && (hasCustomIcon ? renderCustomIcons() : <IconFolderFilled className="folder-icon w-4 h-4 shrink-0" aria-hidden="true" fill={color} />)}
        <span className={classnames('folder-title', subText ? 'subText' : '', hasSubfolder ? 'subFolder' : '')}>
          <SubTextWrapper subText={subText}>
            {getFolderText()}
            {subText && <span className="folder-subtext">{subText}</span>}
            {hasSubfolder && (
              <button
                type="button"
                onKeyDown={f => f}
                onClick={handleExpandClick}
                className={classnames({ folderButton: true, isExpanded })}
                aria-label={translate(isExpanded ? 'Shrink subfolders' : 'Expand subfolders')}
              >
                <IconChevronDown className="w-3 h-3 shrink-0" />
              </button>
            )}
          </SubTextWrapper>
          {hasNewBadge && <span className='new-badge'>{translate('New')}</span>}
        </span>
        {hasContextMenu && <FolderContextMenu onClick={handleClickToContextMenu} folderID={id} menuItems={menuItems} />}
        {badge ? (
          <span className="folderBadge">
            <IconBellDiagonalFilled className="folderBadge-bell" style={{ display: 'none' }} />
            {badge}
          </span>
        ) : null}
        {isHoverActive && (
          <Tooltip
            v2
            wrapperClassName="tooltip"
            align="Center"
            attach="Top"
            style={{
              backgroundColor: '#141E46',
              wordBreak: 'break-word'
            }}
          >
            {folderText}
          </Tooltip>
        )}
      </div>
      {((hasSubfolder && isExpanded) || forceContextMenuVisibility) && !isNewReorderFolder && children}
      {extendedSection}
    </>
  );
};

BasicFolder.propTypes = {
  id: oneOfType([string, number]).isRequired,
  folderType: string.isRequired,
  text: string,
  subText: oneOfType([string, node, element]),
  color: string.isRequired,
  badge: oneOfType([string, number]).isRequired,
  hasIcon: bool,
  hasSubfolder: bool,
  isItemHovering: bool,
  hasContextMenu: bool,
  hasNewBadge: bool,
  forceContextMenuVisibility: bool,
  children: node.isRequired,
  changeContextMenuVisibility: func,
  WrapperRenderer: elementType,
  extendedSection: oneOfType([node, element]),
  menuItems: arrayOf(shape({})),
  customIconProps: shape({}),
  canExpand: bool,
  isFocussable: bool,
  isTeamDragging: bool,
  preventReorder: bool,
  hasSharerIcon: bool,
  isDragAnimationHidden: bool
};

export default BasicFolder;
