import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

const CreateNewWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewFormWizard" */'./LazyWizards/LazyCreateNewFormWizard'));

const rootElement = document.getElementById('modal-container');

export const openCreateNewFormModal = (props, callback = f => f) => {
  return new Promise((resolve, reject) => {
    const createFlowWrapperElement = document.getElementById('createFlowModal-wrapper');

    // A/B Test: createNewWizardWithSubtextV2
    if (!window.createNewWizardWithSubtextV2Actions) {
      const { JotFormActions } = window;
      if (typeof JotFormActions === 'function') {
        window.createNewWizardWithSubtextV2Actions = JotFormActions('createNewWizardWithSubtextV2');
      }
    }

    // Action tracking: sign beta
    const { allowSign, username } = window.user || {};
    if (allowSign) {
      if (!window.signActions) {
        const { JotFormActions } = window;
        if (typeof JotFormActions === 'function') {
          window.signActions = JotFormActions('documents-listings');
        }
      }
      window.signActions.tick({ actor: username, action: 'click', target: 'create-form-button' });
    }

    const registerJotformAction = ({ action, target }) => {
      const { username: actor = null } = window.user || {};
      if (!actor) return;
      if (!window.createNewWizardWithSubtextV2Actions) return;
      window.createNewWizardWithSubtextV2Actions.tick({ actor, action, target });
    };

    registerJotformAction({ action: 'click', target: '.createForm' });

    const handleClose = () => {
      reject();
      if (props && typeof props.onHide === 'function') props.onHide();
      // document.removeEventListener('keydown', handleDocumentKeyDown, { capture: true });
      window.history.replaceState({}, '', window.location.pathname);
      unmountComponentAtNode(rootElement);
      if (props.closeButtonCallback) {
        props.closeButtonCallback();
      }
    };
    // const handleConfirm = value => {
    //   resolve(value);
    //   // document.removeEventListener('keydown', handleDocumentKeyDown, { capture: true });
    //   ReactDOM.unmountComponentAtNode(root);
    // };

    const onViewChange = isMain => {
      if (createFlowWrapperElement) {
        if (isMain) {
          createFlowWrapperElement.classList.remove('isHidden');
        } else {
          createFlowWrapperElement.classList.add('isHidden');
        }
      }
    };

    const handleShow = () => {
      if (props.openWizardCallback) props.openWizardCallback();
    };

    lazyRender({
      root: rootElement,
      callback,
      children: (
        <CreateNewWizard
          {...props}
          onShow={handleShow}
          user={window.user} // A/B Test: createNewWizardWithSubtextV2
          onHide={handleClose}
          initialVisibilityState={true}
          closeable={props.canClose}
          disableActionLogger
          isSignReleased={window?.isSignReleased}
          onViewChange={onViewChange}
        />
      )
    });
  });
};
