import 'map.prototype.tojson';

if (!global.Element.prototype.matches) {
  global.Element.prototype.matches = global.Element.prototype.msMatchesSelector || global.Element.prototype.webkitMatchesSelector;
}

if (!global.Element.prototype.closest) {
  global.Element.prototype.closest = function closest(s) {
    let el = this;

    do {
      if (global.Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
