import { handleCustomNavigation } from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';
import { setUserNotificationStatus } from '../utils';

export const setStoreAppRecToLocSt = localStActionKey => StorageHelper.setLocalStorageItem({
  key: localStActionKey, value: '1'
});

const onConfirm = async ({ actionTargetName }) => {
  handleCustomNavigation(`${window.location.origin}/myapps/?action=createRecommendedOnlineStoreApp&recSource=2`);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, disabledKey, localStActionKey }) => {
  setStoreAppRecToLocSt(localStActionKey);
  setUserNotificationStatus(disabledKey);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: actionTargetName
    });
  }
};

const checkStoreRecommendation = notifications => {
  let notification = notifications.find(notif => notif.name === 'storeRecommendation');
  const otherNotifications = notifications.filter(notif => notif.name !== 'storeRecommendation');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkStoreRecommendation;
