/* eslint-disable complexity */
import React, {
  useMemo, useState, useCallback, useEffect
} from 'react';
import {
  arrayOf, shape, string, func, bool, oneOfType, number, node, element
} from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { Hooks } from '@jotforminc/uikit';
import { useDrop, useDrag } from 'react-dnd';
import WatchmanRecorder from '../../utils/WatchmanRecorder';

import BasicFolder from '../Folder/BasicFolder';

import {
  FOLDER_DROP_POSITIONS,
  FOLDER_TYPES,
  LISTING_TYPES,
  DEFAULT_FOLDER_IDS
} from '../../constants';
import { calculateHoverPosition, getFolderRenderer } from '../Folder/utils';
import { PreviewRenderer, OverPreviewRenderer } from '../Folder/PreviewRenderer';

const Folder = props => {
  const {
    id, className = '', color = '#6F76A7', text = '', badge = '', subfolders = [], canDrag = false, hasContextMenu = false, hasNewBadge = false, selectedFolderID = '', handleClick = f => f,
    isDroppable = false, hasIcon = false, onDrop = f => f, children, folderType = FOLDER_TYPES.STATIC, isRoot = false, isShared = false, path = '', subText = '', extendedSection = null,
    menuItems = [], customIconProps = {
      iconBgColor: '',
      url: '',
      icon: '',
      emojiId: '',
      emojiSize: '',
      iconColor: '',
      iconSvgRef: ''
    }, canExpand = false, isFocussable = false, hasSharerIcon = false
  } = props;

  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [hoverPosition, setHoverPosition] = useState(false);

  const getHoverPos = ({ isOver, monitor }) => {
    // eslint-disable-next-line no-use-before-define
    return isOver ? calculateHoverPosition(combinedRef?.current, monitor) : false;
  };

  const debouncedUpdateHoverPosition = debounce(newHoverPos => {
    if (hoverPosition !== newHoverPos) setHoverPosition(newHoverPos);
  }, 100);

  const isDraggingFolderAncestor = draggingFolderPath => path && path.includes(draggingFolderPath);
  const [{
    isHighlighted,
    isItemHovering,
    draggingItemType,
    isTeamDragging,
    isTeamAssetDragging
  }, dropRef] = useDrop(() => ({
    canDrop: currentDraggingItem => {
      if (currentDraggingItem.folderType === FOLDER_TYPES.TEAM
        || (isShared && currentDraggingItem.type === 'folder')
        || isDraggingFolderAncestor(currentDraggingItem.path)
        || currentDraggingItem.isTeamAsset) {
        return false;
      }
      return isDroppable || isRoot;
    },
    accept: [...(isRoot ? [] : Object.values(LISTING_TYPES)), 'folder'],
    drop: (item, monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (!isOver) return null;

      onDrop({
        item,
        monitor,
        folderID: id,
        position: getHoverPos({ isOver, monitor })
      });
    },
    collect: monitor => {
      const item = monitor.getItem();
      const itemType = monitor.getItemType();
      const isOver = monitor.isOver({ shallow: true });
      const isTeamItem = item?.folderType === FOLDER_TYPES.TEAM;
      const isTeamAsset = item?.isTeamAsset;
      debouncedUpdateHoverPosition(getHoverPos({ isOver, monitor }));

      return {
        isItemHovering: isOver && itemType === 'folder' && !isTeamItem && !isShared && !isDraggingFolderAncestor(item.path),
        isHighlighted: isOver && Object.values(LISTING_TYPES).indexOf(itemType) !== -1 && !isTeamAsset,
        draggingItemType: itemType,
        isTeamDragging: isTeamItem,
        isTeamAssetDragging: isTeamAsset
      };
    }
  }), [isDroppable]);
  const [{ draggingItem }, dragRef] = useDrag(() => ({
    item: {
      id,
      text,
      color,
      canDrag,
      folderType,
      path,
      type: 'folder'
    },
    type: 'folder',
    canDrag: () => {
      return canDrag;
    },
    collect: monitor => ({
      draggingItem: monitor.getItem()
    }),
    previewOptions: {
      DraggingState: true
    }
  }));

  useEffect(() => {
    if (!draggingItemType) {
      setHoverPosition(false);
    }
  }, [draggingItemType]);

  const refList = [canDrag && dragRef, (isDroppable || isRoot) && dropRef].filter(val => val);
  const combinedRef = Hooks.useCombinedRefs(...refList);
  const isCurrentFolderSelected = selectedFolderID === id;
  const hasSubfolder = subfolders.length > 0;
  const showDraggingFolderPreview = isItemHovering && draggingItem && draggingItem.id !== id && draggingItem.type === 'folder' && !isShared && !isTeamDragging;

  const classes = classnames({
    isSelected: isCurrentFolderSelected,
    folderItem: true,
    [className]: true,
    isContextMenuOpen,
    isHighlighted: isHighlighted && (!showDraggingFolderPreview || hoverPosition === FOLDER_DROP_POSITIONS.OVER)
  });

  const changeContextMenuVisibility = isExpanded => setContextMenuOpen(isExpanded);
  const handleRendererClick = e => {
    e.stopPropagation();
    const isSharedWithMe = id === DEFAULT_FOLDER_IDS.SHARED_WITH_ME;
    if (isSharedWithMe || isShared) {
      const target = folderType === FOLDER_TYPES.USERNAME ? 'sharer' : 'sharerFolder';
      WatchmanRecorder.trackEvent('click', isSharedWithMe ? DEFAULT_FOLDER_IDS.SHARED_WITH_ME : target, 'myFormsSharedWithMe', true);
    }
    handleClick(id, folderType);
  };

  const Renderer = useMemo(() => getFolderRenderer(folderType), [folderType]);
  const FolderPreview = useCallback(() => (
    <BasicFolder
      {...draggingItem}
      WrapperRenderer={PreviewRenderer}
    />
  ), [draggingItem]);

  const OverFolderPreview = useCallback(() => (
    <BasicFolder
      {...draggingItem}
      WrapperRenderer={OverPreviewRenderer}
    />
  ), [draggingItem]);

  return (
    <Renderer
      key={id}
      ref={combinedRef}
      className={classes}
      onClick={handleRendererClick}
    >
      {!isRoot && showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.BEFORE ? (
        <FolderPreview />
      ) : null}
      <BasicFolder
        id={id}
        text={text}
        color={color}
        badge={badge}
        hasIcon={hasIcon}
        folderType={folderType}
        hasSubfolder={hasSubfolder}
        hasContextMenu={hasContextMenu}
        hasNewBadge={hasNewBadge}
        subText={subText}
        isItemHovering={isItemHovering}
        changeContextMenuVisibility={changeContextMenuVisibility}
        forceContextMenuVisibility={!isShared && hoverPosition === FOLDER_DROP_POSITIONS.OVER && !isTeamDragging && !isTeamAssetDragging}
        extendedSection={extendedSection}
        menuItems={menuItems}
        customIconProps={customIconProps}
        canExpand={canExpand}
        isFocussable={isFocussable}
        isTeamDragging={isTeamDragging}
        preventReorder={isShared}
        hasSharerIcon={hasSharerIcon}
      >
        {children}
        {showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.OVER ? (
          <OverFolderPreview />
        ) : null}
      </BasicFolder>
      {showDraggingFolderPreview && hoverPosition === FOLDER_DROP_POSITIONS.AFTER ? (
        <FolderPreview />
      ) : null}
    </Renderer>
  );
};

Folder.propTypes = {
  id: string.isRequired,
  className: string,
  text: string,
  color: string,
  badge: oneOfType([string, number]),
  subfolders: arrayOf(shape({})),
  canDrag: bool,
  hasContextMenu: bool,
  hasNewBadge: bool,
  selectedFolderID: string,
  handleClick: func,
  isDroppable: bool,
  hasIcon: bool,
  onDrop: func,
  children: shape({}).isRequired,
  folderType: string,
  isRoot: bool,
  isShared: bool,
  path: string,
  subText: oneOfType([string, node, element]),
  extendedSection: oneOfType([node, element]),
  menuItems: arrayOf(shape({})),
  customIconProps: shape({}),
  canExpand: bool,
  isFocussable: bool,
  hasSharerIcon: bool
};

export default Folder;
