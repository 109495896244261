import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { safeLazy, lazyRender } from '@jotforminc/safe-lazy-import';

import { fetchFormQuestions } from '../api';

const CreateNewReportWizard = safeLazy(() => import(/* webpackChunkName: "LazyWizard_CreateNewReportWizard" */'./LazyWizards/LazyCreateNewReportWizard'));

const root = document.getElementById('modal-container');

export const openEditReportModal = ({
  forms, selectedID, reportID, formID, reportData, ...props
}) => {
  return new Promise((resolve, reject) => {
    const handleClose = () => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('edit-digest')) {
        window.history.replaceState({}, '', window.location.pathname);
      }
      reject();
      unmountComponentAtNode(root);
    };
    const skipValue = selectedID || formID ? { form: true } : null;

    const updateReport = (id, payload) => new Promise((resolveUpdateReport, rejectUpdateReport) => {
      resolve({
        id,
        payload,
        resolveUpdateReport,
        rejectUpdateReport
      });
    });

    const {
      reportName, reportType, reportPassword, fields, isProtected, reportSettings, digest, user, digestProps
    } = reportData;

    lazyRender({
      root,
      children: (
        <CreateNewReportWizard
          disableActionLogger
          usePortal
          fetchFormQuestions={fetchFormQuestions}
          forms={forms}
          initialVisibilityState={true}
          onHide={handleClose}
          targetContainer="#wizardPortal"
          formId={selectedID || formID}
          skip={skipValue}
          isReportEdit={true}
          onSave={updateReport}
          reportType={reportType}
          reportName={reportName}
          reportPassword={reportPassword}
          fields={fields}
          isProtected={isProtected}
          reportSettings={reportSettings}
          reportID={reportID}
          digest={digest}
          user={user}
          digestProps={digestProps}
          {...props}
        />
      )
    });
  });
};
