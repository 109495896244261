/* eslint-disable max-len */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';

import SalesforceLogo from '../assets/campaigns/2024/announcing-salesforce/logo.svg';
import Bird from '../assets/campaigns/2024/announcing-salesforce/bird.png';

import './styles/announcing-salesforce-header-banner.scss';

export const AnnouncingSalesforceHeaderBanner = ({
  utmSource,
  accountTypeName
}) => {
  const isEng = window.currentLocale === 'en-US';
  return (
    <div className="announcing-salesforce-wrapper">
      <div className="announcing-salesforce-container">
        <div className="image">
          <img src={Bird} alt="Bird" />
        </div>
        <div className="salesforce-content-container">
          <div className="heading">
            <span>
              {translationRenderer('Announcing 5 new features for [1[Salesforce]]')({
                renderer1: text => (isEng ? <SalesforceLogo className="salesforce-logo" /> : <strong>{text}</strong>)
              })}

            </span>
          </div>
          <a
            className="locale cta-button"
            href={`https://www.jotform.com/blog/announcing-new-features-for-salesforce-forms-2024/?utm_source=${utmSource}&utm_campaign=${utmSource}&utm_content=${accountTypeName}&utm_medium=myforms-header`}
            target="_blank"
          >
            {t('Discover now')}
          </a>
        </div>
      </div>
    </div>
  );
};

AnnouncingSalesforceHeaderBanner.propTypes = {
  utmSource: string.isRequired,
  accountTypeName: string.isRequired
};

export const renderAnnouncingSalesforceHeaderBanner = ({
  container,
  utmSource,
  accountTypeName
}) => {
  const root = createRoot(container);
  root.render(
    <AnnouncingSalesforceHeaderBanner
      utmSource={utmSource}
      accountTypeName={accountTypeName}
    />
  );
};
