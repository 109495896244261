import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useMobileView = breakpoint => {
  const [isMobileView, setIsMobileView] = useState();

  const handleResize = () => {
    const mediaQuery = global.matchMedia(`(max-width: ${breakpoint})`);
    setIsMobileView(mediaQuery.matches);
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 150);
    global.addEventListener('resize', debouncedHandleResize);
    return () => {
      global.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return isMobileView;
};
