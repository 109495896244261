import { createRoot } from 'react-dom/client';
import { Loading } from '@jotforminc/loading';
import React, { Suspense } from 'react';
import { safeLazy } from '@jotforminc/safe-lazy-import';
import MoveConfirmationModal from './moveConfirmationModal';

const MoveConfirmationModalOld = safeLazy(() => import(/* webpackChunkName: "LazyModal_MoveConfirmationModal" */'./modal'));

export const openMoveConfirmationModal = ({
  itemList,
  type,
  targetTeamName,
  targetTeamID,
  sourceTeamID,
  toTeam,
  isRetry,
  rootSelector,
  username,
  isAdmin
}) => {
  const selector = rootSelector || '#wizardPortal';
  const root = document.querySelector(selector);
  const rootNode = createRoot(root);
  const isNewModal = !!window.newMoveToModalEnabled; // Enable new modal for enterprise instances created after 23/07/24
  const ConfirmationModal = isNewModal ? MoveConfirmationModal : MoveConfirmationModalOld;

  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      rootNode.unmount();
    };

    const handleSave = movedAssets => {
      resolve(movedAssets);
      rootNode.unmount();
    };

    rootNode.render(
      <Suspense fallback={<Loading />}>
        <ConfirmationModal
          itemList={itemList}
          type={type}
          targetTeamName={targetTeamName}
          targetTeamID={targetTeamID}
          sourceTeamID={sourceTeamID}
          toTeam={toTeam}
          onClose={handleClose}
          onSave={handleSave}
          isRetry={isRetry}
          username={username}
          isAdmin={isAdmin}
        />
      </Suspense>
    );
  });
};
