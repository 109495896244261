import React, { useState, useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { setCookie } from '@jotforminc/utils';
import './CampaignCountDown.scss';
import {
  counterFirstDay, counterSecondDay,
  counterEndDate, firstDayHours,
  secondDayHours, digits
} from './utils';

let initialCountdown;
const nowDate = Date.now();

if ((nowDate >= counterFirstDay) && (nowDate < counterSecondDay)) {
  initialCountdown = firstDayHours();
} else if ((nowDate >= counterSecondDay) && (nowDate < counterEndDate)) {
  initialCountdown = secondDayHours();
}

export const CampaignCountDown = () => {
  if (!initialCountdown) return null;
  const countdown = initialCountdown;
  const [animationStart, setAnimationStart] = useState(false);

  useEffect(() => {
    const isAnimationActive = !document.cookie.match('ss-campaign-count-animation');
    if (isAnimationActive) {
      setTimeout(() => {
        setCookie('ss-campaign-count-animation', '1', 0.2);
        setAnimationStart(true);
      }, 3000);
    }
  }, []);

  const countToString = countdown.toString();
  const splittedCountedValue = [];
  for (let i = 0; i < countToString.length; i++) {
    splittedCountedValue.push(countToString.charAt(i));
  }
  return (
    <div className="deal-wrapper-ss2023">
      <div className='play'>
        {
          splittedCountedValue.map((digit, index, allValues) => {
            const classes = ['firstPlay', 'secondPlay', 'thirdPlay'];
            return (
              <ul className={`flip ${classes[index]}`}>
                {
                  digits.map(numberD => {
                    let dynamicClass = '';
                    const islastDigit = index === allValues.length - 1;
                    if (islastDigit && animationStart) {
                      if (numberD === parseInt(digit, 10) - 1) dynamicClass = 'active';
                      if (numberD === parseInt(digit, 10)) dynamicClass = 'before';
                    }
                    return (
                      <li className={dynamicClass}>
                        <a href="{}">
                          <div className="up">
                            <div className="shadow" />
                            <div className="inn">{(animationStart && islastDigit) ? numberD : digit}</div>
                          </div>
                          <div className="down">
                            <div className="shadow" />
                            <div className="inn">{(animationStart && islastDigit) ? numberD : digit}</div>
                          </div>
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
            );
          })
        }
      </div>
      <div className="deal-text">{t('Deals Left')}</div>
    </div>
  );
};
