import React, {
  useState, Suspense
} from 'react';
import { bool, func, shape } from 'prop-types';
import { Picker } from '@jotforminc/emoji-mart';
import { t } from '@jotforminc/translation';
import { Loading } from '@jotforminc/loading';
import { Tab, TabListWithStyles } from '@jotforminc/tabs';
import { safeLazy } from '@jotforminc/safe-lazy-import';

const IconSelector = safeLazy(() => import(/* webpackChunkName: "IconEmojiSelector_IconSelector" */'../../IconSelector'));

const IconEmojiSelector = ({
  emojiPickerProperties, isEmojiFirst, ...props
}) => {
  const {
    handleEmojiClick, ...otherProps
  } = emojiPickerProperties;
  const [activeTab, setActiveTab] = useState(false);

  return (
    <TabListWithStyles
      list={{
        iconSelector: { label: t('ICON') },
        emojiSelector: { label: t('EMOJI') }
      }}
      onTabChange={setActiveTab}
      defaultActiveTab={isEmojiFirst ? 'emojiSelector' : ''}
    >
      <Tab id="iconSelector">
        <Suspense fallback={<Loading />}>
          <IconSelector {...props} />
        </Suspense>
      </Tab>
      <Tab id="emojiSelector">
        <Picker
          onClick={handleEmojiClick}
          autoFocus={true}
          showPreview={false}
          showSkinTones={false}
          emojiSize={24}
          color="#0061FF"
          title="Emoji"
          perLine={8}
          native={true}
          isActive={activeTab === 'emojiSelector'} // Hacky way to fix clicking tabs issue for the first render of the Picker.
          {...otherProps}
        />
      </Tab>
    </TabListWithStyles>
  );
};

IconEmojiSelector.propTypes = {
  emojiPickerProperties: shape({
    handleEmojiClick: func
  }),
  isEmojiFirst: bool
};

IconEmojiSelector.defaultProps = {
  emojiPickerProperties: {
    handleEmojiClick: f => f
  },
  isEmojiFirst: false
};

export default IconEmojiSelector;
