import debounce from 'lodash/debounce';
import { sendTrackDataBase, sendTrackData } from './sendTrackData';

class WatchmanRecorder {
  sentEvents = {};

  activePage = '';

  pendingEvents = [];

  customPendingEvents = [];

  canSendEvents = false;

  constructor(activePage = '') {
    this.activePage = activePage;
  }

  setUser = username => {
    this.username = username;
    this.canSendEvents = true;
    this.pendingEvents.forEach(({ action, target }) => {
      this.trackEvent(action, target);
    });
  };

  setUserForCustomEvents = username => {
    this.username = username;
    this.canSendEvents = true;
    this.customPendingEvents.forEach(({
      action,
      target,
      projectName,
      isEnterpriseAllowed
    }) => {
      this.trackEventForCustomProject(action, target, projectName, isEnterpriseAllowed);
    });
  };

  // track events with custom project name with 'listings' suffix: `${customProject}-listings`
  trackEvent = (action = '', target = {}, customProject = '', isEnterpriseAllowed = false) => {
    if (!action) return;
    if (!this.username) {
      this.pendingEvents.push({ action, target });
      return;
    }
    sendTrackData(customProject || this.activePage)(this.username, action, target, isEnterpriseAllowed);
  };

  // track events with a completely custom project name: `${customProject}`
  trackEventForCustomProject = (action = '', target = {}, customProject = '', isEnterpriseAllowed = false) => {
    if (!action) return;
    if (!this.username) {
      this.customPendingEvents.push({
        action,
        target,
        projectName: customProject,
        isEnterpriseAllowed
      });
      return;
    }
    sendTrackDataBase(customProject)(this.username, action, target, isEnterpriseAllowed);
  };

  // track events once with a completely custom project name: `${customProject}`
  trackEventOnceForCustomProject = (action = '', target = {}, customProject = '', isEnterpriseAllowed = false) => {
    if (this.sentEvents[`${action}${target}`]) {
      return;
    }
    this.sentEvents[`${action}${target}`] = true;
    return this.trackEventForCustomProject(action, target, customProject, isEnterpriseAllowed);
  };

  // track event once
  trackEventOnce = (action = '', target = {}) => {
    if (this.sentEvents[action]) {
      return;
    }
    this.sentEvents[action] = true;
    return this.trackEvent(action, target);
  };

  debouncedTrackEvent = debounce(this.trackEvent, 500);
}

export default new WatchmanRecorder(window.activeListing);

// Another way of tracking data, we can send track data via saga or directly from this class.
