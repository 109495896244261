import React, { forwardRef } from 'react';
import { CreateTeamButton } from '@jotforminc/enterprise-components';

import {
  FOLDER_DROP_POSITIONS, FOLDER_TYPES
} from '../../constants';

export const calculateHoverPosition = (itemDOM, monitor, useAfter = false) => {
  const hoverBoundingRect = itemDOM.getBoundingClientRect();
  const hoverMiddleY = hoverBoundingRect.top + 20;
  const clientOffset = monitor.getClientOffset();
  const hoverClientY = clientOffset.y;
  switch (true) {
    case hoverClientY < hoverMiddleY:
      return FOLDER_DROP_POSITIONS.BEFORE;
    // Disabled as calculation triggers only when dragging element enters the droppable place
    case hoverClientY > hoverMiddleY:
      return useAfter ? FOLDER_DROP_POSITIONS.AFTER : FOLDER_DROP_POSITIONS.OVER;
    default:
      return FOLDER_DROP_POSITIONS.OVER;
  }
};

const FolderRendererMap = {
  [FOLDER_TYPES.BUTTON]: forwardRef((props, ref) => <li><button type="button" {...props} ref={ref} /></li>),
  [FOLDER_TYPES.CREATE_TEAM_BUTTON]: forwardRef((props, ref) => <li><CreateTeamButton {...props} ref={ref} /></li>)
};

export const getFolderRenderer = folderType => FolderRendererMap[folderType] || 'li';
