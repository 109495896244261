import React, { useEffect, useContext } from 'react';
import {
  shape,
  arrayOf,
  func,
  number,
  bool
} from 'prop-types';
import { ActionContext } from '../context';
import { ScNotificationListOutline } from './sc/scNotificationListOutline';
import { ScNotificationListContent } from './sc/scNotificationListContent';
import NotificationListHeader from './ListHeader';
import ListBody from './ListBody';

const List = ({
  headerProps,
  bodyProps
}) => {
  const logNotificationCenterActions = useContext(ActionContext);

  useEffect(() => {
    logNotificationCenterActions({
      action: 'seen',
      target: 'notification-list'
    });
  }, []);

  return (
    <ScNotificationListOutline>
      <ScNotificationListContent>
        <NotificationListHeader {...headerProps} />
        <ListBody {...bodyProps} />
      </ScNotificationListContent>
    </ScNotificationListOutline>
  );
};

List.propTypes = {
  headerProps: shape({
    notificationCount: number,
    onBackButtonClick: func,
    onDismissAll: func
  }),
  bodyProps: shape({
    notifications: arrayOf(shape()),
    animateOnDismissAll: bool,
    onConfirm: func,
    onDismiss: func
  })
};

List.defaultProps = {
  headerProps: {
    notificationCount: 0,
    onBackButtonClick: f => f
  },
  bodyProps: {
    notifications: [],
    animateOnDismissAll: false,
    onConfirm: f => f,
    onDismiss: f => f
  }
};

export default List;
