import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface ApiCallProps {
  path: string,
  options?: Partial<AxiosRequestConfig>,
  method?: AxiosRequestConfig['method'],
  url?: string,
  headers?: AxiosRequestConfig['headers'],
  callback?: (response: AxiosResponse<any>) => void,
  handleError?: (error: any) => void
}

export const apiCall = ({
  path,
  options = {},
  method = 'get',
  url = '/API',
  headers = {},
  callback = ({ data: { content } = {} }) => content,
  handleError = () => {}
}: ApiCallProps): Promise<any> => {
  return axios({
    method,
    url: `${url}${path}`,
    withCredentials: true,
    headers,
    ...options
  }).then(callback).catch(error => {
    // eslint-disable-next-line no-console
    handleError(error);
  });
};
