import { UserState } from '../typings/state';

/* eslint-disable max-len */
export const createInitial = (user: UserState) => {
  const reducers = {
    promptValue: '',
    form: {
      form_id: '',
      url: ''
    },
    messages: [],
    manual_changes: null,
    formProperties: {},
    user,
    fullstoryUrl: ''
  };
  return reducers;
};

export const CustomActionTypes = [
  {
    id: 'add-logo',
    text: 'Add a logo to your form'
  },
  {
    id: 'change-tone-of-voice',
    text: 'Change tone of voice'
  },
  {
    id: 'get-fonts',
    text: 'Change Font'
  }
];

export const AIActionTypes = {
  CREATE_AI_FORM: 'CREATE_AI_FORM',
  ADD_MESSAGE_TO_CHAT: 'ADD_MESSAGE_TO_CHAT',
  UNDO_LAST_ACTION: 'UNDO_LAST_ACTION',
  SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
  SET_FULLSTORY_URL: 'SET_FULLSTORY_URL'
} as const;

export const mockFormProps = {
  form_id: '240242090608044',
  url: 'https://form.jotform.com/240242090608044',
  messages: []
};

export const MessageTypes = {
  // It's only user's entered prompt. It's use for ai prompt history
  USER_MESSAGE: 'USER_MESSAGE',
  // Only user action, it's exist in ai message histories
  USER_ACTION: 'USER_ACTION',
  ASSISTANT: 'ASSISTANT',
  TOOL: 'TOOL',
  CUSTOM: 'CUSTOM',
  ERROR: 'ERROR',
  RETRYABLE_ERROR: 'RETRYABLE_ERROR',
  UNDO: 'UNDO',
  HELP: 'HELP',
  WELCOME: 'WELCOME'
};

export const ExampleBuildPrompts = [
  'I want to build a registration form for student events',
  'I want to build an order form for my cupcake store',
  'I want to build a feedback form for my customers',
  'I want to build a job application form for my company'
];

export const ExamplePromptBoxes = [
  {
    slug: 'Registration Form',
    prompt: 'Create a course registration form suitable for any school or institution.'
  },
  {
    slug: 'Job Application Form',
    prompt: 'Develop a basic job application form that serves as a one-page solution for collecting essential information from applicants. This form should encompass personal details, educational background, and reference information. You can use your imagination to generate more fields related to the topic.'
  },
  {
    slug: 'Feedback Form',
    prompt: 'Create a client feedback form to gather valuable insights from my clients.'
  },
  {
    slug: 'Appointment Form',
    prompt: 'Develop an appointment request form tailored for medical practices. This form should collect information needed to schedule health appointments, such as a patient\'s name, address, and contact details.'
  }
];

export const ExamplePrompts = [
  {
    slug: 'Course Registration Form',
    prompt: 'Create a course registration form suitable for any school or institution.'
  },
  {
    slug: 'Job Application Form',
    prompt: 'Develop a basic job application form that serves as a one-page solution for collecting essential information from applicants. This form should encompass personal details, educational background, and reference information. You can use your imagination to generate more fields related to the topic.'
  },
  {
    slug: 'Client Feedback Form',
    prompt: 'Create a client feedback form to gather valuable insights from my clients.'
  },
  {
    slug: 'Appointment Request Form',
    prompt: 'Develop an appointment request form tailored for medical practices. This form should collect information needed to schedule health appointments, such as a patient\'s name, address, and contact details.'
  },
  {
    slug: 'Math Quiz',
    prompt: 'Generate a math quiz form to conduct quizzes online. Customize the form to include various math questions, sections, or topics that align with high school curriculum.'
  },
  {
    slug: 'Summer Camp Consent Form',
    prompt: 'Create a summer camp permission or consent form for students. Add a signature field for parents\' consent.'
  },
  {
    slug: 'Beauty Clinic Feedback Form',
    prompt: 'I run a beauty clinic. Create a feedback form to collect my customers\' comments, suggestions, questions, and complaints.'
  },
  {
    slug: 'Hotel Booking Form',
    prompt: 'Generate a hotel booking form for my boutique hotel.'
  },
  {
    slug: 'Volunteer Application Form',
    prompt: 'My nonprofit organization needs a volunteer application form that includes a background check.'
  },
  {
    slug: 'Clinic Appointment Form',
    prompt: 'Create a comprehensive appointment form for my clinic.'
  },
  {
    slug: 'Video Contest Application Form',
    prompt: 'Generate a form to accept applications to my video contest. The form should have fields for application details and a section to allow applicants to upload up to 3 videos. Applicants should pass a CAPTCHA to submit.'
  },
  {
    slug: 'Tattoo Studio Contact Form',
    prompt: 'I need a contact form for my tattoo studio. My communication tone is friendly and sarcastic, so the form should be as well.'
  },
  {
    slug: 'Identity Verification Form',
    prompt: 'Develop an online identity verification form for my company to conduct thorough identity checks on new employees. The form should gather essential information to ensure the accuracy and legitimacy of the provided details.'
  },
  {
    slug: 'Mobile App Satisfaction Survey',
    prompt: 'Client satisfaction survey for my mobile app.'
  }
];

export const ASSISTANT_ROLES: string[] = [
  MessageTypes.ASSISTANT,
  MessageTypes.CUSTOM,
  MessageTypes.UNDO,
  MessageTypes.ERROR,
  MessageTypes.RETRYABLE_ERROR,
  MessageTypes.HELP,
  MessageTypes.WELCOME
];

export const EXCEPTIONAL_ASSISTANT_ROLES: string[] = [
  MessageTypes.UNDO,
  MessageTypes.ERROR,
  MessageTypes.RETRYABLE_ERROR
];

export const PROMT_PROPS = {
  MAX_LENGTH: 500
};
