/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { translationRenderer } from '@jotforminc/translation';
import { IconExclamationTriangleFilled } from '@jotforminc/svg-icons';
import { SELECTORS } from '../../store/selectors';

const HIPAAWarnings = () => {
  const { isPlanHIPAAEligible } = useSelector(SELECTORS.getUserCredentials);
  const upgradeLink = '/pricing?utm_source=hipaa-account-warning&utm_medium=banner&utm_content=myforms&utm_campaign=hipaa';
  const disableLink = '/myaccount/data?utm_source=hipaa-account-warning&utm_medium=banner&utm_content=myforms&utm_campaign=hipaa';

  const middleAlignment = () => (
    <div className="infobox-wrapper red" data-testid="hipaawarning-middle">
      <p className="infobox red">
        <div className="infobox-icon">
          <IconExclamationTriangleFilled />
        </div>
        <span>
          {translationRenderer('Your plan is not HIPAA-enabled! [1[Upgrade your plan]] or [2[remove HIPAA-related features]] to continue using your forms without interruption.')({
            renderer1: upgradeText => <a href={upgradeLink} target="_blank">{upgradeText}</a>,
            renderer2: removeText => <a href={disableLink} target="_blank">{removeText}</a>
          })}
        </span>
      </p>
    </div>
  );

  const leftAlignment = () => (
    <div className="lsApp-sidebar-warning" data-testid="hipaawarning-left">
      <div className="sidebar-hipaa-warning red">
        <div className="sidebar-hipaa-warning-icon">
          <IconExclamationTriangleFilled />
        </div>
        <span>
          {translationRenderer('Your plan is not HIPAA-enabled! [1[Upgrade your plan]] or [2[remove HIPAA-related features]] to continue using your forms without interruption.')({
            renderer1: upgradeText => <a href={upgradeLink} target="_blank">{upgradeText}</a>,
            renderer2: removeText => <a href={disableLink} target="_blank">{removeText}</a>
          })}
        </span>
      </div>
    </div>
  );

  if (isPlanHIPAAEligible === false) {
    if (window.limitWarningAlignment === 'middle_alt') {
      return middleAlignment();
    } if (window.limitWarningAlignment === 'left_alt') {
      return leftAlignment();
    }
  }

  return <></>;
};

export default HIPAAWarnings;
