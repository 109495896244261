import Styled from 'styled-components';

export const ScNotificationBadge = Styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #FF6100;
  padding: 0 8px;
  background: #FFF3E8;
  border-radius: 4px;
  margin-left: 8px;
  margin-right: auto;
`;
