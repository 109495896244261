/* eslint-disable max-len */
import React from 'react';
import './loading.scss';

const Loading: React.FC = () => {
  return (
    <div className='ai-loading'>
      <div className='flex justify-center relative'>
        <svg
          className='ai-star-animation'
          width="43" height="43" viewBox="0 0 43 43"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path className='ai-star-animation-main' d="M18.6288 1.89256C19.5626 -0.630854 23.1316 -0.630854 24.0654 1.89256L25.4398 5.60644C27.397 10.8957 31.5671 15.0658 36.8563 17.023L40.5703 18.3974C43.0934 19.3312 43.0934 22.9002 40.5703 23.834L36.8563 25.2084C31.5671 27.1655 27.397 31.3357 25.4398 36.6249L24.0654 40.3389C23.1316 42.862 19.5626 42.862 18.6288 40.3389L17.2544 36.6249C15.2972 31.3357 11.1271 27.1655 5.83784 25.2084L2.12383 23.834C-0.399258 22.9002 -0.399258 19.3312 2.12383 18.3974L5.83784 17.023C11.1271 15.0658 15.2972 10.8957 17.2544 5.60644L18.6288 1.89256Z" fill="#892DCA" />
          <circle
            className='ai-star-animation-circle circle-1'
            cx="27.8589" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation-circle circle-2'
            cx="14.9309" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation-circle circle-3'
            cx="21.3949" cy="27.1488" r="4.30933"
            transform="rotate(90 21.3949 27.1488)" fill="#892DCA"
          />
          <circle
            className='ai-star-animation-circle circle-4'
            cx="21.3949" cy="14.2208" r="4.30933"
            transform="rotate(90 21.3949 14.2208)" fill="#892DCA"
          />
        </svg>
        <svg
          className='ai-star-animation2'
          width="43" height="43" viewBox="0 0 43 43"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path className='ai-star-animation2-main' d="M18.6288 1.89256C19.5626 -0.630854 23.1316 -0.630854 24.0654 1.89256L25.4398 5.60644C27.397 10.8957 31.5671 15.0658 36.8563 17.023L40.5703 18.3974C43.0934 19.3312 43.0934 22.9002 40.5703 23.834L36.8563 25.2084C31.5671 27.1655 27.397 31.3357 25.4398 36.6249L24.0654 40.3389C23.1316 42.862 19.5626 42.862 18.6288 40.3389L17.2544 36.6249C15.2972 31.3357 11.1271 27.1655 5.83784 25.2084L2.12383 23.834C-0.399258 22.9002 -0.399258 19.3312 2.12383 18.3974L5.83784 17.023C11.1271 15.0658 15.2972 10.8957 17.2544 5.60644L18.6288 1.89256Z" fill="#892DCA" />
          <circle
            className='ai-star-animation2-circle circle-1'
            cx="27.8589" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation2-circle circle-2'
            cx="14.9309" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation2-circle circle-3'
            cx="21.3949" cy="27.1488" r="4.30933"
            transform="rotate(90 21.3949 27.1488)" fill="#892DCA"
          />
          <circle
            className='ai-star-animation2-circle circle-4'
            cx="21.3949" cy="14.2208" r="4.30933"
            transform="rotate(90 21.3949 14.2208)" fill="#892DCA"
          />
        </svg>
        <svg
          className='ai-star-animation3'
          width="43" height="43" viewBox="0 0 43 43"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path className='ai-star-animation3-main' d="M18.6288 1.89256C19.5626 -0.630854 23.1316 -0.630854 24.0654 1.89256L25.4398 5.60644C27.397 10.8957 31.5671 15.0658 36.8563 17.023L40.5703 18.3974C43.0934 19.3312 43.0934 22.9002 40.5703 23.834L36.8563 25.2084C31.5671 27.1655 27.397 31.3357 25.4398 36.6249L24.0654 40.3389C23.1316 42.862 19.5626 42.862 18.6288 40.3389L17.2544 36.6249C15.2972 31.3357 11.1271 27.1655 5.83784 25.2084L2.12383 23.834C-0.399258 22.9002 -0.399258 19.3312 2.12383 18.3974L5.83784 17.023C11.1271 15.0658 15.2972 10.8957 17.2544 5.60644L18.6288 1.89256Z" fill="#892DCA" />
          <circle
            className='ai-star-animation3-circle circle-1'
            cx="27.8589" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation3-circle circle-2'
            cx="14.9309" cy="20.6848" r="4.30933"
            fill="#892DCA"
          />
          <circle
            className='ai-star-animation3-circle circle-3'
            cx="21.3949" cy="27.1488" r="4.30933"
            transform="rotate(90 21.3949 27.1488)" fill="#892DCA"
          />
          <circle
            className='ai-star-animation3-circle circle-4'
            cx="21.3949" cy="14.2208" r="4.30933"
            transform="rotate(90 21.3949 14.2208)" fill="#892DCA"
          />
        </svg>
        <svg
          width="128" height="150" viewBox="0 0 188 225"
          fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 4">
            <g className='paper-group'>
              <path className='paper' d="M168.232 22H31.2315C29.0224 22 27.2315 23.7909 27.2315 26V187C27.2315 189.209 29.0224 191 31.2315 191H168.232C170.441 191 172.232 189.209 172.232 187V26C172.232 23.7909 170.441 22 168.232 22Z" fill="#F3F3FE" />
              <path className='ai-paper-line line-1' d="M135.232 131H65.2315C55.2904 131 47.2315 139.059 47.2315 149C47.2315 158.941 55.2904 167 65.2315 167H135.232C145.173 167 153.232 158.941 153.232 149C153.232 139.059 145.173 131 135.232 131Z" fill="#FF6100" />
              <path className='ai-paper-line line-2' d="M135.232 88H65.2315C55.2904 88 47.2315 96.0589 47.2315 106C47.2315 115.941 55.2904 124 65.2315 124H135.232C145.173 124 153.232 115.941 153.232 106C153.232 96.0589 145.173 88 135.232 88Z" fill="#0099FF" />
              <path className='ai-paper-line line-3' d="M135.232 45H65.2315C55.2904 45 47.2315 53.0589 47.2315 63C47.2315 72.9411 55.2904 81 65.2315 81H135.232C145.173 81 153.232 72.9411 153.232 63C153.232 53.0589 145.173 45 135.232 45Z" fill="#FFB629" />
            </g>
          </g>
        </svg>
      </div>
      {/* <svg
        width="296" height="169" viewBox="0 -20 296 199"
        fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <g className='paper-group'>
          <rect
            className='paper'
            x="84" width="145" height="169"
            rx="4" fill="#F3F3FE"
          />
          <rect
            className='ai-line-1 opacity-0'
            x="99" y="23" width="82"
            height="13" rx="4" fill="#E3E5F5"
          />
          <rect
            className='ai-line-2 opacity-0'
            x="99" y="44" width="114"
            height="13" rx="4" fill="#E3E5F5"
          />
          <rect
            className='ai-line-4 opacity-0'
            x="99" y="94" width="52"
            height="13" rx="4" fill="#E3E5F5"
          />
          <rect
            className='ai-line-3 opacity-0'
            x="99" y="69" width="114"
            height="13" rx="4" fill="#E3E5F5"
          />
          <rect
            className='ai-line-4 opacity-0'
            x="99" y="119" width="114"
            height="13" rx="4" fill="#E3E5F5"
          />
          <rect
            className='red-ellipse opacity-0'
            y="95" width="106" height="36"
            rx="18" fill="#FF6100"
          />
          <rect
            className='blue-ellipse opacity-0'
            x="190" y="59" width="106"
            height="36" rx="18" fill="#0099FF"
          />
          <rect
            className='yellow-ellipse opacity-0'
            y="23" width="106" height="36"
            rx="18" fill="#FFB629"
          />
          <g className='ai-logo-animation'>
            <path
              className='ai-logo-star-3'
              d="M130.331 54.2888C132.291 55.014 132.291 57.7861 130.331 58.5113L127.446 59.5787C123.338 61.0988 120.099 64.3376 118.579 68.4455L117.512 71.3302C116.787 73.2901 114.015 73.2901 113.289 71.3302L112.222 68.4456C110.702 64.3376 107.463 61.0988 103.355 59.5787L100.471 58.5113C98.5107 57.7861 98.5107 55.014 100.471 54.2888L103.355 53.2214C107.463 51.7013 110.702 48.4625 112.222 44.3545L113.289 41.4699C114.015 39.51 116.787 39.51 117.512 41.4699L118.579 44.3545C120.099 48.4625 123.338 51.7013 127.446 53.2214L130.331 54.2888Z"
              fill="#9C4DD3"
            />
            <path
              className='ai-logo-star-2'
              d="M167.238 101.406C168.254 101.782 168.254 103.218 167.238 103.594L165.743 104.147C163.614 104.935 161.935 106.614 161.147 108.743L160.594 110.238C160.218 111.254 158.782 111.254 158.406 110.238L157.853 108.743C157.065 106.614 155.386 104.935 153.257 104.147L151.762 103.594C150.746 103.218 150.746 101.782 151.762 101.406L153.257 100.853C155.386 100.065 157.065 98.386 157.853 96.2569L158.406 94.7619C158.782 93.746 160.218 93.746 160.594 94.7619L161.147 96.2569C161.935 98.386 163.614 100.065 165.743 100.853L167.238 101.406Z"
              fill="#9C4DD3"
            />
            <path
              className='ai-logo-star-1'
              d="M197.198 15.2548C197.817 13.5817 200.183 13.5817 200.802 15.2548L201.713 17.7173C203.011 21.224 205.776 23.9889 209.283 25.2865L211.745 26.1977C213.418 26.8168 213.418 29.1832 211.745 29.8023L209.283 30.7135C205.776 32.0111 203.011 34.776 201.713 38.2827L200.802 40.7452C200.183 42.4183 197.817 42.4183 197.198 40.7452L196.287 38.2827C194.989 34.776 192.224 32.0111 188.717 30.7135L186.255 29.8023C184.582 29.1832 184.582 26.8168 186.255 26.1977L188.717 25.2865C192.224 23.9889 194.989 21.224 196.287 17.7173L197.198 15.2548Z"
              fill="#9C4DD3"
            />
          </g>
        </g>
        <g className='ai-animation-cursor' filter="url(#filter0_dd_3923_14853)">
          <path
            fill-rule="evenodd" clip-rule="evenodd"
            d="M40.2929 50.2929C40.5608 50.0251 40.9569 49.9316 41.3163 50.0513L56.3163 55.0513C56.6968 55.1782 56.9647 55.5201 56.9968 55.92C57.0289 56.3198 56.819 56.7001 56.4636 56.8861L51.1044 59.6901L55.7072 64.2929C56.0977 64.6835 56.0977 65.3166 55.7072 65.7071C55.3167 66.0977 54.6835 66.0977 54.293 65.7071L49.6902 61.1043L46.8861 66.4636C46.7001 66.819 46.3198 67.0289 45.92 66.9968C45.5201 66.9647 45.1782 66.6968 45.0513 66.3163L40.0513 51.3163C39.9316 50.9569 40.0251 50.5608 40.2929 50.2929Z"
            fill="black"
          />
          <path
            d="M41.6325 49.1027C40.9138 48.8631 40.1215 49.0501 39.5858 49.5858C39.0501 50.1215 38.8631 50.9138 39.1027 51.6325L44.1027 66.6325C44.3563 67.3935 45.0402 67.9294 45.8399 67.9936C46.6395 68.0578 47.4002 67.638 47.7721 66.9272L49.9454 62.7737L53.5859 66.4142C54.3669 67.1953 55.6333 67.1953 56.4143 66.4143C57.1954 65.6332 57.1954 64.3669 56.4143 63.5858L52.7738 59.9453L56.9272 57.7721C57.638 57.4002 58.0578 56.6396 57.9936 55.8399C57.9294 55.0402 57.3935 54.3563 56.6325 54.1027L41.6325 49.1027Z"
            stroke="white" stroke-width="2" stroke-linecap="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_3923_14853" x="30" y="44"
            width="37"
            height="37.0001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.145098 0 0 0 0 0.176471 0 0 0 0 0.356863 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3923_14853" />
            <feColorMatrix
              in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="4" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.329412 0 0 0 0 0.372549 0 0 0 0 0.435294 0 0 0 0.16 0" />
            <feBlend mode="normal" in2="effect1_dropShadow_3923_14853" result="effect2_dropShadow_3923_14853" />
            <feBlend
              mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3923_14853"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
 */}
    </div>
  );
};

export default Loading;
