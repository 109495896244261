/* eslint-disable max-len */
import Styled from 'styled-components';
import { breakpoints } from '../../constants';

export const ScNotificationListOutline = Styled.div`
  box-shadow: 0 0 12px rgba(43, 50, 69, 0.4);
  color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
  font-family: "Circular", -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif;
  opacity: 0;
  box-shadow: 0px 8px 16px rgba(84, 95, 111, 0.16), 0px 2px 4px rgba(37, 45, 91, 0.04);
  width: calc(100% - 16px);
  min-width: calc(100vw - 16px);
  margin: 0 8px;
  background: #FFFFFF;
  border: 1px solid #C8CEED;
  animation: fadeInNotification .25s forwards;
  animation-delay: .1s;

  @keyframes fadeInNotification {
    from {
      opacity: 0;
      margin-top: 15px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  @media screen and (min-width:${breakpoints.mobileNew}){
    width: 400px;
    min-width: initial;
    margin: 0;
    transform: translateY(4px);
    overflow: visible;
  }

  &::before{
    content: "";
    display: block;
    position: absolute;
    height: 24px;
    top: -8px;
    right: 57px;
    z-index: -1;
    width: 28px;
    height: 18px;
    background-size: 28px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='20' viewBox='0 0 28 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2531 1.76777C13.8388 1.18198 14.7886 1.18198 15.3744 1.76777L23.6066 9.99999L14.3137 19.2929L5.02084 9.99999L13.2531 1.76777Z' fill='white' stroke='%23C8CEED'/%3E%3Cpath d='M0 13C0 11.8954 0.895431 11 2 11H5L14.5 2L24 11H28V20H0L0 13Z' fill='white'/%3E%3C/svg%3E%0A");
    animation: fadeInBeforeNotification .25s forwards;
    animation-delay: .1s;

    @media screen and (min-width:${breakpoints.mobileNew}) {
      right: 20px;
    }
  }

  @keyframes fadeInBeforeNotification {
    from {
      top: 7px;
      opacity: 0;
    }
    to {
      top: -8px;
      opacity: 1;
    }
  }
`;
