import { AxiosRequestConfig } from 'axios';
import { apiCall } from '../../utils/api';

export const getLogs = (params: AxiosRequestConfig['params'], teamID?: string): Promise<any> => {
  if (teamID) {
    return apiCall({ path: `/team/${teamID}/history`, options: { params }, headers: { 'jf-team-id': teamID } });
  }
  return apiCall({ path: '/enterprise/history', options: { params } });
};

export const checkExportStatus = (id: string, teamID?: string): Promise<any> => {
  if (teamID) {
    return apiCall({ path: `/team/${teamID}/history/export/status?id=${id}`, headers: { 'jf-team-id': teamID } });
  }
  return apiCall({ path: `/enterprise/logs/export/status?id=${id}` });
};

export const exportLogs = (params: AxiosRequestConfig['params'], teamID?: string): Promise<any> => {
  if (teamID) {
    return apiCall({ path: `/team/${teamID}/history/export`, options: { params }, headers: { 'jf-team-id': teamID } });
  }
  return apiCall({ path: '/enterprise/logs/export', options: { params } });
};
