import React, { forwardRef } from 'react';
import { string } from 'prop-types';

type Props = {
  color?: string
};

const ColorRenderer = forwardRef<HTMLDivElement, Props>(({ color, ...rest }, ref) => (
  <div
    className='jfColors-picker'
    style={{ background: color }}
    ref={ref}
    {...rest}
  />
));

ColorRenderer.propTypes = {
  color: string
};

ColorRenderer.defaultProps = {
  color: '#FFFFFF'
};

export default ColorRenderer;
