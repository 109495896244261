import React from 'react';
import {
  array, bool, func, string
} from 'prop-types';
import { Button } from '@jotforminc/magnet';
import { AssetIconRenderer } from '@jotforminc/enterprise-components';
import { t } from '@jotforminc/translation';
import { IconAngleDownCircleFilled, IconCaretsSwitchHorizontal, IconUserFilled } from '@jotforminc/svg-icons';
import './move.scss';
import { goToAssetSource, mapAssetTypes } from '../../utils';
import {
  OWNERSHIP_TRANSFER_TEXT
  // ,REQUEST_TRANSFER_OWNERSHIP, TRANSFER_OWNERSHIP, UNDO // TEMPORARILY DISABLED UNTILL THE TRANSFER IMPLEMENTATION IS COMPLETE
} from './constants';

const AssetList = ({
  assets = [], handleSelect = f => f, checked = false, assetName = '', shouldTransfer = false, isExpanded = false, handleExpand = f => f, isSelected = false
  // , handleTransfer, isMoveable = false, isAdmin = false // TEMPORARILY DISABLED UNTILL THE TRANSFER IMPLEMENTATION IS COMPLETE
}) => {
  const {
    resource: { title: firstItemTitle, type: firstItemResourceType }, type: firstItemType, initiatorAssetKey: firstItemInitiatorAssetKey, id: firstItemID
  } = assets[0];
  const restOfList = Object.groupBy(assets.filter((asset, index) => index !== 0), ({ id }) => id);
  // const transferButtonText = isAdmin ? t(TRANSFER_OWNERSHIP) : t(REQUEST_TRANSFER_OWNERSHIP); // TEMPORARILY DISABLED UNTILL THE TRANSFER IMPLEMENTATION IS COMPLETE
  const warningCount = assets.filter(({ ownerConflict = false }) => ownerConflict).length;

  const [conflictedAssetGroup, nonConflictingAssetGroup] = Object.values(restOfList)
    .reduce(([conflicted, nonConflicted], list) => {
      list.forEach(asset => {
        const { ownerConflict = false, isHidden, from } = asset;
        if (!isHidden) {
          if (ownerConflict && from !== 'listing') {
            conflicted.push(asset);
          } else {
            nonConflicted.push(asset);
          }
        }
      });
      return [conflicted, nonConflicted];
    }, [[], []]);

  const sortedList = [
    ...conflictedAssetGroup.sort(({ id: idA }, { id: idB }) => ((idA > idB) ? 1 : -1)),
    ...nonConflictingAssetGroup
  ];

  // const renderIcons = ownerConflict => {
  //   if (transferringUsername !== username && isSelected) {
  //     return (

  //     );
  //   }
  //   return null;
  // };
  return (
    <>
      <div className='px-8 general-wrapper mt-2'>
        <div className={`block justify-between register-wrapper  bg-navy-25 cursor-pointer border border-navy-50 divide-solid justify-between ${isExpanded ? '' : 'borderNotExpanded'}`}>
          <div className='flex items-center gap-1 moveToHeader justify-between'>
            <div className='responsive-col flex items-center gap-1'>
              <div className='flex items-center gap-1 customer-wrapper'>
                <div className='input-label-wrapper'>
                  <input
                    className=''
                    type='checkbox'
                    checked={checked}
                    id={firstItemID}
                    onClick={e => handleSelect(e, assetName)}
                  />
                  <label />
                </div>
                <div className='flex items-center gap-1' onClick={() => handleExpand(firstItemInitiatorAssetKey)}>
                  <div className='w-6 h-6 formTitle'>
                    <AssetIconRenderer type={firstItemType} isNavy={false} resourceType={firstItemResourceType} />
                  </div>
                  <div className='flex items-center gap-1 clickable-area'>
                    <span className={`${warningCount > 0 ? ' color-navy-700 max-w-40 font-medium ownershipIssue whitespace-nowrap overflow-hidden inline-block'
                      : ' color-navy-700 font-medium formText whitespace-nowrap overflow-hidden max-w-120 inline-block'}`}
                    >
                      {firstItemTitle}
                    </span>
                    {sortedList.length > 0 && <IconAngleDownCircleFilled className={`w-4 h-4 cursor-pointer duration-300expandArrow ${isExpanded ? 'rotate-180 transform' : 'duration-300'}`} />}
                  </div>
                </div>
              </div>
              <div />
              <div className='responsive-margin-2' onClick={() => handleExpand(firstItemInitiatorAssetKey)}>
                {warningCount > 0 && isSelected && !shouldTransfer && (
                <div className='transferButton bg-yellow-600 color-white p-1 radius text-xs ownership-text whitespace-nowrap'>
                  {`${warningCount} Ownership issues`}
                </div>
                )}
              </div>
            </div>
            {/* TEMPORARILY DISABLED UNTILL THE TRANSFER IMPLEMENTATION IS COMPLETE
            <div className='flex items-center justify-between color-navy-700 font-medium min-h-14 px-2 py-2 radius color-blue-500 bg-transparent ownership-wrapper'>
              {isMoveable && isSelected && (
                <Button className='undoRequestButtons outline-0 border-0 bg-transparent p-0 radius' variant='ghost' onClick={handleTransfer}>
                  {shouldTransfer ? t(UNDO) : (transferButtonText)}
                </Button>
              )}
            </div> */}
          </div>
        </div>

      </div>
      {isExpanded && (
      <div className={`moveToElementsWrapper radius pt-0 ${isExpanded ? '' : ''}`}>
        {sortedList.map(({
          type, ownerConflict, resource: {
            title, name, owner, username, type: resourceType, id: resourceID
          }
        }) => {
          const formOwner = owner || username;
          return (
            <>
              <div className={`moveToElement cursor-pointer hover:bg-navy-10  flex items-center border border-navy-50 divide-solid gap-1  justify-between${isExpanded ? '' : ''}`}>
                <div className='flex items-center gap-1'>
                  <div className='flex items-center gap-1'>
                    <div className='w-6 h-6'>
                      <AssetIconRenderer
                        type={type} isNavy={false} resourceType={resourceType || ''}
                        isMono={true}
                      />
                    </div>
                    <div
                      className={`color-navy-700 text-sm whitespace-nowrap overflow-hidden max-w-120 inline-block 
                      ${ownerConflict ? 'formOwnerIssueText whitespace-nowrap overflow-hidden inline-block max-w-96' : 'formElementsText'}`}
                    >
                      {title || name}
                    </div>
                  </div>
                  {ownerConflict && isSelected && (
                  <div>
                    <div className={`flex items-center gap-1 color-black text-xs radius responsive-margin1 whitespace-nowrap ${shouldTransfer ? 'bg-green-100' : 'bg-navy-75'} p-1`}>
                      {shouldTransfer ? <IconCaretsSwitchHorizontal className='w-3 h-4' /> : <IconUserFilled className='w-3 h-4' />}
                      {shouldTransfer ? t(OWNERSHIP_TRANSFER_TEXT) : formOwner}
                    </div>
                  </div>
                  )}
                </div>
                {type !== 'sign' && (
                <Button className='viewAssetButtons outline-0 border-0 bg-transparent p-0 radius' variant='ghost' onClick={() => goToAssetSource(type, resourceID)}>
                  View
                  {' '}
                  {t(mapAssetTypes(type))}
                </Button>
                )}
              </div>
            </>
          );
        })}
      </div>
      )}
    </>
  );
};

AssetList.propTypes = {
  assets: array,
  handleSelect: func,
  checked: bool,
  assetName: string,
  shouldTransfer: bool,
  isExpanded: bool,
  handleExpand: func,
  isSelected: bool
  // ,isAdmin: bool,
  // handleTransfer: func.isRequired,
  // isMoveable: bool
};

export default AssetList;
