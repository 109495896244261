const getCurrentDeal = (nowDate, period, limits, floors) => {
  const currentDif = nowDate - period;
  const minuteDiff = Math.floor(currentDif / 60000);
  const dealDiff = Math.floor(minuteDiff / floors);
  return limits - dealDiff;
};

export const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const getCurrentThings = (period, limits, floors) => {
  const nowDate = Date.now();
  const isAnimationActive = !document.cookie.match('bf-campaign-count-animation');
  if ((nowDate >= period[0]) && (nowDate <= period[1])) {
    const currentDeal = getCurrentDeal(nowDate, period[0], limits[0], floors[0]);
    const result = isAnimationActive ? currentDeal + 1 : currentDeal;
    return result % 10 === 0 ? result + 1 : result;
  }
  if ((nowDate >= period[1]) && (nowDate <= period[2])) {
    const currentDeal = getCurrentDeal(nowDate, period[1], limits[1], floors[1]);
    const result = isAnimationActive ? currentDeal + 1 : currentDeal;
    return result % 10 === 0 ? result + 1 : result;
  }
  if ((nowDate >= period[2]) && (nowDate <= period[3])) {
    const currentDeal = getCurrentDeal(nowDate, period[2], limits[2], floors[2]);
    const result = isAnimationActive ? currentDeal + 1 : currentDeal;
    return result % 10 === 0 ? result + 1 : result;
  }
  if ((nowDate >= period[3]) && (nowDate <= period[4])) {
    const currentDeal = getCurrentDeal(nowDate, period[3], limits[3], floors[3]);
    const result = isAnimationActive ? currentDeal + 1 : currentDeal;
    return result % 10 === 0 ? result + 1 : result;
  }
};

const getTime = date => {
  return new Date(date).getTime();
};

export const counterFirstDay = new Date('Jan 11, 2024 09:00:00 EST').getTime();
export const counterSecondDay = new Date('Jan 12, 2024 00:00:00 EST').getTime();
export const counterEndDate = new Date('Jan 12, 2024 21:00:00 EST').getTime();

export const firstDayHours = () => {
  const limits = [379, 357, 302, 264, 242];
  const floors = [5, 4, 9, 8];
  const hours = [
    'Jan 11, 2024 09:00:00 EST',
    'Jan 11, 2024 11:00:00 EST',
    'Jan 11, 2024 15:00:00 EST',
    'Jan 11, 2024 21:00:00 EST',
    'Jan 11, 2024 23:59:59 EST'
  ];
  const msArray = hours.map(hour => getTime(hour));
  return getCurrentThings(msArray, limits, floors);
};

export const secondDayHours = () => {
  const limits = [242, 210, 188, 133];
  const floors = [17, 5, 4, 9];
  const hours = [
    'Jan 12, 2024 00:00:00 EST',
    'Jan 12, 2024 09:00:00 EST',
    'Jan 12, 2024 11:00:00 EST',
    'Jan 12, 2024 15:00:00 EST',
    'Jan 12, 2024 21:00:00 EST'
  ];
  const msArray = hours.map(hour => getTime(hour));
  return getCurrentThings(msArray, limits, floors);
};

const currencySymbols = {
  TRY: '₺',
  USD: '$',
  EUR: '€',
  GBP: '£',
  BRL: 'R$',
  AUD: 'A$',
  CAD: 'CA$'
};

export const getCurrencySymbol = currencyCode => {
  return currencySymbols[currencyCode] || currencyCode;
};
