import { updateUserSettings } from '../utils';

const onConfirm = ({ actionTargetName, date }) => {
  global.open('https://www.jotform.com/newsletters/a-custom-branded-app-for-your-team/?utm_source=2022-march-world-blog-regular&utm_campaign=2022-march-newsletter', '_blank');
  updateUserSettings('notificationNewsletterMarch2022', '2');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: `confirm-${date}`,
      target: actionTargetName
    });
  }
};

const onDismiss = ({ actionTargetName, date }) => {
  updateUserSettings('notificationNewsletterMarch2022', '-1');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: `dismiss-${date}`,
      target: actionTargetName
    });
  }
};

// newsletter
const checkNewsletter = notifications => {
  let notification = notifications.find(notif => notif.name === 'newsletter');
  const otherNotifications = notifications.filter(notif => notif.name !== 'newsletter');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkNewsletter;
