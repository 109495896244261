import { updateUserSettings } from '../utils';
import { hipaaFirstStep, notificationConfig } from '../constants';

// NOTE:

// STEP 1:
// confirm with text 'yes' -> hipaaComplienceAccountBoxOffer='0'
// dismiss with text 'no' -> hipaaComplienceAccountBoxOffer='-1'

// STEP 2:
// confirm with text 'Enable HIPAA Compliance'
// dismiss with text 'Enable Later', if step1 is 'yes' -> hipaaComplienceAccountBoxOffer='2'
// dismiss with text 'Dismiss', if step1 is 'no' -> hipaaComplienceAccountBoxOffer='1'

const { actionTargetName } = notificationConfig.hipaaCompliance;

// first step callbacks
const onFirstStepConfirm = () => {
  updateUserSettings('hipaaComplienceAccountBoxOffer', '0');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm-step-1',
      target: actionTargetName
    });
  }
};

const onFirstStepDismiss = () => {
  updateUserSettings('hipaaComplienceAccountBoxOffer', '-1');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss-step-1',
      target: actionTargetName
    });
  }
};

// second step callbacks
const onSecondStepConfirm = () => {
  global.location.href = '/myaccount/data?hipaa=1';

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm-step-2',
      target: actionTargetName
    });
  }
};

const onSecondStepDismiss = hipaaFirstStepAnswer => {
  if (hipaaFirstStepAnswer === hipaaFirstStep.yes) {
    updateUserSettings('hipaaComplienceAccountBoxOffer', '2');
  }

  if (hipaaFirstStepAnswer === hipaaFirstStep.no) {
    updateUserSettings('hipaaComplienceAccountBoxOffer', '1');
  }

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss-step-2',
      target: actionTargetName
    });
  }
};

const checkHipaaCompliance = notifications => {
  let notification = notifications.find(notif => notif.name === 'hipaaCompliance');
  const otherNotifications = notifications.filter(notif => notif.name !== 'hipaaCompliance');

  if (!notification) return notifications;

  notification = {
    ...notification,
    firstStep: {
      ...notification.firstStep,
      confirm: {
        ...notification.firstStep.confirm,
        callback: onFirstStepConfirm
      },
      dismiss: {
        ...notification.firstStep.dismiss,
        callback: onFirstStepDismiss
      }
    },
    secondStep: {
      ...notification.secondStep,
      confirm: {
        ...notification.secondStep.confirm,
        callback: onSecondStepConfirm
      },
      dismiss: {
        ...notification.secondStep.dismiss,
        callback: onSecondStepDismiss
      }
    }
  };

  return [...otherNotifications, notification];
};

export default checkHipaaCompliance;
