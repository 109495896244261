import isEqual from 'lodash/isEqual';
import { ACTION_TYPES } from '../actionTypes';
import {
  getPageFromUrl, isMixTestEnabled, getDefaultAssetFilter, findItemByIDAndType, isAssetFoldersSupported
} from '../../utils';
import {
  LISTING_TYPES, ROUTER_MAP, FOLDER_TYPES, MIX_FILTER_ENABLED_LISTING_TYPES
} from '../../constants';

const page = getPageFromUrl();
const currentPage = ROUTER_MAP[page] || LISTING_TYPES.ASSIGNED_FORM;
const defaultAssetFilter = getDefaultAssetFilter({ currentPage });

const initialState = {
  filter: {},
  isFilterSet: false,
  filterID: '',
  orderBy: '',
  teamFilter: '',
  selectedItems: {},
  lastSelectedListingItemId: '',
  showMultipleSelectWithShiftToast: true,
  isAllSelected: false,
  isMixStructureActive: !!isMixTestEnabled() || currentPage === LISTING_TYPES.MIX,
  currentPage: currentPage, // To check URLs to use specific UI elements (For example: App Picker). Until only the mix page remains. When the other listings are removed, this will be removed.
  assetFilter: defaultAssetFilter, // Its value will be changed by filter dropdown on the mix page & team page. My forms and others won't update this value.
  environment: window.JOTFORM_ENV,
  isMobileViewFoldersVisible: false,
  isMobileApp: false,
  isMobileDevice: false,
  isInboxPanelOpen: false,
  isTeamActivityPanelOpen: false,
  contactDetail: false,
  isAddToFolderMenuVisible: false,
  isMoveToTeamMenuVisible: false,
  isAllFoldersVisible: false,
  expandedFolders: [],
  disableAssetFilterDropdown: false,
  lastAssetFilter: defaultAssetFilter,
  isDefaultAssetFilterUpdated: false
};

// eslint-disable-next-line complexity
export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SELECT_ITEM: {
      const { selectedItems } = state;
      const { id, isSelected, assetType = '' } = action;
      const sanitizedIsSelected = isSelected === undefined ? !selectedItems[`${id}_${assetType}`] : isSelected;
      return {
        ...state,
        isAllSelected: false,
        selectedItems: {
          ...selectedItems,
          [`${id}_${assetType}`]: sanitizedIsSelected
        }
      };
    }
    case ACTION_TYPES.SET_LAST_SELECTED_LISTING_ITEM: {
      const { id } = action;
      return {
        ...state,
        lastSelectedListingItemId: id
      };
    }
    case ACTION_TYPES.DISABLE_MULTIPLE_SELECT_SHIFT_TOAST: {
      return {
        ...state,
        showMultipleSelectWithShiftToast: false
      };
    }
    case ACTION_TYPES.DELETE_ITEM.SUCCESS: {
      const { id, assetType = '' } = action;
      const { selectedItems } = state;
      return { ...state, selectedItems: { ...selectedItems, [`${id}_${assetType}`]: false } };
    }
    case ACTION_TYPES.SET_FILTER:
      return { ...state, filter: action.filter, isFilterSet: true };
    case ACTION_TYPES.SET_FULLTEXT: {
      const { filter } = state;
      const { fullText } = action;
      return { ...state, filter: { ...filter, fullText } };
    }
    case ACTION_TYPES.SELECT_ALL: {
      const { isSelected } = action;
      return { ...state, isAllSelected: isSelected, selectedItems: {} };
    }
    case ACTION_TYPES.SELECT_SINGLE_ITEM: {
      const { id, deselectable, assetType = '' } = action;
      return {
        ...state,
        isAllSelected: false,
        selectedItems: { [`${id}_${assetType}`]: deselectable ? !state.selectedItems[`${id}_${assetType}`] : true }
      };
    }
    case ACTION_TYPES.SET_ORDERBY:
      return { ...state, orderBy: action.orderBy };
    case ACTION_TYPES.SET_TEAM_FILTER:
      const { teamFilter } = action;
      return { ...state, teamFilter };
    case ACTION_TYPES.SET_MOBILE_VIEW_FOLDERS_VISIBLITY:
      return { ...state, isMobileViewFoldersVisible: action.value };
    case ACTION_TYPES.SET_SELECTED_FOLDER:
      const {
        currentPage: currPage, assetFilter, isMixStructureActive, lastAssetFilter, disableAssetFilterDropdown: currIsDisabled
      } = state;
      const { currentTeamID, folderType, userCredentials } = action;
      const newState = {
        ...state,
        isMobileViewFoldersVisible: false,
        selectedItems: {},
        teamFilter: currentTeamID
      };
      const isAssetFilterEnabledPage = isMixStructureActive ? MIX_FILTER_ENABLED_LISTING_TYPES.indexOf(currPage) > -1 : currentPage === LISTING_TYPES.TEAM_PAGE;
      if (!isAssetFilterEnabledPage) {
        return newState;
      }

      const isAssetFolderSelected = [FOLDER_TYPES.ASSET_FOLDER, FOLDER_TYPES.TEAM_FOLDER].indexOf(folderType) > -1;
      const isAssetFoldersActive = isAssetFoldersSupported(userCredentials);
      const lastSelectedAssetFilter = currIsDisabled ? lastAssetFilter : assetFilter;

      return {
        ...newState,
        assetFilter: isAssetFolderSelected && !isAssetFoldersActive ? [LISTING_TYPES.FORM] : lastSelectedAssetFilter,
        disableAssetFilterDropdown: isAssetFolderSelected && !isAssetFoldersActive,
        lastAssetFilter: (!currIsDisabled && isAssetFolderSelected) ? assetFilter : lastAssetFilter
      };
    case ACTION_TYPES.SET_LIST:
    case ACTION_TYPES.FETCH_LIST.SUCCESS: {
      const { selectedItems } = state;
      const { list, offset = 0 } = action;
      if (offset !== 0) {
        return state;
      }

      const newSelectedItems = Object.keys(selectedItems).reduce((prev, itemKey) => {
        const itemInfo = itemKey.split('_');
        const assetID = itemInfo?.length > 0 ? itemInfo[0] : '';
        const assetType = itemInfo?.length > 1 ? itemInfo[1] : '';
        const isItemFounded = findItemByIDAndType({ list, assetID, assetType });
        return ({
          ...prev,
          [itemKey]: !isItemFounded ? false : selectedItems[itemKey]
        });
      }, {});
      return { ...state, selectedItems: newSelectedItems };
    }
    case ACTION_TYPES.BULK_DELETE.SUCCESS:
    case ACTION_TYPES.ON_DELETE_MULTIPLE_PORTALS.SUCCESS:
    case ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.SUCCESS: {
      const { selectedItems } = state;
      const { deletedItemList = [] } = action;

      const newSelectedItems = Object.keys(selectedItems).reduce((prev, itemKey) => {
        const itemInfo = itemKey.split('_');
        const assetID = itemInfo?.length > 0 ? itemInfo[0] : '';
        const assetType = itemInfo?.length > 1 ? itemInfo[1] : '';
        const isDeleted = findItemByIDAndType({ list: deletedItemList, assetID, assetType });
        return ({
          ...prev,
          [itemKey]: isDeleted ? false : selectedItems[itemKey]
        });
      }, {});
      return { ...state, selectedItems: newSelectedItems };
    }
    case ACTION_TYPES.CHANGE_INBOX_PANEL: {
      const { formID } = action;
      return { ...state, lastOpenedInboxPanelId: formID };
    }
    case ACTION_TYPES.SET_FILTER_ID: {
      const { filterID } = action;
      return { ...state, filterID };
    }
    case ACTION_TYPES.SET_FILTER_FORM: {
      return { ...state, filterForm: action.filterForm };
    }
    case ACTION_TYPES.TOGGLE_INBOX_PANEL: {
      const { status } = action;
      return { ...state, isInboxPanelOpen: status };
    }
    case ACTION_TYPES.SET_CONTACT_DETAIL: {
      const { id } = action;
      return { ...state, contactDetail: id };
    }
    case ACTION_TYPES.TOGGLE_TEAM_ACTIVITY_PANEL: {
      const { status } = action;
      return { ...state, isTeamActivityPanelOpen: status };
    }
    case ACTION_TYPES.MOVE_TO_TEAM.SUCCESS:
    case ACTION_TYPES.MOVE_FROM_TEAM.SUCCESS: {
      const { items } = action;
      const { selectedItems } = state;
      const newSelectedItems = Object.keys(selectedItems).reduce((prev, itemKey) => {
        const itemInfo = itemKey.split('_');
        const assetID = itemInfo?.length > 0 ? itemInfo[0] : '';
        const assetType = itemInfo?.length > 1 ? itemInfo[1] : '';
        const isMoved = findItemByIDAndType({ list: items, assetID, assetType });
        return ({
          ...prev,
          [itemKey]: isMoved ? false : selectedItems[itemKey]
        });
      }, {});
      return { ...state, selectedItems: newSelectedItems };
    }
    case ACTION_TYPES.TOGGLE_ADD_TO_FOLDER_MENU_VISIBILITY: {
      const { isVisible } = action;
      return { ...state, isAddToFolderMenuVisible: isVisible };
    }
    case ACTION_TYPES.TOGGLE_MOVE_TO_TEAM_MENU_VISIBILITY: {
      const { isVisible } = action;
      return { ...state, isMoveToTeamMenuVisible: isVisible };
    }
    case ACTION_TYPES.UPDATE_ALL_FOLDERS_VISIBILITY: {
      const { show } = action;
      return { ...state, isAllFoldersVisible: show };
    }
    case ACTION_TYPES.ADD_EXPANDED_FOLDERS: {
      const { newExpandedFolder } = action;
      const { expandedFolders } = state;
      if (expandedFolders.find(folder => folder.id === newExpandedFolder.id && folder.folderType === newExpandedFolder.folderType)) {
        return state;
      }
      return { ...state, expandedFolders: [...expandedFolders, newExpandedFolder] };
    }
    case ACTION_TYPES.REMOVE_EXPANDED_FOLDERS: {
      const { collapsedFolder } = action;
      const { expandedFolders } = state;
      return { ...state, expandedFolders: expandedFolders.filter(folder => !(folder.id === collapsedFolder.id && folder.folderType === collapsedFolder.folderType)) };
    }
    case ACTION_TYPES.SET_MOBILE_DEVICE: {
      const { isMobile } = action;
      return { ...state, isMobileDevice: isMobile };
    }
    case ACTION_TYPES.SET_CURRENT_PAGE: {
      const { newCurrentPage } = action;
      return { ...state, currentPage: newCurrentPage };
    }
    case ACTION_TYPES.SET_ASSET_FILTER: {
      const { newAssetFilter } = action;
      return { ...state, assetFilter: newAssetFilter };
    }
    case ACTION_TYPES.FETCH_USER.SUCCESS: {
      const { user } = action;
      const { isDefaultAssetFilterUpdated } = state;

      const filteredDefaultAssetFilter = getDefaultAssetFilter({ currentPage, userCredentials: user?.credentials });
      if (isEqual(filteredDefaultAssetFilter, defaultAssetFilter) || isDefaultAssetFilterUpdated) return { ...state };

      return {
        ...state,
        assetFilter: filteredDefaultAssetFilter,
        lastAssetFilter: filteredDefaultAssetFilter,
        isDefaultAssetFilterUpdated: true
      };
    }
    default:
      return state;
  }
}
