import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useRerenderOnResize = () => {
  const [, setResize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setResize(val => !val);
    };

    const throttledHandleResize = throttle(handleResize, 50);
    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, []);
};
