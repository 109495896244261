let seenTargets = [];

export const initNotificationCenterActions = () => {
  const logger = ({ action, target, notification }) => {
    try {
      const { user: { username: actor = null }, JotFormActions } = global;

      if (typeof JotFormActions !== 'function' || !actor) return;

      if (typeof global._logNotificationCenterActions !== 'function') {
        global._logNotificationCenterActions = JotFormActions('notification-center');
      }

      // seen actions (these actions should be sent only once)
      if (action === 'seen') {
        if (seenTargets.includes(target)) return;
        seenTargets = [...seenTargets, target];

        // language switch
        if (notification?.name === 'languageSwitch') {
          global._logNotificationCenterActions.tick({
            actor,
            action: `seen-${notification.offeredLang}`,
            target
          });
          return;
        }

        // newsletter
        if (notification?.name === 'newsletter') {
          global._logNotificationCenterActions.tick({
            actor,
            action: `seen-${notification.date}`,
            target
          });
          return;
        }
      }

      // default
      global._logNotificationCenterActions.tick({ actor, action, target });
    } catch (e) {
      console.log('NotificationCenterActions init errror.', e);
    }
  };

  if (!global.logNotificationCenterActions) {
    global.logNotificationCenterActions = logger;
  }

  return logger;
};
