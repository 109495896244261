import { useState, useEffect, Dispatch } from 'react';
import Tracking from '@jotforminc/tracking';
import { AIActionTypes } from '../constants';
import { DispatchAIBuilderAction } from '../context';
import { UserState } from '../typings/state';

const fsVars = {
  aiFormBuilderIncremental_bool: true
};

export const useFullstory = ({ user, dispatchAIBuilder }: { user: UserState, dispatchAIBuilder: Dispatch<DispatchAIBuilderAction> }) => {
  const [parentUsesFS, setParentUsesFS] = useState(true);
  const isAICVariant = (window.isAICVariant || user.aiFormBuilderBetaUser === '16861' || user.aiFormBuilderIncrementalUpdateTest === '22601' || window.location.href.includes('AIFormBuilder'))
  && user.account_type?.name !== 'ADMIN';
  const isComingFromJotFormMobile = global.navigator.userAgent.indexOf('JotForm Mobile') > -1 || global.navigator.userAgent.indexOf('JFCEMobile') > -1;

  useEffect(() => {
    if (isAICVariant && !isComingFromJotFormMobile && !user.aiBuilderFromLandingV2) {
      if (!Tracking.FSisInitialized()) {
        // Set parent state
        setParentUsesFS(false);
        // Enable FS
        Tracking.enableFS(null);
        // Add FS identification
        Tracking.identify(user.username || '', {
          displayName: user.username || '',
          ...fsVars
        });

        window._fs_ready = function getFSSession() {
          const fullstoryUrl = Tracking.getCurrentSessionURL();

          if (fullstoryUrl) {
            dispatchAIBuilder({ type: AIActionTypes.SET_FULLSTORY_URL, payload: fullstoryUrl });
          }
        };
      } else {
        // Add FS vars for existing session
        Tracking.setUserVars(fsVars);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, parentUsesFS]);
};
