export const breakpoints = {
  mobileSM: '375px',
  mobile: '480px',
  mobileNew: '416px',
  tablet: '768px',
  desktopSM: '992px',
  desktopM: '1200px',
  desktopXL: '1600px'
};

export const durations = {
  dismiss: {
    delay: 300,
    animation: 500,
    total: 800,
    gradient: 1000
  }
};

export const hipaaSteps = { firstStep: 1, secondStep: 2 };

export const hipaaFirstStep = { yes: 'yes', no: 'no' };

export const urlParamAll = 'nc-all=true';

export const industryDiscountNotificationSeenAtKey = 'industryDiscountNotificationSeenAt';
