import { handleCustomNavigation } from '@jotforminc/utils';
import { getDomainURL } from '@jotforminc/request-layer';
import { renderLunchAndLearnModal } from '@jotforminc/enterprise-promotions';

import { setFlagToLocSt, setUserNotificationStatus } from '../utils';

const urlParams = new URLSearchParams(window.location.search);

const onConfirm = (notification, user) => {
  if (!urlParams.get(notification.urlParam)) {
    setFlagToLocSt(notification.localStActionKey);
    setUserNotificationStatus(notification.deletedStatus);
  }

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirm',
      target: notification.actionTargetName
    });
  }

  renderLunchAndLearnModal({ user });
};

const onConfirmSecondary = notification => {
  if (!urlParams.get(notification.urlParam)) {
    setFlagToLocSt(notification.localStActionKey);
    setUserNotificationStatus(notification.deletedStatus);
  }

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'confirmSecondary',
      target: notification.actionTargetName
    });
  }

  if (window.JOTFORM_ENV === 'ENTERPRISE') {
    handleCustomNavigation('https://www.jotform.com/enterprise/lunch-and-learn-sessions/');
  } else {
    handleCustomNavigation(`${getDomainURL()}/enterprise/lunch-and-learn-sessions/`);
  }
};

const onDismiss = (notification, isAutoVisibleNotificationMode) => {
  if (!isAutoVisibleNotificationMode && !urlParams.get(notification.urlParam)) {
    setFlagToLocSt(notification.localStActionKey);
    setUserNotificationStatus(notification.deletedStatus);
  }
  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: 'dismiss',
      target: notification.actionTargetName
    });
  }
};

const checkLunchAndLearn = notifications => {
  let notification = notifications.find(notif => notif.name === 'lunchAndLearn');
  const otherNotifications = notifications.filter(notif => notif.name !== 'lunchAndLearn');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    confirmSecondary: {
      ...notification.confirmSecondary,
      callback: onConfirmSecondary
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkLunchAndLearn;
