import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { lazyRender } from '@jotforminc/safe-lazy-import';
import { TemplateSuggestionModal } from '../campaigns';

export const openTemplateSuggestionModal = ({
  user,
  currentPage,
  watchmanProjectName
}) => {
  const containerSelector = '#modal-container';
  const root = document.querySelector(containerSelector);
  let templateSuggestionTracker = f => f;
  if (window.JotFormActions) {
    ({ tick: templateSuggestionTracker } = window.JotFormActions(watchmanProjectName));
  }

  lazyRender({
    root,
    children: (
      <TemplateSuggestionModal
        user={user}
        currentPage={currentPage}
        tracker={templateSuggestionTracker}
        disableDoNotShowAgainCheckbox={true}
        handleClose={() => unmountComponentAtNode(root)}
      />
    )
  });
};
