/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';
import { Button } from '@jotforminc/magnet';
import type { ButtonProps, PolymorphicProps } from '@jotforminc/magnet';

type ButtonBaseProps = {
  hidden?: boolean;
  removeGap?: boolean;
};

/**
 * @function onSubmit
 * @param event
 * @desc We need to hijack the onClick event if we want the button to behave as type="submit". Note that this can
 * all be avoided if we can pass "type" to MagnetUI buttons
 */
const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  event.currentTarget.setAttribute('type', 'submit'); // Bypass Magnet UI button type
  event.currentTarget.form?.requestSubmit(event.currentTarget);
  event.currentTarget.setAttribute('type', 'button'); // revert back
};

const ButtonBase = forwardRef<HTMLButtonElement, PolymorphicProps<'button', ButtonProps> & ButtonBaseProps>(({
  children, hidden, removeGap, className, style, type, onClick, ...props
}, ref) => {
  // Magnet UI only accepts string for children.
  if (children && typeof children !== 'string') {
    console.error(`
    Magnet UI Button only accepts strings.
    You cannot pass React Components, Objects, or Arrays.
    Object passed: ${children}`);
  }

  if (hidden) return null;

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'submit') onSubmit(event);
    if (onClick) onClick(event);
  };

  const removeStyleGap = removeGap ? { gap: 0 } : {};
  return (
    <Button
      ref={ref}
      className={`cursor-pointer ${className}`}
      style={{ ...removeStyleGap, ...style }}
      {...props}
      // Workaround to enable type="submit' for Magnet UI button
      onClick={onClickHandler}
    >
      {children}
    </Button>
  );
});

export { ButtonBase };
