/* eslint-disable max-len */
/* eslint-disable react/jsx-max-props-per-line */
import { t } from '@jotforminc/translation';
import React, { useEffect, useState } from 'react';
import { Button } from '@jotforminc/magnet';
import { IconXmark, IconExclamationCircle } from '@jotforminc/svg-icons';
import { openModal } from '.';
import { useTabNavigation } from '../hooks/AccesibilityHooks/useTabNavigation';

const openInputModal = ({
  titleText = '',
  labelText = '',
  inputPlaceholder = '',
  confirmText = 'Confirm',
  defaultValue = '',
  isRequired = false,
  disableEmpty = false,
  makeValidation = false,
  headerIcon = {},
  inputProps = {},
  ...props
}) => {
  // eslint-disable-next-line react/prop-types
  const HeaderRenderer = ({ handleClose, closeRef }) => {
    return (
      <div className="input-modal-header">
        <div className="input-modal-header-title">
          <div className={`${headerIcon && 'iconWrapperClass'}`}>
            {headerIcon}
          </div>
          {titleText}
        </div>
        <Button
          colorStyle="secondary"
          rounded={true}
          startIcon={IconXmark}
          onClick={handleClose}
          ref={closeRef}
          aria-label="close icon"
        />
      </div>
    );
  };
  // eslint-disable-next-line react/prop-types
  const ContentRenderer = ({ handleConfirm, handleClose }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const closeModalBtn = React.useRef();
    const confirmRef = React.useRef();
    const inputRef = React.useRef();

    const onConfirm = () => {
      const value = inputRef?.current?.value;
      const urlRegex = /[/`<>\\]/;
      const isInvalid = Boolean(value?.match(urlRegex));
      if (isInvalid && makeValidation) {
        setErrorMessage('Sorry, only letters (a-z) and numbers (0-9) are allowed');
        return;
      }

      if (isRequired && !value?.trim()) {
        setErrorMessage('This field is required.');
        return;
      }
      handleConfirm(value);
    };

    useEffect(() => {
      if (inputRef?.current) inputRef.current.focus();
    }, []);

    useTabNavigation(closeModalBtn, confirmRef, [{
      keyCode: 'Enter',
      callback: e => {
        e.preventDefault();
        onConfirm();
      }
    }]);

    return (
      <div className="input-modal">
        <HeaderRenderer handleClose={handleClose} closeRef={closeModalBtn} />
        <div className="input-modal-content">
          <label
            htmlFor="input-modal-content-input"
            className="input-modal-content-label"
          >
            {labelText}
          </label>
          <input
            id="input-modal-content-input"
            className="input-modal-content-input"
            placeholder={inputPlaceholder}
            defaultValue={defaultValue}
            onChange={() => setErrorMessage('')}
            {...inputProps}
            ref={inputRef}
          />
          <span className={`input-modal-content-input-error ${errorMessage ? 'isVisible' : ''}`}>
            <IconExclamationCircle className="w-4 h-4" />
            {t(errorMessage)}
          </span>
        </div>
        <div className="input-modal-footer">
          <Button
            colorStyle="secondary"
            variant="outline"
            className="text-uppercase w-full xs:w-max"
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            className="text-uppercase w-full mb-3 xs:mb-0 xs:w-max"
            colorStyle="success"
            onClick={onConfirm}
            ref={confirmRef}
            disabled={disableEmpty && (!inputRef?.current?.value || inputRef.current.value.trim().length === 0)}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    );
  };
  return openModal({
    ContentRenderer,
    defaultVisible: true,
    HeaderRenderer,
    ...props
  });
};

export default openInputModal;
