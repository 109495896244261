import React, { FunctionComponent } from 'react';
import ActionRecorder from '../../../utils/actionRecorder';
import SummaryCard from '../../SummaryCard';
import { LogGroup, FilterType } from '../types';

type Props = {
  value: { filterType: FilterType },
  onChange: (actionType: string, filterType: FilterType) => void,
  actionFilter?: string | null,
  count: { [key: string]: string },
  logGroups: LogGroup[]
}
const RightPanelFilters: FunctionComponent<Props> = ({
  value, onChange, actionFilter, count, logGroups
}) => {
  const { filterType } = value;

  const getCount = (action: string): string => {
    return Object.prototype.hasOwnProperty.call(count, action) ? count[action] : '0';
  };

  const handleClick = (actionType: string) => {
    ActionRecorder.tick({ action: `${actionType}Clicked`, target: 'activityLog.rightPanelFilters' });
    onChange(actionType, filterType);
  };

  return (
    <div className="jfActivity-filters">
      {logGroups.filter(lg => lg.showInRightPanel).map(({ visibleName, groupId }) => {
        return (
          <SummaryCard
            activeCard={actionFilter}
            card={groupId}
            onClick={handleClick}
            title={visibleName}
            data={{ number: getCount(groupId) }}
            key={groupId}
          />
        );
      })}
    </div>
  );
};

RightPanelFilters.defaultProps = {
  actionFilter: ''
};

export default RightPanelFilters;
