import React from 'react';
import ReactDOM from 'react-dom';
import { ENTERPRSE_PROMOTIONS_ROOT_EL_ID, getRootElement } from '@jotforminc/ep-utils';
import { FqhcModal } from '../components';

const renderFhqcModal = props => {
  const rootEl = getRootElement(ENTERPRSE_PROMOTIONS_ROOT_EL_ID);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(rootEl);
  };

  ReactDOM.render(
    <FqhcModal onClose={handleClose} {...props} />,
    rootEl
  );
};

export default renderFhqcModal;
