import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { t } from '@jotforminc/translation';
import { IconAngleUp, IconAngleDown } from '@jotforminc/svg-icons';
import { OrderType, ORDER_DIR_OPTIONS, TEXT_DIR_OPTIONS } from './constants';
import { handleKeyUp } from '../../utils';
import { SortProps, TableMetadataType, RowProperties } from './types';

type Props<SortOptions> = {
  rowData?: { value: ReactNode, custumColumnClass?: string }[],
  rowProperties?: RowProperties
  tableMetaData: TableMetadataType<SortOptions>,
  isHeader?: boolean,
  changeSortOption?: (columnType: SortOptions, order: OrderType) => void,
  activeSort?: SortProps<SortOptions>,
  isTableEmpty?: boolean
};

export function RowRenderer<SortOptions>({
  rowData = [],
  rowProperties,
  tableMetaData,
  isHeader,
  changeSortOption = () => {},
  activeSort = {},
  isTableEmpty
}: Props<SortOptions>): ReactElement {
  return (
    <tr className={rowProperties?.className ? rowProperties.className : 'jfTable-row'} onClick={e => { if (rowProperties?.onClick) rowProperties?.onClick(e); }}>
      {rowData && rowData.map((column, index) => {
        const {
          type, forColumn, canBeSorted, value: columnHeader, isRTL
        } = tableMetaData.HEADERS[index];
        const columnClass = classNames('jfTable-column', column.custumColumnClass || '', { [`for${forColumn}`]: true }, { forRTLColumn: isRTL }, { forEllipsisRow: forColumn === 'Activity' });
        const columnValue = column.value;

        if (isHeader) {
          const { ASC, DESC } = ORDER_DIR_OPTIONS;
          const { sortBy, sortWay } = activeSort;
          const isActive = type === sortBy;
          const isDescending = sortWay === DESC;
          const activeIcon = isDescending ? 'active-down' : 'active-up';
          const newSortWay = isDescending ? ASC : DESC;
          const defaultSortWay = DESC;

          return (
            <th key={forColumn} className={columnClass}>
              {(canBeSorted && !isTableEmpty) ? (
                <div
                  className={`sortableHeader ${forColumn}`}
                  onClick={() => changeSortOption(type, isActive ? newSortWay : defaultSortWay)}
                  onKeyUp={e => handleKeyUp(e, () => changeSortOption(type, isActive ? newSortWay : defaultSortWay))}
                  tabIndex={0}
                  role="button"
                  aria-label={`${columnHeader} Header - ${TEXT_DIR_OPTIONS[isActive ? newSortWay : defaultSortWay]}`}
                >
                  {t(columnHeader)}
                  <span className={`tableSort-icon-wrapper ${isActive ? activeIcon : ''}`}>
                    <IconAngleUp viewBox="6 7 12 8" className="tableSort-icon sort-up" />
                    <IconAngleDown viewBox="6 9 12 8" className="tableSort-icon sort-down" />
                  </span>
                </div>
              ) : t(columnHeader) }
            </th>
          );
        }
        return <td key={forColumn} className={columnClass}>{columnValue}</td>;
      })}
    </tr>
  );
}

RowRenderer.defaultProps = {
  rowData: [],
  rowProperties: {},
  isHeader: false,
  changeSortOption: () => {},
  activeSort: {},
  isTableEmpty: false
};
