/* eslint-disable max-statements */
/* eslint-disable complexity */
/*
  What is a jotform experience flow and why we need it?

  We have several modals that we inform user about their jotform accound
  For example; Industry selection, Email Verification,  Bounced Email
  These things actually not related to listing functionality, bcs of that,
  we  decided to separate their business logic jotformExperiences saga
*/

import {
  take, select, call, takeEvery, all
} from 'redux-saga/effects';
// eslint-disable-next-line
import QueryString from 'querystring-es3';
import { openFormLimitDialog } from '@jotforminc/limit-dialog';
import { enterprisePromotionSingleton } from '@jotforminc/ep-utils';
import { ABTestManager } from '@jotforminc/abtest-manager';

import { SELECTORS } from '../../store/selectors';
import { renderExperienceModals } from '../../campaigns';
import { renderNewUserCampaign } from '../../campaigns/NewUser';
import { renderUKNewUserCampaign } from '../../campaigns/UKNewUser';
import { renderSalesforceHeaderBanner } from '../../campaigns/SalesforceHeaderBanner';
import { renderAnnouncingSalesforceHeaderBanner } from '../../campaigns/AnnouncingSalesforceHeaderBanner';
import { renderReturnToPaidCampaign } from '../../campaigns/ReturnToPaidCampaign';
import { renderBlackFriday2024 } from '../../campaigns/BlackFriday2024';
import { renderAnnouncingJotformEnterprise } from '../../campaigns/AnnouncingJotformEnterprise';
import { renderEndOfYearCampaign } from '../../campaigns/EndOfYear';
import { renderSummerSaleCampaign } from '../../campaigns/SummerSale';
import { renderChurchIndustry } from '../../campaigns/ChurchIndustry';
import { renderAnimalShelter } from '../../campaigns/AnimalShelter';
import { renderSpringSaleHeaderBanner } from '../../campaigns/SpringSaleHeaderBanner';
import { renderOneDollarHeaderBanner } from '../../campaigns/OneDollarHeaderBanner';
import { renderWorkflowsHeaderBanner } from '../../campaigns/WorkflowsHeaderBanner';
import { renderSummerCamp } from '../../campaigns/SummerCamp';
import { renderJotformResellerProgram } from '../../campaigns/JotformResellerProgram';
import { renderEducationCampaign } from '../../campaigns/Education';
import { renderLaunch } from '../../campaigns/Launch';
import {
  shouldShowEmailBouncedModal, shouldShowHIPAAEnforcementModal, shouldShowNotVerifiedModal,
  shouldShowLaunchModal, shouldShowAnnouncingLightbox, shouldShowAbandonedUserModal,
  shouldShowUxrSurveyModal, shouldShowMyformsBanner,
  shouldShowReactivationAnnouncement, shouldShowCampaignLightbox, getActiveCampaign,
  shouldIndustryModalOpen, shouldShowBonusCampaignPopup,
  shouldShowEarthquakeDonateModal,
  shouldShowMobileAppQRCode, shouldShowEnterprisePromotionAsset,
  shouldShowReactivationModal,
  shouldShowAutoVisibleNotification,
  shouldShowTemplateSuggestionModal,
  shouldShowSalesforceEarlyAccessModal,
  shouldShowSalesforceRatingModal,
  shouldShowWorkflowsRatingModal,
  shouldCvShowForceSignupModal,
  shouldShowListingsIncreaseModal,
  isMobileDevice,
  shouldShowMyworkspaceSuggestionModal,
  shouldShowAgentBetaLaunchModal,
  shouldShowFormCopilotModal,
  shouldShowWorkflowLaunchModal
} from '../../utils/ExperienceUtils';

import { ACTION_TYPES } from '../../store/actionTypes';
import {
  MODAL_TYPES, MODAL_PROPERTIES, LISTING_TYPES
} from '../../constants';
import { getPortalRoot } from '../../utils';
import { renderNotificationCenter } from '../../campaigns/NotificationCenter';
import { renderTwoFactorAnnouncement } from '../../campaigns/TwoFactorAnnouncement';

function* renderExperienceModalsByFeature({ featureList }) {
  const feature = featureList[0] || '';
  const credentials = yield select(SELECTORS.getUserCredentials);
  yield call(renderExperienceModals, feature, credentials);
}

function* checkThenRenderModals() {
  const { action = '' } = QueryString.parse(window.location.search.replace('?', ''));
  // do not mount any modal if create wizard is initially opened
  if (action === 'createWizard') return true;

  const [
    user, userAccountType, isMobileApp, launchAvailable, blackFridayAvailable, blackFridayCheckoutAvailable,
    endOfYearAvailable, summerSaleAvailable, nonprofitAvailable, educationAvailable, isNewUserCampaignAvailable,
    isBackToPaidCampaignAvailable, springSaleAvailable, oneDollarSilverCampaignAvailable,
    currentPage, userType, username, systemPlans, totalCount, list
  ] = yield all([
    select(SELECTORS.getUser),
    select(SELECTORS.getUserAccountTypeName),
    select(SELECTORS.getIsMobileApp),
    select(SELECTORS.getLaunchAvailability),
    select(SELECTORS.getBlackFridayCampaignAvailability),
    select(SELECTORS.getBlackFridayCheckoutAvailability),
    select(SELECTORS.getEndOfYearCampaignAvailability),
    select(SELECTORS.getSummerSaleCampaignAvailability),
    select(SELECTORS.getNonprofitCampaignAvailability),
    select(SELECTORS.getEducationCampaignAvailability),
    select(SELECTORS.getNewUserCampaignAvailability),
    select(SELECTORS.getBackToPaidCampaignAvailability),
    select(SELECTORS.getSpringSaleCampaignAvailability),
    select(SELECTORS.getOneDollarSilverCampaignAvailability),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getUserType),
    select(SELECTORS.getUserName),
    select(SELECTORS.getUserSystemPlans),
    select(SELECTORS.getListCount),
    select(SELECTORS.getList)
  ]);

  // Don't show campaigns to form users
  if (userType === 'FORM_USER') {
    return;
  }

  const { credentials } = user;
  let modalType;
  let campaignData;

  const campaignDataSetter = data => {
    campaignData = data;
  };

  const cvForceSignupModalPayload = yield call(shouldCvShowForceSignupModal, { currentPage, credentials });
  if (cvForceSignupModalPayload) {
    campaignData = cvForceSignupModalPayload;
    modalType = MODAL_TYPES.EXPERIENCE_CV_FORCE_SIGNUP_MODAL;
  } else if (yield call(shouldShowFormCopilotModal, { credentials, currentPage, list })) {
    modalType = MODAL_TYPES.FORM_COPILOT_CTA_MODAL;
  } else if (yield call(shouldShowTemplateSuggestionModal, {
    currentPage,
    credentials,
    totalCount
  })) {
    modalType = MODAL_TYPES.EXPERIENCE_TEMPLATE_SUGGESTION_MODAL;
    campaignData = { currentPage: currentPage === LISTING_TYPES.MIX ? LISTING_TYPES.FORM : currentPage };
  } else if (yield call(shouldShowMyworkspaceSuggestionModal, {
    currentPage,
    credentials,
    totalCount
  })) {
    modalType = MODAL_TYPES.EXPERIENCE_MYWORKSPACE_SUGGESTION_MODAL;
    campaignData = { currentPage: currentPage };
  } else if (yield call(shouldShowNotVerifiedModal, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_NOT_VERIFIED;
  } else if (yield call(shouldShowBonusCampaignPopup, { isNewUserCampaignAvailable, credentials })) {
    const newUserExpirationDate = yield select(SELECTORS.getNewUserCampaignExpirationDate);
    const activeCampaign = getActiveCampaign({ bonusCampaignAvailable: true });
    modalType = MODAL_TYPES.EXPERIENCE_BONUS_CAMPAIGN_POPUP;
    campaignData = {
      activeCampaign,
      newUserExpirationDate
    };
  } else if (yield call(shouldShowCampaignLightbox, {
    blackFridayAvailable,
    blackFridayCheckoutAvailable,
    isNewUserCampaignAvailable,
    userAccountType,
    userSettings: credentials,
    endOfYearAvailable,
    summerSaleAvailable,
    nonprofitAvailable,
    educationAvailable,
    isBackToPaidCampaignAvailable,
    oneDollarSilverCampaignAvailable,
    springSaleAvailable
  })) {
    const [plans] = yield all([
      select(SELECTORS.getUserSystemPlansForLimitDialog)
    ]);
    modalType = MODAL_TYPES.EXPERIENCE_CAMPAIGN_LIGHTBOX;
    const { campaign = {} } = (user?.credentials || {});
    const { industryCampaign, industryCampaignAssetVersion } = campaign;
    const activeCampaign = getActiveCampaign({
      blackFridayAvailable,
      blackFridayCheckoutAvailable,
      endOfYearAvailable,
      summerSaleAvailable,
      nonprofitAvailable,
      isBackToPaidCampaignAvailable,
      oneDollarSilverCampaignAvailable,
      springSaleAvailable,
      animalShelterAvailable: industryCampaign === 'ANIMAL-SHELTER',
      churchAvailable: industryCampaign === 'CHURCH',
      educationAvailable: industryCampaign === 'EDUCATION'
    });

    const isMobile = isMobileDevice();
    let churchLightboxVariation = '';
    let animalShelterLightboxVariation = '';
    let educationLightboxVariation = '';
    // should initialize church mobile AB Test
    if (industryCampaign === 'CHURCH' && industryCampaignAssetVersion === 'v2' && isMobile) {
      churchLightboxVariation = 'A';
      // A/B Test: churchLBMobile
      const abTestManager = new ABTestManager({
        user,
        isTestEnabled: true,
        urlParam: 'churchLBTest',
        testName: 'churchLBMobile',
        testVariantCode: '21451',
        controlVariantCode: '21441',
        cacheVariantCodeAtLocalStorage: true
      });

      try {
        const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
        if (isTestVariant) {
          churchLightboxVariation = 'B';
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (industryCampaign === 'EDUCATION' && industryCampaignAssetVersion === 'v2' && isMobile) {
      educationLightboxVariation = 'A';
      // A/B Test: educationLBMobile
      const abTestManager = new ABTestManager({
        user,
        isTestEnabled: true,
        urlParam: 'educationLBTest',
        testName: 'educationLBMobile',
        testVariantCode: '27231',
        controlVariantCode: '27221',
        cacheVariantCodeAtLocalStorage: true
      });

      try {
        const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
        if (isTestVariant) {
          educationLightboxVariation = 'B';
        }
      } catch (e) {
        console.log(e);
      }
    }
    // should initialize animal shelter mobile AB Test
    if (industryCampaign === 'ANIMAL-SHELTER' && industryCampaignAssetVersion === 'v2' && isMobile) {
      animalShelterLightboxVariation = 'A';
      // A/B Test: animalShelterLBTest
      const abTestManager = new ABTestManager({
        user,
        isTestEnabled: false,
        urlParam: 'animalShelterLBTest',
        testName: 'animalShelterLBMobile',
        testVariantCode: '25981',
        controlVariantCode: '25971',
        cacheVariantCodeAtLocalStorage: true
      });

      try {
        const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
        if (isTestVariant) {
          animalShelterLightboxVariation = 'B';
        }
      } catch (e) {
        console.log(e);
      }
    }

    let enableGAM = false;
    if (oneDollarSilverCampaignAvailable) {
      const abTestManager = new ABTestManager({
        user,
        isTestEnabled: true,
        testName: 'oneDollarSilverGAMLightbox',
        testVariantCode: '28731',
        controlVariantCode: '28721'
      });

      try {
        const isTestVariant = yield call(abTestManager.isTestVariant.bind(abTestManager));
        if (isTestVariant) {
          enableGAM = true;
        }
      } catch (e) {
        console.log(e);
      }
    }
    enableGAM = enableGAM || blackFridayAvailable || blackFridayCheckoutAvailable;
    campaignData = {
      plans,
      activeCampaign,
      userAccountType,
      enableGAM,
      churchLightboxVariation,
      educationLightboxVariation,
      animalShelterLightboxVariation
    };
  } else if (shouldShowAgentBetaLaunchModal(user, currentPage)) {
    modalType = MODAL_TYPES.EXPERIENCE_AGENT_BETA_LAUNCH_MODAL;
  } else if (shouldShowWorkflowLaunchModal(user, currentPage)) {
    modalType = MODAL_TYPES.EXPERIENCE_CAMPAIGN_LIGHTBOX;
  } else if (shouldShowWorkflowsRatingModal(user, currentPage, totalCount)) {
    modalType = MODAL_TYPES.EXPERIENCE_WORKFLOWS_RATING_MODAL;
  } else if (shouldShowSalesforceEarlyAccessModal(user)) {
    modalType = MODAL_TYPES.EXPERIENCE_SALESFORCE_EARLY_ACCESS;
  } else if (shouldShowSalesforceRatingModal(user)) {
    modalType = MODAL_TYPES.EXPERIENCE_SALESFORCE_RATING_MODAL;
  } else if (yield call(shouldShowLaunchModal, { launchAvailable })) {
    const { EXPERIENCE_LAUNCH_MODAL: { name, logger } } = MODAL_PROPERTIES;
    campaignData = { lightboxName: name, loggerName: logger };
    modalType = MODAL_TYPES.EXPERIENCE_LAUNCH_MODAL;
  } else if (yield call(shouldShowReactivationAnnouncement, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_REACTIVATION_ANNOUNCEMENT;
  } else if (window.shouldGetKVKKConsent) {
    modalType = MODAL_TYPES.EXPERIENCE_KVKK_CONSENT;
  } else if (yield call(shouldShowHIPAAEnforcementModal, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_HIPAA_ENFORCEMENT;
  } else if (yield call(shouldIndustryModalOpen, { credentials, isMobileApp, userSettings: credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_INDUSTRY;
  } else if (yield call(shouldShowEmailBouncedModal)) {
    modalType = MODAL_TYPES.EXPERIENCE_BOUNCE;
  } else if (yield call(shouldShowAbandonedUserModal, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_ABANDONED_USER;
  } else if (yield call(shouldShowUxrSurveyModal, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_UXR_SURVEY;
  } else if (yield call(shouldShowReactivationModal, {
    userAccountType, username, callback: campaignDataSetter
  })) {
    modalType = MODAL_TYPES.EXPERIENCE_REACTIVATION_MODAL;
  } else if (yield call(shouldShowMyformsBanner, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_MYFORMS_BANNER;
  } else if (yield call(shouldShowEnterprisePromotionAsset, { credentials })) { // use this for enterprise assets
    modalType = enterprisePromotionSingleton.assetType;
  } else if (yield call(shouldShowEarthquakeDonateModal, { credentials })) {
    const [plans] = yield all([
      select(SELECTORS.getUserSystemPlans)
    ]);

    modalType = MODAL_TYPES.EXPERIENCE_EARTHQUAKE_DONATION_MODAL;
    campaignData = { plans };
  } else if (yield call(shouldShowMobileAppQRCode, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_MOBILE_APP_QR_CODE;
  } else if (yield call(shouldShowAutoVisibleNotification, {
    credentials, userType, currentPage, accountTypeName: userAccountType
  })) {
    modalType = MODAL_TYPES.EXPERIENCE_NOTIFICATION_CENTER;
  } else if (yield call(shouldShowAnnouncingLightbox)) {
    modalType = MODAL_TYPES.EXPERIENCE_ANNOUNCING_LIGHTBOX;
  } else if (yield call(shouldShowListingsIncreaseModal, { credentials })) {
    modalType = MODAL_TYPES.EXPERIENCE_LISTINGS_INCREASE_LIMITS_MODAL;
  } else {
    return;
  }

  yield call(renderExperienceModals, modalType, credentials, campaignData, systemPlans);
}

function* mountBanner() {
  const container = document.getElementById('lsApp-toolbar-campaign-container');
  const { PLATFORM_ENV = '' } = window;

  const [
    isNewUserCampaignAvailable,
    expireDate,
    assetType,
    accountTypeName,
    utmSourceSuffix,
    isBlackFridayCampaignAvailable,
    isEndOfYearCampaignAvailable,
    isSummerSaleCampaignAvailable,
    isOverQuotaCampaignAvailable,
    overQuotaExpireDate,
    isBackToPaidCampaignAvailable,
    backToPaidExpireDate,
    oneDollarSilverCampaignAvailable,
    oneDollarSilverCampaignExpireDate,
    isGAMBannerAvailable,
    isSpringSaleCampaignAvailable,
    campaignUtm,
    username,
    // eslint-disable-next-line no-unused-vars
    launchAvailable,
    currentPage,
    user
  ] = yield all([
    select(SELECTORS.getNewUserCampaignAvailability),
    select(SELECTORS.getNewUserCampaignExpirationDate),
    select(SELECTORS.getNewUserCampaignAssetType),
    select(SELECTORS.getUserAccountTypeName),
    select(SELECTORS.getUtmSourceSuffix),
    select(SELECTORS.getBlackFridayCampaignAvailability),
    select(SELECTORS.getEndOfYearCampaignAvailability),
    select(SELECTORS.getSummerSaleCampaignAvailability),
    select(SELECTORS.getOverquotaCampaignAvailability),
    select(SELECTORS.getOverquotaCampaignExpirationDate),
    select(SELECTORS.getBackToPaidCampaignAvailability),
    select(SELECTORS.getBackToPaidCampaignExpirationDate),
    select(SELECTORS.getOneDollarSilverCampaignAvailability),
    select(SELECTORS.getOneDollarSilverCampaignExpirationDate),
    select(SELECTORS.getGAMBannerAvailable),
    select(SELECTORS.getSpringSaleCampaignAvailability),
    select(SELECTORS.getCampaignUtm),
    select(SELECTORS.getUserName),
    select(SELECTORS.getLaunchAvailability),
    select(SELECTORS.getCurrentPage),
    select(SELECTORS.getUser)
  ]);

  const { credentials } = user;
  const {
    excludeCampaignAssets = false,
    enterprise_reseller: isEnterpriseResellerUser = false,
    campaign = {}
  } = credentials;

  const {
    salesforceLaunchAssetsAvailable = false,
    workflowLaunchAssetsAvailable
  } = campaign;
  const dynamicPrefillBannerEnabled = global?.location?.href?.includes('salesforce-prefill=1') || salesforceLaunchAssetsAvailable;
  if (isGAMBannerAvailable) {
    document.body.classList.add('gam-enabled');
    return;
  }

  if (excludeCampaignAssets === '1') return;
  if (global?.location?.href?.includes('jrp=1') || isEnterpriseResellerUser) {
    const utmSource = `jotform-reseller-program-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('jotform-reseller-program-body');
    return renderJotformResellerProgram({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }

  if ((isNewUserCampaignAvailable && assetType === 'v2' && expireDate) || global?.location?.href?.includes('ukNewUserCampaign=1')) {
    const utmSource = `newUserDesignTest-${utmSourceSuffix}-cta`;
    const expires = new Date(expireDate * 1000).getTime();
    document.body.classList.add('newUser-body');
    return renderUKNewUserCampaign({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }

  if (isNewUserCampaignAvailable && expireDate && accountTypeName !== 'GUEST') {
    const utmSource = `newUser-${utmSourceSuffix}-cta`;
    const expires = new Date(expireDate * 1000).getTime();
    document.body.classList.add('newUser-body');
    return renderNewUserCampaign({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }

  if (global?.location?.href?.includes('workflows=1') || (workflowLaunchAssetsAvailable && currentPage !== LISTING_TYPES.TEAMS)) {
    const utmSource = `workflows-${utmSourceSuffix}-cta`;
    const expires = new Date(oneDollarSilverCampaignExpireDate * 1000).getTime();
    document.body.classList.add('workflow-launch-body');
    return renderWorkflowsHeaderBanner({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username,
      expirationDate: expires
    });
  }

  if (isOverQuotaCampaignAvailable && overQuotaExpireDate) {
    const quotaUtm = campaignUtm ?? 'overQuota';
    const utmSource = `${quotaUtm}-${utmSourceSuffix}-cta`;
    const expires = new Date(overQuotaExpireDate * 1000).getTime();
    document.body.classList.add('newUser-body');
    return renderUKNewUserCampaign({
      container,
      utmSource,
      accountTypeName,
      isOverquotaCampaign: isOverQuotaCampaignAvailable,
      expirationDate: expires,
      campaignUtm: quotaUtm
    });
  }

  if (isBlackFridayCampaignAvailable && currentPage !== LISTING_TYPES.TEAMS) {
    const utmSource = `blackFriday2024-${utmSourceSuffix}-cta`;
    document.body.classList.add('black-friday-2024-my-forms-body');
    return renderBlackFriday2024({
      container,
      utmSource,
      accountTypeName,
      username
    });
  }
  if (isEndOfYearCampaignAvailable && currentPage !== LISTING_TYPES.TEAMS) {
    const utmSource = `eoy2023-${utmSourceSuffix}-cta`;
    document.body.classList.add('eoy-body');
    return renderEndOfYearCampaign({
      container,
      utmSource,
      accountTypeName,
      username,
      campaign: credentials?.campaign
    });
  }
  if (isSummerSaleCampaignAvailable && currentPage !== LISTING_TYPES.TEAMS) {
    const utmSource = `ss2023-${utmSourceSuffix}-cta`;
    document.body.classList.add('ss-body');
    return renderSummerSaleCampaign({
      container,
      utmSource,
      accountTypeName,
      username
    });
  }
  const { industryCampaign, industryCampaignAssetVersion } = campaign;
  if (global?.location?.href?.includes('education=1') || (industryCampaign === 'EDUCATION' && industryCampaignAssetVersion === 'v2')) {
    const utmSource = `education2024-${utmSourceSuffix}-cta`;
    document.body.classList.add('education2024-body');
    return renderEducationCampaign({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username
    });
  }
  if ((isBackToPaidCampaignAvailable || (global?.location?.href?.includes('returnToPaidCampaign=1' || campaignUtm === 'btp2023'))) && currentPage !== LISTING_TYPES.TEAMS) {
    const utmSource = `returnToPaidCampaign-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('returnToPaidCampaign-body');
    return renderReturnToPaidCampaign({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }
  if (global?.location?.href?.includes('summerCamp=1') || campaignUtm === 'sc2024') {
    const utmSource = `summer-camp-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('summer-camp-body');
    return renderSummerCamp({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }

  if (global?.location?.href?.includes('church=1') || (industryCampaign === 'CHURCH' && industryCampaignAssetVersion === 'v2')) {
    const utmSource = `church-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('church-body');
    return renderChurchIndustry({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username,
      expirationDate: expires
    });
  }
  if (global?.location?.href?.includes('animal-shelter=1') || (industryCampaign === 'ANIMAL-SHELTER' && industryCampaignAssetVersion === 'v2')) {
    const utmSource = `animal-shelter-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('animal-shelter-body');
    return renderAnimalShelter({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username,
      expirationDate: expires
    });
  }
  if (oneDollarSilverCampaignAvailable || global?.location?.href?.includes('one-dollar=1')) {
    const utmSource = `one-dollar-${utmSourceSuffix}-cta`;
    const expires = new Date(oneDollarSilverCampaignExpireDate * 1000).getTime();
    document.body.classList.add('one-dollar-body');
    return renderOneDollarHeaderBanner({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username,
      expirationDate: expires
    });
  }

  if ((isSpringSaleCampaignAvailable && accountTypeName !== 'GUEST' && currentPage !== LISTING_TYPES.TEAMS) || global?.location?.href?.includes('spring=1')) {
    const utmSource = `summer-sale-${utmSourceSuffix}-cta`;
    const expires = new Date(backToPaidExpireDate * 1000).getTime();
    document.body.classList.add('spring-sale-body');
    return renderSpringSaleHeaderBanner({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username,
      expirationDate: expires
    });
  }

  if (false && credentials?.is2FAActive && !credentials?.is2FAEnabled && window.JOTFORM_ENV === 'PRODUCTION' && currentPage !== LISTING_TYPES.TEAMS) {
    const utmSource = `announcement-2fa-${utmSourceSuffix}-cta`;
    document.body.classList.add('announcement-2fa-body');
    return renderTwoFactorAnnouncement({
      container,
      utmSource,
      accountTypeName,
      username: credentials?.username
    });
  }

  if (false && currentPage !== LISTING_TYPES.TEAMS && currentPage !== LISTING_TYPES.TEAM_PAGE) {
    const utmSource = `announcingJotformEnterprise-${utmSourceSuffix}-cta`;
    const expires = new Date(expireDate * 1000).getTime();
    document.body.classList.add('announcingJotformEnterprise-body');
    return renderAnnouncingJotformEnterprise({
      container,
      utmSource,
      accountTypeName,
      expirationDate: expires
    });
  }
  if (dynamicPrefillBannerEnabled) {
    const utmSource = PLATFORM_ENV === 'salesforce' ? `appExchange-dynamic-prefill-${utmSourceSuffix}-cta` : `dynamic-prefill-${utmSourceSuffix}-cta`;
    document.body.classList.add('announcing-salesforce-body');
    return renderAnnouncingSalesforceHeaderBanner({
      container,
      utmSource,
      accountTypeName,
      username
    });
  }
  if (false && currentPage !== LISTING_TYPES.TEAMS) { // donation apps launch header banner
    const utmSource = `donation-apps-launch-${utmSourceSuffix}-cta`;
    document.body.classList.add('enterprise-mobile-body');
    return renderLaunch({
      container,
      utmSource,
      accountTypeName,
      username
    });
  }
  if (false && currentPage !== LISTING_TYPES.TEAMS && currentPage !== LISTING_TYPES.TEAM_PAGE) {
    const utmSource = `saleforce-launch-${utmSourceSuffix}-cta`;
    document.body.classList.add('salesforce-body');
    return renderSalesforceHeaderBanner({
      container,
      utmSource,
      accountTypeName
    });
  }
}

function* showNotificationCenter() {
  const [creds, isGuest, userType, currentPage] = yield all([
    select(SELECTORS.getUserCredentials),
    select(SELECTORS.getIsGuestUser),
    select(SELECTORS.getUserType),
    select(SELECTORS.getCurrentPage)
  ]);

  if (isGuest || userType === 'FORM_USER' || [LISTING_TYPES.FORM, LISTING_TYPES.MIX].indexOf(currentPage) === -1) return;

  try {
    yield call(renderNotificationCenter, {
      user: creds,
      initInAutoVisibleMode: false
    });
  } catch (e) {
    console.warn('notification center couldnt be inited.');
  }
}

function* renderLimitDialog({ limitType, dispatchedFrom }) {
  const [user, systemPlans] = yield all([
    select(SELECTORS.getUserCredentials),
    select(SELECTORS.getUserSystemPlans)
  ]);

  try {
    yield call(openFormLimitDialog, {
      user,
      plans: systemPlans,
      dispatchedFrom,
      portalRoot: getPortalRoot(),
      selectedLimitType: limitType
    });
  } catch (e) {
    // no problem, modal closed!
  }
}

export function* rootJotformExperienceFlows() {
  yield take(ACTION_TYPES.FETCH_USER.SUCCESS);
  const user = yield select(SELECTORS.getUser);

  const { credentials } = user;
  // Why?? Builder modules needs it..
  window.user = credentials;
  yield take(ACTION_TYPES.FETCH_LIST.SUCCESS);
  yield mountBanner();
  yield showNotificationCenter();

  yield checkThenRenderModals();
  yield takeEvery(ACTION_TYPES.OPEN_LIMIT_DIALOG, renderLimitDialog);
  yield takeEvery(ACTION_TYPES.SET_FEATURES, renderExperienceModalsByFeature);
}
