/* eslint-disable react/prop-types */
import React from 'react';
import Styled from 'styled-components';
import Container from '../Container';

export const LoaderSvg = Styled.svg`
width: 100%;
height: 100%;
.mainRectangle {
  transform-origin: center;
  animation: scale2 1.5s 0s infinite normal cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.rect {
  transform: scale(0);

  &.rect1 {
    animation: scale1 2s  infinite  cubic-bezier(0.2, 0.68, 0.18, 1.08);
    transform-origin: 25px 50px;
  }
  &.rect2 {
    animation: scale2 2s  infinite  cubic-bezier(0.2, 0.68, 0.18, 1.08);
    transform-origin: 80px 50px;
  }
  &.rect3 {
    animation: scale3 2s  infinite  cubic-bezier(0.2, 0.68, 0.18, 1.08);
    transform-origin: 50px 10px;
  }
}
@keyframes scale1 {
  0% { transform: scale(0); }
  10% { transform: scale(1); }
  100% {
    transform: scale(1);
  }
}
@keyframes scale2 {
  0% { transform: scale(0); }
  20% { transform: scale(0); }
  30% { transform: scale(1); }
  100% {
    transform: scale(1);
  }
}
@keyframes scale3 {
  0% { transform: scale(0); }
  40% { transform: scale(0); }
  50% { transform: scale(1); }
  100% {
    transform: scale(1);
  }
}
`;

const Loader = () => {
  return (
    <Container>
      <LoaderSvg
        style={{ overflow: 'visible' }}
        viewBox="0 0 101.76 101.76"
      >
        <line
          stroke="#ccd8f2"
          fill="none"
          x1="50.6"
          y1="20.55"
          x2="50.6"
          y2="33.55"
        />
        <path
          stroke="#ccd8f2"
          fill="none"
          className="cls-1"
          d="M50.6,33.55h-27a4,4,0,0,0-4,4v3.5"
        />
        <path
          stroke="#ccd8f2"
          fill="none"
          className="cls-1"
          d="M50.6,33.55h26a4,4,0,0,1,4,4v3.5"
        />
        <line
          stroke="#ccd8f2"
          fill="none"
          className="cls-1"
          x1="50.6"
          y1="81.05"
          x2="50.6"
          y2="68.05"
        />
        <path
          stroke="#ccd8f2"
          fill="none"
          className="cls-1"
          d="M50.6,68.05h-27a4,4,0,0,1-4-4v-3.5"
        />
        <path
          stroke="#ccd8f2"
          fill="none"
          className="cls-1"
          d="M50.6,68.05h26a4,4,0,0,0,4-4v-3.5"
        />
        <rect
          fill="#ff6102"
          className="rect rect3"
          x="30.42"
          width="40.83"
          height="20.55"
          rx="10.28"
        />
        <rect
          fill="#ff6102"
          className="rect rect3"
          x="30.42"
          y="81.05"
          width="40.83"
          height="20.55" rx="10.28"
        />
        <rect
          fill="#09f"
          className="rect rect1"
          y="40.53"
          width="40.83"
          height="20.55"
          rx="10.28"
        />
        <rect
          fill="#ffb21e"
          className="rect rect2"
          x="60.92"
          y="40.53" width="40.83"
          height="20.55"
          rx="10.28"
        />
      </LoaderSvg>
    </Container>
  );
};

export default Loader;
