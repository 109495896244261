import React from 'react';
import ContentLoader from 'react-content-loader';

const ListPlaceholder = () => (
  <ContentLoader
    height={80}
    width={400}
    speed={2}
    style={{ height: 80 }}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect
      x="15" y="48" rx="3"
      ry="3" width="380" height="14"
    />
    <rect
      x="15" y="12" rx="3"
      ry="3" width="201" height="14"
    />
  </ContentLoader>
);

export default ListPlaceholder;
