import { AppIcons } from '@jotforminc/header-components';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { LISTING_TYPES } from '../../constants';
import { ALL_PERMISSIONS } from '../../utils/permissions';
import { canAccessMyAgentsPage } from '../../utils';

export const PRODUCT_CONFIGS = [
  {
    name: 'Form',
    detailsName: 'Form',
    description: 'Start collecting data with powerful forms that use conditional logic, accept payments, generate reports, and automate workflows.',
    Icon: AppIcons?.formBuilder?.Default,
    type: LISTING_TYPES.FORM,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FORM,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Form.png',
    isDefault: true
  },
  {
    name: window?.isWorkflowReleased ? 'Workflow' : 'Approval',
    detailsName: window?.isWorkflowReleased ? 'Workflow (Formerly Approvals)' : 'Approval',
    description: 'Create and automate workflows in minutes, streamline approvals, and track progress effortlessly—all without endless emails.',
    Icon: AppIcons?.approvals?.Default,
    type: LISTING_TYPES.TASK,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_FLOW,
    createPermissionName: 'Approval',
    isNew: window?.isWorkflowReleased,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Workflow.png'
  },
  {
    name: 'App',
    detailsName: 'App',
    description: 'No-code app builder to create fully customized apps with forms, widgets, data, and your branding, downloadable on any device.',
    Icon: AppIcons?.portal?.Default,
    type: LISTING_TYPES.PORTAL,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_APP,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/App.png'
  },
  {
    name: 'E-sign',
    detailsName: 'E-sign',
    description: 'Transform your documents into signable documents and automate your workflow with e-signatures on any device.',
    Icon: AppIcons?.sign?.Default,
    type: LISTING_TYPES.DOCUMENT,
    createPermissionName: 'Document',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_DOCUMENT,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Sign.png',
    checkVisibility: ({ user }) => {
      return !isEnterprise() || user?.allowSign;
    }
  },
  {
    name: 'Agent',
    visibleName: 'AI Agent',
    detailsName: 'AI Agent',
    description: 'Create a conversational, subject-trained agent that guides users, answers their questions, ensuring smooth and accurate completion.',
    Icon: AppIcons?.myAgents?.Default,
    isBeta: true,
    type: LISTING_TYPES.AGENT,
    createPermissionName: 'Agent',
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_AGENT,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Agent.png',
    checkVisibility: ({ user }) => {
      return canAccessMyAgentsPage(user);
    }
  },
  {
    name: 'Table',
    detailsName: 'Table',
    description: 'Track, organize, and manage data with powerful spreadsheets, calendars, reports and more for seamless collaboration.',
    Icon: AppIcons?.tables?.Default,
    type: LISTING_TYPES.SHEET,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_SHEET,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Table.png'
  },
  {
    name: 'Board',
    detailsName: 'Board',
    description: 'Track and manage tasks, set due dates, and collaborate with your team to keep projects on track.',
    Icon: AppIcons?.boards?.Default,
    isBeta: true,
    type: LISTING_TYPES.BOARD,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_BOARD,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Board.png',
    checkVisibility: ({ user }) => {
      return !!user?.allowBoards;
    }
  },
  {
    name: 'Report',
    detailsName: 'Report',
    description: 'Turn the data into professional reports and presentations that automatically update with each new submission.',
    Icon: AppIcons?.reports?.Default,
    type: LISTING_TYPES.REPORT,
    userCreatePermissionType: ALL_PERMISSIONS.CREATE_REPORT,
    imgSource: 'https://cdn.jotfor.ms/assets/img/my-forms/sideBySideModal/Report.png'
  }
];
