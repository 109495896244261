import { changeLanguage, updateUserSettings } from '../utils';

const onConfirm = ({ offeredLang, url, actionTargetName }) => {
  changeLanguage(offeredLang, url);

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: `confirm-${offeredLang}`,
      target: actionTargetName
    });
  }
};

const onDismiss = ({ offeredLang, actionTargetName }) => {
  updateUserSettings('switchLanguageOffer', '1');

  if (typeof global.logNotificationCenterActions === 'function') {
    global.logNotificationCenterActions({
      action: `dismiss-${offeredLang}`,
      target: actionTargetName
    });
  }
};

const checkLanguageSwitch = notifications => {
  let notification = notifications.find(notif => notif.name === 'languageSwitch');
  const otherNotifications = notifications.filter(notif => notif.name !== 'languageSwitch');

  if (!notification) return notifications;

  notification = {
    ...notification,
    confirm: {
      ...notification.confirm,
      callback: onConfirm
    },
    dismiss: {
      ...notification.dismiss,
      callback: onDismiss
    }
  };

  return [...otherNotifications, notification];
};

export default checkLanguageSwitch;
