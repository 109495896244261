import Styled from 'styled-components';
import { PickerModal } from '@jotforminc/picker-modal';

export const ScPickerModal = Styled(PickerModal)`
  .header-content {
    margin: 20px 0;
    font-family: Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

    .pm-t {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.15px;
      color: #141E46;
    }

    .pm-d {
      margin: 0.25em 0 0;
      font-size: 16px;
      letter-spacing: 0.12px;
      color: #60658C;
    }
  }

  .close-modal {
    margin-right: 24px;
  }

  .pm-b {
    max-width: 600px;
    width: 90%;
    min-height: 570px;

    [data-sc="smartList-controlBar"] {
      .cb-body {
        display: none;
      }
      .cb-searchCont {
        flex: 0 0 100%;
      }
    }

    .emptyList {
      text-align: center;
      min-height: 60vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  [data-sc='footer'] {
    background-color: rgba(239,239,246,.45);
  }

  .pm-f {
    display: flex;
    justify-content: space-between;

    button {
      font-weight: 500;
      font-family: Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }

    .taste.isGray {
      padding: 8px 32px;
    }

    .taste.isGreen {
      background-color: #01BD6F;
      padding: 0px 32px;

      &:hover {
        background-color: #01d67e;
      }
      &[disabled] {
        opacity: 1;
        background-color: #78D6AF;
      }
    }
  }

  .js-ConfirmationModal {
    font-family: inherit;
  }

  &.autoHeight {
    .pm-b {
      min-height: auto;

      ul {
        overflow-y: auto;

        li:last-child {
          margin-bottom: 28px;
        }
      }
    }
  }

  @media screen and (min-width: 480px) {
    [data-sc="picker-body"] {
      max-height: 60vh;
    }
  }
`;
