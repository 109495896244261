import {
  call, select, all
} from 'redux-saga/effects';

import * as API from '../../api';
import { SELECTORS } from '../../store/selectors';
import { DEFAULT_FOLDER_IDS } from '../../constants';

function* fetchSummary() {
  try {
    const resp = yield call(API.getSummary);
    return resp;
  } catch (err) {
    return {};
  }
}

export function* getAssignedFolderSummary(folderInfo) {
  const [
    user,
    {
      draftCount = 0,
      assignedFormsCount = 0,
      sharedFormResourcesCount = 0
    }
  ] = yield all([
    select(SELECTORS.getUser),
    call(fetchSummary)
  ]);

  const { selectedFolder } = folderInfo;
  const newFolderList = folderInfo.folderList.map(folder => {
    const isSelectedFolder = folder.id === selectedFolder;

    if (isSelectedFolder) {
      return folder;
    }

    switch (folder.id) {
      case DEFAULT_FOLDER_IDS.DRAFTS:
        return draftCount > 0 && ({
          ...folder,
          count: draftCount,
          badge: draftCount || ''
        });
      case DEFAULT_FOLDER_IDS.ASSIGNED:
        return assignedFormsCount > 0 && ({
          ...folder,
          count: assignedFormsCount
        });
      case DEFAULT_FOLDER_IDS.SHARED_WITH_ME:
        return sharedFormResourcesCount > 0 && ({
          ...folder,
          count: user.type === 'FORM_USER' ? sharedFormResourcesCount : 0
        });
      default:
        return folder;
    }
  }).filter(f => f);

  return {
    ...folderInfo,
    folderList: newFolderList
  };
}
