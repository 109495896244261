/* eslint-disable max-len */
import styled from 'styled-components';

export const TeamNoAccess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 74px);
  background: #FFFFFF;

  .isMyaccount & {
    margin-top: -10px;
    border-top: 2px solid #E4E8F2;
  }

  .icon {
    width: 96px;
    height: 96px;
    background-color: rgba(255, 182, 41, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  h1 { 
    font-size: 32px;
    font-weight: 700;
    color: #0A1551;
    margin: 12px 0;
  }
  
  p {
    font-size: 16px;
    line-height: 36px;
    color: #0A1551;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    gap: 12px;

    .btn {
      padding: 8px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;
      height: 100%;   
      display: inline-flex;
      justify-content: center;
    }

    .primary {
      background-color: #4573E3;
      color: #FFF;
      min-width: 164px;
    }

    .secondary {
      border: 1px solid #D8DAE9;
      color: #2C3345;
      min-width: 210px;
    }
  }
`;
