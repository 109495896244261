/* eslint-disable react/require-default-props */
import React, {
  ReactNode, forwardRef, FunctionComponent, useRef
} from 'react';
import debounce from 'lodash/debounce';
import { ImageUpload } from '@jotforminc/image-upload';
import { t } from '@jotforminc/translation';
import { ColorPicker as UIKitColorPicker, Popover } from '@jotforminc/uikit';
import ColorRenderer from './ColorRenderer';
import { BG_IMAGE_SET } from './constants';
import { TeamProps } from '../../types';
import { handleUpdateTeamProps } from './utils';
import { CustomizationWrapper } from './StyledElements';
import { ImageUploadRefType } from './types';

type Props = {
  teamId?: string,
  coverBgColor?: string,
  coverTitleColor?: string,
  coverBgImageURL?: string,
  buttonRef: ReactNode,
  setTeam?: (f: (TeamProps | ((currTeam: TeamProps) => TeamProps))) => void,
  onUpdate?: (newData: TeamProps) => void,
  useOnUpdateProp?: boolean
};

const CustomizeCover = forwardRef<FunctionComponent, Props>(({
  teamId,
  coverBgColor,
  coverTitleColor,
  coverBgImageURL,
  buttonRef,
  onUpdate = f => f,
  setTeam = f => f,
  useOnUpdateProp
}, ref) => {
  const imageUploadRef = useRef<ImageUploadRefType>(null);
  const update = (newData: TeamProps) => (useOnUpdateProp ? onUpdate(newData) : handleUpdateTeamProps(teamId as string, newData, onUpdate, setTeam));

  const handleResetSelectedImage = () => {
    if (imageUploadRef?.current?.setSelectedImage) {
      imageUploadRef.current.setSelectedImage('');
    }
  };

  const removeHeightParam = (url: string) => {
    const heightParam = new RegExp(/(&h=80)$/, 'gm');
    return url.replace(heightParam, '');
  };

  const selectImage = (url: string) => update({ coverBgColor: '', coverBgImageURL: removeHeightParam(url), coverBgImageCropInfo: '' });
  const removeImage = () => {
    update({ coverBgImageURL: '' });
    handleResetSelectedImage();
  };

  const onLinkTextInputSubmit = (url?: string) => {
    if (!url) return;
    selectImage(url);
    handleResetSelectedImage();
  };

  return (
    <Popover
      ref={ref}
      targetRef={buttonRef}
      popoverOptions={{
        placement: 'bottom-start',
        modifiers: [
          { name: 'offset', options: { offset: [0, 5] } }
        ]
      }}
    >
      <CustomizationWrapper className="cover">
        <div className="heading withBorder" data-testid="cover-customization-header">
          {t('CUSTOMIZE COVER')}
        </div>
        <div className="content withBorder">
          <div className="double-wrapper midSize">
            <div className="content-wrapper col">
              <div className="content-heading">
                {t('Background Color')}
              </div>
              <div className="content-option content-option-colorpicker">
                <UIKitColorPicker
                  ColorRenderer={ColorRenderer}
                  pickerProps={{
                    disableAlpha: true
                  }}
                  value={coverBgColor}
                  onChange={debounce(val => update({ coverBgImageURL: '', coverBgColor: val }), 400)}
                  onInputRemove={() => update({ coverBgColor: '' })}
                />
              </div>
            </div>
            <div className="content-wrapper col">
              <div className="content-heading">
                {t('Title Color')}
              </div>
              <div className="content-option content-option-colorpicker">
                <UIKitColorPicker
                  ColorRenderer={ColorRenderer}
                  pickerProps={{
                    disableAlpha: true
                  }}
                  value={coverTitleColor}
                  onChange={debounce(val => update({ coverTitleColor: val }), 400)}
                  onInputRemove={() => update({ coverTitleColor: '' })}
                />
              </div>
            </div>
          </div>
          <div className="content-wrapper col">
            <div className="content-fileupload">
              <ImageUpload
                ref={imageUploadRef}
                renderTabs={['groupedGallery', 'upload', 'link', 'unsplash']}
                initialSelectedImage={coverBgImageURL}
                theme="isLight"
                groupedGalleryClass="galleryWrapper"
                groupedGalleryImageList={BG_IMAGE_SET}
                onImageUpload={file => {
                  selectImage(file?.url);
                  handleResetSelectedImage();
                }}
                onLinkTextInputSubmit={onLinkTextInputSubmit}
                onFileRemove={removeImage}
                onImageSelect={selectImage}
                onImageRemove={removeImage}
              />
            </div>
          </div>
        </div>
      </CustomizationWrapper>
    </Popover>
  );
});

CustomizeCover.defaultProps = {
  teamId: '',
  coverBgColor: '',
  coverTitleColor: '',
  coverBgImageURL: '',
  useOnUpdateProp: false
};

export default CustomizeCover;
