import {
  call, put, spawn, delay, select, take
} from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { handleCustomNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';
import Tracking from '@jotforminc/tracking';

import * as API from '../../api';
import { ACTION_CREATORS } from '../../store/actionCreators';
import { ACTION_TYPES } from '../../store/actionTypes';
import { SELECTORS } from '../../store/selectors';
import { registerUniqueAction } from '../utils';
import { ASSET_TYPES, DEFAULT_FOLDER_IDS } from '../../constants';
import { renameToastCall, showError, deleteToastCall } from '../../components/ListItem/toastForItemAction';
import { openCreateNewAgentModal } from '../../wizards/CreateNewAgentModal';
import WatchmanRecorder from '../../utils/WatchmanRecorder';
import ConfirmationDialog from '../../utils/Confirmation';
import openCloneAgentModal from '../../modals/CloneAgentModal';

const creationWizardChannel = channel();

function* watchCreationWizardChannel() {
  while (true) {
    const { fetchedForms, teamId, AIAgentBetaAccepted } = yield take(creationWizardChannel);
    if (AIAgentBetaAccepted) {
      yield put(ACTION_CREATORS.updateUserProperty({ AIAgentBetaAccepted }));
    } else {
      yield put(ACTION_CREATORS.fetchAllFormsWithPropsSuccess(fetchedForms, teamId));
    }
  }
}

export function* checkUserAgentLimitAndShowLimitModal() {
  let isAgentLimitExceeded = false;
  try {
    const response = yield call(API.getAgentlimitExceeded);
    if (typeof response === 'boolean' && response === true) {
      isAgentLimitExceeded = true;
    }
  } catch (error) {
    console.error('Agent limit error:', error);
  }

  if (isAgentLimitExceeded) {
    yield put(ACTION_CREATORS.openLimitDialog({ limitType: 'aiAgents', dispatchedFrom: 'listings-createAiAgent' }));
  }

  return isAgentLimitExceeded;
}

export function* handleCreateWizard({
  isSideBar, isSideBySideUser, createAssetFrom, targetText = '', isMixPageUser, closeButtonCallback, openWizardCallback
}) {
  try {
    if (yield call(checkUserAgentLimitAndShowLimitModal)) {
      if (closeButtonCallback) {
        closeButtonCallback();
      }
      return;
    }

    const fetchedUserForms = yield select(SELECTORS.getFetchedUserForms);
    const fetchedTeamForms = yield select(SELECTORS.getFetchedTeamForms);
    const isAIAgentBetaAccepted = yield select(SELECTORS.getUserBetaAcceptedAIAgent);
    const teamID = yield select(SELECTORS.getCurrentTeamID);
    const sessionUrl = Tracking ? Tracking.getCurrentSessionURL() : '';
    const isMobileDevice = yield select(SELECTORS.getIsMobileDevice);
    const logAbTestAction = yield select(SELECTORS.getUserLogAbTestAction);

    const props = {
      fetchFormsCallback: teamID ? API.fetchTeamAllFormsWithAgentInfo : API.fetchAllFormsWithAgentInfo,
      fetchFormsSuccess: (forms, teamId) => {
        creationWizardChannel.put({ fetchedForms: forms, teamId });
      },
      fetchedForms: teamID ? fetchedTeamForms[teamID] : fetchedUserForms,
      createAgent: async formList => {
        try {
          const response = await API.createAgent(formList);
          if (response?.uuid) {
            formList.forEach(async formID => {
              await API.updateFormItem(formID, { isAIAgentCreated: '1' }); // it is a temporary solution
            });
            handleCustomNavigation(`/agent/build/${response.uuid}`, '_self');
          }
        } catch (err) {
          console.log(err);
        }
      },
      creationLogger: actionEvent => {
        WatchmanRecorder.trackEvent('click', `create-agent-button${isSideBar ? '-sideBar' : ''}-${actionEvent}-agent-created`, 'agents', true);
        if (createAssetFrom && logAbTestAction) {
          logAbTestAction({ action: 'click', target: `${isMobileDevice ? 'mobile-' : ''}${isSideBar ? 'sideBar-' : ''}${targetText}${createAssetFrom}-${actionEvent}-agent-created` });
          logAbTestAction({ action: 'click', target: 'actTest-agentCreated' });
        }
      },
      isAIAgentBetaAccepted,
      teamID,
      closeButtonCallback,
      openWizardCallback,
      updateUserAIAgentBetaAccepted: () => {
        creationWizardChannel.put({ AIAgentBetaAccepted: '1' });
      },
      sessionUrl,
      isMixAssetCreationModal: isMixPageUser || isSideBySideUser,
      backToMixModalActionLogger: () => {
        WatchmanRecorder.trackEventForCustomProject(
          'click',
          `${isMobileDevice ? 'mobile-' : ''}back-to-mix-modal-from-create-agents`,
          isSideBySideUser ? 'sideBySideModal' : 'mixAssetCreationModal',
          true
        );
      }
    };
    yield call(openCreateNewAgentModal, { ...props });
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleOnDeleteMultipleAgents() {
  try {
    const deletedItemList = yield select(SELECTORS.getSelectedItemsWithInfo);
    const selectedIDList = yield select(SELECTORS.getSelectedItemIDs);
    const result = yield call(API.deleteMultipleAgents, selectedIDList);
    if (!result) {
      yield put(ACTION_CREATORS.onDeleteMultipleAgentsError());
      return;
    }
    yield put(ACTION_CREATORS.onDeleteMultipleAgentsSuccess(deletedItemList));
  } catch (e) {
    showError();
    yield put(ACTION_CREATORS.onDeleteMultipleAgentsError());
  }
}

export function* handleCloneAgent({ id }) {
  try {
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;

    if (yield call(checkUserAgentLimitAndShowLimitModal)) {
      return false;
    }

    const response = yield call(openCloneAgentModal, { id, agent });

    if (response?.newAgentID) {
      handleCustomNavigation(`/agent/build/${response.newAgentID}`, '_blank');
      yield put(ACTION_CREATORS.fetchListRequest());
      yield put(ACTION_CREATORS.cloneAgentSuccess(id, ASSET_TYPES.AGENT));
      yield put(ACTION_CREATORS.selectItem(response.newAgentID, true, ASSET_TYPES.AGENT));
    }
  } catch (err) {
    console.log('error:', err);
  }
}

export function* handleDeleteAgent(value) {
  try {
    const { id } = value;
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;
    yield call(API.updateAgent, id, { status: 'DELETED' });
    yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.AGENT));
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleAgentFavorite(value) {
  try {
    const { id } = value;
    yield delay(500);
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;

    const { favorite } = agent;
    yield call(API.updateAgent, id, { favorite: favorite === '1' ? 1 : 0 });
  } catch (e) {
    console.log('error', e);
  }
}

export function* handleRenameAgent({ id, props }) {
  try {
    yield call(API.updateAgent, id, props);
    yield call(renameToastCall);
    yield put(ACTION_CREATORS.renameAgentSuccess(id, props, ASSET_TYPES.AGENT));
  } catch (e) {
    yield call(showError);
    yield put(ACTION_CREATORS.renameAgentError(id, ASSET_TYPES.AGENT));
  }
}

export function* handleArchiveAgent({ id, archive }) {
  try {
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;
    yield call(API.updateAgent, id, { status: archive ? 'ARCHIVED' : 'ACTIVE' });
    if (archive) {
      yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.AGENT));
      yield put(ACTION_CREATORS.setDeletedItems([{ id, assetType: ASSET_TYPES.AGENT }]));
    }
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleRestoreAgent({ id, refetchList }) {
  try {
    // const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT)); // TODO: it can not select, i will check
    // if (!agent) return false;
    yield call(API.updateAgent, id, { status: 'ACTIVE' });
    refetchList();
  } catch (e) {
    console.log('error:', e);
  }
}

export function* handleEnableAgent({ id }) {
  try {
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;
    const selectedFolder = yield select(SELECTORS.getSelectedFolder);
    yield call(API.updateAgent, id, { status: 'ACTIVE' });
    yield put(ACTION_CREATORS.enableAgentSuccess(id, { status: 'ACTIVE', isDisabled: false }, ASSET_TYPES.AGENT));
    if (selectedFolder === DEFAULT_FOLDER_IDS.ARCHIVE) {
      yield put(ACTION_CREATORS.fetchListRequest());
    }
  } catch (e) {
    yield call(showError);
    yield put(ACTION_CREATORS.enableAgentError(id, ASSET_TYPES.AGENT));
  }
}

export function* handleDisableAgent({ id }) {
  try {
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;
    const selectedFolder = yield select(SELECTORS.getSelectedFolder);
    yield call(API.updateAgent, id, { status: 'DISABLED' });
    yield put(ACTION_CREATORS.disableAgentSuccess(id, { status: 'DISABLED', isDisabled: true }, ASSET_TYPES.AGENT));
    if (selectedFolder === DEFAULT_FOLDER_IDS.ARCHIVE) {
      yield put(ACTION_CREATORS.fetchListRequest());
    }
  } catch (e) {
    yield call(showError);
    yield put(ACTION_CREATORS.disableAgentError(id, ASSET_TYPES.AGENT));
  }
}

export function* handlePurgeAgent({ id }) {
  try {
    const agent = yield select(SELECTORS.getItemByIDAndType(id, ASSET_TYPES.AGENT));
    if (!agent) return false;

    yield call(ConfirmationDialog, {
      title: t('Delete Agent'),
      description: t('Are you sure you want to delete selected agent?'),
      content: t('This agent will be deleted permanently. This action cannot be undone!')
    });

    yield call(API.purgeAgent, id);
    yield call(deleteToastCall, id);
    yield put(ACTION_CREATORS.deleteItemSuccess(id, ASSET_TYPES.AGENT));
  } catch (err) {
    console.log(err);
    // Modal closed
  }
}

export function* rootAgentFlow() {
  yield spawn(registerUniqueAction, ACTION_TYPES.CREATE_WIZARD, handleCreateWizard);
  yield spawn(registerUniqueAction, ACTION_TYPES.SET_FAVORITE, handleAgentFavorite);
  yield spawn(registerUniqueAction, ACTION_TYPES.DELETE_AGENT.REQUEST, handleDeleteAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.CLONE_AGENT.REQUEST, handleCloneAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.RENAME_AGENT.REQUEST, handleRenameAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.ARCHIVE_AGENT.REQUEST, handleArchiveAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.RESTORE_AGENT.REQUEST, handleRestoreAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.ENABLE_AGENT.REQUEST, handleEnableAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.DISABLE_AGENT.REQUEST, handleDisableAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.PURGE_ITEM, handlePurgeAgent);
  yield spawn(registerUniqueAction, ACTION_TYPES.ON_DELETE_MULTIPLE_AGENTS.REQUEST, handleOnDeleteMultipleAgents);
  yield spawn(watchCreationWizardChannel);
}
