import React from 'react';
import { createRoot } from 'react-dom/client';
import { Modal as UIKitModal } from '@jotforminc/uikit';

const root = document.getElementById('modal-container');

export const openModal = props => {
  const rootNode = createRoot(root);

  return new Promise((resolve, reject) => {
    const handleClose = () => {
      reject();
      // document.removeEventListener('keydown', handleDocumentKeyDown, { capture: true });
      rootNode.unmount();
    };
    const handleConfirm = value => {
      resolve(value);
      // document.removeEventListener('keydown', handleDocumentKeyDown, { capture: true });
      rootNode.unmount();
    };
    const { ContentRenderer } = props;
    rootNode.render(
      <UIKitModal
        onModalClose={handleClose}
        DialogRenderer={({ children }) => (
          <div style={{
            position: 'fixed',
            zIndex: 1001,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: 'rgba(37, 45, 91, 0.7)'
          }}
          >
            {children}
          </div>
        )}
        {...props}
        ContentRenderer={() => <ContentRenderer handleConfirm={handleConfirm} handleClose={handleClose} />}
      />
    );
  });
};
