/* eslint-disable max-len */
import { Button } from '@jotforminc/uikit';
import styled from 'styled-components';

export const ScButton = styled(Button)`
  &.bare {
    font-size: .875em;
    color: #4573e3;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    padding: 0;
    border: 0;
  }

  &.taste {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5em .75em;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    border: 0;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 500;
    font-family: inherit;
  }

  &.panel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    text-transform: capitalize;
    border: 0;
    padding: 6px;
    margin: 0;
    font-size: 15px;
    font-family: Roboto,Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    transition: .3s background-color ease;
  }

  &.panel.isGreen {
    background-color: #4BC073;
    color: #fff;

    &:hover {
      background-color: #65DA8D;
    }

    &:active {
      background-color: #32A75A;
    }
  }

  &.taste.isGreen {
    background-color: #54b45c;
    color: #fff;
  }


  &.taste.isPurple {
    background-color: #8563ce;
    color: #fff;
  }

  &.taste.isRed {
    background-color: #ff4947;
    color: #fff;
  }

  &.taste.isGray {
    background-color: #FAFAFC;
    border: 1px solid #ADB4D2;
    color: #A0A6C3;

    &:hover {
      background-color: #fff;
      color: #9EA7CE;
      border-color: #9EA7CE;
    }

    &[disabled] {
      color: #A0A6C3;
      border-color: #A0A6C3;
      background-color: #FAFAFC;
    }
  }

  &.taste.isBlue, &.panel.isBlue {
    background-color: #0099FF;
    color: #fff;
    transition: .3s background-color ease;

    &:hover {
      background-color: #33adff;
    }

    &:active {
      background-color: #007acc;
    }
  }

  &.taste.isLightGreen {
    background-color: #78BB07;
    color: #fff;

    &:hover {
      background-color: #4EB100;
    }

    &:active {
      background-color: #5FA200;
    }
  }

  &.taste.isSubGray {
    background-color: #e9e9f2;
    color: #898ba9;

    &:hover {
      background-color: #F2F2FE;
    }

    &:active {
      background-color: #DEDEEC;
    }

    &[disabled] {
      &:hover,&:active {
        background-color: #e9e9f2;
      }
    }
  }

  &.taste.isWhite {
    background-color: #fff;
    color: #8E4FCD;
    transition: .3s background-color ease;
    border: 1px solid #8E4FCD;

    svg path {
      fill: #8E4FCD;
    }

    &:hover {
      background-color: #7735BA;
      color: #fff;

      svg path { fill: #fff; }
    }

    &:active {
      background-color: #6722AC;
      color: #fff;

      svg path { fill: #fff; }
    }
  }


  &.taste.quickQR {
    border-radius: 4px;
    & > svg {
      & > path { fill: #fff }
    }

    @media screen and (max-width: 480px) {
      border-radius: 29px;
    }
  }

  &.taste.quickQR {
    margin: 0 20px;
    font-weight: 500;
    font-size: 14px;
    font-family: inherit;
    transition: .3s background-color ease;
    text-transform: none;

    & > svg { margin-right: 7px;}

    @media screen and (max-width: 480px) {
      padding: 11px;
      margin: 0;

      & > span { display: none; }
      & > svg {
        margin-right: 0;
        & > path { fill: #fff }
      }
    }
  }

  &.taste.quickQR {
    background-color: #0a1551;

    &:hover {
      background-color: #252d5b;
    }
  }

  &.taste.quickQR.isSelected {
    background-color: #091141;
  }

  &.taste.undoRedo {
    margin: 0 2px;
    padding: 12px 17px;
    font-size: 14px;
    font-family: inherit;
    transition: .3s background-color ease;
    text-transform: none;
    font-weight: 400;

    & > svg { margin-right: 11px;}
    & > span { color: #23283A; line-height: 16px; }

    @media screen and (max-width: 768px) {
      padding: 12px 10px;
      & > span { display: none; }
      & > svg { margin-right: 0; }
    }

    @media screen and (max-width: 480px) {
      padding: 12px 10px;
      & > span { display: none; }
      & > svg { margin-right: 0; }
    }

    &[disabled] {
      & > svg > path { fill: #979797;}
      & > span { color: #898BA9; }
    }
  }

  &[disabled] { opacity: .6; cursor: initial; }


  &.outline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .5em 1em;
    border-radius: 2px;
    cursor: pointer;
    background: transparent;
    border: 1px solid currentColor;
    font-size: .875em;
    line-height: 1rem;
    font-weight: 500;
  }

  &.outline.isRed {
    color: #ff4947;
  }

  &.outline.removeForms { padding: .625em; }
  &.outline.removeForms span { margin-left: .25rem; }

  &.goBack {
    font-size: .875em;
    line-height: 1;
    color: #90a4ae;
    padding: 0 1em 1em 0;

    & > * { vertical-align: middle; }
    svg { width: 18px; margin-right: 5px; }
  }

  /*
  &.forRevoke {
    font-size: .875em;
    color: #f9535c;
    margin: 0 .5rem 0 auto;
    padding: .5em 1em .5em 1.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='15' viewBox='0 0 11 15'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23F9535C'%3E%3Cpath d='M8.834 3.252v9.037c0 .834-.58 1.445-1.375 1.446-1.757.002-3.514.002-5.271 0-.795-.001-1.374-.612-1.374-1.447V3.252h8.02zM2.828 5.345v6.292h1.137c.166 0 .187 0 .19-.138V5.548c0-.037.01-.079-.003-.108-.018-.037-.056-.09-.085-.09-.41-.007-.82-.005-1.239-.005zm3.99 0H5.686c-.192 0-.193 0-.193.202v6.08H6.82V5.345zM0 2.414V.819h.184C.992.82 1.8.823 2.607.814c.08 0 .177-.05.238-.112.178-.187.339-.396.515-.585.05-.054.13-.097.198-.097.843-.007 1.687-.007 2.53 0 .067 0 .148.043.198.097.177.19.337.399.515.585.061.063.158.112.238.112.803.009 1.605.005 2.408.005h.195v1.595H0z' transform='translate(-3 -1) translate(3.542 1.417)'/%3E%3C/g%3E%3Cpath d='M0 0L17 0 17 17 0 17z' transform='translate(-3 -1)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 11px 15px;
    background-position: 8px 7px;
  }
  */

  @media screen and (max-width: 480px) {
    &.outline.removeForms span { display: none; }
    &.cb-addForm {
      margin-left: 0.5em;
      span { display: none; }
      svg { margin-right: 0 !important; }
    }
  }
`;
