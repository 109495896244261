import React, { useRef, useState, useEffect } from 'react';
import {
  arrayOf, string, bool, shape, func
} from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { NavigationWrapper as Navigation, appList as defaultAppList, getDefaultNavItems } from '@jotforminc/header-components';
import layer from '../../utils/layer';
import Wrapper from './styled';

const AppPicker = ({
  appList,
  currentApp,
  teamID,
  teams,
  openCreateTeamModal,
  showTeamSection,
  isTeamPage,
  showBackToMyForms,
  hideIfEmpty,
  form,
  hideSummary,
  user,
  darkMode,
  isInSsrHeader,
  isInListings,
  isTeamCreationDisabled,
  isTeamBrowsingDisabled,
  backToMyFormsText,
  backToMyFormsLink
}) => {
  const branding21 = true;
  const ref = useRef(false);
  const [isPickerVisible, setPickerVisibility] = Hooks.useClickOutsideState(false, [ref]);
  const togglePickerVisibility = () => setPickerVisibility(!isPickerVisible);

  const [summary, setSummary] = useState({});

  const loadSummary = () => layer.get(`listings/listing/summary?apps=${appList}`).then(setSummary);

  useEffect(() => {
    if (!hideSummary && isPickerVisible) {
      // This endpoint creates a huge load on meta db, don't open it by default.
      // Ask to Berkay
      loadSummary();
    }
  }, [hideSummary, JSON.stringify(appList), isPickerVisible]);

  const navigationItems = getDefaultNavItems(branding21);

  return (
    <Wrapper ref={ref} className={isPickerVisible ? `${branding21 ? 'branding21 ' : ''}appBoxOpened` : ''}>
      <Navigation
        form={form}
        summary={summary}
        appList={appList}
        showBackToMyForms={showBackToMyForms}
        appName={currentApp}
        appConfig={navigationItems[currentApp]}
        isNavigationOpened={isPickerVisible}
        toggleNavigation={togglePickerVisibility}
        hideIfEmpty={hideIfEmpty}
        user={user}
        teamID={teamID}
        teams={teams}
        openCreateTeamModal={openCreateTeamModal}
        showTeamSection={showTeamSection}
        isTeamPage={isTeamPage}
        title={isTeamPage ? teams.find(team => team.id === teamID)?.name : ''}
        darkMode={darkMode}
        isInSsrHeader={isInSsrHeader}
        isInListings={isInListings}
        isTeamCreationDisabled={isTeamCreationDisabled}
        isTeamBrowsingDisabled={isTeamBrowsingDisabled}
        backToMyFormsText={backToMyFormsText}
        backToMyFormsLink={backToMyFormsLink}
      />
    </Wrapper>
  );
};

AppPicker.propTypes = {
  appList: arrayOf(string),
  teamID: string,
  teams: arrayOf(shape({})),
  openCreateTeamModal: func,
  showTeamSection: bool,
  isTeamPage: bool,
  showBackToMyForms: bool,
  currentApp: string,
  hideIfEmpty: bool,
  hideSummary: bool,
  form: shape({}),
  user: shape({}).isRequired,
  darkMode: bool,
  isInSsrHeader: bool,
  isInListings: bool,
  isTeamCreationDisabled: bool,
  isTeamBrowsingDisabled: bool,
  backToMyFormsText: string,
  backToMyFormsLink: string
};

AppPicker.defaultProps = {
  currentApp: 'myForms',
  showBackToMyForms: true,
  teamID: '',
  teams: [],
  openCreateTeamModal: () => {},
  showTeamSection: false,
  isTeamPage: false,
  appList: defaultAppList,
  hideIfEmpty: false,
  hideSummary: false,
  form: {},
  darkMode: false,
  isInSsrHeader: false,
  isInListings: false,
  isTeamCreationDisabled: false,
  isTeamBrowsingDisabled: false,
  backToMyFormsLink: '/myforms',
  backToMyFormsText: 'Go Back to My Forms'
};

export default AppPicker;
