import { createRoot } from 'react-dom/client';
import React from 'react';
import { t, translationRenderer } from '@jotforminc/translation';
import { string } from 'prop-types';
import './styles/announcing-jotform-enterprise.scss';
import Image from './assets/campaigns/2023/announcing-jotform-enterprise/left-image.png';

export const AnnouncingJotformEnterprise = ({ utmSource, accountTypeName }) => {
  return (
    <div className="announcing-jotform-enterprise-wrapper">
      <div className='announcing-jotform-enterprise-container'>
        <div className="image">
          <img src={Image} alt="" />
          <div className="animated-images">
            <img className='photo' src="https://cdn01.jotfor.ms/assets/img/campaigns/2023/enterprise/flyin/icon-gear.png" alt="" />
            <img className='photo' src="https://cdn01.jotfor.ms/assets/img/campaigns/2023/enterprise/flyin/icon-user.png" alt="" />
            <img className='photo' src="https://cdn01.jotfor.ms/assets/img/campaigns/2023/enterprise/flyin/icon-doc.png" alt="" />
          </div>
        </div>
        <div className='announcing-jotform-enterprise-heading-container'>
          <div className='heading'>
            <h5 className='title'>Announcing</h5>
            <h6 className='subtitle'>
              {translationRenderer('[1[ Jotform Enterprise]] [2[Professional Services]]')({
                renderer1: title1 => <strong className="first-title">{title1}</strong>,
                renderer2: title2 => <strong className="second-title">{title2}</strong>
              })}
            </h6>
          </div>
          <a className="discover-button locale" href={`/enterprise/professional-services/?utm_campaign=proser2023&utm_source=${utmSource}& utm_content=${accountTypeName}&utm_medium=myforms-header`}>
            {t('Discover Now')}
          </a>
        </div>
      </div>
    </div>
  );
};

AnnouncingJotformEnterprise.propTypes = {
  utmSource: string.isRequired,
  // expirationDate: number.isRequired,
  accountTypeName: string.isRequired
};

export const renderAnnouncingJotformEnterprise = ({
  container, expirationDate, accountTypeName, utmSource
}) => {
  const root = createRoot(container);
  root.render(<AnnouncingJotformEnterprise utmSource={utmSource} expirationDate={expirationDate} accountTypeName={accountTypeName} />);
};
