import { ABTestManager } from '@jotforminc/abtest-manager';

// A/B Test: urlActionCreateWizVsTempSugModalOnMyApps
export const initUrlActionCreateWizVsTempSugModalOnMyApps = async user => {
  let isTestVariant = false;
  let logAbTestAction = f => f;

  const VARIANT_MAP = {
    CONTROL: '22511',
    TEST: '22521'
  };

  const searchParams = new URLSearchParams(window.location?.search);

  const abTestManager = new ABTestManager({
    user: user,
    isTestEnabled: false,
    testName: 'urlActionCreateWizVsTempSugModalOnMyApps',
    controlVariantCode: VARIANT_MAP.CONTROL,
    testVariantCode: VARIANT_MAP.TEST,
    urlParam: 'tsmfa',
    cacheVariantCodeAtLocalStorage: true,
    customUserChecks: {
      isNotFromStoreLanding: !searchParams.has('fromLandingStore'),
      isNotFromDonationLanding: !searchParams.has('fromLandingDonation')
    }
  });

  try {
    isTestVariant = await abTestManager.isTestVariant();
    logAbTestAction = abTestManager.registerABTestAction;
  } catch (e) {
    isTestVariant = false;
  }

  return { isTestVariant, logAbTestAction };
};
