/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  arrayOf, shape, string, func, bool, oneOfType, number
} from 'prop-types';
import classNames from 'classnames';
import withScrolling from 'react-dnd-scrolling';
import { Loader } from '@jotforminc/enterprise-components';
import Nestable from 'react-nestable';
import { useDispatch } from 'react-redux';
import {
  FOLDER_DROP_POSITIONS,
  FOLDER_TYPES, getDefaultFolders, LISTING_TYPES, DEFAULT_FOLDER_IDS
} from '../../constants';
import TeamFolder from './TeamFolder';
import { ACTION_CREATORS } from '../../store/actionCreators';
import ShowMoreLessButton from '../Folder/ShowMoreLessButton';
import { childrenToSubfolders, subfoldersToChildren } from '../../utils';

const FolderWrapper = ({ children, ...props }) => <ul {...props}>{children}</ul>;

const INITIAL_VISIBLE_FOLDER_COUNT = 5;

const checkIsSelectedFolderVisible = (folderList, folderId) => {
  return !!folderList.find(folder => {
    if (folder.id === folderId) return true;

    if (folder.subfolders) return checkIsSelectedFolderVisible(folder.subfolders, folderId);

    return false;
  });
};

const getVisibleFolderList = allList => allList.slice(0, INITIAL_VISIBLE_FOLDER_COUNT);

const TeamFolderList = ({
  folders = getDefaultFolders(LISTING_TYPES.ASSIGNED_FORM), className = '', selectedFolderID = '', handleClick = f => f, onDrop = f => f, isDroppable = false,
  hasContextMenu: hasContextMenuFolderList = false, user = {}, isTeamCreationDisabled = false, teamPermissions = {}, isReorderTeamsAllowed = false,
  isRootFolder = false, folderId = '', teamFolderMenuItems = [], selectedFolder = {}, currentTeamID = ''
}) => {
  const dispatch = useDispatch();
  const filteredFolderList = folders.filter(f => f);
  const filteredFoldersWithoutSeperators = folders.filter(item => item && item?.itemType !== 'listingSeperator');
  const disableDraggingAction = !isReorderTeamsAllowed;
  const classes = classNames(
    'lsApp-sidebar-folder',
    { hasNoFolders: !filteredFoldersWithoutSeperators.length },
    { disableDraggingAction },
    className
  );
  const hasShowMoreLessButtons = folderId === DEFAULT_FOLDER_IDS.TEAM_FOLDER_GROUP;
  const [isAllFoldersVisible, setIsAllFoldersVisible] = useState(!hasShowMoreLessButtons);
  const [isSelectedFolderVisibilityChecked, setIsSelectedFolderVisibilityChecked] = useState(false);

  useEffect(() => {
    if (hasShowMoreLessButtons && selectedFolderID && !isSelectedFolderVisibilityChecked) {
      setIsSelectedFolderVisibilityChecked(true);
      const { folderType } = selectedFolder || {};
      const checkSelectedVisibility = [FOLDER_TYPES.TEAM, FOLDER_TYPES.TEAM_FOLDER].indexOf(folderType) > -1;

      if (checkSelectedVisibility && !checkIsSelectedFolderVisible(getVisibleFolderList(filteredFolderList), selectedFolderID)) {
        setIsAllFoldersVisible(true);
      }
    }
  }, [selectedFolderID]);

  const folderList = !isAllFoldersVisible ? getVisibleFolderList(filteredFolderList) : filteredFolderList;

  const WrapperRenderer = useMemo(() => {
    return (isRootFolder ? withScrolling('ul') : FolderWrapper);
  }, [isRootFolder]);

  const handleReorderTeams = ({ dragItem, items, targetPath }) => {
    if (dragItem.folderType === FOLDER_TYPES.TEAM) {
      const newSubfolders = childrenToSubfolders(items);
      const order = targetPath?.pop();
      let position = FOLDER_DROP_POSITIONS.BEFORE;
      let referenceTeamID = '';

      if (order === 0) {
        position = FOLDER_DROP_POSITIONS.BEFORE;
        referenceTeamID = newSubfolders[order + 1].id;
      } else {
        position = FOLDER_DROP_POSITIONS.AFTER;
        referenceTeamID = newSubfolders[order - 1].id;
      }
      dispatch(ACTION_CREATORS.reorderTeams({
        position,
        teamID: dragItem.id,
        referenceTeamID
      }));
    } else {
      dispatch(ACTION_CREATORS.reorderTeamSubfolders({
        teamID: dragItem.team_id, folderId: dragItem.id, targetPath, newSubfolders: childrenToSubfolders(items)
      }));
    }
  };

  const NestableFolderRenderer = folder => {
    const {
      id, subfolders = [], hasContextMenu = hasContextMenuFolderList, isDroppable: isDroppableFolder = isDroppable, isTeamDroppable, canReorderSubfolders
    } = folder;

    const { canDrag = isDroppableFolder } = folder;

    return (
      <TeamFolder
        {...folder}
        key={id}
        handleClick={handleClick}
        selectedFolderID={selectedFolderID}
        canDrag={canDrag}
        onDrop={onDrop}
        isDroppable={isDroppableFolder}
        isTeamDroppable={isTeamDroppable}
        hasContextMenu={hasContextMenu}
        user={user}
        isTeamCreationDisabled={isTeamCreationDisabled}
        teamPermissions={teamPermissions}
        teamFolderMenuItems={teamFolderMenuItems}
        currentTeamID={currentTeamID}
        canReorderSubfolders={canReorderSubfolders}
      >
        <TeamFolderList
          folders={subfolders}
          selectedFolderID={selectedFolderID}
          handleClick={handleClick}
          isDroppable={isDroppable}
          onDrop={onDrop}
          hasContextMenu={hasContextMenu}
          user={user}
          isTeamCreationDisabled={isTeamCreationDisabled}
          teamPermissions={teamPermissions}
          isReorderTeamsAllowed={isReorderTeamsAllowed}
          teamFolderMenuItems={teamFolderMenuItems}
          currentTeamID={currentTeamID}
        />
      </TeamFolder>
    );
  };

  const isTeamFolder = !!folderList.find(folder => folder.folderType === FOLDER_TYPES.TEAM);
  const teamSubFolder = folderList.find(folder => folder.folderType === FOLDER_TYPES.TEAM_FOLDER);

  return (
    <>
      {(disableDraggingAction && [DEFAULT_FOLDER_IDS.TEAM_FOLDER_GROUP, DEFAULT_FOLDER_IDS.TEAM_PAGE_ALL_FOLDER].indexOf(folderId) > -1) ? (
        <Loader withAnimation={true} teamAnimation={true} />
      ) : null}
      <WrapperRenderer className={classes}>
        {isTeamFolder || !!teamSubFolder
          ? (
            <Nestable
              items={isTeamFolder ? folderList : subfoldersToChildren(folderList)}
              renderItem={({ item }) => NestableFolderRenderer(item)}
              onChange={handleReorderTeams}
              confirmChange={({ dragItem, destinationParent }) => {
                if (!dragItem?.canReorderSubfolders && dragItem?.folderType === FOLDER_TYPES.TEAM_FOLDER) {
                  return false;
                }
                if (destinationParent?.id && destinationParent?.folderType) {
                  dispatch(ACTION_CREATORS.addExpandedFolders({ id: destinationParent.id, folderType: destinationParent.folderType }));
                }
                return true;
              }}
              maxDepth={isTeamFolder ? 0 : 100}
              group={isTeamFolder ? 'teamFolder' : 'teamSubFolder'}
              className={teamSubFolder && !teamSubFolder.canReorderSubfolders ? 'preventTeamSubfolderReorder' : ''}
            />
          )
          : folderList.map(NestableFolderRenderer)}
      </WrapperRenderer>
      {hasShowMoreLessButtons && filteredFolderList.length > INITIAL_VISIBLE_FOLDER_COUNT ? (
        <ShowMoreLessButton
          isAllFoldersVisible={isAllFoldersVisible}
          setIsAllFoldersVisible={setIsAllFoldersVisible}
          isUserFoldersGroup={false}
        />
      ) : null}
    </>
  );
};

TeamFolderList.propTypes = {
  folders: arrayOf(shape()),
  selectedFolderID: string,
  handleClick: func,
  isDroppable: bool,
  hasContextMenu: bool,
  onDrop: func,
  className: string,
  user: shape({}),
  isTeamCreationDisabled: bool,
  teamPermissions: shape({}),
  isReorderTeamsAllowed: bool,
  isRootFolder: bool,
  folderId: string,
  teamFolderMenuItems: arrayOf(shape()),
  selectedFolder: shape({}),
  currentTeamID: oneOfType([string, number])
};

export default TeamFolderList;
