/* eslint-disable react/prop-types */
import React, {
  forwardRef, useMemo, useRef, useState
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { SelectionGroup, Hooks } from '@jotforminc/uikit';
import { Button } from '@jotforminc/magnet';
import { IconUsersArrowRightFilled, IconPlusCircleFilled } from '@jotforminc/svg-icons';

import classNames from 'classnames';

import PopoverMenu from '../../PopoverMenu';
import PopoverOption from '../../PopoverOption';
import PopoverText from '../../PopoverText';
import Image from '../../Image';

import translate from '../../../utils/translate';

import { SELECTORS } from '../../../store/selectors';
import { ACTION_CREATORS } from '../../../store/actionCreators';
import WatchmanRecorder from '../../../utils/WatchmanRecorder';
import OptionTextRenderer from './OptionTextRenderer';
import { POPOVER_PROPS } from './constants';

const MoveToTeamWrapper = styled.div`
  div.listings-popover-more-option {
    &:hover {
      &:before {
        background-color: #474E7C;
      }
    }
    &[data-selected="true"] {
      &:before {
        background-color: #474E7C;
        opacity: 1;
      }
    }
  }

  .option {
    justify-content: center;
    align-items: center;
    display: flex;

    .option-cmp {
      flex: 0 1;

      .sb-customIcon {
        border: .6px solid #FFFFFF;
      }
    }
    .option-text {
      align-items: center;
    }
  }
  .option-icon {
    display: none;
  }
  .emoji-mart-emoji {
    text-align: center;
  }

  .isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const ButtonRenderer = forwardRef(({ className, ...buttonProps }, buttonRef) => (
  <Button
    data-testid="move-to-team-toolbar"
    {...buttonProps}
    ref={buttonRef}
    colorStyle="secondary"
    variant="outline"
    startIcon={IconUsersArrowRightFilled}
    // eslint-disable-next-line react/prop-types
    className={classNames('forMoveToTeamButton', className)}
  >
    {translate('Move to Team')}
  </Button>
));

const MoveToTeam = forwardRef((props, ref) => {
  const [selectedTeamID, setSelectedTeamID] = useState('');
  const flattenedFolders = useSelector(SELECTORS.getFlattenedFolders) || [];
  const selectedListItemIDs = useSelector(SELECTORS.getSelectedItemIDs);
  const isMenuOpen = useSelector(SELECTORS.getIsMoveToTeamMenuVisible);
  const dispatch = useDispatch();
  const menuRef = useRef();
  const combinedRef = Hooks.useCombinedRefs(ref, menuRef);
  const createTeam = () => dispatch(ACTION_CREATORS.showCreateTeamModal(false, 'moveToDropdown'));
  const toggleMenuVisibility = isVisible => dispatch(ACTION_CREATORS.toggleMoveToTeamMenuVisibility(isVisible));

  const teamFolders = flattenedFolders.filter(folder => folder.folderType === 'team');
  const teamFoldersWithPermission = teamFolders.filter(folder => folder?.permissions?.moveTo);
  const hasFolders = teamFoldersWithPermission.length > 0;
  const teamOptions = hasFolders ? teamFoldersWithPermission.map(team => ({
    value: team.id,
    Renderer: PopoverOption,
    preventHideMenu: true,
    text: <OptionTextRenderer text={team.name} />,
    component: (
      <Image
        iconBgColor={team.teamAvatarIconBgColor}
        url={team.teamAvatarURL}
        icon={team.teamAvatarIcon}
        emojiId={team.teamAvatarEmojiId}
        emojiSize={14}
        iconColor={team.teamAvatarIconColor}
        iconSvgRef={team.teamAvatarIconSvgRef}
        hasCustomization={false}
      />
    )
  })) : [{
    disabled: true,
    hideOnDisable: false,
    Renderer: PopoverText,
    text: (
      <OptionTextRenderer
        className="noItems"
        text={translate(teamFolders.length ? `You don't have permission to move assets to your existing team${teamFolders.length > 1 ? 's' : ''}` : "You don't have any teams")}
      />
    )
  }];

  const onSelectionChange = items => {
    if (!Array.isArray(items)) {
      setSelectedTeamID(items);
    }
  };

  const onMoveToTeam = () => {
    if (selectedListItemIDs.length > 0 && selectedTeamID) {
      WatchmanRecorder.trackEventForCustomProject('cta_moveToTeam', selectedTeamID, 'teams', true);
      dispatch(ACTION_CREATORS.moveToTeamRequest(selectedTeamID, selectedListItemIDs));
    }
  };

  const OptionButtonRenderer = useMemo(() => (
    <MoveToTeamWrapper>
      <ul className="listings-popover-modal-item-actions">
        <li className="listings-popover-more-action">
          {hasFolders ? (
            <Button
              className="w-full"
              colorStyle="success"
              disabled={!selectedTeamID}
              onClick={onMoveToTeam}
            >
              {translate('Move to This Team')}
            </Button>
          ) : (
            <Button
              colorStyle="success"
              startIcon={IconPlusCircleFilled}
              className="w-full"
              onClick={createTeam}
            >
              {translate('Create team')}
            </Button>
          )}
        </li>
      </ul>
    </MoveToTeamWrapper>
  ), [hasFolders, selectedTeamID, selectedListItemIDs]);

  const ContainerRenderer = forwardRef(({ children, ...rest }, containerRef) => (
    <ul
      aria-label={translate('teams list')}
      role="listbox" className="listings-popover-modal-item-list" ref={containerRef}
      {...rest}
    >
      {children}
    </ul>
  ));

  const MenuRenderer = useMemo(() => menuProps => (
    <MoveToTeamWrapper>
      <SelectionGroup
        {...menuProps}
        ContainerRenderer={ContainerRenderer}
        OptionContainerRendererProps={{ tabIndex: 0 }}
      />
    </MoveToTeamWrapper>
  ), [selectedListItemIDs]);

  const handleMoveButtonClick = () => {
    WatchmanRecorder.trackEventForCustomProject('click', 'myformsToolbarMoveTo', 'teams', true);
    setSelectedTeamID('');
  };

  return (
    <PopoverMenu
      withoutChildren
      ref={combinedRef}
      options={teamOptions}
      MenuRenderer={MenuRenderer}
      popoverProps={POPOVER_PROPS}
      ButtonRenderer={ButtonRenderer}
      onSelectionChange={onSelectionChange}
      onButtonClick={handleMoveButtonClick}
      isMenuOpen={isMenuOpen}
      onVisibilityChange={toggleMenuVisibility}
      additionalContent={OptionButtonRenderer}
      defaultValue={selectedTeamID}
      autoFocusToFirstChild={true}
    />
  );
});

export default MoveToTeam;
