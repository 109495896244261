import { useState, useEffect } from 'react';
import featureFlag from './FeatureFlag';

// TODO: support multiple features
export const useFeatureValue = feature => {
  const [state, setState] = useState(featureFlag.getFeature(feature));
  useEffect(() => {
    return featureFlag.store.subscribe(() => {
      setState(featureFlag.getFeature(feature));
    });
  }, []);
  return state;
};
