import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MobileAppSheet } from '@jotforminc/jotform-common';
import { TeamNoAccessView } from '@jotforminc/enterprise-components';
import { useIsMobile } from '@jotforminc/hooks';
import { readCookie } from '@jotforminc/utils';

import classNames from 'classnames';
import store from './store/store';
import appConfig from './appConfig';
import Sidebar from './containers/sidebar';
import ReduxListingContainer from './containers/Listing/ReduxListingContainer';
import Cover from './components/Cover';

import './styles/style.scss';
import { darkTheme, lightTheme } from './styles/theme';
import Feature from './containers/Feature';
import { FEATURE_LIST, LISTING_TYPES, MOBILE_BREAKDOWN_WIDTH } from './constants';
import { SELECTORS } from './store/selectors';
import { ACTION_CREATORS } from './store/actionCreators';

const LiteApp = () => { // For Mobile apps
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={lightTheme}>
          <div className="lsApp" />
        </ThemeProvider>
      </DndProvider>
    </Provider>
  );
};

const Content = () => {
  const dispatch = useDispatch();
  const showNoAccessToTeamView = useSelector(SELECTORS.getShowNoAccessToTeamView);
  const showTeamNotFoundView = useSelector(SELECTORS.getShowTeamNotFoundView);
  const isActivityPanelOpen = useSelector(SELECTORS.getIsTeamActivityPanelOpen);
  const currentPage = useSelector(SELECTORS.getCurrentPage);
  const isMobile = useIsMobile(MOBILE_BREAKDOWN_WIDTH);
  const isSheetPage = currentPage === LISTING_TYPES.SHEET;
  const [isDownloadButtonOnMobile, setIsDownloadButtonOnMobile] = useState(false);

  if (showNoAccessToTeamView || showTeamNotFoundView) return <TeamNoAccessView showTeamNotFoundView={!!showTeamNotFoundView} />;

  const pages = [
    '^/(../)?myforms(.*)'
  ];

  const handleMount = async () => {
    if (isMobile && readCookie(`mobile-app-sheet-shown-${isSheetPage ? 'tables' : 'myforms'}`) !== 'true') {
      setIsDownloadButtonOnMobile(true);
    }

    const dispatchFetchListRequest = ({ data }) => {
      if (data === 'aiFormGenerated') {
        dispatch(ACTION_CREATORS.fetchListRequest());
      }
    };

    window.addEventListener('message', dispatchFetchListRequest);

    return window.removeEventListener('message', dispatchFetchListRequest);
  };

  useEffect(() => {
    handleMount();
  }, []);

  if (isSheetPage && isDownloadButtonOnMobile) {
    pages.push('^/(../)?mytables(.*)');
  }

  return (
    <>
      <Feature
        name={FEATURE_LIST.JOTFORM_TEAMS}
        activeComponent={<Cover canEdit={false} />}
        inactiveComponent={null}
      />
      <div className="lsApp-body">
        <Sidebar />
        <div className={classNames('lsApp-content', { rightPanelOpen: isActivityPanelOpen })}>
          <Switch>
            <Route exact path="/:id?">
              <ReduxListingContainer />
            </Route>
          </Switch>
        </div>
      </div>
      <div id="wizardPortal" />
      <MobileAppSheet pages={pages} project={isSheetPage ? 'tables' : 'myforms'} />
    </>
  );
};

const App = () => {
  const useDarkTheme = false;
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={useDarkTheme ? darkTheme : lightTheme}>
          <div className="lsApp">
            <Router basename={appConfig.BASE_URL}>
              <Content />
            </Router>
          </div>
        </ThemeProvider>
      </DndProvider>
    </Provider>
  );
};

const AppComponent = window.initialScreen === 'create-form' ? LiteApp : App;

export default AppComponent;
