import { ABTestManager } from '@jotforminc/abtest-manager';
import { isProduction } from '..';

// A/B Test: ctAppWizardStartFromBasic
export const initCtAppWizardStartFromBasic = async ({ user, isAppFirstUser }) => {
  let templateID = '';
  let isTestVariant = false;
  let logAbTestAction = f => f;

  const VARIANT_MAP = {
    CONTROL_A: '16911',
    TEST_B: '16921',
    TEST_C: '16951'
  };

  const TEMPLATE_MAP = {
    [VARIANT_MAP.CONTROL_A]: '',
    [VARIANT_MAP.TEST_B]: isProduction() ? '20240392404005040' : '20212623027448048',
    [VARIANT_MAP.TEST_C]: isProduction() ? '20240393119580052' : '20212621403763044'
  };

  const abTestManager = new ABTestManager({
    user: user.credentials,
    isTestEnabled: false,
    testName: 'ctAppWizardStartFromBasic',
    controlVariantCode: VARIANT_MAP.CONTROL_A,
    urlParam: 'ctawsfb',
    testVariantCode: [{
      code: VARIANT_MAP.TEST_B,
      urlParam: 'ctawsfb-b'
    }, {
      code: VARIANT_MAP.TEST_C,
      urlParam: 'ctawsfb-c'
    }],
    customUserChecks: {
      isAppFirstUser
    },
    cacheVariantCodeAtLocalStorage: true
    // debugMode: {
    //   // forceUserEligible: true,
    //   logTestState: true
    //   // forceTestVariant: true
    // }
  });

  try {
    const [isTestVar, variantCode] = await abTestManager.isTestVariant();
    isTestVariant = isTestVar;
    templateID = TEMPLATE_MAP[variantCode];
    logAbTestAction = abTestManager.registerABTestAction;
  } catch (e) {
    isTestVariant = false;
  }

  return { isTestVariant, templateID, logAbTestAction };
};
