import React from 'react';
import { createRoot } from 'react-dom/client';
import { t } from '@jotforminc/translation';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogCloseButton,
  DialogDescription,
  DialogIcon,
  DialogTitle
} from '@jotforminc/magnet';
import {
  IconCheckCircle,
  IconExclamationCircle,
  IconTrashExclamationFilled
} from '@jotforminc/svg-icons';

const ConfirmationDialog = (
  {
    dialogType,
    title,
    description,
    content,
    icon,
    backText = t('Cancel'),
    confirmText = t('Confirm'),
    confirmButtonColor = 'error',
    customClass = ''
  }
) => {
  return new Promise((resolve, reject) => {
    const headerIcon = () => {
      switch (dialogType) {
        case 'success':
          return {
            icon: IconCheckCircle,
            colorStyle: 'success'
          };
        case 'warning':
          return {
            icon: IconExclamationCircle,
            colorStyle: 'warning'
          };
        case 'informative':
          return {
            icon: IconExclamationCircle,
            colorStyle: 'informative'
          };
        case 'error':
          return {
            icon: IconExclamationCircle,
            colorStyle: 'error'
          };
        case 'delete':
          return {
            icon: IconTrashExclamationFilled,
            colorStyle: 'error'
          };
        case 'custom':
          return {
            icon,
            colorStyle: 'informative'
          };
        default:
          return {
            icon: IconTrashExclamationFilled,
            colorStyle: 'error'
          };
      }
    };
    const root = document.getElementById('listing-portal-root');
    const rootNode = createRoot(root);

    const handleClose = () => {
      reject();
      rootNode.unmount();
    };

    const handleConfirm = () => {
      resolve();
      rootNode.unmount();
    };

    const ConfirmationDialogPortal = () => {
      return (
        <Dialog
          open
          onClose={handleClose}
          ariaLabel={title}
        >
          <DialogBody className="pt-8 px-8">
            {headerIcon() && <DialogIcon icon={headerIcon().icon} colorStyle={headerIcon().colorStyle} />}
            {title && <DialogTitle>{title}</DialogTitle>}
            {description && <DialogDescription className="line-height-md break-word">{description}</DialogDescription>}
            {content && <DialogContent className={`px-8 break-word ${customClass}`}>{content}</DialogContent>}
          </DialogBody>
          <DialogActions>
            <Button colorStyle="secondary" variant="outline" onClick={handleClose}>{backText}</Button>
            <Button colorStyle={confirmButtonColor} onClick={handleConfirm}>{confirmText}</Button>
          </DialogActions>
          <DialogCloseButton onClick={handleClose} />
        </Dialog>
      );
    };

    rootNode.render(<ConfirmationDialogPortal />);
  });
};

export default ConfirmationDialog;
