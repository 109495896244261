import React from 'react';
import ContentLoader from 'react-content-loader';

export const MoveToListPlaceholder = () => {
  return (
    <ContentLoader
      height={50}
      speed={2}
      primaryColor="#E3E5F5"
      secondaryColor="#ecebeb"
    >
      <rect
        x='10' y='18' rx='3'
        ry='3' width='16' height='16'
      />
      <rect
        x='30' y='15.5' rx='3'
        ry='3' width='18' height='22'
      />
      <rect
        x='55' y='14' rx='5'
        ry='5' width='125' height='24'
      />
      <rect
        x='310' y='13' rx='5'
        ry='5' width='78' height='24'
      />
    </ContentLoader>
  );
};

export const MoveToTextPlaceholder = () => {
  return (
    <>
      <ContentLoader
        height={20}
        speed={2}
        primaryColor="#E3E5F5"
        secondaryColor="#ecebeb"
      />
      <ContentLoader
        height={30}
        speed={2}
        primaryColor="#E3E5F5"
        secondaryColor="#ecebeb"
      />
    </>
  );
};

export const MoveToButtonPlaceholder = () => {
  return (
    <>
      <div className='ml-auto' style={{ width: '96px' }}>
        <ContentLoader
          height={40} width={96} speed={2}
          primaryColor="#E3E5F5"
          secondaryColor="#ecebeb"
        />
      </div>
    </>
  );
};
