import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../Loader';
import { SortableTableHeader } from '../LazyTable/SortableTableHeader';
import { RowRenderer } from '../LazyTable/RowRenderer';
import { SortProps, TableMetadataType, RowProperties } from '../LazyTable/types';
import { LoaderProps } from '../../types';
import { EmptyPage } from '../EmptyPage';

type InfiniteScrollTableProps<SortOptions> = {
  tableData: TableMetadataType<SortOptions> & {
    data: {rowContent: { value: ReactNode, custumColumnClass?: string }[], rowProperties?: RowProperties, expandedRowContent?: ReactElement, id?: string }[],
    activeSort: SortProps<SortOptions>
  },
  loadMore?: () => void,
  hasMore?: boolean,
  scrollableTarget?: string,
  changeSortOption?: (sortProps: SortProps<SortOptions>) => void,
  tableClass?: string,
  teamID?: string,
  isTeamTable?: boolean,
  loaderProps?: LoaderProps,
  emptyPage?: {
    headerText?: string,
    text?: string,
    hasSearch?: boolean,
    classes?: string,
    button?: ReactNode
  }
};

export function InfiniteScrollTable<SortOptions>({
  tableData,
  loadMore = () => {},
  hasMore = true,
  scrollableTarget,
  changeSortOption,
  tableClass,
  teamID,
  isTeamTable,
  loaderProps,
  emptyPage = {},
  ...rest
}: InfiniteScrollTableProps<SortOptions>): ReactElement {
  const { data, activeSort } = tableData;
  const isEmpty = !hasMore && !data.length;
  return (
    <InfiniteScroll
      {...rest}
      hasMore={hasMore}
      dataLength={data.length}
      next={loadMore}
      scrollableTarget={scrollableTarget}
      hasChildren={!!data.length}
      loader={(
        <Loader
          withAnimation={true}
          teamAnimation={!!teamID}
          text='Loading...'
          {...loaderProps}
        />
      )}
    >
      <>
        <table className={classnames('jfTable', tableClass, { isEmpty })}>
          <thead className="jfTable-head">
            <SortableTableHeader
              value={activeSort}
              tableMetaData={tableData}
              handleOrder={changeSortOption}
              isTableEmpty={data.length === 0}
            />
          </thead>
          <tbody className="jfTable-body">
            {data.map(row => (
              <React.Fragment key={row.id || uuid()}>
                <RowRenderer
                  rowData={row.rowContent}
                  rowProperties={row.rowProperties}
                  tableMetaData={tableData}
                />
                {row.expandedRowContent ? row.expandedRowContent : null}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {isEmpty && (
          <EmptyPage
            {...emptyPage}
            isTeamTable={!!teamID || isTeamTable}
          />
        )}
      </>
    </InfiniteScroll>
  );
}

InfiniteScrollTable.defaultProps = {
  loadMore: () => {},
  hasMore: true,
  scrollableTarget: null,
  changeSortOption: () => {},
  tableClass: '',
  teamID: '',
  isTeamTable: false,
  loaderProps: {},
  emptyPage: {
    headerText: 'THERE ARE NO DATA YET',
    text: '',
    hasSearch: false,
    classes: '',
    button: null
  }
};
