import styled from 'styled-components';
import { WizardBody } from '@jotforminc/wizard-shared';

const ScWizardBody = styled(WizardBody)`
  &.jfWizard-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-grow: 0;
    margin: 40px 0;
  }
  
  .productItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 175px;
    width: 100%;
    border-radius: 8px;
    border: none;
    transition: all .2s;

    &.form { 
      background-color: #ff6100;

      &:hover { background-color: #e55300; }
    }

    &.inbox { 
      background-color: #249BB4;

      &:hover { background-color: #1989A0; }
    }

    &.pdf { 
      background-color: #3E62C8;

      &:hover { background-color: #2F4EAD; }
    }

    &.report { 
      background-color: #5369AB;

      &:hover { background-color: #344067; }
    }

    &.sign { 
      background-color: #7BB60F;

      &:hover { background-color: #6DA900; }
    }

    &.table { 
      background-color: #049E38;

      &:hover { background-color: #007B2A; }
    }

    &.approval, &.workflow { 
      background-color: #007862;

      &:hover { background-color: #006653; }
    }

    &.app { 
      background-color: #9c4dd3;

      &:hover { background-color: #892dca; }
    }


    &[data-productcount="4"] {
      flex: 1 0 46%;
    }

    @media screen and (max-width: 520px) {
      flex: 1 0 41%;
    }

    @media screen and (max-width: 320px) {
      flex: 1 0 100%;
      margin: 0 0 32px;
    }

    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      gap: 16px;

      @media screen and (max-width: 468px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 320px) {
        grid-template-columns: 1fr;
      }
    }

    &.disabled {
      pointer-events: none;
      position: relative;
      order: 1;

      > div {
        opacity: 0.5;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        opacity: 0.5;
      }

      &:after {
        content: 'Coming Soon';
        background-color: #2347A6;
        border-radius: 4px;
        position: absolute;
        top: 12px;
        right: -16px;
        padding: 6px;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
        z-index: 2;
      }
    }
  }

  .productIconWrapper {
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .productIcon {
    width: 54px;

    &.table,
    &.app {
      width: 62px;
    }
  }

  .productName {
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    margin-top: 24px;
    text-align: center;
  }
`;

export default ScWizardBody;
